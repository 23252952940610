import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

function SearchBox(props) {
  const classes = useStyles();

  const handleInputChange = (event) => {
    const { onInputChange } = props;
    if (onInputChange) {
      onInputChange(event);
    }
  };

  const handleChange = (event, value) => {
    const { onChange } = props;
    if (onChange) {
      onChange(value);
    }
  };

  

  const getOptionLabel = (option) => {
    if (option.label) {
      return option.label;
    }
    return '';
  };

  const { options, label } = props;
  return (
    <div className={classes.root}>
      <Autocomplete
        options={options}
        fullWidth
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            // variant="outlined"
            fullWidth
            onChange={handleInputChange}
          />
        )}
      />
      <SearchIcon className={classes.icon} />
    </div>
  );
}

SearchBox.defaultProps = {
  label: 'Search',
};

SearchBox.propTypes = {
  options: PropTypes.array.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default SearchBox;
