import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import FilterIcon from '@material-ui/icons/FilterList';
import TextField from '@material-ui/core/TextField';
import SingleListControl from '../SingleListControl';
import ExpansionPanelDetailed from '../ExpansionPanelDetail';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '180px',
    '& > *': {
      // margin: theme.spacing(1),
      padding: theme.spacing(1),

    },
  },
  paper: {
    // width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
}));

export default function FarmFilterBox(props) {
  const classes = useStyles();
  const {
    metaData,
    handleChange,
    selectedData,
    expandedFilter,
    handleExpand,
    handleToggle,
    filters,
    handleSaveFilter,
    activeFilter,
    handleFilterChange,
  } = props;

  if (!metaData || !selectedData) {
    return null;
  }
  // console.log(metaData);
  
  return (

    <div>
      <FormControl className={classes.formControl}>
        <div style={{width: '100%'}}>
          <Select
            native
          // disableUnderline
          // key="3a"
             // className={classes.select}
            required
              // style={{ paddingLeft: '8px' }}
            value={activeFilter}
            onChange={handleFilterChange}
          // MenuProps={{ classes: { paper: classes.menuPaper } }}
            inputProps={{
              name: 'filter',
              id: 'filter',
            }}
          >
            <option key="" value="" />
            {filters
              && filters.map(
                (filter) => <option key={filter.sk} value={filter.sk}>{filter.name}</option>,
              )}

          </Select>
          <Tooltip title="Add">
            <IconButton size="medium" color="secondary" aria-label="add" onClick={handleSaveFilter}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
      </FormControl>

      <ExpansionPanelDetailed
        heading="Date"
        expanded={expandedFilter}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData && selectedData.startDate && selectedData.endDate ? 1 : 0} />
        )}
        expandIcon={<FilterIcon />}
      >
        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            type="date"
            id="startDate"
            label="Start Date"
            name="startDate"
            InputLabelProps={{
              shrink: true,
            }}
            value={selectedData.startDate}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            disabled={!selectedData.startDate}
            minDate={selectedData.startDate}
            type="date"
            id="endDate"
            label="End Date"
            name="endDate"
            // InputProps={{ inputProps: { min: selectedData.startDate } }}
            inputProps={{ min: selectedData.startDate }}
            InputLabelProps={{
              shrink: true,
            }}
            value={selectedData.endDate}
            onChange={handleChange}
          />
        </div>

      </ExpansionPanelDetailed>
      {metaData.userGroups.length > 0 && (
      <ExpansionPanelDetailed
        heading="User groups"
        expanded={expandedFilter}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData && selectedData.userGroups.length} />
        )}
        expandIcon={<FilterIcon />}
      >
        <SingleListControl
          data={metaData.userGroups}
          // onBlur={this.handleBlur}
          checkedItems={selectedData && selectedData.userGroups}
          handleToggle={handleToggle}
          arrayName="userGroups"
        />
      </ExpansionPanelDetailed>
      )}
      {metaData.users.length > 0 && (
      <ExpansionPanelDetailed
        heading="Users"
        expanded={expandedFilter}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData && selectedData.users.length} />
        )}
        expandIcon={<FilterIcon />}
      >
        <SingleListControl
          data={metaData.users}
          // onBlur={this.handleBlur}
          checkedItems={selectedData && selectedData.users}
          handleToggle={handleToggle}
          arrayName="users"
        />
      </ExpansionPanelDetailed>
      )}
      <ExpansionPanelDetailed
        heading="Crops"
        expanded={expandedFilter}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData && selectedData.crops.length} />
        )}
        expandIcon={<FilterIcon />}
      >
        <SingleListControl
          data={metaData.crops}
          // onBlur={this.handleBlur}
          checkedItems={selectedData && selectedData.crops}
          handleToggle={handleToggle}
          arrayName="crops"
        />
      </ExpansionPanelDetailed>
      {metaData.clusters && (
      <ExpansionPanelDetailed
        heading="Clusters"
        expanded={expandedFilter}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData && selectedData.clusters.length} />
        )}
        expandIcon={<FilterIcon />}
      >
        <SingleListControl
          data={metaData.clusters}
          // onBlur={this.handleBlur}
          checkedItems={selectedData && selectedData.clusters}
          handleToggle={handleToggle}
          arrayName="clusters"
        />
      </ExpansionPanelDetailed>
      )}
      <ExpansionPanelDetailed
        heading="Blocks"
        expanded={expandedFilter}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData && selectedData.blocks.length} />
        )}
        expandIcon={<FilterIcon />}
      >
        <SingleListControl
          data={metaData.blocks}
          // onBlur={this.handleBlur}
          checkedItems={selectedData && selectedData.blocks}
          handleToggle={handleToggle}
          arrayName="blocks"
        />
      </ExpansionPanelDetailed>
      <ExpansionPanelDetailed
        heading="Plantings"
        expanded={expandedFilter}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData && selectedData.plantings.length} />
        )}
        expandIcon={<FilterIcon />}
      >
        <SingleListControl
          data={metaData.plantings}
          // onBlur={this.handleBlur}
          checkedItems={selectedData && selectedData.plantings}
          handleToggle={handleToggle}
          arrayName="plantings"
        />
      </ExpansionPanelDetailed>
      <ExpansionPanelDetailed
        heading="Record Types"
        expanded={expandedFilter}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData && selectedData.recordTypes.length} />
        )}
        expandIcon={<FilterIcon />}
      >
        <SingleListControl
          data={metaData.recordTypes}
          // onBlur={this.handleBlur}
          checkedItems={selectedData && selectedData.recordTypes}
          handleToggle={handleToggle}
          arrayName="recordTypes"
        />
      </ExpansionPanelDetailed>
      { metaData.monitorTypes.length > 0
      && (
      <ExpansionPanelDetailed
        heading="Monitor Types"
        expanded={expandedFilter}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData && selectedData.monitorTypes.length} />
        )}
        expandIcon={<FilterIcon />}
      >
        <SingleListControl
          data={metaData.monitorTypes}
          // onBlur={this.handleBlur}
          checkedItems={selectedData && selectedData.monitorTypes}
          handleToggle={handleToggle}
          arrayName="monitorTypes"
        />
      </ExpansionPanelDetailed>
      )}
      { metaData.targetTypes.length > 0 && selectedData.recordTypes.findIndex((g) => g === 'pestings') !== -1
      && (
      <ExpansionPanelDetailed
        heading="Target Types"
        expanded={expandedFilter}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData && selectedData.targetTypes.length} />
        )}
        expandIcon={<FilterIcon />}
      >
        <SingleListControl
          data={metaData.targetTypes}
          // onBlur={this.handleBlur}
          checkedItems={selectedData && selectedData.targetTypes}
          handleToggle={handleToggle}
          arrayName="targetTypes"
        />
      </ExpansionPanelDetailed>
      )}
      { metaData.targetTypes.length > 0 && selectedData.recordTypes.findIndex((g) => g === 'pestings') !== -1 && metaData.targets.length > 0
      && (
      <ExpansionPanelDetailed
        heading="Targets"
        expanded={expandedFilter}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData && selectedData.targets.length} />
        )}
        expandIcon={<FilterIcon />}
      >
        <SingleListControl
          data={metaData.targets}
          // onBlur={this.handleBlur}
          checkedItems={selectedData && selectedData.targets}
          handleToggle={handleToggle}
          arrayName="targets"
        />
      </ExpansionPanelDetailed>
      )}
      { metaData.targetTypes.length > 0 && selectedData.recordTypes.findIndex((g) => g === 'pestings') !== -1 && metaData.targets.length && metaData.stages.length > 0
      && (
      <ExpansionPanelDetailed
        heading="Stages"
        expanded={expandedFilter}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData && selectedData.stages.length} />
        )}
        expandIcon={<FilterIcon />}
      >
        <SingleListControl
          data={metaData.stages}
          // onBlur={this.handleBlur}
          checkedItems={selectedData && selectedData.stages}
          handleToggle={handleToggle}
          arrayName="stages"
        />
      </ExpansionPanelDetailed>
      )}

    </div>

  );
}
FarmFilterBox.propTypes = {
  metaData: PropTypes.object.isRequired,
  selectedData: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
  expandedFilter: PropTypes.string.isRequired,
  handleExpand: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  activeFilter: PropTypes.string.isRequired,
  handleSaveFilter: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,

};
