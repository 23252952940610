import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import GenDialog from '../../components/GenDialog';
import { validate, validateForm } from '../../libs/validate';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});
const requiredFields = [
  { name: 'name', min: 1, max: 100 },
  { name: 'description', min: 1, max: 300 },
];

class AgronomistActD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      agronomistActs: {},
      errors: { count: 0 },
      changed: false,

    };
  }

  componentDidMount() {
    if (this.props.isNew) {
      this.setState({ agronomistActs: {} });
    } else {
      const agronomistActs = this.props.selectedSetting;
      this.setState({ agronomistActs });
    }
  }

  validateField(field, requiredFields) {
    const error = validate(field, this.state.agronomistActs, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const agronomistActs = { ...this.state.agronomistActs };
    agronomistActs[event.target.id] = event.target.value;
    this.setState({ agronomistActs, changed: true });
  }

  render() {
    const {
      open, isNew, setClose, handleUpdate, getFromGlobalState,
    } = this.props;
    const { agronomistActs, errors } = this.state;
    const formValid = validateForm(agronomistActs, requiredFields);

    return (

      <GenDialog
        open={open}
        dialogObject={agronomistActs}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary="init_setting_"
        dynamoType="agronomistActs"
        valid={formValid}
        changed={this.state.changed}
        newTitle="New Activity"
        title={agronomistActs.name}
        getFromGlobalState={getFromGlobalState}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoFocus
          value={agronomistActs.name}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.name ? true : undefined}
          helperText={errors.name}
        />
        <TextField
          margin="normal"
          multiline
          rowsMax="4"
           // required
          fullWidth
          id="description"
          label="Description"
          name="description"
          value={agronomistActs.description}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.description ? true : undefined}
          helperText={errors.description}
        />

      </GenDialog>

    );
  }
}
AgronomistActD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedSetting: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(AgronomistActD);
