import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import UploadIcon from '@material-ui/icons/CloudUpload';
import Fab from '@material-ui/core/Fab';
import uuid from 'uuid';
import { parseCSV } from '../../libs/genLib';
import {
  toStore, getErrorMessage, getDownloadUrl, downloadFile, createBatchRecords,
} from '../../libs/storage';
import Load from '../../components/Load';
import FertiliserD from '../FertiliserD';
import EnhancedTable from '../../components/Etable';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '5px',
    backgroundColor: 'inherit',
  },
  paper: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
    marginLeft: '4px',
  },
  mainSection: {
    width: '100%',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    paddingRight: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1),
  },
});

const headCells = [
  {
    id: 'isDisabled', numeric: false, disablePadding: false, label: 'Disabled', maxWidth: '80px',
  },
  {
    id: 'name', numeric: false, disablePadding: false, label: 'Name',
  },
  {
    id: 'supplier', numeric: false, disablePadding: false, label: 'Supplier',
  },
  {
    id: 'unit', numeric: false, disablePadding: false, label: 'Unit',
  },
  {
    id: 'price', numeric: false, disablePadding: false, label: 'Unit Price',
  },
  {
    id: 'description', numeric: false, disablePadding: false, label: 'Description',
  },
];

const mobileHeadCells = [
  {
    id: 'name',
    combine: [{ id: 'unit', opacity: 1 }, { id: 'supplier', opacity: 0.6 }],
    numeric: false,
    disablePadding: true,
    label: 'Name',
    maxWidth: '100px',
  },
  {
    id: 'isDisabled', numeric: false, disablePadding: true, label: 'Disabled',
  },
];

const fertMap = {
  nitrogenP: 'N',
  phosphorusP: 'P',
  potassiumP: 'K',
  caP: 'Ca',
  mgP: 'Mg',
  sP: 'S',
  bP: 'B',
  feP: 'Fe',
  mnP: 'Mn',
  znP: 'Zn',
  cuP: 'Cu',
  moP: 'Mo',
};

class FertiliserAdminForm extends Component {
  constructor(props) {
    super(props);
    this.setClose = this.setClose.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleClickShowFertiliser = this.handleClickShowFertiliser.bind(this);
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleSelectFile = this.handleSelectFile.bind(this);

    this.state = {
      isloading: false,
      selectedFile: null,
      fertiliserDialogOpen: false,
      isNew: false,

    };
  }

  async componentDidMount() {
    const {
      isAuthenticated, getFertiliserDetails,
    } = this.props;
    if (isAuthenticated) {
      this.setState({ isloading: true });
      await getFertiliserDetails();
      this.setState({ isloading: false });
      // setAdminState({ genFertilisers });
    }
  }

  handleClickShowFertiliser = (event, parent, sk) => {
    const { fertilisers } = this.props;
    const fertiliser = fertilisers.find((fert) => fert.sk === sk);
    if (fertiliser) {
      this.setState({ selectedFertiliser: fertiliser, fertiliserDialogOpen: true });
    }
  };

  handleClickAdd() {
    this.setState({
      fertiliserDialogOpen: true,
      isNew: true,
      selectedFertiliser: {
        isGeneral: true,
        setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
        nitrogenP: 0,
        phosphorusP: 0,
        potassiumP: 0,
        caP: 0,
        mgP: 0,
        sP: 0,
        bP: 0,
        feP: 0,
        mnP: 0,
        znP: 0,
        cuP: 0,
        moP: 0,
        nitrogenG: 0,
        phosphorusG: 0,
        potassiumG: 0,
        caG: 0,
        mgG: 0,
        sG: 0,
        bG: 0,
        feG: 0,
        mnG: 0,
        znG: 0,
        cuG: 0,
        moG: 0,
      },
    });
  }

  handleUpdate(ferteliserIn) {
    const {
      getAdminState, setAdminState,
    } = this.props;
    const genFertilisers = getAdminState('genFertilisers');
    const updatedIndex = genFertilisers.findIndex((i) => i.sk === ferteliserIn.sk);
    const newFertilisers = [...genFertilisers];
    if (updatedIndex === -1) {
      newFertilisers.push(ferteliserIn);
    } else {
      newFertilisers[updatedIndex] = ferteliserIn;
    }
    setAdminState({ genFertilisers: newFertilisers });
  }

  // close the fertiliser dialog
  setClose() {
    this.setState({ fertiliserDialogOpen: false, isNew: false });
  }

  // write a function called validateFile that will take the loaded file and identify the rows where the name is duplicated
  // return the name of the duplicate row
  validateFile = (fertilisers) => {
    const { setSnack } = this.props;
    const names = fertilisers.map((fertiliser) => fertiliser.name);
    const duplicates = names.filter((name, index) => names.indexOf(name) !== index);
    if (duplicates.length) {
      setSnack({
        open: true,
        variant: 'error',
        messageText: `The following fertilisers are duplicated: ${duplicates.join(', ')}`,
      });
      return false;
    }
    return true;
  }

  // write a new function called loadFertiliser that will take the fertilisers that has been parsed
  // and then load them into the database
  loadFertiliser = async (fertilisers) => {
    const { getFromGlobalState, getAdminState } = this.props;
    const { setSnack } = this.props;
    this.setState({ isloading: true });
    const genFertilisers = getAdminState('genFertilisers');
    const newFertilisers = fertilisers.map((fertiliser) => {
      const newFertiliser = fertiliser;
      const updatedIndex = genFertilisers.findIndex((i) => i.name === fertiliser.name);
      if (updatedIndex === -1) {
        const skPrimary = 'init_';
        newFertiliser.sk = skPrimary.concat(uuid.v1());
        newFertiliser.version = 1;
      } else {
        newFertiliser.version = genFertilisers[updatedIndex].version + 1;
        newFertiliser.sk = genFertilisers[updatedIndex].sk;
      }
      newFertiliser.type = 'genFertilisers';
      newFertiliser.setId = 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3';
      newFertiliser.isGeneral = true;
      newFertiliser.isDisabled = false;
      newFertiliser.unit = fertiliser?.unit && fertiliser?.unit === 'granular' ? 'kg' : 'liter';
      // loop over fertMap and replace the P in the key with a G, then
      // add these new keys to the object
      Object.keys(fertMap).forEach((key) => {
        if (newFertiliser[key]) {
          newFertiliser[key] = parseFloat(newFertiliser[key]);
          const newKey = key.replace('P', 'G');
          newFertiliser[newKey] = (newFertiliser[key] * 1000) / 100;
        }
      });
      return newFertiliser;
    });
    try {
      setSnack({ open: true, variant: 'info', messageText: `Uploading ${newFertilisers.length} fertilisers.` });
      const recordsObject = { items: newFertilisers };
      recordsObject.activeTenant = getFromGlobalState('activeSite');
      recordsObject.type = 'batchupload';
      const creation = await createBatchRecords(
        getFromGlobalState('currentUser').username,
        'calls',
        toStore(recordsObject, true),
        getFromGlobalState('activeSite'),
      );
      if (creation.status) {
        for (let f = 0; f < newFertilisers.length; f += 1) {
          this.handleUpdate(newFertilisers[f], false);
        }
        setSnack({
          open: true,
          autoHideDuration: 5000,
          variant: 'info',
          messageText: `Completed uploading ${newFertilisers.length} fertilisers.`,
        });
      } else {
        setSnack({ open: true, variant: 'error', messageText: 'Failed to opload fertilisers' });
        this.setState({ isloading: false });
      }
      this.setState({ isloading: false });
    } catch (e) {
      setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
      this.setState({ isloading: false });
    }
  }

  handleParseAndLoad = async (file) => {
    const { setSnack, getFromGlobalState } = this.props;
    const params = {
      activeTenant: getFromGlobalState('activeSite'),
      type: 'files',
      path: file.Key,
      contentType: 'application/octet-stream', // selectedFile.fileType,
    };
    try {
      const url = await getDownloadUrl(params);
      const result = await downloadFile(url);
      const blobResult = await result.blob();
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        const fertilisers = parseCSV(text);
        if (this.validateFile(fertilisers)) {
          await this.loadFertiliser(fertilisers);
        }
        // this.setState({ rows, columns, isloading: false });
      };
      reader.readAsText(blobResult);
    } catch (e) {
      setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    }
  }

  // create a new function called getFerliserRows that will return the following propoerties
  // for each object in the array
  // sk, name,description, iDisabled and description, the description should be a concateanation
  // of all the rest of the object attributes not yet covered.
  // the iDisabled should be a boolean value that is true if the enabled attribute is false and
  // false if the enabled attribute is true.
  // the sk should be the sk attribute of the object
  // the name should be the name attribute of the object
  // the description should be a concateanation of all the rest of the object attributes
  // not yet covered.

  getFertiliserRows = (fertiliserDetail) => {
    if (!fertiliserDetail || !fertiliserDetail.length) {
      return [];
    }
    const fertiliserRows = fertiliserDetail.map((fertiliser) => {
      const ob = { ...fertiliser };
      ob.isDisabled = ob.isDisabled ? 'Yes' : 'No';
      // the description should be a concateanation of all the rest of the object attributes in a fertilier. use the object attribute name as well

      ob.description = '';
      Object.keys(fertMap).forEach((key) => {
        ob.description += `${fertMap[key]}:${ob[key]} `;
      });
      return ob;
    });
    return fertiliserRows;
  }

  // write and on change handler for the select file
  handleSelectFile = async (event) => {
    const { value } = event.target;
    const { getFromGlobalState } = this.props;
    const newSelectedFile = getFromGlobalState('files').find((file) => file.sk === value);
    this.setState({ selectedFile: newSelectedFile });
  };

  handleUploadFile = async () => {
    const { selectedFile } = this.state;
    this.setState({ isloading: true });
    await this.handleParseAndLoad(selectedFile);
    this.setState({ isloading: false });
  }

  render() {
    const {
      classes,
      getFromGlobalState,
      fertilisers,
      isMobile,
    } = this.props;
    const {
      isloading,
      selectedFile,
      fertiliserDialogOpen,
      selectedFertiliser,
      isNew,
    } = this.state;
    const files = getFromGlobalState('files');
    const rows = this.getFertiliserRows(fertilisers);
    // filter the files where the Key contains 'Settings/Fertilisers'
    const filteredFiles = files.filter((file) => file.Key.includes('Settings/Fertilisers'));

    return (

      <Paper elevation={0} className={classes.paper}>
        {(fertiliserDialogOpen)
          && (
          <FertiliserD
            handleUpdate={this.handleUpdate}
            open={fertiliserDialogOpen}
            setClose={this.setClose}
            isNew={isNew}
            getFromGlobalState={getFromGlobalState}
            fertiliser={selectedFertiliser}
            dynamoType="genFertilisers"
            fertilisers={rows}
          />
          )}

        <FormControl className={classes.formControl}>
          <InputLabel>Select file to upload</InputLabel>
          <Select
            native
          // disableUnderline
          // key="3a"
             // className={classes.select}
            required
              // style={{ paddingLeft: '8px' }}
            value={selectedFile && selectedFile.sk}
            onChange={this.handleSelectFile}
          // MenuProps={{ classes: { paper: classes.menuPaper } }}
            inputProps={{
              name: 'selectedFile',
              id: 'selectedFile',
            }}
          >
            <option key="" value="" />
            {filteredFiles
              && filteredFiles.map(
                (file) => <option key={file.sk} value={file.sk}>{file.filename}</option>,
              )}

          </Select>
        </FormControl>

        <Tooltip title="Upload">
          <Fab
            variant="extended"
            disabled={!selectedFile || isloading}
            size="medium"
            color="primary"
            aria-label="add"
            className={classes.margin}
            onClick={this.handleUploadFile}
          >
            <UploadIcon className={classes.extendedIcon} />
            Upload
          </Fab>

        </Tooltip>

        <Paper elevation={0} className={classes.paper}>
          <Load isloading={isloading}>
            <InputLabel>{`${rows && rows.length} fertilisers `}</InputLabel>
            <EnhancedTable
              heading="Fertilisers"
              displayToolbar
              hideHeading
              id="fertilisersTable"
              noPagination //= { rows && rows.length > 5 ? false:true}
              rows={rows}
              headCells={!isMobile ? headCells : mobileHeadCells}
              handleSelect={this.handleClickShowFertiliser}
              handleAddItem={this.handleClickAdd}
              actionType="actionType"
              rowKeyName="sk"
              disablePadFirstCol
             // padFirstCol="5px"
              clean
              dense
        // parent
       // ancestorsInRow
        // parentSK="parentSK"
              maxHeight="350px"
              selectedRow={selectedFertiliser ? selectedFertiliser.sk : ''}
            />
          </Load>
        </Paper>
      </Paper>

    );
  }
}
FertiliserAdminForm.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  getAdminState: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
  fertilisers: PropTypes.array.isRequired,
  setAdminState: PropTypes.func.isRequired,
  getFertiliserDetails: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
export default withStyles(styles)(FertiliserAdminForm);
