import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Load from '../../components/Load';
import LoaderButton from '../../components/LoaderButton';
import { sharedUserAdd, sharedUserReject, getSiteList } from '../../libs/storage';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    // marginTop:'5px',
  },
  root2: {
    flexGrow: 1,
    marginTop:'5px',
  },
  submit: {
    margin: theme.spacing(1),
  },
  iconContainer: {
    textAlign:'center',
    width:'60px',
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    backgroundColor: 'inherit',
    // overflow: 'scroll',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  fab: {
    margin: theme.spacing(1),
  },
  fabFloat: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 80,
    left: 'auto',
    position: 'fixed',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  list :{
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  searchBar: {
    [theme.breakpoints.up('sm')]: {
      //display: 'none',
    },

  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  cardHeader: {
    paddingBottom: '6px',

  },
  cached: {
    fontSize: 15,
    color: theme.palette.secondary.main,
  },
  syncError: {
    fontSize: 15,
    color: 'red',
  },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  

  
});


class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: "",
      isNew: false,
      isloading:false,
      
     
    };
  }

  componentDidMount() {
  
  }

  
  onAccept = async (email,tenant) => {
    //logic here to add existing user to our farm.
    try {
      const payLoad = {email, tenant};
      this.setState({ isloading: true });
      const accepted = await sharedUserAdd(payLoad);
      const messages = this.props.getFromGlobalState("invites");
      const idx = messages.findIndex(message =>(message.email === email && message.tenantId === tenant));
      if (idx !== -1){
        messages.splice(idx,1);
      }
      this.setState({ isloading: false });
      const siteList = await getSiteList();
      const filteredList = siteList.filter((s)=> !s.isDisabled || s.isDisabled !== true);
      this.props.setGlobalState({ siteList: filteredList });

      this.props.setGlobalState({invites:messages});
      this.props.setSnack({ open: true, variant: 'info', autoHideDuration: 2000, messageText: 'Invitation accepted.' });
    }
    catch (e) {
      this.setState({ isloading: false, userDialogOpen: false });
      this.props.setSnack({ open: true, variant: 'error', messageText: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error}, api src: ${e.response.data.src} ` : e.message) });
      return false;
    }
  }

  onReject = async (email,tenant) => {
    //logic here to add existing user to our farm.
    try {
      const payLoad = {email, tenant};
      this.setState({ isloading: true });
      const accepted = await sharedUserReject(payLoad);
      const messages = this.props.getFromGlobalState("invites");
      const idx = messages.findIndex(message =>(message.email === email && message.tenantId === tenant));
      if (idx !== -1){
        messages.splice(idx,1);
      }
      this.setState({ isloading: false });
      this.props.setGlobalState({invites:messages});
      this.props.setSnack({ open: true, variant: 'info', autoHideDuration: 2000, messageText: 'Invitation rejected.' });
    }
    catch (e) {
      this.setState({ isloading: false, userDialogOpen: false });
      this.props.setSnack({ open: true, variant: 'error', messageText: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error}, api src: ${e.response.data.src} ` : e.message) });
      return false;
    }
  }
  
  
  handleExpandActivity = (activity) => {
    let selectedActivity = this.state.selectedActivity;
    if (selectedActivity && selectedActivity.type === selectedActivity.type){
      this.setState({selectedActivity:null});
      // this.setState({expandedContent:null});
    }
    else {
      this.setState({selectedActivity: activity});
    }
  
  
  }


  

  
  renderMessages(messages, classes, isloading) { 
    return messages.map(
      (message) =>
          <Grid key={message.sk} style={{padding:"3px"}} item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card className={classes.root}>
      <CardActionArea>
       {/* <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="140"
          image="/static/images/cards/contemplative-reptile.jpg"
          title="Contemplative Reptile"
       /> */}
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {`Invitation from ${message.farm ? message.farm : message.shortName }`}
          </Typography>
          <Typography gutterBottom variant="body2" component="p">
            {`Sent on: ${new Date(parseInt(message.createdAt))}`}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {`You have been granted access to ${ message.farm ? message.farm : message.shortName } by ${message.invitedByName} ${message.invitedBySurname}. You will be able to access this farm on Land Diary by selecting the short name "${message.shortName}". You can accept or reject this invitation below.`}
          </Typography>
         
        </CardContent>
      </CardActionArea>
      <CardActions>
      <LoaderButton
            // type="submit"
                    onClick={this.onAccept.bind(this, message.email,message.tenantId)}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isloading}
                    isloading={isloading}
                    text={'Accept'}
                    loadingText={'Accepting ...'}
                  />

        <LoaderButton
            // type="submit"
                  onClick={this.onReject.bind(this, message.email,message.tenantId)}
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    disabled={isloading}
                    isloading={isloading}
                    text={'Reject'}
                    loadingText={'Rejecting ...'}
                  />
      </CardActions>
    </Card>
          </Grid>
      
    );
  }

  

  render() {
    const {classes,getFromGlobalState} = this.props;
    const messages = getFromGlobalState("invites");
    console.log('report loading....',this.state.reportLoading);
    
    return (
      <Paper elevation={0} className={classes.paper}>

        
        
      <Grid container className={classes.root} spacing={2} alignItems="flex-start"> 
      <Grid className={classes.searchBar} key='search' style={{padding:"3px",marginBottom:'3px'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
     
      </Grid>
      </Grid>
      <Load isloading={this.state.isLoading}>
      <Grid container className={classes.root2} spacing={2}  alignItems="flex-start"> 
        { messages && this.renderMessages(messages,classes)}

      
        
       
    </Grid>
    </Load> 
    </Paper>
     
    );
  }
}
Messages.propTypes = {
  pdfRequested: PropTypes.bool.isRequired,
};
export default withStyles(styles)(Messages);
