import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OperatorSelector from '../../components/OperatorSelector';

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    // alignItems: 'center',
  },
  tooltip: {
    backgroundColor: 'white',
  },
});

const filterItems = (arr, selectedBlocks) => {
  const feritilisers = {};
  if (!selectedBlocks) { return; }
  for (const ob of selectedBlocks) {
    const plantingCrop = ob.plantingOB.crop;
    const allowedFertilisers = [];
    arr.forEach((fertiliser) => {
      const idx = fertiliser.fertcrops.findIndex((c) => c.crop === plantingCrop);
      if (idx !== -1) {
        allowedFertilisers.push(fertiliser);
      }
    });
    feritilisers[ob.planting] = [...allowedFertilisers];
  }
  return feritilisers;
};

// todo make more efficient

const findFertUnits2 = (selectedBlocks, fertilisers) => {
  if (!fertilisers) { return 0; }
  if (!selectedBlocks) { return; }
  const fertCrop = {};

  for (var ob of selectedBlocks) {
    if (!ob.record.product) { continue; }
    const fertiliser = fertilisers.find((c) => c.sk === ob.record.product);
    const fertcrop = findChemCrop(fertiliser, ob.plantingOB);
    fertCrop[ob.planting] = fertcrop;
  }
  return fertCrop;
};

const findChemCrop = (fert, planting) => {
  const fertCrop = (fert.fertcrops||[]).find((cc) => cc.crop === planting.crop);
  return fertCrop;
};

const findFertUnits = (fertiliserSK, planting, fertilisers) => {
  if (!fertiliserSK) { return undefined; }
  const fertiliser = fertilisers && fertilisers.find((c) => c.sk === fertiliserSK);
  const fertcrop = fertiliser && findChemCrop(fertiliser, planting);
  return fertcrop;
};
const findFertiliser = (fertiliserSK, fertilisers) => {
  if (!fertiliserSK) { return undefined; }
  const fertiliser = fertilisers.find((c) => c.sk === fertiliserSK);
  return fertiliser;
};

const getTotals = (selectedBlocks, fertilisers) => {

  for (var ob of selectedBlocks){

    if (!ob.record) continue;
    if (!ob.record.unit) continue;
    if (!fertilisers) continue;
    const record = ob.record;

    if (record.unit) {
      let fertCrop;
      let fertUnit;
      if (record.product) {
        fertCrop = findFertUnits(record.product, ob.plantingOB, fertilisers);
        const fertiliser = findFertiliser(record.product, fertilisers);
        fertUnit = fertiliser?.unit;
      }
      if (record.unit === 'plant') {
      // record.total = record.volume * planting[0].totalPlants;
        record.totalUnit = fertUnit === 'kg' ? 'g' : 'ml';
      } else {
        record.totalUnit = fertUnit === 'kg' ? 'kg' : 'l';
      }
    }

    if (record.volume && !record.totalOverride) {
      if (record.unit === 'plant') {
        record.total = record.volume * ob.plantingOB.totalPlants;
      } else if (record.unit === 'ha') {
        record.total = record.volume * ob.plantingOB.size;
      } else {
        record.total = record.volume;
      }
    } 

  
    };
}



function FertilisingFormBatch(props) {
  const {
    classes, record, errors,getFromGlobalState, handleBlur, onApplyAll, handleChange, overrideTotalBatch, firstFormValid, metaData, planting, block, handleSwitchChange, selectedBlocks, handleBatchChange, handleCommonBlur, handleCommonChange,
  } = props;
  /////// const allowedFertilisers = filterItems(metaData.fertilisers, selectedBlocks);
  const combinations = getFromGlobalState('combinations').filter((x)=>x.combinationType === "Fertiliser");
  const allowedFertilisers = metaData.fertilisers.filter((f) => !f.isDisabled).concat(combinations);
  let fertCrop;
  const ferts = metaData.fertilisers.concat(combinations);
  getTotals(selectedBlocks, ferts);

  // if (record.product) {
  fertCrop = findFertUnits(selectedBlocks, ferts);
  // }

  let isFirst = true;

  return (
   
<>
      {selectedBlocks && selectedBlocks.map((selectedBlock) => (
        <Card full style={{ width: '100%', marginBottom: '15px' }}>
          <CardContent>
          <Grid container className={classes.root} spacing={1} alignItems="flex-start">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h6" color="inherit" noWrap className={classes.location}>
                {selectedBlock.label}
              </Typography>

            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="date"
    // defaultValue={new Date()}
                id="date"
                label="Date"
                name="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedBlock.record.date}
                onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                onBlur={handleBlur.bind(this, selectedBlock.planting)}
                error={errors[selectedBlock.planting] && errors[selectedBlock.planting].date ? true : undefined}
                helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].date}
              />
            </Grid>

            {/* } <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
<TextField
    margin="normal"
    //required
    //fullWidth
    type="date"
   // defaultValue={new Date()}
    id="endDate"
    label="End Date"
    name="endDate"
    InputLabelProps={{
      shrink: true,
    }}
    value={selectedBlock.record.endDate}
    onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
    onBlur={handleBlur.bind(this,selectedBlock.planting)}
    error={errors[selectedBlock.planting] && errors[selectedBlock.planting].endDate ? true : undefined}
    helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].endDate}
  />
  </Grid> */}

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                margin="normal"
    // required
    // fullWidth
                type="time"
    // defaultValue={new Date()}
                id="endTime"
                label="End Time"
                name="endTime"
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedBlock.record.endTime}
                onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                onBlur={handleBlur.bind(this, selectedBlock.planting)}
                error={errors[selectedBlock.planting] && errors[selectedBlock.planting].endTime ? true : undefined}
                helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].endTime}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="product">Fertiliser</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  value={selectedBlock.record.product}
                  onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                  onBlur={handleBlur.bind(this, selectedBlock.planting)}
                  error={errors[selectedBlock.planting] && errors[selectedBlock.planting].product ? true : undefined}
                  inputProps={{
                    name: 'product',
                    id: 'product',
                  }}
                >
                  <option value="" />
                  {allowedFertilisers && allowedFertilisers.map((fertiliser) => (
                    <>
                      <option value={fertiliser.sk}>{fertiliser.name}</option>
                    </>
                  ))}
                </Select>
                {
                //errors && errors[selectedBlock.planting] && errors[selectedBlock.planting].product && <FormHelperText className={classes.helperText}>{errors && errors[selectedBlock.planting] && errors[selectedBlock.planting].product}</FormHelperText>
                }
              </FormControl>
            </Grid>

          
            <>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor="product">Application per</InputLabel>
                  <Select
                    native
                    fullWidth
                    required
                    displayEmpty
                    value={selectedBlock.record.unit}
                    onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                    onBlur={handleBlur.bind(this, selectedBlock.planting)}
                    error={errors[selectedBlock.planting] && errors[selectedBlock.planting].unit ? true : undefined}
                    inputProps={{
                      name: 'unit',
                      id: 'unit',
                    }}
                  >
                    <option value="" />
                    <option value="planting">Planting</option>
                    <option value="plant">Plant</option>
                    <option value="ha">ha</option>

                  </Select>
                  {errors.unit && <FormHelperText className={classes.helperText}>{errors.unit}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                <TextField
                  margin="normal"
                  required
                  type="number"
                  //inputProps={{ min: '0.00001', step: 'any' }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{record.totalUnit ? record.totalUnit : ''}</InputAdornment>,
                    min: '0.00001',
                    step: 'any',
                  }}
                  InputLabelProps={{
                    shrink: true,
                    min: '0.00001',
                    step: 'any',
                  }}
        // fullWidth
                  id="volume"
                  //label={`Applied QTY in ${record.totalUnit ? record.totalUnit : ''}`}
                  label="Applied quantity"
                  name="volume"

                  value={selectedBlock.record.volume}
                  onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                  onBlur={handleBlur.bind(this, selectedBlock.planting)}
                  error={errors[selectedBlock.planting] && errors[selectedBlock.planting].volume ? true : undefined}
                  helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].volume}
                />

              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                <TextField
                  margin="normal"
                  required
                  type="number"
        // inputProps={{ 'min': '0.00001', 'step':'any' }}
                  InputLabelProps={{
                    shrink: true,
                    min: '0.00001',
                    step: 'any',
                  }}
        // fullWidth
                  id="total"
                  label={`Total ${selectedBlock.record.totalUnit ? selectedBlock.record.totalUnit : ''}`}
                  name="total"
                  value={selectedBlock.record.total}
                  onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                  onBlur={handleBlur.bind(this, selectedBlock.planting)}
                  error={errors[selectedBlock.planting] && errors[selectedBlock.planting].volume ? true : undefined}
                  helperText={errors.total}
                />

              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControlLabel
            value="totalOverride"
            control={(
              <Radio
                color="secondary"
                name="totalOverride"
                checked={selectedBlock.record.totalOverride}
                id="totalOverride"
                onClick={overrideTotalBatch.bind(this, selectedBlock.planting, selectedBlock.block)}
              />
)}
            label="Override"
          />
        </Grid>

            
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <OperatorSelector
                  onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                  onBlur={handleBlur.bind(this, selectedBlock.planting)}
                  operators={metaData.operators}
                  title="Operator"
                  value={selectedBlock.record.operator}
                  team={selectedBlock.record.team}
                  error={errors[selectedBlock.planting] && errors[selectedBlock.planting].operator}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  margin="normal"
    // required
                  fullWidth
                  id="notes"
                  label="Notes"
                  name="notes"
                  multiline
                  rowsMax="4"
                  value={selectedBlock.record.notes}
                  onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                  onBlur={handleBlur.bind(this, selectedBlock.planting)}
                  error={errors.notes ? true : undefined}
                  helperText={errors.notes}
                />
              </Grid>
              {isFirst && (
              <Grid style={{ marginTop: '20px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={onApplyAll.bind(this, selectedBlock.planting, selectedBlock.block, selectedBlock.record)}
                  disabled={firstFormValid}
                >
                  Apply to All
                </Button>
              </Grid>
              )}
              {isFirst = false}
            </>
           
</Grid>
          </CardContent>
        </Card>
      ))}

  
    </>
  );
}

FertilisingFormBatch.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
  planting: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleSwitchChange: PropTypes.func.isRequired,
  selectedBlocks: PropTypes.array.isRequired,
  handleBatchChange: PropTypes.func.isRequired,
  overrideTotalBatch: PropTypes.func.isRequired,
  handleCommonChange: PropTypes.func.isRequired,
  handleCommonBlur: PropTypes.func.isRequired,
  onApplyAll: PropTypes.func.isRequired,
  firstFormValid: PropTypes.bool.isRequired,
  getFromGlobalState:PropTypes.func
};

export default withStyles(styles)(FertilisingFormBatch);
