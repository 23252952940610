import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import GenDialog from '../../components/GenDialog';
import { validate, validateForm } from '../../libs/validate';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

const requiredFields = [
  { name: 'name', min: 1, max: 200 },
];

class ProductProcessorTypeD extends Component {
  constructor(props) {
    super(props);

    const { handleUpdate } = this.props;
    this.handleUpdate = handleUpdate.bind(this);
    this.state = {
      productProcessorType: {},
      errors: { count: 0 },
      changed: false,

    };
  }

  componentDidMount() {
    const { isNew, selectedSetting } = this.props;
    if (isNew) {
      this.setState({ productProcessorType: { enabled: true, isGeneral: true, setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3' } });
    } else {
      const productProcessorType = selectedSetting;
      this.setState({ productProcessorType });
    }
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const { productProcessorType } = this.state;
    const newProductProcessorType = { ...productProcessorType };
    newProductProcessorType[event.target.id] = event.target.value;
    this.setState({ productProcessorType: newProductProcessorType, changed: true });
  }

  handleCheckChange = (event) => {
    const { productProcessorType } = this.state;
    const newProductProcessorType = { ...productProcessorType };
    newProductProcessorType[event.target.id] = event.target.checked;
    this.setState({ productProcessorType: newProductProcessorType, changed: true });
  }

  validateField(field) {
    const { productProcessorType, errors } = this.state;
    const error = validate(field, productProcessorType, requiredFields);
    const newErrors = { ...errors };
    newErrors[field] = error.msg;
    this.setState({ errors: newErrors });
  }

  render() {
    const {
      classes, open, isNew, setClose, getFromGlobalState,
    } = this.props;
    const { productProcessorType, errors, changed } = this.state;
    const formValid = validateForm(productProcessorType, requiredFields);

    return (

      <GenDialog
        open={open}
        dialogObject={productProcessorType}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={this.handleUpdate}
        skPrimary="init_"
        dynamoType="productProcessorTypes"
        valid={formValid}
        changed={changed}
        newTitle="New Pocessor Type"
        title={productProcessorType.name}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={productProcessorType.enabled}
                 // disabled={isInvite}
                  id="enabled"
                  onChange={this.handleCheckChange}
                  value="enabled"
                  color="secondary"
                />
        )}
              label="Enabled"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoFocus
              value={productProcessorType.name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              value={productProcessorType.description}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.discription ? true : undefined}
              helperText={errors.discription}
            />
          </Grid>
        </Grid>
      </GenDialog>

    );
  }
}
ProductProcessorTypeD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedSetting: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(ProductProcessorTypeD);
