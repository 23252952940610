import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import UpdateModal from '../UpdateModal';
import Snack from '../Snack';

import AppBar from '../AppBar';
import AppBarMobile from '../AppBarMobile';
import ResponsiveDrawer from '../Drawer';

const styles = (theme) => ({
  root: {
    // display: 'flex',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },

    // overflow: 'hidden',
  },
  // toolbar: theme.mixins.toolbar,
  toolbarSpacer: {
    minHeight: '56px',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  content: {
    flexGrow: 1,
    // width: "auto",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(12),
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -240,
    },
    backgroundColor: 'inherit',
    // overflow: "auto",
  },
  drawer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },

  },
});

class AppLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileopen: false,
    };
  }

  setSnackClose = () => {
    const { setSnack } = this.props;
    setSnack({ open: false, messageText: ' ', variant: 'info' });
  }

  handleDrawerToggle = (event) => {
    this.setState((state) => ({ mobileopen: !state.mobileopen }));
    event.stopPropagation();
  };

  handleDrawerClose = (event) => {
    const { mobileopen } = this.state;
    if (mobileopen) {
      this.setState((state) => ({ mobileopen: !state.mobileopen }));
      event.stopPropagation();
    }
  };
  /* handleToggle = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }; */

  render() {
    const {
      classes,
      children,
      location, /* isAuthenticated,  farmId , */
      handleLogout,
      reload,
      history,
      snack,
      online,
      getFromGlobalState,
      setGlobalState,
      refreshState,
      sync,
      userDetail,
      industryView,
      isMobile,
    } = this.props;
    const { mobileopen } = this.state;
    const isAuthenticated = getFromGlobalState('isAuthenticated');
    return (
      <div className={classes.root} onClick={this.handleDrawerClose}>
        <AppBar
          online={online}
          location={location}
          isAuthenticated={isAuthenticated}
          reload={reload}
          isMobile={isMobile}
          history={history}
          handleLogout={handleLogout}
          handleDrawerToggle={this.handleDrawerToggle}
          getFromGlobalState={getFromGlobalState}
          setGlobalState={setGlobalState}
          refreshState={refreshState}
          industryView={industryView}
          sync={sync}
        />
        <AppBarMobile
          online={online}
          location={location}
          isAuthenticated={isAuthenticated}
          reload={reload}
          history={history}
          isMobile={isMobile}
          handleLogout={handleLogout}
          getFromGlobalState={getFromGlobalState}
          setGlobalState={setGlobalState}
          refreshState={refreshState}
          userDetail={userDetail}
          sync={sync}
        />
        <ResponsiveDrawer
          online={online}
          handleDrawerToggle={this.handleDrawerToggle}
          mobileopen={mobileopen}
          location={location}
          className={classes.drawer}
          isMobile={isMobile}
          isAuthenticated={isAuthenticated}
          handleLogout={handleLogout}
          userDetail={userDetail}
          industryView={industryView}
          getFromGlobalState={getFromGlobalState}
          data={{ messages: getFromGlobalState('invites') }}
        />

        <main className={classes.content}>
          <div className={classes.toolbarSpacer} />

          {children}
          <UpdateModal />
          <Snack
            open={snack.open}
            setClose={this.setSnackClose}
            messageText={snack.messageText}
            variant={snack.variant}
            autoHideDuration={snack.autoHideDuration ? snack.autoHideDuration : undefined}
          />
        </main>
      </div>
    );
  }
}

AppLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  refreshState: PropTypes.func.isRequired,
  industryView: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  sync: PropTypes.func.isRequired,
  userDetail: PropTypes.object.isRequired,
  // isAuthenticated: PropTypes.bool.isRequired,
  snack: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.any.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.any.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  online: PropTypes.bool.isRequired,
  setSnack: PropTypes.func.isRequired,
};
export default compose(
  withRouter,
  withStyles(styles),
)(AppLayout);
