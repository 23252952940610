import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import FilterIcon from '@material-ui/icons/FilterList';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SingleListTargets from '../SingleListTargets';
import ExpansionPanelDetailed from '../ExpansionPanelDetail';
import { generateTargetsRows, generateTargetsRowsByType } from '../../libs/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '180px',
    '& > *': {
      // margin: theme.spacing(1),
      padding: theme.spacing(1),

    },
  },
  paper: {
    // width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
}));

export default function TargetsList(props) {
  const classes = useStyles();
  const {
    metaData, handleSelect, checkedItems, handleReasonsDataChange, pickApplied, handleApplied, readOnly, arrayName, planting, expandedTarget, handleExpand, getFromGlobalState, handleToggle, handleChange, handleReentryOverride,
  } = props;
  const rows = generateTargetsRows(metaData.monitorings, getFromGlobalState);
  const targetsByType = generateTargetsRowsByType(rows, getFromGlobalState);
  console.log(targetsByType);
  const bel = 2;
  return (
    <>
      {targetsByType && Object.entries(targetsByType) && Object.entries(targetsByType).map((target) => (

        <ExpansionPanelDetailed
          heading={target[0]}
          expanded={expandedTarget}
          handleExpand={handleExpand}
       // </> detailComponent={(
       //   <Badge color="secondary" badgeContent={selectedData.countries.length} />
      //  )}
          expandIcon={<FilterIcon />}
        >

          <SingleListTargets
            data={target[1]}
       // onBlur={handleBlur.bind(this)}
            handleChange={handleChange}
            checkedItems={checkedItems}
            handleToggle={handleToggle}
            handleReentryOverride={handleReentryOverride}
        // memberName='targetDetail.name'
            arrayName={arrayName}
            planting={planting}
            readOnly={readOnly}
            pickApplied={pickApplied}
            handleApplied={handleApplied}
            handleReasonsDataChange={handleReasonsDataChange}
          />

        </ExpansionPanelDetailed>

      ))}
    </>

  );
}
TargetsList.propTypes = {
  metaData: PropTypes.object.isRequired,
  checkedItems: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
  expandedTarget: PropTypes.string.isRequired,
  handleExpand: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReentryOverride: PropTypes.func.isRequired,
  arrayName: PropTypes.string.isRequired,
  planting: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  pickApplied: PropTypes.bool.isRequired,
  handleApplied: PropTypes.func.isRequired,
  handleReasonsDataChange: PropTypes.func.isRequired,

};
