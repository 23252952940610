import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  helperText: {
    color: 'red',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
});

function mapOperators(operators) {
  if (!operators) {
    return {};
  }
  const teams = {};
  const sortedOperators = operators && operators.filter((o) => o.enabled).sort((a, b) => (`${a.surname}`).localeCompare(b.surname, undefined, { numeric: false }));
  for (let i = 0; i < sortedOperators.length; i += 1) {
    const teamName = sortedOperators[i].team ? sortedOperators[i].team : 'Unasigned';
    if (!teams[teamName]) {
      teams[teamName] = [];
    }
    teams[teamName].push({ name: sortedOperators[i].name, surname: sortedOperators[i].surname, sk: sortedOperators[i].sk });
  }
  return teams;
}

function OperatorSelector(props) {
  const {
    classes, onChange, operators, title, value, handleBlur, error, team,
  } = props;
  const mappedOperators = mapOperators(operators);
  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start">
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="team">Team</InputLabel>
          <Select
            native
            fullWidth
            required
            displayEmpty
            value={team}
            onChange={onChange}
            onBlur={handleBlur}
            error={error ? true : undefined}
            inputProps={{
              name: 'team',
              id: 'team',
            }}
          >
            <option aria-label="None" value="" />
            {Object.entries(mappedOperators) && Object.entries(mappedOperators).map((t) => (
              <option value={t[0]}>{t[0]}</option>
            ))}
          </Select>
          {error && <FormHelperText className={classes.helperText}>{error}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="assignedTo">{title}</InputLabel>
          <Select
            native
            fullWidth
            required
            displayEmpty
            value={value}
            onChange={onChange}
            onBlur={handleBlur}
            error={error ? true : undefined}
            inputProps={{
              name: 'operator',
              id: 'operator',
            }}
          >
            <option aria-label="None" value="" />
            {mappedOperators && mappedOperators[team] && mappedOperators[team].map((operator) => (
              <option value={operator.sk}>{`${operator.name} ${operator.surname}`}</option>
            ))}
          </Select>
          {error && <FormHelperText className={classes.helperText}>{error}</FormHelperText>}
        </FormControl>
      </Grid>
    </Grid>

  );
}
OperatorSelector.defaultProps = {
  error: '',
};
OperatorSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  operators: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  error: PropTypes.string,
  team: PropTypes.string.isRequired,
};

export default withStyles(styles)(OperatorSelector);
