export default {
    s3: {
      REGION: "us-east-1",
      BUCKET: "dev-landdiary-attachmentsbucket", //landdiary-api-dev-attachmentsbucket-1nd8z6lwfm5ya"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://y6b6vsydqb.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_PTcEgtyOT",
      APP_CLIENT_ID: "6io74e3s3ok97to2pk8uj9abic",
      IDENTITY_POOL_ID: "us-east-1:a92a9302-d01d-4020-affb-6fa37d28d67b"
    }
  };

  