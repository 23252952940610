import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      //margin: theme.spacing(1),
      padding: theme.spacing(1),
      
    },
  },
}));

export default function InfoBox(props) {
  const classes = useStyles();
  const { message } = props;
  return (
    <div className={classes.root}>
      <Paper elevation={1}><InfoIcon  size='medium' /><Typography variant='subtitle1'>{message}</Typography></Paper>
    </div>
  );
}
InfoBox.propTypes = {
  message: PropTypes.string.isRequired,
};
