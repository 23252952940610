import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import GenDialog from '../../components/GenDialog';
import { validate, validateForm } from '../../libs/validate';
import countryData from '../../data/countries.json';
import SingleListControl from '../../components/SingleListControl';
import ChipControl from '../../components/ChipControl';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

const requiredFields = [
  { name: 'name', min: 1, max: 200 },
  { name: 'productProcessorType', min: 1, max: 200 },
  { name: 'country', min: 1, max: 100 },
  { name: 'province', min: 1, max: 100 },
];

class ProductProcessorD extends Component {
  constructor(props) {
    super(props);

    const { handleUpdate } = this.props;
    this.handleUpdate = handleUpdate.bind(this);
    this.state = {
      productProcessor: {},
      errors: { count: 0 },
      changed: false,
      regions: [],
      processedCrops: [],
      selectedCropType: '',
      localGenCrops: {},

    };
  }

  async componentDidMount() {
    const {
      isNew, selectedSetting,
    } = this.props;
    if (isNew) {
      this.setState({ productProcessor: { enabled: true, isGeneral: true, setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3' } });
    } else {
      const productProcessor = selectedSetting;
      if (productProcessor.country) {
        const countryFilter = countryData.filter((p) => p.countryName === productProcessor.country);
        const regions = countryFilter.length > 0 ? countryFilter[0].regions : [];
        this.setState({ productProcessor, regions });
      } else {
        this.setState({ productProcessor });
      }
    }
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const { productProcessor } = this.state;
    const newproductProcessor = { ...productProcessor };
    newproductProcessor[event.target.id] = event.target.value;
    this.setState({ productProcessor: newproductProcessor, changed: true });
  }

  handleCropTypeChange = async (event) => {
    const { getFromGlobalState } = this.props;
    const { productProcessor } = this.state;
    const selectedCropType = event.target.value;
    const cropsSetting = getFromGlobalState('cropsSetting');
    const localGenCrops = {};
    localGenCrops[selectedCropType] = cropsSetting.filter((record) => record.sk.startsWith(selectedCropType));
    if (productProcessor.processingCrops && productProcessor.processingCrops[selectedCropType]) {
      localGenCrops[selectedCropType] = localGenCrops[selectedCropType].filter((item) => !productProcessor.processingCrops[selectedCropType].includes(item.sk));
    }
    // localGenCrops = productProcessor.processingCrops[selectedCropType] ? localGenCrops[selectedCropType].filter((item) => !productProcessor.processingCrops[selectedCropType].includes(item.sk)) : localGenCrops;
    // const crops = await getCrops(getFromGlobalState, setGlobalState, setSnack, selectedCropType);
    // const processedCrops = crops[selectedCropType];
    this.setState({
      selectedCropType, changed: true, localGenCrops,
    });
  }

  handleToggle = (sk) => {
    const cropType = sk.substring(0, sk.indexOf('_setchild_'));
    const { productProcessor, localGenCrops } = this.state;
    const { getFromGlobalState } = this.props;
    const newProductProcessor = { ...productProcessor };
    const newLocalGenCrops = { ...localGenCrops };
    if (!newProductProcessor.processingCrops) {
      newProductProcessor.processingCrops = {};
    }
    if (!newProductProcessor.processingCrops[cropType]) {
      newProductProcessor.processingCrops[cropType] = [];
    }
    const currentIndex = newProductProcessor.processingCrops[cropType].findIndex((item) => item === sk);

    if (currentIndex === -1) {
      newProductProcessor.processingCrops[cropType].push(sk);
      const idx = newLocalGenCrops[cropType].length ? newLocalGenCrops[cropType].findIndex((f) => f.sk === sk) : -1;
      if (idx !== -1) {
        newLocalGenCrops[cropType].splice(idx, 1);
      }
    } else {
      newProductProcessor.processingCrops[cropType].splice(currentIndex, 1);
      if (newProductProcessor.processingCrops[cropType].length === 0) {
        delete newProductProcessor.processingCrops[cropType];
      }
      if (Object.keys(newProductProcessor.processingCrops).length === 0) {
        newProductProcessor.processingCrops = null;
      }
      const idx = newLocalGenCrops[cropType] && newLocalGenCrops[cropType].length ? newLocalGenCrops[cropType].findIndex((f) => f.sk === sk) : -1;
      if (idx === -1) {
        const cropsSetting = getFromGlobalState('cropsSetting');
        const setting = cropsSetting.find((c) => c.sk === sk);
        if (!newLocalGenCrops[cropType]) {
          newLocalGenCrops[cropType] = [];
        }
        newLocalGenCrops[cropType].push(setting);
      }
    }
    this.setState({ productProcessor: newProductProcessor, localGenCrops: newLocalGenCrops, changed: true });
  }

  handleCheckChange = (event) => {
    const { productProcessor } = this.state;
    const newProductProcessor = { ...productProcessor };
    newProductProcessor[event.target.id] = event.target.checked;
    this.setState({ productProcessor: newProductProcessor, changed: true });
  }

  handleCountryChange = (event) => {
    const { productProcessor } = this.state;
    const newProductProcessor = { ...productProcessor };
    if (newProductProcessor.country !== event.target.value && newProductProcessor.province) {
      newProductProcessor.province = '';
    }
    newProductProcessor[event.target.id] = event.target.value;
    const countryFilter = countryData.filter((p) => p.countryName === event.target.value);
    const regions = countryFilter.length > 0 ? countryFilter[0].regions : [];
    this.setState({ productProcessor: newProductProcessor, changed: true, regions });
  }

  validateField(field) {
    const { productProcessor, errors } = this.state;
    const error = validate(field, productProcessor, requiredFields);
    const newErrors = { ...errors };
    newErrors[field] = error.msg;
    this.setState({ errors: newErrors });
  }

  render() {
    const {
      classes, open, isNew, setClose, metaData, getFromGlobalState,
    } = this.props;
    const {
      productProcessor, errors, changed, regions, selectedCropType, localGenCrops,
    } = this.state;
    const formValid = validateForm(productProcessor, requiredFields);
    const processorTypes = isNew ? metaData && metaData.filter((f) => f.enabled) : metaData;
    const cropTypes = getFromGlobalState('cropTypesSetting').filter((c) => c.enabled);

    return (

      <GenDialog
        open={open}
        dialogObject={productProcessor}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={this.handleUpdate}
        skPrimary="_setchild_"
        dynamoType="productProcessors"
        valid={formValid}
        changed={changed}
        newTitle="New Pocessor"
        parentKey={productProcessor.productProcessorType}
        title={productProcessor.name}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={productProcessor.enabled}
                 // disabled={isInvite}
                  id="enabled"
                  onChange={this.handleCheckChange}
                  value="enabled"
                  color="secondary"
                />
        )}
              label="Enabled"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="unitNum">Processor Type</InputLabel>
              <Select
                native
                fullWidth
                required
                displayEmpty
                value={productProcessor.productProcessorType}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                inputProps={{
                  name: 'productProcessorType',
                  id: 'productProcessorType',
                }}
              >
                <option value="" />
                {processorTypes && processorTypes.map((pt) => (
                  <>
                    <option value={pt.sk}>{pt.name}</option>
                  </>
                ))}
              </Select>
              {errors.productProcessorType && <FormHelperText className={classes.helperText}>{errors.productProcessorType}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor="country">Country</InputLabel>
              <Select
                native
                fullWidth
                margin="normal"
                required
                displayEmpty
                value={productProcessor.country}
                onChange={this.handleCountryChange}
                onBlur={this.handleBlur}
                error={errors.country ? true : undefined}
                inputProps={{
                  name: 'country',
                  id: 'country',
                }}
              >
                <option aria-label="empty" value="" />
                {countryData && countryData.map((country) => (
                  <>
                    <option value={country.countryName}>{country.countryName}</option>
                  </>
                ))}
              </Select>
              {errors.country
              && <FormHelperText className={classes.helperText}>{errors.country}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor="Province">Province</InputLabel>
              <Select
                native
                fullWidth
                required
                displayEmpty
                margin="normal"
                value={productProcessor.province}
                disabled={!productProcessor.province && regions.length < 1}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={errors.province ? true : undefined}
                inputProps={{
                  name: 'province',
                  id: 'province',
                }}
              >
                <option aria-label="empty" value="" />
                {regions && regions.map((region) => (
                  <>
                    <option value={region.name}>{region.name}</option>
                  </>
                ))}
              </Select>
              {errors.province
  && <FormHelperText className={classes.helperText}>{errors.province}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={productProcessor.name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="unitNum">Crop Types</InputLabel>
              <Select
                native
                fullWidth
                required
                displayEmpty
                value={selectedCropType}
                onChange={this.handleCropTypeChange}
                onBlur={this.handleBlur}
                inputProps={{
                  name: 'cropTypes',
                  id: 'cropTypes',
                }}
              >
                <option value="" />
                {cropTypes && cropTypes.map((pt) => (
                  <>
                    <option value={pt.sk}>{pt.name}</option>
                  </>
                ))}
              </Select>
              {errors.productProcessorType && <FormHelperText className={classes.helperText}>{errors.productProcessorType}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <Typography>Available Crops</Typography>
              <SingleListControl
                data={localGenCrops[selectedCropType]}
                hideCheck
          // onBlur={this.handleBlur}
                checkedItems={productProcessor.processingCrops ? productProcessor.processingCrops[selectedCropType] : []}
                handleToggle={this.handleToggle}
                arrayName="processedCrops"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography>Slected Crops</Typography>
            {productProcessor.processingCrops && Object.keys(productProcessor.processingCrops).map((key) => (
              <>
                {productProcessor.processingCrops[key].length > 0
                && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControl className={classes.formControl}>
                    <Typography>{cropTypes.find((c) => c.sk === key).name}</Typography>
                    <ChipControl
                      onBlur={this.handleBlur}
                      name={key}
                      id={key}
                      chipData={productProcessor.processingCrops && productProcessor.processingCrops[key] ? productProcessor.processingCrops[key].map((s) => {
                        const ob = {};
                        ob.sk = s;
                        const cropsSetting = getFromGlobalState('cropsSetting');
                        ob.name = cropsSetting ? getFromGlobalState('cropsSetting').find((r) => r.sk === s).name : '';
                        return ob;
                      }) : []}
                      handleDelete={this.handleToggle}
                    />
                    <FormHelperText className={classes.helperText}>{errors.observableOn}</FormHelperText>
                    {errors.processingCrops && <FormHelperText className={classes.helperText}>{errors.processingCrops}</FormHelperText>}
                  </FormControl>
                </Grid>
                )}

              </>
            ))}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              value={productProcessor.description}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.discription ? true : undefined}
              helperText={errors.discription}
            />
          </Grid>
        </Grid>
      </GenDialog>

    );
  }
}
ProductProcessorD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedSetting: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(ProductProcessorD);
