import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
});

const getTotal = (packingEquips, packEquip, units) =>{
  if (!packingEquips) {return 0;}
  if (!packEquip) {return 0;}
  if (!units) {return 0;}

  let equip = packingEquips.find(p=>p.sk===packEquip);
  if (!equip) {return undefined;}
  return units*equip.capacity;
};

function PackingForm(props) {
  const { classes, record, errors, handleBlur, handleChange, metaData } = props;
  const packingEquips = metaData;
  record.packTotal=getTotal(packingEquips,record.packEquip,record.packUnits);
  record.wasteTotal=getTotal(packingEquips,record.wasteEquip,record.wasteUnits);
  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start" >
      <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>
        <TextField
    margin="normal"
    required
    fullWidth
    type="date"
    //defaultValue={new Date()}
    id="date"
    label="Packing Date"
    name="date"
    InputLabelProps={{
      shrink: true,
    }}
    value={record.date}
    onChange={handleChange.bind(this)}
    onBlur={handleBlur}
    error={errors.date ? true : undefined}
    helperText={errors.date}
  />
  </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
       <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="packEquip">Packing Unit</InputLabel>
          <Select
          native
          fullWidth
          required
          displayEmpty
          value={record.packEquip}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur.bind(this)}
          error={errors.packEquip? true : undefined}
          inputProps={{
            name: 'packEquip',
            id: 'packEquip',
          }}
        >
          <option value=""></option>
          {packingEquips && packingEquips.map ((heq) => 
          <>
          <option value={heq.sk}>{heq.name}</option>
          </>
     )}
        </Select>
        {errors.packingEquip&& <FormHelperText className={classes.helperText}>{errors.packingEquip}</FormHelperText>}
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
        margin="normal"
        required
        fullWidth
        type="number"
        inputProps={{ 'min': '0.00001', 'step':'any' }}
        id="packUnits"
        label="Total Pack Units"
        name="packUnits"
        value={record.packUnits}
        onChange={handleChange.bind(this)}
        onBlur={handleBlur.bind(this)}
        error={errors.packUnits ? true : undefined}
        helperText={errors.packUnits}
      /> 
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
       <TextField
        margin="normal"
        required
        fullWidth
        type="number"
        inputProps={{ 'min': '0.00001', 'step':'any' }}
        id="packTotal"
        label="Total packed in Kg"
        name="packTotal"
        value={record.packTotal}
        onChange={handleChange.bind(this)}
        onBlur={handleBlur.bind(this)}
        error={errors.packTotal ? true : undefined}
        helperText={errors.packTotal}
      />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
<FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="wasteEquip">Waste Unit</InputLabel>
          <Select
          native
          fullWidth
          required
          displayEmpty
          value={record.wasteEquip}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur.bind(this)}
          error={errors.wasteEquip? true : undefined}
          inputProps={{
            name: 'wasteEquip',
            id: 'wasteEquip',
          }}
        >
          <option value=""></option>
          {packingEquips && packingEquips.map ((heq) => 
          <>
          <option value={heq.sk}>{heq.name}</option>
          </>
     )}
        </Select>
        {errors.wasteEquip&& <FormHelperText className={classes.helperText}>{errors.wasteEquip}</FormHelperText>}
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
        margin="normal"
        required
        fullWidth
        type="number"
        inputProps={{ 'min': '0.00001', 'step':'any' }}
        id="wasteUnits"
        label="Total Units"
        name="wasteUnits"
        value={record.wasteUnits}
        onChange={handleChange.bind(this)}
        onBlur={handleBlur.bind(this)}
        error={errors.wasteUnits ? true : undefined}
        helperText={errors.wasteUnits}
      /> 
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
       <TextField
        margin="normal"
        required
        fullWidth
        type="number"
        inputProps={{ 'min': '0.00001', 'step':'any' }}
        id="wasteTotal"
        label="Total waste in Kg"
        name="wasteTotal"
        value={record.wasteTotal}
        onChange={handleChange.bind(this)}
        onBlur={handleBlur.bind(this)}
        error={errors.wasteTotal ? true : undefined}
        helperText={errors.wasteTotal}
      />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

<FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="destination">Destination</InputLabel>
          <Select
          native
          fullWidth
          required
          displayEmpty
          value={record.destination}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur.bind(this)}
          error={errors.destination? true : undefined}
          inputProps={{
            name: 'destination',
            id: 'destination',
          }}
        >
          <option value=""></option>
          <option value="Local">Local</option>
          <option value="Europe">Europe</option>
        </Select>
        {errors.destination&& <FormHelperText className={classes.helperText}>{errors.application}</FormHelperText>}
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
        margin="normal"
        required
        fullWidth
        id="consignment"
        label="Consignment number"
        name="consignment"
        value={record.consignment}
        onChange={handleChange.bind(this)}
        onBlur={handleBlur.bind(this)}
        error={errors.consignment ? true : undefined}
        helperText={errors.consignment}
      /> 
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
        margin="normal"
        fullWidth
        type="text"
        id="deliveryNote"
        label="Delivery Note"
        name="deliveryNote"
        value={record.deliveryNote}
        onChange={handleChange.bind(this)}
        onBlur={handleBlur.bind(this)}
        error={errors.deliveryNote ? true : undefined}
        helperText={errors.deliveryNote}
      /> 
      </Grid>

      
  <Grid item xs={4} sm={4} md={8} lg={8} xl={8}></Grid>
  </Grid>
  );
}

PackingForm.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
};

export default withStyles(styles)(PackingForm);
