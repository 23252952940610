import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'block',
    padding: '0px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    width: '100%',
    display: 'block',
    padding: '0px',
  },
  column1: {
    flexBasis: '60%',
  },
  column2: {
    flexBasis: '30%',
  },
  column2B: {
    flexBasis: '40%',
    paddingLeft: '8px',
    float: 'right',
  },
  column3: {
    flexBasis: '10%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

function ExpansionPanelDetail(props) {
  const {
    classes, handleExpand, children, heading, headingComponent, detailHeading, expandIcon, expanded, type, overwriteDisplay, onClear, onSave, disablePadding, action, detailComponent, disabled,
  } = props;
  let expand = false;
  let newStyle = disablePadding ? { borderBottom: 0, padding: '0px', display: 'block' } : undefined;
  newStyle = overwriteDisplay ? { display: 'block' } : newStyle;
  const expandIconOb = expandIcon || <ExpandMoreIcon />;
  if (type) {
    expand = (expanded === type);
  } else {
    expand = (expanded === heading);
  }
  return (

    <ExpansionPanel
      mountOnEnter
      disabled={disabled}
      style={disablePadding ? {
        borderBottom: 0, boxShadow: 'none', border: 'none', borderTop: 0,
      } : undefined}
      elevation={disablePadding ? 0 : undefined}
      classes={{ root: classes.root }}
      unmountOnExit
      TransitionProps={{ unmountOnExit: true }}
      className={classes.root}
      expanded={expand}
      onChange={(x)=>{
        //console.log(x);
       // console.log(handleExpand);
        handleExpand(heading)
      }}
    >
      <ExpansionPanelSummary
        expandIcon={expandIconOb}
        aria-controls="panel1c-content"
        id="panel1c-header"
        elevation={disablePadding ? 0 : undefined}
        style={disablePadding ? { borderBottom: 0 } : undefined}
      >
        {!headingComponent && (
        <div className={classes.column1}>
          <Typography className={classes.heading}>{heading}</Typography>
        </div>
        )}
        {headingComponent && (
        <div className={classes.column1}>
          {headingComponent}
        </div>
        )}
        {!detailComponent && (
          <div className={classes.column2B}>
            <Typography className={classes.secondaryHeading}>{detailHeading}</Typography>
          </div>
        )}
        {detailComponent && (
          <div className={classes.column2}>
            {detailComponent}
          </div>
        )}
        {expand && action
          && (
          <div className={classes.column3}>
            {action}
          </div>
          )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails elevation={disablePadding ? 0 : undefined} style={newStyle}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>

  );
}
ExpansionPanelDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  handleExpand: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  overwriteDisplay: PropTypes.bool.isRequired,
  detailHeading: PropTypes.string,
  detailComponent: PropTypes.object,
  headingComponent: PropTypes.object,
  type: PropTypes.string.isRequired,
  expandIcon: PropTypes.object,
  expanded: PropTypes.string.isRequired,
  disablePadding: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default withStyles(styles, { withTheme: true })(ExpansionPanelDetail);
