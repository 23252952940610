import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import { getNameFromKey2 } from '../../libs/genLib';
import GenDialog from '../../components/GenDialog';
import { validate, validateForm } from '../../libs/validate';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: '120px',
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
  group: {
    flexDirection: 'row',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },

});

const requiredFields = [
  { name: 'product', min: 1, max: 100 },
  { name: 'inout', min: 1, max: 10 },
  { name: 'amount', min: 1, max: 1000000, type: 'number' },
  { name: 'reason', min: 1, max: 100 },
  { name: 'date', min: 1, max: 100 },
];

class InventoryItemD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      inventoryItem: this.props.inventoryItem || {},
      errors: { count: 0 },
      changed: false,

    };
  }

  componentDidMount() {
    if (this.props.isNew) {
      const today = new Date();
      this.setState({ inventoryItem: { entryType: 'adjustment', date: today.toISOString().substr(0, 10) } });
    } else {
      const { inventoryItem } = this.props;
      this.setState({ inventoryItem });
    }
  }


  validateField(field, requiredFields) {
    const error = validate(field, this.state.inventoryItem, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)


  handleChange = (event) => {
    const inventoryItem = { ...this.state.inventoryItem };
    inventoryItem[event.target.id] = event.target.value;
    this.setState({ inventoryItem, changed: true });
  }

  handleRadioChange = (name, event) => {
    const inventoryItem = { ...this.state.inventoryItem };
    inventoryItem[name] = event.target.value;
    this.setState({ inventoryItem, changed: true });
  }


  render() {
    const {
      classes, open, isNew, setClose, newTitle, handleUpdate, metaData, product, dynamoType, activityName, handleDelete, allowDelete,getFromGlobalState
    } = this.props;
    const { inventoryItem = {}, errors } = this.state;
    const formValid = validateForm(inventoryItem, requiredFields);
    inventoryItem.product = product?.sk;
    if (inventoryItem.price && inventoryItem.amount) {
     inventoryItem.pricePerUnit = inventoryItem.price / inventoryItem.amount;
    }


    let block = null;
    let planting = null;
    let activities = null;
    let activity = null;
    if (inventoryItem.entryType && inventoryItem.entryType === 'application') {
      inventoryItem.inout = inventoryItem.inout ? inventoryItem.inout : 'out';
      if (inventoryItem.block) {
        block = metaData.blocks.find((b) => b.sk === inventoryItem.block);
        if (block) {
          inventoryItem.reason = inventoryItem.reason ? inventoryItem.reason : `Application: ${block.number}`;
          if (inventoryItem.planting) {
            planting = block.plantings.find((p) => p.sk === inventoryItem.planting);
            if (planting[activityName]) {
              activities = planting[activityName].filter((s) => s.product === product.sk && (!isNew || s.completed === false));
              if (inventoryItem.activity) {
                activity = activities.find((a) => a.sk === inventoryItem.activity);
                inventoryItem.amount = isNew ? activity.calculatedProduct : inventoryItem.amount;
              }
            }
          }
        }
      }
    }

    return (

      <GenDialog
        open={open}
        dialogObject={inventoryItem}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        handleDeleteDialogObject={handleDelete.bind(this)}
        allowDelete={allowDelete}
        disableDelete={activity && activity.completed}
        skPrimary="init_inventory_"
        dynamoType={dynamoType}
        valid={formValid}
        changed={this.state.changed}
        newTitle={newTitle ? newTitle : "New Entry"}
        title={newTitle ? newTitle : "New Entry"}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          {inventoryItem.entryType && inventoryItem.entryType === 'adjustment'
      && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl component="fieldset" className={classes.formControlRadio}>
              <FormLabel component="label" required>Inventory In / Out</FormLabel>
              <RadioGroup

                aria-label="Direction"
                name="inout"
                id="inout"
                className={classes.group}
                value={inventoryItem.inout}
                onChange={this.handleRadioChange.bind(this, 'inout')}
              >
                <FormControlLabel value="in" control={<Radio />} label="in" />
                <FormControlLabel value="out" control={<Radio />} label="out" />
                {errors.inout && <FormHelperText className={classes.helperText}>{errors.inout}</FormHelperText>}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

            <TextField
              margin="normal"
              required
              fullWidth
              type="number"
              inputProps={{ min: '0.00001', step: 'any' }}
              id="amount"
              label={`Amount in ${(product && (product.unit))}`}
              name="amount"

              value={inventoryItem.amount}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.amount ? true : undefined}
              helperText={errors.amount}
            />
            

          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor="reason">Reason</InputLabel>
              <Select
                native
                fullWidth
                required
                displayEmpty
                value={inventoryItem.reason}
                onChange={this.handleChange.bind(this)}
                onBlur={this.handleBlur.bind(this)}
                error={errors.reason ? true : undefined}
                inputProps={{
                  name: 'reason',
                  id: 'reason',
                }}
              >
                <option value="" />
                {inventoryItem.inout && inventoryItem.inout === 'in' && <>
                <option value="New Stock">New Stock</option> 
                <option value="Stock Return">Stock Return</option></> }
                {inventoryItem.inout && inventoryItem.inout === 'out' && (
                  <>
                    {' '}
                    <option value="Chemical Application">Application</option>
                    <option value="Stock Return to supplier">Stock Return to supplier</option>
                    <option value="Stock Exired">Stock Expired</option>

                  </>
                ) }
                <option value="Stock Adjustment">Stock Adjustment</option>

              </Select>
              {errors.reason && <FormHelperText className={classes.helperText}>{errors.reason}</FormHelperText>}
            </FormControl>
          </Grid>
        </>
      )}

          {!isNew && inventoryItem.entryType && inventoryItem.entryType === 'application'
          && (
            <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>{`Block ${getNameFromKey2(inventoryItem.block, metaData.blocks,'number')}, ${getNameFromKey2(planting.crop, metaData.crops)}, ${activity.date}, ${product.name}`}</Typography>
              <Typography>{`Application: ${(activity.completed ? 'Completed' : 'Busy')}`}</Typography>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel shrink htmlFor="block">Block</InputLabel>
                  <Select
                    native
                    fullWidth
                    required
                    displayEmpty
                    value={inventoryItem.block}
                    onChange={this.handleChange.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    error={errors.block ? true : undefined}
                    inputProps={{
                      name: 'block',
                      id: 'block',
                    }}
                  >
                    <option value="" />
                    {metaData.blocks && metaData.blocks.map((block) => (
                      <>
                      <option value={block.sk}>{block.number}</option>
                      </>
                    ))}

                  </Select>
                  {errors.block && <FormHelperText className={classes.helperText}>{errors.block}</FormHelperText>}
                </FormControl>
              </Grid>

              {inventoryItem.block
               && (
               <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                 <FormControl fullWidth className={classes.formControl}>
                   <InputLabel shrink htmlFor="planting">Planting</InputLabel>
                   <Select
                     native
                     fullWidth
                     required
                     displayEmpty
                     value={inventoryItem.planting}
                     onChange={this.handleChange.bind(this)}
                     onBlur={this.handleBlur.bind(this)}
                     error={errors.planting ? true : undefined}
                     inputProps={{
                       name: 'planting',
                       id: 'planting',
                     }}
                   >
                     <option value="" />
                     {block && block.plantings && block.plantings.map((planting) => (
                       <>
                         <option value={planting.sk}>{getNameFromKey2(planting.crop, metaData.crops)}</option>
                       </>
                     ))}

                   </Select>
                   {errors.planting && <FormHelperText className={classes.helperText}>{errors.planting}</FormHelperText>}
                 </FormControl>
               </Grid>
               )}

              {inventoryItem.planting
               && (
               <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                 <FormControl fullWidth className={classes.formControl}>
                   <InputLabel shrink htmlFor="activity">Application</InputLabel>
                   <Select
                     native
                     fullWidth
                     required
                     displayEmpty
                     value={inventoryItem.activity}
                     onChange={this.handleChange.bind(this)}
                     onBlur={this.handleBlur.bind(this)}
                     error={errors.activity ? true : undefined}
                     inputProps={{
                       name: 'activity',
                       id: 'activity',
                     }}
                   >
                     <option value="" />
                     {activities && activities.map((activity) => (
                       <>
                         <option value={activity.sk}>{`${activity.date}, ${product.name}, ${activity.calculatedProduct} ${activity.productUnit}`}</option>
                       </>
                     ))}

                   </Select>
                   {errors.activity && <FormHelperText className={classes.helperText}>{errors.activity}</FormHelperText>}
                 </FormControl>
               </Grid>
               )}*/}
              {inventoryItem.activity
               && (
               <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                 <TextField
                   margin="normal"
                   required
                  // fullWidth
                   type="number"
                   inputProps={{ min: '0.00001', step: 'any' }}
                   id="amount"
                   label={`Amount in ${(product && (product.unit))}`}
                   name="amount"
                   disabled={activity && activity.completed}

                   value={inventoryItem.amount}
                   onChange={this.handleChange}
                   onBlur={this.handleBlur}
                   error={errors.amount ? true : undefined}
                   helperText={errors.amount}
                 />
               </Grid>
               )}
              

            </> 
          )}

{inventoryItem.inout && inventoryItem.amount && inventoryItem.inout === 'in' && inventoryItem.reason === 'New Stock'
               && (<>
               <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                 <TextField
                   margin="normal"
                   required
                  // fullWidth
                   type="number"
                   inputProps={{ min: '0.00001', step: 'any' }}
                   id="price"
                   label={`Total price`}
                   name="price"
                   disabled={activity && activity.completed}

                   value={inventoryItem.price}
                   onChange={this.handleChange}
                   onBlur={this.handleBlur}
                   error={errors.amount ? true : undefined}
                   helperText={errors.amount}
                 />
               </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  margin="normal"
                  required
                  
                 // disabled
                //  fullWidth
                 // type="number"
                 // inputProps={{ min: '0.00001', step: 'any' }}
                  id="pricePerUnit"
                  inputProps={
                    { readOnly: true, }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={`Price per ${(product && (product.unit))}`}
                  name="pricePerUnit"
                  ////disabled={activity && activity.completed}

                  value={inventoryItem.pricePerUnit && inventoryItem.pricePerUnit.toLocaleString("en-ZA", {style:"currency", currency:"ZAR"})}
                  ////onChange={this.handleChange}
                  ////onBlur={this.handleBlur}
                  /////error={errors.amount ? true : undefined}
                  /////helperText={errors.amount}
                />
              </Grid></>
               )}


          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
            // required
              fullWidth
            // type="number"
              id="comment"
              label="Comment"
              name="comment"

              value={inventoryItem.comment}
              disabled={activity && activity.completed}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.comment ? true : undefined}
              helperText={errors.comment}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              type="date"
        // defaultValue={new Date()}
              id="date"
              label="Date"
              name="date"
              disabled={activity && activity.completed}
              InputLabelProps={{
                shrink: true,
              }}
              value={inventoryItem.date}
              onChange={this.handleChange.bind(this)}
              onBlur={this.handleBlur}
              error={errors.date ? true : undefined}
              helperText={errors.date}
            />
          </Grid>
        </Grid>
      </GenDialog>

    );
  }
}
InventoryItemD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  inventoryItem: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete:PropTypes.func,
  allowDelete: PropTypes.bool,
  metaData: PropTypes.array,
  product: PropTypes.object,
  dynamoType: PropTypes.string.isRequired,
  activityName: PropTypes.string.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  newTitle: PropTypes.string.isRequired,

};
export default withStyles(styles)(InventoryItemD);
