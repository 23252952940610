import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GenDialog from "../../components/GenDialog";
import Report from '../../components/Report';



const styles = theme => ({
    paper: {
      marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      display: 'flex',
    flexWrap: 'wrap',
    },
    formControl: {
      minWidth: 220,
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    helperText :{
      color: 'red',
    },
    
  });

  
/*const requiredFields = [
    {name:'crop', min: 1, max: 40},
    {name:'variety', min: 1, max: 40},
    {name:'plantSpacing', min: 1, max: 40},
    {name:'plantDensity', min: 1, max: 40},
    {name:'plantDate', min: 1, max: 40},

    
  ];*/


class ReportD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      record: {},
      errors: {count:0},
      
    
    };
  }
  
  componentDidMount() {
    
  }
  
   handleSelect = (event, product, selectedInventoryItemID,actionType,handleSelect) =>{
    
 
  }
  
  render() {
    const { open, isNew, setClose,recordType, title,farm, planting, getFromGlobalState,block } = this.props;
    
   

    return (
 
      <GenDialog
      open={open}
      dialogObject={undefined}
      setClose={setClose}
      isNew={isNew}
      disableActions
      handleUpdateDialogObject={undefined}
      skPrimary={undefined}
      dynamoType={undefined}
      valid={undefined}
      title={title}
      maxWidth='lg'
      parentKey={undefined}
      plantingKey={undefined}
      getFromGlobalState={getFromGlobalState}
      >
        <Report 
              //displayToolbar
              recordType={recordType}
              planting={planting}
              block={block}
              farm={farm}
              handleSelect={this.handleSelect.bind(this)}
              getFromGlobalState={getFromGlobalState}
              showAll
              />

      </GenDialog>
     
    );
  }
}
ReportD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  recordType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  planting: PropTypes.array.isRequired,
  block:PropTypes.array.isRequired,
  farm:PropTypes.object.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  showAll:PropTypes.bool,

};
export default withStyles(styles)(ReportD);
