import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import GenDialog from '../../components/GenDialog';
import { getNameFromKey2 } from '../../libs/genLib';
import { validate, validateForm } from '../../libs/validate';
import { generateTargetsRows, generateTargetsRowsByTypeArr } from '../../libs/analytics';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 220,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },
  group: {
    //  display: 'flex';
    // flex-wrap: 'wrap';
    flexDirection: 'row',
  },

});

const requiredFields = [
  { name: 'targetType', min: 1, max: 100 },
  { name: 'target', min: 1, max: 100 },
  { name: 'phi', min: 1, max: 100 },
  { name: 'dosage', min: 1, max: 100 },
  { name: 'dosageUnit', min: 1, max: 100 },
  {
    name: 'reentry', min: 0, max: 1000, type: 'number', condition: { name: 'reentryOverride', value: false },
  },
 // { name: 'targets', min: 1, max: 500, type: 'array' },
 // { name: 'dosageUnit', min: 1, max: 40 },
 // { name: 'dosage', min: 1, max: 200 },
  // {name:'valper100l',min:1,max:40},
  // {name:'valperUnit',min:1,max:40},
  // {name:'totalUnit', min: 1, max: 40},
 // {
 //   name: 'reentry', min: 0, max: 1000, type: 'number', condition: { name: 'reentryOverride', value: false },
 // },
];

class ChemCropD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      chemCropTarget: {},
      errors: { count: 0 },
      changed: false,
      expandedTarget: '',

    };
  }

  componentDidMount() {
    if (this.props.isNew) {
      this.setState({ chemCropTarget: { reentryOverride: false } });
    } else {
      this.setState({ chemCropTarget: this.props.chemCropTarget });
    }
  }

  validateField(field, requiredFields) {
    const error = validate(field, this.state.chemCropTarget, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleRadioChange = (name, event) => {
    const chemCrop = { ...this.state.chemCropTarget };
    chemCrop[name] = event.target.value;
    this.setState({ chemCrop, changed: true });
  }


  handleChange = (event) => {
    const chemCropTarget = { ...this.state.chemCropTarget };
    chemCropTarget[event.target.id] = event.target.value;
    this.setState({ chemCropTarget, changed: true });
  }

  handleCheckboxChange = (event) => {
    const chemCropTarget = { ...this.state.chemCropTarget };
    chemCropTarget[event.target.id] = event.target.checked;
    this.setState({ chemCropTarget, changed: true });
  }

  filterByCrop = (targets, crop) => {
    if (!targets) {
      return [];
    }
    let rows = targets.filter((f) => f.cropSpecific === 'Yes' && f.observableOnSk === crop);
    rows = rows.concat(targets.filter((t) => t.cropSpecific === 'No'));
    return rows;

  }

  render() {
    const {
      classes, open, isNew, chemical, setClose, handleUpdate, parent, metaData, getFromGlobalState, crop, alreadySet,
    } = this.props;
    const { chemCropTarget, errors } = this.state;
   // const crops = metaData.filteredCrops;
    const rows = generateTargetsRows(metaData.monitorings, getFromGlobalState);
    const targetsByType = generateTargetsRowsByTypeArr(rows, getFromGlobalState);
   
   // const targetTypes = getFromGlobalState('targetsTypesSetting');
   const targets = chemCropTarget.targetType ? targetsByType.find((t) => t.sk === chemCropTarget.targetType).targetsList : [];
   const targetsFiltered = this.filterByCrop(targets, crop);
   //filter targetsFiltered by alreadySet
   const targetsFilteredSet = !isNew ? targetsFiltered : targetsFiltered.filter((t) => !alreadySet.find((a) => a.targetSK === t.sk) && t.isDisabled !== "Yes" );
   
   const subTitle = getNameFromKey2(chemical.sk, getFromGlobalState('chemicals'));
    // console.log('crops!!!!',crops);
    
    const formValid = validateForm(chemCropTarget, requiredFields);

    return (

      <GenDialog
        open={open}
        dialogObject={chemCropTarget}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary="_setchild_"
        dynamoType="chemcropsTargets"
        valid={formValid}
        changed={this.state.changed}
        newTitle="New Target"
        //subTitle={getNameFromKey2(parent.sk, getFromGlobalState('chemicals'))}
        subTitle={subTitle}
        title={!isNew && getNameFromKey2(chemCropTarget.target, targets)}
        parentKey={parent.sk}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="crop">Target type</InputLabel>
              <Select
                native
          // fullWidth
                required
                displayEmpty
                value={chemCropTarget.targetType}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={errors.targetType ? true : undefined}
                inputProps={{
                  name: 'targetType',
                  id: 'targetType',
                }}
              >
                <option value="" />
                {targetsByType && targetsByType.map((type) => (
                  <>
                    <option value={type.sk}>{type.name}</option>
                  </>
                ))}

              </Select>
              {errors.targetType && <FormHelperText className={classes.helperText}>{errors.targetType}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="crop">Target</InputLabel>
              <Select
                native
          // fullWidth
                required
                disabled={!chemCropTarget.targetType}
                displayEmpty
                value={chemCropTarget.target}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={errors.target ? true : undefined}
                inputProps={{
                  name: 'target',
                  id: 'target',
                }}
              >
                <option value="" />
                {targetsFilteredSet && targetsFilteredSet.map((target) => (
                  <>
                    <option value={target.sk}>{target.name}</option>
                  </>
                ))}

              </Select>
              {errors.target && <FormHelperText className={classes.helperText}>{errors.target}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        margin="normal"
                        disabled={chemCropTarget.reentryOverride}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">days</InputAdornment>,
                          min: '0.00001',
                          step: 'any',
                        }}
                        id="reentry"
                        label="Re-entry Period"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="reentry"
                        // disabled={!!checkedItems.find((p) => p.sk === value.sk).reentryOverride}
                        value={chemCropTarget.reentry}
                        onChange={(event) => this.handleChange(event)}
                        onBlur={this.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControlLabel
                        value="reentryOverride"
                        label="or deposit dry"
                        labelPlacement="end"
                        //disabled={readOnly}
                        control={(
                          <Checkbox
                            color="secondary"
                            name="reentryOverride"
                            id="reentryOverride"
                            checked={chemCropTarget.reentryOverride}
                            onClick={(event) => this.handleCheckboxChange(event)}
                          />
)}

                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        margin="normal"
                        required
                        //disabled={readOnly}
                        type="number"
                       // inputProps={{ min: '0.00001', step: 'any' }}
                        id="phi"
                        label="Pre-harvest interval (PHI)"
                        name="phi"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">days</InputAdornment>,
                          min: '0.00001',
                          step: 'any',
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={chemCropTarget.phi}
                        onChange={(event) => this.handleChange(event)}
                      />

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        margin="normal"
                        required
                        id="dosage"
                        label="Dosage"
                       // disabled={readOnly}
                        name="valperUnit"
                        type="number"
                        value={chemCropTarget.dosage}
                        onChange={(event) => this.handleChange(event)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="cdosagerop">Dosage Unit</InputLabel>
                        <Select
                          native
                       //   disabled={readOnly}
          // fullWidth
                          required
                          displayEmpty
                          value={chemCropTarget.dosageUnit}
                          onChange={(event) => this.handleChange(event)}
                          onBlur={this.handleBlur}
                          error={errors.dosageUnit ? true : undefined}
                          inputProps={{
                            name: 'dosageUnit',
                            id: 'dosageUnit',
                          }}
                        >
                          <option value="" />
                          <option value="ml/ha">ml/ha</option>
                          <option value="L/ha">L/ha</option>
                          <option value="g/ha">g/ha</option>
                          <option value="kg/ha">kg/ha</option>
                          <option value="/ha">/ha</option>
                          <option value="/trap">/trap</option>
                          <option value="g/L">g/L</option>
                          <option value="g/100L">g/100L</option>
                          <option value="ml/100L">ml/100L</option>
                          <option value="L/100L">L/100L</option>
                          <option value="/m2">/m2</option>
                          <option value="ml/plant">ml/plant</option>
                          <option value="g/plant">g/plant</option>

                        </Select>
                         {errors.dosageUnit && <FormHelperText className={classes.helperText}>{errors.dosageUnit}</FormHelperText>}
                      </FormControl>
                    </Grid>

         
          {/* } <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            margin="normal"
            required
            type="number"
            inputProps={{ 'min': '0.00001', 'step':'any' }}
           fullWidth
            id="valper100l"
            label={`Dosage (${parent.unit} per 100 Liter)`}
            name="valper100l"

            value={chemCrop.valper100l}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.valper100l ? true : undefined}
            helperText={errors.valper100l}
          />
          </Grid> */}

          {/* }  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl component="fieldset" className={classes.formControlRadio}>
        <FormLabel required >Per</FormLabel>
        <RadioGroup

          aria-label="totalUnit"
          name="totalUnit"
          id="totalUnit"
          className={classes.group}
          value={chemCrop.totalUnit}
          onChange={this.handleRadioChange.bind(this,'totalUnit')}
        >
          <FormControlLabel value="Plant" control={<Radio />} label="Plant" />
          <FormControlLabel  value="Hectare" control={<Radio />} label="Hectare" />
          {errors.totalUnit&& <FormHelperText className={classes.helperText}>{errors.totalUnit}</FormHelperText>}
        </RadioGroup>
      </FormControl>
        </Grid> */}

        </Grid>
      </GenDialog>

    );
  }
}
ChemCropD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  chemCropTarget: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  parent: PropTypes.object.isRequired,
  metaData: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,
  crop: PropTypes.string,
  alreadySet:PropTypes.array.isRequired,
  chemical:PropTypes.object.isRequired,

};
export default withStyles(styles)(ChemCropD);
