const rules = {
  Read: {
    static: ['posts:list', 'home-page:visit'],
  },
  changeSettings: {
    static: [
      'openSettingsPage',
      'openDocumentsPage',
      'openPlantingPage',
    ],
  },
  manageInventory: {
    static: [
      'openInventoryPage',
    ],
  },
  viewDashboard: {
    static: [
      'viewDashboard',
      'viewReports',
    ],
  },
  createActivities: {
    static: [
      'createActivities',
    ],
  },
  allowedActivities: {
    static: [
      'fertilisings',
      'sprayings',
      'waterings',
      'harvestings',
      'pestings',
      'activities',
    ],
  },
  Admin: {
    static: [
      'fertilisings',
      'accessSettings',
      'editFolders',
      'editAsset',
      'submitDialogs',
      'deleteAsset',
      'accessDashboard',
    ],
  },
};

export default rules;


/*

<Can
        role={this.props.getFromGlobalState('userGroup')}
        perform="accessDashboard"
        yes={() => (
          <MenuItem
          classes={{ selected: classes.selected }}
          component={Link}
          to="/dashboard"
          selected={pathname === '/dashboard'}
        >
          <ListItemIcon className={classes.icon}>
            <DashboardIcon className={classes.image} color="inherit" />
          </ListItemIcon>
              Dashboard
        </MenuItem>
          
        )}
        no={() => null}
      />





      export default {
    blocks:'setting',
    varieties:'setting',
    chemcrops:'setting',
    chemequips:'setting',
    chemicals:'setting',
    crops:'setting',
    farm:'setting',
    farms:'setting',
    fertcrops:'setting',
    fertilisers:'setting',
    harvestequips:'setting',
    packequips:'setting',
    pests:'setting',
    monitorings:'setting',
    users:'setting',
    operators: 'setting',
    files: 'setting',
    inv_chemical:'inventory',
    inv_fertiliser:'inventory',
    irrigations:'record',
    packings:'record',
    pestings:'record',
    plantings:'setting',
    sprayings:'record',
    waterings:'record',
    fertilisings:'record',
    harvestings:'record',
    adminSettings:'adminSettings',
    filters: 'record',
  };
*/