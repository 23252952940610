import React , { Component }from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';

class DetailsDialog extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          
        };
      }
    render() {
        const {
            classes, open, isNew, setClose, getFromGlobalState,details,handleClose,farm
          } = this.props;
          console.log(farm);
        return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{farm?.name}</DialogTitle>
        <DialogContent>
        <Grid container className={classes.root} spacing={1} alignItems="flex-end">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label={`Name`}
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={farm?.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="shortName"
              label={`Short Name`}
              name="shortName"
              value={farm?.shortName}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
                margin="normal"
                fullWidth
                label="Production Unit Code (PUC)"
                InputLabelProps={{
                shrink: true,
                }}
                value={farm?.puc}
                disabled
            />
         </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
                margin="normal"
                fullWidth
                label="GLOBALG.A.P. Number"
                InputLabelProps={{
                shrink: true,
                }}
                value={farm?.gapnum}
                disabled
            />
         </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
                margin="normal"
                fullWidth
                label="VAT number"
                InputLabelProps={{
                shrink: true,
                }}
                name="Country"
                value={farm?.vatNo}
                disabled
            />
         </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
                margin="normal"
                fullWidth
                label="Physical Address Line 1"
                InputLabelProps={{
                shrink: true,
                }}
                name="Country"
                value={farm?.address1}
                disabled
            />
         </Grid>
         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
                margin="normal"
                fullWidth
                label="Physical Address Line 2"
                InputLabelProps={{
                shrink: true,
                }}
                name="Country"
                value={farm?.address2}
                disabled
            />
         </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
                margin="normal"
                fullWidth
                label="Country"
                InputLabelProps={{
                shrink: true,
                }}
                name="Country"
                value={farm?.country}
                disabled
            />
         </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
                margin="normal"
                fullWidth
                label="Province"
                InputLabelProps={{
                shrink: true,
                }}
                name="Province"
                value={farm?.province}
                disabled
            />
         </Grid>
         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
                margin="normal"
                fullWidth
                label="Postal Code"
                InputLabelProps={{
                shrink: true,
                }}
                name="pCode"
                value={farm?.pCode}
                disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="contactNum"
                label={`Contact Number`}
                name="contactNum"
                value={farm?.contactNum}
                InputLabelProps={{
                    shrink: true,
              }}
                 disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={`Email`}
                name="email"
                value={farm?.email}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled
            />
          </Grid>
           </Grid>
             </DialogContent>
            </Dialog>
        );
    }
}
export default DetailsDialog;