import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ColorPicker from 'material-ui-color-picker';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Map from '../../components/Map';
import { geoStringToLatLong, changePolygonColors } from '../../libs/genLib';
import InfoBox from '../../components/InfoBox';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';

import { getDownloadUrl } from '../../libs/storage';



const styles = theme => ({
    paper: {
      marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      display: 'flex',
    flexWrap: 'wrap',
    },
    formControl: {
      minWidth: 120,
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    helperText :{
      color: 'red',
    },
    formControlRadio: {
      marginTop: theme.spacing(2),
   },
   group :{
    //  display: 'flex';
    //flex-wrap: 'wrap';
    flexDirection: 'row',
    },
    
  });

  
const requiredFields = [
    {name:'number', min: 1, max: 40},
    {name:'size', min: 1, max: 40},
    {name:'soilType', min: 1, max: 40},
    {name:'slope', min: 1, max: 40},
   // {name:'geoLocation', min: 1, max: 200},
    {name:'irrigated', min: 1, max: 40},
    {name:'irrigationSystem', condition:{name:'irrigated',value:'irrigated'}, min: 1, max: 100},
    {name:'volumePerHour',condition:{name:'irrigated',value:'irrigated'}, min: 1, max: 40},
    {name:'flowunit',condition:{name:'irrigated',value:'irrigated'}, min: 1, max: 40}
  ];


class BlockD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      block: {},
      errors: {count:0},
      changed:false,
      locationLoading:false,
      locale:'en-ZA',
      farmKml: null,
    
    };
  }
  
  componentDidMount() {
    if (this.props.isNew) {
      this.setState({block: {}});
    }
    else {
      this.setState({block: this.props.block});
    }
   // this.setState({ locale:getLocale(this.props.getFromGlobalState('farm')[0]) });
  }
  validateField (field, requiredFields)
  {
      let error = validate(field, this.state.block, requiredFields)
      let errors = {...this.state.errors};
      errors[field] = error.msg;
      this.setState({errors: errors }); 
  }

  handleBlur = (event) => {
    return this.validateField (event.target.id, requiredFields);
  }


  handleChange = (event) => {
    let block = {...this.state.block};
    block[event.target.id] = event.target.value; 
    this.setState({ block:block,changed:true });
  }
  handleRadioChange = (name,event) => {
    let block = {...this.state.block};
    block[name] = event.target.value; 
    this.setState({ block:block,changed:true });
  }
  handleToggle = name => event => {
    let block = {...this.state.block};
    block[name] = event.target.checked; 
    this.setState({ block:block,changed:true });
  };

  handleColorChange = (color) =>{
    let block = {...this.state.block};
    block.color = color; 
    this.setState({ block:block,changed:true });
  }

  onMapClick = (event) => {
    const block = {...this.state.block};
    if (!block.paths) {
      block.paths = [];
    }
    block.paths.push({lat: event.latLng.lat(), lng: event.latLng.lng()});
    this.setState({block});
  }

  blockDrawn = (event) => {
    const block = { ...this.state.block };
    block.paths = [];
    const paths = event.getPath().getArray();
    paths && paths.map((path) => {
      block.paths.push({ lat: path.lat(), lng: path.lng() });
    });
    const size = window.google.maps.geometry.spherical.computeArea(event.getPath());
    block.size = size/10000;
    block.size = block.size.toFixed(4);
    this.setState({ block, changed:true });
  }
  getLogoUrl = async (key, fileType) => {
    const params = {
      activeTenant:this.props.getFromGlobalState('activeSite'),
      type: 'files',
      path: key,
      contentType: fileType,
    };
    return getDownloadUrl(params);
  }



  render() {
    const { classes, userLocation, open, isNew, setClose, handleUpdateBlocks, handleDeleteBlock, getFromGlobalState, farm, otherBlockPolygons } = this.props;
    const {block, errors} = this.state;
    if (!farm[0]) {
      return null;
    }
   const otherPolygons = block && changePolygonColors(otherBlockPolygons, '#d3d3d3', '#FFFFFF', block.sk);
   const center = geoStringToLatLong(farm[0].geoLocation) ? geoStringToLatLong(farm[0].geoLocation) : userLocation;
   const formValid = validateForm(block, requiredFields);
   const idx = otherPolygons ? otherPolygons.findIndex(p => p.sk === block.sk) : -1;
   if (block.paths && idx === -1){
    otherPolygons.push({label: block.number, sk: block.sk, paths: block.paths, color: block.color, clickable: true});
   }
   else if (block.paths) {
    otherPolygons[idx] = {label: block.number, sk: block.sk, paths: block.paths, color: block.color, clickable: true, strokeWeight:2};
   }
   
    return (
      <GenDialog
      open={open}
      dialogObject={block}
      setClose={setClose}
      isNew={isNew}
      handleUpdateDialogObject={handleUpdateBlocks.bind(this)}
      handleDeleteDialogObject={handleDeleteBlock.bind(this)}
      allowDelete
      skPrimary="init_block_"
      dynamoType="blocks"
      changed={this.state.changed}
      valid={formValid}
      newTitle='New Block'
      title={block.number}
      getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-end" >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <InfoBox message='Select a color and map your block using the ploygon tool.'/>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <InputLabel shrink htmlFor="color">Block Color</InputLabel>
        <ColorPicker
        name='color'
        id='color'
        defaultValue='Color'
        value={block.color}
        onChange={color => this.handleColorChange(color)}
      
      />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Map 
        center={center} 
        style={{ height: '500px', width: '100%' }}
        zoom={16}
        mode={'polygon'}
        polygons={otherPolygons}
        selectedPoly={block.sk}
        //onClick={this.onMapClick.bind(this)}
        onPolygonComplete={this.blockDrawn.bind(this)}
        isModalOpen={open}
        userLocation={userLocation}
        />
        </Grid>
       

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="number">Block Number</InputLabel>
         <TextField
            margin="normal"
            required
           // fullWidth
            id="number"
           // label="Block Number"
            name="number"
            style={{minWidth:'200px'}}
           // autoFocus
            value={block.number}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.number ? true : undefined}
            helperText={errors.number}
          />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="size">Size (Hectares)</InputLabel>
          <TextField
            margin="normal"
            required
            style={{minWidth:'200px'}}
            inputProps={{ 'min': '0.00001', 'step':'any' }}
            type="number"
            id="size"
            name="size"
            value={block.size}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.size? true : undefined}
            helperText={errors.size}
          />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="soilType">Soil Type</InputLabel>
          <Select
          native
          style={{minWidth:'200px'}}
          required
          displayEmpty
          value={block.soilType}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.soilType? true : undefined}
          inputProps={{
            name: 'soilType',
            id: 'soilType',
          }}
        >
          <option value=""></option>
          <option value="Clay">Clay</option>
          <option value="Clay loam">Clay loam</option>
          <option value="Loam">Loam</option>
          <option value="Loamy Sand">Loamy Sand</option>
          <option value="Sand">Sand</option>
          <option value="Sandy clay">Sandy clay</option>
          <option value="Sandy clay loam">Sandy clay loam</option>
          <option value="Sandy loam">Sandy loam</option>
          <option value="Silt">Silt</option>
          <option value="Silt loam">Silt loam</option>
          <option value="Silty clay">Silty clay</option>
          <option value="Silty clay loam">Silty clay loam</option>
          <option value="Gravel">Gravel</option>
          <option value="Unknown">Unknown</option>
        </Select>
        {errors.soilType&& <FormHelperText className={classes.helperText}>{errors.soilType}</FormHelperText>}
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="slope">Slope</InputLabel>
          <Select
          native
          style={{minWidth:'200px'}}
          required
          displayEmpty
          value={block.slope}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.slope? true : undefined}
          inputProps={{
            name: 'slope',
            id: 'slope',
          }}
        >
          <option value=""></option>
          <option value="Flat">Flat</option>
          <option value="Gentle">Gentle</option>
          <option value="Gentle to moderate">Gentle to moderate</option>
          <option value="Moderate">Moderate</option>
          <option value="Steep">Steep</option>
          <option value="Unknown">Unknown</option>
        </Select>
        {errors.slope&& <FormHelperText className={classes.helperText}>{errors.slope}</FormHelperText>}
        </FormControl>
        </Grid>
       {/*} <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
         
        <FormControl fullWidth className={classes.formControl}>
      <InputLabel shrink htmlFor="geoLocation">Geolocation</InputLabel>
          <TextField
            margin="normal"
            //required
            style={{minWidth:'200px'}}
            id="geoLocation"
            name="geoLocation"
            value={block.geoLocation}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.geoLocation? true : undefined}
            helperText={errors.geoLocation}
          />
          </FormControl>
         
         </Grid> */}
         {/*<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
          <LoaderFab
                onClick={this.getCurrentLocation}
                success //={this.state.locationLoading && this.state.locationLoaded}
                icon={<GeoIcon />}
                loading={this.state.locationLoading}
                // disabled={!changed || (isSubmitting || !valid)}
              />
        
         
        </Grid>*/}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl component="fieldset" className={classes.formControlRadio}>
        <FormLabel shrink component="legend" required  >Irrigation</FormLabel>
        <RadioGroup
        
          aria-label="irrigated"
          name="irrigated"
          id="irrigated"
          className={classes.group}
          value={block.irrigated}
          onChange={this.handleRadioChange.bind(this,'irrigated')}
        >
          <FormControlLabel value="dryLand" control={<Radio />} label="Dry Land" />
          <FormControlLabel  value="irrigated" control={<Radio />} label="Irrigated" />
          {errors.irrigated&& <FormHelperText className={classes.helperText}>{errors.irrigated}</FormHelperText>}
        </RadioGroup>
      </FormControl>
      </Grid>

         {block.irrigated==='irrigated' && <> 
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
           <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="irrigationSystem">Irrigation Type</InputLabel>
          <Select
          native
          style={{minWidth:'150px'}}
         // required
          displayEmpty
          value={block.irrigationSystem}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.irrigationSystem? true : undefined}
          inputProps={{
            name: 'irrigationSystem',
            id: 'irrigationSystem',
          }}
        >
          <option value=""></option>
          <option value="Dry Land">Dry Land</option>
          <option value="Micro">Micro</option>
          <option value="Pivot">Pivot</option>
          <option value="Drip">Drip</option>
          <option value="Watercart">Watercart</option>
          <option value="Overhead Sprinklers">Overhead Sprinklers</option>
          <option value="Drag Line">Drag Line</option>
          <option value="Ultra Low Flow Drip">Ultra Low Flow Drip</option>
          <option value="Low Flow Drip">Low Flow Drip</option>
          <option value="Flood">Flood</option>
          <option value=""></option>
          
        {/*  {metaData && metaData.map ((irr) => 
          <>
          <option value={irr.sk}>{irr.name}</option>
          </>
        )}*/}
        </Select>
        {errors.irrigationSystem&& <FormHelperText className={classes.helperText}>{errors.irrigationSystem}</FormHelperText>}
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            margin="normal"
           // required
            type="number"
            inputProps={{ 'min': '0.00001', 'step':'any' }}
            style={{minWidth:'150px'}}
            id="volumePerHour"
            label="Liters (per hour)"
            name="volumePerHour"
            value={block.volumePerHour}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.volumePerHour? true : undefined}
            helperText={errors.volumePerHour}
          />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl component="fieldset" className={classes.formControlRadio}>
        <FormLabel shrink component="legend" required  >Per</FormLabel>
        <RadioGroup
        
          aria-label="flowunit"
          name="flowunit"
          id="flowunit"
          className={classes.group}
          value={block.flowunit}
          onChange={this.handleRadioChange.bind(this,'flowunit')}
        >
          <FormControlLabel value="hectare" control={<Radio />} label="Hectare" />
          <FormControlLabel  value="plant" control={<Radio />} label="Plant" />
          {errors.flowunit&& <FormHelperText className={classes.helperText}>{errors.flowunit}</FormHelperText>}
        </RadioGroup>
      </FormControl>
      </Grid>
      </>}
</Grid>
      </GenDialog>
     
    );
  }
}
BlockD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  block: PropTypes.object,
  otherBlockPolygons: PropTypes.array,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  metaData: PropTypes.object.isRequired,
  handleUpdateBlocks: PropTypes.func.isRequired,
  handleDeleteBlock: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  farm: PropTypes.array.isRequired,
  userLocation:PropTypes.object

};
export default withStyles(styles)(BlockD);
