import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Load from '../../components/Load';
import Tabs from '../../components/Tabs';
import { getCircles, getFertiliserCircles, getHarvestingData, getIrrigationData  } from '../../libs/analytics';
import MonitoringDashboard from './recordTypes/monitoring';
import FertilisingDashboard from './recordTypes/fertilising';
import Irrigation from './recordTypes/irrigation';
import Harvesting from './recordTypes/harvesting';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    backgroundColor: 'inherit',
    // overflow: 'scroll',
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  section: {
    margin: theme.spacing(2, 4),
  },

});

class DataDashboard extends Component {
  constructor(props) {
    super(props);
    // this.onMapBlockClick = this.onMapBlockClick.bind(this);

    this.state = {
      monitoringData: [],
      fertilisingData: [],
      selectedTab: 0,
      isLoading: false,
      // selectedTab: '',
      selectedStage: '',
      irrigationData:[],
      harvestingData:[],
      chemicals:[],
    };
  }

  componentDidMount() {
    const { getFromGlobalState, blocks } = this.props;
    this.setState({ isLoading: true });
    // const blocks = getFromGlobalState('blocks');
    const chemicals = getFromGlobalState('chemicals');
    const monitoringSettings = getFromGlobalState('monitorings');
    const targetsTypesSetting = getFromGlobalState('targetsTypesSetting');
    const monitoringData = getCircles(blocks, monitoringSettings, targetsTypesSetting);
    const fertilisingSettings = getFromGlobalState('fertilisers');
    const fertilisingData = getFertiliserCircles(blocks, fertilisingSettings);
    const irrigationData = getIrrigationData(blocks);
    const harvestingData = getHarvestingData(blocks);
    
    this.setState({ isLoading: false, monitoringData, fertilisingData , irrigationData, harvestingData, chemicals});
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const { getFromGlobalState, activeSite, blocks } = this.props;
    if (activeSite !== prevProps.activeSite
      || blocks !== prevProps.blocks) {
      // const blocks = getFromGlobalState('blocks');
      const monitoringSettings = getFromGlobalState('monitorings');
      const targetsTypesSetting = getFromGlobalState('targetsTypesSetting');
      const monitoringData = getCircles(blocks, monitoringSettings, targetsTypesSetting);
      const fertilisingSettings = getFromGlobalState('fertilisers');
      const fertilisingData = getFertiliserCircles(blocks, fertilisingSettings);
      const irrigationData = getIrrigationData(blocks);
      const harvestingData = getHarvestingData(blocks);
      this.setState({ isLoading: false, infoWindow: null, monitoringData, fertilisingData, irrigationData, harvestingData });
    }
  }

  handleTabChange = (event, newValue) => {
    const currentTab = this.state.selectedTab;
    if (newValue !== currentTab) {
      this.setState({ selectedTab: newValue });
    }
  }

  getMonitoringInfo(target, stage, observationData) {
    return (
      <>
        <Typography variant="h6">
          {target.name}
        </Typography>
        <Typography variant="subtitle2">{` Stage: ${stage.stageDescription}`}</Typography>
        <Typography style={{ color: observationData.color }} variant="h6">{` Average: ${observationData.value.toFixed(2)}`}</Typography>
        <Typography variant="subtitle2">{` Priority: ${stage.priority}`}</Typography>
        <Typography variant="subtitle2">{` Date: ${observationData.date}`}</Typography>
        {observationData.threshold && <Typography variant="subtitle2">{` Threshold Value: ${observationData.thresholdValue.toFixed(2)}`}</Typography>}
        {observationData.threshold && <Typography variant="subtitle2">{` Threshold: Low ${observationData.threshold.low} Med ${observationData.threshold.med} High ${observationData.threshold.high}`}</Typography>}

        <Typography variant="subtitle2">{` Recommended points: ${stage.quantityOfUnit}`}</Typography>
        <Typography variant="subtitle2">{` Monitored points: ${observationData.actualObservations}`}</Typography>

        {/* <Link href="#" variant="h6" onClick={this.mapEditBlock.bind(this, block.sk)}>
          Edit
        </Link> */}
      </>
    );
  }

  render() {
    const { classes, handleMonitoringClick, handleFertilisingClick, handlePPPClick, getFromGlobalState } = this.props;
    const {
      monitoringData,
      selectedTab,
      fertilisingData,
      irrigationData,
      harvestingData,
      chemicals
    } = this.state;

    return (

      <Tabs value={selectedTab} handleChange={this.handleTabChange}>
        <MonitoringDashboard
          heading="Monitoring"
          data={monitoringData}
          handleMonitoringClick={handleMonitoringClick}
          chemicals={chemicals}
          getFromGlobalState={getFromGlobalState}
        />
        <FertilisingDashboard
          heading="Fertilising"
          data={fertilisingData}
          handleFertilsingClick={handleFertilisingClick}
        />
       <Irrigation
        heading="Irrigation"
        data={irrigationData}
       />
       <Harvesting
        heading="Harvesting"
        data={harvestingData}
       />
      </Tabs>

    );
  }
}
DataDashboard.propTypes = {
  getFromGlobalState: PropTypes.func.isRequired,
  activeSite: PropTypes.string.isRequired,
  blocks: PropTypes.array.isRequired,
  handleMonitoringClick: PropTypes.func.isRequired,
  handleFertilisingClick: PropTypes.func.isRequired,
  handlePPPClick: PropTypes.func.isRequired,

};
export default withStyles(styles)(DataDashboard);
