import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import uuid from 'uuid';
import {
  toStore, createObject, deleteObject, getErrorMessage,
} from '../../libs/storage';
import Load from '../../components/Load';
import EditTable from '../../components/EditTable';

import LoaderButton from '../../components/LoaderButton';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '5px',
    backgroundColor: 'inherit',
  },
  paper: {
    marginLeft: '4px',
    marginTop: '10px',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
    marginLeft: '4px',
  },
  submit: {
    margin: theme.spacing(1),
  },
});
class CropsAdminForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isloadingTable: false,
      selectedCropType: null,
      filters: {},
      isSubmitting: false,

    };
  }

  componentDidMount() {
    const {
      isAuthenticated,
    } = this.props;
    if (!isAuthenticated) {

    }
  }

  onRowUpdate = async (newData) => {
    const {
      getFromGlobalState, setSnack, setGlobalState,
    } = this.props;
    const { selectedCropType } = this.state;
    const cropsDetail = getFromGlobalState('cropsDetail');
    try {
      const ob = { ...newData };
      ob.version += 1;
      ob.activeTenant = getFromGlobalState('activeSite');

      const creation = await createObject(getFromGlobalState('currentUser').username, 'calls', toStore(ob), ob.activeTenant);
      if (creation.status) {
        ob.cached = creation.cached;
      }
      const cropsDetailNew = [...cropsDetail[selectedCropType]];
      const idx = cropsDetailNew.findIndex((detail) => detail.sk === ob.sk);
      if (idx === -1) {
        setSnack({ open: true, variant: 'error', messageText: 'row not found in local cache - please refresh' });
        return false;
      }
      cropsDetailNew[idx] = ob;
      const cropsDet = { ...cropsDetail };
      cropsDet[selectedCropType] = cropsDetailNew;
      setGlobalState({ cropsDetail: cropsDet });
      return true;
    } catch (e) {
      setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
      return false;
    }
  }

onRowDelete = async (oldData) => {
  const {
    getFromGlobalState, setSnack, setGlobalState,
  } = this.props;
  const { selectedCropType } = this.state;
  const cropsDetail = getFromGlobalState('cropsDetail');
  try {
    const ob = { ...oldData };
    ob.activeTenant = getFromGlobalState('activeSite');

    const deletion = await deleteObject(ob);
    if (!deletion.status) {
      setSnack({ open: true, variant: 'error', messageText: 'failed to delete - please refresh' });
      return false;
    }
    const cropsDetailNew = [...cropsDetail[selectedCropType]];
    const idx = cropsDetailNew.findIndex((detail) => detail.sk === ob.sk);
    if (idx === -1) {
      setSnack({ open: true, variant: 'error', messageText: 'row not found in local cache - please refresh' });
      return false;
    }
    cropsDetailNew.splice(idx, 1);
    const cropsDet = { ...cropsDetail };
    cropsDet[selectedCropType] = cropsDetailNew;
    setGlobalState({ cropsDetail: cropsDet });
    return true;
  } catch (e) {
    setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    return false;
  }
}

   onRowAdd = async (rowData) => {
     const {
       getFromGlobalState, setGlobalState, setSnack,
     } = this.props;
     const cropsDetail = getFromGlobalState('cropsDetail');
     const { selectedCropType } = this.state;
     try {
       const ob = { ...rowData };
       ob.setId = 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3';
       ob.sk = rowData.cropName.concat('_setChild_').concat(uuid.v1());
       ob.isGeneral = true;
       ob.type = 'cropsDetail';
       ob.version = 1;
       ob.activeTenant = getFromGlobalState('activeSite');

       const creation = await createObject(getFromGlobalState('currentUser').username, 'calls', toStore(ob), ob.activeTenant);
       if (creation.status) {
         ob.cached = creation.cached;
       }
       const cropsDetailNew = [...cropsDetail[selectedCropType]];
       cropsDetailNew.push(ob);
       const cropsDet = { ...cropsDetail };
       cropsDet[selectedCropType] = cropsDetailNew;
       setGlobalState({ cropsDetail: cropsDet });
       return true;
     } catch (e) {
       setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
       return false;
     }
   }

   handleEnableToggle = async () => {
     const { selectedCropType } = this.state;
     const { getFromGlobalState, setGlobalState, setSnack } = this.props;
     const cropTypes = getFromGlobalState('cropTypesSetting');
     const cropType = { ...cropTypes.find((c) => c.sk === selectedCropType) };
     if (!cropType.hasOwnProperty('enabled')) {
       cropType.enabled = true;
     } else {
       cropType.enabled = !cropType.enabled;
     }
     try {
       if (!cropType.version) {
         cropType.version = 0;
       }
       cropType.version += 1;
       cropType.activeTenant = getFromGlobalState('activeSite');
       this.setState({ isSubmitting: true });
       const creation = await createObject(getFromGlobalState('currentUser').username, 'calls', toStore(cropType), cropType.activeTenant);
       if (creation.status) {
         cropType.cached = creation.cached;
         const cropTypesNew = [...cropTypes];
         const idx = cropTypesNew.findIndex((p) => p.sk === selectedCropType);
         if (idx !== -1) {
           cropTypesNew[idx] = cropType;
           this.setState({ isSubmitting: false });
           setGlobalState({ cropTypes: cropTypesNew });
         }
       }
     } catch (e) {
       setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
       return false;
     }
   }

onCropTypeChange = async (event) => {
  let { selectedCropType } = this.state;
  const { columns, getCropDetails, setAdminState } = this.props;
  if (selectedCropType === event.target.value) {
    return true;
  }
  selectedCropType = event.target.value;
  this.setState({ selectedCropType, isloadingTable: true });
  const retVal = await getCropDetails(selectedCropType);

  if (retVal && retVal.crops && retVal.crops.length) {
    const columnsNew = this.getHeader(retVal.crops).sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: false }));
    let nCols = { ...columns };
    nCols = columnsNew;
    setAdminState({ columns: nCols });
  }
  this.setState({ isloadingTable: false });
}

getHeader = (crops) => {
  if (!crops) {
    return [];
  }
  const cropLookup = {};

  crops.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: false })).forEach((element) => {
    cropLookup[element.sk] = element.name;
  });

  const typeHeader = [
    {
      title: 'Crop name',
      field: 'cropName',
      validate: (rowData) => (rowData.cropName === '' ? 'Crop name cannot be empty' : ''),
      lookup: cropLookup,
    },
    { title: 'Cultivar type', field: 'cultivarType', width: 100 },
    {
      title: 'Cultivar name',
      field: 'cultivar',
      validate: (rowData) => (rowData.cultivar === '' ? 'Cultivar name cannot be empty' : ''),
    },
    {
      title: 'Rootstock type', field: 'rootStockType',
    },
    {
      title: 'Rootstock', field: 'rootStock',
    },
  ];
  return typeHeader;
}

render() {
  const {
    classes, rows, columns, getFromGlobalState,
  } = this.props;
  const {
    isloadingTable, selectedCropType, isloading, filters, isSubmitting,
  } = this.state;
  const cropTypes = getFromGlobalState('cropTypesSetting');
  const selectedCropTypeOb = cropTypes.find((c) => c.sk === selectedCropType);
  const cropTypeEnabled = selectedCropTypeOb && selectedCropTypeOb.enabled;
  // const columns = data.crops && data.crops[selectedCropType] ? this.getHeader(data.crops[selectedCropType]) : [];

  // const headCells = this.getHeader(crops, filters);
  return (
    <Load isloading={isloading}>
      <Paper elevation={0} style={{ marginLeft: '4px' }} className={classes.paper}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="cropType">Crop Type</InputLabel>
          <Select
            native
            fullWidth
            required
            displayEmpty
            value={selectedCropType}
            onChange={this.onCropTypeChange}
            inputProps={{
              name: 'cropType',
              id: 'cropType',
            }}
          >
            <option value="" />
            {cropTypes && cropTypes.map((cropType) => (
              <>
                <option value={cropType.sk}>{cropType.name}</option>
              </>
            ))}
          </Select>
        </FormControl>
        {selectedCropType && (
        <div style={{ margin: '10px' }}>
          <LoaderButton
            // type="submit"
            onClick={this.handleEnableToggle}
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={(isSubmitting)}
            isloading={isSubmitting}
            text={cropTypeEnabled ? 'Disable' : 'Enable'}
            loadingText={cropTypeEnabled ? 'Disabling...' : 'Enabling...'}
          />
        </div>
        )}
        <EditTable
          headCells={columns}
          title=""
          rows={rows && rows[selectedCropType] ? rows[selectedCropType] : []}
          onRowAdd={selectedCropType && this.onRowAdd}
          onRowUpdate={this.onRowUpdate}
          onRowDelete={this.onRowDelete}
          isLoading={isloadingTable}
          filters={filters}
        />
      </Paper>
    </Load>
  );
}
}
CropsAdminForm.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
  rows: PropTypes.object.isRequired,
  setAdminState: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  getCropDetails: PropTypes.func.isRequired,
};
export default withStyles(styles)(CropsAdminForm);
