import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BlockList from '../../containers/BlockList';






const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 150,
    marginTop: theme.spacing(2),
  },
  formControlC: {
    minWidth: 150,
    margin: theme.spacing(1),
    //marginTop: theme.spacing(2),
  },
});






function BlockSelector(props) {
  const {
    classes, blocks, allSelected, onSelectAll, selectedBlocks, metaData, handleToggle, clusters, selectedCluster, selectedClusterGroup, handleClusterChange, handleClusterGroupChange,
  } = props;

  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start" >
     
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      {clusters && clusters.length && (
        <>
      <FormControl className={classes.formControlC}>
        <InputLabel shrink htmlFor="assignedTo">Cluster groups</InputLabel>
        <Select
          native
          fullWidth
          required
          displayEmpty
          value={selectedClusterGroup}
          onChange={handleClusterGroupChange}
          //onBlur={handleBlur}
          inputProps={{
            name: 'cluster',
            id: 'cluster',
          }}
        >
          <option value="" />
          {clusters && clusters.map((cluster) => (
            <>
              <option key={cluster.sk} value={cluster.sk}>{cluster.name}</option>
            </>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControlC}>
      <InputLabel shrink htmlFor="assignedTo">Cluster</InputLabel>
      <Select
        native
        fullWidth
        required
        displayEmpty
        value={selectedCluster}
        onChange={handleClusterChange}
        //onBlur={handleBlur}
        inputProps={{
          name: 'cluster',
          id: 'cluster',
        }}
      >
        <option value="" />
        {selectedClusterGroup && clusters.find((c) => c.sk === selectedClusterGroup).list.map((cluster) => (
          <>
            <option key={cluster.sk} value={cluster.sk}>{cluster.name}</option>
          </>
        ))}
      </Select>
      </FormControl>
      </>
      )}

    
              <FormControlLabel
          value="start"
          style={{float:'right'}}
          control={<Checkbox
           // edge="start"
            checked={allSelected}
            onChange={onSelectAll}
           // tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': 'SelectAll' }}
          />}
          label="Select All"
          labelPlacement="start"
        />
        </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <BlockList
          blocks={blocks}
          crops={((metaData && metaData.crops) ? metaData.crops : [])}
          checkedItems={selectedBlocks}
          handleToggle={handleToggle}
        />
      </Grid>
    </Grid>
  );
}

BlockSelector.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleToggle: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
 // handleComplexChange: PropTypes.func,
 
  updateParent:PropTypes.func.isRequired,
  blocks: PropTypes.object.isRequired,
  metaData: PropTypes.object.isRequired,
  selectedBlocks: PropTypes.array.isRequired,
  onSelectAll:PropTypes.func.isRequired,
  allSelected:PropTypes.bool.isRequired,
  clusters: PropTypes.array.isRequired,
  handleClusterChange: PropTypes.func.isRequired,
  handleClusterGroupChange: PropTypes.func.isRequired,
  selectedCluster: PropTypes.string.isRequired,
  selectedClusterGroup: PropTypes.string.isRequired,
 
};

export default withStyles(styles)(BlockSelector);
