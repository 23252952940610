import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import uuid from 'uuid';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
    maxHeight: 300,
    minHeight: 300,
    overflowY: 'auto',
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

const requiredFields = [
  { name: 'name', min: 1, max: 40 },
  // { name: 'capacity', min: 1, max: 40 },
];

class ClusterD extends Component {
  constructor(props) {
    super(props);
    this.handleClickDelete = this.handleClickDelete.bind(this);

    this.state = {
      isloading: false,
      // isNew: this.props.isNew,
      cluster: {},
      errors: { count: 0 },
      changed: false,

    };
  }

  componentDidMount() {
    if (this.props.isNew) {
      this.setState({ harvestingEquipment: {} });
    } else {
      const cluster = this.props.selectedSetting;
      this.setState({ cluster });
    }
  }

  validateField(field, requiredFields) {
    const error = validate(field, this.state.cluster, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const cluster = { ...this.state.cluster };
    cluster[event.target.id] = event.target.value;
    this.setState({ cluster, changed: true });
  }

  handleClickAdd = () => {
    const cluster = { ...this.state.cluster };
    if (!cluster.list) {
      cluster.list = [];
    }
    cluster.list.push({ sk: uuid.v1(), name: '' });
    this.setState({ cluster, changed: true });
  }

  handleClickDelete = (id) => {
    const cluster = { ...this.state.cluster };
    if (!cluster.list) {
      return;
    }
    const idx = cluster.list.findIndex((i) => i.sk === id);
    if (idx !== -1) {
      cluster.list.splice(idx, 1);
    }
    this.setState({ cluster, changed: true });
  }

  handleArrayChange = (event) => {
    const cluster = { ...this.state.cluster };
    if (!cluster.list) {
      return;
    }
    const listItem = cluster.list.find((i) => i.sk === event.target.id);
    if (listItem) {
      listItem.name = event.target.value;
    }
    this.setState({ cluster, changed: true });
  }

  render() {
    const {
      classes, open, isNew, setClose, handleUpdate, getFromGlobalState,
    } = this.props;
    const { cluster, errors } = this.state;
    const formValid = validateForm(cluster, requiredFields);

    return (

      <GenDialog
        open={open}
        dialogObject={cluster}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary="init_setting_"
        dynamoType="clusters"
        valid={formValid}
        changed={this.state.changed}
        newTitle="New cluster group"
        title={cluster.name}
        getFromGlobalState={getFromGlobalState}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Cluster group name"
          name="name"
          autoFocus
          value={cluster.name}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.name ? true : undefined}
          helperText={errors.name}
        />

        <Typography variant="subtitle1">
          Add Cluster
          <Tooltip placement="top" title="Add">
            <IconButton
              aria-label="Add"
              color="secondary"
              className={classes.menuButton}
              onClick={this.handleClickAdd.bind(this)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>

        </Typography>

        <FormControl className={classes.formControl}>
          <Typography variant="subtitle1"> Clusters</Typography>
          <List component="nav" aria-label="main mailbox folders">
            {cluster && cluster.list && cluster.list.map((clusterItem) => (
              <ListItem button>
                <TextField onChange={this.handleArrayChange} value={clusterItem.name} id={clusterItem.sk} name={clusterItem.sk} />
                <Tooltip placement="top" title="Delete">
                  <IconButton
                    aria-label="Delete"
                    color="secondary"
                    className={classes.menuButton}
                    id={clusterItem.id}
                    inputProps={{
                      name: clusterItem.sk,
                      id: clusterItem.sk,
                    }}
                   // onClick={this.handleClickDelete.bind(this)}
                    onClick={(e) => this.handleClickDelete(clusterItem.sk)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </FormControl>
        {/*  <TextField
          margin="normal"
            // required
          fullWidth
          id="capacity"
          label="Capacity in Kg"
          name="capacity"
          type="number"
          inputProps={{ min: '0.00001', step: 'any' }}
           // autoFocus
          value={harvestingEquipment.capacity}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.capacity ? true : undefined}
          helperText={errors.capacity}
            /> */}

        {/* }  <FormControl className={classes.formControl}>
          <Typography variant="subtitle1"> Can be used for  *</Typography>

          <SingleListControl

          data= {metaData}
          onBlur={this.handleBlur}
          checkedItems ={chemEquipment.allowedChemicals}
          handleToggle={this.handleToggle}

          />
         <FormHelperText className={classes.helperText}>{errors.allowedChemicals}</FormHelperText>
          {errors.allowedChemicals&& <FormHelperText className={classes.helperText}>{errors.allowedChemicals}</FormHelperText>}
    </FormControl> */}

      </GenDialog>

    );
  }
}
ClusterD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedSetting: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(ClusterD);
