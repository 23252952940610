import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import {
  PieChart, Pie, Legend, Tooltip, Cell, ResponsiveContainer, showLedgend,
} from 'recharts';
import { ColorLuminance, convertHex } from '../../libs/genLib';

const PARENT = '#0e4553';

export default class ChartPie2 extends PureComponent {
  render() {
    const {
      title, data, keys, dataKey, color, showLedgend, showLabel, height, cx, cy, innerRadius, outerRadius,
      legendAlign, legendLayout, legendVerticalAlign,addMargin
    } = this.props;
    const mTop = addMargin ? '16px' : undefined;
    return (
      <Paper style={{ borderRadius: '4px', overflow: 'hidden', marginTop: mTop }}>
        {title && (
        <>
          <div style={{
            width: '100%', justifyContent: 'center', display: 'inline-flex', paddingTop: '5px',
          }}
          >
            <Typography variant="button" gutterBottom>{title}</Typography>

          </div>
         
        </>
        )}

        <ResponsiveContainer width="100%" height={height} style={{ cursor: 'pointer' }}>
          <PieChart width="100%" height="100%">
            <Pie
              data={data}
              cx={cx}
              cy={cy}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              fill="#8884d8"
              paddingAngle={3}
              dataKey={dataKey}
              label={showLabel}
            >
              { data && data.length && data.map((k, i) => <Cell key={`cell-${i}`} fill={convertHex(ColorLuminance(PARENT, -0.4 * i), 70)} />)}

            </Pie>
            {showLedgend && <Legend wrapperStyle={{ fontSize: '12px' }} layout={legendLayout} verticalAlign={legendVerticalAlign} align={legendAlign} />}
            <Tooltip />

          </PieChart>
        </ResponsiveContainer>

      </Paper>

    );
  }
}
ChartPie2.defaultProps = {
  height: 150,
  cx: 70,
  cy: 65,
  innerRadius: 20,
  outerRadius: 40,
  legendLayout: 'vertical',
  legendVerticalAlign: 'middle',
  legendAlign: 'right',
  addMargin: false,

};
ChartPie2.propTypes = {
  data: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  showLedgend: PropTypes.bool.isRequired,
  showLabel: PropTypes.bool.isRequired,
  height: PropTypes.number,
  cx: PropTypes.number,
  cy: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  legendLayout: PropTypes.string,
  legendVerticalAlign: PropTypes.string,
  legendAlign: PropTypes.string,
  addMargin:PropTypes.bool,


};
