import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Grid from '@material-ui/core/Grid';
// import { validate, validateForm } from '../../libs/validate';
import GenModal from '../../components/GenModal';
import { getDownloadUrl } from '../../libs/storage';
// import ListControl from '../../components/ListControl';


const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
 /* title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },*/
  helperText: {
    color: 'red',
  },
  group: {
    //  display: 'flex';
    // flex-wrap: 'wrap';
    flexDirection: 'row',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },

});

class TargetDetailD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      targetStage: this.props.targetStage,
      changed: false,
      //imageUrls: {},

    };
  }

  async componentDidMount() {
    const { getFromGlobalState, setGlobalState } = this.props;
    const imageUrls = { ...getFromGlobalState('imageUrls') };
    const { targetStage } = this.props;
    const newImageUrls = await this.getUrls(targetStage, imageUrls);
   //this.setState({ imageUrls: newImageUrls });
   setGlobalState({ imageUrls: newImageUrls });
  }

  getTargetUrl = async (key, fileType) => {
    const params = {
      activeTenant:this.props.getFromGlobalState('activeSite'),
      type: 'files',
      path: key,
      isGeneral: true,
      setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
      contentType: fileType,
    };
    const url = await getDownloadUrl(params);
    return url;
  }

  getUrls = async (stage, imageUrls) => {
    // const imgObj = {};
    if (!stage) {
      return null;
    }
      if (stage.images) {
        for (const item of stage.images) {
          if (!imageUrls[item.Key]) {
            const url = await this.getTargetUrl(item.Key, item.fileType);
            imageUrls[item.Key] = url;
          }
        }
      }
  
    return imageUrls;
  }

  render() {
    const {
      classes, open, isNew, setClose, handleUpdate, targetStage, getFromGlobalState,onClose,
    } = this.props;
    const { pest, errors } = this.state;
    const imageUrls = getFromGlobalState('imageUrls');
    const isMobile = getFromGlobalState('isMobile');
    // const formValid = validateForm(pest, requiredFields);
    if (!targetStage) {
      return null;
    };


    return (

      <GenModal
        open={true}
        setClose={onClose}
        isMobile={isMobile}
        //title={pest.name}
      >
        <div style={{zIndex: 6000}}>
          <Typography variant="subtitle1">{targetStage && targetStage.procedure ? targetStage.procedure : '' }</Typography>
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.root}>
              <ImageList className={classes.imageList} cols={targetStage && targetStage.images && targetStage.images.length > 1 ? 2.5 : 1}>
                {targetStage && targetStage.images && targetStage.images.map((item, imageIdx) => (
                  <ImageListItem key={item.Key} style={{ minWidth: '220px', height: '220px' }}>
                    {imageUrls && imageUrls[item.Key] ? <img key={item.Key} style={{ objectFit: 'cover', height: '100%' }} alt="target" src={imageUrls[item.Key]} /> : (
                      <div style={{
                        width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                      }}
                      >
                        <CircularProgress color="secondary" />
                      </div>
                    )}
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
          </Grid>
        </Grid>
        </div>
      </GenModal>
    );
  }
}
TargetDetailD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  targetStage: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(TargetDetailD);
