import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Link from '@material-ui/core/Link';
import FarmD from '../FarmD';
import Map from '../../components/Map';
import { geoStringToLatLong } from '../../libs/genLib';
import { getDownloadUrl } from '../../libs/storage';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paperDropperBody: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  heading: {
    marginTop: '4px',
    cursor: 'pointer',
  },
  submit: {
    margin: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(10),
  },
  mainSection: {
    width: '100%',
    flexWrap: 'wrap',
  },
  parentSection: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },

  },
  childSection: {
    marginLeft: theme.spacing(5),
  },
  childSectionChildren: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class FarmDetailForm extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.setClose = this.setClose.bind(this);
    this.handleClickShowFarm = this.handleClickShowFarm.bind(this);
    this.onCloseInfoWindow = this.onCloseInfoWindow.bind(this);
    this.onSelectFarmMarker = this.onSelectFarmMarker.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    const { initialState } = this.props;
    this.state = {
      farm: initialState,
      selectedFarm: null,
      isNew: false,
      farmDialogOpen: false,
      logoURL: '',
      farmMarkerSelected: false,
    };
  }

  async componentDidMount() {
    const { farm } = this.state;
    if (farm[0]) {
      const logoURL = await this.getLogoUrl(farm[0].logoKey, farm[0].logoFileType);
      this.setState({ logoURL });
    }
  }

  handleClickShow = () => {
    this.setState({ farmDialogOpen: true });
  }

  handleBlur = () => {
    // return this.validateField (event.target.id, this.props.requiredFields);
  }

  handleClickAdd() {
    this.setState({ farmDialogOpen: true, isNew: true, selectedFarm: {} });
  }

  handleClickEdit() {
    this.setState({ farmDialogOpen: true, isNew: true });
  }

  handleClickShowFarm() {
    const { getFromGlobalState } = this.props;
    const farm = getFromGlobalState('farm');
    if (!farm[0]) {
      farm[0] = {};
      this.setState({ selectedFarm: farm[0], farmDialogOpen: 'true', isNew: true });
    } else {
      this.setState({ selectedFarm: farm[0], farmDialogOpen: 'true', isNew: false });
    }
  }

  onSelectFarmMarker() {
    this.setState({ farmMarkerSelected: true });
  }

  onCloseMarker() {
    this.setState({ farmMarkerSelected: false });
  }

  onCloseInfoWindow() {
    this.setState({ farmMarkerSelected: false });
  }

  onMapClick() {
    this.setState({ farmMarkerSelected: false });
  }

  setClose() {
    this.setState({ farmDialogOpen: false });
  }

getLogoUrl = async (key, fileType) => {
  const { getFromGlobalState } = this.props;
  const params = {
    activeTenant: getFromGlobalState('activeSite'),
    type: 'files',
    path: key,
    contentType: fileType,
  };
  return getDownloadUrl(params);
}

getFarmInfo(farm) {
  return (
    <>
      <Typography variant="h6">
        {farm.name}
      </Typography>
      <Typography variant="subtitle2">{` Short name: ${farm.shortName} ha`}</Typography>
      <Typography variant="subtitle2">{` Production Unit Code (PUC): ${farm.puc}`}</Typography>
      <Typography variant="subtitle2">{` GLOBALG.A.P. Number: ${farm.gapnum}`}</Typography>
      {farm.vatNo && <Typography variant="subtitle2">{` VAT Number: ${farm.vatNo}`}</Typography>}
      <Link href="#" variant="h6" onClick={this.mapEditFarm.bind(this, farm.sk)}>
        Edit
      </Link>
    </>
  );
}

  handleUpdate = async (farmIn) => {
    const { getFromGlobalState, setGlobalState } = this.props;
    const updatedIndex = getFromGlobalState('farm').findIndex((i) => i.sk === farmIn.sk);
    if (updatedIndex === -1) {
      const newFarm = getFromGlobalState('farm');
      newFarm[0] = farmIn;
      const logoURL = await this.getLogoUrl(farmIn.logoKey, farmIn.logoFileType);
      this.setState({ logoURL });
      setGlobalState({ farm: newFarm });
    } else {
      const newFarm = getFromGlobalState('farm');
      newFarm[updatedIndex] = farmIn;
      const logoURL = await this.getLogoUrl(farmIn.logoKey, farmIn.logoFileType);
      this.setState({ logoURL });
      setGlobalState({ farm: newFarm });
    }
  }

  mapEditFarm() {
    const { getFromGlobalState } = this.props;
    const farm = getFromGlobalState('farm');
    if (!farm[0]) {
      farm[0] = {};
      this.setState({ selectedFarm: farm[0], farmDialogOpen: 'true', isNew: true });
    } else {
      this.setState({ selectedFarm: farm[0], farmDialogOpen: 'true', isNew: false });
    }
  }

  render() {
    const {
      classes, initialState, activeSite, getFromGlobalState,
    } = this.props;
    const {
      farmMarkerSelected, farmDialogOpen, isNew, selectedFarm, logoURL,
    } = this.state;
    const farm = initialState;
    // if (!farm[0]){
    //    return null;
    //  }
    const centerLoc = !farm[0] ? { lat: -30.664413, lng: 23.758869 } : farm[0].geoLocation ? geoStringToLatLong(farm[0].geoLocation) : { lat: -30.664413, lng: 23.758869 };
    // const centerLoc = geoStringToLatLong(farm[0].geoLocation);
    const zoom = !farm[0] ? 2 : farm[0].geoLocation ? 16 : 2;
    // const logoURL = await getLogoUrl
    let prefix = 'Farm';
    if (farm[0] && farm[0].entityType) {
      prefix = farm[0].entityType === 'farm' ? 'Farm' : 'Entity';
    }
    const markers = farm[0] && farm[0].geoLocation ? [{ position: centerLoc, sk: farm[0].key, clickable: true }] : [];
    const infoWindow = farmMarkerSelected ? { label: farm[0].name, position: centerLoc, content: this.getFarmInfo(farm[0]) } : undefined;
    // [{sk:'1', position:centerLoc, label:farmd.name}
    return (
      <Paper elevation={0} className={classes.maninSection}>

        {(farmDialogOpen)
        && (
          <FarmD
            handleUpdate={this.handleUpdate}
            open={farmDialogOpen}
            setClose={this.setClose}
            isNew={isNew}
            farm={selectedFarm}
            activeSite={activeSite}
            getFromGlobalState={getFromGlobalState}
          />
        )}
        <Typography variant="subtitle1">
          {`${prefix} Detail`}
          <Tooltip placement="top" title="Edit">
            <IconButton
              aria-label="Edit"
              color="primary"
              className={classes.menuButton}
              onClick={this.handleClickShowFarm}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Typography>

        {farm && farm.map((farmd) => (
          <Paper elevation={0} className={classes.parentSection} key={farm.sk}>
            <Grid container className={classes.root} spacing={1} alignItems="flex-start">
              {farmd.logoKey && farmd.logoKey
              && (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle2">Logo</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <div>
                    <br />
                    <img alt="logo" id="logo" src={logoURL} style={{ maxWidth: '150px' }} />
                  </div>
                </Grid>
              </>
              )}
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography variant="subtitle2">{`${prefix} Name`}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>{farmd.name}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography variant="subtitle2">{`${prefix} Short Name`}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>{farmd.shortName}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography variant="subtitle2">Production Unit Code (PUC)</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>{farmd.puc}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography variant="subtitle2"> GLOBALG.A.P. Number</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>{farmd.gapnum}</Typography>
              </Grid>
              { farmd.vatNo && (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle2"> VAT Number</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>{farmd.vatNo}</Typography>
                </Grid>
              </>
              )}
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography variant="subtitle2">
                  {`${prefix} Physical Address`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>{farmd.address1}</Typography>

                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>{farmd.address2}</Typography>

                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>{farmd.country}</Typography>

                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>{farmd.province}</Typography>

                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>{farmd.pCode}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography variant="subtitle2">{`${prefix} Contact Number`}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>{farmd.contactNum}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography variant="subtitle2">{`${prefix} Email`}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>{farmd.email}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Map
                center={centerLoc}
                markers={markers}
                infoWindow={infoWindow}
                onCloseInfoWindow={this.onCloseInfoWindow}
                zoom={zoom}
                onClick={this.onMapClick}
                onSelectMarker={this.onSelectFarmMarker}
              />
            </Grid>

          </Paper>
        ))}

      </Paper>
    );
  }
}
FarmDetailForm.propTypes = {
  initialState: PropTypes.array.isRequired,
  activeSite: PropTypes.string.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(FarmDetailForm);
