import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import GenDialog from '../../components/GenDialog';
import { getNameFromKey2 } from '../../libs/genLib';
import TargetsList from '../../components/TargetsList';
import { validate, validateForm } from '../../libs/validate';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 220,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },
  group: {
    //  display: 'flex';
    // flex-wrap: 'wrap';
    flexDirection: 'row',
  },

});

const requiredFields = [
  { name: 'crop', min: 1, max: 100 },
 // { name: 'targets', min: 1, max: 500, type: 'array' },
 // { name: 'dosageUnit', min: 1, max: 40 },
 // { name: 'dosage', min: 1, max: 200 },
  // {name:'valper100l',min:1,max:40},
  // {name:'valperUnit',min:1,max:40},
  // {name:'totalUnit', min: 1, max: 40},
 // {
 //   name: 'reentry', min: 0, max: 1000, type: 'number', condition: { name: 'reentryOverride', value: false },
 // },
];

class ChemCropD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      chemCrop: {},
      errors: { count: 0 },
      varieties: [],
      changed: false,
      expandedTarget: '',

    };
  }

  componentDidMount() {
    if (this.props.isNew) {
      this.setState({ chemCrop: { reentryOverride: false } });
    } else {
      this.setState({ chemCrop: this.props.chemCrop });
    }
  }

  validateField(field, requiredFields) {
    const error = validate(field, this.state.chemCrop, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleRadioChange = (name, event) => {
    const chemCrop = { ...this.state.chemCrop };
    chemCrop[name] = event.target.value;
    this.setState({ chemCrop, changed: true });
  }

  handleReentryOverride = (event, value) => {
    const chemCrop = { ...this.state.chemCrop };
    const currentIndex = chemCrop.targets.findIndex((t) => t.sk === value);
    if (currentIndex !== -1) {
      if (!Object.hasOwn(chemCrop.targets[currentIndex], 'reentryOverride')) {
        chemCrop.targets[currentIndex].reentryOverride = event.target.checked;
      }
      else {
        chemCrop.targets[currentIndex].reentryOverride = !chemCrop.targets[currentIndex].reentryOverride;
      }
      
    }
    this.setState({ chemCrop, changed: true });
  }

  handleToggle = (value,relation,parentId) => {
    const chemCrop = { ...this.state.chemCrop };
    if (!chemCrop.targets) {
      chemCrop.targets = [];
    }
    const currentIndex = chemCrop.targets.findIndex((t) => t.sk === value);

    if (currentIndex === -1) {
      chemCrop.targets.push({ sk: value });
    } else {
      chemCrop.targets.splice(currentIndex, 1);
    }
    this.setState({ chemCrop, changed: true });
  }

  handleTargetChange = (event, value) => {
    const chemCrop = { ...this.state.chemCrop };
    if (!chemCrop.targets) {
      chemCrop.targets = [];
    }
    const currentIndex = chemCrop.targets.findIndex((t) => t.sk === value);
    if (currentIndex !== -1) {
      chemCrop.targets[currentIndex][event.target.id] = event.target.value;
    } 
    this.setState({ chemCrop, changed: true });
  }

  handleChange = (event) => {
    const chemCrop = { ...this.state.chemCrop };
    chemCrop[event.target.id] = event.target.value;
    this.setState({ chemCrop, changed: true });
  }

  handleCheckChange = (event) => {
    const { chemCrop } = this.state;
    const newChemicalCrop = { ...chemCrop };
    newChemicalCrop[event.target.id] = event.target.checked;
    this.setState({ chemCrop: newChemicalCrop, changed: true });
  }

  handleExpand = (setting) => {
    const { expandedTarget } = this.state;
    if (expandedTarget !== setting) {
      this.setState({ expandedTarget: setting });
    } else {
      this.setState({ expandedTarget: '' });
    }
  }

  render() {
    const {
      classes, open, isNew, setClose, handleUpdate, parent, metaData, getFromGlobalState,
    } = this.props;
    const { chemCrop, errors, expandedTarget } = this.state;
    const crops = metaData.filteredCrops;
    // console.log('crops!!!!',crops);
    const formValid = validateForm(chemCrop, requiredFields);

    return (

      <GenDialog
        open={open}
        dialogObject={chemCrop}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary="_setchild_"
        dynamoType="chemcrops"
        valid={formValid}
        changed={this.state.changed}
        newTitle="New Crop"
        subTitle={`${parent.name}`}
        title={!isNew && chemCrop.crop ? getNameFromKey2(chemCrop.crop, crops, 'name') : ''}
        parentKey={parent.sk}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
        {!isNew && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={chemCrop.isDisabled}
                 // disabled={isInvite}
                  id="isDisabled"
                  onChange={this.handleCheckChange}
                  value="isDisabled"
                  color="secondary"
                />
        )}
              label="Disabled"
            />
          </Grid>}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="crop">Crop</InputLabel>
              <Select
                native
          // fullWidth
                required
                displayEmpty
                value={chemCrop.crop}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={errors.crop ? true : undefined}
                inputProps={{
                  name: 'crop',
                  id: 'crop',
                }}
              >
                <option value="" />
                {crops && crops.map((crop) => (
                  <>
                    <option value={crop.sk}>{crop.name}</option>
                  </>
                ))}

              </Select>
              {errors.crop && <FormHelperText className={classes.helperText}>{errors.crop}</FormHelperText>}
            </FormControl>
          </Grid>

          {/* } <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            margin="normal"
            required
            type="number"
            inputProps={{ 'min': '0.00001', 'step':'any' }}
           fullWidth
            id="valper100l"
            label={`Dosage (${parent.unit} per 100 Liter)`}
            name="valper100l"

            value={chemCrop.valper100l}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.valper100l ? true : undefined}
            helperText={errors.valper100l}
          />
          </Grid> */}

          {/* }  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl component="fieldset" className={classes.formControlRadio}>
        <FormLabel required >Per</FormLabel>
        <RadioGroup

          aria-label="totalUnit"
          name="totalUnit"
          id="totalUnit"
          className={classes.group}
          value={chemCrop.totalUnit}
          onChange={this.handleRadioChange.bind(this,'totalUnit')}
        >
          <FormControlLabel value="Plant" control={<Radio />} label="Plant" />
          <FormControlLabel  value="Hectare" control={<Radio />} label="Hectare" />
          {errors.totalUnit&& <FormHelperText className={classes.helperText}>{errors.totalUnit}</FormHelperText>}
        </RadioGroup>
      </FormControl>
        </Grid> */}

        </Grid>
      </GenDialog>

    );
  }
}
ChemCropD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  chemCrop: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  parent: PropTypes.object.isRequired,
  metaData: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(ChemCropD);
