import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const styles = theme => ({
    paper: {
      marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      display: 'flex',
    flexWrap: 'wrap',
    },
    formControl: {
      minWidth: 120,
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    
  });

class GenModal extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }
 
  render() {
    const { classes, open, setClose, children } = this.props;
   // const { success, message} = this.state;


    
    return (
 
      <Dialog
      open={open}
      onClose={setClose}
     // style={{ zIndex: '40000 !important'}}
      aria-labelledby="responsive-dialog-title"
      fullScreen={false}
     // TransitionComponent={ Transition}
    >
         
      <DialogContent>
        {children}
          
  <DialogActions>
           <Button
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={setClose}
          > Close </Button>
        
          </DialogActions>
      </DialogContent>
    </Dialog>
    );
  }
}
GenModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  dialogObject: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  handleSubmitModalObject: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  

};
export default withStyles(styles)(GenModal);
