import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import WaterIcon from '@material-ui/icons/Opacity';
// import BugIcon from '@material-ui/icons/BugReport';
// import PackIcon from '@material-ui/icons/Account';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Can from '../Can';

/* function PackIcon() { return (<SvgIcon color='secondary' style={{fontSize:"x-large"}}  viewBox="0 0 24 24">
<path  d="M2,10.96C1.5,10.68 1.35,10.07 1.63,9.59L3.13,7C3.24,6.8 3.41,6.66 3.6,6.58L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.66,6.72 20.82,6.88 20.91,7.08L22.36,9.6C22.64,10.08 22.47,10.69 22,10.96L21,11.54V16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V10.96C2.7,11.13 2.32,11.14 2,10.96M12,4.15V4.15L12,10.85V10.85L17.96,7.5L12,4.15M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V12.69L14,15.59C13.67,15.77 13.3,15.76 13,15.6V19.29L19,15.91M13.85,13.36L20.13,9.73L19.55,8.72L13.27,12.35L13.85,13.36Z" />
></SvgIcon>)} */

function FertiliserIcon() {
  return (
    <SvgIcon color="secondary" style={{ fontSize: 'x-large' }} viewBox="0 0 24 24">
      <path d="M23,4.1V2.3L21.2,2.1C21.1,2.1 20.5,2 19.5,2C15.4,2 12.4,3.2 10.7,5.3C9.4,4.5 7.6,4 5.5,4C4.5,4 3.8,4.1 3.8,4.1L1.9,4.4L2,6.1C2.1,9.1 3.6,14.8 8.8,14.8C8.9,14.8 8.9,14.8 9,14.8V18.2C5.2,18.7 2,20 2,20V22H22V20C22,20 18.8,18.7 15,18.2V15C21.3,14.9 23,7.8 23,4.1M12,18C11.7,18 11.3,18 11,18V12.4C11,12.4 10.8,9 8,9C8,9 9.5,9.8 9.9,12.7C9.5,12.8 9.1,12.8 8.8,12.8C4.2,12.8 4,6.1 4,6.1C4,6.1 4.6,6 5.5,6C7.4,6 10.5,6.4 11.4,9.1C11.9,4.6 17,4 19.5,4C20.4,4 21,4.1 21,4.1C21,4.1 21,13.1 14.7,13.1C14.5,13.1 14.2,13.1 14,13.1C14,11.1 16,8.1 16,8.1C13,9.1 13,13 13,13V18C12.7,18 12.3,18 12,18Z" />
      >
    </SvgIcon>
  );
}

function BugIcon() {
  return (
    <SvgIcon color="secondary" style={{ fontSize: 'x-large' }} viewBox="0 0 24 24">
      <path d="M20,8H17.19C16.74,7.2 16.12,6.5 15.37,6L17,4.41L15.59,3L13.42,5.17C12.96,5.06 12.5,5 12,5C11.5,5 11.05,5.06 10.59,5.17L8.41,3L7,4.41L8.62,6C7.87,6.5 7.26,7.21 6.81,8H4V10H6.09C6.03,10.33 6,10.66 6,11V12H4V14H6V15C6,15.34 6.03,15.67 6.09,16H4V18H6.81C8.47,20.87 12.14,21.84 15,20.18C15.91,19.66 16.67,18.9 17.19,18H20V16H17.91C17.97,15.67 18,15.34 18,15V14H20V12H18V11C18,10.66 17.97,10.33 17.91,10H20V8M16,15A4,4 0 0,1 12,19A4,4 0 0,1 8,15V11A4,4 0 0,1 12,7A4,4 0 0,1 16,11V15M14,10V12H10V10H14M10,14H14V16H10V14Z" />
      >
    </SvgIcon>
  );
}

function ChemicalIcon(props) {
  return (
    <SvgIcon color={props.color} style={{ fontSize: 'x-large' }} viewBox="0 0 24 24">
      <path d="M3,3H21V5A2,2 0 0,0 19,7V19A2,2 0 0,1 17,21H7A2,2 0 0,1 5,19V7A2,2 0 0,0 3,5V3M7,5V7H12V8H7V9H10V10H7V11H10V12H7V13H12V14H7V15H10V16H7V19H17V5H7Z" />
      >
    </SvgIcon>
  );
}

function HarvestIcon(props) {
  return (
    <SvgIcon color={props.color} style={{ fontSize: 'x-large' }} viewBox="0 0 24 24">
      <path d="M10.5,3C8,3 6,5 6,7.5C6,8.11 6.13,8.71 6.37,9.27C5.5,10.12 5,11.28 5,12.5C5,15 7,17 9.5,17C10,17 10.5,16.89 11,16.72V21H13V15.77C13.5,15.91 14,16 14.5,16A5.5,5.5 0 0,0 20,10.5A5.5,5.5 0 0,0 14.5,5C14.41,5 14.33,5 14.24,5C13.41,3.76 12,3 10.5,3M10.5,5C11.82,5 12.91,6.03 13,7.35C13.46,7.12 14,7 14.5,7A3.5,3.5 0 0,1 18,10.5A3.5,3.5 0 0,1 14.5,14C13.54,14 12.63,13.61 11.96,12.91C11.76,14.12 10.72,15 9.5,15A2.5,2.5 0 0,1 7,12.5C7,11.12 7.8,10.54 9,9.79C8.2,8.76 8,8.16 8,7.5A2.5,2.5 0 0,1 10.5,5Z" />
      >
    </SvgIcon>
  );
}

function AgronomistIcon() {
  return (
    <SvgIcon color="secondary" style={{ fontSize: 'x-large' }} viewBox="0 0 24 24">
      <path d="M19.5,12c0.93,0,1.78,0.28,2.5,0.76V8c0-1.1-0.9-2-2-2h-6.29l-1.06-1.06l1.41-1.41l-0.71-0.71L9.82,6.35l0.71,0.71 l1.41-1.41L13,6.71V9c0,1.1-0.9,2-2,2h-0.54c0.95,1.06,1.54,2.46,1.54,4c0,0.34-0.04,0.67-0.09,1h3.14 C15.3,13.75,17.19,12,19.5,12z" />
      <path d="M19.5,13c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5S21.43,13,19.5,13z M19.5,18 c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5s1.5,0.67,1.5,1.5S20.33,18,19.5,18z" />
      <path d="M4,9h5c0-1.1-0.9-2-2-2H4C3.45,7,3,7.45,3,8C3,8.55,3.45,9,4,9z" />
      <path d="M9.83,13.82l-0.18-0.47L10.58,13c-0.46-1.06-1.28-1.91-2.31-2.43l-0.4,0.89l-0.46-0.21l0.4-0.9C7.26,10.13,6.64,10,6,10 c-0.53,0-1.04,0.11-1.52,0.26l0.34,0.91l-0.47,0.18L4,10.42c-1.06,0.46-1.91,1.28-2.43,2.31l0.89,0.4l-0.21,0.46l-0.9-0.4 C1.13,13.74,1,14.36,1,15c0,0.53,0.11,1.04,0.26,1.52l0.91-0.34l0.18,0.47L1.42,17c0.46,1.06,1.28,1.91,2.31,2.43l0.4-0.89 l0.46,0.21l-0.4,0.9C4.74,19.87,5.36,20,6,20c0.53,0,1.04-0.11,1.52-0.26l-0.34-0.91l0.47-0.18L8,19.58 c1.06-0.46,1.91-1.28,2.43-2.31l-0.89-0.4l0.21-0.46l0.9,0.4C10.87,16.26,11,15.64,11,15c0-0.53-0.11-1.04-0.26-1.52L9.83,13.82z M7.15,17.77c-1.53,0.63-3.29-0.09-3.92-1.62c-0.63-1.53,0.09-3.29,1.62-3.92c1.53-0.63,3.29,0.09,3.92,1.62 C9.41,15.38,8.68,17.14,7.15,17.77z" />
      >
    </SvgIcon>
  );
}

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  icon: {
    border: `${'2px solid'} ${theme.palette.secondary.main}`,
    color: 'inherit',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    padding: theme.spacing(1),
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',

  },
  selected: {

    color: 'red !important', // "white",

  },
  iconButton: {
    float: 'left',
    width: '70px',
    height: '70px',
    color: 'inherit',
    fontSize: 'large',

  },
  root: {
    // padding:'12px',
    // color:'#63c7c8',
    // width:'30px',
    display: 'inline-flex',

  },
  iconContainer: {
    textAlign: 'center',
    width: '60px',
  },
  label: {
    color: 'inherit',
  },
  valueText: {
    fontSize: '0.9rem',
    marginLeft: '3px',
    paddingBottom: '15px',
  },
  button: {
    border: '3px solid white',
    padding: '8px',
  },
  listItem: {
    // paddingTop:'2px',
    // paddingBottom:'2px',
  },
  badge: {

    margin: theme.spacing(2),
  },
});

function RoundIcon(props) {
  const {
    classes, water, chemical, plantingSK, handleSelectType, selectedIcon, blockSK, userDetail,
  } = props;

  return (
    <div className={classes.root}>
      <List style={{ width: '50%' }}>
        <Can
          role={userDetail && userDetail.rights && userDetail.rights.allowedActivities ? 'allowedActivities' : 'noAccess'}
          isAdministrator={!!(userDetail && userDetail.rights && userDetail.rights.administrator)}
          perform={userDetail.rights && userDetail.rights.allowedActivities && userDetail.rights.allowedActivities.includes('fertilisings') ? 'fertilisings' : 'noAccess'}
          yes={() => (
            <ListItem className={classes.listItem}>
              <div className={classes.iconContainer}>
                <IconButton className={classes.button} style={{ border: selectedIcon === 'fertilisings' ? '3px solid #63c7c8' : undefined }} size="large" onClick={handleSelectType.bind(this, blockSK, plantingSK, 'fertilisings')}>
                  <FertiliserIcon edge="start" color="secondary" style={{ fontSize: 'x-large' }} />
                </IconButton>
                <Typography style={{ fontSize: '0.9rem' }}>Fertiliser</Typography>

              </div>
              {/* <Typography variant='subtitle1' className={classes.valueText}>{`${fertiliser.total.value.toLocaleString()}${fertiliser.total.unit}`}</Typography> */}
            </ListItem>
          )}
        />
        <Can
          role={userDetail && userDetail.rights && userDetail.rights.allowedActivities ? 'allowedActivities' : 'noAccess'}
          isAdministrator={!!(userDetail && userDetail.rights && userDetail.rights.administrator)}
          perform={userDetail.rights && userDetail.rights.allowedActivities && userDetail.rights.allowedActivities.includes('waterings') ? 'waterings' : 'noAccess'}
          yes={() => (
            <ListItem className={classes.listItem}>

              {/* <Badge color="secondary" max={9} badgeContent={water.total.tasks ? water.total.tasks : 0} className={classes.badge}> */}
              <div className={classes.iconContainer}>
                <IconButton disabled={water.disabled} className={classes.button} style={{ border: selectedIcon === 'waterings' ? '3px solid #63c7c8' : undefined }} size="large" onClick={handleSelectType.bind(this, blockSK, plantingSK, 'waterings')}>
                  <WaterIcon edge="start" color={water.disabled ? 'grey' : 'secondary'} style={{ fontSize: 'x-large' }} />
                </IconButton>
                <Typography color={water.disabled ? 'grey' : undefined} style={{ fontSize: '0.9rem' }}>Irrigation</Typography>
              </div>
              {/* </Badge> */}

              {/*  <Typography variant='subtitle1' className={classes.valueText}>{`${water.total.value.toLocaleString()}${water.total.unit}`}</Typography> */}

            </ListItem>
          )}
        />
        <Can
          role={userDetail && userDetail.rights && userDetail.rights.allowedActivities ? 'allowedActivities' : 'noAccess'}
          isAdministrator={!!(userDetail && userDetail.rights && userDetail.rights.administrator)}
          perform={userDetail.rights && userDetail.rights.allowedActivities && userDetail.rights.allowedActivities.includes('pestings') ? 'pestings' : 'noAccess'}
          yes={() => (
            <ListItem className={classes.listItem}>

              <div className={classes.iconContainer}>
                <IconButton className={classes.button} style={{ border: selectedIcon === 'pestings' ? '3px solid #63c7c8' : undefined }} size="large" onClick={handleSelectType.bind(this, blockSK, plantingSK, 'pestings')}>
                  <BugIcon edge="start" color="secondary" style={{ fontSize: 'x-large' }} />
                </IconButton>
                <Typography style={{ fontSize: '0.9rem' }}>Monitoring</Typography>
              </div>
              {/*  <Typography variant='subtitle1' className={classes.valueText}>{`${pest.total.value.toLocaleString()}${pest.total.unit}`}</Typography> */}
            </ListItem>
          )}
        />
      </List>
      <List style={{ width: '50%' }}>
        <Can
          role={userDetail && userDetail.rights && userDetail.rights.allowedActivities ? 'allowedActivities' : 'noAccess'}
          isAdministrator={!!(userDetail && userDetail.rights && userDetail.rights.administrator)}
          perform={userDetail.rights && userDetail.rights.allowedActivities && userDetail.rights.allowedActivities.includes('sprayings') ? 'sprayings' : 'noAccess'}
          yes={() => (
            <ListItem className={classes.listItem}>

              <div className={classes.iconContainer}>
                <IconButton className={classes.button} style={{ border: selectedIcon === 'sprayings' ? '3px solid #63c7c8' : undefined }} size="large" onClick={handleSelectType.bind(this, blockSK, plantingSK, 'sprayings')}>
                  <ChemicalIcon edge="start" color={'secondary'} style={{ fontSize: 'x-large' }} />
                </IconButton>
                <Typography style={{ fontSize: '0.9rem' }}>Plant Protection</Typography>
              </div>
              {/*  <Typography variant='subtitle1' className={classes.valueText}>{`${chemical.total.value.toLocaleString()}${chemical.total.unit}`}</Typography> */}
            </ListItem>
          )}
        />
        <Can
          role={userDetail && userDetail.rights && userDetail.rights.allowedActivities ? 'allowedActivities' : 'noAccess'}
          isAdministrator={!!(userDetail && userDetail.rights && userDetail.rights.administrator)}
          perform={userDetail.rights && userDetail.rights.allowedActivities && userDetail.rights.allowedActivities.includes('harvestings') ? 'harvestings' : 'noAccess'}
          yes={() => (
            <ListItem className={classes.listItem}>

              <div className={classes.iconContainer}>
                <IconButton className={classes.button} style={{ border: selectedIcon === 'harvestings' ? '3px solid #63c7c8' : undefined }} size="large" onClick={handleSelectType.bind(this, blockSK, plantingSK, 'harvestings')}>
                  <HarvestIcon edge="start" color={chemical.status && chemical.status === 'red' ? 'error' : 'secondary'} style={{ fontSize: 'x-large' }} />
                </IconButton>
                <Typography style={{ fontSize: '0.9rem' }}>Harvest</Typography>
              </div>
              {/* <Typography variant='subtitle1'  className={classes.valueText}>{`${harvest.total.value.toLocaleString()}${harvest.total.unit}`}</Typography> */}
            </ListItem>
          )}
        />
        {/* <ListItem className={classes.listItem}>

    <div className={classes.iconContainer}>
    <IconButton className={classes.button}  style={{border:selectedIcon==='packings'? '3px solid #63c7c8':undefined}} size='large' onClick={handleSelectType.bind(this,blockSK,plantingSK,'packings')}>
    <PackIcon edge='start' color='secondary' style={{fontSize:"x-large"}}  />
    </IconButton>
    <Typography color='secondary' style={{fontSize:'0.9rem'}}>Pack</Typography>
    </div>

  </ListItem> */}
        <Can
          role={userDetail && userDetail.rights && userDetail.rights.allowedActivities ? 'allowedActivities' : 'noAccess'}
          isAdministrator={!!(userDetail && userDetail.rights && userDetail.rights.administrator)}
          perform={userDetail.rights && userDetail.rights.allowedActivities && userDetail.rights.allowedActivities.includes('activities') ? 'activities' : 'noAccess'}
          yes={() => (
            <ListItem className={classes.listItem}>

              <div className={classes.iconContainer}>
                <IconButton className={classes.button} style={{ border: selectedIcon === 'activities' ? '3px solid #63c7c8' : undefined }} size="large" onClick={handleSelectType.bind(this, blockSK, plantingSK, 'activities')}>
                  <AgronomistIcon edge="start" color="secondary" style={{ fontSize: 'x-large' }} />
                </IconButton>
                <Typography style={{ fontSize: '0.9rem' }}>Agronomic Activity</Typography>
              </div>
              {/*  <Typography variant='subtitle1' className={classes.valueText} >{`${pack.total.value.toLocaleString()}${pack.total.unit}`}</Typography> */}
            </ListItem>
          )}
        />
      </List>
    </div>

  );
}

RoundIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  fertiliserValue: PropTypes.string.isRequired,
  waterValue: PropTypes.string.isRequired,
  pestValue: PropTypes.string.isRequired,
  chemicalValue: PropTypes.string.isRequired,
  harvestValue: PropTypes.string.isRequired,
  packValue: PropTypes.string.isRequired,
  handleSelectType: PropTypes.func.isRequired,
  plantingSK: PropTypes.string.isRequired,
  blockSK: PropTypes.string.isRequired,
  block: PropTypes.object.isRequired,
  selectedIcon: PropTypes.string.isRequired,
  userDetail: PropTypes.object.isRequired,
};

export default withStyles(styles)(RoundIcon);
