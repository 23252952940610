import React, {Fragment}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function ListControl(props) {
  
  const {data, handleToggle, checkedItems, childCollectionMember} = props;
  
  const classes = useStyles();
 
  function renderList (data, parentSK)
  {
      return (
        <List className={classes.root}>
        {data && data.map(value => {
          const labelId = `checkbox-list-label-${value.sk}`;
  
          return (
            <ListItem className={classes.nested} disableRipple key={value.sk} role={undefined} dense button onClick={handleToggle.bind(this,value.sk,parentSK)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checkedItems && checkedItems.indexOf(value.sk) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.name}`} />
            </ListItem>
          );
        })}
      </List>)
  }

  return (
    <List className={classes.root}>
      {data && data.map(value => {

        return (
            <Fragment key={value.sk}>
            <Typography key={value.sk} variant="subtitle1">{value.name}</Typography>
          {childCollectionMember && value[childCollectionMember] && renderList(value[childCollectionMember],value.sk)}
          </Fragment>
        );
      })}
    </List>
  );
}
ListControl.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    checkedItems: PropTypes.array.isRequired,
    handleToggle: PropTypes.func.isRequired,
    childCollectionMember: PropTypes.string.isRequired,
  };