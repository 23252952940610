import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import Fab from '@material-ui/core/Fab';
import NewIcon from '@material-ui/icons/FiberNewOutlined';
import CachedIcon from '@material-ui/icons/CloudUploadOutlined';
import SyncErrorIcon from '@material-ui/icons/CloudOffOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableContainer from '@material-ui/core/TableContainer';

import AutoAvatar from '../AutoAvatar';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function desc2(a, b, orderBy) {
  if (typeof a[orderBy] === 'number' && typeof b[orderBy] === 'number') {
    // Compare numbers
    return b[orderBy] - a[orderBy];
  } else if (Date.parse(a[orderBy]) && Date.parse(b[orderBy])) {
    // Compare dates
    return new Date(b[orderBy]) - new Date(a[orderBy]);
  } else {
    // Compare strings (case-insensitive)
    return b[orderBy].toString().toLowerCase().localeCompare(a[orderBy].toString().toLowerCase());
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    multiSelect,
    userAvatar,
    clean,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {multiSelect && (
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />

        </TableCell>
        )}
        {userAvatar && <TableCell style={{ maxWidth: '20px', borderBottom: (clean ? 'none' : undefined) }} />}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={{
              minWidth: headCell.minWidth, maxWidth: headCell.maxWidth, overflow: 'hidden', borderBottom: (clean ? 'none' : undefined),
            }}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
  multiSelect: PropTypes.bool.isRequired,
  clean: PropTypes.bool,
  userAvatar: PropTypes.bool,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'inline-flex',
  },
  title: {
    flex: '0 0 auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    paddingRight: theme.spacing(1),
  },

}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    heading,
    handleAddItem,
    disableAdd,
    editDisabled,
    handleEditItem,
    handleShowDetail,
    showDetailSelected,
    showEditButton,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        <Typography variant="subtitle2" id="tableTitle">
          {heading}
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {handleShowDetail
          && (
          <Tooltip title="Detail">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={showDetailSelected}
                  onChange={handleShowDetail}
                  name="checkedB"
                  color="secondary"
                />
        )}
              label="Detail"
            />
          </Tooltip>
          )}
        {showEditButton && handleEditItem

         && (
         <div style={{ float: 'right', marginLeft: 'auto' }}>

           <Tooltip title="Edit item">
             <Fab
               variant="extended"
               size="medium"
               color="primary"
               disabled={editDisabled}
               aria-label="add"
               className={classes.margin}
               onClick={handleEditItem}
             >
               <EditIcon className={classes.extendedIcon} />
               View
             </Fab>

           </Tooltip>
         </div>
         )}
        {handleAddItem

          && (
          <div style={{ float: 'right', marginLeft: 'auto' }}>

            <Tooltip title="Add">
              <Fab
                variant="extended"
                disabled={disableAdd}
                size="medium"
                color="primary"
                aria-label="add"
                className={classes.margin}
                onClick={handleAddItem}
              >
                <AddIcon className={classes.extendedIcon} />
                Add
              </Fab>

            </Tooltip>
          </div>
          )}

      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleAddItem: PropTypes.func.isRequired,
  handleShowDetail: PropTypes.func.isRequired,
  showDetailSelected: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  showEditButton: PropTypes.bool,
  handleEditItem: PropTypes.func,
  editDisabled: PropTypes.bool,
  disableAdd: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // marginTop: theme.spacing(3),
  },
  paper: {
    // width: '100%',
    // marginBottom: theme.spacing(2),
    // width: '100%',
    // marginTop: theme.spacing(3),
    // overflowX: 'auto',
  },
  table: {
  // minWidth: 750,
  },
  tableWrapper: {
    // maxHeight:'600px',
    width: '100%',
    // overflow: 'auto',
    overflowX: 'hidden',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cached: {
    fontSize: 15,
    color: theme.palette.secondary.main,
  },
  margin: {
    margin: theme.spacing(1),
  },
  syncError: {
    fontSize: 15,
    color: 'red',
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const {
    rows,
    headCells,
    maxHeight,
    handleEditItem,
    disableAdd,
    editDisabled,
    heading,
    dense,
    showEditButton,
    parentSK,
    hideHeading,
    displayToolbar,
    handleSelect,
    multiSelect,
    selectedRow,
    parent,
    handleAddItem,
    rowKeyName,
    actionType,
    grandParentSK,
    recordType,
    clean,
    noPagination,
    hideHeader,
    padFirstCol,
    disablePadFirstCol,
    userAvatar,
    ancestorsInRow,
    handleShowDetail,
    showDetailSelected,
    orderByProperty,
    orderType

  } = props;
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  let rowsPerPage2 = rowsPerPage;
  if (noPagination) {
    rowsPerPage2 = rows ? rows.length : 3;
  }

  useEffect(()=>{
    orderByProperty && setOrderBy(orderByProperty);
    orderByProperty && setOrder(orderType);
  },[]);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[rowKeyName]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, sk) {
    const selectedIndex = selected.indexOf(sk);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, sk);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const isSelected = (sk) => selected.indexOf(sk) !== -1;

  const emptyRows = noPagination ? 0 : rowsPerPage2 - Math.min(rowsPerPage2, rows.length - page * rowsPerPage2);
  const overflowX = clean ? 'hidden' : undefined;
  return (

    <>
      {displayToolbar && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          heading={hideHeading ? null : heading}
          handleAddItem={handleAddItem}
          showDetailSelected={showDetailSelected}
          handleShowDetail={handleShowDetail}
          showEditButton={showEditButton}
          editDisabled={editDisabled}
          handleEditItem={handleEditItem}
          disableAdd={disableAdd}
        />
      ) }
      <div className={classes.tableWrapper}>
        <TableContainer style={{ maxHeight, overflowX }}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            {!hideHeader && (
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={multiSelect ? handleSelectAllClick : undefined}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
                multiSelect={multiSelect}
                clean={clean}
                userAvatar={userAvatar}
              />
            )}
            <TableBody>
              {rows && stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage2, page * rowsPerPage2 + rowsPerPage2)
                .map((row, index) => {
                  // console.log("rows per page",rowsPerPage2);
                  // console.log("sliceStart",page*rowsPerPage2,"sliceEnd",page * rowsPerPage2 + rowsPerPage2);
                  const isItemSelected = multiSelect ? isSelected(row[rowKeyName]) : selectedRow === row[rowKeyName];
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={row.disabled ? undefined : (multiSelect ? (event) => handleClick(event, row[rowKeyName]) : handleSelect ? (event) => handleSelect(event, !ancestorsInRow ? parent : row[parentSK], row[rowKeyName], row[actionType], !ancestorsInRow ? grandParentSK : row.blockSK, recordType, row) : undefined)}
                      role="checkbox" //  blockSK,plantingSK,record.type,record.sk
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[rowKeyName]}
                      selected={isItemSelected}
                      style={row.disabled ? { fontStyle: 'oblique', cursor: 'pointer' } : { cursor: 'pointer' }}
                    >
                      {multiSelect && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                      )}
                      {userAvatar && <TableCell style={{ verticalAlign: 'top', maxWidth: '20px', paddingLeft: (disablePadFirstCol ? padFirstCol : undefined), borderBottom: (clean ? 'none' : undefined) }}><AutoAvatar userKey={row[rowKeyName]} name={row.name}> </AutoAvatar></TableCell>}

                      {/* combine multiple rows in a column byseeing if a headcell has more than one ids in the array */}

                      {headCells.map((headCell, i) => {
                        const align = headCell.numeric ? 'right' : 'left';

                        if (i === 0) {
                          if (headCell.combine) {
                            return (
                              <TableCell
                                style={{
                                  verticalAlign: 'top', borderBottom: (clean ? 'none' : undefined), minWidth: headCell.minWidth, maxWidth: headCell.maxWidth, overflow: 'hidden', paddingLeft: (disablePadFirstCol ? padFirstCol : undefined),
                                }}
                                component="th"
                                id={labelId}
                                scope="row"
                              >
                                {headCell.combine && headCell.combine.map((cellId, k) => {
                                  const color = headCell.numeric ? (parseInt(row[cellId.id]) >= 0 ? 'green' : 'red') : 'inherit';
                                  const attention = row.attention && k === 0 ? <NewIcon color="secondary" /> : undefined;
                                  const alert = row.alert && k === 0 ? <Typography color="error">{row.alert}</Typography> : undefined;
                                  const cached = row.cached && k === 0 ? (row.syncError ? <SyncErrorIcon className={classes.syncError} /> : <CachedIcon className={classes.cached} />) : undefined;
                                  return (
                                    <>
                                      {cached}
                                      {attention}
                                      {alert}
                                      <div style={{ color, opacity: cellId.opacity }}>{`${row[cellId.id]} ${cellId.postfix ? cellId.postfix : ''}`}</div>
                                    </>
                                  );
                                })}

                              </TableCell>
                            );
                          }

                          return (
                            <TableCell
                              style={{
                                verticalAlign: 'top', borderBottom: (clean ? 'none' : undefined), minWidth: headCell.minWidth, fontSize: headCell.fontSize, maxWidth: headCell.maxWidth, overflow: 'hidden', paddingLeft: (disablePadFirstCol ? padFirstCol : undefined),
                              }}
                              component="th"
                              id={labelId}
                              scope="row"
                            >
                              {row[headCell.id]}
                            </TableCell>
                          );
                        }

                        if (headCell.combine) {
                          return (
                            <TableCell
                              style={{
                                verticalAlign: 'top', borderBottom: (clean ? 'none' : undefined), minWidth: headCell.minWidth, maxWidth: headCell.maxWidth, overflow: 'hidden',
                              }}
                              align={align}
                            >

                              {headCell.combine && headCell.combine.map((cellId, k) => {
                                const color = headCell.numeric ? (parseInt(row[cellId.id]) >= 0 ? 'green' : 'red') : 'inherit';
                                if (cellId.postfix) {
                                return <div style={{ color, opacity: cellId.opacity }}>{`${row[cellId.id]} ${cellId.postfix ? cellId.postfix : ''}`}</div>;
                                }
                                else {
                                  return <div style={{ color, opacity: cellId.opacity }}>{row[cellId.id]}</div>;
                                }
                              })}

                            </TableCell>
                          );
                        }

                        return (
                          <TableCell
                            style={{
                              verticalAlign: 'top', borderBottom: (clean ? 'none' : undefined), minWidth: headCell.minWidth, fontSize: headCell.fontSize, maxWidth: headCell.maxWidth, overflow: 'hidden',
                            }}
                            align={align}
                          >
                            {row[headCell.id]}
                          </TableCell>
                        );
                      })}

                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={headCells.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {!noPagination
        && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
          component="div"
          labelRowsPerPage="Rows"
          count={rows.length}
          rowsPerPage={rowsPerPage2}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        )}
    </>
  );
}
EnhancedTable.defaultProps = {
  padFirstCol: '0px',
  maxHeight: undefined,
  parentSK: 'plantingSK',
};
EnhancedTable.propTypes = {
  classes: PropTypes.object,
  rows: PropTypes.array.isRequired,
  ancestorsInRow: PropTypes.bool.isRequired,
  headCells: PropTypes.array.isRequired,
  handleShowDetail: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  hideHeading: PropTypes.bool,
  displayToolbar: PropTypes.bool,
  hideHeader: PropTypes.bool,
  disablePadFirstCol: PropTypes.bool,
  multiSelect: PropTypes.bool,
  handleSelect: PropTypes.func,
  selectedRow: PropTypes.string,
  parent: PropTypes.object,
  grandParentSK: PropTypes.object,
  handleAddItem: PropTypes.func,
  rowKeyName: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  recordType: PropTypes.string,
  clean: PropTypes.bool,
  noPagination: PropTypes.bool,
  dense: PropTypes.bool,
  userAvatar: PropTypes.bool,
  showDetailSelected: PropTypes.bool,
  handleShowDetail: PropTypes.func,
  padFirstCol: PropTypes.string,
  maxHeight: PropTypes.string,
  parentSK: PropTypes.string,
  showEditButton: PropTypes.bool.isRequired,
  handleEditItem: PropTypes.func,
  editDisabled: PropTypes.bool,
  disableAdd: PropTypes.bool,
  orderByProperty:PropTypes.string,
  orderType:PropTypes.string

};
