import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  paper: {

    borderRadius: '8px',
    height: '100%',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    boxShadow: '0px 1px 1px rgb(100 116 139 / 6%), 0px 1px 2px rgb(100 116 139 / 10%)',
    overflow: 'hidden',
  },
  cardContnet: {
    paddingBottom: '32px',
  },
}));

export default function StatBox(props) {
  const classes = useStyles();
  const {
    stat, header, icon, message, maxWidth,
  } = props;
  return (
    <Paper className={classes.root} style={maxWidth ? { maxWidth } : undefined}>
      <Card>
        <CardContent className={classes.cardContnet}>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs={8} sm={8} md={8} lg={6} xl={8}>
              <div style={{ paddingLeft: '24px', paddingTop: '24px' }}>
                <Typography variant="button">{header}</Typography>

                <Typography variant="h4">{stat}</Typography>
                {message && <Typography variant="body2">{message}</Typography>}

              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
            <Grid item xs={2} sm={2} md={2} lg={4} xl={2}>
              <div style={{ paddingLeft: '24px', paddingTop: '24px' }}>
                {icon && (
                <Avatar style={{
                  width: '56px', height: '56px', float: 'right', backgroundColor: '#0e4553',
                }}
                >
                  {icon}
                </Avatar>
                )}
              </div>
            </Grid>
          </Grid>
          {false && message && (
          <div style={{
            paddingLeft: '24px', paddingTop: '16px', display: 'flex', alignItems: 'center',
          }}
          >
            {message}
          </div>
          )}
        </CardContent>
      </Card>
    </Paper>

  );
}
StatBox.propTypes = {
  stat: PropTypes.number.isRequired,
  header: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  maxWidth: PropTypes.string.isRequired,
};
