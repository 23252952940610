import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Page, Text, View, Image, Link, Document, StyleSheet, PDFDownloadLink,
} from '@react-pdf/renderer';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import Logo from '../../images/logo_gray.png';
import { getData } from '../../libs/analytics';
import { getNameFromKey2, getObjectFromKey, getToday, format } from '../../libs/genLib';
import LoaderFab from '../../components/LoaderFab';
import { getDownloadUrl } from '../../libs/storage';

const BORDER_COLOR = '#bfbfbf';
const BORDER_STYLE = 'solid';
const COL1_WIDTH = 40;

// Create styles
const styles = StyleSheet.create({
  // page: {
  //  flexDirection: 'column',
  // backgroundColor: '#E4E4E4'
  // },
  body: {
    paddingTop: 35,
    paddingBottom: 55,
    paddingHorizontal: 35,
  },
  sectionRight: {
    marginBottom: 10,
    paddingBottom: 10,
    // flexGrow: 1,
    textAlign: 'right',
    // height:220,
    width: '60%',
  // display:'inline-block',
  },
  sectionLeft: {
    marginBottom: 10,
    paddingBottom: 10,
    // flexGrow: 1,
    // textAlign:'right',
    // height:220,
    width: '40%',
    // display:'inline-block',
  },
  section: {
    // margin: 10,
  //  padding: 10,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // overflow:'auto',
  //  flexWrap: 'nowrap',
  //  display:'flex'
    // flexGrow: 1,
    // textAlign:'right',
    // width:300,
  },
  sectionNoPadLeft: {
    margin: 10,
    padding: 10,
    marginLeft: 0,
    paddingLeft: 0,
    // flexGrow: 1,
    // textAlign:'right',
    // width:300,
  },
  textHeader: {
    fontSize: 24,
    paddingBottom: 10,
  },
  textSubHeader: {
    fontSize: 14,
    // margin: 12,
    // fontFamily: 'Oswald'
  },
  textOpac: {
    fontSize: 10,
    opacity: 0.6,
  },
  text: {
    fontSize: 10,
    // opacity:0.6,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol1Header: {
    width: `${COL1_WIDTH}%`,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    // width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    // fontSize: 10,
  },
  tableCol1: {
    width: `${COL1_WIDTH}%`,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    // width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 3,
    fontSize: 10,
    fontWeight: 500,
  },
  tableCell: {
    margin: 3,
    fontSize: 8,
    wrap: true,
  },
  tableCellStrong: {
    margin: 3,
    fontSize: 8,
    fontWeight: 'bold',
    wrap: true,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'right',
    color: 'grey',
    paddingRight: 20,
    marginRight: 20,
  },
  logoText: {
    position: 'absolute',
    fontSize: 10,
    // fontWeight:500,
    bottom: 30,
    left: 20,
    // right: 0,
    textAlign: 'left',
    // color: '#63c7c8',
    // paddingLeft:20,
    //  marginLeft:20,
    textDecoration: 'none',
  },
  logo: {
    // position: 'absolute',
    // fontSize: 15,
    width: 30,
    height: 30,
    // fontWeight:500,
    // bottom: 20,
    // left: 0,
    // right: 0,
    // textAlign: 'left',
    // color: '#0e4553',
    paddingLeft: 2,
    // marginLeft:20,

  },
  farmLogo: {
    // position: 'absolute',
    // fontSize: 15,
    maxWidth: '150px',

    // fontWeight:500,
    // bottom: 20,
    // left: 0,
    // right: 0,
    // textAlign: 'left',
    // color: '#0e4553',

    // marginLeft:20,

  },
});

const ReportRecords = (props) => (

  <View style={styles.table}>
    <View fixed style={styles.tableRow}>
      {props.data.headCells && props.data.headCells.map((headerCell) => (
        <>
          <View style={[styles.tableColHeader, { width: `${100 / props.data.headCells.length} %` }]}>
            <Text style={styles.tableCellHeader}>{headerCell.label}</Text>
          </View>
        </>
      ))}
    </View>
    {props.data.rows && props.data.rows.map((row) => (
      <>
        <View style={styles.tableRow}>
          {props.data.headCells && props.data.headCells.map((headerCell) => (
            <>
              <View style={[styles.tableCol, { width: `${100 / props.data.headCells.length} %` }]}>
               <Text style={row.strong ? styles.tableCellStrong : styles.tableCell}>{format(headerCell,row[headerCell.id], props.data.locale)}</Text>
              </View>
            </>
          ))}
        </View>

      </>
    ))}

  </View>

);

const ReportHeader = (props) => (
  // <View style={styles.sectionRight} fixed > if you want it to be a header that repeats

  <View wrap={false} style={styles.section}>
    {props.logoURL && <View style={styles.sectionLeft}><Image cache style={styles.farmLogo} src={props.logoURL} /></View>}
    {!props.logoURL && <View style={styles.sectionLeft} />}
    <View style={styles.sectionRight}>

      <Text style={styles.textHeader}>{props.farm.name}</Text>
      {props.farm.gapnum && <Text style={styles.textOpac}>{`GLOBAL.G.A.P: ${props.farm.gapnum}`}</Text>}
      {props.farm.puc && <Text style={styles.textOpac}>{`Production Unit Code (PUC): ${props.farm.puc}`}</Text>}
      {props.farm.vatNo && <Text style={styles.textOpac}>{`VAT Number: ${props.farm.vatNo}`}</Text>}
      <Text style={styles.textOpac}>{`Tel: ${props.farm.contactNum}`}</Text>
      <Text style={styles.textOpac}>{`email: ${props.farm.email}`}</Text>
      {props.farm.geoLocation && <Text style={styles.textOpac}>{`Geolocation: ${props.farm.geoLocation.lat && props.farm.geoLocation.lng ? `Lat: ${props.farm.geoLocation.lat.toFixed(7)} Lng: ${props.farm.geoLocation.lng.toFixed(7)}` : props.farm.geoLocation}`}</Text>}
      <Text style={styles.textOpac}>{`Address: ${props.farm.address1}`}</Text>
      <Text style={styles.textOpac}>{`${props.farm.address2}`}</Text>
      <Text style={styles.textOpac}>{`${props.farm.country}`}</Text>
      <Text style={styles.textOpac}>{`${props.farm.province}`}</Text>
      <Text style={styles.textOpac}>{`${props.farm.pCode}`}</Text>

    </View>
  </View>

);

const ReportDocument = (props) => (

  <Document>
    <Page size="A4" style={styles.body} orientation="landscape">
      {/* Heading */}

      {/* <PDFReportHeader logoURL={props.logoURL} farm={props.farm} /> */}
      <ReportHeader {...props} />

      {/* Block info */}
      <View style={[styles.sectionNoPadLeft, {
        paddingBottom: 0, paddingTop: 0, marginTop: 0, marginBottom: 0,
      }]}
      >
        <Text style={styles.textSubHeader}>{`Block: ${props.block.number}`}</Text>
        <Text style={styles.textSubHeader}>{`Crop: ${props.crop.name}`}</Text>
        <Text style={styles.textSubHeader}>{`Variety: ${props.variety}`}</Text>
        {props.plantingName && <Text style={styles.textSubHeader}>{`Planting: ${props.plantingName}`}</Text>}
        <Text style={styles.textSubHeader}>{`Plants: ${props.planting.totalPlants}`}</Text>
        <Text style={styles.textSubHeader}>{`Size: ${props.planting.size} ha`}</Text>
        <Text style={styles.textOpac}>{`Report Date: ${getToday()}`}</Text>
        {props.startDate && props.endDate && <Text style={styles.textOpac}>{`Report range: From: ${props.startDate} To: ${props.endDate}`}</Text>}
      </View>

      <View style={styles.sectionNoPadLeft}>
        <Text style={styles.textSubHeader}>{props.data.title}</Text>
      </View>

      {/* records */}

      <ReportRecords {...props} />

      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => (
          `Page: ${pageNumber} / ${totalPages}`
        )}
        fixed
      />
      <View wrap={false} style={styles.logoText}>
        <Link style={{ textDecoration: 'none' }} wrap={false} src="landdiary.com">
          <Text wrap={false} style={{ borderRadius: 3, paddingLeft: '3px', paddingBottom: '2px' }}>
            <Image style={styles.logo} src={Logo} />
            {' '}
          </Text>
        </Link>
      </View>
    </Page>

  </Document>
);

class ReportPDF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      record: {},
      errors: { count: 0 },
      logoURL: undefined,
    };
  }

  async componentDidMount() {
    // console.log('mounting', this.props.farm.logoKey);
    if (this.props.farm && this.props.farm.logoKey) {
      //   console.log('reding url');
      const logoURL = await this.getLogoUrl(this.props.farm.logoKey, this.props.farm.logoFileType);
      //    console.log('haveurl', logoURL);
      this.setState({ logoURL });
    }
  }

  getLogoUrl = async (key, fileType) => {
    const params = {
      activeTenant: this.props.getFromGlobalState('activeSite'),
      type: 'files',
      path: key,
      contentType: fileType,
    };
    const url = await getDownloadUrl(params);
    return url;
  }

  render() {
    const {
      recordType, farm, planting, getFromGlobalState, block, startDate, endDate, onDownloaded,
    } = this.props;
    const data = getData(recordType, planting, block, getFromGlobalState, undefined, undefined, undefined, undefined, undefined, startDate, endDate);
 // remove all the elements from data.headers that is of type icon
    if (data.headCells) {
      data.headCells = data.headCells.filter((headCell) => headCell.type !== 'icon');
    }

    const crop = getObjectFromKey('crops', planting.crop, getFromGlobalState);
    const variety = getNameFromKey2(planting.variety, crop.varieties, 'cultivar');
    const plantingName = planting.name ? planting.name : '';
    const { logoURL } = this.state;
    const waitForURL = farm.logoKey && !logoURL;
    if (waitForURL) {
      return (
        <div style={{

          color: 'inherit',
          float: 'right',
          marginLeft: 'auto',

        }}
        >
          <LoaderFab
            key="pdf"
            onClick={onDownloaded}
            success
            icon={<DownloadIcon />}
            color="inherit"
            loading
            disabled
          />
        </div>
      );
    }

    return (

      <PDFDownloadLink
        document={<ReportDocument plantingName={plantingName} data={data} block={block} farm={farm} crop={crop} variety={variety} planting={planting} startDate={startDate} endDate={endDate} logoURL={logoURL} />}
        fileName={`${farm.name} ${data.title} ${getToday()}`}

        style={{
          // textDecoration: "none",
          // padding: "10px",
          color: 'inherit',
          float: 'right',
          marginLeft: 'auto',
          // backgroundColor: "#f2f2f2",
          // border: "1px solid #4a4a4a"
        }}
      >
        {({
          blob, url, loading, error,
        }) =>
        //   console.log('loading',loading);
          (
            <LoaderFab
              key="pdf"
              onClick={onDownloaded}
              success={!error}
              icon={<DownloadIcon />}
              color="inherit"
              loading={loading}
              disabled={loading}
            />
          )}
      </PDFDownloadLink>

    );
  }
}
ReportPDF.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  recordType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  planting: PropTypes.array.isRequired,
  block: PropTypes.array.isRequired,
  farm: PropTypes.object.isRequired,
  onDownloaded: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  showAll: PropTypes.bool,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,

};
export default withStyles(styles)(ReportPDF);
