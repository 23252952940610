import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import uuid from 'uuid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { getErrorMessage, getDownloadUrl, getTargetDetails } from '../../libs/storage';
import ExpansionPanelDetailed from '../../components/ExpansionPanelDetail';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';
import SingleListControl from '../../components/SingleListControl';
import TargetStage from '../../components/TargetStage';

const styles = (theme) => ({
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  helperText: {
    color: 'red',
  },
  group: {
    flexDirection: 'row',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },

});

const requiredFields = [
  { name: 'name', min: 1, max: 80 },
  { name: 'targetType', min: 1, max: 80 },
  { name: 'image', min: 1, max: 5000 },
  // { name: 'threshold', min: 0, max: 50000, type: 'number',condition:{name:'pestOrDisease',value:'Pest'} },
  { name: 'observableOn', min: 1, max: 5000 },

];

class TargetD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      target: this.props.target,
      errors: { count: 0 },
      changed: false,
      imageUrl: null,
      imageUrls: {},
      isLoading: false,
      expnandedObservable: null,

    };
  }

  async componentDidMount() {
    const { targetType, target, isNew } = this.props;
    if (isNew) {
      this.setState({
        target: {
          observableOn: [], targetType, setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3', isGeneral: true, imageID: uuid.v1(),
        },
      });
    } else {
      if (target.cropSpecific && !target.observableOn) {
        target.observableOn = [];
      }
      if (target.cropSpecific === 'No') {
        const { imageUrls } = this.state;
        const newImageUrls = await this.getNonCropSpecificUrls(target, imageUrls);
        this.setState({ target, imageUrls: newImageUrls });
      }
    }
  }

  validateField(field, requiredFields) {
    const error = validate(field, this.state.target, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const target = { ...this.state.target };
    target[event.target.id] = event.target.value;
    this.setState({ target, changed: true });
  }

  handleSettingExpand = async (setting, item) => {
    const { getFromGlobalState, setGlobalState, setSnack } = this.props;
   
    if (this.state.expnandedObservable !== setting) {
      this.setState({ isLoading: true});
      this.setState({ expnandedObservable: setting });
      const { imageUrls } = this.state.imageUrls;
      const newImageUrls = await this.getUrls(item.stages, imageUrls);
      // need to get the phenology here.
      const targetType = getFromGlobalState('targetsTypesSetting').find((t) => t.name === 'Phenology');
      await getTargetDetails(getFromGlobalState, setGlobalState, setSnack, targetType.sk, item.sk);

      this.setState({ imageUrls: newImageUrls, isLoading: false });
    } else {
      this.setState({ expnandedObservable: '' });
    }
  }

  handleArrayChange = (event, obsIdx, stageIdx) => {
    const target = { ...this.state.target };
    if (target.cropSpecific === 'Yes') {
      target.observableOn[obsIdx].stages[stageIdx][event.target.id] = event.target.value;
    } else {
      target.nonCropDetail[event.target.id] = event.target.value;
    }
    // obj[event.target.id] = event.target.value;
    this.setState({ target, changed: true });
  }

  handleDeleteStage = (e, obsIdx, stageIdx) => {
    const target = { ...this.state.target };
    if (target.observableOn[obsIdx].stages[stageIdx]) {
      target.observableOn[obsIdx].stages.splice(stageIdx, 1);
      this.setState({ target, changed: true });
    }
  }

  handleDeleteObservable = (e, obsIdx) => {
    const target = { ...this.state.target };
    if (target.observableOn[obsIdx]) {
      target.observableOn.splice(obsIdx, 1);
      this.setState({ target, changed: true });
    }
  }

  handleDeleteImage = (e, obsIdx, stageIdx, key) => {
    const target = { ...this.state.target };
    if (target.cropSpecific === 'Yes' && target.observableOn[obsIdx].stages[stageIdx].images) {
      const imgIdx = target.observableOn[obsIdx].stages[stageIdx].images.findIndex((p) => p.Key === key);
      if (imgIdx !== -1) {
        target.observableOn[obsIdx].stages[stageIdx].images.splice(imgIdx, 1);
        this.setState({ target, changed: true });
      }
    } else if (target.nonCropDetail.images) {
      const imgIdx = target.nonCropDetail.images.findIndex((p) => p.Key === key);
      if (imgIdx !== -1) {
        target.target.nonCropDetail.images.splice(imgIdx, 1);
        this.setState({ target, changed: true });
      }
    }
  }

  handleVariableTChange = (event, obsIdx, stageIdx, month, range) => {
    const target = { ...this.state.target };

    if (target.cropSpecific) {
      if (!target.observableOn[obsIdx].stages[stageIdx].variableThreshold) {
        target.observableOn[obsIdx].stages[stageIdx].variableThreshold = {};
      }
      if (!target.observableOn[obsIdx].stages[stageIdx].variableThreshold[month]) {
        target.observableOn[obsIdx].stages[stageIdx].variableThreshold[month] = {};
      }
      if (!target.observableOn[obsIdx].stages[stageIdx].variableThreshold[month][range]) {
        target.observableOn[obsIdx].stages[stageIdx].variableThreshold[month][range] = {};
      }
      target.observableOn[obsIdx].stages[stageIdx].variableThreshold[month][range] = event.target.value;
    } else {
      if (!target.nonCropDetail.variableThreshold) {
        target.nonCropDetail.variableThreshold = {};
      }
      if (!target.nonCropDetail.variableThreshold[month]) {
        target.nonCropDetail.variableThreshold[month] = {};
      }
      if (!target.nonCropDetail.variableThreshold[month][range]) {
        target.nonCropDetail.variableThreshold[month][range] = {};
      }
      target.nonCropDetail.variableThreshold[month][range] = event.target.value;
    }
    this.setState({ target, changed: true });
  }

  handleRadioChange = (event, obsIdx, stageIdx, name) => {
    const target = { ...this.state.target };
    if (target.cropSpecific === 'Yes') {
      target.observableOn[obsIdx].stages[stageIdx][name] = event.target.value;
    } else {
      target.nonCropDetail[name] = event.target.value;
    }
    // obj[event.target.id] = event.target.value;
    this.setState({ target, changed: true });
  }

  handleToggle = (value, relation, parentId) => {
    const currentIndex = this.state.target.observableOn.findIndex((item) => item.sk === value);
    const newPest = { ...this.state.target };
    const { crops } = this.props.data;

    if (currentIndex === -1) {
      const item = crops && crops.find((item) => item.sk === value);
      item.stages = [{ sk: uuid.v1() }];
      newPest.observableOn.push(item);
    } else {
      newPest.observableOn.splice(currentIndex, 1);
    }
    this.setState({ target: newPest, changed: true });
  }

  getFilteredCrops = (target, data) => {
    if (!target) {
      if (!data.crops) {
        return [];
      }
      return data.crops;
    }
    if (!data.crops) {
      return [];
    }
    if (!target.observableOn) {
      return data.crops;
    }

    let filteredData = [];
    filteredData = data.crops.filter((el) => !target.observableOn.find((element) => element.sk === el.sk));
    return filteredData;
  }

  fileUploaded = async (fileIn, obsIdx, stageIdx, stage) => {
    const { getFromGlobalState, setSnack } = this.props;
    const activeTenant = getFromGlobalState('activeSite');
    try {
      if (!fileIn.serverId) {
        console.log('received undefined key');
        return;
      }
      const key = fileIn.serverId;

      const asset = {};

      asset.sk = `init_${key}`;
      asset.lastModified = fileIn.file.lastModified;
      asset.Key = key;
      asset.lastModifiedDate = fileIn.file.lastModifiedDate ? fileIn.file.lastModifiedDate : new Date(fileIn.file.lastModified);
      asset.fileExtension = fileIn.fileExtension;
      asset.filename = fileIn.filename;
      asset.filenameWithoutExtension = fileIn.filenameWithoutExtension;
      asset.fileSize = fileIn.fileSize;
      asset.fileType = fileIn.fileType;
      asset.createdAt = new Date().valueOf();
      asset.version = 1;
      asset.type = 'files';
      const newPest = { ...this.state.target };
      const imageUrls = { ...this.state.imageUrls };
      // newPest = asset;
      if (newPest.cropSpecific === 'Yes') {
        if (!newPest.observableOn[obsIdx].stages) {
          newPest.observableOn[obsIdx].stages = [];
        }
        if (!newPest.observableOn[obsIdx].stages[stageIdx].images) {
          newPest.observableOn[obsIdx].stages[stageIdx].images = [];
        }
        const imgIdx = newPest.observableOn[obsIdx].stages[stageIdx].images.findIndex((p) => p.Key === asset.Key);
        if (imgIdx === -1) {
          newPest.observableOn[obsIdx].stages[stageIdx].images.push(asset);
        } else {
          newPest.observableOn[obsIdx].stages[stageIdx].images[imgIdx] = asset;
        }
      } else {
        if (!newPest.nonCropDetail.images) {
          newPest.nonCropDetail.images = [];
        }
        const imgIdx = newPest.nonCropDetail.images.findIndex((p) => p.Key === asset.Key);
        if (imgIdx === -1) {
          newPest.nonCropDetail.images.push(asset);
        } else {
          newPest.nonCropDetail.images[imgIdx] = asset;
        }
      }
      // imageUrls[asset.Key] = await getTargetUrl(asset.Key, asset.fileType, activeTenant);
      const params = {
        activeTenant,
        type: 'files',
        setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
        path: asset.Key,
        contentType: asset.fileType,
        isGeneral: true,
      };
      imageUrls[asset.Key] = await getDownloadUrl(params);
      console.log('imageURLS', JSON.stringify(imageUrls));

      // const imageUrl = await this.getTargetUrl(asset.Key, asset.fileType);
      this.setState({ target: newPest, imageUrls, changed: true });
    } catch (e) {
      console.log('Error from dialog', e);
      setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    }
  }

  handleDelete = (value) => {
    const newPest = { ...this.state.target };
    const currentIndex = newPest && newPest.observableOn.findIndex((item) => item.sk === value);

    if (currentIndex !== -1) {
      newPest.observableOn.splice(currentIndex, 1);
    }
    this.setState({ target: newPest, changed: true });
  }

  renderNonCropSpecific = (props) => {
    const {
      classes, target, isOther, errors, imageUrls, getFromGlobalState,
    } = props;
    if (!target.nonCropDetail) {
      target.nonCropDetail = {};
    }
    return (
      <TargetStage
        classes={classes}
        stage={target.nonCropDetail}
        disableElevation={isOther}
        errors={errors}
        getFromGlobalState={getFromGlobalState}
        cropSpecific={target.cropSpecific === 'Yes'}
                    // obsIdx={0}
                    // stageIdx={0}
        targetType={target.targetType}
                    // handleDeleteStage={this.handleDeleteStage}
        handleRadioChange={this.handleRadioChange}
        handleArrayChange={this.handleArrayChange}
        handleBlur={this.handleBlur}
        handleDeleteImage={this.handleDeleteImage}
        fileUploaded={this.fileUploaded}
        handleVariableTChange={this.handleVariableTChange}
        imageUrls={imageUrls}
        isGeneral
        setId="a9d3f4b0-696b-4eb9-a9d7-48828697d1a3"
      />
    );
  }

  renderCropSpecific = (props) => {
    const {
      errors, selectedCropType, onCropTypeChange, data, classes, target, filteredCrops, loading, expnandedObservable,
      isOther, getFromGlobalState, imageUrls, isLoading,
    } = props;
    return (
      <>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography>Observable on</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="cropType">Crop Type</InputLabel>
            <Select
              native
              fullWidth
              required
              displayEmpty
              value={selectedCropType}
              onChange={onCropTypeChange}
              inputProps={{
                name: 'cropType',
                id: 'cropType',
              }}
            >
              <option value="" />
              {data && data.cropTypes && data.cropTypes.filter((f) => f.enabled).map((cropType) => (
                <>
                  <option value={cropType.sk}>{cropType.name}</option>
                </>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl className={classes.formControl}>
            <Typography>Available crops</Typography>
            <SingleListControl
              data={filteredCrops}
              onBlur={this.handleBlur}
              checkedItems={target.observableOn}
              handleToggle={this.handleToggle}
              loading={loading}
              hideCheck
              childCollectionMember="cropsSetting"
            />
            <FormHelperText className={classes.helperText}>{errors.observableOn}</FormHelperText>
            {errors.observableOn && <FormHelperText className={classes.helperText}>{errors.observableOn}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {target && target.observableOn.map((item, obsIdx) => (

            <ExpansionPanelDetailed
              heading={item.name}
              expanded={expnandedObservable}
              action={(
                <IconButton
                  onClick={(e) => this.handleDeleteObservable(e, obsIdx)}
                >
                  <DeleteIcon color="secondary" />
                </IconButton>
)}
              handleExpand={(e) => this.handleSettingExpand(item.name, item)}
            >
              <div style={{ width: '100%' }}>
                {item.stages && item.stages.map((sitem, sitemIdx) => (
                  <TargetStage
                    classes={classes}
                    stage={sitem}
                    cropSpecific={target.cropSpecific === 'Yes'}
                    disableElevation={isOther}
                    errors={errors}
                    getFromGlobalState={getFromGlobalState}
                    obsIdx={obsIdx}
                    stageIdx={sitemIdx}
                    targetType={target.targetType}
                    handleDeleteStage={this.handleDeleteStage}
                    handleRadioChange={this.handleRadioChange}
                    handleArrayChange={this.handleArrayChange}
                    handleBlur={this.handleBlur}
                    handleDeleteImage={this.handleDeleteImage}
                    fileUploaded={this.fileUploaded}
                    handleVariableTChange={this.handleVariableTChange}
                    imageUrls={imageUrls}
                    isGeneral
                    isLoading={isLoading}
                    setId="a9d3f4b0-696b-4eb9-a9d7-48828697d1a3"
                    selectedCropType={item.sk}
                  />
                ))}
                {!isOther && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{ width: '100%', float: 'right', marginLeft: 'auto' }}>

                      <Tooltip title="Add Stage">
                        <Fab
                          variant="extended"
                          size="medium"
                          color="primary"
                          aria-label="add"
                          className={classes.margin}
          // onChange={(e) => this.handleArrayChange(e,stage)}
                          onClick={(e) => this.handleAddStage(obsIdx)}
                        >
                          <AddIcon className={classes.extendedIcon} />
                          Stage
                        </Fab>

                      </Tooltip>
                    </div>

                  </Grid>
                )}
              </div>
            </ExpansionPanelDetailed>

          ))}
          {/*  <FormControl className={classes.formControl}>
              <Typography>Selected Crops</Typography>
              <ChipControl
                onBlur={this.handleBlur}
                chipData={target && target.observableOn ? target.observableOn : []}
                handleDelete={this.handleDelete}
              />
              <FormHelperText className={classes.helperText}>{errors.observableOn}</FormHelperText>
              {errors.observableOn && <FormHelperText className={classes.helperText}>{errors.observableOn}</FormHelperText>}
                </FormControl> */}
        </Grid>
      </>
    );
  }

  /* getUrls = async (stages, imageUrls) => {
   // const imgObj = {};
    if (!stages) {
      return null;
    }
    const { getFromGlobalState } = this.props;
    const activeTenant = getFromGlobalState('activeSite');

    for (const stage of stages){
    if (stage.images) {
      for (const item of stage.images) {
        if (!imageUrls[item.Key]){
        const url = await getTargetUrl(item.Key, item.fileType, activeTenant)
        imageUrls[item.Key]=url;
        }

    }
  }
  }
    return imageUrls;
  } */
  getNonCropSpecificUrls = async (target, imageUrls) => {
    const { getFromGlobalState } = this.props;
    const activeTenant = getFromGlobalState('activeSite');
    const newImageUrls = { ...imageUrls };

    if (target.nonCropDetail && target.nonCropDetail.images) {
      for (const image of target.nonCropDetail.images) {
        if (!newImageUrls[image.Key]) {
          const params = {
            activeTenant,
            type: 'files',
            setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
            path: image.Key,
            contentType: image.fileType,
            isGeneral: true,
            collectionName: 'targets/pests',
          };
          newImageUrls[image.Key] = await getDownloadUrl(params);
        }
      }
    }
    return newImageUrls;
  }

  getUrls = async (stages, imageUrls) => {
    // const imgObj = {};
    if (!stages) {
      return null;
    }
    const { getFromGlobalState } = this.props;
    const activeTenant = getFromGlobalState('activeSite');
    const newImageUrls = { ...imageUrls };
    for (const stage of stages) {
      if (stage.images) {
        for (const image of stage.images) {
          if (!newImageUrls[image.Key]) {
            const params = {
              activeTenant,
              type: 'files',
              setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
              path: image.Key,
              contentType: image.fileType,
              isGeneral: true,
              collectionName: 'targets/pests',
            };
            newImageUrls[image.Key] = await getDownloadUrl(params);
          }
        }
      }
    }
    return newImageUrls;
  }

  handleAddStage = (obervableIdx) => {
    const target = { ...this.state.target };
    target.observableOn[obervableIdx].stages.push({ sk: uuid.v1() });
    this.setState({ target });
  }

  render() {
    const {
      classes,
      open,
      isNew,
      setClose,
      handleUpdate,
      data,
      getFromGlobalState,
      targetType,
      onCropTypeChange,
      selectedCropType,
      loading,
    } = this.props;
    const {
      target, errors, expnandedObservable, imageUrls, isLoading,
    } = this.state;
    const formValid = validateForm(target, requiredFields);
    const filteredCrops = this.getFilteredCrops(target, data);
    const targetsTypesSetting = getFromGlobalState('targetsTypesSetting');
    const targetTypeObj = targetType ? targetsTypesSetting.find((t) => t.sk === targetType) : null;
    const targetTypeName = targetTypeObj ? targetTypeObj.name : '';
    const isOther = targetTypeName === 'Other';
    // const selectedCrops = target && target.observableOn && this.enrichSelected(target.observableOn, data.crops);
    if (!target) {
      return null;
    }

    return (

      <GenDialog
        open={open}
        dialogObject={target}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary="_setchild_"
        dynamoType="targetDetail"
        valid// add formValid
        parentKey={targetType}
        changed={this.state.changed}
        newTitle="New Target"
        title={target.name}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root2} spacing={1} alignItems="flex-start">

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              // autoFocus
              value={target.name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="labelName"
              label="Label Name"
              name="labelName"
              // autoFocus
              value={target.labelName}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.labelName ? true : undefined}
              helperText={errors.labelName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl component="fieldset" className={classes.formControlRadio}>
              <FormLabel required>Crop Specific</FormLabel>
              <RadioGroup
                aria-label="threshold"
                name="cropSpecific"
                id="cropSpecific"
                className={classes.group}
                value={target.cropSpecific}
                onChange={this.handleChange}
              >
                <FormControlLabel value="Yes" control={<Radio id="cropSpecific" />} label="Yes" />
                <FormControlLabel value="No" control={<Radio id="cropSpecific" />} label="No" />
                {errors.cropSpecific && <FormHelperText className={classes.helperText}>{errors.cropSpecific}</FormHelperText>}
              </RadioGroup>
            </FormControl>
          </Grid>

          {target.cropSpecific && target.cropSpecific === 'Yes'
          && this.renderCropSpecific({
            errors,
            selectedCropType,
            onCropTypeChange,
            data,
            classes,
            target,
            filteredCrops,
            loading,
            expnandedObservable,
            isOther,
            getFromGlobalState,
            imageUrls,
            isLoading,
          }) }
          {target.cropSpecific && target.cropSpecific === 'No'
          && this.renderNonCropSpecific({
            classes, target, isOther, errors, imageUrls, getFromGlobalState,
          })}
        </Grid>
      </GenDialog>
    );
  }
}
TargetD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  // target: PropTypes.object,
  target: PropTypes.object,
  targetType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  data: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,
  onCropTypeChange: PropTypes.func.isRequired,
  selectedCropType: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,

};
export default withStyles(styles, { withTheme: true })(TargetD);
