import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SingleListControl from "../../components/SingleListControl";
import { validate, validateForm } from "../../libs/validate";
import { addSite } from '../../libs/storage';
import PropTypes from 'prop-types';
import GenDialog from "../../components/GenDialog";




const styles = theme => ({
    paper: {
      marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      display: 'flex',
    flexWrap: 'wrap',
    },
    formControl: {
      minWidth: 120,
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    helperText :{
      color: 'red',
    },
    
  });

  
const requiredFields = [
    {name:'name', min: 1, max: 100},
    //{name:'description', min: 1, max: 250}
  ];


class FolderD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      folder: {name:''},
      errors: {count:0},
      changed:false,
      isloading:false,
    
    };
  }
  
  componentDidMount() {
    if (!this.props.isNew) {
      let folder = this.props.folder;
      this.setState({folder: folder});
    }
  }

  validateField (field, requiredFields)
  {
      let error = validate(field, this.state.folder, requiredFields)
      let errors = {...this.state.errors};
      errors[field] = error.msg;
      this.setState({errors: errors }); 
  }

  handleBlur = (event) => {
    return this.validateField(event.target.id, requiredFields);
  }


  handleChange = (event) => {
    let folder = {...this.state.folder};
    folder[event.target.id] = event.target.value; 
    this.setState({ folder,changed:true });
  }

  

  render() {
    const { classes, open, isNew, setClose, handleUpdate,  metaData, getFromGlobalState , handlePreExecute, parent} = this.props;
    const {folder, errors} = this.state;
   const formValid = validateForm(folder, requiredFields);
   if (isNew){
   folder.path = parent ? `${parent.path}${folder.name}/` : `${folder.name}/`;
   folder.parentSK = parent ? parent.sk : null;
   }
    
    return (
 
      <GenDialog
        open={open}
        dialogObject={folder}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary="init_setting_"
        dynamoType="folders"
        parentKey={parent ? parent.sk : null}
        valid={formValid}
        changed={this.state.changed}
        newTitle='New Folder'
        title={folder && folder.name ? folder.name: ''}
        getFromGlobalState={getFromGlobalState}
        handlePreExecute={handlePreExecute}
        >
         <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoFocus
            value={folder.name}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.name ? true : undefined}
            helperText={errors.name}
          />
           <TextField
            margin="normal"
           // required
            fullWidth
            id="description"
            label="Description"
            name="description"
            value={folder.description}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.description ? true : undefined}
            helperText={errors.description}
          />
           <br/>
          {/* {this.state.isNew && <Typography>Settings to copy to new farm (optional)</Typography>}

           {this.state.isNew && availableSettings && availableSettings.map ((setting) => 
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
         <Typography>{setting.name}</Typography>
           <SingleListControl
            data= {getFromGlobalState(setting.key)}
            checkedItems ={farms.settingsToCopy && farms.settingsToCopy[setting.key] ? farms.settingsToCopy[setting.key] : []}
            handleToggle =  {this.handleToggle}
            arrayName={setting.key}
          />
          </Grid>
           )}*/}
           
   
      

      </GenDialog>
     
    );
  }
}
FolderD.defaultProps = {
  parent: null,
};
FolderD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  folder: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,
  parent: PropTypes.object,

};
export default withStyles(styles)(FolderD);
