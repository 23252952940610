import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import OperatorSelector from '../../components/OperatorSelector';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
});


function AgronomyActFormBatch(props) {
  const { classes, errors, handleBlur, firstFormValid, onApplyAll, metaData, selectedBlocks, handleBatchChange } = props;
  const agronomistActs = metaData.agronomistActs;

  let isFirst = true;
  
  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start">
     

  {selectedBlocks && selectedBlocks.map((selectedBlock) => (
         
         <Card style={{width:'100%', marginBottom:'15px'}}>
             <CardContent>
         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
         <Typography variant="h6" color="inherit" noWrap className={classes.location}>
         {selectedBlock.label}
       </Typography></Grid>

       <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          margin="normal"
          required
          fullWidth
          type="date"
    //defaultValue={new Date()}
          id="date"
          label="Date"
          name="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={selectedBlock.record.date}
          onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
          onBlur={handleBlur.bind(this,selectedBlock.planting)}
          error={errors[selectedBlock.planting] && errors[selectedBlock.planting].date ? true : undefined}
          helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].date}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="activities">Activity</InputLabel>
          <Select
          native
          fullWidth 
          required
          displayEmpty
          value={selectedBlock.record.activity}
          onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
          onBlur={handleBlur.bind(this,selectedBlock.planting)}
          error={errors[selectedBlock.planting] && errors[selectedBlock.planting].activity ? true : undefined}
          inputProps={{
            name: 'activity',
            id: 'activity',
          }}
        >
          <option value=""></option>
          {agronomistActs && agronomistActs.map ((activity) => 
          <>
          <option value={activity.sk}>{activity.name}</option>
          </>
     )}
        </Select>
        {errors[selectedBlock.planting] && errors[selectedBlock.planting].activity && <FormHelperText className={classes.helperText}>{errors[selectedBlock.planting].activity}</FormHelperText>}
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
  </Grid>
  
  
  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <OperatorSelector
          onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
          onBlur={handleBlur.bind(this,selectedBlock.planting)}
          operators={metaData.operators}
          title="Operator"
          value={selectedBlock.record.operator}
          team={selectedBlock.record.team}
          error={errors[selectedBlock.planting] && errors[selectedBlock.planting].operator}
        />
      </Grid>
      {isFirst && (
              <Grid style={{ marginTop: '20px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={onApplyAll.bind(this, selectedBlock.planting, selectedBlock.block, selectedBlock.record)}
                  disabled={firstFormValid}
                >
                  Apply to All
                </Button>
              </Grid>
              )}
              {isFirst = false}
      </CardContent>
  </Card>
          ))}
 
    </Grid>
  );
}
AgronomyActFormBatch.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
  planting:PropTypes.object.isRequired,
  selectedBlocks: PropTypes.array.isRequired,
 handleBatchChange: PropTypes.func.isRequired,
 handleCommonChange: PropTypes.func.isRequired,
 handleCommonBlur: PropTypes.func.isRequired,
 onApplyAll: PropTypes.func.isRequired,
 firstFormValid: PropTypes.bool.isRequired,
};

export default withStyles(styles)(AgronomyActFormBatch);
