import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ChipControl(props) {
  const { chipData, handleDelete } = props;
  const classes = useStyles();
  if (!chipData) {
    return null;
  }
  return (
    <Paper component="ul" elevation={0} className={classes.root}>
      {chipData.map((data) => (
        <li key={data}>
          <Chip
            color="primary"
            label={data.name}
            variant="outlined"
            onDelete={handleDelete.bind(this, data.sk)}
            className={classes.chip}
          />
        </li>
      ))}
    </Paper>

  );
}
ChipControl.propTypes = {
  classes: PropTypes.object.isRequired,
  chipData: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
