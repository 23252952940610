import React from 'react';
import PropTypes from 'prop-types';
import EnhancedTable from '../Etable';
import settings from '../../libs/settings';
import { getDataByRecordType } from '../../libs/analytics';

const { RECENTS_LEN } = settings.constants;

export function sortOutAverages(data) {
  const blockPlantPestData = {};

  data.forEach(row => {
    const block = row.blockNumber;
    const plantType = row.plantingLabel;  
    const pestType = row.name;  
    const totalValue = row.value; 

    if (!blockPlantPestData[block]) {
      blockPlantPestData[block] = {};
    }

    if (!blockPlantPestData[block][plantType]) {
      blockPlantPestData[block][plantType] = {};
    }

    if (!blockPlantPestData[block][plantType][pestType]) {
      blockPlantPestData[block][plantType][pestType] = { totalObservations: 0, observationCount: 0, value: 0 };
    }

    blockPlantPestData[block][plantType][pestType].totalObservations += totalValue; 
    blockPlantPestData[block][plantType][pestType].observationCount += 1;  
    blockPlantPestData[block][plantType][pestType].value += totalValue; 
  });

  Object.keys(blockPlantPestData).forEach(block => {
    Object.keys(blockPlantPestData[block]).forEach(plantType => {
      Object.keys(blockPlantPestData[block][plantType]).forEach(pestType => {
        const { value, observationCount } = blockPlantPestData[block][plantType][pestType];
        const average = observationCount > 0 ? value / observationCount : 0; 
        blockPlantPestData[block][plantType][pestType].average = average;
      });
    });
  });

  const filteredData = [];
  const seenCombinations = {};

  data.forEach(row => {
    const block = row.blockNumber;
    const plantType = row.plantingLabel;
    const pestType = row.name;

    const key = `${block}_${plantType}_${pestType}`;
    if (!seenCombinations[key]) {
      seenCombinations[key] = true;

      const { average, value, observationCount } = blockPlantPestData[block][plantType][pestType];

      const newRow = {
        ...row,
        average:average.toFixed(2),
        value: value.toFixed(2),  
        observationCount : observationCount.toFixed(2) 
      };

      delete newRow.actualObservations;

      filteredData.push(newRow);
    }
  });

  return filteredData;
}

export default function ReportByActivity(props) {
  const {
    recordType, handleSelect, getFromGlobalState, blocks, clean, displayToolbar, noPagination, hideHeader, handleAddItem, selectedRow, showAll, handleShowDetail, showDetail,
  } = props;
  const data = getDataByRecordType(recordType, blocks, getFromGlobalState, clean, showAll, RECENTS_LEN, undefined, undefined, true, true);
  const userDetail = getFromGlobalState('userDetail');
  return (
    <div style={{ paddingLeft: '2px', paddingRight: '2px' }}>
      <EnhancedTable
        displayToolbar={displayToolbar}
        noPagination={noPagination}
        hideHeader={hideHeader}
        heading={data.title}
        rows={showAll ? data.rows : data.mostRecent}
        headCells={data.headCells}
        handleSelect={handleSelect}
        handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? handleAddItem : null}
        actionType="actionType"
        rowKeyName="sk"
        recordType={recordType}
        clean={clean}
        selectedRow={selectedRow}
        ancestorsInRow
        showDetailSelected={showDetail}
        handleShowDetail={handleShowDetail}
        maxHeight="350px"
        dense={!!showDetail}
      />
    </div>
  );
}
ReportByActivity.propTypes = {
  isloading: PropTypes.bool.isRequired,
  children: PropTypes.any,
  recordType: PropTypes.string.isRequired,
  blocks: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  clean: PropTypes.bool,
  displayToolbar: PropTypes.bool,
  noPagination: PropTypes.bool,
  hideHeader: PropTypes.bool,
  handleAddItem: PropTypes.func,
  selectedRow: PropTypes.string,
  showAll: PropTypes.bool,
  handleShowDetail: PropTypes.func,
  showDetail: PropTypes.bool,
  noReload: PropTypes.bool,
  getFromGlobalState: PropTypes.func,

};
