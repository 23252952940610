import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import OperatorSelector from '../../components/OperatorSelector';



const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
});

const getTotalVolume = (selectedBlocks) => {
   
    for (var ob of selectedBlocks){

        if (!ob.record) continue;
        if (!ob.record.duration) continue;
    
        let block = ob.blockOB;
        let planting = ob.plantingOB;

        const unit = block.flowunit;
        let totalVolume;
        if (unit === 'hectare') {
            totalVolume = parseFloat(ob.record.duration) * parseFloat(block.volumePerHour) * parseFloat((planting.size ? planting.size : block.size));
        } else if (unit === 'plant') {
            totalVolume = ob.record.duration * block.volumePerHour * planting.totalPlants;
        }
        ob.record.actualLiters=totalVolume;
        };
    }
  
  


function WateringFormBatch(props) {
  const {
    classes, record, errors, firstFormValid, onApplyAll, handleBlur, handleCommonBlur, handleCommonChange, block, planting, metaData, selectedBlocks, handleSwitchChange, handleBatchChange
  } = props;
  let isFirst = true;
  //record.operator = record.operator ? record.operator : '';
  getTotalVolume(selectedBlocks);
 // if (record.duration) {
 //   record.actualLiters = getTotalVolume(record.duration,planting, block);
 // }
  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start" >
        


      {selectedBlocks && selectedBlocks.map((selectedBlock) => (
         
            <Card style={{width:'100%', marginBottom:'15px'}}>
                <CardContent>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h6" color="inherit" noWrap className={classes.location}>
            {selectedBlock.label}
          </Typography></Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          margin="normal"
          required
          fullWidth
          type="date"
    //defaultValue={new Date()}
          id="date"
          label="Date"
          name="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={selectedBlock.record.date}
          onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
          onBlur={handleBlur.bind(this,selectedBlock.planting)}
          error={errors[selectedBlock.planting] && errors[selectedBlock.planting].date ? true : undefined}
          helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].date}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <InputLabel shrink htmlFor="duration">Duration (hours)</InputLabel>
        <TextField
          margin="normal"
          required
          type="number"
          inputProps={{ min: '0.00001', step: 'any' }}
          fullWidth
          id="duration"
         // label="Duration (hours)"
          name="duration"
    
          value={selectedBlock.record.duration}
  // value=
    // onChange={handleCalcVolume.bind(this,record,planting, block,updateParent,handleChange)}
        //  onChange={handleChange.bind(this)}
        onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
   // onChange={handleComplexChange.bind(this,'volumePerHour','litersApplied')}
          onBlur={handleBlur.bind(this,selectedBlock.planting)}
          error={(errors[selectedBlock.planting] && errors[selectedBlock.planting].duration) ? true : undefined}
          helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].duration}
        />
  </Grid>
     
      
      <Grid item xs={7} sm={7} md={9} lg={9} xl={9}></Grid>
     
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    <FormControl fullWidth className={classes.formControl}>
      <InputLabel shrink htmlFor="actualLiters">Applied Liters</InputLabel>
      <TextField
        margin="normal"
        required
        type="number"
        inputProps={{ min: '0.00001', step:'any' }}
        fullWidth
        id="actualLiters"
// label="Total Applied"
        name="actualLiters"
// shrink
        value={selectedBlock.record.actualLiters}
        onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
        onBlur={handleBlur.bind(this,selectedBlock.planting)}
        error={(errors[selectedBlock.planting] && errors[selectedBlock.planting].actualLiters) ? true : undefined}
        helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].actualLiters}
      />

    </FormControl>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <OperatorSelector
          onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
          operators={metaData.operators}
          title="Operator"
          value={selectedBlock.record.operator}
          team={selectedBlock.record.team}
          onBlur={handleBlur.bind(this,selectedBlock.planting)}
          error={errors[selectedBlock.planting] && errors[selectedBlock.planting].operator}
        />
      </Grid>
      {isFirst && <Grid style={{marginTop:'20px'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Button
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={onApplyAll.bind(this,selectedBlock.planting,selectedBlock.block,selectedBlock.record)}
                    disabled={firstFormValid}
                  >
                    Apply to All
                     </Button>
      </Grid>}
      {isFirst = false}
  </Grid>
  </CardContent>
  </Card>
          ))}
 
    </Grid>
  );
}

WateringFormBatch.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  
  handleBlur: PropTypes.func.isRequired,
  
 // handleComplexChange: PropTypes.func,
 planting:PropTypes.object.isRequired,
 updateParent:PropTypes.func.isRequired,
 block: PropTypes.object.isRequired,
 metaData: PropTypes.object.isRequired,
 handleSwitchChange:PropTypes.func.isRequired,
 selectedBlocks: PropTypes.array.isRequired,
 handleBatchChange: PropTypes.func.isRequired,
 handleCommonChange: PropTypes.func.isRequired,
 handleCommonBlur: PropTypes.func.isRequired,
 onApplyAll: PropTypes.func.isRequired,
 firstFormValid: PropTypes.bool.isRequired,
};

export default withStyles(styles)(WateringFormBatch);
