function validateText(fieldValue, requiredField) {
  if (fieldValue.length < requiredField.min) {
    return { valid: false, msg: `Minimum length ${requiredField.min}` };
  }
  if (fieldValue.length > requiredField.max) {
    return { valid: false, msg: `Maximum length ${requiredField.max}` };
  }
  return { valid: true, msg: '' };
}
function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
function validateEmail(fieldValue, requiredField) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (! re.test(String(fieldValue).toLowerCase())){
      return { valid: false, msg: `Not a valid email address` };
    }
  if (fieldValue < requiredField.min) {
    return { valid: false, msg: `Minimum value ${requiredField.min}` };
  }
  if (fieldValue > requiredField.max) {
    return { valid: false, msg: `Maximum value ${requiredField.max}` };
  }
  return { valid: true, msg: '' };
}
function validateObject(fieldValue, requiredField) {
  if (!fieldValue) {
    return { valid: false, msg: 'Must select at least one' };
  }
  if (Object.keys(fieldValue).length < requiredField.min) {
    return { valid: false, msg: `Minimum value ${requiredField.min}` };
  }
  return { valid: true, msg: '' };
}
function validateArray(fieldValue, requiredField) {
  if (!fieldValue) {
    return { valid: false, msg: 'Must select at least one' };
  }
  if (fieldValue.length < requiredField.min) {
    return { valid: false, msg: `Minimum value ${requiredField.min}` };
  }
  if (fieldValue.length > requiredField.max) {
    return { valid: false, msg: `Maximum value ${requiredField.max}` };
  }
  return { valid: true, msg: '' };
}
function validateNumber(fieldValue, requiredField) {
  if (!isNumber(fieldValue)) {
    return { valid: false, msg: 'Must be a valid number' };
  }
  if (fieldValue < requiredField.min) {
    return { valid: false, msg: `Minimum value ${requiredField.min}` };
  }
  if (fieldValue > requiredField.max) {
    return { valid: false, msg: `Maximum value ${requiredField.max}` };
  }
  return { valid: true, msg: '' };
}
function validatePassword(fieldValue, requiredField) {
  //if (fieldValue.length < requiredField.min || !fieldValue.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$/))
  if (fieldValue.length < requiredField.min || !fieldValue.match(/^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/))
  {
    return { valid: false, msg: 'Password guidance. Minimum length 8, must contain uppercase and lowercase characters' };
  }
  return { valid: true, msg: '' };
}

export function validate(field, object, requiredFields) {
  const requiredField = requiredFields.find((property) => property.name === field);
  const error = {};
  error.msg = '';
  error.isValid = false;
  if (!requiredField) {
    error.isValid = true;
    return error;
  }
  if (requiredField.conditions) {
    if (!object[requiredField.condition.name]) {
      error.isValid = true;
      return error;
    }
    if (object[requiredField.condition.name] !== requiredField.condition.value) {
      error.isValid = true;
      return error;
    }
  }

  const ob = object;
  let retVal = {};
  const fieldValue = ob[field];
  if (fieldValue === undefined) {
    error.msg = 'Is required';
    error.isValid = false;
    return error;
  }
  if (requiredField.type && requiredField.type === 'number') {
    retVal = validateNumber(fieldValue, requiredField);
    error.msg = retVal.msg;
    error.isValid = retVal.valid;
    return error;
  }
  if (!fieldValue || fieldValue.length === 0) {
    error.msg = 'Is required';
    error.isValid = false;
    return error;
  }
  if (requiredField.type && requiredField.type === 'number') {
    retVal = validateNumber(fieldValue, requiredField);
    error.msg = retVal.msg;
    error.isValid = retVal.valid;
    return error;
  }
  if (requiredField.type && requiredField.type === 'password') {
    retVal = validatePassword(fieldValue, requiredField);
    error.msg = retVal.msg;
    error.isValid = retVal.valid;
    return error;
  }
  if (requiredField.type && requiredField.type === 'email') {
    retVal = validateEmail(fieldValue, requiredField);
    error.msg = retVal.msg;
    error.isValid = retVal.valid;
    return error;
  }
  if (requiredField.type && requiredField.type === 'object') {
    retVal = validateObject(fieldValue, requiredField);
    error.msg = retVal.msg;
    error.isValid = retVal.valid;
    return error;
  }
  if (requiredField.type && requiredField.type === 'array') {
    retVal = validateArray(fieldValue, requiredField);
    error.msg = retVal.msg;
    error.isValid = retVal.valid;
    return error;
  }
  retVal = validateText(fieldValue, requiredField);
  error.msg = retVal.msg;
  error.isValid = retVal.valid;
  return error;
}
export function validateForm(object, requiredFields) {
  let retVal = true;
  // see if we have the same keys.
  for (var reqField of requiredFields) {
    if ((!reqField.condition) || (reqField.condition && object.hasOwnProperty(reqField.condition.name) && object[reqField.condition.name]===reqField.condition.value)) {
      if (object && object.hasOwnProperty(reqField.name)) {
        const valid = validate(reqField.name,object, requiredFields);
        if (!valid.isValid) {
          retVal=false;
          break;
        } else {
          continue;
        }
      }
      retVal=false;
      break;
    } else {
      continue;
    }
  };
   
  return retVal;
}

export function validateBatchForm(arrOfObjects, requiredFields) {
  let retVal = true;
  // see if we have the same keys.
  loop1:
for (var ob of arrOfObjects){
  let object = ob.record;
loop2:
  for (var reqField of requiredFields) {
    if ((!reqField.condition) || (reqField.condition && object.hasOwnProperty(reqField.condition.name) && object[reqField.condition.name]===reqField.condition.value)) {
      if (object && object.hasOwnProperty(reqField.name)) {
        const valid = validate(reqField.name,object, requiredFields);
        if (!valid.isValid) {
          retVal=false;
          break loop1;
        } else {
          continue;
        }
      }
      retVal=false;
      break loop1;
    } else {
      continue;
    }
  };
};
   
  return retVal;
}
