import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import LoaderButton from '../../components/LoaderButton';
import { createEntity } from '../../libs/storage';
import Snack from '../../components/Snack';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1),
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      success: true,
      message: '',
      email: '',
      password: '',
      currentUser: undefined,
      newPassword: '',
      confirmNewPassword: '',
      changingPassword: false,
      changePasswordSubmitting: false,
      showPassword: false,

    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  showPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  handleChangeClick = async (event) => {
    event.preventDefault();
    this.setState({ changePasswordSubmitting: true, message: '', success: true });
    const { newPassword } = this.state;
    try {
      await Auth.completeNewPassword(this.state.currentUser, newPassword);
      const ss = await Auth.signIn(this.state.email, this.state.newPassword);
      await createEntity({ userName: ss.username, email: this.state.email });

      this.props.userHasAuthenticated(true);
    } catch (e) {
      this.setState({ changePasswordSubmitting: false, changingPassword: false });
      this.setState(
        {
          success: false,
          message: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error}` : e.message),
        },
      );
      console.log('ERROR:', e);
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isloading: true, message: '', success: true });

    try {
      const currentUser = await Auth.signIn(this.state.email, this.state.password);
      this.setState({ currentUser });
      if (currentUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.setState({ changingPassword: true });
      } else {
        this.props.userHasAuthenticated(true);
      }
      this.setState({ isloading: false });
    } catch (e) {
      this.setState({ isloading: false });
      this.setState(
        {
          success: false,
          message: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error}` : e.message),
        },
      );
      console.log(e);
    }
  }

  renderLogin(classes) {
    const { email, password, showPassword } = this.state;
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={this.handleSubmit} className={classes.form} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              inputProps={{ autocapitalise: 'none' }}
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={this.handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.showPassword}>
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
                type: showPassword ? 'text' : 'password',
              }}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={this.handleChange}
            />
            <LoaderButton
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!this.validateForm()}
              isloading={this.state.isloading}
              text="Login"
              loadingText="Logging in…"
            />
            <Grid container spacing={2} style={{ paddingTop: '10px' }}>
              <Grid item xs>
                <Typography fullWidth component={Link} to="/login/reset" color="secondary" href="#" variant="subtitle1"> Forgot password?</Typography>
              </Grid>
              <Grid item>
                <Typography fullWidth component={Link} color="secondary" to="/signup" variant="subtitle1"> Don't have an account? Sign Up</Typography>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  }

  validateChangeForm() {
    return (
      this.state.newPassword.length > 0
      && this.state.newPassword === this.state.confirmNewPassword
    );
  }

  setSnackClose = () => {
    this.setState({ message: '', success: true });
  }

  renderChangePassword(classes) {
    const { changePasswordSubmitting, showPassword } = this.state;
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <form onSubmit={this.handleChangeClick}>

            <TextField
              margin="normal"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.showPassword}>
                     {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
                type: showPassword ? 'text' : 'password',
              }}
              fullWidth
              id="newPassword"
              label="New Password"
              name="newPassword"
              autoFocus
              value={this.state.newPassword}
              onChange={this.handleChange}
            />
            <TextField
              margin="normal"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.showPassword}>
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
                type: showPassword ? 'text' : 'password',
              }}
              fullWidth
              id="confirmNewPassword"
              label="Confirm Password"
              name="confirmNewPassword"
              value={this.state.confirmNewPassword}
              onChange={this.handleChange}
            />

            <LoaderButton
              type="submit"
              key="loaderchange"
              id="loaderchange"
              name="loaderchange"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!this.validateChangeForm()}
              isloading={changePasswordSubmitting}
              text="Change"
              loadingText="Changing…"
            />

          </form>
        </div>
      </Container>
    );
  }

  render() {
    const { classes } = this.props;
    const { changingPassword } = this.state;
    return (
      <>
        {changingPassword ? this.renderChangePassword(classes) : this.renderLogin(classes)}
        <Snack
          open={!this.state.success}
          setClose={this.setSnackClose.bind(this)}
          messageText={this.state.message}
          variant="error"
        />
      </>

    );
  }
}
export default withStyles(styles)(Login);
