import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Fab from '@material-ui/core/Fab';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';
import SingleListControl from '../../components/SingleListControl';
import SearchBox from '../../components/SearchBox';
import { getAdminSettings, getErrorMessage } from '../../libs/storage';
import { getNameFromKey2 } from '../../libs/genLib';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },
  group: {
    //  display: 'flex';
    // flex-wrap: 'wrap';
    flexDirection: 'row',
  },

});

class CombinationProductD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: { count: 0 },
      changed: false,
      selectedChemical: null,
      selectedFertiliser: null,
      combination: null,
      selectedChemicalCrop: null,
      selectedChemicalCropTarget: null,
      selectedCombinationProduct: null,
      selectedType: null,

    };
  }

  componentDidMount() {
    const { isNewComboEntry, selectedCombinationTarget , combination} = this.props;
    if (!isNewComboEntry && selectedCombinationTarget) {
      if (selectedCombinationTarget.type === 'chemical') {
        this.setState({combination, selectedType: 'chemical', selectedChemical: selectedCombinationTarget.selectedChemical, selectedChemicalCrop: selectedCombinationTarget.selectedChemicalCrop, selectedChemicalCropTarget: selectedCombinationTarget.selectedChemicalCropTarget});
      }
      if (selectedCombinationTarget.type === 'fertiliser') {
        this.setState({combination, selectedType: 'fertiliser', selectedFertiliser: selectedCombinationTarget.selectedFertiliser });
      }
     
    } else {
    const { combination } = this.props;
      if (!combination.combinationTargets) {
        combination.combinationTargets = [];
      }
      this.setState({ combination });
    }
  }

  validateField(field) {
    const { requiredFields } = this.props;
    const { combination, errors } = this.state;
    const error = validate(field, combination, requiredFields);
    const newErrors = { ...this.state.errors };
    newErrors[field] = error.msg;
    this.setState({ errors: newErrors });
  }

  handleBlur = (event) => {
    const { requiredFields } = this.props;
    this.validateField(event.target.id, requiredFields);
  }

  handleChangePPP = (event) => {
    const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === event.target.value);
    this.setState({
      selectedChemical, selectedChemicalCrop: null, selectedChemicalCropTarget: null, isNew: false,
    // selectedChemical, selectedChemicalSK: selectedChemicalID,
    });
  }

  handleChangeType = (event) => {
    this.setState({ selectedType: event.target.value, changed: true, selectedChemical: null, selectedChemicalCrop: null, selectedChemicalCropTarget: null, selectedFertiliser: null });
  }

  handleChange = (event) => {
    const { combination } = this.state;
    const newCombination = { ...combination };
    newCombination[event.target.id] = event.target.value;
    this.setState({ combination: newCombination, changed: true });
  }


  handleSelectChemicalCrop = (event) => {
    const { selectedChemical } = this.state;
    // const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalId);
    const selectedChemicalCrop = selectedChemical.chemcrops.find((cc) => cc.sk === event.target.value);
    this.setState({ selectedChemicalCrop, selectedChemicalCropTarget: null });
  }

  handleChangeFert = (event) => {
    const fertilisers = this.props.getFromGlobalState('fertilisers');
    const selectedFertiliser = fertilisers.find((c) => c.sk === event.target.value);
    this.setState({ selectedFertiliser: selectedFertiliser, changed: true });
  }

  handleRadioChange = (name, event) => {
    const combination = { ...this.state.combination };
    combination[name] = event.target.value;
    this.setState({ combination, changed: true });
  }

  handleCheckChange = (event) => {
    const { combination } = this.state;
    const newCombination = { ...combination };
    newCombination[event.target.id] = event.target.checked;
    this.setState({ combination: newCombination, changed: true });
  }

  handleSelectChemicalCropTarget = (event) => {
    const { selectedChemicalCrop } = this.state;
    // const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalId);
    const selectedChemicalCropTarget = selectedChemicalCrop.targets.find((cc) => cc.sk === event.target.value);
    this.setState({ selectedChemicalCropTarget });
  }

   generateCropRows = (selectedChemical, getFromGlobalState) => {
     if (!selectedChemical) {
       return [];
     }
     const rows = [];
     // const pests = generateReasonLis(getFromGlobalState('monitorings'));
     const crops = getFromGlobalState('crops');
     for (let c = 0; selectedChemical && selectedChemical.chemcrops && c < selectedChemical.chemcrops.length; c += 1) {
       rows.push({
         sk: selectedChemical.chemcrops[c].sk,
         name: getNameFromKey2(selectedChemical.chemcrops[c].crop, crops),
         isDisabled: selectedChemical.chemcrops[c].isDisabled ? 'Yes' : 'No',
       });
     }
     return rows;
   }

 generateReasonLis = (pests) => {
   if (!pests) {
     return [];
   }
   const rows = pests.map((monitoring) => {
     const ob = {};
     ob.sk = monitoring.sk;
     ob.name = monitoring.targetDetail.name;
     ob.targetSK = monitoring.targetDetail.sk;
     return ob;
   });
   return rows;
 }

 handleToggle = (value,relation,parentId) => {


  this.setState ({selectedCombinationProduct : value});

}

handleAddPPP = (combination) => {
  const {
    selectedChemical, selectedChemicalCrop, selectedChemicalCropTarget,
  } = this.state;
  const newCombination = { ...combination };
  if (!newCombination.combinationProducts) {
    newCombination.combinationProducts = [];
  }
  const idx = newCombination.combinationProducts.findIndex((c) => c.sk === selectedChemical.sk);
  if (idx === -1) {
    newCombination.combinationProducts.push({ type: 'chemical',
      sk: selectedChemical.sk.concat(selectedChemicalCrop.sk).concat(selectedChemicalCropTarget.sk),
      selectedChemical,
      selectedChemicalCrop,
      selectedChemicalCropTarget,
      //name: `${selectedChemical.name}  ${selectedChemicalCrop.name}  ${selectedChemicalCropTarget.name} `
      chemicalSK: selectedChemical.sk,
      cropSK: selectedChemicalCrop.sk,
      targetSK: selectedChemicalCropTarget.sk,
    });
    this.setState({ combination: newCombination, changed: true, selectedChemical: null, selectedChemicalCrop: null, selectedChemicalCropTarget: null });
  }
}

handleAddFert = (combination) => {
  const {
   selectedFertiliser,
  } = this.state;
  const newCombination = { ...combination };
  if (!newCombination.combinationProducts) {
    newCombination.combinationProducts = [];
  }
  const idx = newCombination.combinationProducts.findIndex((c) => c.sk === selectedFertiliser.sk);
  if (idx === -1) {
    newCombination.combinationProducts.push({type: 'fertiliser',
      sk: selectedFertiliser.sk,
      selectedFertiliser,
    });
    this.setState({ combination: newCombination, changed: true, selectedFertiliser: null });
  }
}

getListData = () => {
  const { combination } = this.state;
  const { getFromGlobalState, fertilisers } = this.props;
  const crops = getFromGlobalState('crops');
 
 const data = combination && combination.combinationProducts && combination.combinationProducts.map((product) => {
 if (product.type === 'fertiliser') {
    const ob = {};
    ob.type = product.type;
    ob.sk = product.sk;
    ob.typeText = 'Fertiliser';
    ob.name = getNameFromKey2(product.sk, fertilisers);
    return ob;
  }
  else {
  const targetRows = product.selectedChemicalCrop ? this.generateCropTargetRows(product.selectedChemicalCrop, getFromGlobalState) : [];
  const cropRows = product.selectedChemical ? this.generateCropRows(product.selectedChemical, getFromGlobalState) : [];
  const crop = cropRows.find((c) => c.sk === product.cropSK);
  const target = targetRows.find((c) => c.sk === product.targetSK);
    const ob = {};
    ob.sk = product.sk;
    ob.type = product.type;
    ob.typeText = 'PPP';
    ob.name = `${getNameFromKey2(product.sk, getFromGlobalState('chemicals'))},  ${crop.name},  ${target.name}`;
    return ob;
  }
  });
  return data;
}

async handlePreExecute(combination) {
  const { selectedType } = this.state;
  if (selectedType === 'chemical') {
    this.handleAddPPP(combination);
  }
  if (selectedType === 'fertiliser') {
    this.handleAddFert(combination);
  }
  return true;
}


 generateCropTargetRows = (selectedChemicalCrop, getFromGlobalState) => {
   if (!selectedChemicalCrop) {
     return [];
   }
   const rows = [];
   const pests = this.generateReasonLis(getFromGlobalState('monitorings'));

   for (let t = 0; selectedChemicalCrop.targets && t < selectedChemicalCrop.targets.length; t += 1) {
     rows.push({
       sk: selectedChemicalCrop.targets[t].sk,
       name: getNameFromKey2(selectedChemicalCrop.targets[t].target, pests),
       targetSK: selectedChemicalCrop.targets[t].target,
       isDisabled: selectedChemicalCrop.targets[t].isDisabled ? 'Yes' : 'No',
     });
   }

   return rows;
 }

 render() {
   const {
     classes,
     open,
     isNew,
     setClose,
     handleUpdate,
     getFromGlobalState,
     requiredFields,
     chemicals,
     fertilisers,
   } = this.props;

   const {
     selectedChemical,
     selectedFertiliser,
     selectedType,
     errors,
     changed,
     combination,
     selectedChemicalCrop,
     selectedChemicalCropTarget,
     selectedCombinationProduct,
   } = this.state;

   if (!combination) {
     return null;
   }
   const formValid = validateForm(combination, requiredFields);
   const cropRows = selectedChemical ? this.generateCropRows(selectedChemical, getFromGlobalState) : [];
   const targetRows = selectedChemicalCrop ? this.generateCropTargetRows(selectedChemicalCrop, getFromGlobalState) : [];

   return (

     <GenDialog
       open={open}
       dialogObject={combination}
       setClose={setClose}
       isNew={isNew}
       changed={changed}
       handleUpdateDialogObject={handleUpdate.bind(this)}
       skPrimary="init_setting_"
       dynamoType="combinations"
       valid={formValid}
       newTitle="Products"
       title={combination && combination.name ? combination.name : ''}
       getFromGlobalState={getFromGlobalState}
       handlePreExecute={this.handlePreExecute.bind(this)}
     >
       <Grid container className={classes.root} spacing={1} alignItems="flex-start">
       <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
           <FormControl className={classes.formControl}>
             <InputLabel shrink htmlFor="product">Type</InputLabel>
             <Select
               native
               fullWidth
               required
               displayEmpty
               value={selectedType}
               onChange={this.handleChangeType.bind(this)}
           // onBlur={handleBlur.bind(this)}
           // error={errors.product ? true : undefined}
               inputProps={{
                 name: 'type',
                 id: 'type',
               }}
             >
               <option value="" />
               
                   <option value={'chemical'}>{'Plant Protection Product'}</option>
                   <option value={'fertiliser'}>{'Fertiliser'}</option>
              
             </Select>
           </FormControl>
         </Grid>
        {selectedType && selectedType === 'chemical' && <> <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
           <FormControl className={classes.formControl}>
             <InputLabel shrink htmlFor="product">Name</InputLabel>
             <Select
               native
               fullWidth
               required
               displayEmpty
               value={selectedChemical ? selectedChemical.sk : ''}
               onChange={this.handleChangePPP.bind(this)}
           // onBlur={handleBlur.bind(this)}
           // error={errors.product ? true : undefined}
               inputProps={{
                 name: 'selectedChemical',
                 id: 'selectedChemical',
               }}
             >
               <option value="" />
               {chemicals && chemicals.map((chemical) => (
                 <>
                   <option value={chemical.sk}>{chemical.name}</option>
                 </>
               ))}
             </Select>
           </FormControl>
         </Grid>
         <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
           <FormControl className={classes.formControl}>
             <InputLabel shrink htmlFor="product">Crop</InputLabel>
             <Select
               native
               fullWidth
               required
               displayEmpty
               value={selectedChemicalCrop ? selectedChemicalCrop.sk : ''}
               onChange={this.handleSelectChemicalCrop.bind(this)}
           // onBlur={handleBlur.bind(this)}
           // error={errors.product ? true : undefined}
               inputProps={{
                 name: 'selectedCrop',
                 id: 'selectedCrop',
               }}
             >
               <option value="" />
               {cropRows && cropRows.map((crop) => (
                 <>
                   <option value={crop.sk}>{crop.name}</option>
                 </>
               ))}
             </Select>
           </FormControl>
         </Grid>
         <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
           <FormControl className={classes.formControl}>
             <InputLabel shrink htmlFor="product">Target</InputLabel>
             <Select
               native
               fullWidth
               required
               displayEmpty
               value={selectedChemicalCropTarget ? selectedChemicalCropTarget.sk : ''}
               onChange={this.handleSelectChemicalCropTarget.bind(this)}
           // onBlur={handleBlur.bind(this)}
           // error={errors.product ? true : undefined}
               inputProps={{
                 name: 'selectedChemicalCropTarget',
                 id: 'selectedChemicalCropTarget',
               }}
             >
               <option value="" />
               {targetRows && targetRows.map((target) => (
                 <>
                   <option value={target.sk}>{target.name}</option>
                 </>
               ))}
             </Select>
           </FormControl>
         </Grid>
         </>}
        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
           <div style={{ float: 'right', marginLeft: 'auto' }}>

             <Tooltip title="Add">
               <Fab
                 variant="extended"
                 disabled={!selectedChemicalCropTarget || !selectedChemicalCrop || !selectedChemical }
                 size="medium"
                 color="primary"
                 aria-label="add"
                 className={classes.margin}
                 onClick={this.handleAddPPP.bind(this)}
               >
                 <AddIcon className={classes.extendedIcon} />
                 Add
               </Fab>

             </Tooltip>
           </div>
               </Grid>*/}
        {selectedType && selectedType === 'fertiliser' && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
           <FormControl className={classes.formControl}>
             <InputLabel shrink htmlFor="product">Fertiliser</InputLabel>
             <Select
               native
               fullWidth
               required
               displayEmpty
               value={selectedFertiliser ? selectedFertiliser.sk : ''}
               onChange={this.handleChangeFert.bind(this)}
           // onBlur={handleBlur.bind(this)}
           // error={errors.product ? true : undefined}
               inputProps={{
                 name: 'seletedFertiliser',
                 id: 'seletedFertiliser',
               }}
             >
               <option value="" />
               {fertilisers && fertilisers.map((fertiliser) => (
                 <>
                   <option value={fertiliser.sk}>{fertiliser.name}</option>
                 </>
               ))}
             </Select>
           </FormControl>
         </Grid>
 }
        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
           <div style={{ float: 'right', marginLeft: 'auto' }}>

             <Tooltip title="Add">
               <Fab
                 variant="extended"
                 disabled={!selectedFertiliser}
                 size="medium"
                 color="primary"
                 aria-label="add"
                 className={classes.margin}
                 onClick={this.handleAddFert.bind(this)}
               >
                 <AddIcon className={classes.extendedIcon} />
                 Add
               </Fab>

             </Tooltip>
           </div>
               </Grid>*/}
       {/*  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
         <FormControl className={classes.formControl}>
          <Typography variant="subtitle1"> Products</Typography>
         
          <SingleListControl
          
          data= {this.getListData()}
          onBlur={this.handleBlur}
          checkedItems ={selectedCombinationProduct ? [selectedCombinationProduct]: []}
          handleToggle={this.handleToggle}
         
          />
    
          </FormControl>
          </Grid>
               */}
        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
           <TextField
             margin="normal"
             required
             fullWidth
             id="description"
             label="Description"
             name="description"
             InputLabelProps={{
               shrink: true,
             }}
             value={combination && combination.description}
             onChange={this.handleChange}
             onBlur={this.handleBlur}
             error={errors.name ? true : undefined}
             helperText={errors.name}
           />
            </Grid>*/}
       </Grid>

     </GenDialog>

   );
 }
}

CombinationProductD.defaultProps = {
  requiredFields: [
    { name: 'name', min: 1, max: 40 },
  ],
};
CombinationProductD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  combination: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  combinationType: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  skPrimary: PropTypes.string,
  requiredFields: PropTypes.array,
  chemicals: PropTypes.array.isRequired,
  fertilisers: PropTypes.array.isRequired,

};
export default withStyles(styles)(CombinationProductD);
