import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import { API } from 'aws-amplify';
// import { Storage } from "aws-amplify";

import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
// import './filepond.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
// import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// import FilePondPluginFileRename from 'filepond-plugin-file-rename';
// import config from '../../config';
import { processFile } from '../../libs/storage';

// import { s3Upload } from '../../libs/awsLib';
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(
  FilePondPluginFileValidateSize, FilePondPluginFileValidateType, FilePondPluginImageResize, FilePondPluginImageTransform
);
// registerPlugin(FilePondPluginFileValidateType);
// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview,FilePondPluginImageResize,FilePondPluginImageTransform);

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    backgroundColor: 'inherit',
    // overflow: 'scroll',
  },
  section: {
    margin: theme.spacing(2, 4),
  },
});

class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      files: [],
      thumbFiles: [],
    };
  }

  componentDidMount() {
  // if (!this.props.isAuthenticated) {

    // }
  }

  handleInit() {
    this.setState({ thumbFiles: [] });
    // console.log('FilePond instance has initialised', this.pond);
  }

  handleProgress(e) {
  //  console.log('progresssssss', e);
  }

  handleOnLoad(fieldName, file, metadata, load, error, progress, abort) {
    console.log('onload');
  }

  getTotal(totalOb) {
    let total = 0;
    Object.values(totalOb).forEach((value) => total += value);
    // console.log('total', total);
    return total;
  }

   handleProcessing = (fieldName, file, metadata, load, error, progress, abort, forceName, fileName, isGeneral, setId) => {
     // const s3Instance = this.props.getFromGlobalState('s3Instance');
     const progTotals = { loaded: {}, total: 0 };
     const path = this.props.folder;

     let fileTemp = [];
     if (!Array.isArray(file)) {
       fileTemp.push(file);
     } else {
       fileTemp = file.map((fileItem) => {
         const newFileItem = { ...fileItem };
         newFileItem.isThumb = false;
         newFileItem.isPreview = false;
         if (newFileItem.name === 'thumb') {
           newFileItem.file.isThumb = true;
         }
         if (newFileItem.name === 'preview') {
           newFileItem.file.isPreview = true;
         }
         return newFileItem.file;
       });
     }
     fileTemp.forEach((fileIt) => {
       let Key = path;
       if (fileIt.isThumb) {
         Key = `${path}${this.props.thumbNailFolder}/`;
       }
       if (fileIt.isPreview) {
         Key = `${path}preview/`;
       }
       let filename = `${path}${fileIt.name}`;
       if (forceName) {
         const extension = fileIt.name.substring(fileIt.name.indexOf('.'), fileIt.name.length);
         filename = `${path}${fileName}${extension}`;
       }

       // if (this.props.generateIcon){
       //   filename = `${path}${this.props.thumbNailFolder}/icon.svg`;
       //  }
       // console.log('fileName', filename);
       progTotals.total += fileIt.size;
       // const nFile
       // fileIt.name = filename;
       // fieldName, file, metadata,activeTenant,path, load, error, progress, abort
       try { // fieldName, file, metadata,activeTenant,path, load, error, progress, abort
         const result = processFile(undefined, fileIt, undefined, this.props.activeTenant, filename, load, error, progress, abort, isGeneral, setId);
       } catch (e) {
         throw (e);
       }
     });
   }

   render() {
     const {
       acceptedFileTypes,
       maxFileSize,
       maxFiles,
       forceName,
       fileName,
       allowFileTypeValidation,
       imageTransformVariantsIncludeOriginal,
       imageResizeTargetWidth,
       imageResizeTargetHeight,
       allowImageResize,
       allowImageTransform,
       allowImageExifOrientation,
       onFileUploadStart,
       allowMultiple,
       isGeneral,
       setId,
       onError,
       imageTransformOutputQuality,
       error
     } = this.props;

     return (
       <div className="App">
         {/* Pass FilePond properties as attributes */}
         <FilePond
           ref={(ref) => (this.pond = ref)}
           allowImagePreview={false}
           allowFileTypeValidation={allowFileTypeValidation}
           credits={false}
           // imageTransformCanvasMemoryLimit={4096 * 4096}
           imageTransformOutputQuality={imageTransformOutputQuality}
           maxFileSize={maxFileSize}
           acceptedFileTypes={acceptedFileTypes}
           files={this.state.files}
           allowMultiple={allowMultiple}
           allowImageResize={allowImageResize}
           allowImageTransform={allowImageTransform}
           allowImageExifOrientation={allowImageExifOrientation}

           maxFiles={this.props.generateIcon ? 1 : maxFiles}
           server={{
             process: (fieldName, file, metadata, load, error, progress, abort) => {
               this.handleProcessing(fieldName, file, metadata, load, error, progress, abort, forceName, fileName, isGeneral, setId);
             },
           }}
           oninit={() => this.handleInit()}
           labelFileProcessingError={function (serverError) {
             console.log('serverError', serverError);
              if(error){
               onError(error)
              return `Upload error: ${serverError.message}`;
              }
             onError && onError(serverError);
             return `Upload error: ${serverError.message}`;
           }}
           imageResizeTargetWidth={allowImageResize ? imageResizeTargetWidth : undefined}
           imageResizeTargetHeight={allowImageResize ? imageResizeTargetHeight : undefined}
           imageResizeMode={allowImageResize ? 'contain' : undefined}
         // imageTransformVariantsDefaultName={generateThumbnail ? 'thumb' : undefined}
          // imageTransformVariantsIncludeDefault
          // imageTransformVariantsIncludeOriginal={imageTransformVariantsIncludeOriginal}
           oninitfile={(file) => {
             onFileUploadStart && onFileUploadStart(file);
             //   console.log(err, fileItem.getMetadata('resize'));
           }}
           onaddfile={(err, fileItem) => {
             if (err && onError) {
               onError(err);
             }
           }}
           
           onpreparefile={(fileItem, output) => {
       /////     onFileUploadStart && onFileUploadStart();
             // create a new image object
             // const img = new Image();
             // let newFileItem = {...fileItem};
           //  console.log('prepare', fileItem);
             // newFileItem.file.name = `thumb256-${fileItem.name}`;
             // this.pond.addFile(fileItem.file);
             // let thumbs = this.state.thumbFiles;
             // thumbs.push(fileItem.file);
             // this.setState({thumbFiles: thumbs });

             // set the image source to the output of the Image Transform plugin
             // img.src = URL.createObjectURL(output);

             // add it to the DOM so we can see the result
             //  document.body.appendChild(img);
           }}
           onprocessfile={(error, file) => {
             // Set currently active file objects to this.state
              if (error && onError) {
               onError(error);
             }
             this.props.onFileUploaded && this.props.onFileUploaded(file, this.state.files);

             console.log('processfile', file.serverId);
           }}

           // uncomment if issue with uploader - removed setting the state!!!

           onupdatefiles={(fileItems) => {
             // const files = fileItems.filter.map((fileItem) => fileItem.file);
             //  onFileUploadStart && onFileUploadStart(fileItems);
           }}

          /* onupdatefiles={(fileItems) => {
             const files = fileItems.filter.map((fileItem) => fileItem.file);
             this.setState({ files }, () => {
               if (onFileUploadStart && files && files.length) {
                 onFileUploadStart(fileItems);
               }
             });
           }} */
         />

       </div>

     );
   }
}
FileUploader.defaultProps = {
  maxFiles: 20,
  generatePreview: true,
  imageTransformVariantsIncludeOriginal: true,
  thumbNailFolder: 'thumbs',
  containerImageFileName: '',
  imageResizeTargetWidth: 800,
  imageResizeTargetHeight: 180,
  allowImagePreview: true,
  allowImageResize: true,
  allowImageTransform: true,
  allowImageExifOrientation: true,
  generateIcon: false,
  onFileUploadStart: null,
  allowMultiple: true,
  allowFileTypeValidation: false,
  acceptedFileTypes: [],
  forceName: false,
  fileName: null,
  maxFileSize: '20MB',
  isGeneral: false,
  setId: '',
  onError: null,
  imageTransformOutputQuality: null,

};
FileUploader.propTypes = {
  classes: PropTypes.object.isRequired,
  folder: PropTypes.string.isRequired,
  onFileUploaded: PropTypes.func.isRequired,
  onFileUploadStart: PropTypes.func,
  generateThumbnail: PropTypes.bool.isRequired,
  generatePreview: PropTypes.bool,
  generateIcon: PropTypes.bool,
  maxFiles: PropTypes.number,
  thumbNailFolder: PropTypes.string,
  imageTransformVariantsIncludeOriginal: PropTypes.bool,
  containerImageFileName: PropTypes.string,
  imageResizeTargetWidth: PropTypes.number,
  imageResizeTargetHeight: PropTypes.number,
  allowImagePreview: PropTypes.bool,
  allowImageResize: PropTypes.bool,
  allowImageTransform: PropTypes.bool,
  allowImageExifOrientation: PropTypes.bool,
  activeTenant: PropTypes.string.isRequired,
  allowMultiple: PropTypes.bool,
  allowFileTypeValidation: PropTypes.bool,
  acceptedFileTypes: PropTypes.array,
  forceName: PropTypes.bool,
  fileName: PropTypes.string,
  maxFileSize: PropTypes.string,
  isGeneral: PropTypes.bool,
  setId: PropTypes.string,
  onError: PropTypes.func,
  imageTransformOutputQuality: PropTypes.number,
  error:PropTypes.any
};
export default withStyles(styles)(FileUploader);
