import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '360px',
    // width: '360px',
    backgroundColor: theme.palette.background.paper,
    maxHeight: '300px',
    // height: '150px',
    overflowY: 'auto',
  },
  rootLoad: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function SingleListTargets(props) {
  const {
    data, handleToggle, checkedItems, arrayName, planting, loading, hideCheck, onSelectAll, allSelected,
  } = props;

  const classes = useStyles();

  return (
    <>
      {loading && (
      <div className={classes.rootLoad}>
        {' '}
        <CircularProgress color="secondary" />
        {' '}
      </div>
      )}
      {onSelectAll && (
      <FormControlLabel
        value="top"
        style={{ float: 'right' }}
        control={(
          <Checkbox
           // edge="start"
            checked={allSelected}
            onChange={onSelectAll}
           // tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': 'SelectAll' }}
          />
)}
        label="Select All"
        labelPlacement="start"
      />
      )}
      <List className={classes.root}>
        {data && data.map((value) => {
          const labelId = `checkbox-list-label-${value.sk}`;

          return (
            <ListItem className={classes.nested} disableRipple disabled={value.disabled} key={value.sk} role={undefined} dense button onClick={handleToggle && handleToggle.bind(this, value.sk, arrayName, planting)}>
              {!hideCheck && !value.disabled && (
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checkedItems && checkedItems.indexOf(value.sk) !== -1}
                  tabIndex={-1}
                  disableRipple
                  disabled={!!value.disabled}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              )}
              <ListItemText id={labelId} primary={`${value.name}`} />
            </ListItem>
          );
        })}
      </List>
    </>

  );
}
SingleListTargets.defaultProps = {
  onSelectAll: null,
  allSelected: false,
};
SingleListTargets.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  checkedItems: PropTypes.array.isRequired,
  handleToggle: PropTypes.func.isRequired,
  arrayName: PropTypes.string.isRequired,
  planting: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  hideCheck: PropTypes.bool.isRequired,
  onSelectAll: PropTypes.func,
  allSelected: PropTypes.bool,
};
