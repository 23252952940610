import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GenDialog from '../../components/GenDialog';

const styles = (theme) => ({
    paper: {
      marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      display: 'flex',
    flexWrap: 'wrap',
    },
    formControl: {
      minWidth: 220,
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    helperText :{
      color: 'red',
    },
    
  });

  
/*const requiredFields = [
    {name:'crop', min: 1, max: 40},
    {name:'variety', min: 1, max: 40},
    {name:'plantSpacing', min: 1, max: 40},
    {name:'plantDensity', min: 1, max: 40},
    {name:'plantDate', min: 1, max: 40},

    
  ];*/


class ShowAllD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      record: {},
      errors: {count:0},
      
    
    };
  }
  
  componentDidMount() {
    
  }
  
   handleSelect = (event, product, selectedInventoryItemID,actionType,handleSelect) =>{
    
 
  }
  
  render() {
    const { open, isNew, setClose, title,children,onGeneratePDF,pdfRequested, reportPDF, getFromGlobalState, subTitle, reportCSV, csvRequested, onGenerateCSV } = this.props;
    
   

    return (
 
      <GenDialog
      open={open}
      dialogObject={undefined}
      setClose={setClose}
      isNew={isNew}
      disableActions
      handleUpdateDialogObject={undefined}
      skPrimary={undefined}
      dynamoType={undefined}
      valid={undefined}
      title={title}
      subTitle={subTitle}
      maxWidth='lg'
      parentKey={undefined}
      plantingKey={undefined}
      onGeneratePDF={onGeneratePDF}
      pdfRequested={pdfRequested}
      reportPDF={reportPDF}
      onGenerateCSV={onGenerateCSV}
      csvRequested={csvRequested}
      reportCSV={reportCSV}
      getFromGlobalState={getFromGlobalState}
     
      >
        {children}
       

      </GenDialog>
     
    );
  }
}
ShowAllD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  recordType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  planting: PropTypes.array.isRequired,
  block:PropTypes.array.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  onGeneratePDF:PropTypes.func,
  reportPDF:PropTypes.any,
  pdfRequested:PropTypes.bool,
  csvRequested:PropTypes.bool,
  onGenerateCSV:PropTypes.func,
  reportCSV:PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  

};
export default withStyles(styles)(ShowAllD);
