import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import GenDialog from '../../components/GenDialog';
import { validate, validateForm } from '../../libs/validate';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

const requiredFields = [
  { name: 'name', min: 1, max: 40 },
];

class FilterD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNew: this.props.isNew,
      filter: {},
      errors: { count: 0 },
      changed: false,

    };
  }

  componentDidMount() {
    const { filter } = this.props;
    this.setState({ filter });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const filter = { ...this.state.filter };
    filter[event.target.id] = event.target.value;
    this.setState({ filter, changed: true });
  }

  handleCheckChange = (event) => {
    const filter = { ...this.state.filter };
    filter[event.target.id] = event.target.checked;
    this.setState({ filter, changed: true });
  }

  handleSaveAsNew = (event) => {
    const filter = { ...this.state.filter };
    filter[event.target.id] = event.target.checked;
    filter.name = '';
    filter.default = false;
    this.setState({ filter, changed: true, isNew: true });
  }

  validateField(field) {
    const error = validate(field, this.state.filter, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  render() {
    const {
      open, setClose, handleUpdate, getFromGlobalState,
    } = this.props;
    const {
      filter, errors, changed, isNew,
    } = this.state;
    const formValid = validateForm(filter, requiredFields);
    if (!filter) {
      return null;
    }

    return (

      <GenDialog
        open={open}
        dialogObject={filter}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary="init_setting_"
        dynamoType="filters"
        valid={formValid}
        changed={changed}
        newTitle="New Filter"
        title="Filter"
        getFromGlobalState={getFromGlobalState}
      >
        <FormControlLabel
          control={(
            <Checkbox
              checked={filter.saveAsNew}
                 // disabled={isInvite}
              id="saveAsNew"
              onChange={this.handleSaveAsNew}
              // value="default"
              color="secondary"
            />
        )}
          label="Save as New"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Filter"
          name="name"
          autoFocus
          value={filter.name}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.name ? true : undefined}
          helperText={errors.name}
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={filter.default}
                 // disabled={isInvite}
              id="default"
              onChange={this.handleCheckChange}
              // value="default"
              color="secondary"
            />
        )}
          label="Default"
        />

      </GenDialog>

    );
  }
}
FilterD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(FilterD);
