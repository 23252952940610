import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import OperatorSelector from '../../components/OperatorSelector';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
});


function AgronomyActForm(props) {
  const { classes, record, errors, handleBlur, handleChange,metaData } = props;
  const agronomistActs = metaData.agronomistActs;
  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    <TextField
    margin="normal"
    required

    fullWidth
    type="date"
   // defaultValue={new Date()}
    id="date"
    label="Date"
    name="date"
    InputLabelProps={{
      shrink: true,
    }}
    value={record.date}
    onChange={handleChange.bind(this)}
    onBlur={handleBlur}
    error={errors.date ? true : undefined}
    helperText={errors.date}
  />
  </Grid>
      <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="activities">Activity</InputLabel>
          <Select
          native
          fullWidth 
          required
          displayEmpty
          value={record.activity}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur.bind(this)}
          error={errors.activity? true : undefined}
          inputProps={{
            name: 'activity',
            id: 'activity',
          }}
        >
          <option value=""></option>
          {agronomistActs && agronomistActs.map ((activity) => 
          <>
          <option value={activity.sk}>{activity.name}</option>
          </>
     )}
        </Select>
        {errors.activity&& <FormHelperText className={classes.helperText}>{errors.activity}</FormHelperText>}
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
  </Grid>
  
 
  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <OperatorSelector
          onChange={handleChange.bind(this)}
          operators={metaData.operators}
          title="Operator"
          value={record.operator}
          team={record.team}
          onBlur={handleBlur}
          error={errors.operator}
        />
      </Grid>
  </Grid>
  );
}

AgronomyActForm.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
  planting:PropTypes.object.isRequired,
};

export default withStyles(styles)(AgronomyActForm);
