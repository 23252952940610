import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import InfoBox from '../../components/InfoBox';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    // minWidth: 120,
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
});

const requiredFields = [
  { name: 'name', min: 1, max: 40 },
  { name: 'surname', min: 1, max: 40 },
  { name: 'email', min: 1, max: 40 },
  {
    name: 'rights', min: 2, max: 400, type: 'object',
  },
];

class UserD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      user: {
        rights: {
          administrator: false,
          manageInventory: false,
          createActivities: false,
          allowedActivities: [],
          changeSettings: false,
          readOnly: true,
          viewDashboard: false,
          industryView: false,
        },
        enabled: true,
      },
      errors: { count: 0 },
      changed: false,

    };
  }

  componentDidMount() {
    if (!this.props.isNew) {
      const { user } = this.props;
      this.setState({ user });
    }
  }

  validateField(field, requiredFields) {
    const error = validate(field, this.state.user, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const user = { ...this.state.user };
    user[event.target.id] = event.target.value;
    this.setState({ user, changed: true });
  }

  handleToggleActivities = (name) => (event) => {
    const user = { ...this.state.user };
    if (!user.rights) {
      user.rights = {};
    }
    if (!user.rights.allowedActivities) {
      user.rights.allowedActivities = [];
      user.rights.createActivities = true;
    }
    if (event.target.checked) {
      user.rights.allowedActivities.push(name);
    } else {
      const index = user.rights.allowedActivities.indexOf(name);
      if (index > -1) {
        user.rights.allowedActivities.splice(index, 1);
      }
      if (user.rights.allowedActivities.length === 0) {
        user.rights.createActivities = false;
      }
    }
    this.setState({ user, changed: true });
  };

  handleToggle = (name) => (event) => {
    const user = { ...this.state.user };
    if (!user.rights) {
      user.rights = {};
    }
    user.rights[name] = event.target.checked;
    if (name === 'administrator') {
      user.rights.manageInventory = user.rights.administrator;
      user.rights.createActivities = user.rights.administrator;
      user.rights.readOnly = false;
      user.rights.changeSettings = user.rights.administrator;
      user.rights.viewDashboard = user.rights.administrator;
      user.rights.industryView = false;
      if (event.target.checked) {
        user.rights.allowedActivities = [];
        user.rights.allowedActivities.push('fertilisings');
        user.rights.allowedActivities.push('sprayings');
        user.rights.allowedActivities.push('waterings');
        user.rights.allowedActivities.push('harvestings');
        user.rights.allowedActivities.push('pestings');
        user.rights.allowedActivities.push('activities');
      } else {
        user.rights.allowedActivities = [];
      }
    }
    if (name === 'industryView') {
      user.rights.manageInventory = false;
      user.rights.createActivities = false;
      user.rights.readOnly = false;
      user.rights.changeSettings = false;
    }
    if (name === 'manageInventory') {
      //user.rights.readOnly = false;
    }
    if (name === 'changeSettings') {
      //user.rights.readOnly = false;
    }
    if (name === 'viewDashboard') {
      user.rights.viewDashboard = event.target.checked;
    }
    if (name === 'createActivities') {
      //user.rights.readOnly = false;
      if (user.rights.allowedActivities) {
        user.rights.allowedActivities = [];
      }
      if (event.target.checked) {
        user.rights.allowedActivities.push('fertilisings');
        user.rights.allowedActivities.push('sprayings');
        user.rights.allowedActivities.push('waterings');
        user.rights.allowedActivities.push('harvestings');
        user.rights.allowedActivities.push('pestings');
        user.rights.allowedActivities.push('activities');
      } else {
        user.rights.allowedActivities = [];
      }
    }
    if (name === 'readOnly') {
      user.rights.administrator = false;
      //user.rights.manageInventory = false;
      //user.rights.createActivities = false;
      //user.rights.changeSettings = false;
      user.rights.industryView = false;
    }
    this.setState({ user, changed: true });
  };

  handleEnableToggle = (name) => (event) => {
    const user = { ...this.state.user };
    user[name] = event.target.checked;

    this.setState({ user, changed: true });
  };

  render() {
    const {
      classes, open, isNew, setClose, handleSubmitAddExistingUser, handleUpdate, allowDelete, handleDeletePreExecute, handleDeleteDialogObject, handlePreExecute, getFromGlobalState,
    } = this.props;
    const { user, errors } = this.state;
    const isInvite = user.type && (user.type === 'SentSharedInvite' || user.type === 'SentInvite');
    const formValid = validateForm(user, requiredFields);
    const userDetail = getFromGlobalState('userDetail');
    const canDelete = userDetail.sk !== user.sk;
    return (

      <GenDialog
        open={open}
        dialogObject={user}
        setClose={setClose}
        setSnack={this.props.setSnack}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        handlePreExecute={handlePreExecute.bind(this)}
        skPrimary="init_user_"
        dynamoType="users"
        valid={isInvite ? false : formValid}
        deleteLabel="Are you sure you want to remove this user ?"
        changed={this.state.changed}
        newTitle="New User"
        title={user?.name}
        getFromGlobalState={getFromGlobalState}
        rejectedUser={user.rejected}
        handleSubmitAddExistingUser={handleSubmitAddExistingUser}
        allowDelete={allowDelete && handleDeleteDialogObject && canDelete}
        handleDeletePreExecute={handleDeletePreExecute}
        handleDeleteDialogObject={handleDeleteDialogObject ? handleDeleteDialogObject.bind(this) : undefined}
      >
        {isInvite && <InfoBox message="Invitiations can not be edited once sent. You can edit the user post acceptance or you can delete the invitation." />}
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          disabled={isInvite}
          autoFocus
          value={user?.name}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.name ? true : undefined}
          helperText={errors.name}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="surname"
          label="Surname"
          disabled={isInvite}
          name="surname"
           // autoFocus
          value={user.surname}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.surname ? true : undefined}
          helperText={errors.surname}
        />
        <TextField
          margin="normal"
          fullWidth
          id="email"
          label="eMail"
          disabled={isInvite || (isNew ? undefined : true)}
          name="email"
          type="email"
          inputProps={{ autocapitalise: 'none' }}
          value={user?.email}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.email ? true : undefined}
          helperText={errors.email}
        />

        <TextField
          margin="normal"
          // required
          fullWidth
          id="group"
          label="Group"
          name="group"
           // autoFocus
          value={user?.group}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.group ? true : undefined}
          helperText={errors.group}
        />
        <FormControl fullWidth component="fieldset" disabled={isInvite} className={classes.formControl}>
          <FormLabel component="legend">Access rights</FormLabel>
          <FormControlLabel
            control={(
              <Checkbox

                checked={user.rights && user.rights.readOnly}
                disabled={user.rights && user.rights.administrator && user.rights.administrator === true ? true : undefined}
                name="readOnly"
                onChange={this.handleToggle('readOnly')}
                value="readOnly"
                color="secondary"
              />
        )}
            label="Read-only"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={user.rights && user.rights.administrator}

                name="administrator"
                onChange={this.handleToggle('administrator')}
                value="administrator"
                color="secondary"
              />
        )}
            label="Administrator (change everything)"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={user.rights && user.rights.changeSettings}
                disabled={user.rights && user.rights.administrator && user.rights.administrator === true ? true : undefined}
                name="changeSettings"
                onChange={this.handleToggle('changeSettings')}
                value="changeSettings"
                color="secondary"
              />
        )}
            label="Settings and documents"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={user.rights && user.rights.viewDashboard}
                disabled={user.rights && user.rights.administrator && user.rights.administrator === true ? true : undefined}
                name="viewDashboard"
                onChange={this.handleToggle('viewDashboard')}
                value="viewDashboard"
                color="secondary"
              />
        )}
            label="Dashboard and reports"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={user.rights && user.rights.manageInventory}
                disabled={user.rights && user.rights.administrator && user.rights.administrator === true ? true : undefined}
                name="manageInventory"
                onChange={this.handleToggle('manageInventory')}
                value="manageInventory"
                color="secondary"
              />
        )}
            label="Inventory"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={user.rights && user.rights.createActivities}
                disabled={isInvite || (user.rights && user.rights.administrator && user.rights.administrator === true ? true : undefined)}
                name="createActivities"
                onChange={this.handleToggle('createActivities')}
                value="createActivities"
                color="secondary"
              />
        )}
            label="Records"
          />
          {user.rights && user.rights.createActivities && (
          <div style={{ marginLeft: '24px' }}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={user.rights && user.rights.allowedActivities && user.rights.allowedActivities.includes('fertilisings')}
                  disabled={isInvite || (user.rights && user.rights.administrator && user.rights.administrator === true ? true : undefined)}
                  name="fertilisings"
                  onChange={this.handleToggleActivities('fertilisings')}
                  value="fertilisings"
                  color="secondary"
                />
        )}
              label="Fertiliser"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={user.rights && user.rights.allowedActivities && user.rights.allowedActivities.includes('sprayings')}
                  disabled={isInvite || (user.rights && user.rights.administrator && user.rights.administrator === true ? true : undefined)}
                  name="sprayings"
                  onChange={this.handleToggleActivities('sprayings')}
                  value="sprayings"
                  color="secondary"
                />
        )}
              label="Plant Protection"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={user.rights && user.rights.allowedActivities && user.rights.allowedActivities.includes('waterings')}
                  disabled={isInvite || (user.rights && user.rights.administrator && user.rights.administrator === true ? true : undefined)}
                  name="waterings"
                  onChange={this.handleToggleActivities('waterings')}
                  value="waterings"
                  color="secondary"
                />
        )}
              label="Irrigation"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={user.rights && user.rights.allowedActivities && user.rights.allowedActivities.includes('harvestings')}
                  disabled={isInvite || (user.rights && user.rights.administrator && user.rights.administrator === true ? true : undefined)}
                  name="harvestings"
                  onChange={this.handleToggleActivities('harvestings')}
                  value="harvestings"
                  color="secondary"
                />
        )}
              label="Harvest"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={user.rights && user.rights.allowedActivities && user.rights.allowedActivities.includes('pestings')}
                  disabled={isInvite || (user.rights && user.rights.administrator && user.rights.administrator === true ? true : undefined)}
                  name="pestings"
                  onChange={this.handleToggleActivities('pestings')}
                  value="pestings"
                  color="secondary"
                />
        )}
              label="Monitoring"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={user.rights && user.rights.allowedActivities && user.rights.allowedActivities.includes('activities')}
                  disabled={isInvite || (user.rights && user.rights.administrator && user.rights.administrator === true ? true : undefined)}
                  name="activities"
                  onChange={this.handleToggleActivities('activities')}
                  value="activities"
                  color="secondary"
                />
        )}
              label="Agronomic Activities"
            />
          </div>
          )}

          <FormControlLabel
            control={(
              <Checkbox
                checked={user.rights && user.rights.industryView}
                disabled={isInvite || (user.rights && user.rights.administrator && user.rights.administrator === true ? true : undefined)}
                name="industryView"
                onChange={this.handleToggle('industryView')}
                value="industryView"
                color="secondary"
              />
        )}
            label="Industry view"
          />

        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          {!user.shared && <FormLabel component="legend">Platform Status</FormLabel>}
          {user.shared && <FormLabel component="legend">Farm Status</FormLabel>}
          <FormControlLabel
            control={(
              <Checkbox
                checked={isInvite ? false : user.enabled}
                disabled={isInvite}
                id="enabled"
                onChange={this.handleEnableToggle('enabled')}
                value="enabled"
                color="secondary"
              />
        )}
            label="Enabled"
          />
        </FormControl>

      </GenDialog>

    );
  }
}
UserD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  handlePreExecute: PropTypes.func,
  getFromGlobalState: PropTypes.func.isRequired,
  allowDelete: PropTypes.bool.isRequired,
  handleDeletePreExecute: PropTypes.func,
  handleSubmitAddExistingUser:PropTypes.func,
  setSnack:PropTypes.func

};
export default withStyles(styles)(UserD);
