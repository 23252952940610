import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import FilterIcon from '@material-ui/icons/FilterList';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SingleListControl from '../SingleListControl';
import ExpansionPanelDetailed from '../ExpansionPanelDetail';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '180px',
    '& > *': {
      // margin: theme.spacing(1),
      padding: theme.spacing(1),

    },
  },
  paper: {
    // width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
}));

export default function FilterBox(props) {
  const classes = useStyles();
  const {
    metaData, handleToggle, selectedData, expandedSetting, handleExpand,
  } = props;
  return (

    <div>
      <ExpansionPanelDetailed
        heading="Country"
        expanded={expandedSetting}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData.countries.length} />
        )}
        expandIcon={<FilterIcon />}
      >

        <SingleListControl
          data={metaData.countries}
          // onBlur={this.handleBlur}
          checkedItems={selectedData.countries}
          handleToggle={handleToggle}
          arrayName="countries"
        />

      </ExpansionPanelDetailed>

      { /* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Region</InputLabel>
              <SingleListControl
                data={metaData.regions}
          // onBlur={this.handleBlur}
                checkedItems={selectedData.regions}
                handleToggle={handleToggle}
                arrayName="regions"
              />
            </FormControl>
  </Grid> */}
      <ExpansionPanelDetailed
        heading="Province"
        expanded={expandedSetting}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData.provinces.length} />
        )}
        expandIcon={<FilterIcon />}
      >

        <SingleListControl
          data={metaData.provinces}
          // onBlur={this.handleBlur}
          checkedItems={selectedData.provinces}
          handleToggle={handleToggle}
          arrayName="provinces"
        />

      </ExpansionPanelDetailed>
      <ExpansionPanelDetailed
        heading="Crop Type"
        expanded={expandedSetting}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData.cropTypes.length} />
        )}
        expandIcon={<FilterIcon />}
      >

        <SingleListControl
          data={metaData.cropTypes}
          // onBlur={this.handleBlur}
          checkedItems={selectedData.cropTypes}
          handleToggle={handleToggle}
          arrayName="cropTypes"
        />

      </ExpansionPanelDetailed>
      <ExpansionPanelDetailed
        heading="Crop"
        expanded={expandedSetting}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData.crops.length} />
        )}
        expandIcon={<FilterIcon />}
      >

        <SingleListControl
          data={metaData.crops}
          // onBlur={this.handleBlur}
          checkedItems={selectedData.crops}
          handleToggle={handleToggle}
          arrayName="crops"
        />

      </ExpansionPanelDetailed>
      <ExpansionPanelDetailed
        heading="Cultivar type"
        expanded={expandedSetting}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData.cultivarTypes.length} />
        )}
        expandIcon={<FilterIcon />}
      >

        <SingleListControl
          data={metaData.cultivarTypes}
          // onBlur={this.handleBlur}
          checkedItems={selectedData.cultivarTypes}
          handleToggle={handleToggle}
          arrayName="cultivarTypes"
        />

      </ExpansionPanelDetailed>
      <ExpansionPanelDetailed
        heading="Cultivar"
        expanded={expandedSetting}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData.cultivars.length} />
        )}
        expandIcon={<FilterIcon />}
      >

        <SingleListControl
          data={metaData.cultivars}
          // onBlur={this.handleBlur}
          checkedItems={selectedData.cultivars}
          handleToggle={handleToggle}
          arrayName="cultivars"
        />

      </ExpansionPanelDetailed>
      <ExpansionPanelDetailed
        heading="Rootstock type"
        expanded={expandedSetting}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData.rootStockTypes.length} />
        )}
        expandIcon={<FilterIcon />}
      >

        <SingleListControl
          data={metaData.rootStockTypes}
          // onBlur={this.handleBlur}
          checkedItems={selectedData.rootStockTypes}
          handleToggle={handleToggle}
          arrayName="rootStockTypes"
        />

      </ExpansionPanelDetailed>
      <ExpansionPanelDetailed
        heading="Rootstock"
        expanded={expandedSetting}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData.rootStocks.length} />
        )}
        expandIcon={<FilterIcon />}
      >

        <SingleListControl
          data={metaData.rootStocks}
          // onBlur={this.handleBlur}
          checkedItems={selectedData.rootStocks}
          handleToggle={handleToggle}
          arrayName="rootStocks"
        />

      </ExpansionPanelDetailed>
     {/* <ExpansionPanelDetailed
        heading="Irrigation Types"
        expanded={expandedSetting}
        handleExpand={handleExpand}
        detailComponent={(
          <Badge color="secondary" badgeContent={selectedData.irrigationTypes.length} />
        )}
        expandIcon={<FilterIcon />}
      >

        <SingleListControl
          data={metaData.irrigationTypes}
          // onBlur={this.handleBlur}
          checkedItems={selectedData.irrigationTypes}
          handleToggle={handleToggle}
          arrayName="irrigationTypes"
        />

        </ExpansionPanelDetailed>*/}

    </div>

  );
}
FilterBox.propTypes = {
  metaData: PropTypes.object.isRequired,
  selectedData: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
  expandedSetting: PropTypes.string.isRequired,
  handleExpand: PropTypes.func.isRequired,

};
