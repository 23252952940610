import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
  group: {
    //  display: 'flex';
    // flex-wrap: 'wrap';
    flexDirection: 'row',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },

});


const requiredFields = [
  { name: 'product', min: 1, max: 100 },
  { name: 'inout', min: 1, max: 10 },
  { name: 'amount', min: 0, max: 1000000000, type: 'number' },
  { name: 'unit', min: 1, max: 20 },
  { name: 'reason', min: 1, max: 100 },
  { name: 'date', min: 1, max: 100 },

];


class InventoryItemD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      inventoryRequest: this.props.inventoryRequest,
      errors: { count: 0 },
      changed: false,

    };
  }

  componentDidMount() {
    if (this.props.isNew){
      this.setState({ inventoryRequest: this.props.inventoryRequest });
    }
    else {
      let inventoryRequest = this.props.inventoryRequest;
      this.setState({ inventoryRequest });
    }
    this.setAmountMax();
  }


  

  handleBlur = (event) => {
    return this.validateField (event.target.id, requiredFields);
  }


  handleChange = (event) => {
    const inventoryRequest = { ...this.state.inventoryRequest };
    inventoryRequest[event.target.id] = event.target.value;
    this.setState({ inventoryRequest,changed: true });
  }
  handleRadioChange = (name,event) => {
    let inventoryRequest = { ...this.state.inventoryRequest };
    inventoryRequest[name] = event.target.value;
    this.setState({ inventoryRequest ,changed: true });
  }
  handleToggle = name => event => {
    let inventoryRequest = { ...this.state.inventoryRequest };
    inventoryRequest[name] = event.target.checked;
    this.setState({ inventoryRequest,changed: true });
  };

  setAmountMax = () => {
    const { inventoryRequest } = this.state;
    if (inventoryRequest.totalAmount && inventoryRequest.applicationTotal) {
      const amountValidation = requiredFields.find((f) => f.name === 'amount');
      amountValidation.max = inventoryRequest.totalAmount - inventoryRequest.applicationTotal;
    }
  };

  validateField(field) {
    const { inventoryRequest, errors } = this.state;
    // this.setAmountMax();
    const error = validate(field, inventoryRequest, requiredFields);
    const newErrors = { ...errors };
    newErrors[field] = error.msg;
    this.setState({ errors: newErrors });
  }

  /* handleToggle = (value,relation,parentId) => {

    const currentIndex = this.state.inventoryRequest.allowedVarieties.indexOf(value);
    const newChemical = {...this.state.inventoryRequest};

    if (currentIndex === -1) {
      newChemical.allowedVarieties.push(value);
    } else {
      newChemical.allowedVarieties.splice(currentIndex, 1);
    }

    this.setState ({inventoryRequest : newChemical});

  } */




  render() {
    const {
      open, isNew, setClose, handleUpdate, dynamoType, getFromGlobalState, // product, handlePreExecute,
    } = this.props;
    const { inventoryRequest, changed, errors } = this.state;
    const formValid = validateForm(inventoryRequest, requiredFields);
    // inventoryRequest.product = product.sk;

    return (

      <GenDialog
        open={open}
        dialogObject={inventoryRequest}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
       // handlePreExecute={handlePreExecute.bind(this)}
        skPrimary="_inventory_"
        dynamoType={dynamoType}
        valid={formValid}
        changed={changed}
        newTitle="New Entry"
        title="Inventory Request"
        parentKey={inventoryRequest.activity}
        getFromGlobalState={getFromGlobalState}
      >
        <TextField
          margin="normal"
          readOnly
          fullWidth
          type="number"
          // inputProps={{ min: '0.00001', step: 'any' }}
          inputProps={{ step: '0.001' }}
          id="amount"
          label={`Total Amount for application in ${inventoryRequest.unit}`}
          name="amount"
          disabled
          value={inventoryRequest.totalAmount}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.totalAmount ? true : undefined}
          helperText={errors.totalAmount}
        />
        <TextField
          margin="normal"
          readOnly
          fullWidth
          type="number"
          //inputProps={{ min: '0.00001', step: 'any' }}
          id="applicationTotal"
          label={`Already taken from inventory`}
          name="amount"
          disabled
          value={inventoryRequest.applicationTotal}
          //onChange={this.handleChange}
          //onBlur={this.handleBlur}
          error={errors.applicationTotal ? true : undefined}
          helperText={errors.applicationTotal}
        />
        { /* {product.unit === 'gram'
        && (
        <FormControl fullWidth component="fieldset" className={classes.formControlRadio}>
          <FormLabel required>Unit</FormLabel>
          <RadioGroup
            aria-label="unit"
            name="unit"
            readOnly
            id="unit"
            className={classes.group}
            value={inventoryRequest.unit}
            onChange={this.handleRadioChange.bind(this,'unit')}
          >
            <FormControlLabel value="kg" control={<Radio />} label="kg" />
            <FormControlLabel value="gram" control={<Radio />} label="gram" />
            {errors.unit && <FormHelperText className={classes.helperText}>{errors.unit}</FormHelperText>}
          </RadioGroup>
        </FormControl>
        )}

        {product.unit === 'ml'
        && (
        <FormControl fullWidth component="fieldset" className={classes.formControlRadio}>
          <FormLabel required>Unit</FormLabel>
          <RadioGroup
            aria-label="unit"
            name="unit"
            id="unit"
            readOnly
            className={classes.group}
            value={inventoryRequest.unit}
            onChange={this.handleRadioChange.bind(this,'unit')}
          >
            <FormControlLabel value="liter" control={<Radio />} label="liter" />
            <FormControlLabel value="ml" control={<Radio />} label="ml" />
            {errors.unit && <FormHelperText className={classes.helperText}>{errors.unit}</FormHelperText>}
          </RadioGroup>
        </FormControl>
        )} */}
        <TextField
          margin="normal"
            // required
          fullWidth
            // type="number"
          id="reason"
          readOnly
          label="Application"
          name="reason"

          value={inventoryRequest.reason}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.reason ? true : undefined}
          helperText={errors.reason}
        />
        <TextField
          margin="normal"
            // required
          fullWidth
            // type="number"
          id="comment"
          label="Comment"
          name="comment"

          value={inventoryRequest.comment}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.comment ? true : undefined}
          helperText={errors.comment}
        />
        <TextField
          margin="normal"
            // required
          fullWidth
            // type="number"
          id="amount"
          label="Amount to take from inventory"
          name="amount"
          type="number"
          inputProps={{ min: '0.00001', step: 'any' }}
          value={inventoryRequest.amount}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.amount ? true : undefined}
          helperText={errors.amount}
        />
        {/* <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" />
          <FormControlLabel
            control={
              (
                <Checkbox
                  checked={inventoryRequest.enabled}
                  id="enabled"
                  onChange={this.handleToggle('enabled')}
                  value="enabled"
                  color="secondary"
                />
              )
        }
            label="Register in inventory"
          />
      </FormControl> */}

        <TextField
          margin="normal"
          required
          fullWidth
          type="date"
        // defaultValue={new Date()}
          id="date"
          label="Date"
          name="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={inventoryRequest.date}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur}
          error={errors.date ? true : undefined}
          helperText={errors.date}
        />

      </GenDialog>

    );
  }
}
InventoryItemD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  inventoryRequest: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handlePreExecute:PropTypes.func,
  product: PropTypes.object.isRequired,
  dynamoType: PropTypes.string.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(InventoryItemD);
