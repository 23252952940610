import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import GenDialog from '../../components/GenDialog';
import SingleListControl from '../../components/SingleListControl';
import { validate, validateForm } from '../../libs/validate';



const styles = (theme) => ({
    paper: {
      marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      display: 'flex',
    flexWrap: 'wrap',
    },
    formControl: {
      minWidth: 120,
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    helperText :{
      color: 'red',
    },
    
  });

  
const requiredFields = [
    {name:'name', min: 1, max: 40},
    {name:'capacity', min: 1, max: 40},
    {name:'allowedChemicals', min: 1, max: 40}
  ];


class ChemEquipmentD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      chemEquipment: this.props.selectedSetting,
      errors: {count:0},
      changed:false,
    
    };
  }
  
  componentDidMount() {
    if (this.props.isNew){
      this.setState({chemEquipment: {allowedChemicals:[]}});
    }
    else {
      let chemEquipment = this.props.selectedSetting;
      if (!chemEquipment.allowedChemicals)
      {
        chemEquipment.allowedChemicals=[]
      }
      this.setState({chemEquipment: chemEquipment});
    }
  }


  validateField (field, requiredFields)
  {
      let error = validate(field, this.state.chemEquipment, requiredFields)
      let errors = {...this.state.errors};
      errors[field] = error.msg;
      this.setState({errors: errors }); 
  }

  handleBlur = (event) => {
    return this.validateField (event.target.id, requiredFields);
  }


  handleChange = (event) => {
    let chemEquipment = {...this.state.chemEquipment};
    chemEquipment[event.target.id] = event.target.value; 
    this.setState({ chemEquipment:chemEquipment,changed:true });
  }

  handleToggle = (value,relation,parentId) => {

    const currentIndex = this.state.chemEquipment.allowedChemicals.indexOf(value);
    const newChemEquipment = {...this.state.chemEquipment};

    if (currentIndex === -1) {
      newChemEquipment.allowedChemicals.push(value);
    } else {
      newChemEquipment.allowedChemicals.splice(currentIndex, 1);
    }

    this.setState ({chemEquipment : newChemEquipment});

  }
  render() {
    const { classes, open, isNew, setClose, handleUpdate,  metaData, getFromGlobalState } = this.props;
    const {chemEquipment, errors} = this.state;
   const formValid = validateForm(chemEquipment, requiredFields);
    
    return (
 
      <GenDialog
      open={open}
      dialogObject={chemEquipment}
      setClose={setClose}
      isNew={isNew}
      handleUpdateDialogObject={handleUpdate.bind(this)}
      skPrimary="init_setting_"
      dynamoType="chemequips"
      valid={formValid}
      changed={this.state.changed}
      newTitle='Equipment'
      title={chemEquipment.name}
      getFromGlobalState={getFromGlobalState}
      >
         <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Equipment Name"
            name="name"
            autoFocus
            value={chemEquipment.name}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.chemEquipment ? true : undefined}
            helperText={errors.name}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="capacity"
            label="Capacity in Liters"
            name="capacity"
            type="number"
            inputProps={{ 'min': '0.00001', 'step':'any' }}
           // autoFocus
            value={chemEquipment.capacity}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.capacity ? true : undefined}
            helperText={errors.capacity}
          />
         
          <FormControl className={classes.formControl}>
          <Typography variant="subtitle1"> Can be used for  *</Typography>
         
          <SingleListControl
          
          data= {metaData}
          onBlur={this.handleBlur}
          checkedItems ={chemEquipment.allowedChemicals}
          handleToggle={this.handleToggle}
         
          />
         <FormHelperText className={classes.helperText}>{errors.allowedChemicals}</FormHelperText>
          {errors.allowedChemicals&& <FormHelperText className={classes.helperText}>{errors.allowedChemicals}</FormHelperText>}
          </FormControl>
        

      </GenDialog>
     
    );
  }
}
ChemEquipmentD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedSetting: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(ChemEquipmentD);
