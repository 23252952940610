import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

export default function Privacy(props) {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography variant="h6">Draft version under Beta</Typography>
      <br />
      <Typography>
        This is the privacy notice of Land Diary. In this document, "we", "our", or "us" refer to Land Diary (Pty) Ltd.
        We are company number K2021500741 registered in South Africa.
      </Typography>
      <br />
      <Typography variant="h5">Introduction</Typography>
      <Typography>
        This privacy notice aims to inform you about how we collect and process any information that we collect from you, or that you provide to us. It covers information that could identify you (“personal information”) and information that could not. In the context of the law and this notice, “process” means collect, store, transfer, use or otherwise act on information. It tells you about your privacy rights and how the law protects you.
        We are committed to protecting your privacy and the confidentiality of your personal information. Our policy is not just an exercise in complying with the law, but a continuation of our respect for you and your personal information.
        We undertake to preserve the confidentiality of all information you provide to us and hope that you reciprocate.
        Our policy complies with the Protection of Personal Information Act 2013.
        The law requires us to tell you about your rights and our obligations to you in regard to the processing and control of your personal information.
        Except as set out below, we do not share, sell, or disclose to a third party, any information collected through our website.
      </Typography>
      <br />
      <Typography variant="h5">1. Information we process</Typography>
      <Typography>
        We may collect, use, store and transfer different kinds of personal information about you. We have collated these into groups as follows:
        Your identity includes information such as first name, last name, title, date of birth, and other identifiable information that you may have provided at some time.
        Your contact information includes information such as billing address, delivery address, email address, telephone numbers and any other information you have given to us for the purpose of communication or meeting.
        Your financial information includes information such as your bank account and payment card details.
        Transaction information includes details about payments or communications to and from you and information about the products and services you have purchased from us.
        Technical information includes your internet protocol (IP) address, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.
        Your profile includes information such as your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.
        We may aggregate anonymous information such as statistical or demographic data for any purpose. Anonymous information is information that does not identify you as an individual. Aggregated information may be derived from your personal information but is not considered personal information in law because it does not reveal your identity.
        For example, we may aggregate profile information to assess interest in a product or service.
        However, if we combine or connect aggregated data with your personal information so that it can identify you in any way, we treat the combined data as personal information, and it will be used in accordance with this privacy notice.
      </Typography>
      <br />
      <Typography variant="h5">2. Special personal information</Typography>
      <Typography>
        Special personal information is data about your race or ethnicity, religious or philosophical beliefs, sex life, political opinions, trade union membership, information about your health and biometric data.
        It also includes information about criminal convictions and offences.
        We do not collect any special personal information about you.
      </Typography>
      <br />
      <Typography variant="h5">3. If you do not provide personal information we need</Typography>
      <Typography>
        Where we need to collect information by law, or under the terms of a contract, we have with you, and you fail to provide that information when requested, we may not be able to perform that contract. In that case, we may have to stop providing a service to you. If so, we will notify you of this at the time.
      </Typography>
      <br />
      <Typography variant="h5">The bases on which we process information about you</Typography>
      <br />
      <Typography variant="h5">
        4. Information we process because we have a contractual obligation with you
      </Typography>
      <Typography>
        When you create an account, or otherwise agree to our terms and conditions, a contract is formed between you and us.
        In order to carry out our obligations under that contract, we must process the information you give us. Some of this information may be personal information.
        We may use it in order to:
        verify your identity for security purposes
        provide you with our services
        We process this information on the basis there is a contract between us, or that you have requested we use the information before we enter into a legal contract.
        We shall continue to process this information until the contract between us ends or is terminated by either party under the terms of the contract.
      </Typography>
      <br />
      <Typography variant="h5">5. Information we process with your consent</Typography>
      <Typography>
        Through certain actions when otherwise there is no contractual relationship between us, such as when you browse our website or ask us to provide you more information about our business, including our products and services, you provide your consent to us to process information that may be personal information.
        Wherever possible, we aim to obtain your explicit consent to process this information, for example, by asking you to agree to our use of cookies.
        If you have given us explicit permission to do so, we may from time to time, pass your name and contact information to selected associates whom we consider may provide services or products you would find useful.
        We continue to process your information on this basis until you withdraw your consent or it can be reasonably assumed that your consent no longer exists.
        You may withdraw your consent at any time by instructing us at info@landdiary.co.za. However, if you do so, you may not be able to use our website or our services further.
      </Typography>
      <br />
      <Typography variant="h5">
        6. Information we process for the purposes of legitimate interests
      </Typography>
      <Typography>
        We may process the information on the basis there is a legitimate interest, either to you or to us, of doing so.
        Where we process your information on this basis, we do after having given careful consideration to:
        whether the same objective could be achieved through other means
        whether processing (or not processing) might cause you harm
        whether you would expect us to process your information, and whether you would, in the round, consider it reasonable to do so
        For example, we may process your information on this basis for the purposes of:
        record-keeping for the proper and necessary administration of LandDiary
        responding to unsolicited communication from you to which we believe you would expect a response
        protecting and asserting the legal rights of any party
        insuring against or obtaining professional advice that is required to manage Land Diary risk
        protecting your interests where we believe we have a duty to do so
      </Typography>
      <br />
      <Typography variant="h5">
        7. Information we process because we have a legal obligation
      </Typography>
      <Typography>
        Sometimes, we must process your information in order to comply with a statutory obligation.
        For example, we may be required to give information to legal authorities if they so request or if they have the proper authorization such as a search warrant or court order.
        This may include your personal information.
      </Typography>
      <br />
      <Typography variant="h5">
        Specific uses of information you provide to us
      </Typography>
      <br />
      <Typography variant="h5">
        8. Information relating to your method of payment
      </Typography>
      <Typography>
        Payment information is never taken by us or transferred to us either through our website or otherwise. Our employees and contractors never have access to it.
        At the point of payment, you are transferred to a secure page on the website of PayFast or some other reputable payment service provider. That page may be branded to look like a page on our website, but it is not controlled by us.
      </Typography>
      <br />
      <Typography variant="h5">
        9. Information about your direct debit
      </Typography>
      <Typography>
        When you agree to set up a direct debit arrangement, the information you give to us is passed to our own bank [name of bank] for processing according to our instructions. We do not keep a copy.
      </Typography>
      <br />
      <Typography variant="h5">
        10. Communicating with us
      </Typography>
      <Typography>
        When you contact us, whether by telephone, through our website or by e-mail, we collect the information you have given to us in order to reply with the information you need.
        We record your request and our reply in order to increase the efficiency of our business.
        We keep personally identifiable information associated with your messages, such as your name and email address so as to be able to track our communications with you to provide a high-quality service.
      </Typography>
      <br />
      <Typography variant="h5">
        11. Complaining
      </Typography>
      <Typography>
        When we receive a complaint, we record all the information you have given to us.
        We use that information to resolve your complaint.
        If your complaint reasonably requires us to contact some other person, we may decide to give to that other person some of the information contained in your complaint. We do this as infrequently as possible, but it is a matter for our sole discretion as to whether we do give information, and if we do, what that information is.
        We may also compile statistics showing information obtained from this source to assess the level of service we provide, but not in a way that could identify you or any other person.
      </Typography>
      <br />
      <Typography variant="h5">
        Use of information we collect through automated systems when you visit our website
      </Typography>
      <br />
      <Typography variant="h5">
        12. Cookies
      </Typography>
      <Typography>
        Cookies are small text files that are placed on your computer's hard drive by your web browser when you visit any website. They allow information gathered on one web page to be stored until it is needed for use on another, allowing a website to provide you with a personalized experience and the website owner with statistics about how you use the website so that it can be improved.
        Some cookies may last for a defined period, such as one day or until you close your browser. Others last indefinitely.
        Your web browser should allow you to delete any you choose. It also should allow you to prevent or limit their use.
        Our website uses cookies. They are placed by software that operates on our servers, and by software operated by third parties whose services we use.
        When you first visit our website, we ask you whether you wish us to use cookies. If you choose not to accept them, we shall not use them for your visit except to record that you have not consented to their use for any other purpose.
        If you choose not to use cookies or you prevent their use through your browser settings, you will not be able to use all the functionality of our website.
        We use cookies in the following ways:
        to track how you use our app
        to record whether you have seen specific messages we display on our app
        to keep you signed in to our app
      </Typography>
      <br />
      <Typography variant="h5">
        13. Personal identifiable information from your browsing activity
      </Typography>
      <Typography>
        Requests by your web browser to our servers for web pages and other content on our website are recorded.
        We record information such as your geographical location, your Internet service provider and your IP address. We also record information about the software you are using to browse our website, such as the type of computer or device and the screen resolution.
        We use this information in aggregate to assess the popularity of the webpages on our website and how we perform in providing content to you.
        If combined with other information we know about you from previous visits, the information possibly could be used to identify you personally, even if you are not signed in to our website.
      </Typography>
      <br />
      <Typography variant="h5">
        Disclosure and sharing of your information
      </Typography>
      <br />
      <Typography variant="h5">
        Information we obtain from third parties
      </Typography>
      <Typography>
        Although we do not disclose your personal information to any third party (except as set out in this notice), we sometimes receive information that is indirectly made up from your personal information from third parties whose services we use.
        No such information is personally identifiable to you.
      </Typography>
      <br />
      <Typography variant="h5">
        15. Credit reference
      </Typography>
      <Typography>
        To assist in combating fraud, we share information with credit reference agencies, so far as it relates to clients or customers who instruct their credit card issuer to cancel payment to us without having first provided an acceptable reason to us and given us the opportunity to refund their money.
      </Typography>
      <br />

      <Typography variant="h5">
        16. Information may be processed outside South Africa
      </Typography>
      <Typography>
        Our websites are hosted in the United States of America.
        We may also use outsourced services in countries outside South Africa from time to time in other aspects of our business.
        Accordingly, information obtained within South Africa or any other country could be processed outside South Africa.
        For example, some of the software our website uses may have been developed in the United States of America.
      </Typography>
      <br />

      <Typography variant="h5">
        Control over your own information
      </Typography>
      <br />

      <Typography variant="h5">
        17. Your duty to inform us of changes
      </Typography>
      <Typography>
        It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes.
      </Typography>
      <br />

      <Typography variant="h5">
        18. Access to your personal information
      </Typography>
      <Typography>
        At any time you may review or update personally identifiable information that we hold about you, by signing in to your account.
        To obtain a copy of any information that is not provided on our website you should contact us to make that request.`
        After receiving the request, we will tell you when we expect to provide you with the information, and whether we require any fee for providing it to you.
      </Typography>
      <br />

      <Typography variant="h5">
        19. Removal of your information
      </Typography>
      <Typography>
        If you wish us to remove personally identifiable information from our website, you should contact us to make your request.
        This may limit the service we can provide to you.
      </Typography>
      <br />

      <Typography variant="h5">
        20. Verification of your information
      </Typography>
      <Typography>
        When we receive any request to access, edit or delete personal identifiable information we shall first take reasonable steps to verify your identity before granting you access or otherwise taking any action. This is important to safeguard your information.
      </Typography>
      <br />

      <Typography variant="h5">
        Other matters
      </Typography>
      <br />

      <Typography variant="h5">
        21. Use of website by children
      </Typography>
      <Typography>
        We do not sell products or provide services for purchase by children, nor do we market to children.
      </Typography>
      <br />

      <Typography variant="h5">
        22. Encryption of data sent between us
      </Typography>
      <Typography>
        We use Secure Sockets Layer (SSL) certificates to verify our identity to your browser and to encrypt any data you give us.
        Whenever information is transferred between us, you can check that it is done so using SSL by looking for a closed padlock symbol or another trust mark in your browser’s URL bar or toolbar.
      </Typography>
      <br />

      <Typography variant="h5">
        23. How you can complain
      </Typography>
      <Typography>
        If you are not happy with our privacy policy or if you have any complaint then you should tell us.
        If a dispute is not settled, then we hope you will agree to attempt to resolve it by engaging in good faith with us in the process of mediation or arbitration.
      </Typography>
      <br />

      <Typography variant="h5">
        24. Retention period for personal data
      </Typography>
      <Typography>
        Except as otherwise mentioned in this privacy notice, we keep your personal information only for as long as required by us:
        to provide you with the services you have requested;
        to comply with other law, including for the period demanded by our tax authorities;
        to support a claim or defence in court.
      </Typography>
      <br />

      <Typography variant="h5">
        25. Compliance with the law
      </Typography>
      <Typography>
        Our privacy policy has been compiled so as to comply with the law of every country or legal jurisdiction in which we aim to do business. If you think it fails to satisfy the law of your jurisdiction, we should like to hear from you.
        However, ultimately it is your choice as to whether you wish to use our website.
      </Typography>
      <br />

      <Typography variant="h5">
        26. Review of this privacy policy
      </Typography>
      <Typography>
        We may update this privacy notice from time to time as necessary. The terms that apply to you are those posted here on our website on the day you use our website. We advise you to print a copy for your records.
        If you have any question regarding our privacy policy, please contact us.
      </Typography>
      <br />

    </Paper>
  );
}
Privacy.propTypes = {
};
