import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';
import SearchBox from '../../components/SearchBox';
import { getAdminSettings, getErrorMessage } from '../../libs/storage';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },
  group: {
    //  display: 'flex';
    // flex-wrap: 'wrap';
    flexDirection: 'row',
  },

});

class CombinationD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      combination: null,
      errors: { count: 0 },
      changed: false,

    };
  }

  componentDidMount() {
    const { isNew, combinationType } = this.props;
    if (isNew) {
      this.setState({ combination: { combinationProducts: [], combinationType, isDisabled: false } });
    } else {
      const { combination } = this.props;
      if (!combination.combinationProducts) {
        combination.combinationProducts = [];
      }
      this.setState({ combination });
    }
  }

  validateField(field) {
    const { requiredFields } = this.props;
    const { combination, errors } = this.state;
    const error = validate(field, combination, requiredFields);
    const newErrors = { ...this.state.errors };
    newErrors[field] = error.msg;
    this.setState({ errors: newErrors });
  }


  handleBlur = (event) => {
    const { requiredFields } = this.props;
    this.validateField(event.target.id, requiredFields);
  }

  handleChange = (event) => {
    const combination = { ...this.state.combination };
    combination[event.target.id] = event.target.value;
    this.setState({ combination, changed: true });
  }

  handleRadioChange = (name, event) => {
    const combination = { ...this.state.combination };
    combination[name] = event.target.value;
    this.setState({ combination, changed: true });
  }

  handleCheckChange = (event) => {
    const { combination } = this.state;
    const newCombination = { ...combination };
    newCombination[event.target.id] = event.target.checked;
    this.setState({ combination: newCombination, changed: true });
  }

  render() {
    const {
      classes,
      open,
      isNew,
      setClose,
      handleUpdate,
      getFromGlobalState,
      requiredFields,
    } = this.props;

    const {
      combination,
      errors,
      changed,
    } = this.state;

    if (!combination) {
      return null;
    }
    const formValid = validateForm(combination, requiredFields);

    return (

      <GenDialog
        open={open}
        dialogObject={combination}
        setClose={setClose}
        isNew={isNew}
        changed={changed}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary="init_setting_"
        dynamoType="combinations"
        valid={formValid}
        newTitle="New Combination"
        title={combination && combination.name ? combination.name : ''}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          {!isNew && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={combination.isDisabled}
                 // disabled={isInvite}
                  id="isDisabled"
                  onChange={this.handleCheckChange}
                  value="isDisabled"
                  color="secondary"
                />
        )}
              label="Disabled"
            />
          </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              value={combination && combination.name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              InputLabelProps={{
                shrink: true,
              }}
              value={combination && combination.description}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>
        </Grid>

      </GenDialog>

    );
  }
}

CombinationD.defaultProps = {
  requiredFields: [
    { name: 'name', min: 1, max: 40 },
  ],
};
CombinationD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  combination: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  combinationType: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  skPrimary: PropTypes.string,
  requiredFields: PropTypes.array,

};
export default withStyles(styles)(CombinationD);
