import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetailed from '../../components/ExpansionPanelDetail';
import MonitoringD from '../MonitoringD';
import StationD from '../StationD';
import EnhancedTable from '../../components/Etable';
import { getNameFromKey2 } from '../../libs/genLib';
import { generateTargetsRows } from '../../libs/analytics';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paperDropperBody: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  heading: {
    marginTop: '4px',
    cursor: 'pointer',
  },
  submit: {
    margin: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(10),
  },
  mainSection: {
    width: '100%',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  parentSection: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },

  },
  childSection: {
    marginLeft: theme.spacing(5),
  },
  childSectionChildren: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const detailHeadCells = [
  {
    id: 'isDisabled', numeric: false, disablePadding: true, label: 'Disabled',
  },
  {
    id: 'targetType', numeric: false, disablePadding: true, label: 'Target type',
  },
  {
    id: 'name', numeric: false, disablePadding: false, label: 'Name',
  },
  {
    id: 'stages', numeric: false, disablePadding: false, label: 'Stages',
  },
  {
    id: 'stagesDescrip', numeric: false, disablePadding: false, label: 'Stage Descriptions',
  },
  {
    id: 'cropSpecific', numeric: false, disablePadding: false, label: 'Crop specific',
  },
  {
    id: 'observableOn', numeric: false, disablePadding: false, label: 'Crop',
  },
];

const mobileHeadCells = [
  {
    id: 'targetType', combine: [{ id: 'targetType', opacity: 1 }, { id: 'name', opacity: 0.8 }, { id: 'mobileDescription', opacity: 0.6 }], numeric: false, disablePadding: true, label: 'Target', maxWidth: '100px',
  },
  {
    id: 'observableOn', numeric: false, disablePadding: false, label: 'Crop',
  },
];

class MonitoringDetailForm extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.setClose = this.setClose.bind(this);
    this.handleUpdateSite = this.handleUpdateSite.bind(this);
    this.setCloseStation = this.setCloseStation.bind(this);
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleShowMonitoring = this.handleShowMonitoring.bind(this);

    this.state = {
      expandedSetting: '',
      stationDialogOpen: false,
      // monitorings: this.props.initialState,
      selectedMonitoring: null,
      selectedStation: null,
      selectedMonitoringSK: '',
      isNew: false,
      monitoringDialogOpen: false,
    };
  }

  handleShowMonitoring = (event, parent, monitoringSK) => {
    const { getFromGlobalState } = this.props;
    const selectedMonitoring = getFromGlobalState('monitorings').find((monitoring) => monitoring.sk === monitoringSK);
    this.setState({
      selectedMonitoring, selectedMonitoringSK: monitoringSK, monitoringDialogOpen: 'true', isNew: false,
    });
  }

  handleBlur = () => {
    // return this.validateField (event.target.id, this.props.requiredFields);
  }

  handleClickAdd() {
    this.setState({ monitoringDialogOpen: true, isNew: true, selectedMonitoring: {} });
  }

handleSettingExpand = (setting) => {
  const { expandedSetting } = this.state;
  if (expandedSetting !== setting) {
    this.setState({ expandedSetting: setting });
  } else {
    this.setState({ expandedSetting: '' });
  }
}

handleCkickShowMonitoring(selectedMonitoringId) {
  const { getFromGlobalState } = this.props;
  const selectedMonitoring = getFromGlobalState('monitorings').find((monitoring) => monitoring.sk === selectedMonitoringId);
  this.setState({
    selectedMonitoring, selectedMonitoringSK: selectedMonitoringId, monitoringDialogOpen: 'true', isNew: false,
  });
}

handleClickAddNewStation = (monitoring) => {
  this.setState({
    stationDialogOpen: true,
    isNew: true,
    selectedMonitoringSK: monitoring.sk,
    selectedMonitoring: monitoring,
    selectedStation: {},
  });
}

handleClickShowStation = (selectedMonitoringId, selectedStationId) => {
  const { getFromGlobalState } = this.props;
  const selectedMonitoring = getFromGlobalState('monitorings').find((monitoring) => monitoring.sk === selectedMonitoringId);
  const selectedStation = selectedMonitoring.monitorStations.find((station) => station.sk === selectedStationId);
  this.setState({ selectedMonitoring, selectedStation, selectedMonitoringSK: selectedMonitoring.sk });
  this.setState({ stationDialogOpen: true, isNew: false });
}

handleClickEdit() {
  this.setState({ monitoringDialogOpen: true, isNew: true });
}

handleUpdateSite(siteIn, monitoringSK) {
  const { getFromGlobalState, setGlobalState } = this.props;
  const updatedMonitoringIndex = getFromGlobalState('monitorings').findIndex((i) => i.sk === monitoringSK);
  if (updatedMonitoringIndex === -1) {
    alert("impossible - we don't have this monitoring!");
    return;
  }
  // find the planting
  const monitorings = getFromGlobalState('monitorings');
  const sites = monitorings[updatedMonitoringIndex].monitorStations;

  if (!sites) {
    monitorings[updatedMonitoringIndex].monitorStations = [];
  }

  const updatedSiteIndex = (monitorings ? monitorings[updatedMonitoringIndex].monitorStations.findIndex((i) => i.sk === siteIn.sk) : -1);
  if (updatedSiteIndex === -1) {
    monitorings[updatedMonitoringIndex].monitorStations.push(siteIn);
    setGlobalState({ monitorings });
  } else {
    monitorings[updatedMonitoringIndex].monitorStations[updatedSiteIndex] = siteIn;
    setGlobalState({ monitorings });
  }
}

handleUpdate(pestIn) {
  const { getFromGlobalState, setGlobalState } = this.props;
  const updatedIndex = getFromGlobalState('monitorings').findIndex((i) => i.sk === pestIn.sk);
  const newMonitorings = getFromGlobalState('monitorings');
  if (updatedIndex === -1) {
    newMonitorings.push(pestIn);
    setGlobalState({ monitorings: newMonitorings });
    // this.state.blocks.push(blockIn);
  } else {
    newMonitorings[updatedIndex] = pestIn;
    setGlobalState({ monitorings: newMonitorings });
  }
}

setClose() {
  this.setState({ monitoringDialogOpen: false });
}

setCloseStation() {
  this.setState({ stationDialogOpen: false });
}

render() {
  const {
    classes, initialState, getFromGlobalState, setGlobalState, setSnack,
  } = this.props;
  const monitorings = initialState;
  const {
    monitoringDialogOpen,
    isNew,
    selectedMonitoring,
    stationDialogOpen,
    selectedStation,
    selectedMonitoringSK,
  } = this.state;
  const headCells = getFromGlobalState('isMobile') ? mobileHeadCells : detailHeadCells;
  const rows = generateTargetsRows(monitorings, getFromGlobalState);
  const userDetail = getFromGlobalState('userDetail');
  console.log(monitorings);
  return (

    <Paper elevation={0} className={classes.mainSection}>

      {(monitoringDialogOpen)
          && (
          <MonitoringD
            handleUpdate={this.handleUpdate}
            open={monitoringDialogOpen}
            setClose={this.setClose}
            isNew={isNew}
            monitoring={selectedMonitoring}
            getFromGlobalState={getFromGlobalState}
            setGlobalState={setGlobalState}
            setSnack={setSnack}
            metaData={getFromGlobalState('crops')}
            rows={rows}
          />
          )}

      {(stationDialogOpen)
          && (
          <StationD
            handleUpdate={this.handleUpdateSite}
            open={stationDialogOpen}
            type="monitorStations"
            setClose={this.setCloseStation}
            isNew={isNew}
            station={selectedStation}
            getFromGlobalState={getFromGlobalState}
            parentSK={selectedMonitoringSK}
            parent={selectedMonitoring}
          />
          )}

      <EnhancedTable
        displayToolbar
        id="monitoringTable"
        heading=""
        noPagination
        dense
        rows={rows}
        headCells={headCells}
        handleSelect={this.handleShowMonitoring}
        handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handleClickAdd : null}
        actionType="actionType"
        rowKeyName="sk"
        clean
        maxHeight="350px"
        selectedRow={selectedMonitoringSK}
      />
    </Paper>
  );
}
}
MonitoringDetailForm.propTypes = {
  initialState: PropTypes.array.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,

};
export default withStyles(styles)(MonitoringDetailForm);
