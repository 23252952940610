import React, { Component } from "react";
import Load from '../../components/Load';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

//import { fromStore } from "../../libs/storage";



const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    //marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'center',
    marginBottom: theme.spacing(10),
    //overflow: 'scroll',
  },
  section: {
    margin: theme.spacing(2, 4),
  },
});

class UnderConstruction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    };
  }
  
  async componentDidMount() {
    //if (!this.props.isAuthenticated) {
    //  return;
    //}
  
  }
  

  render() {
    const {classes, reload,location: { pathname } } = this.props;
    return (
      <Load isloading={this.state.isloading}>
      <Paper elevation={0} className={classes.paper}>
        <Typography component="h1" variant="h5">
        {`Under Construction ${pathname}`}
      </Typography>
      <Grid container className={classes.root} spacing={2} alignItems="flex-start"> 
      <Grid style={{padding:"10px"}} item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper elevation={0} className={classes.section}>
      <Divider variant="middle"/>
      </Paper>
      </Grid>
      </Grid>
      </Paper>
      </Load>
     
    );
  }
}
export default withStyles(styles)(UnderConstruction);
