import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@material-ui/core';
import ChartLine2 from '../../../components/LineChart2';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function generateRandomColors(count) {
    const colors = [];
    while (colors.length < count) {
        const color = generateRandomColor();
        if (!colors.includes(color)) {
            colors.push(color);
        }
    }
    return colors;
}

function generateRandomColor() {
    let color;
    do {
        // Generate a random hex color
        color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        // Ensure the color has a valid length (6 characters + #)
        color = color.length === 7 ? color : `#${color.slice(1).padStart(6, '0')}`;
    } while (isWhite(color));

    return color;
}

function isWhite(color) {
    // Convert hex color to RGB
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Check if the color is too close to white
    return (r > 240 && g > 240 && b > 240);
}

const randomColors = generateRandomColors(100);

const Irrigation=({data})=> {

    const renderCharts = () => {
    
        const crops = [...new Set(data.flatMap(d => Object.keys(d).filter(k => k.startsWith('totalLiters_')).map(k => k.replace('totalLiters_', ''))))];

        return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{
                    width: '100%', justifyContent: 'center', display: 'inline-flex', paddingTop: '5px',
                }}
                >
                <Typography variant="button" gutterBottom>Liters/Week</Typography>
                </div>
                <ResponsiveContainer key={`Irrigation 3`} width="100%" height={400} style={{ cursor: 'pointer' }}>
                <LineChart
                            width={500}
                            height={400}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                    >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="week" />
                            <YAxis  />
                            <Tooltip
                                label="Week Ending"
                            />
                            <Legend />
                            {crops.map((crop, index )=> (
                                <Line key={crop} name={crop} type="monotone" dataKey={`totalLiters_${crop}`} stroke={randomColors[index]} activeDot={{ r: 8 }} />
                        ))}
                            {/* <Line type="monotone" dataKey="liters" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                            </LineChart>
                </ResponsiveContainer>
        </Grid>
        );
      }

    return (
       <Paper
        elevation={1}
        style={{
          marginTop: '10px', maxHeight: '80vh', minHeight: '400px', overflowY: 'auto', backgroundClip: 'inherit',
        }}
       >
            <Grid container spacing={1} alignItems="flex-start">
                    {renderCharts()}
            </Grid>
       </Paper>
    );
}

Irrigation.propTypes = {
    data: PropTypes.object.isRequired,
};
export default Irrigation;