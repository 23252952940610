import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
//import uuid from 'uuid';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';
import WateringForm from './wateringForm';
import FertilisingForm from './fertilisingForm';
import PestingForm from './pestingForm';
import AgronomyActForm from './agronomyActForm';
import SprayingForm from './sprayingForm';
import HarvestingForm from './harvestingForm';
import PackingForm from './packingForm';
import { calculateMidpoint, getNameFromKey2 } from '../../libs/genLib';
import { getDownloadUrl } from '../../libs/storage';
import { isLocationInPoly, findBlock } from '../../libs/geoLib';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 220,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

/* const requiredFields = [
    {name:'crop', min: 1, max: 40},
    {name:'variety', min: 1, max: 40},
    {name:'plantSpacing', min: 1, max: 40},
    {name:'plantDensity', min: 1, max: 40},
    {name:'plantDate', min: 1, max: 40},

  ]; */

class RecordD extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSwitchChange2 = this.handleSwitchChange2.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSettingExpand = this.handleSettingExpand.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onSubtract = this.onSubtract.bind(this);
    this.onForwardSubset = this.onForwardSubset.bind(this);
    this.onBackSubset = this.onBackSubset.bind(this);
    this.onForwardSet = this.onForwardSet.bind(this);
    this.onBackSet = this.onBackSet.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.onChangeCounter = this.onChangeCounter.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.overrideHarvestTotal = this.overrideHarvestTotal.bind(this);
    this.handleTargetChange = this.handleTargetChange.bind(this);

    this.state = {
      record: {},
      errors: { count: 0 },
      changed: false,
      selectedTab: 0,
      expandedSetting: '',
      monitoringSet: { set: 0, subSet: 0 },
      watchId: '',
      currentLocation: null,
      isInBlock: false,
      displayTargetDetail: false,
      selectedTargetDetail: null,
      monitoringPicture: null,
      displayMonitoringPicture: false,
      uploading: false,
      foundBlock: null,
      expandedTarget: '',

    };
  }

  componentDidMount() {
    const { isNew, recordType, record } = this.props;
    if (isNew) {
      const today = new Date();
      const now = `${today.getHours()}:${today.getMinutes()}`;

      if (recordType === 'pestings') {
        const options = {
          enableHighAccuracy: true,
          timeout: 100000,
          maximumAge: 0,
        };
        const watchId = navigator.geolocation.watchPosition(this.locationFound, this.locationError, options);
        this.setState({ watchId });
      }

      if (recordType === 'sprayings') {
        this.setState({
          record: {
            completed: false, calculatedProductOverride: false, calculatedVolumeOverride: false, chemrigs: [], reasons: [],
          },
        });
      }
      if (recordType === 'fertilisings') {
        this.setState({ record: { totalOverride: false, date: today.toISOString().substr(0, 10), time: now } });
      } else {
        this.setState({ record: { date: today.toISOString().substr(0, 10), time: now } });
      }
    } else {
      this.setState({ record, changed: false });
    }
    if (recordType === 'pestings') {
      const options = {
        enableHighAccuracy: true,
        timeout: 100000,
        maximumAge: 0,
      };
      const watchId = navigator.geolocation.watchPosition(this.locationFound, this.locationError, options);
      this.setState({ watchId });
    }
  }

  setSelectedTab =(x)=>{
    this.setState({selectedTab:x})
  };

  componentWillUnmount() {
    const { watchId } = this.state;
    navigator.geolocation.clearWatch(watchId);
  }

  handleBlur = (event) => this.validateField(event.target.id, this.props.requiredFields)

  overrideCalculatedVolume = (event) => {
    const record = { ...this.state.record };
    record.calculatedVolumeOverride = !record.calculatedVolumeOverride;
    this.setState({ record, changed: true });
  }

  overrideCalculatedProduct = (event) => {
    const record = { ...this.state.record };
    record.calculatedProductOverride = !record.calculatedProductOverride;
    this.setState({ record, changed: true });
  }

  filterItems = (arr, planting) => {
    const plantingCrop = planting[0].crop;
    const allowedChemicals = [];
    arr.forEach((chemical) => {
      const idx = chemical.chemcrops.findIndex((c) => c.crop === plantingCrop);
      if (idx !== -1) {
        allowedChemicals.push(chemical);
      }
    });
    return allowedChemicals;
  };

  handleChange = (event) => {
    const record = { ...this.state.record };
    record[event.target.id] = event.target.value;
    record['createTime'] = Date.now();
    this.setState({ record, changed: true });
  }

  handleSwitchChange2 = (event) => {
    const record = { ...this.state.record };
    record[event.target.id] = event.target.checked;
    let currentLocation = null
    if(event.target.checked){
      const midpoint = calculateMidpoint(this.props?.block?.paths)
      currentLocation = {latitude:midpoint.lat,longitude:midpoint.lng,accuracy:0}
    }
    this.setState({ record, changed: true, currentLocation });
  }

  handleLocationChange = (location) => {
    const record = { ...this.state.record };
    record.geoLocation = location;
    this.setState({ record, changed: true });
  }

  handleRadioChange = (name, event) => {
    const record = { ...this.state.record };
    record[name] = event.target.value;
    this.setState({ record, changed: true });
  }

  handleCheckboxChange = (event) => {
    const record = { ...this.state.record };
    record[event.target.id] = event.target.checked;
    this.setState({ record, changed: true });
  }

  handleExpand = (setting) => {
    const { expandedTarget } = this.state;
    if (expandedTarget !== setting) {
      this.setState({ expandedTarget: setting });
    } else {
      this.setState({ expandedTarget: '' });
    }
  }

  overrideHarvestTotal = (event) => {
    const record = { ...this.state.record };
    const errors = { ...this.state.errors };
    record[event.target.id] = !record[event.target.id];
    if (record[event.target.id]) {
      errors.harvestEquip = undefined;
      errors.harvestUnits = undefined;
    }
    this.setState({ errors, record, changed: true });
  }

  handleSettingExpand = (setting) => {
    if (this.state.expandedSetting !== setting) {
      this.setState({ expandedSetting: setting });
    } else {
      this.setState({ expandedSetting: '' });
    }
  }

  overrideTotal = (event) => {
    const record = { ...this.state.record };
    const errors = { ...this.state.errors };
    record[event.target.id] = !record[event.target.id];
    // if ( record[event.target.id]){

    //   errors.harvestEquip = undefined;
    //   errors.harvestUnits=undefined;
    // }
    this.setState({ errors, record, changed: true });
  }

   handleSwitchChange = (name) => (event) => {
     const record = { ...this.state.record };
     record[name] = event.target.checked;
     this.setState({ record, changed: true });
   };

  handleComplexChange = (inputDataField, fieldToUpdate, event) => {
    const record = { ...this.state.record };
    record[event.target.id] = event.target.value;
    record[fieldToUpdate] = event.target.value * this.props.inputData[inputDataField];
    this.setState({ record, changed: true });
  }

  handleTabChange = (event, newValue) => {
    const currentTab = this.state.selectedTab;
    if (newValue !== currentTab) {
      this.setState({ selectedTab: newValue });
    }
  }

  onForwardSubset = (event, stageSK) => {
    const { monitoringSet } = this.state;
    const newMonitoringSet = { ...monitoringSet };
    if (!newMonitoringSet[stageSK]) {
      newMonitoringSet[stageSK] = { subSet: 0 };
    }
    newMonitoringSet[stageSK].subSet += 1;
    this.setState({ monitoringSet: newMonitoringSet });
  }

  onBackSubset = (event, stageSK) => {
    const { monitoringSet } = this.state;
    const newMonitoringSet = { ...monitoringSet };
    if (!newMonitoringSet[stageSK]) {
      newMonitoringSet[stageSK] = { subSet: 0 };
    }
    if (newMonitoringSet[stageSK].subSet > 0) {
      newMonitoringSet[stageSK].subSet -= 1;
      this.setState({ monitoringSet: newMonitoringSet });
    }
  }

  onForwardSet = async (done) => {
    const { monitoringSet } = this.state;
    let newMonitoringSet = { ...monitoringSet };
    let { set } = newMonitoringSet;
    if (!set) {
      set = 0;
    }
    newMonitoringSet = { set };
    if (!done) {
      newMonitoringSet.set += 1;
    }
    this.setState({ monitoringSet: newMonitoringSet });
  }

  onBackSet = (event, stageSK) => {
    const { monitoringSet } = this.state;
    let newMonitoringSet = { ...monitoringSet };
    let { set } = newMonitoringSet;
    if (!set) {
      set = 0;
    }
    newMonitoringSet = { set };
    if (newMonitoringSet.set > 0) {
      newMonitoringSet.set -= 1;
      this.setState({ monitoringSet: newMonitoringSet });
    }
  }

  getTargetUrl = async (key, fileType) => {
    const params = {
      activeTenant: this.props.getFromGlobalState('activeSite'),
      type: 'files',
      path: key,
      // isGeneral: true,
      // setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
      contentType: fileType,
    };
    const url = await getDownloadUrl(params);
    return url;
  }

  fileUploadedLocal = async (f) => {
    const { getFromGlobalState, setGlobalState } = this.props;
    const imageUrls = getFromGlobalState('imageUrls');
    const newImageUrls = { ...imageUrls };
    if (!newImageUrls[f.serverId]) {
      const url = await this.getTargetUrl(f.serverId, f.fileType);
      newImageUrls[f.serverId] = url;
      setGlobalState({ imageUrls: newImageUrls });
    }
  }

  onFileUploadStart = () => {
    this.setState({ uploading: true });
  }

  fileUploaded = async (file, monitoring, stageSK, set, subSet) => {
    if (!file.serverId) {
      return;
    }
    const { record } = this.state;
    const newRecord = { ...record };
    let stageIdx;
    if (!newRecord.observations) {
      newRecord.observations = [];
    }
    stageIdx = newRecord.observations.findIndex((o) => (o.stageSK && o.stageSK === stageSK));
    if (stageIdx === -1) {
      const ob = {
        stageSK,
        monitoringSK: monitoring.monitoring.sk,
        targetDetailSK: monitoring.monitoring.targetDetail.sk,
        pictures: [],
        entries: [],
      };
      if (monitoring.monitoring.cropSpecific === 'Yes') {
        ob.observableOnSK = monitoring.monitoring.targetDetail.observableOn[0].sk;
      } else {
        ob.nonCropSecofocSK = monitoring.monitoring.targetDetail.sk;
      }
      stageIdx = newRecord.observations.push(ob);
      stageIdx -= 1;
    }

    const observation = newRecord.observations[stageIdx];
    if (!observation.pictures) {
      observation.pictures = [];
    }
    if (observation.pictures.length <= set) {
      const entLength = observation.pictures.length;
      for (let j = 0; j < ((set - entLength) + 1); j += 1) {
        observation.pictures.push([]);
      }
    }
    if (observation.pictures[set].length <= subSet) {
      const entLength = observation.pictures[set].length;
      for (let j = 0; j < ((subSet - entLength) + 1); j += 1) {
        observation.pictures[set].push([]);
      }
    }
    observation.pictures[set][subSet].push({ Key: file.serverId, fileType: file.fileType });
    // this.setState({ record: newRecord, changed: true });
    await this.setStateAsync({ record: newRecord, changed: true, uploading: false });
    // this.setState({ record: newRecord, changed: true }, () => {
    await this.fileUploadedLocal(file);
    // });
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  locationError = (err) => {
    const { setSnack } = this.props;
    setSnack({ open: true, variant: 'error', messageText: ` Failed to find location ${err.message}` });
    // console.warn('ERROR(' + err.code + '): ' + err.message);
  }

  locationFound = (pos) => {
    const { isMobile, block, getFromGlobalState } = this.props;
    const crd = pos.coords;
    const tolerance = isMobile ? 200 : 200;
    if (crd.accuracy < tolerance) {
      if (isLocationInPoly(block.paths, crd.latitude, crd.longitude)) {
        this.setState({ currentLocation: crd, isInBlock: true });
      } else {
        const blocks = getFromGlobalState('blocks');
        const foundBlock = findBlock(blocks, crd.latitude, crd.longitude);
        this.setState({ currentLocation: crd, isInBlock: false, foundBlock });
      }
      // navigator.geolocation.clearWatch(watchId);
    }
  }

  onTakePhoto = (event, monitoring, stageSK, set, subSet) => {
    event.preventDefault();
    const { record } = this.state;
    // const newRecord = { ...record };
    if (monitoring.cropSpecific === 'Yes') {
      this.setState({
        displayMonitoringPicture: true,
        monitoringPicture: {
          monitoring, stageSK, set, subSet,
        },
      });
    } else {
      this.setState({
        displayMonitoringPicture: true,
        monitoringPicture: {
          monitoring, stageSK: null, set, subSet,
        },
      });
      const blee = 'Todo';
    }
  }

  onCloseTargetDetail = () => {
    this.setState({ displayTargetDetail: false, selectedTargetDetail: null });
  }

  onCloseMonitoringPicture = () => {
    this.setState({ monitoringPicture: null, displayMonitoringPicture: false });
  }

  onShowTargetDetail = (event, monitoring, stageSK, set, subSet, unitNum) => {
    event.preventDefault();
    if (monitoring.cropSpecific === 'Yes') {
      this.setState({ displayTargetDetail: true, selectedTargetDetail: monitoring.targetDetail.observableOn[0].stages.find((s) => s.sk === stageSK) });
    } else {
      this.setState({ displayTargetDetail: true, selectedTargetDetail: monitoring.targetDetail.nonCropDetail });
      const blee = 'Todo';
    }
  }

  onChangeCounter = (event, monitoring, stageSK, set, subSet, unitNum) => {
    if (unitNum === 'Present') {
      return null;
    }
    const { record } = this.state;
    const newRecord = { ...record };
    let stageIdx;
    if (!newRecord.observations) {
      newRecord.observations = [];
    }
    stageIdx = newRecord.observations.findIndex((o) => (o.stageSK && o.stageSK === stageSK));
    if (stageIdx === -1) {
      const ob = {
        stageSK,
        monitoringSK: monitoring.sk,
        targetDetailSK: monitoring.targetDetail.sk,
        entries: [],
        pictures: [],
      };
      if (monitoring.cropSpecific === 'Yes') {
        ob.observableOnSK = monitoring.targetDetail.observableOn[0].sk;
      } else {
        ob.nonCropSecofocSK = 'blee';
      }
      stageIdx = newRecord.observations.push(ob);
      stageIdx -= 1;
    }

    const observation = newRecord.observations[stageIdx];
    if (observation.entries.length <= set) {
      const entLength = observation.entries.length;
      for (let j = 0; j < ((set - entLength) + 1); j += 1) {
        observation.entries.push([]);
      }
    }
    if (observation.entries[set].length <= subSet) {
      observation.entries[set].push(0);
    }
    if (unitNum !== 'Present') {
      // observation.entries[set][subSet] = Number(event.target.value);
      observation.entries[set][subSet] = event.target.value;
      if (isNaN(observation.entries[set][subSet])) {
        const bb = 10;
      }
    } else {
      observation.entries[set][subSet] = 1;
    }
    this.captureLocation(set, newRecord);
    this.setState({ record: newRecord, changed: true });
  }

  captureLocation = (set, newRecord) => {
    const { currentLocation } = this.state;
    // const newRecord = { ...record };
    if (!newRecord.geoLocations) {
      // newRecord.observation.geoLocations = [];
      newRecord.geoLocations = [];
    }
    if (newRecord.geoLocations.length <= set) {
      const entLength = newRecord.geoLocations.length;
      for (let j = 0; j < ((set - entLength) + 1); j += 1) {
        newRecord.geoLocations.push([]);
      }
    }
    if (!newRecord.geoLocations[set].accuracy) {
      const location = currentLocation;// await getLocation();
      const geoLocation = {
        accuracy: location && location.accuracy,
        altitude: location && location.altitude,
        altitudeAccuracy: location && location.altitudeAccuracy,
        latitude: location && location.latitude,
        longitude: location && location.longitude,
      };
      newRecord.geoLocations[set] = geoLocation;
    }
  }

  onAdd = (event, monitoring, stageSK, set, subSet, unitNum) => {
    const { record } = this.state;
    const newRecord = { ...record };
    let stageIdx;
    if (!newRecord.observations) {
      newRecord.observations = [];
    }
    let ob = {};
    if (monitoring.cropSpecific === 'Yes') {
      stageIdx = newRecord.observations.findIndex((o) => (o.stageSK && o.stageSK === stageSK));
      if (stageIdx === -1) {
        ob = {
          cropSpecific: true,
          stageSK,
          monitoringSK: monitoring.sk,
          targetDetailSK: monitoring.targetDetail.sk,
          entries: [],
          pictures: [],
        };
        ob.observableOnSK = monitoring.targetDetail.observableOn[0].sk;
        stageIdx = newRecord.observations.length;
        newRecord.observations.push(ob);
      }
    } else {
      stageIdx = newRecord.observations.findIndex((o) => (o.targetDetailSK && o.targetDetailSK === monitoring.targetDetail.sk));
      if (stageIdx === -1) {
        ob = {
          cropSpecific: false,
          monitoringSK: monitoring.sk,
          targetDetailSK: monitoring.targetDetail.sk,
          entries: [],
          pictures: [],
        };
        stageIdx = newRecord.observations.length;
        newRecord.observations.push(ob);
      }
    }
    const observation = newRecord.observations[stageIdx];
    if (observation.entries.length <= set) {
      const entLength = observation.entries.length;
      for (let j = 0; j < ((set - entLength) + 1); j += 1) {
        observation.entries.push([]);
      }
    }
    if (observation.entries[set].length <= subSet) {
      observation.entries[set].push(-1);
    }
    if (unitNum !== 'Present') {
      observation.entries[set][subSet] = (Number(observation.entries[set][subSet]) || 0) + 1;
      const MAX_SCALE = 5;
      if (unitNum === 'Scale' && observation.entries[set][subSet] > MAX_SCALE) {
        observation.entries[set][subSet] = MAX_SCALE;
      }
      if (isNaN(observation.entries[set][subSet])) {
        const DEFAULT_BB_VALUE = 10;
        const bb = DEFAULT_BB_VALUE; // placeholder if required for later use
      }
    } else {
      observation.entries[set][subSet] = 1;
    }
    this.captureLocation(set, newRecord);
    this.setState({ record: newRecord, changed: true });
};


  onSubtract = (event, monitoring, stageSK, set, subSet, unitNum) => {
    const { record } = this.state;
    const newRecord = { ...record };
    let stageIdx;
    if (!newRecord.observations) {
      newRecord.observations = [];
    }
    let ob = {};
    if (monitoring.cropSpecific === 'Yes') {
      stageIdx = newRecord.observations.findIndex((o) => (o.stageSK && o.stageSK === stageSK));
      if (stageIdx === -1) {
        ob = {
          cropSpecific: true,
          stageSK,
          monitoringSK: monitoring.sk,
          targetDetailSK: monitoring.targetDetail.sk,
          entries: [],
          pictures: [],
        };
        ob.observableOnSK = monitoring.targetDetail.observableOn[0].sk;
        stageIdx = newRecord.observations.push(ob);
        stageIdx -= 1;
      }
    } else {
      stageIdx = newRecord.observations.findIndex((o) => (o.targetDetailSK && o.targetDetailSK === monitoring.targetDetail.sk));
      if (stageIdx === -1) {
        ob = {
          cropSpecific: false,
          monitoringSK: monitoring.sk,
          targetDetailSK: monitoring.targetDetail.sk,
          entries: [],
          pictures: [],
        };
        stageIdx = newRecord.observations.push(ob);
        stageIdx -= 1;
      }
    }
    const observation = newRecord.observations[stageIdx];
    if (observation.entries.length <= set) {
      const entLength = observation.entries.length;
      for (let j = 0; j < ((set - entLength) + 1); j += 1) {
        observation.entries.push([]);
      }
    }
    if (observation.entries[set].length <= subSet) {
      observation.entries[set].push(-1);
    }
    if (unitNum !== 'Present') {
      observation.entries[set][subSet] = Number(observation.entries[set][subSet]) - 1;
      if (observation.entries[set][subSet] < 0) {
        observation.entries[set][subSet] = 0;
      }
    } else {
      observation.entries[set][subSet] = 0;
    }
    this.captureLocation(set, newRecord);
    this.setState({ record: newRecord, changed: true });
  };

  /* onSubtract = (event, monitoring, stageSK, set, subSet, unitNum) => {
    const { record } = this.state;
    const newRecord = { ...record };
    let stageIdx;
    if (!newRecord.observations) {
      newRecord.observations = [];
    }
    stageIdx = newRecord.observations.findIndex((o) => (o.stageSK && o.stageSK === stageSK));
    if (stageIdx === -1) {
      const ob = {
        stageSK,
        monitoringSK: monitoring.sk,
        targetDetailSK: monitoring.targetDetail.sk,
        entries: [],
        pictures: [],
      };
      if (monitoring.cropSpecific === 'Yes') {
        ob.observableOnSK = monitoring.targetDetail.observableOn[0].sk;
      } else {
        ob.nonCropSecofocSK = 'blee';
      }
      stageIdx = newRecord.observations.push(ob);
      stageIdx -= 1;
    }

    const observation = newRecord.observations[stageIdx];
    if (observation.entries.length <= set) {
      observation.entries.push([]);
    }
    if (observation.entries[set].length <= subSet) {
      observation.entries[set].push(0);
    }
    if (unitNum !== 'Present') {
      observation.entries[set][subSet] -= 1;
      if (observation.entries[set][subSet] < 0) {
        observation.entries[set][subSet] = 0;
      }
    } else {
      observation.entries[set][subSet] = 0;
    }
    this.captureLocation(set, newRecord);
    this.setState({ record: newRecord, changed: true });
  }; */

  // todo will have to move to  redux
  handleToggleChemrigs = (value, arrayName) => {
    const { record } = this.state;
    const recordNew = { ...record };
    if (!recordNew[arrayName]) {
      recordNew[arrayName] = [];
    }
    const currentIndex = recordNew[arrayName].indexOf(value);

    if (currentIndex === -1) {
      recordNew[arrayName].push(value);
    } else {
      recordNew[arrayName].splice(currentIndex, 1);
    }

    this.setState({ record: recordNew, changed: true });
  };

  handleTargetChange = (event, value) => {
    const { record } = this.state;
    const recordNew = { ...record };
    if (!recordNew.reasons) {
      recordNew.reasons = [];
    }
    const currentIndex = recordNew.reasons.findIndex((v) => v.target === value);
    if (currentIndex !== -1) {
      // make all the rest false.
      //const appliedList = recordNew[arrayName].filter((f) => f.applied === true);
     
      recordNew.reasons[currentIndex][event.target.id] = event.target.value;
      this.setState({ record: recordNew, changed: true });
    }
  }

  handleReentryOverride = (event, value) => {
    const { record } = this.state;
    const recordNew = { ...record };
    if (!recordNew.reasons) {
      recordNew.reasons = [];
    }
    const currentIndex = recordNew.reasons.findIndex((v) => v.target === value);
    if (currentIndex !== -1) {
      // make all the rest false.
      //const appliedList = recordNew[arrayName].filter((f) => f.applied === true);
      if (!Object.hasOwn(recordNew.reasons[currentIndex], 'reentryOverride')) {
        recordNew.reasons[currentIndex].reentryOverride = event.target.checked;
      }
      else {
        recordNew.reasons[currentIndex].reentryOverride = !recordNew.reasons[currentIndex].reentryOverride;
      }
      this.setState({ record: recordNew, changed: true });
    }
  }

  handleApplied = (event, value, arrayName) => {
    const { record } = this.state;
    const recordNew = { ...record };
    if (!recordNew[arrayName]) {
      recordNew[arrayName] = [];
    }
    const currentIndex = recordNew[arrayName].findIndex((v) => v.target === value);
    if (currentIndex !== -1) {
      // make all the rest false.
      //const appliedList = recordNew[arrayName].filter((f) => f.applied === true);
      for (let v = 0; v < recordNew[arrayName].length; v += 1) {
        recordNew[arrayName][v].applied = false;
      }
      recordNew[arrayName][currentIndex].applied = event.target.checked;
      this.setState({ record: recordNew, changed: true });
    }
  }

  handleToggleReasons = (value, arrayName, planting) => {
    const { record } = this.state;
    const { getFromGlobalState } = this.props;
    const recordNew = { ...record };
    if (!recordNew[arrayName]) {
      recordNew[arrayName] = [];
    }
    const currentIndex = recordNew[arrayName].findIndex((i) => i.target === value);
    for (let r = 0; r < recordNew[arrayName].length; r += 1) {
      recordNew[arrayName][r].applied = false;
    }

    if (currentIndex === -1) {
      // const chemicals = getFromGlobalState('chemicals');
      const combinations = getFromGlobalState('combinations').filter((x)=>x.combinationType === "Plant Protection");
      const chemicals = getFromGlobalState('chemicals').concat(combinations);
      const chemical = chemicals.find((f) => f.sk === record.product);
      const chemcrop = chemical.type === "combinations" ?
      chemical.combinationProducts.map((x)=>x.
      selectedChemicalCrop
      ).find((c) => c?.crop === planting[0]?.crop)
      : chemical.chemcrops.find((c) => c?.crop === planting[0]?.crop);
      const targetSpes = (chemcrop?.targets||[]).find((t) => t.target === value);
       if(targetSpes?.applied)targetSpes.applied = true
      //targetSpes.coppiedfromSettingSK = targetSpes.sk;
      // have the chmical - now find the reason.

      recordNew[arrayName].push({ ...targetSpes });
    } else {
      recordNew[arrayName].splice(currentIndex, 1);
    }

    this.setState({ record: recordNew, changed: true });
  };

  updateParent(attribute, value) {
    const { record } = this.state;
    const recordNew = { ...record };
    recordNew[attribute] = value;
    this.setState({ record: recordNew });
  }

  validateField(field, requiredFields) {
    const { record, errors } = this.state;
    const error = validate(field, record, requiredFields);
    const errorsNew = { ...errors };
    errorsNew[field] = error.msg;
    this.setState({ errors: errorsNew });
  }

  render() {
    const {
      open,
      isNew,
      setClose,
      handleUpdateRecord,
      allowDelete,
      handleDeleteRecord,
      requiredFields,
      recordType,
      title,
      newTitle,
      plantingSK,
      blockSK,
      block,
      getFromGlobalState,
      planting,
      setSnack,
      isMobile,
      setGlobalState,
    } = this.props;
    const {
      record,
      errors,
      selectedTab,
      expandedSetting,
      monitoringSet,
      currentLocation,
      changed,
      displayTargetDetail,
      selectedTargetDetail,
      monitoringPicture,
      displayMonitoringPicture,
      uploading,
      isInBlock,
      foundBlock,
      expandedTarget,
    } = this.state;
    const formValid = recordType !== 'pestings' && isNew ? validateForm(record, requiredFields) : true;
    const crops = getFromGlobalState('crops');

    // const test = getFromGlobalState('blocks').find(b=>b.sk === blockSK).plantings.filter(p=>p.sk===plantingSK);

    return (

      <GenDialog
        open={open}
        dialogObject={record}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdateRecord.bind(this)}
      // skPrimary={'recordType'}
        skPrimary="_record_"
        dynamoType={recordType}
        deleteLabel="Are you sure you want to proceed? This action will permanently delete all records and information from this session."
        valid={formValid}
        changed={changed}
        newTitle={newTitle}
        setSnack={this.props.setSnack}
        getFromGlobalState={getFromGlobalState}
        title={title}
        lastUpdatedBy={{
          createdByEmail: record?.createdByEmail ? record?.createdByEmail : '',
          createdAt: record?.createdAt,
        }}
     // subTitle={`Block ${block.number} v.${record.version ? record.version: '0' }`}
        subTitle={`Block ${block.number} ${getNameFromKey2(planting.crop, crops, 'name')} ${planting.variety ? getNameFromKey2(planting.variety, crops.find((crop) => crop.sk === planting.crop)?.varieties, 'name') : ''} v.${record?.version ? record.version : '0'} `}
        parentKey={plantingSK}
        plantingKey={plantingSK}
        allowDelete={allowDelete && handleDeleteRecord}
        handleDeleteDialogObject={handleDeleteRecord ? handleDeleteRecord.bind(this) : undefined}
      >
        {recordType === 'waterings'
         && (
         <WateringForm
           record={record}
           errors={errors}
           handleChange={this.handleChange}
           handleBlur={this.handleBlur}
           metaData={{ operators: getFromGlobalState('operators').filter((o) => o.roles.indexOf('waterings') !== -1) }}
        // handleComplexChange={this.handleComplexChange.bind(this)}
           updateParent={this.updateParent.bind(this)}
           block={block}
           setSnack={setSnack}
           planting={block.plantings.filter((p) => p.sk === plantingSK)}
           handleSwitchChange={this.handleSwitchChange}
         />
         )}

        {recordType === 'fertilisings'
         && (
         <FertilisingForm
           record={record}
           errors={errors}
           handleChange={this.handleChange}
           handleBlur={this.handleBlur}
           metaData={{ fertilisers: getFromGlobalState('fertilisers'), operators: getFromGlobalState('operators').filter((o) => o.roles.indexOf('fertilisings') !== -1), inventory: getFromGlobalState('inv_fertiliser') }}
           planting={getFromGlobalState('blocks').find((b) => b.sk === blockSK).plantings.filter((p) => p.sk === plantingSK)}
           block={block}
           handleSwitchChange={this.handleSwitchChange}
           getFromGlobalState={getFromGlobalState}
           overrideTotal={this.overrideTotal}
         />
         )}

        {recordType === 'pestings'
         && (
         <PestingForm
           record={record}
           errors={errors}
           isNew={isNew}
           onAdd={this.onAdd}
           onSubtract={this.onSubtract}
           onForwardSubset={this.onForwardSubset}
           onBackSubset={this.onBackSubset}
           onForwardSet={this.onForwardSet}
           onBackSet={this.onBackSet}
           selectedTab={selectedTab}
           setSelectedTab={this.setSelectedTab}
           handleLocationChange={this.handleLocationChange}
           handleSettingExpand={this.handleSettingExpand}
           expandedSetting={expandedSetting}
           handleCheckboxChange={this.handleCheckboxChange}
           handleTabChange={this.handleTabChange}
           handleChange={this.handleChange}
           handleRadioChange={this.handleRadioChange}
           handleBlur={this.handleBlur}
           handleSwitchChange={this.handleSwitchChange2}
           currentLocation={currentLocation}
           setInfo={monitoringSet}
           setSnack={setSnack}
           isMobile={isMobile}
           getFromGlobalState={getFromGlobalState}
           metaData={{ targetTypes: getFromGlobalState('targetsTypesSetting'), monitorings: getFromGlobalState('monitorings'), operators: getFromGlobalState('operators').filter((o) => o.roles.indexOf('pestings') !== -1) }}
           planting={getFromGlobalState('blocks').find((b) => b.sk === blockSK).plantings.filter((p) => p.sk === plantingSK)}
           onChangeCounter={this.onChangeCounter}
           onShowTargetDetail={this.onShowTargetDetail}
           onTakePhoto={this.onTakePhoto}
           selectedTargetDetail={selectedTargetDetail}
           displayTargetDetail={displayTargetDetail}
           onCloseTargetDetail={this.onCloseTargetDetail}
           setGlobalState={setGlobalState}
           onCloseMonitoringPicture={this.onCloseMonitoringPicture}
           monitoringPicture={monitoringPicture}
           displayMonitoringPictures={displayMonitoringPicture}
           fileUploaded={this.fileUploaded}
           onFileUploadStart={this.onFileUploadStart}
           uploading={uploading}
           isInBlock={isInBlock}
           block={block}
           foundBlock={foundBlock}
         />
         )}

        {recordType === 'activities'
         && (
         <AgronomyActForm
           record={record}
           errors={errors}
           setSnack={setSnack}
           handleChange={this.handleChange}
           handleBlur={this.handleBlur}
           metaData={{ agronomistActs: getFromGlobalState('agronomistActs'), operators: getFromGlobalState('operators').filter((o) => o.roles.indexOf('activities') !== -1) }}
           planting={getFromGlobalState('blocks').find((b) => b.sk === blockSK).plantings.filter((p) => p.sk === plantingSK)}
         />
         )}

        {recordType === 'sprayings'
         && (
         <SprayingForm
           record={record}
           errors={errors}
           isNew={isNew}
           handleChange={this.handleChange}
           handleBlur={this.handleBlur}
           metaData={{
             chemicals: getFromGlobalState('chemicals'), chemequips: getFromGlobalState('chemequips'), pests: getFromGlobalState('monitorings'), operators: getFromGlobalState('operators').filter((o) => o.roles.indexOf('sprayings') !== -1), inventory: getFromGlobalState('inv_chemical'),
           }}
           planting={getFromGlobalState('blocks').find((b) => b.sk === blockSK).plantings.filter((p) => p.sk === plantingSK)}
           block={block}
           setSnack={setSnack}
           handleToggle={this.handleToggleReasons}
           handleSwitchChange={this.handleSwitchChange}
           overrideCalculatedVolume={this.overrideCalculatedVolume}
           overrideCalculatedProduct={this.overrideCalculatedProduct}
           getFromGlobalState={getFromGlobalState}
           handleExpand={this.handleExpand}
           expandedTarget={expandedTarget}
           handleApplied={this.handleApplied}
           handleTargetChange={this.handleTargetChange}
           handleReentryOverride={this.handleReentryOverride}
         />
         )}

        {recordType === 'harvestings'
         && (
         <HarvestingForm
           record={record}
           errors={errors}
           handleLocationChange={this.handleLocationChange}
           handleChange={this.handleChange}
           handleBlur={this.handleBlur}
           setSnack={setSnack}
          // metaData={getFromGlobalState('harvestequips')}
           metaData={{ operators: getFromGlobalState('operators').filter((o) => o.roles.indexOf('harvestings') !== -1), harvestEquips: getFromGlobalState('harvestequips') }}
           overrideTotalHarvest={this.overrideHarvestTotal}
         />
         )}

        {recordType === 'packings'
         && (
         <PackingForm
           record={record}
           errors={errors}
           handleChange={this.handleChange}
           handleBlur={this.handleBlur}
           metaData={getFromGlobalState('packequips')}
         />
         )}

      </GenDialog>

    );
  }
}
RecordD.defaultProps = {
  allowDelete: false,
};
RecordD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  planting: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleUpdateRecord: PropTypes.func.isRequired,
  handleDeleteRecord: PropTypes.func.isRequired,
  blockSK: PropTypes.string.isRequired,
  plantingSK: PropTypes.string.isRequired,
  requiredFields: PropTypes.array.isRequired,
  recordType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  newTitle: PropTypes.string.isRequired,
  inputData: PropTypes.object.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  block: PropTypes.object.isRequired,
  allowDelete: PropTypes.bool,
  setSnack: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  record: PropTypes.object.isRequired,
  setGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(RecordD);
