import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import GenDialog from '../../components/GenDialog';
import SingleListControl from '../../components/SingleListControl';
import { validate, validateForm } from '../../libs/validate';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

const requiredFields = [
  { name: 'consumer', min: 1, max: 200 },
];

class ApiKeyD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      apiKey: this.props.selectedSetting,
      errors: { count: 0 },
      changed: false,
      activeSite: '',

    };
  }

  componentDidMount() {
    const activeSite = this.props.getFromGlobalState('activeSite');
    this.setState({ activeSite });

  }


  handleCheckChange = (event) => {
    const { apiKey } = this.state;
    const newApiKey = { ...apiKey };
    newApiKey[event.target.id] = event.target.checked;
    this.setState({ apiKey: newApiKey, changed: true });
  }

  validateField(field, requiredFields) {
    const error = validate(field, this.state.apiKey, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const apiKey = { ...this.state.apiKey };
    apiKey[event.target.id] = event.target.value;
    this.setState({ apiKey, changed: true });
  }

  render() {
    const {
      classes, open, isNew, setClose, handleUpdate, getFromGlobalState,
    } = this.props;
    const { apiKey, errors, activeSite } = this.state;
    const formValid = validateForm(apiKey, requiredFields);

    return (

      <GenDialog
        open={open}
        dialogObject={apiKey}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary="init_setting_"
        dynamoType="apiKeys"
        valid={formValid}
        changed={this.state.changed}
        newTitle="New API Key"
        title={apiKey.consumer}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          {!isNew && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={apiKey.isDisabled}
                 // disabled={isInvite}
                  id="isDisabled"
                  onChange={this.handleCheckChange}
                  value="isDisabled"
                  color="secondary"
                />
        )}
              label="Disabled"
            />
          </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="consumer"
              label="Consumer Name"
              name="consumer"
              autoFocus
              value={apiKey.consumer}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.apiKey ? true : undefined}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              disabled
              fullWidth
              id="activeTenant"
              label="Farm ID"
              name="activeTenant"
              autoFocus
              value={activeSite}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.apiKey ? true : undefined}
              helperText={errors.apiKey}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              disabled
              fullWidth
              id="apiKey"
              label="API Key"
              name="apiKey"
              autoFocus
              value={apiKey.apiKey}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.apiKey ? true : undefined}
              helperText={errors.apiKey}
            />
          </Grid>
        </Grid>

      </GenDialog>

    );
  }
}
ApiKeyD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedSetting: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(ApiKeyD);
