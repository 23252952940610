import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';
import ExpansionPanelDetailed from '../../components/ExpansionPanelDetail';
import Wizard from '../../components/Wizard';
import TargetStage from '../../components/TargetStage';
import InfoBox from '../../components/InfoBox';

import { getTargetDetails, getDownloadUrl, getNonCropSpecificTargetDetails } from '../../libs/storage';

const styles = (theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(1),
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    display: 'inline',
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
  group: {
    flexDirection: 'row',
    // marginTop: theme.spacing(1),
  },
  formControlRadio: {
    marginTop: theme.spacing(1),
  },

});

const requiredFields = [
  // { name: 'name', min: 1, max: 40 },
  { name: 'monitorType', min: 1, max: 40 },
  { name: 'targetType', min: 1, max: 40 },
  // { name: 'threshold', min: 0, max: 50000, type: 'number',condition:{name:'pestOrDisease',value:'Pest'} },
  // { name: 'allowedCrops', min: 1, max: 5000 },
];

const variableThresholds = [
  {
    month: 1, member: 'jan', label: 'Jan', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 2, member: 'feb', label: 'Feb', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 3, member: 'mar', label: 'Mar', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 4, member: 'apr', label: 'Apr', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 5, member: 'may', label: 'May', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 6, member: 'jun', label: 'Jun', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 7, member: 'jul', label: 'Jul', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 8, member: 'aug', label: 'Aug', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 9, member: 'sep', label: 'Sep', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 10, member: 'oct', label: 'Oct', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 11, member: 'nov', label: 'Nov', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 12, member: 'dec', label: 'Dec', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },

];

// Wizard setup

const requiredWizardFields = [
  [{ name: 'targetType', min: 1, max: 120 }, { name: 'cropSpecific', min: 1, max: 120 }], // wiz step 0
  [{ name: 'crop', min: 1, max: 120 }], // wiz step 1
  [{ name: 'target', min: 1, max: 120 }], // wiz step 2
  [], // wiz step 3
];

function WizardP1(props) {
  const {
    monitoring,
    handleChange,
    targetsTypesSetting,
    errors,
    classes,
    isNew,
  } = props;
  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl component="fieldset" className={classes.formControlRadio}>
          <FormLabel required>Type</FormLabel>
          <RadioGroup
            aria-label="targetType"
            name="targetType"
            id="targetType"
            disabled={!isNew}
            className={classes.group}
            value={monitoring.targetType}
            onChange={handleChange}
          >
            {targetsTypesSetting && targetsTypesSetting.map((targetType) => (
              <>
                <FormControlLabel key={targetType.sk} value={targetType.sk} control={<Radio disabled={!isNew} id="targetType" />} label={targetType.name} />
              </>
            ))}
            {errors.targetType
                  && <FormHelperText className={classes.helperText}>{errors.targetType}</FormHelperText>}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl component="fieldset" className={classes.formControlRadio}>
          <FormLabel required>Crop Specific</FormLabel>
          <RadioGroup
            aria-label="cropSpecific"
            name="cropSpecific"
            id="cropSpecific"
            className={classes.group}
            disabled={isNew}
            value={monitoring.cropSpecific}
            onChange={handleChange}
          >
            <FormControlLabel value="Yes" control={<Radio disabled={!isNew} id="cropSpecific" />} label="Yes" />
            <FormControlLabel value="No" control={<Radio disabled={!isNew} id="cropSpecific" />} label="No" />
            {errors.cropSpecific
                && (
                <FormHelperText
                  className={classes.helperText}
                >
                  {errors.cropSpecific}
                </FormHelperText>
                )}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}
WizardP1.propTypes = {
  errors: PropTypes.array.isRequired,
  monitoring: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  targetsTypesSetting: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired,
};

function WizardP2(props) {
  const {
    monitoring,
    handleObervableChange,
    crops,
    errors,
    classes,
    handleBlur,
    isNew,
  } = props;
  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="crop">Obervable on</InputLabel>
            <Select
              native
              required
              displayEmpty
              disabled={!isNew}
              value={monitoring.crop}
              onChange={handleObervableChange}
              onBlur={handleBlur}
              error={errors.crop ? true : undefined}
              inputProps={{
                name: 'crop',
                id: 'crop',
              }}
            >
              <option aria-label="empty" value="" />
              {crops && crops.map((crop) => (
                <>
                  <option value={crop.sk}>{crop.name}</option>
                </>
              ))}
            </Select>
            {errors.crop
      && <FormHelperText className={classes.helperText}>{errors.crop}</FormHelperText>}
          </FormControl>
        </Grid>

      </Grid>
    </Grid>
  );
}
WizardP2.propTypes = {
  errors: PropTypes.array.isRequired,
  monitoring: PropTypes.object.isRequired,
  crops: PropTypes.array.isRequired,
  handleObervableChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired,
};

function WizardP3(props) {
  const {
    monitoring,
    targetDetails,
    handleObervableChange,
    errors,
    classes,
    handleBlur,
    isNew,
    rows
  } = props;

  const existing = (rows||[]).map((x)=>({targetSK:x?.targetSK, observableOnSk:x?.observableOnSk}));
  console.log("targetDetails, rows",targetDetails,rows);
  const cropsk = monitoring?.crop

  const monitorExistsAlready=(array, object)=> {
    return array.some(item =>
        Object.keys(object).every(key => item[key] === object[key])
    );
}

    const filteredTargetDetails = (targetDetails||[]).filter((x)=>{
        const selectedCrop = {observableOnSk:cropsk, targetSK:x.sk}
        const existsAlready = monitorExistsAlready(existing,selectedCrop);
        if(!existsAlready){
         return x
        }
  })

  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="target">Targets</InputLabel>
          <Select
            native
            required
            displayEmpty
            disabled={!isNew}
            value={monitoring.target}
            onChange={handleObervableChange}
            onBlur={handleBlur}
            error={errors.target ? true : undefined}
            inputProps={{
              name: 'target',
              id: 'target',
            }}
          >
            <option aria-label="empty" value="" />
            {filteredTargetDetails.map((target) => (
              <>
                <option value={target.sk}>{target.name}</option>
              </>
            ))}
          </Select>
          {errors.target
              && <FormHelperText className={classes.helperText}>{errors.target}</FormHelperText>}
        </FormControl>
      </Grid>
    </Grid>
  );
}
WizardP3.propTypes = {
  errors: PropTypes.array.isRequired,
  monitoring: PropTypes.object.isRequired,
  targetDetails: PropTypes.array.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleObervableChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired,
};

function WizardP4(props) {
  const {
    monitoring,
    handleRadioChange,
    handleArrayChange,
    expandedStage,
    handleStageExpand,
    handleVariableTChange,
    getFromGlobalState,
    imageUrls,
    errors,
    classes,
    handleBlur,
    targetOther,
    handleInclude,
  } = props;
  const selectedTarget = monitoring.targetDetail;
  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <InfoBox message="Select the stages you want to monitor. You can adjust the thresholds for your farm." />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {monitoring.cropSpecific === 'Yes' && selectedTarget && selectedTarget.observableOn.map((item, obsIdx) => item && item.stages && item.stages.map((stage, sitemIdx) => (
          <ExpansionPanelDetailed
            // heading={!targetOther ? `Stage ${sitemIdx + 1}` : `${selectedTarget.name}`}
            headingComponent={(
              <>
                <Checkbox
                  edge="start"
                  checked={stage.selected ? stage.selected : false}
                  tabIndex={-1}
                  disableRipple
                  id={stage.sk}
                 // onClick={handleInclude (event, obsIdx, stageIdx, name)}
                  onClick={(event) => handleInclude(event, obsIdx, sitemIdx)}
                  inputProps={{ 'aria-labelledby': stage.sk }}
                />
                <Typography className={classes.heading}>
                  {!targetOther ? `Stage ${sitemIdx + 1}` : `${stage.monitorType} : ${stage.stageDescription}`}
                </Typography>
              </>
)}
           // detailHeading={`${stage.monitorType} : ${stage.stageDescription}`}
            expanded={expandedStage}
            type={stage.sk}
            handleExpand={(e) => handleStageExpand(stage)}
          >
            <TargetStage
              classes={classes}
              stage={stage}
              cropSpecific={selectedTarget.cropSpecific === 'Yes'}
              errors={errors}
              getFromGlobalState={getFromGlobalState}
              obsIdx={obsIdx}
              stageIdx={sitemIdx}
              targetType={selectedTarget.targetType}
              // handleDeleteStage={this.handleDeleteStage}
              handleRadioChange={handleRadioChange}
              handleArrayChange={handleArrayChange}
              handleBlur={handleBlur}
              // handleDeleteImage={this.handleDeleteImage}
              // fileUploaded={this.fileUploaded}
              handleVariableTChange={handleVariableTChange}
              imageUrls={imageUrls}
              isGeneral
              setId="a9d3f4b0-696b-4eb9-a9d7-48828697d1a3"
              disableUpload
              disableElevation
              disableDelete
              selectionMode
            />

          </ExpansionPanelDetailed>
        )))}
        {monitoring.cropSpecific === 'No'
         && (
         <TargetStage
           classes={classes}
           stage={selectedTarget.nonCropDetail}
           disableElevation
           errors={errors}
           getFromGlobalState={getFromGlobalState}
           cropSpecific={selectedTarget.cropSpecific === 'Yes'}
           targetType={selectedTarget.targetType}
           handleRadioChange={handleRadioChange}
           handleArrayChange={handleArrayChange}
           handleBlur={handleBlur}
           handleVariableTChange={handleVariableTChange}
           imageUrls={imageUrls}
           isGeneral
           disableUpload
           disableDelete
           selectionMode
           setId="a9d3f4b0-696b-4eb9-a9d7-48828697d1a3"
         />
         )}

      </Grid>
    </Grid>
  );
}
WizardP4.propTypes = {
  errors: PropTypes.array.isRequired,
  monitoring: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  imageUrls: PropTypes.array.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleArrayChange: PropTypes.func.isRequired,
  handleStageExpand: PropTypes.func.isRequired,
  handleVariableTChange: PropTypes.func.isRequired,
  expandedStage: PropTypes.func.isRequired,
  targetOther: PropTypes.bool.isRequired,
  handleInclude: PropTypes.func.isRequired,
};

class MonitoringD extends Component {
  constructor(props) {
    super(props);
    const { handleUpdate, rows } = this.props;
    this.handleUpdate = handleUpdate.bind(this);
    this.state = {
      monitoring: null,
      errors: { count: 0 },
      changed: false,
      activeStep: 0,
      isLoadingStep: false,
      targetDetails: [],
      includedTargets: [],
      expandedStage: '',
      imageUrls: {},
      steps: [{ label: 'Type' }, { label: 'Crops' }, { label: 'Target' }, { label: 'Stages' }],
      rows:rows
    };
  }

  async componentDidMount() {
    const { isNew, monitoring} = this.props;
    if (isNew) {
      await this.initWizard();
      this.setState({ monitoring: {}});
    } else {
      const newMonitoring = { ...monitoring };
      await this.initWizard();
      this.setState({ monitoring: newMonitoring,activeStep:3 });
    }
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const { monitoring } = this.state;
    const newMonitoring = { ...monitoring };
    newMonitoring[event.target.id] = event.target.value;
    this.setState({ monitoring: newMonitoring, changed: true });
  }

  handleCheckChange = (event) => {
    const { monitoring } = this.state;
    const newMonitoring = { ...monitoring };
    newMonitoring[event.target.id] = event.target.checked;
    this.setState({ monitoring: newMonitoring, changed: true });
  }

  handleInclude = (event, obsIdx, stageIdx) => {
    const { monitoring } = this.state;
    const newMonitoring = { ...monitoring };
    if (newMonitoring.cropSpecific === 'Yes') {
      const newVal = newMonitoring.targetDetail.observableOn[obsIdx].stages[stageIdx].selected ? !newMonitoring.targetDetail.observableOn[obsIdx].stages[stageIdx].selected : true;
      newMonitoring.targetDetail.observableOn[obsIdx].stages[stageIdx].selected = newVal;
    } else {
      const newVal = newMonitoring.targetDetail.nonCropDetail.selected ? !newMonitoring.targetDetail.nonCropDetail.selected : false;
      newMonitoring.targetDetail.nonCropDetail.selected = newVal;
    }
    // obj[event.target.id] = event.target.value;
    this.setState({ monitoring: newMonitoring, changed: true });
    event.preventDefault();
    event.stopPropagation();
    event.persist();
  }

  handleRadioChange = (event, obsIdx, stageIdx, name) => {
    const { monitoring } = this.state;
    const newMonitoring = { ...monitoring };
    if (newMonitoring.cropSpecific === 'Yes') {
      newMonitoring.targetDetail.observableOn[obsIdx].stages[stageIdx][name] = event.target.value;
    } else {
      newMonitoring.targetDetail.nonCropDetail[name] = event.target.value;
    }
    // obj[event.target.id] = event.target.value;
    this.setState({ monitoring, changed: true });
  }

  handleObervableChange = async (event) => {
    // call getTargets in genLib for this type and crop.
    const { monitoring } = this.state;
    const newMonitoring = { ...monitoring };
    newMonitoring[event.target.id] = event.target.value;
    this.setState({ monitoring: newMonitoring, changed: true });
  }

  handleTargetChange = async (event) => {
    // call getTargets in genLib for this type and crop.
    const { monitoring, targetDetails } = this.state;
    const newMonitoring = { ...monitoring };
    newMonitoring[event.target.id] = event.target.value;
    const selectedTargetObj = targetDetails.find((t) => t.sk === event.target.value);
    if (selectedTargetObj) {
      // const filteredTargetDetails = targetDetails.filter((t) => t.monitorType === 'Field')
      // find only the stages that are of the correct type.
      // const filteredStages = selectedTargetObj.observableOn[0].stages && selectedTargetObj.observableOn[0].stages.filter((s) => s.monitorType === monitoring.monitorType);
      // selectedTargetObj.observableOn[0].stages = filteredStages;
      selectedTargetObj.isGeneral = false;
    }
    newMonitoring.targetDetail = { ...selectedTargetObj };
    this.setState({ monitoring: newMonitoring, changed: true });
  }

  handleVariableTChange = (event, obsIdx, stageIdx, month, range) => {
    const { monitoring } = this.state;
    const newMonitoring = { ...monitoring };
    if (monitoring.cropSpecific === 'Yes') {
      if (!newMonitoring.targetDetail.observableOn[obsIdx].stages[stageIdx].variableThreshold) {
        newMonitoring.targetDetail.observableOn[obsIdx].stages[stageIdx].variableThreshold = {};
      }
      if (!newMonitoring.targetDetail.observableOn[obsIdx].stages[stageIdx].variableThreshold[month]) {
        newMonitoring.targetDetail.observableOn[obsIdx].stages[stageIdx].variableThreshold[month] = {};
      }
      if (!newMonitoring.targetDetail.observableOn[obsIdx].stages[stageIdx].variableThreshold[month][range]) {
        newMonitoring.targetDetail.observableOn[obsIdx].stages[stageIdx].variableThreshold[month][range] = {};
      }
      newMonitoring.targetDetail.observableOn[obsIdx].stages[stageIdx].variableThreshold[month][range] = event.target.value;
    } else {
      if (!newMonitoring.targetDetail.nonCropDetail.variableThreshold) {
        newMonitoring.targetDetail.nonCropDetail.variableThreshold = {};
      }
      if (!newMonitoring.targetDetail.nonCropDetail.variableThreshold[month]) {
        newMonitoring.targetDetail.nonCropDetail.variableThreshold[month] = {};
      }
      if (!newMonitoring.targetDetail.nonCropDetail.variableThreshold[month][range]) {
        newMonitoring.targetDetail.nonCropDetail.variableThreshold[month][range] = {};
      }
      newMonitoring.targetDetail.nonCropDetail.variableThreshold[month][range] = event.target.value;
    }
    this.setState({ monitoring: newMonitoring, changed: true });
  }

  //

  handleToggle = (value) => {
    const { monitoring } = this.state;
    const currentIndex = monitoring.allowedCrops.indexOf(value);
    const newMonitoring = { ...monitoring };

    if (currentIndex === -1) {
      newMonitoring.allowedCrops.push(value);
    } else {
      newMonitoring.allowedCrops.splice(currentIndex, 1);
    }

    this.setState({ monitoring: newMonitoring, changed: true });
  }

  initWizard = async () => {
    const newStep = 0;
    this.setState({ isLoadingStep: true });
    await this.getStepContent(newStep);
    this.setState({ activeStep: newStep, isLoadingStep: false });
  }

  handleNext = async () => {
    const { activeStep, monitoring } = this.state;
    let newStep;
    if (activeStep === 0 && monitoring.cropSpecific === 'No') {
      newStep = activeStep + 2;
    } else {
      newStep = activeStep + 1;
    }
    this.setState({ isLoadingStep: true });
    await this.getStepContent(newStep);
    this.setState({ activeStep: newStep, isLoadingStep: false });
  }

  handleBack = async () => {
    const { activeStep, monitoring } = this.state;
    let newStep;
    if (activeStep === 2 && monitoring.cropSpecific === 'No') {
      newStep = activeStep - 2;
    } else {
      newStep = activeStep - 1;
    }
    this.setState({ isLoadingStep: true });
    await this.getStepContent(newStep);
    this.setState({ activeStep: newStep, isLoadingStep: false });
  }

  handleReset = () => {
    this.setState({ activeStep: 0 });
  }

  handleFinish = () => {
    // const { monitoring } = this.state;
  }

  filterTargets = (monitoring, targetDetails) => targetDetails.filter((t) => t.cropSpecific === monitoring.cropSpecific)
  /* let i = 0;
    const filteredTagerDetails = [];
    const len = targetDetails.length;
    while (i < len) {
      if (targetDetails[i] && targetDetails[i].observableOn[0] && targetDetails[i].observableOn[0].stages) {
        if (targetDetails[i].observableOn[0].stages.find((s) => s.monitorType === monitorType)) {
          filteredTagerDetails.push(targetDetails[i]);
        }
      }
      i += 1;
    }
    return filteredTagerDetails; */

  getStepContent = async (activeStep) => {
    const { monitoring } = this.state;
    const {
      getFromGlobalState, setSnack, setGlobalState,
    } = this.props;

    switch (activeStep) {
      case 2: {
        let targetDetails = [];
        if (monitoring.cropSpecific === 'Yes') {
          targetDetails = await getTargetDetails(getFromGlobalState, setGlobalState, setSnack, monitoring.targetType, monitoring.crop);
        } else {
          targetDetails = await getNonCropSpecificTargetDetails(getFromGlobalState, setGlobalState, setSnack, monitoring.targetType);
        }
        const filteredTargets = this.filterTargets(monitoring, targetDetails);
        // sort filter targets alphabeticaly
        filteredTargets.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.setState({ targetDetails: filteredTargets });
        break;
      }
      case 3: {
        if (monitoring.cropSpecific === 'No') {
          const { imageUrls } = this.state;
          const newImageUrls = await this.getNonCropSpecificUrls(monitoring, imageUrls);
          this.setState({ imageUrls: newImageUrls });
        }
        break;
      }

      default:
        return 'Unknown stepIndex';
    }
  }

  handleArrayChange = (event, obsIdx, stageIdx) => {
    const { monitoring } = this.state;
    const newMonitoring = { ...monitoring };
    if (newMonitoring.cropSpecific === 'Yes') {
      newMonitoring.targetDetail.observableOn[obsIdx].stages[stageIdx][event.target.id] = event.target.value;
    } else {
      newMonitoring.targetDetail.nonCropDetail[event.target.id] = event.target.value;
    }
    // obj[event.target.id] = event.target.value;
    this.setState({ monitoring, changed: true });
  }

  handleStageExpand = async (stage) => {
    if (!stage) {
      return;
    }
    const { expandedStage, imageUrls } = this.state;
    if (expandedStage !== stage.sk) {
      const newExpandedStage = stage.sk;
      this.setState({ expandedStage: newExpandedStage });
      const newImageUrls = stage.images && await this.getUrls(stage.images, imageUrls);
      this.setState({ imageUrls: newImageUrls });
    } else {
      this.setState({ expandedStage: '' });
    }
  }

  getNonCropSpecificUrls = async (target, imageUrls) => {
    const { getFromGlobalState } = this.props;
    const activeTenant = getFromGlobalState('activeSite');
    const newImageUrls = { ...imageUrls };

    if (target.targetDetail.nonCropDetail && target.targetDetail.nonCropDetail.images) {
      for (const image of target.targetDetail.nonCropDetail.images) {
        if (!newImageUrls[image.Key]) {
          const params = {
            activeTenant,
            type: 'files',
            setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
            path: image.Key,
            contentType: image.fileType,
            isGeneral: true,
            collectionName: 'targets/pests',
          };
          newImageUrls[image.Key] = await getDownloadUrl(params);
        }
      }
    }
    return newImageUrls;
  }

  getUrls = async (images, imageUrls) => {
    if (!images) {
      return imageUrls;
    }
    const { getFromGlobalState } = this.props;
    const activeTenant = getFromGlobalState('activeSite');
    const newImageUrls = { ...imageUrls };

    await Promise.all(images.map(async (image) => {
      if (!newImageUrls[image.Key]) {
        const params = {
          activeTenant,
          type: 'files',
          setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
          path: image.Key,
          contentType: image.fileType,
          isGeneral: true,
          collectionName: 'targets/pests',
        };
        newImageUrls[image.Key] = await getDownloadUrl(params);
      }
    }));
    return newImageUrls;
  }

  validateField(field) {
    const { monitoring, errors } = this.state;
    const error = validate(field, monitoring, requiredFields);
    const newErrors = { ...errors };
    newErrors[field] = error.msg;
    this.setState({ errors: newErrors });
  }

  render() {
    const {
      classes,
      open,
      isNew,
      setClose,
      metaData,
      getFromGlobalState,
    } = this.props;
    const crops = metaData;
    const {
      monitoring,
      errors,
      changed,
      activeStep,
      isLoadingStep,
      targetDetails,
      expandedStage,
      imageUrls,
      steps,
      includedTargets,
    } = this.state;
    const stepValid = validateForm(monitoring, requiredWizardFields[activeStep]);
    const formValid = isNew ? stepValid && activeStep === steps.length - 1 : stepValid;
    if (!monitoring) {
      return null;
    }
    const targetsTypesSetting = getFromGlobalState('targetsTypesSetting');
    const targetOther = targetsTypesSetting.find((t) => t.name === 'Other').sk;
    const isMobile = getFromGlobalState('isMobile');
    return (

      <GenDialog
        open={open}
        dialogObject={monitoring}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={this.handleUpdate}
        skPrimary="init_setting_"
        dynamoType="monitorings"
        valid={formValid}
        changed={changed}
        newTitle="New Monitoring"
        title={monitoring && monitoring.targetDetail && monitoring.targetDetail.labelName ? monitoring.targetDetail.labelName : 'New Monitoring'}
        getFromGlobalState={getFromGlobalState}
      >
        <>
          {!isNew && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={monitoring.isDisabled}
                 // disabled={isInvite}
                  id="isDisabled"
                  onChange={this.handleCheckChange}
                  value="isDisabled"
                  color="secondary"
                />
        )}
              label="Disabled"
            />
          </Grid>
          )}
          <Wizard
            steps={steps}
            activeStep={activeStep}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            handleReset={this.handleReset}
            handleFinish={this.handleFinish}
            stepValid={stepValid}
            minHeight="250px"
            minWidth="550px"
            replaceFinish
            isMobile={isMobile}
            isLoadingStep={isLoadingStep}
          >
            <WizardP1
              errors={errors}
              monitoring={monitoring}
              handleChange={this.handleChange}
              targetsTypesSetting={targetsTypesSetting}
              classes={classes}
              isNew={isNew}
            />
            <WizardP2
              errors={errors}
              monitoring={monitoring}
              crops={crops}
              handleObervableChange={this.handleObervableChange}
              targetOther={targetOther}
              handleBlur={this.handleBlur}
              classes={classes}
              isNew={isNew}
            />
            <WizardP3
              monitoring={monitoring}
              targetDetails={targetDetails}
              handleObervableChange={this.handleTargetChange}
              errors={errors}
              classes={classes}
              handleBlur={this.handleBlur}
              isNew={isNew}
              rows={this.state.rows}
            />
            <WizardP4
              monitoring={monitoring}
              targetDetails={targetDetails}
              handleRadioChange={this.handleRadioChange}
              handleArrayChange={this.handleArrayChange}
              expandedStage={expandedStage}
              handleStageExpand={this.handleStageExpand}
              handleVariableTChange={this.handleVariableTChange}
              getFromGlobalState={getFromGlobalState}
              imageUrls={imageUrls}
              errors={errors}
              classes={classes}
              handleBlur={this.handleBlur}
              targetOther={targetOther}
              isNew={isNew}
              handleInclude={this.handleInclude}
              includedTargets={includedTargets}
            />
          </Wizard>
        </>
        {/*  <Grid container className={classes.root} spacing={1} alignItems="flex-start">

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              // label={monitoring.target === 'Pest' ? 'Pest Name' : 'Disease Name'}
              name="name"
              // autoFocus
              value={monitoring.name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="unitNum">Type</InputLabel>
              <Select
                native
                style={{ minWidth: '200px' }}
                required
                displayEmpty
                value={monitoring.unitNum}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={errors.unitNum ? true : undefined}
                inputProps={{
                  name: 'unitNum',
                  id: 'unitNum',
                }}
              >
                <option aria-label="empty" value="" />
                <option value="Number">Number</option>
                <option value="Present">Present / Absent</option>
                <option value="Percentage">Percentage</option>

              </Select>
              {errors.unitNum
              && <FormHelperText className={classes.helperText}>{errors.unitNum}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
              fullWidth
              id="quantityOfUnit"
              label="Number of observation points"
              name="quantityOfUnit"
              value={monitoring.quantityOfUnit}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.quantityOfUnit ? true : undefined}
              helperText={errors.quantityOfUnit}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="unit">{monitoring.target === 'Other' ? 'Unit' : 'Point description'}</InputLabel>
              <Select
                native
                style={{ minWidth: '200px' }}
                required
                displayEmpty
                value={monitoring.unit}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={errors.unit ? true : undefined}
                inputProps={{
                  name: 'unit',
                  id: 'unit',
                }}
              >
                <option aria-label="empty" value="" />
                {unitList && unitList.map((unit) => <option value={unit.value}>{unit.value}</option>)}

              </Select>
              {errors.unit && <FormHelperText className={classes.helperText}>{errors.unit}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="frequency">Observation frequency</InputLabel>
              <Select
                native
                style={{ minWidth: '200px' }}
                required
                displayEmpty
                value={monitoring.frequency}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={errors.unit ? true : undefined}
                inputProps={{
                  name: 'frequency',
                  id: 'frequency',
                }}
              >
                <option aria-label="empty" value="" />
                <option value="Second">Second</option>
                <option value="Minute">Minute</option>
                <option value="Hourly">Hourly</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Annually">Annually</option>
              </Select>
              {errors.frequency && <FormHelperText className={classes.helperText}>{errors.frequency}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl component="fieldset" className={classes.formControlRadio}>
              <FormLabel required>Threshold</FormLabel>
              <RadioGroup
                aria-label="threshold"
                name="threshold"
                id="threshold"
                className={classes.group}
                value={monitoring.threshold}
                onChange={this.handleChange}
              >
                <FormControlLabel value="Yes" control={<Radio id="threshold" />} label="Yes" />
                <FormControlLabel value="No" control={<Radio id="threshold" />} label="No" />
                {errors.threshold
                && <FormHelperText className={classes.helperText}>{errors.threshold}</FormHelperText>}
              </RadioGroup>
            </FormControl>
          </Grid>
          {monitoring.threshold && monitoring.threshold === 'Yes' && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl component="fieldset" className={classes.formControlRadio}>
              <FormLabel required>Constant Threshold</FormLabel>
              <RadioGroup
                aria-label="thresholdConstant"
                name="thresholdConstant"
                id="thresholdConstant"
                className={classes.group}
                value={monitoring.thresholdConstant}
                onChange={this.handleChange}
              >
                <FormControlLabel value="Yes" control={<Radio id="thresholdConstant" />} label="Yes" />
                <FormControlLabel value="No" control={<Radio id="thresholdConstant" />} label="No" />
                {errors.thresholdConstant && <FormHelperText className={classes.helperText}>{errors.thresholdConstant}</FormHelperText>}
              </RadioGroup>
            </FormControl>
          </Grid>
          )}

          { monitoring.threshold && monitoring.threshold === 'Yes' && monitoring.thresholdConstant && monitoring.thresholdConstant === 'Yes' && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormLabel required>Thresholds</FormLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="thresholdLow"
                  label="Low"
                  name="thresholdLow"
                  value={monitoring.thresholdLow}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  error={errors.thresholdLow ? true : undefined}
                  helperText={errors.thresholdLow}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="thresholdMedium"
                  label="Medium"
                  name="thresholdMedium"
                  value={monitoring.thresholdMedium}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  error={errors.thresholdMedium ? true : undefined}
                  helperText={errors.thresholdMedium}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="thresholdHigh"
                  label="High"
                  name="thresholdHigh"
                  value={monitoring.thresholdHigh}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  error={errors.thresholdHigh ? true : undefined}
                  helperText={errors.thresholdHigh}
                />
              </Grid>
            </>
          )}
          {monitoring.thresholdConstant && monitoring.thresholdConstant === 'No' && variableThresholds.map((threshold) => (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormLabel required>{threshold.label}</FormLabel>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id={`${threshold.member}Low`}
                  label="Low"
                  name={`${threshold.member}Low`}
                  value={monitoring.variableThreshold && monitoring.variableThreshold[threshold.member] && monitoring.variableThreshold[threshold.member].low ? monitoring.variableThreshold[threshold.member].low : undefined}
                  onChange={this.handleVariableTChange.bind(this, threshold.member, 'low')}
                  onBlur={this.handleBlur}
                  error={errors.variableThreshold && monitoring.variableThreshold[threshold.member] && errors.variableThreshold[threshold.member].low ? true : undefined}
                  helperText={errors.variableThreshold ? errors.variableThreshold[threshold.member] : undefined}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id={`${threshold.member}Med`}
                  label="Medium"
                  name={`${threshold.member}Med`}
                  value={monitoring.variableThreshold && monitoring.variableThreshold[threshold.member] && monitoring.variableThreshold[threshold.member].med ? monitoring.variableThreshold[threshold.member].med : undefined}
                  onChange={this.handleVariableTChange.bind(this, threshold.member, 'med')}
                  onBlur={this.handleBlur}
                  error={errors.variableThreshold && monitoring.variableThreshold[threshold.member] && errors.variableThreshold[threshold.member].med ? true : undefined}
                  helperText={errors.variableThreshold ? errors.variableThreshold[threshold.member] : undefined}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id={`${threshold.member}High`}
                  label="High"
                  name={`${threshold.member}High`}
                  value={monitoring.variableThreshold && monitoring.variableThreshold[threshold.member] && monitoring.variableThreshold[threshold.member].high ? monitoring.variableThreshold[threshold.member].high : undefined}
                  onChange={this.handleVariableTChange.bind(this, threshold.member, 'high')}
                  onBlur={this.handleBlur}
                  error={errors.variableThreshold && monitoring.variableThreshold[threshold.member] && errors.variableThreshold[threshold.member].high ? true : undefined}
                  helperText={errors.variableThreshold ? errors.variableThreshold[threshold.member] : undefined}
                />
              </Grid>
            </>
          ))}

        </Grid> */}
      </GenDialog>
    );
  }
}
// create default propTypes for open, close, and isNew
MonitoringD.defaultProps = {
  isNew: false,
  open: false,
};

MonitoringD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  monitoring: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  metaData: PropTypes.array.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  rows:PropTypes.array
};
export default withStyles(styles)(MonitoringD);
