import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import UserD from '../UserD';
import {
  addUser,
  enableUser,
  disableUser,
  userInviteDelete,
  sharedUserInvite,
  deleteUser,
  sharedUserInviteDelete,
} from '../../libs/storage';
import EnhancedTable from '../../components/Etable';
import ConfirmDialog from '../../components/ConfirmDialog';
import Tabs from '../../components/Tabs';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  aligner: {
    // display:'inline-flex',
    // marginRight:'auto',
    // marginBottom:'15px',
  },
  paperDropperBody: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  heading: {
    marginTop: '4px',
    cursor: 'pointer',
    // marginTop: '10px',
  },
  submit: {
    margin: theme.spacing(1),
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    // overflow: 'scroll',
  },
  mainSection: {
    // display: 'flex',
    width: '100%',
    // display: 'flex',
    flexWrap: 'wrap',
    // flexDirection: 'column',
  },
  parentSection: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },

  },
  childSection: {
    marginLeft: theme.spacing(5),
  },
  childSectionChildren: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class UserDetailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedSetting: '',
      // users: this.props.initialState,
      selectedUser: null,
      selectedVariety: null,
      modalObject: null,
      isNew: false,
      userDialogOpen: false,
      varietyDialogOpen: false,
      errors: {},
      openModal: '',
      confirmExistingUserAdd: false,
      sharedUser: null,
      isLoadingShared: false,
      selectedTab: 0,
      headCells: [],

    };
  }

  handleClickShow = () => {
    this.setState({ userDialogOpen: true });
  }

  componentDidMount =() => {
    console.log(this.props.initialState);
    const isMobile = !window.matchMedia('(min-width: 850px)').matches;
    let headCells = { ...this.state };
    if (!isMobile) {
      headCells = [
        {
          id: 'enabledText', numeric: false, disablePadding: false, label: 'Enabled', maxWidth: '70px',
        },
        {
          id: 'name', combine: [{ id: 'name', opacity: 1 }, { id: 'email', opacity: 0.6 }], numeric: false, disablePadding: true, label: 'Users',
        },
        {
          id: 'group', numeric: false, disablePadding: false, label: 'Group', maxWidth: '70px',
        },

      ];
    } else {
      headCells = [
        {
          id: 'name', combine: [{ id: 'name', opacity: 1 }, { id: 'email', opacity: 0.6 }], numeric: false, disablePadding: true, label: 'Users',
        },
      // { id: 'email', numeric: false, disablePadding: false, label: `email` },
      ];
    }
    const clean = isMobile;
    this.setState({ headCells, clean });
  }

  handleBlur = (event) => {
    // return this.validateField (event.target.id, this.props.requiredFields);
  }

  handleClickAdd() {
    this.setState({ userDialogOpen: true, isNew: true, selectedUser: {} });
  }

  handleClickShowUser(event, planting, selectedUserId, actionType, dunno, recordType) {
    let selectedUser = this.props.getFromGlobalState('users').find((user) => user.sk === selectedUserId);
    if (!selectedUser) {
      selectedUser = this.props.getFromGlobalState('sentInvites').find((user) => user.sk === selectedUserId);
    }
    if (!selectedUser) {
      selectedUser = this.props.getFromGlobalState('sentSharedInvites').find((user) => user.sk === selectedUserId);
    }
    this.setState({
      selectedUser, selectedUserSK: selectedUserId, userDialogOpen: 'true', isNew: false,
    });
  }

  handleClickEdit() {
    this.setState({ userDialogOpen: true, isNew: true });
  }

  setClose() {
    if (!this.state.confirmExistingUserAdd) {
      this.setState({ userDialogOpen: false });
    }
  }

  setCloseVariety() {
    this.setState({ varietyDialogOpen: false });
  }

  handleCloseModal = () => {
    this.setState({ confirmExistingUserAdd: false });
  }

  hasEnableToggled(userIn) {
    const stateUser = this.props.getFromGlobalState('users').find((user) => user.sk === userIn.sk);

    return { toggled: (stateUser.enabled !== userIn.enabled), enabled: userIn.enabled };
  }

  handleDeleteDialogObject = (dialogObject) => {
    const users = this.props.getFromGlobalState('users');
    const idx = users.findIndex((u) => u.sk === dialogObject.sk);
    if (idx !== -1) {
      users.splice(idx, 1);
    }
    this.props.setGlobalState({ users });
    console.log("deleting user now", users);

  }

  handleUserDelete = async (dialogObject, event) => {
    try {
      const { setGlobalState, getFromGlobalState } = this.props;
      if (dialogObject.type === 'SentSharedInvite' || dialogObject.rejected) {
        this.setState({ isSubmitting: true });
        await sharedUserInviteDelete({
          type: 'users', activeTenant: getFromGlobalState('activeSite'), sk: dialogObject.sk, email: dialogObject.email,
        });

        const sentSharedInvites = getFromGlobalState('sentSharedInvites');
        const idx = sentSharedInvites.findIndex((p) => p.sk === dialogObject.sk);
        if (idx !== -1) {
          sentSharedInvites.splice(idx, 1);
          setGlobalState({ sentSharedInvites });
        }
        this.setState({ isSubmitting: false, success: true });

        dialogObject.rejected && this.handleDeleteDialogObject(dialogObject);
        
        return false; // we are done nothing more to do by gendialog.
      }

      if (dialogObject.type === 'SentInvite') {
        this.setState({ isSubmitting: true });
        await userInviteDelete({
          type: 'users', activeTenant: getFromGlobalState('activeSite'), sk: dialogObject.sk, email: dialogObject.email,
        });
        const sentInvites = getFromGlobalState('sentInvites');
        const idx = sentInvites.findIndex((p) => p.sk === dialogObject.sk);
        if (idx !== -1) {
          sentInvites.splice(idx, 1);
          setGlobalState({ sentInvites });
        }
        this.setState({ isSubmitting: false, success: true });
        return false; // we are done nothing more to do by gendialog.
      }
      if (dialogObject.shared) {
        return true; // shared user - let gendialog delete the user.
      }
      this.setState({ isSubmitting: true });
      await deleteUser({ type: 'users', activeTenant: getFromGlobalState('activeSite'), sk: dialogObject.sk });
      this.setState({ isSubmitting: false, success: true });
      return false;
    } catch (e) {
      this.setState(
        {
          success: false,
          isSubmitting: false,
          messageText: (e.response ? `${e.response.data.error}, api src: ${e.response.data.src} ` : e.message),
        },
      );
      throw (e);
    }
  };

  async handlePreExecute(userIn) {
    const activeTenant = this.props.getFromGlobalState('activeSite');
    if (this.state.isNew) {
      try {
        const res = await addUser(userIn);
        this.setState({ isloading: false });
        userIn.userName = res.Username;
        this.props.setSnack({
          open: true, variant: 'info', autoHideDuration: 4000, messageText: 'User invitation sent. You can view the user post acceptance.',
        });
        return false;
      } catch (e) {
        this.setState({ isloading: false });
        if (e.response && e.response.data && e.response.data.error) {
          if (e.response.data.error.code === 'UsernameExistsException') {
            userIn.activeTenant = activeTenant;
            userIn.shared = true;
            this.setState({ confirmExistingUserAdd: true, sharedUser: userIn });
            return false;
          }
          throw (e);
        } else {
          throw (e);
        }
      }
    }
    const userToggle = this.hasEnableToggled(userIn);
    if (userIn.shared) {
      return true;
    }
    if (userToggle.toggled) {
      try {
        if (userToggle.enabled) {
          await enableUser({ sk: userIn.sk, type: 'users', activeTenant });
        } else {
          await disableUser({ sk: userIn.sk, type: 'users', activeTenant });
        }
        this.setState({ isloading: false });
        return true;
      } catch (e) {
        this.setState({ isloading: false });
        throw (e);
      }
    } else {
      return true;
    }
  }

  handleSubmitAddExistingUser = async (dialogObject) => {
    // logic here to add existing user to our farm.
    try {
      const sharedUser = dialogObject && dialogObject?.rejected ? {...dialogObject?.rejected} : this.state.sharedUser;
      if (!sharedUser) { return false; }
      this.setState({ isLoadingShared: true });
      delete sharedUser.rejected;
      await sharedUserInvite(sharedUser);
      const sentSharedInvites = this.props.getFromGlobalState('sentSharedInvites');
      sharedUser.type = 'SentSharedInvite';
      sentSharedInvites.push(sharedUser);
      this.props.setGlobalState({ sentSharedInvites });
      this.setState({ isLoadingShared: false, userDialogOpen: false, confirmExistingUserAdd: false });
      this.props.setSnack({
        open: true, variant: 'info', autoHideDuration: 2000, messageText: 'User invitation sent.',
      });
    } catch (e) {
      this.setState({ isLoadingShared: false, userDialogOpen: false, confirmExistingUserAdd: false });
      this.props.setSnack({ open: true, variant: 'error', messageText: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error}, api src: ${e.response.data.src} ` : e.message) });
      return false;
    }
  }

  handleTabChange = (event, newValue) => {
    const currentTab = this.state.selectedTab;
    if (newValue !== currentTab) {
      this.setState({ selectedTab: newValue });
    }
  }

  async handleUpdate(userIn) {
    const updatedIndex = this.props.getFromGlobalState('users').findIndex((i) => i.sk === userIn.sk);
    if (userIn.shared) {
      return;
    }
    if (updatedIndex === -1) {
      const sentInvites = this.props.getFromGlobalState('sentInvites');
      userIn.type = 'SentInvite';
      sentInvites.push(userIn);
      this.props.setGlobalState({ sentInvites });
    } else {
      const newUsers = this.props.getFromGlobalState('users');
      newUsers[updatedIndex] = userIn;
      this.props.setGlobalState({ users: newUsers });
    }
  }

  render() {
    const {
      classes, initialState, allowDelete, handleDelete, getFromGlobalState,
    } = this.props;
    const { isLoadingShared, headCells, clean } = this.state;
    const users = initialState;
    const sentInvites = this.props.getFromGlobalState('sentInvites');
    const sentSharedInvites = this.props.getFromGlobalState('sentSharedInvites');
    const userDetail = getFromGlobalState('userDetail');
    console.log(userDetail);

    let rows = users.filter((u) => !u.rejected && !u.shared).map((user, i) => {
      const ob = { ...user };
      ob.name = `${ob.name} ${ob.surname}`;
      ob.enabledText = ob.enabled ? 'Yes' : 'No';
      return ob;
    });
    const sentInvitesRow = sentInvites ? sentInvites.map((user, i) => {
      const ob = { ...user };
      ob.name = `${ob.name} ${ob.surname}`;
      ob.surname = ob.surname ? ob.surname : 'No surname';
      ob.enabledText = 'Pending';
      // ob.disabled = true;
      return ob;
    }) : [];
    rows = rows.concat(sentInvitesRow);

    const rejectedRows = users.filter((u) => u.rejected && !u.shared).map((user, i) => {
      const ob = { ...user };
      ob.name = `${ob.name} ${ob.surname}`;
      ob.enabledText = ob.enabled ? 'Yes' : 'No';
      return ob;
    });

    let sharedRows = users.filter((u) => !u.rejected && u.shared).map((user, i) => {
      const ob = { ...user };
      ob.name = `${ob.name} ${ob.surname}`;
      ob.enabledText = ob.enabled ? 'Yes' : 'No';
      return ob;
    });

    const sentSharedRows = sentSharedInvites ? sentSharedInvites.map((user, i) => {
      const ob = { ...user };
      ob.name = ' ';
      ob.name = `${ob.name} ${ob.surname}`;
      ob.surname = ob.surname ? ob.surname : 'No surname';
      ob.enabledText = 'Pending';
      // ob.disabled = true;
      return ob;
    }) : [];
    sharedRows = sharedRows.concat(sentSharedRows);

    return (

      <Paper elevation={0} className={classes.mainSection}>

        {(this.state.userDialogOpen)
          && (
          <UserD
            handleUpdate={this.handleUpdate.bind(this)}
            open={this.state.userDialogOpen}
            setClose={this.setClose.bind(this)}
            isNew={this.state.isNew}
            setSnack={this.props.setSnack}
            user={this.state.selectedUser}
            getFromGlobalState={this.props.getFromGlobalState}
            handlePreExecute={this.handlePreExecute.bind(this)}
            handleDeletePreExecute={this.handleUserDelete.bind(this)}
            handleSubmitAddExistingUser={this.handleSubmitAddExistingUser}
            allowDelete={this.state.selectedUser.type !== 'sentInvites'}
            handleDeleteDialogObject={this.handleDeleteDialogObject ? this.handleDeleteDialogObject.bind(this) : undefined}
          />
          )}
        <Tabs value={this.state.selectedTab} handleChange={this.handleTabChange}>
          <EnhancedTable
            userAvatar
            heading="My users"
            displayToolbar
            hideHeading
            id="userTable"
            noPagination //= { rows && rows.length > 5 ? false:true}
            rows={rows}
            headCells={headCells}
            handleSelect={this.handleClickShowUser.bind(this)}
            handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handleClickAdd.bind(this) : null}
                         // parent={}
            actionType="actionType"
            rowKeyName="sk"
            disablePadFirstCol
            padFirstCol="5px"
            clean
            maxHeight="350px"
            selectedRow={this.state.selectedUserSK}
          />
          <EnhancedTable
            userAvatar
            displayToolbar
            hideHeading
            id="userTableShared"
            heading="Shared Users"
            noPagination //= { sharedRows && sharedRows.length > 5 ? false:true}
            rows={sharedRows}
                         // dense
            maxHeight="350px"
            headCells={headCells}
            handleSelect={this.handleClickShowUser.bind(this)}
                          // handleAddItem={this.handleClickAdd.bind(this)}
                         // parent={}
            actionType="actionType"
            rowKeyName="sk"
            disablePadFirstCol
            padFirstCol="5px"
            clean
            selectedRow={this.state.selectedUserSK}
          />
          {/* {rejectedRows && rejectedRows.length > 0
          && ( */}
          <EnhancedTable
            userAvatar
            hideHeading
            displayToolbar
            id="userTableRejected"
            heading="Rejected User Invitations"
            noPagination// ={ rejectedRows && rejectedRows.length > 5 ? false:true}
            rows={rejectedRows}
            headCells={headCells}
            handleSelect={this.handleClickShowUser.bind(this)}
                         // handleAddItem={this.handleClickAdd.bind(this)}
                         // parent={}
            actionType="actionType"
            rowKeyName="sk"
            disablePadFirstCol
            padFirstCol="5px"
            clean
                        //  dense
            maxHeight="350px"
            selectedRow={this.state.selectedUserSK}
          />
          {/* ) } */}
        </Tabs>

        {this.state.confirmExistingUserAdd
           && (
           <ConfirmDialog
             open={this.state.confirmExistingUserAdd}
             loading={isLoadingShared}
             title="Shared User"
             actionText="Add"
             message="The user with this email address already has a profile on Land Diary. Do you want to invite the user to this farm? The access rights you have selected will apply."
             handleConfirm={this.handleSubmitAddExistingUser.bind(this)}
             handleCancel={this.handleCloseModal}
           />
           )}

      </Paper>
    );
  }
}
UserDetailForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  initialState: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(UserDetailForm);
