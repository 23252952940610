import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(1),
  },
  buttonLeft: {
    //margin: theme.spacing(1),
    textAlign: 'left',
  },
}));

export default function Policy(props) {
  const classes = useStyles();
  const {
    message, onChecked, checked, linkCaption, displayPolicy, handleCancel, id, onDisplayPolicy, policyCaption,
  } = props;
  return (
    <>

      <Button disableRipple className={classes.buttonLeft} color="primary" onClick={onDisplayPolicy}>{linkCaption}</Button>
      {displayPolicy && (
      <Dialog
        open={displayPolicy}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{policyCaption}</DialogTitle>
        <DialogContent>
          {message && (
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
          ) }
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={(
              <Checkbox
                checked={checked}
                onChange={onChecked}
                name={id}
                id={id}
              />
        )}
            label="I accept"
          />
          <Button
            onClick={handleCancel}
            color="primary"
            variant="contained"
            className={classes.submit}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      )}

    </>
  );
}
Policy.propTypes = {
  message: PropTypes.string.isRequired,
  onChecked: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  linkCaption: PropTypes.string.isRequired,
  displayPolicy: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onDisplayPolicy: PropTypes.func.isRequired,
  policyCaption: PropTypes.string.isRequired,
};
