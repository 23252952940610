import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import LoaderButton from '../../components/LoaderButton';


const styles = (theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(1),
    },
  });
  

 class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      oldPassword: '',
      isChanging: false,
      confirmPassword: ''
    };
  }


  validateForm() {
    return (
      this.state.oldPassword.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleChangeClick = async (event) => {
    event.preventDefault();

    this.setState({ isChanging: true });

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        this.state.oldPassword,
        this.state.password
      );

      this.props.history.push('/');
    } catch (e) {
      alert(e.message);
      this.setState({ isChanging: false });
    }
  };



  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleChangeClick}>
        <TextField
            margin="normal"
            required
            type="password"
            fullWidth
            id="oldPassword"
            label="Old Password"
            name="oldPassword"
           // autoComplete="email"
            autoFocus
            value={this.state.oldPassword}
            onChange={this.handleChange}
          /> 
          <TextField
            margin="normal"
            required
            type="password"
            fullWidth
            id="password"
            label="Old Password"
            name="password"
           // autoComplete="email"
           // autoFocus
            value={this.state.password}
            onChange={this.handleChange}
          /> 
           <TextField
            margin="normal"
            required
            type="password"
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            name="confirmPassword"
           // autoComplete="email"
            autoFocus
            value={this.state.confirmPassword}
            onChange={this.handleChange}
          /> 
    
    <LoaderButton
            type="submit"
            // fullWidth
             variant="contained"
             color="primary"
             className={classes.submit}
             disabled={!this.validateForm()}
             isloading={this.state.isChanging}
             text="Change"
             loadingText="Changing…"
            />

       
      </form>
    );
  }
}
export default withStyles(styles)(ChangePassword);
