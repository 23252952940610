import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { findBlock } from '../../libs/geoLib';
import Map from '../../components/Map';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    backgroundColor: 'inherit',
    // overflow: 'scroll',
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  section: {
    margin: theme.spacing(2, 4),
  },

});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.onMapBlockClick = this.onMapBlockClick.bind(this);
    this.onCloseInfoWindow = this.onCloseInfoWindow.bind(this);
    this.onMapClick = this.onMapClick.bind(this);

    this.state = {
      currentLocation: null,
      foundBlock: null,
      watchId: '',
      blockPaths: [],
      infoWindow: null,
      infoWindowPosition: null,
      userMoved: false,
    };
  }

  componentDidMount() {
    const options = {
      enableHighAccuracy: true,
      timeout: 100000,
      maximumAge: 0,
    };
    const { getFromGlobalState } = this.props;
    const blocks = getFromGlobalState('blocks');
    const watchId = navigator.geolocation.watchPosition(this.locationFound, this.locationError, options);
    const blockPaths = this.getBlockPaths(blocks);
    this.setState({ watchId, blockPaths });
  }

  componentWillUnmount() {
    const { watchId } = this.state;
    navigator.geolocation.clearWatch(watchId);
  }

  locationError = (err) => {
    const { setSnack } = this.props;
    setSnack({ open: true, variant: 'error', messageText: ` Failed to find location ${err.message}` });
  }

  locationFound = (pos) => {
    const { isMobile, block, getFromGlobalState } = this.props;
    const crd = pos.coords;
    const blocks = getFromGlobalState('blocks');
    const foundBlock = findBlock(blocks, crd.latitude, crd.longitude);
    const blockPaths = this.getBlockPaths(blocks, foundBlock);
    this.setState({ currentLocation: crd, blockPaths, foundBlock });
  }

  onUserMoved = () => {
    const { userMoved } = this.state;
    if (!userMoved) {
       this.setState({ userMoved: true });
    }
  }

  recenter = () => {
    const { userMoved } = this.state;
    if (userMoved) {
      this.setState({ userMoved: false });
    }
  }

  getBlockPaths = (blocks, selectedBlock) => {
    const blockPaths = [];
    for (let b = 0; b < blocks.length; b += 1) {
      const block = blocks[b];
      if (block.paths) {
        let strokeColor = block.color;
        let strokeWeight = 1;
        if (selectedBlock && block.sk === selectedBlock.sk) {
          strokeColor = 'black';
          strokeWeight = 3;
        }
        blockPaths.push({
          label: block.number, sk: block.sk, paths: block.paths, color: block.color, strokeColor, strokeWeight,
        });
      }
    }
    return blockPaths;
  }

  onMapBlockClick(key, event) {
    const { selectedBlock } = this.state;
    const { getFromGlobalState } = this.props;
    const blocks = getFromGlobalState('blocks');
    if (blocks && selectedBlock !== key) {
      const blockPaths = this.getBlockPaths(blocks, key);
      const block = blocks && blocks.find((b) => b.sk === key);
      const infoWindow = { label: block.number, position: { lat: event.latLng.lat(), lng: event.latLng.lng() }, content: this.getBlockInfo(block) };
      this.setState({
        blockPaths, selectedBlock: key, infoWindow, infoWindowPosition: { lat: event.latLng.lat(), lng: event.latLng.lng() },
      });
    }
    // let selectedBlock = this.props.getFromGlobalState('blocks').find(block=>block.sk===key);
  }

  onMapClick() {
    this.onCloseInfoWindow();
  }

  onCloseInfoWindow() {
    const { getFromGlobalState } = this.props;
    const blocks = getFromGlobalState('blocks');
    const blockPaths = this.getBlockPaths(blocks, '');
    this.setState({
      blockPaths, foundBlock: '', infoWindowPosition: null, infoWindow: null,
    });
  }

  render() {
    const {
      online,
      classes,
    } = this.props;

    const {
      currentLocation,
      foundBlock,
      blockPaths,
      infoWindow,
      userMoved,
    } = this.state;

    const markers = currentLocation && window.google && window.google.maps ? [{
      position: {
        lat: currentLocation.latitude,
        lng: currentLocation.longitude,
      },
      icon: {
        path: currentLocation && window.google && window.google.maps ? window.google.maps.SymbolPath.CIRCLE : undefined,
        //scale: 7,
        scale: currentLocation.accuracy > 3 ? currentLocation.accuracy : 3,
        fillColor: 'red',
        fillOpacity: 0.2,
        strokeColor: 'red',
        strokeWeight: 2,
      },
    }] : [];

    const message = currentLocation ? (foundBlock ? `You are in block: ${foundBlock.number}` : 'You do not appear to be in a block') : null;

    return (
      <Grid container className={classes.root} spacing={1} alignItems="flex-start">
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
          <Typography style={{ margin: '5px' }} variant="subtitle1">
            {`${currentLocation && currentLocation ? `Accuracy: ${currentLocation.accuracy.toFixed(2)} m` : 'Locating...'}`}
            <MyLocationIcon style={!currentLocation ? { color: 'red', paddingLeft: '8px' } : (currentLocation && currentLocation.accuracy < 10 ? { color: 'green', paddingLeft: '8px' } : { color: 'orange', paddingLeft: '8px' })} />
          </Typography>

        </Grid>

        {message && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography style={{ margin: '5px' }} variant="subtitle1">
            {message}
          </Typography>
        </Grid>
        )}
        {online && (
        <Map
          center={currentLocation ? { lat: currentLocation.latitude, lng: currentLocation.longitude } : undefined}
            // zoom={16}
          polygons={blockPaths}
          markers={markers}
          style={{ height: '400px', width: '100%' }}
          onPolygonClick={this.onMapBlockClick}
          onCloseInfoWindow={this.onCloseInfoWindow}
          infoWindow={infoWindow}
          onClick={this.onMapClick}
          keepBounds={userMoved}
        //  onUserMoved={this.onUserMoved}
       //   recenter={this.recenter}
        />
        )}
      </Grid>

    );
  }
}
Dashboard.propTypes = {
  getFromGlobalState: PropTypes.func.isRequired,
  online: PropTypes.bool.isRequired,
};
export default withStyles(styles)(Dashboard);
