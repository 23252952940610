import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Load from '../../components/Load';
import Map from '../../components/Map';
import { getCircles } from '../../libs/analytics';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    backgroundColor: 'inherit',
    // overflow: 'scroll',
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  section: {
    margin: theme.spacing(2, 4),
  },

});

class MapDashboard extends Component {
  constructor(props) {
    super(props);
    this.onMapBlockClick = this.onMapBlockClick.bind(this);
    this.onMapCircleClick = this.onMapCircleClick.bind(this);
    this.onCloseInfoWindow = this.onCloseInfoWindow.bind(this);
    this.onMapClick = this.onMapClick.bind(this);

    this.state = {
      blockPaths: [],
      circles: [[]],
      isLoading: false,
      infoWindow: null,
      infoWindowPosition: null,
      selectedBlock: '',
      selectedStage: '',
      keepBounds: false,
    };
  }

  componentDidMount() {
    const { getFromGlobalState, blocks } = this.props;
    this.setState({ isLoading: true });
    //const blocks = getFromGlobalState('blocks');
    const blockPaths = this.getBlockPaths(blocks);
    const monitoringSettings = getFromGlobalState('monitorings');
    const targetsTypesSetting = getFromGlobalState('targetsTypesSetting');
    const circles = getCircles(blocks, monitoringSettings, targetsTypesSetting).circles;
    this.setState({ blockPaths, isLoading: false, circles });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const { getFromGlobalState, activeSite, blocks } = this.props;

    if (activeSite !== prevProps.activeSite ||
      blocks !== prevProps.blocks) {
      //const blocks = getFromGlobalState('blocks');
      const blockPaths = this.getBlockPaths(blocks);
      const monitoringSettings = getFromGlobalState('monitorings');
      const targetsTypesSetting = getFromGlobalState('targetsTypesSetting');
      const clusters = getFromGlobalState('clusters');
      const circles = getCircles(blocks, monitoringSettings, targetsTypesSetting).circles;
      this.setState({
        blockPaths, isLoading: false, selectedBlock: '', infoWindow: null, circles, keepBounds: false,
      });
    }
  }

  onMapClick() {
    this.onCloseInfoWindow();
  }

  onCloseInfoWindow() {
    const { getFromGlobalState, blocks } = this.props;
    //const blocks = getFromGlobalState('blocks');
    const blockPaths = this.getBlockPaths(blocks, '');
    this.setState({
      blockPaths, selectedBlock: '', selectedStage: '', infoWindowPosition: null, infoWindow: null,
    });
  }

  onMapCircleClick(key, event) {
    const { circles, selectedStage } = this.state;
    const { getFromGlobalState } = this.props;
    if (selectedStage !== key.observation.stageSK) {
      const monitoringSettings = getFromGlobalState('monitorings');
      const { targetDetail } = monitoringSettings.find((s) => s.sk === key.observation.monitoringSK);
      let stageDetail;
      if (key.observation.cropSpecific) {
        stageDetail = targetDetail.observableOn[0].stages.find((s) => s.sk === key.observation.stageSK);
      } else {
        stageDetail = targetDetail.nonCropDetail;
      }
      const display = this.getMonitoringInfo(targetDetail, stageDetail, key.observationData);
      const infoWindow = { label: targetDetail.name, position: { lat: event.latLng.lat(), lng: event.latLng.lng() }, content: display };
      this.setState({
        keepBounds: true, selectedStage: key.observation.stageSK, infoWindow, infoWindowPosition: { lat: event.latLng.lat(), lng: event.latLng.lng() },
      });
    }
  }

  getMonitoringInfo(target, stage, observationData) {
    return (
      <>
        <Typography variant="h6">
          {target.name}
        </Typography>
        <Typography variant="subtitle2">{` Stage: ${stage.stageDescription}`}</Typography>
        <Typography style={{ color: observationData.color }} variant="h6">{` Average: ${observationData.value.toFixed(2)}`}</Typography>
        <Typography variant="subtitle2">{` Priority: ${stage.priority}`}</Typography>
        <Typography variant="subtitle2">{` Date: ${observationData.date}`}</Typography>
        {observationData.threshold && <Typography variant="subtitle2">{` Threshold Value: ${observationData.thresholdValue.toFixed(2)}`}</Typography>}
        {observationData.threshold && <Typography variant="subtitle2">{` Threshold: Low ${observationData.threshold.low} Med ${observationData.threshold.med} High ${observationData.threshold.high}`}</Typography>}

        <Typography variant="subtitle2">{` Recommended points: ${stage.quantityOfUnit}`}</Typography>
        <Typography variant="subtitle2">{` Monitored points: ${observationData.actualObservations}`}</Typography>

        {/* <Link href="#" variant="h6" onClick={this.mapEditBlock.bind(this, block.sk)}>
          Edit
        </Link> */}
      </>
    );
  }

  onMapBlockClick(key, event) {
    const { selectedBlock } = this.state;
    const { getFromGlobalState, blocks } = this.props;
    //const blocks = getFromGlobalState('blocks');
    if (blocks && selectedBlock !== key) {
      const blockPaths = this.getBlockPaths(blocks, key);
      const block = blocks && blocks.find((b) => b.sk === key);
      const infoWindow = { label: block.number, position: { lat: event.latLng.lat(), lng: event.latLng.lng() }, content: this.getBlockInfo(block) };
      this.setState({
        blockPaths, selectedBlock: key, infoWindow, infoWindowPosition: { lat: event.latLng.lat(), lng: event.latLng.lng() },
      });
    }
    // let selectedBlock = this.props.getFromGlobalState('blocks').find(block=>block.sk===key);
  }

  getBlockInfo(block) {
    return (
      <>
        <Typography variant="h6">
          {block.number}
        </Typography>
        <Typography variant="subtitle2">{` Size: ${block.size} ha`}</Typography>
        <Typography variant="subtitle2">{` Soil Type: ${block.soilType}`}</Typography>
        <Typography variant="subtitle2">{` Slope: ${block.slope}`}</Typography>
        <Typography variant="subtitle2">{` Irrigation: ${block.irrigated === 'irrigated' ? 'Irrigated' : 'Dry Land'}`}</Typography>
        {/* <Link href="#" variant="h6" onClick={this.mapEditBlock.bind(this, block.sk)}>
          Edit
        </Link> */}
      </>
    );
  }


  getBlockPaths = (blocks, selectedBlock) => {
    const blockPaths = [];
    for (let b = 0; blocks && b < blocks.length; b += 1) {
      const block = blocks[b];
      if (block.paths) {
        let strokeColor = block.color;
        let strokeWeight = 1;
        if (selectedBlock && block.sk === selectedBlock) {
          strokeColor = 'black';
          strokeWeight = 3;
        }
        blockPaths.push({
          label: block.number, sk: block.sk, paths: block.paths, color: block.color, strokeColor, strokeWeight,
        });
      }
    }
    return blockPaths;
  }

  render() {
    const { classes } = this.props;
    const {
      blockPaths,
      circles,
      isLoading,
      infoWindow,
      selectedBlock,
      infoWindowPosition,
      keepBounds,
    } = this.state;

    return (

      <Load isloading={isLoading} style={{ width: '100%', height: '500px' }}>
        {blockPaths && blockPaths.length && (
        <Map
         // center={center}
            // zoom={16}
          polygons={blockPaths}
          onPolygonClick={this.onMapBlockClick}
          onCircleClick={this.onMapCircleClick}
          onCloseInfoWindow={this.onCloseInfoWindow}
          infoWindow={infoWindow}
          onClick={this.onMapClick}
          circles={circles}
          keepBounds={keepBounds}
        />
        )}
      </Load>

    );
  }
}
MapDashboard.propTypes = {
  getFromGlobalState: PropTypes.func.isRequired,
  activeSite: PropTypes.string.isRequired,
  blocks: PropTypes.array.isRequired,

};
export default withStyles(styles)(MapDashboard);
