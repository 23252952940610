import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { generateColors } from '../../libs/genLib';

const PARENT = '#3f51b5';

export default class ChartBarStacked extends PureComponent {
  render() {
    const {
      title, data, keys, dataKey, height, name,
    } = this.props;
    const colors = keys && keys.length && generateColors(PARENT, keys.length);
    return (
      <Paper style={{ borderRadius: '4px', overflow: 'hidden' }}>
        {title && (
        <>
          <div style={{
            width: '100%', justifyContent: 'center', display: 'inline-flex', paddingTop: '5px',
          }}
          >
            <Typography variant="button" gutterBottom>{title}</Typography>

          </div>
        </>
        )}

        <ResponsiveContainer key={name} width="100%" height={height} style={{ cursor: 'pointer' }}>
          <BarChart
            width="100%"
            height={height}
            data={data}
            key={`${name}-${title}`}
            margin={{
              top: 20,
              right: 20,
              left: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={dataKey} tick={{ fontSize: '12px' }} />
            <YAxis />
            <Tooltip />
            <Legend wrapperStyle={{ fontSize: '12px' }} />
            { keys && keys.length && keys.map((k, i) => <Bar stackId="a" key={`${name}-${i}`} dataKey={k} fill={colors[i]} />)}

          </BarChart>
        </ResponsiveContainer>

      </Paper>

    );
  }
}
ChartBarStacked.defaultProps = {
  height: 400,
};
ChartBarStacked.propTypes = {
  data: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  height: PropTypes.string,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
