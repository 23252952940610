import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import { amber, green } from '@material-ui/core/colors';

const styles = (theme) => ({
  greenAvatar: {
    margin: 10,
    color: green[500],
    backgroundColor: green[500],
  },
  redAvatar: {
    margin: 10,
    color: theme.palette.error.dark,
    backgroundColor: theme.palette.error.dark,
  },
  amberAvatar: {
    margin: 10,
    color: amber[500],
    backgroundColor: amber[500],
  },
});

function getSize(size) {
  if (size === 'small') {
    return {
      width: '7px', height: '7px', marginLeft: '0px', marginRight: '0px',
    };
  }
  if (size === 'medium') {
    return {
      width: '15px', height: '15px', marginLeft: '5px', marginRight: '5px',
    };
  }
  if (size === 'large') {
    return { width: '30', height: '30' };
  }

  return { width: '30', height: '30' };
}
function getMessages(status) {
  if (!status.messages) {
    return '';
  }
  const result = status.messages.reduce((str, msg) =>
    // return the sum with previous value
    str.concat(msg).concat(', '),
  '');

  return result;
}
function getComponent(status, hideIfGreen, size, msg, classes, clsName) {
  if (hideIfGreen && status.status === 'green') {
    return (null);
  }

  return (<Tooltip placement="top" title={msg}><Avatar className={classes[clsName]} style={getSize(size)} /></Tooltip>);
}

function Status(props) {
  const {
    classes, status, hideIfGreen, size,
  } = props;
  if (!status) { return null; }
  const clsName = `${status.status}Avatar`;
  const msg = getMessages(status);

  return (
    <>
      {getComponent(status, hideIfGreen, size, msg, classes, clsName) }
    </>
  );
}
Status.propTypes = {
  status: PropTypes.object.isRequired,
  hideIfGreen: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
};
export default withStyles(styles, { withTheme: true })(Status);
