import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import GeoIcon from '@material-ui/icons/GpsFixedOutlined';
import GenDialog from '../../components/GenDialog';
import { validate, validateForm } from '../../libs/validate';
import LoaderFab from '../../components/LoaderFab';

const styles = (theme) => ({
    paper: {
      marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      display: 'flex',
    flexWrap: 'wrap',
    },
    formControl: {
      minWidth: 120,
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    helperText :{
      color: 'red',
    },
    
  });

  
const requiredFields = [
    {name:'name', min: 1, max: 40},
  ];


class StationD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      station: {},
      errors: {count:0},
      changed:false,
      locationLoading:false,
      locationLoaded: false,
      
    
    };
  }
  
  componentDidMount() {
    if (this.props.isNew){
      this.setState({station: {}});
    }
    else {
      this.setState({station: this.props.station});
    }
  }


  validateField (field, requiredFields)
  {
      let error = validate(field, this.state.station, requiredFields)
      let errors = {...this.state.errors};
      errors[field] = error.msg;
      this.setState({errors: errors }); 
  }

  handleBlur = (event) => {
    return this.validateField (event.target.id, requiredFields);
  }

  handleChange = (event) => {
    let station = {...this.state.station};
    station[event.target.id] = event.target.value; 
    this.setState({ station:station,changed:true });
  }

  getCurrentLocation = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 100000,
      maximumAge: 0,
    };
    this.setState({locationLoading:true, locationLoaded:false});
    navigator.geolocation.getCurrentPosition((position) => {
      const location = {lat:parseFloat(position.coords.latitude.toFixed(7)), lng: parseFloat(position.coords.longitude.toFixed(7))};
      let station = {...this.state.station};
      station.geoLocation = location ; 
      this.setState({ station : station, changed:true, locationLoading:false, locationLoaded:true });
    },
    (error) => {
      if (error.code === error.PERMISSION_DENIED)
        this.setState({locationLoading:false, locationLoaded:false});
    },
    options);
  }
  
  render() {
    const { classes, open, isNew, setClose, handleUpdate, parentSK,parent, getFromGlobalState, type } = this.props;
    const {station, errors} = this.state;
   const formValid = validateForm(station, requiredFields);
    
    return (
 
      <GenDialog
      open={open}
      dialogObject={station}
      setClose={setClose}
      isNew={isNew}
      handleUpdateDialogObject={handleUpdate.bind(this)}
      skPrimary="_setchild_"
      dynamoType={type}
      valid={formValid}
      changed={this.state.changed}
      newTitle='New Station'
      title={station.name ? station.name : ''}
      subTitle={parent ? parent.name : '' }
      parentKey={parentSK}
      getFromGlobalState={getFromGlobalState}
      >
         <Grid container className={classes.root} spacing={1} alignItems="flex-end" >
         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Station Name"
            name="name"
            
            value={station.name}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.variety ? true : undefined}
            helperText={errors.name}
          />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <TextField
          margin="normal"
          //required
          fullWidth
          id="notes"
          label="Notes"
          name="notes"
          multiline
          rowsMax="4"
          value={station.notes}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.notes ? true : undefined}
          helperText={errors.notes}
        />
        </Grid>
          <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
         
        <FormControl fullWidth className={classes.formControl}>
      <InputLabel shrink htmlFor="geoLocation">Geolocation</InputLabel>
          <TextField
            margin="normal"
            //required
            style={{minWidth:'200px'}}
            id="geoLocation"
            name="geoLocation"
            value={station.geoLocation ? `lat:${station.geoLocation.lat} lng:${station.geoLocation.lng}` : ''}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.geoLocation? true : undefined}
            helperText={errors.geoLocation}
          />
          </FormControl>
         
         </Grid> 

<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
          <LoaderFab
                onClick={this.getCurrentLocation}
                success //={this.state.locationLoading && this.state.locationLoaded}
                icon={<GeoIcon />}
                loading={this.state.locationLoading}
                // disabled={!changed || (isSubmitting || !valid)}
              />
        
         
        </Grid>
         
        </Grid>

      </GenDialog>
     
    );
  }
}
StationD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  station: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  parentSK: PropTypes.string.isRequired,
  settings: PropTypes.object,
  parent: PropTypes.object.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(StationD);
