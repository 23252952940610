import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import OperatorSelector from '../../components/OperatorSelector';
import TargetsList from '../../components/TargetsList';

// import { calculateApplicationTotal } from '../../libs/analytics';

import { inputDateAndTimeToLocaleDate, getNow, getToday } from '../../libs/genLib';

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    // alignItems: 'center',
  },

  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '12px',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',

  },
});
// todo make more efficient
const filterItems = (arr, planting) => {
  const plantingCrop = planting[0].crop;
  const allowedChemicals = [];
  arr.forEach((chemical) => {
    const idx = chemical.chemcrops.findIndex((c) => c.crop === plantingCrop);
    if (idx !== -1) {
      allowedChemicals.push(chemical);
    }
  });
  return allowedChemicals;
};

/* const calculateRequiredChemicalAmount = (chemicalSK, chemicals, planting, blockSize, explain) => {
  if (!chemicalSK) { return undefined; }
  const chemical = chemicals.find((c) => c.sk === chemicalSK);
  const chemcrop = findChemCrop(chemical, planting);
  const chemAmount = { amount: 0, unit: '' };
  if (!chemcrop) { return undefined; }

  if (chemcrop.totalUnit === 'Hectare') {
    chemAmount.amount = parseFloat(blockSize) * parseFloat(chemcrop.valperUnit);
    // chemAmount.unit = chemical.unit;
    chemAmount.unit = chemical.unit;
    chemAmount.totalUnit = 'Hectare';
    chemAmount.totalVolume = (Math.round((chemcrop.valperUnit / chemcrop.valper100l * blockSize * 100 * 100), 2) / 100);

    chemAmount.totalProductMessage = `Total chemical: ${chemAmount.amount.toLocaleString()} ${chemical.unit}`;
    chemAmount.totalVolumeMessage = `Total volume: ${(Math.round((chemcrop.valperUnit / chemcrop.valper100l * blockSize * 100 * 100), 2) / 100).toLocaleString()} Liter`;
    if (explain) {
      chemAmount.message = `Total undiluted chemical for the ${blockSize} ${chemcrop.totalUnit.toLocaleString()} planting: ${chemcrop.valperUnit.toLocaleString()} ${chemical.unit} per ${chemcrop.totalUnit} X ${blockSize} ${chemcrop.totalUnit.toLocaleString()} =  ${chemAmount.amount.toLocaleString()} ${chemical.unit}. At ${chemcrop.valper100l.toLocaleString()} ${chemAmount.unit} per 100L and a reccomendation of ${chemcrop.valperUnit.toLocaleString()} ${chemical.unit} per ${chemcrop.totalUnit}. The total diluted volume to apply is estimated at: 
    ${chemcrop.valperUnit.toLocaleString()} / ${chemcrop.valper100l.toLocaleString()} x ${blockSize} ${chemcrop.totalUnit} x 100L = ${(Math.round((chemcrop.valperUnit / chemcrop.valper100l * blockSize * 100 * 100), 2) / 100).toLocaleString()} Liter`;
    }
  } else if (chemcrop.totalUnit === 'Plant') {
    chemAmount.amount = parseFloat(planting[0].totalPlants) * parseFloat(chemcrop.valperUnit);
    chemAmount.unit = chemical.unit;
    chemAmount.totalUnit = 'Plant';
    chemAmount.totalVolume = (Math.round((chemcrop.valperUnit / chemcrop.valper100l * planting[0].totalPlants * 100 * 100), 2) / 100);
    chemAmount.totalProductMessage = `Total chemical: ${chemAmount.amount.toLocaleString()} ${chemAmount.unit}`;
    chemAmount.totalVolumeMessage = `Total volume: ${chemAmount.totalVolume} Liter or ${(Math.round((chemcrop.valperUnit / chemcrop.valper100l * 100 * 100), 2) / 100).toLocaleString()} per Plant`;
    if (explain) {
      chemAmount.message = `Total undiluted chemical for the ${planting[0].totalPlants.toLocaleString()} ${chemcrop.totalUnit}s: ${chemcrop.valperUnit.toLocaleString()} ${chemical.unit} per ${chemcrop.totalUnit.toLocaleString()} X ${planting[0].totalPlants.toLocaleString()} ${chemcrop.totalUnit}s =  ${chemAmount.amount.toLocaleString()} ${chemAmount.unit}. At ${chemcrop.valper100l.toLocaleString()} ${chemAmount.unit} per 100L and a reccomendation of ${chemcrop.valperUnit.toLocaleString()} ${chemical.unit} per ${chemcrop.totalUnit}. The total diluted volume to apply is estimated at: 
    ${chemcrop.valperUnit.toLocaleString()} / ${chemcrop.valper100l.toLocaleString()} x ${planting[0].totalPlants.toLocaleString()} ${chemcrop.totalUnit}s x 100L = ${(Math.round((chemcrop.valperUnit / chemcrop.valper100l * planting[0].totalPlants * 100 * 100), 2) / 100).toLocaleString()} Liter or ${(Math.round((chemcrop.valperUnit / chemcrop.valper100l * 100 * 100), 2) / 100).toLocaleString()} Liter per ${chemcrop.totalUnit}`;
    }
  } else {
    return undefined;
  }
  return chemAmount;
}; */

/* const findChemCrop = (chem, planting) => {
  if (chem === undefined) { return undefined; }
  if (planting === undefined) { return undefined; }
  if (chem.chemcrops === undefined) { return undefined; }

  const chemCrop = chem.chemcrops.find((cc) => cc.crop === planting[0].crop);
  return chemCrop;
}; */

const calculatePHIEnd = (chemicalSK, chemicals, planting, applicationDate, applicationTime, reasons, phiReasons,isCombination) => {
  

  if (!applicationDate || !applicationTime) {
    return '';
  }

  if(isCombination){
    return inputDateAndTimeToLocaleDate(applicationDate, applicationTime, phiReasons||0);
  }
  const chem = chemicals.find((c) => c.sk === chemicalSK);
  console.log("chem",chem);
  const target = reasons && reasons.find((r) => r.applied && r.applied === true);
  if (target) {
    if (chem.chemcrops) {
      const chemcrop = chem.chemcrops.find((cc) => cc.crop === planting[0].crop);
      if (chemcrop) {
        const chemForTarget = chemcrop.targets.find((c) => c.sk === target.sk);
        if (chemForTarget) {
          const phi = phiReasons ? phiReasons : chemForTarget.phi;
          return inputDateAndTimeToLocaleDate(applicationDate, applicationTime, phi);
        }
      }
    }
  }
  return '';
};



const generateReasonList = (allowedChemicals, product, getFromGlobalState) => {
  if (!product) {
    return [];
  }

  const monitorings = getFromGlobalState('monitorings');
  const chemical = allowedChemicals.find((f) => f.sk === product);
  
  let targets = []
  if(chemical?.type === "combinations"){
    
    targets =  chemical.combinationProducts.flatMap(x => x?.selectedChemicalCrop?.targets);
  }else{
    targets =  chemical?.chemcrops[0]?.targets
  }
  console.log("targets",targets);

  const monitoringFiltered = monitorings.filter((el) => (targets||[])?.some((f) => f?.target === el.sk)) || [];
  return monitoringFiltered;
};

function SprayingForm(props) {
  const {
    classes, record, errors, handleExpand, handleReentryOverride, handleTargetChange, handleApplied, expandedTarget, getFromGlobalState, handleBlur, handleChange, metaData, planting, handleToggle,
  } = props;

  const combinations = getFromGlobalState('combinations').filter((x)=>x.combinationType === "Plant Protection");
    
  const allowedChemicals = filterItems(metaData.chemicals, planting).concat(combinations);

  let reasons = [];
  if (record.product) {
    console.log("record",record);
    reasons = generateReasonList(allowedChemicals, record.product, getFromGlobalState);
  }

  record.date = record.date ? record.date : getToday();
  record.time = record.time ? record.time : getNow();
  record.endDate = record.endDate ? record.endDate : getToday();

  if (record.chemrigs && record.numRigs) {
    const rig = metaData.chemequips.find((p) => p.sk === record.chemrigs);
    record.volume = (rig.capacity * record.numRigs).toFixed(2);
  }

  const combination = combinations.find((c) => c.sk === record.product) || null;
  const isCombination = combination ? true : false;
  if(isCombination){
    record.combinations = record.combinations || []
  }

  if (record.endDate) {
    record.endTime = record.endTime ? record.endTime : getNow();
    const phiFromReasons = (record?.reasons||[]).length > 0 ? record?.reasons[0].phi : null;
    record.phiLapse = (record.product && record.endDate && record.endTime && calculatePHIEnd(record.product, allowedChemicals, planting, record.date, record.endTime, record.reasons, phiFromReasons,isCombination));
  }

  

  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-end">
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <TextField
          margin="normal"
          required
          fullWidth
          type="date"
          id="date"
          label="Date"
          name="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={record.date}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur}
          error={errors.date ? true : undefined}
          helperText={errors.date}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <TextField
          margin="normal"
          fullWidth
          type="time"
          id="endTime"
          label="End Time"
          name="endTime"
          InputLabelProps={{
            shrink: true,
          }}
          value={record.endTime}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur}
          error={errors.endTime ? true : undefined}
          helperText={errors.endTime}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="product">Plant Protection</InputLabel>
          <Select
            native
            fullWidth
            required
            displayEmpty
            value={record.product}
            onChange={handleChange.bind(this)}
            onBlur={handleBlur.bind(this)}
            error={errors.product ? true : undefined}
            inputProps={{
              name: 'product',
              id: 'product',
            }}
          >
            <option value="" />
            {allowedChemicals && allowedChemicals.map((chemical) => (
              <>
                <option value={chemical.sk}>{chemical.name}</option>
              </>
            ))}
          </Select>
          {errors.product && <FormHelperText className={classes.helperText}>{errors.product}</FormHelperText>}
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <InputLabel shrink htmlFor="reasons">Reasons</InputLabel>
        <TargetsList
          metaData={{ monitorings: reasons }}
          onBlur={handleBlur.bind(this)}
          checkedItems={record.reasons}
          handleToggle={handleToggle}
          getFromGlobalState={getFromGlobalState}
          handleExpand={handleExpand}
          expandedTarget={expandedTarget}
          planting={planting}
          pickApplied
          handleApplied={handleApplied}
          handleChange={handleTargetChange}
          handleReentryOverride={handleReentryOverride}
          arrayName="reasons"
        />
        <FormHelperText className={classes.helperText}>{errors.reasons}</FormHelperText>
        {errors.reasons && <FormHelperText className={classes.helperText}>{errors.reasons}</FormHelperText>}
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="weather">Weather</InputLabel>
          <Select
            native
            fullWidth
            required
            displayEmpty
            value={record.weather}
            onChange={handleChange.bind(this)}
            onBlur={handleBlur.bind(this)}
            error={errors.weather ? true : undefined}
            inputProps={{
              name: 'weather',
              id: 'weather',
            }}
          >
            <option value="" />
            <option value="Sunny">Sunny</option>
            <option value="Cloudy">Cloudy</option>
            <option value="Night">Night</option>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink={record.phiLapse ? true : undefined} htmlFor="phiLapse">Withholding period end </InputLabel>
          <TextField
            margin="normal"
        // required
            fullWidth
            id="phiLapse"
        // label="Pre-harvest interval (PHI) end"
            name="phiLapse"
           
            value={record.phiLapse}
            onChange={handleChange.bind(this)}
            onBlur={handleBlur.bind(this)}
            error={errors.phiLapse ? true : undefined}
            helperText={errors.phiLapse}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <OperatorSelector
          onChange={handleChange.bind(this)}
          operators={metaData.operators}
          team={record.team}
          title="Operator"
          value={record.operator}
          onBlur={handleBlur}
          error={errors.operator}
        />
      </Grid>
      <TextField
        margin="normal"
    // required
        fullWidth
        id="notes"
        label="Notes"
        name="notes"
        multiline
        rowsMax="4"
        value={record.notes}
        onChange={handleChange.bind(this)}
        onBlur={handleBlur.bind(this)}
        error={errors.notes ? true : undefined}
        helperText={errors.notes}
      />
      { isCombination ? combination?.combinationProducts?.map((x,index)=>(
          <Grid item style={{marginTop:20}} container className={classes.root} spacing={1} alignItems="flex-end"  key={index}>
            {x.type === "chemical" ? <Grid style={{border:'1px solid #eee', padding:5}}  container>
                 <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                 <label style={{marginTop:20}}>{x?.selectedChemical?.name} (Chemical)</label>
                 <hr></hr>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="chemrigs">Equipment</InputLabel>
                              <Select
                                native
                                fullWidth
                                displayEmpty
                                // value={record}
                                onChange={(z)=>{
                                  const currentRecord = {...record}
                                  currentRecord.product = x?.selectedChemical?.sk;
                                  currentRecord.type = 'sprayings';
                                  currentRecord.chemrigs = z.target.value
                                  const combinations = record.combinations || []
                                  combinations.splice(index,1,currentRecord);
                                  record.combinations = combinations
                                }}
                                onBlur={handleBlur.bind(this)}
                                error={errors.chemrigs ? true : undefined}
                                inputProps={{
                                  name: 'chemrigs',
                                  id: 'chemrigs',
                                }}
                              >
                                <option value="" />
                                {metaData.chemequips && metaData.chemequips.map((rig) => (
                                  <>
                                    <option value={rig.sk}>{rig.name}</option>
                                  </>
                                ))}
                              </Select>
                          {errors.chemrigs && <FormHelperText className={classes.helperText}>{errors.chemrigs}</FormHelperText>}
                        </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      margin="normal"
                      type="number"
                      inputProps={{ min: '0.00001', step: 'any' }}
                      id="numRigs"
                      shrink
                      label="Total number applied"
                      name="numRigs"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(z)=>{
                        const currentRecord = record.combinations[index]
                        currentRecord.numRigs = z.target.value
                        const rig = metaData.chemequips.find((p) => p.sk === currentRecord.chemrigs);
                        currentRecord.volume = parseFloat(rig?.capacity) * z.target.value;
                        const combinations = record.combinations || [];
                        combinations.splice(index,1,currentRecord);
                        record.combinations = combinations
                      }}
                      onBlur={handleBlur.bind(this)}
                      error={errors.volume ? true : undefined}
                      helperText={errors.volume}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      margin="normal"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                      id="volume"
                      label="Total volume"
                      name="volume"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">liters</InputAdornment>,
                        min: '0.00001',
                        step: 'any',
                      }}
                      value={record.combinations[index]?.volume}
                      onBlur={handleBlur.bind(this)}
                      error={errors.volume ? true : undefined}
                      helperText={errors.volume}
                    />
                  </Grid>
            </Grid> : <Grid container className={classes.root} spacing={1} alignItems="flex-end" style={{border:'1px solid #eee', padding:5}}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <label style={{marginTop:20}}>{x?.selectedFertiliser?.name} (fertiliser)</label>
                        <hr></hr>
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink htmlFor="product">Application per</InputLabel>
                          <Select
                            native
                            fullWidth
                            displayEmpty
                            onChange={(z)=>{
                              const currentRecord =  record.combinations[index] || {...record}
                              delete currentRecord.combinations
                              currentRecord.product = x?.selectedFertiliser?.sk;
                              currentRecord.type = 'fertilisings';
                              currentRecord.unit = z.target.value
                              if (z.target.value) {
                                if (z.target.value === 'plant') {
                                // record.total = record.volume * planting[0].totalPlants;
                                  currentRecord.totalUnit = x?.selectedFertiliser.unit === 'kg' ? 'g' : 'ml';
                                } else {
                                  currentRecord.totalUnit = x?.selectedFertiliser.unit === 'kg' ? 'kg' : 'l';
                                }
                              }
                              const combinations = record.combinations || []
                              combinations.splice(index,1,currentRecord);
                              record.combinations = combinations
                            }}
                            onBlur={handleBlur.bind(this)}
                            error={errors.unit ? true : undefined}
                            inputProps={{
                              name: 'unit',
                              id: 'unit',
                            }}
                          >
                            <option value=''></option>
                            <option value="planting">Planting</option>
                            <option value="plant">Plant</option>
                            <option value="ha">ha</option>
                          </Select>
                          {errors.unit && <FormHelperText className={classes.helperText}>{errors.unit}</FormHelperText>}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          margin="normal"
                          required
                          type="number"
                        // inputProps={{ min: '0.00001', step: 'any' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        // fullWidth
                          id="volume"
                          label="Applied quantity"
                          name="volume"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{record.totalUnit ? (record.totalUnit === 'l' ? 'liters' : record.totalUnit)  : ''}</InputAdornment>,
                            min: '0.00001',
                            step: 'any',
                          }}
                          onChange={(z)=>{
                            const currentRecord =  record.combinations[index] || {}
                            currentRecord.volume = z.target.value
                            if (z.target.value) {
                              if (currentRecord.unit === 'plant') {
                                currentRecord.total = parseFloat( z.target.value) * planting[0].totalPlants;
                              } else if (currentRecord.unit === 'ha') {
                                currentRecord.total = parseFloat(z.target.value) * planting[0].size;
                              } else {
                                currentRecord.total = parseFloat(z.target.value);
                              }
                            }
                            const combinations = record.combinations || []
                            combinations.splice(index,1,currentRecord);
                            record.combinations = combinations
                          }}
                          onBlur={handleBlur.bind(this)}
                          error={errors.volume ? true : undefined}
                          helperText={errors.volume}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          margin="normal"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                            min: '0.00001',
                            step: 'any',
                          }}
                      // fullWidth
                          id="total"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{record.totalUnit ? (record.totalUnit === 'l' ? 'liters' : record.totalUnit)  : ''}</InputAdornment>,
                            min: '0.00001',
                            step: 'any',
                          }}
                          label="Total"
                          name="total"
                          value={record.combinations[index]?.total}
                          onChange={handleChange.bind(this)}
                          onBlur={handleBlur.bind(this)}
                          error={errors.total ? true : undefined}
                          helperText={errors.total}
                        />
                      </Grid>
              </Grid>}
          </Grid>
        )) : 
        <>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="chemrigs">Equipment</InputLabel>
            <Select
              native
              fullWidth
              required
              displayEmpty
              value={record.chemrigs}
              onChange={handleChange.bind(this)}
              onBlur={handleBlur.bind(this)}
              error={errors.chemrigs ? true : undefined}
              inputProps={{
                name: 'chemrigs',
                id: 'chemrigs',
              }}
            >
              <option value="" />
              {metaData.chemequips && metaData.chemequips.map((rig) => (
                <>
                  <option value={rig.sk}>{rig.name}</option>
                </>
              ))}
            </Select>
            {errors.chemrigs && <FormHelperText className={classes.helperText}>{errors.chemrigs}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            margin="normal"
            required
            type="number"
            disabled={!record.chemrigs}
            inputProps={{ min: '0.00001', step: 'any' }}
            id="numRigs"
            shrink
            label="Total number applied"
            name="numRigs"
            InputLabelProps={{
              shrink: true,
            }}
            value={record.numRigs}
            onChange={handleChange.bind(this)}
            onBlur={handleBlur.bind(this)}
            error={errors.volume ? true : undefined}
            helperText={errors.volume}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            margin="normal"
            required
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            disabled
          // inputProps={{ min: '0.00001', step: 'any' }}
            //fullWidth
            id="volume"
            label="Total volume"
            name="volume"
            InputProps={{
              endAdornment: <InputAdornment position="end">liters</InputAdornment>,
              min: '0.00001',
              step: 'any',
            }}
            value={record.volume}
            onChange={handleChange.bind(this)}
            onBlur={handleBlur.bind(this)}
            error={errors.volume ? true : undefined}
            helperText={errors.volume}
          />
        </Grid>
        </>
      }

    </Grid>
  );
}

SprayingForm.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
  planting: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleSwitchChange: PropTypes.func.isRequired,
  overrideCalculatedProduct: PropTypes.func.isRequired,
  overrideCalculatedVolume: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  handleExpand: PropTypes.func.isRequired,
  expandedTarget: PropTypes.string.isRequired,
  handleApplied: PropTypes.func.isRequired,
  handleTargetChange: PropTypes.func.isRequired,
  handleReentryOverride: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SprayingForm);
