import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { amber, green, red } from '@material-ui/core/colors';
import CardActions from '@material-ui/core/CardActions';
import InventoryItemD from '../InventoryItemD';
import SimpleCard from '../../components/SimpleCard';
import EnhancedTable from '../../components/Etable';
import Status from '../../components/Status';
import { calculateApplicationTotal, getDataByRecordType } from '../../libs/analytics';
import InventoryRequestD from '../InventoryRequestD';
import ShowAllD from '../ShowAllD';
import settings from '../../libs/settings';
import ReportInventoryPDF from '../ReportInventoryPDF';
import { toStore, createObject } from '../../libs/storage';
import ReportInventoryCSV from '../ReportInventoryCVS';


const { RECENTS_LEN } = settings.constants;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '5px',
    backgroundColor: 'inherit',
  },
  aligner: {
    // display:'inline-flex',
    // marginRight:'auto',
    // marginBottom:'15px',
  },
  paperDropperBody: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  heading: {
    marginTop: '4px',
    cursor: 'pointer',
    // marginTop: '10px',
  },
  submit: {
    margin: theme.spacing(1),
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    // overflow: 'scroll',
  },
  mainSection: {
    // display: 'flex',
    width: '100%',
    // display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'inherit',
    // flexDirection: 'column',
  },
  parentSection: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },

  },
  buttonRight: {
    margin: theme.spacing(1),
    float: 'right',
    marginLeft: 'auto',
  },
  buttonLeft: {
    margin: theme.spacing(1),
    float: 'left',
    marginRight: 'auto',
  },
  childSection: {
    marginLeft: theme.spacing(5),
  },
  childSectionChildren: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },

  statusBar: {
    display: 'flex',
    flexDirection: 'row',
  },
  colorSecondary: {
    backgroundColor: '#e0e0e0',

  },
  red: {
    backgroundColor: red[500],

  },
  amber: {
    backgroundColor: amber[500],
  },
  green: {
    backgroundColor: green[500],
  },
  textred: {
    color: red[500],

  },
  textamber: {
    color: amber[500],
  },
  textgreen: {
    color: green[500],
  },
  badgeChild: {
    padding: theme.spacing(0, 0.5),
    margin: '1px',
    // paddingLeft: '0px',
  },
  badge: {

    // margin: theme.spacing(2),
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class InventoryItemDetailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedProduct: null,
      selectedProduct: null,
      inventoryItems: null, // this.props.initialState,
      inventoryRequests: [],
      selectedInventoryItem: null,
      selectedChemicalCrop: null,
      expandedContent: null,
      modalObject: null,
      isNew: false,
      inventoryItemDialogOpen: false,
      chemicalCropDialogOpen: false,
      inventoryRequestDialogOpen: false,
      errors: {},
      openModal: '',
      showAllDialogOpen: false,
      showReportDialogOpen: false,
      clean: true,
      pdf: false,
      startDate: undefined,
      endDate: undefined,
      csv:false,
      applications:[]
    };
  }

  componentDidMount() {
    const { initialState } = this.props;
   
    //  const requests = getBlockCollectionByNameFlat(metaData.blocks, metaData.crops,
    // metaData.products, activityName, 'Request', inventoryName, true, getFromGlobalState);
    //  this.setState({ inventoryRequests: requests, inventoryItems: initialState });
    this.setState({ inventoryItems: initialState });
  }

  handleClickShow = () => {
    this.setState({ inventoryItemDialogOpen: true });
  }

   generatePDF = (startDate, endDate) => {
     this.setState({ pdf: true, startDate, endDate });
   }

   generateCSV = (startDate, endDate) => {
    this.setState({ csv: true, startDate, endDate });
  }

   onDownloaded = () => {
     this.setState({ pdf: false, csv: true, });
   }

   handleClickAdd(product) {
     this.setState({
       selectedAction: 'Entry', inventoryItemDialogOpen: true, selectedProduct: product, isNew: true, selectedInventoryItem: {},
     });
   }

   handleClickShowInventoryItem(event, product, selectedInventoryItemID, actionType) {
     if (actionType === 'Entry') {
       const selectedInventoryItem = this.props.getFromGlobalState(this.props.inventoryName).find((c) => c.sk === selectedInventoryItemID);
       this.setState({
         selectedAction: actionType, selectedProduct: product, selectedInventoryItem, selectedinventoryItemSK: selectedInventoryItemID, inventoryItemDialogOpen: 'true', isNew: false,
       });
     } else if (actionType === 'Request') {
       const selectedRequest = this.state.inventoryRequests.find((c) => c.activitySK === selectedInventoryItemID);
       this.setState({
         selectedAction: actionType, selectedInventory: selectedRequest.inventoryName, selectedProduct: product, selectedInventoryItem: selectedRequest, selectedInventoryRequestSK: selectedInventoryItemID, inventoryRequestDialogOpen: 'true', isNew: true,
       });
     }
   }


   getSelectedItem = (inventoryName) => {
     const { selectedInventoryItem } = this.state;
     const returnItem = {};
     const today = new Date();

     const selectedItem = selectedInventoryItem;

     returnItem.block = selectedItem.blockSK;
     returnItem.planting = selectedItem.plantingSK;
     returnItem.product = selectedItem.product;
     returnItem.inout = 'out';
     returnItem.totalAmount = selectedItem.amount;
     returnItem.unit = selectedItem.unit;
     returnItem.entryType = 'application';
     returnItem.reason = `Block:${selectedItem.blockNumber} ${selectedItem.cropName} ${selectedItem.varietyName}`;
     returnItem.activity = selectedItem.activitySK;
     returnItem.recordType = selectedItem.recordType;
     returnItem.date = today.toISOString().substr(0, 10);
     let applicationTotal = 0; // block, planting, recordType, applicationType, sk
     const retVals = calculateApplicationTotal(selectedItem.blockSK, selectedItem.plantingSK, selectedItem.recordType, inventoryName, selectedItem.activitySK, this.props.getFromGlobalState);
     if (retVals) {
       applicationTotal = retVals.value;
     }
     returnItem.applicationTotal = applicationTotal;
     return returnItem;
   }

   setClose() {
     if (this.state.selectedAction === 'Request') {
       this.setState({ inventoryRequestDialogOpen: false });
     } else {
       this.setState({ inventoryItemDialogOpen: false });
     }
   }

   setCloseShowAll() {
     this.setState({ showAllDialogOpen: false });
   }

   setCloseReport() {
     this.setState({ showReportDialogOpen: false, clean: true });
   }

   handleUpdate(inventoryItemIn) {
     const { getFromGlobalState, inventoryName, setGlobalState } = this.props;
     const updatedIndex = getFromGlobalState(inventoryName).findIndex((i) => i.sk === inventoryItemIn.sk);
     if (updatedIndex === -1) {
       const newInventoryItems = getFromGlobalState(inventoryName);
       newInventoryItems.push(inventoryItemIn);
       setGlobalState({ [inventoryName]: newInventoryItems });
     } else {
       const newInventoryItems = getFromGlobalState(inventoryName);
       newInventoryItems[updatedIndex] = inventoryItemIn;
       setGlobalState({ [inventoryName]: newInventoryItems });
     }
    // this.setState({ pdf: false });
   }

   handleDelete(inventoryItemIn) {
     const { getFromGlobalState, inventoryName, setGlobalState } = this.props;
     const updatedIndex = getFromGlobalState(inventoryName).findIndex((i) => i.sk === inventoryItemIn.sk);
     if (updatedIndex === -1) {
       alert('impossible deleting an item we dont have');
     } else {
       const newInventoryItems = getFromGlobalState(inventoryName);
       newInventoryItems.splice(updatedIndex, 1);
       // newInventoryItems[updatedIndex] = inventoryItemIn;
       setGlobalState({ [inventoryName]: newInventoryItems });
     }
     this.setState({ pdf: false });
   }

   handleExpand(product) {
     const { expandedProduct } = this.state;
     if (expandedProduct && product.sk === expandedProduct.sk) {
       this.setState({ expandedProduct: null, pdf: false });
       // this.setState({expandedContent:null});
     } else {
       this.setState({ expandedProduct: product, pdf: false });
     }
   }

   async handlePreExecute(inventoryItemIn) {
     // TODO: Add a rollback if the creation of the inventory entry fails.
     const {
       getFromGlobalState,
       setGlobalState,
       setSnack,
     } = this.props;

     const {
       block, planting, activity, recordType, type, sk,
     } = inventoryItemIn;

     let newRecord = {};
     const blocks = getFromGlobalState('blocks');
     const updatedBlockIndex = blocks.findIndex((i) => i.sk === block);
     if (updatedBlockIndex === -1) {
       alert("impossible - we don't have this block!");
       return false;
     }
     // find the planting
     const { plantings } = blocks[updatedBlockIndex];
     const updatedPlantingIndex = blocks[updatedBlockIndex].plantings.findIndex((i) => i.sk === planting);

     // see if we have the record
     const records = plantings[updatedPlantingIndex][recordType];
     const recordIndex = (records ? plantings[updatedPlantingIndex][recordType].findIndex((i) => i.sk === activity) : -1);

     if (recordIndex === -1) {
       alert(" impossible we don't have this activity");
       return false;
     }
     newRecord = { ...blocks[updatedBlockIndex].plantings[updatedPlantingIndex][recordType][recordIndex] };
     if (!newRecord[type]) {
       newRecord[type] = [];
     }
     newRecord[type].push(sk);
     try {
       const version = newRecord.version ? newRecord.version : 0;
       newRecord.version = version + 1;
       let creation = {};
       newRecord.activeTenant = this.props.getFromGlobalState('activeSite');
       creation = await createObject(this.props.getFromGlobalState('currentUser').username, 'calls', toStore(newRecord),getFromGlobalState('activeSite'));
       // await createObject(toStore(dialogObject));
       if (creation.status) {
         newRecord.cached = creation.cached;
       }
       // await createObject(toStore(newRecord));
       blocks[updatedBlockIndex].plantings[updatedPlantingIndex][recordType][recordIndex] = newRecord;
       setGlobalState({ blocks });
       // this.setState({ isSubmitting: false, sucess: true });
       return true;
     } catch (e) {
       setSnack({ open: true, variant: 'error', message: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error}, api src: ${e.response.data.src} ` : e.message) });
       /* this.setState(
         {
           success: false,
           isSubmitting: false,
           messageText: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error}` : e.message),
         },
       ); */
       return false;
     }
   }

   handleClickShowAll() {
     this.setState({ showAllDialogOpen: true });
   }

   handleClickShowReport() {
     this.setState({ showReportDialogOpen: true, clean: false });
   }

   sortProductByType = (data,sk,type)=>{

      const products = data && data.filter((x)=>x.product === sk);
      console.log(type,"products --- >", products)
       return products.map((x)=>({
        ...x,
        inout:'out',
        amount:type === "sprayings" ? x.volume : parseFloat(x.total),
        comment:'Application on '+ x.cropName,
        reason:'Application'
   }))
   }

  getProductItems = (product, startDate, endDate) => {
    const { inventoryItems, inventoryRequests } = this.state;
    let items = this.getInventoryForProduct(product, inventoryItems);
    const SprayingData = getDataByRecordType(this.props.activityName, this.props.metaData.blocks, this.props.getFromGlobalState, true, true, 100, undefined, undefined, true, true);
    const sortedProducts = this.sortProductByType(SprayingData.rows, product.sk,this.props.activityName);
    const minStock = parseFloat(product.minStock);

    let rows = sortedProducts.concat(items).map((item) => {
      const ob = { ...item };
      if (ob.inout === 'out') {
        ob.amount = -1 * ob.amount;
      }
      ob.actionType = 'Entry';

      return ob;
    });
    const cumulativeSum = ((sum, item) => (item) => sum += parseFloat(item.amount))(0);
    
    const balances = rows.map(cumulativeSum);

    rows = rows.map((item, i) => {
      const newItem = { ...item };
      newItem.balance = balances[i];
      return newItem;
    });
    const total = rows.reduce((accumulator, row) => accumulator + parseFloat(row.amount), 0);

    const status = (total > 1.1 * minStock ? 'green' : (total > minStock && total <= 1.1 * minStock ? 'amber' : 'red'));

    let requestArray = [];
 
    rows = rows && rows.sort((a, b) => new Date(b.date) - new Date(a.date));
    const count = rows.length;
    const mostRecent = rows.slice(0, RECENTS_LEN);
    const { unit } = product;
    let headCells = [];
    let headCellsClean = [];

    headCellsClean = [
      {
        id: 'date', combine: [{ id: 'date', opacity: 1 }, { id: 'reason', opacity: 0.6 }], numeric: false, disablePadding: false, label: 'Date',
      },
      {
        id: 'amount', combine: [{ id: 'amount', opacity: 1 }], numeric: true, disablePadding: false, label: `(${unit})`,
      },
    ];


    headCells = [
      {
        id: 'date', numeric: false, disablePadding: false, label: 'Date', minWidth: '80px',
      },
      {
        id: 'blockNumber', numeric: false, disablePadding: false, label: 'Block', minWidth: '80px',
      },
      {
        id: 'plantingName', numeric: false, disablePadding: false, label: 'Planting', minWidth: '80px',
      },
      {
        id: 'reason', numeric: false, disablePadding: false, label: 'Reason', minWidth: '70px',
      },
      {
        id: 'comment', numeric: false, disablePadding: false, label: 'Comment', minWidth: '70px',
      },
      {
        id: 'amount', numeric: true, disablePadding: false, label: `Total (${unit})`,
      },
      {
        id: 'balance', numeric: true, disablePadding: false, label: `Balance (${unit})`,
      },

    ];
    return ({
      count, mostRecent, rows: rows, headCells, headCellsClean, total, unit, minStock, status: { status, message: '' },
    });
  }




  getInventoryForProduct(product, inventoryItems) {
    if (!inventoryItems) { return []; }
    if (!product) { return []; }
    let items = inventoryItems.filter((i) => i.product === product.sk);
    return items;
  }


  render() {
    const {
      classes, inventoryName, metaData, activityName, getFromGlobalState,
    } = this.props;
    const {
      inventoryItemDialogOpen, isNew, selectedInventoryItem, selectedProduct, inventoryRequestDialogOpen,
      selectedInventory, showAllDialogOpen, expandedProduct, pdf, csv, clean, selectedinventoryItemSK, showReportDialogOpen, startDate, endDate,
    } = this.state;
    const farm = getFromGlobalState('farm')[0];
    const userDetail = getFromGlobalState('userDetail');
    function sortByDate(array, dateProperty) {
        return array.slice().sort((a, b) => {
            const dateA = new Date(a[dateProperty]);
            const dateB = new Date(b[dateProperty]);
            return dateA - dateB; // For ascending order
            // return dateB - dateA; // For descending order
        });
    }

    return (

      <Paper elevation={0} className={classes.mainSection}>
        {(inventoryName === 'inv_chemical' && inventoryItemDialogOpen)
          && (
          <InventoryItemD
            handleUpdate={this.handleUpdate.bind(this)}
            handleDelete={this.handleDelete.bind(this)}
            allowDelete
            open={inventoryItemDialogOpen}
            setClose={this.setClose.bind(this)}
            isNew={isNew}
            inventoryItem={selectedInventoryItem}
            product={selectedProduct}
            dynamoType="inv_chemical"
            newTitle= {selectedProduct.name}
            activityName="sprayings"
            getFromGlobalState={this.props.getFromGlobalState}
            metaData={{ blocks: getFromGlobalState('blocks'), crops: getFromGlobalState('crops') }}
          />
          )}

        {(inventoryName === 'inv_fertiliser' && inventoryItemDialogOpen)
        && (
          <InventoryItemD
            handleUpdate={this.handleUpdate.bind(this)}
            handleDelete={this.handleDelete.bind(this)}
            allowDelete
            open={inventoryItemDialogOpen}
            setClose={this.setClose.bind(this)}
            isNew={isNew}
            newTitle= {selectedProduct.name}
            inventoryItem={selectedInventoryItem}
            product={selectedProduct}
            dynamoType="inv_fertiliser"
            activityName="fertilisings"
            getFromGlobalState={this.props.getFromGlobalState}
            metaData={{ blocks: getFromGlobalState('blocks'), crops: getFromGlobalState('crops') }}
          />
        )}

        {inventoryRequestDialogOpen
        && (
          <InventoryRequestD
            handleUpdate={this.handleUpdate.bind(this)}
           // handlePreExecute={this.handlePreExecute.bind(this)}
            open={inventoryRequestDialogOpen}
            setClose={this.setClose.bind(this)}
            isNew={isNew}
            product={selectedProduct}
            dynamoType={selectedInventory}
            getFromGlobalState={this.props.getFromGlobalState}
            inventoryRequest={this.getSelectedItem(selectedInventory)}
            // parentKey={}
          />
        )}
        <Grid container className={classes.root} spacing={2} alignItems="flex-start">

          {metaData && metaData.products && metaData.products.map((product, index) => {
            const productItems = this.getProductItems(product);
            const recent = sortByDate(productItems.mostRecent,"date");
            return (
              <>
                {showAllDialogOpen && product.sk === expandedProduct.sk
            && (
            <ShowAllD
              open={showAllDialogOpen && product.sk === expandedProduct.sk}
              setClose={this.setCloseShowAll.bind(this)}
              isNew={false}
              getFromGlobalState={getFromGlobalState}
              title={`${product.name}`}
            >
              <EnhancedTable
                displayToolbar
                id="reportTable"
                noPagination
                rows={productItems.rows}
                headCells={clean ? productItems.headCellsClean : productItems.headCells}
                handleSelect={this.handleClickShowInventoryItem.bind(this)}
                handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handleClickAdd.bind(this, product) : null}
                parent={product}
                actionType="actionType"
                rowKeyName="sk"
                orderByProperty="date"
                orderType="asc"
                clean
                selectedRow={selectedinventoryItemSK}
              />
            </ShowAllD>
            )}

                {showReportDialogOpen && product.sk === expandedProduct.sk
            && (
            <ShowAllD
              open={showReportDialogOpen && product.sk === expandedProduct.sk}
              setClose={this.setCloseReport.bind(this)}
              isNew={false}
              title={`${product.name} report`}
              getFromGlobalState={getFromGlobalState}
              onGeneratePDF={this.generatePDF.bind(this)}
              onGenerateCSV={this.generateCSV.bind(this)}
              pdfRequested={pdf}
              csvRequested={csv}
              reportCSV={<ReportInventoryCSV onDownloaded={this.onDownloaded} data={{headCells:productItems.headCells,title:product.name,rows:startDate && endDate ? sortByDate(productItems.rows, "date").filter((r) => {return r.date >= startDate && r.date <= endDate; }) : sortByDate(productItems.rows, "date")}} color="secondary" className={classes.pdfButton} startDate={startDate} endDate={endDate} farm={farm}  />}
              reportPDF={<ReportInventoryPDF onDownloaded={this.onDownloaded} inventoryName={inventoryName} product={product} color="secondary" className={classes.pdfButton} farm={farm} headCells={productItems.headCells} startDate={startDate} endDate={endDate} 
              rows={startDate && endDate ? sortByDate(productItems.rows, "date").filter((r) => {return r.date >= startDate && r.date <= endDate; }) : sortByDate(productItems.rows, "date")}
              getFromGlobalState={getFromGlobalState}/>}
             
            >
              <EnhancedTable
                displayToolbar
                heading=""
                orderByProperty="date"
                orderType="asc"
                rows={productItems.rows}
                headCells={clean ? productItems.headCellsClean : productItems.headCells}
                parent={product}
                actionType="actionType"
                rowKeyName="sk"
                disablePadFirstCol
                clean
              />

            </ShowAllD>
            )}

                <Grid key={product.sk} style={{ padding: '3px' }} item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SimpleCard
            title={product.name.toUpperCase()}
            expanded= {this.state.expandedProduct && this.state.expandedProduct.sk === product.sk ? true : undefined}
            ariaLabel="Product"
            handleExpandClick={this.handleExpand.bind(this, product)}
            // handleAddExpandedContent={this.handleClickAdd.bind(this,product)}
            // subHeader={`Min Stock Level: ${productItems.minStock}, Balance: ${productItems.total.toLocaleString()} ${productItems.unit}`}
            avatar={
              <Status status={productItems.status} hideIfGreen={false} size="medium" />}
            elevation={1}
            expandedContent={(
              <>
            <EnhancedTable
              displayToolbar
              heading='Most Recent'
              noPagination
              hideHeader
              rows={recent}
              headCells={this.state.clean ? productItems.headCellsClean : productItems.headCells}
              handleSelect={this.handleClickShowInventoryItem.bind(this)}
              handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handleClickAdd.bind(this, product) : null}
              dense
              parent={product}
              actionType='actionType'
              rowKeyName='sk'
              orderByProperty="date"
              orderType="asc"
              clean
              selectedRow={this.state.selectedinventoryItemSK}
            />

               <CardActions>
              <Button size="small" onClick={this.handleClickShowReport.bind(this)} color="secondary" className={classes.buttonLeft}>
              Report
              </Button>
             {/* {productItems.count> settings.constants.RECENTS_LEN && */}
              <Button size="small" onClick={this.handleClickShowAll.bind(this)} color="secondary" className={classes.buttonRight}>
              Show All
              </Button>
           {/* } */}
               </CardActions>

              </>
            )}
          >
            <Grid container className={classes.root} spacing={2} alignItems="flex-end">
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
              <Typography>Stock Level:</Typography>
            </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography variant="subtitle2" className={classes[`text${productItems.status.status}`]}>
                {`${productItems.total.toLocaleString()} ${productItems.unit}`}
                {' '}
                </Typography>
             </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

             
             
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

             
             
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
            </Grid>
            {/* }
           {this.getActivitiesByProduct(product).map((spraying,index) =>
              <>
              {spraying && <><span>{spraying.sprayings.date} </span>
              <span>{spraying.block.number} </span>
              <span>{spraying.sprayings.calculatedProduct} </span>
              <span>{spraying.sprayings.productUnit} </span>
              <span>{getNameFromKey('crops',spraying.planting.crop,this.props.getFromGlobalState)} </span></>}
              </>
            )}
           */}


          </SimpleCard>
                </Grid>
              </>
            ); 
          })}

                </Grid>


      </Paper>
    );
  }
}
InventoryItemDetailForm.propTypes = {
  onSave: PropTypes.func,
  initialState: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  metaData: PropTypes.any,
  inventoryName: PropTypes.string,
  activityName: PropTypes.string.isRequired,

};
export default withStyles(styles)(InventoryItemDetailForm);
