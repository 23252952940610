import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LoaderButton from '../LoaderButton';

const styles = (theme) => ({

  submit: {
    margin: theme.spacing(1),
  },

});

function ConfirmDialog(props) {
  const {
    open, handleConfirm, handleCancel, title, message, classes, loading, actionText,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {message && (
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
          ) }
        </DialogContent>
        <DialogActions>
          <LoaderButton
            // type="submit"
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
            isloading={loading}
            text={actionText || 'Delete'}
            loadingText={actionText ? `${actionText}..` : 'Deleting..'}
          />
          <Button
            onClick={handleCancel}
            color="secondary"
            variant="contained"
            className={classes.submit}
            disabled={loading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  actionText: PropTypes.string.isRequired,
};
export default withStyles(styles, { withTheme: true })(ConfirmDialog);
