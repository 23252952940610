import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '../../../components/Tabs';
import ExpansionPanelDetailed from '../../../components/ExpansionPanelDetail';
// import ChartLine2 from '../../../components/LineChart2';
import ChartBarStacked from '../../../components/BarChartStacked';

const fertMap = {
  nPerHa: { name: 'N', type: 'Macro' },
  pPerHa: { name: 'P', type: 'Macro' },
  kPerHa: { name: 'K', type: 'Macro' },
  caPerHa: { name: 'Ca', type: 'Macro' },
  mgPerHa: { name: 'Mg', type: 'Macro' },
  sPerHa: { name: 'S', type: 'Macro' },
  znPerHa: { name: 'Zn', type: 'Micro' },
  bPerHa: { name: 'B', type: 'Micro' },
  fePerHa: { name: 'Fe', type: 'Micro' },
  mnPerHA: { name: 'Mn', type: 'Micro' },
  cuPerHa: { name: 'Cu', type: 'Micro' },
  moPerHa: { name: 'Mo', type: 'Micro' },
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    backgroundColor: 'inherit',
    // overflow: 'scroll',
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  section: {
    margin: theme.spacing(2, 4),
  },

});

class FertilisingDashboard extends Component {
  constructor(props) {
    super(props);
    // this.onMapBlockClick = this.onMapBlockClick.bind(this);

    this.state = {
      graphData: {},
      expandedMonitorType: 'Macro Nutrients',
      expandedMonitorTypeInner: '',
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const graphData = this.formatData(data);
    if (graphData) {
      this.setState({ graphData });
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const { data } = this.props;
    if (data !== prevProps.data) {
      // const blocks = getFromGlobalState('blocks');
      const graphData = this.formatData(data);
      this.setState({ graphData });
    }
  }

  handleMonitorTypeExpand = (setting) => {
    const { expandedMonitorType } = this.state;
    if (expandedMonitorType !== setting) {
      this.setState({ expandedMonitorType: setting });
    } else {
      this.setState({ expandedMonitorType: '' });
    }
  }

  handleMonitorTypeExpandInner = (setting) => {
    const { expandedMonitorTypeInner } = this.state;
    if (expandedMonitorTypeInner !== setting) {
      this.setState({ expandedMonitorTypeInner: setting });
    } else {
      this.setState({ expandedMonitorTypeInner: '' });
    }
  }

  distinctPush = (array, member) => {
    const idx = array.findIndex((p) => p === member);
    if (idx === -1) {
      array.push(member);
    }
  }

  renderCharts = (data, type) => {
    const { handleMonitoringClick } = this.props;
    console.log(data,type);
    const belee = 2;
    if (!data || !data.data) {
      return null;
    }

    return (

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ChartBarStacked
          dataKey="name"
          key="fertChart"
          title="Nutrients kg/ha"
          height={400}
          name="Fetilisers"
          handleClick={handleMonitoringClick}
          keys={data.dataKeys}
          data={data.data.filter((m) => m.type === type)}
        />
      </Grid>

    );
  }

  formatData = (fertilisingData) => {
    const retVal = { data: [], dataKeys: fertilisingData.distinctFertilisers };
    if (!fertilisingData || !fertilisingData.distinctFertilisers) {
      return null;
    }

    for (let k = 0; k < Object.keys(fertMap).length; k += 1) {
      const key = Object.keys(fertMap)[k];
      const nutrient = fertMap[key];
      const ob = { name: nutrient.name, type: nutrient.type };
      for (let f = 0; f < fertilisingData.distinctFertilisers.length; f += 1) {
        const product = fertilisingData.distinctFertilisers[f];
        const records = fertilisingData.circles.filter((m) => m.product && m.product === product);
        const total = records.reduce((acc, cur) => acc + parseFloat(cur[key] ? cur[key] : 0), 0);
        if (total !== 0) {
          ob[product] = parseFloat(total.toFixed(3));
        }
      }
      retVal.data.push(ob);
    }
    return retVal;
  }

  formatDataOld = (fertilisingData) => {
    // for each monitorType
    const retVal = {};
    if (!fertilisingData || !fertilisingData.distinctFertilisers) {
      return null;
    }

    for (let k = 0; k < fertilisingData.distinctFertilisers.length; k += 1) {
      const product = fertilisingData.distinctFertilisers[k];
      const records = fertilisingData.circles.filter((m) => m.product && m.product === product);
      // const recordsByTarget = records.filte
      // records[type][target][stage]
      if (!retVal[product]) {
        retVal[product] = {};
      }
      for (let r = 0; r < records.length; r += 1) {
        const record = records[r];

        const block = `Block: ${record.block}`;
        if (!retVal[product].dataKeys) {
          retVal[product].dataKeys = [];
        }
        this.distinctPush(retVal[product].dataKeys, block);

        if (!retVal[product].data) {
          retVal[product].data = [];
        }
        const dataObj = { date: new Date(record.date).getTime() };
        dataObj[block] = record.total;
        dataObj.blockName = block;
        dataObj.data = record;
        // observationData.value.toFixed(2)
        retVal[product].data.push(dataObj);
      }
    }
    return retVal;

    /* {
    name: 'Date',
    block 1: 1890,
    block 2: 4800,
    block 3: 2181,
  }, */

  // keys
  }

  render() {
    const { classes } = this.props;

    const { graphData, expandedMonitorType, expandedMonitorTypeInner } = this.state;

    return (
      <Paper
        elevation={1}
        style={{
          marginTop: '10px', maxHeight: '80vh', minHeight: '400px', overflowY: 'auto', backgroundClip: 'inherit',
        }}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          {graphData
                && (
                  <>
                    <ExpansionPanelDetailed
                      heading="Macro Nutrients"
                      overwriteDisplay
                      expanded={expandedMonitorType}
                      handleExpand={this.handleMonitorTypeExpand}
                    >
                      <div style={{ width: '100%' }}>
                        {expandedMonitorType === 'Macro Nutrients' && this.renderCharts(graphData, 'Macro')}
                      </div>
                    </ExpansionPanelDetailed>
                    <ExpansionPanelDetailed
                      heading="Micro Nutrients"
                      overwriteDisplay
                      expanded={expandedMonitorType}
                      handleExpand={this.handleMonitorTypeExpand}
                    >
                      <div style={{ width: '100%' }}>
                        {expandedMonitorType === 'Micro Nutrients' && this.renderCharts(graphData, 'Micro')}
                      </div>
                    </ExpansionPanelDetailed>
                  </>
                )}
        </Grid>
      </Paper>

    );
  }
}
FertilisingDashboard.propTypes = {
  data: PropTypes.object.isRequired,
  handleMonitoringClick: PropTypes.func.isRequired,

};
export default withStyles(styles)(FertilisingDashboard);
