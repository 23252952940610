import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ChemicalD from '../ChemicalD';
import ChemCropD from '../ChemCropD';
import ChemCropTargetD from '../ChemCropTargetD';
import EnhancedTable from '../../components/Etable';
import { getNameFromKey2 } from '../../libs/genLib';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  aligner: {
    // display:'inline-flex',
    // marginRight:'auto',
    // marginBottom:'15px',
  },
  paperDropperBody: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  heading: {
    marginTop: '4px',
    cursor: 'pointer',
    // marginTop: '10px',
  },
  submit: {
    margin: theme.spacing(1),
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    // overflow: 'scroll',
  },
  mainSection: {
    width: '100%',
    flexWrap: 'wrap',
  },
  parentSection: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },

  },
  childSection: {
    marginLeft: theme.spacing(5),
  },
  childSectionChildren: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const detailChemicalHeadCells = [
  {
    id: 'isDisabled', numeric: false, disablePadding: true, label: 'Disabled',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
];

const cropHeadCells = [
  {
    id: 'isDisabled', numeric: false, disablePadding: true, label: 'Disabled',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
];

const targetHeadCells = [
  {
    id: 'isDisabled', numeric: false, disablePadding: true, label: 'Disabled',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
];

{ /* {
    id: 'name', combine: [{ id: 'targetType', opacity: 1 }, { id: 'name', opacity: 0.8 }, { id: 'mobileDescription', opacity: 0.6 }], numeric: false, disablePadding: true, label: 'Target', maxWidth: '100px',
  }, */ }

function generateReasonLis(pests) {
  if (!pests) {
    return [];
  }
  const rows = pests.map((monitoring) => {
    const ob = {};
    ob.sk = monitoring.sk;
    ob.name = monitoring.targetDetail.name;
    ob.targetSK = monitoring.targetDetail.sk;
    return ob;
  });
  return rows;
}

function generatePPPChemicalRows(chemicals) {
  if (!chemicals) {
    return [];
  }
  // const crops = getFromGlobalState('crops');
  // const pests = generateReasonLis(getFromGlobalState('monitorings'));

  const rows = [];
  for (let i = 0; i < chemicals.length; i += 1) {
    rows.push({ name: chemicals[i].name, sk: chemicals[i].sk, isDisabled: chemicals[i].isDisabled ? 'Yes' : 'No' });
  }
  return rows;
}

function generateCropRows(selectedChemical, getFromGlobalState) {
  if (!selectedChemical) {
    return [];
  }
  const rows = [];
  const crops = getFromGlobalState('crops');
  // const chemicals = getFromGlobalState('chemicals');
  // const chemical = chemicals.find((c) => c.sk === selectedChemical.sk);

  //const pests = generateReasonLis(getFromGlobalState('monitorings'));
  for (let c = 0; selectedChemical && selectedChemical.chemcrops && c < selectedChemical.chemcrops.length; c += 1) {
    rows.push({
      sk: selectedChemical.chemcrops[c].sk,
      name: getNameFromKey2(selectedChemical.chemcrops[c].crop, crops),
      isDisabled: selectedChemical.chemcrops[c].isDisabled ? 'Yes' : 'No',
    });
  }
  return rows;
}

function generateCropTargetRows(selectedChemicalCrop, getFromGlobalState) {
  if (!selectedChemicalCrop) {
    return [];
  }
  
  console.log("selectedChemicalCrop",selectedChemicalCrop);
  const rows = [];
  const pests = generateReasonLis(getFromGlobalState('monitorings'));

  for (let t = 0; selectedChemicalCrop.targets && t < selectedChemicalCrop.targets.length; t += 1) {
    rows.push({
      sk: selectedChemicalCrop.targets[t].sk,
      name: getNameFromKey2(selectedChemicalCrop.targets[t].target, pests),
      targetSK: selectedChemicalCrop.targets[t].target,
      isDisabled: selectedChemicalCrop.targets[t].isDisabled ? 'Yes' : 'No',
    });
  }

  return rows;
}

class ChemicalDetailForm extends Component {
  constructor(props) {
    super(props);
    this.handleClickShowChemical = this.handleClickShowChemical.bind(this);
    this.handleClickAddChemical = this.handleClickAddChemical.bind(this);
    this.handleClickSelectChemical = this.handleClickSelectChemical.bind(this);

    this.state = {
      expandedSetting: '',
      // chemicals: this.props.initialState,
      selectedChemical: null,
      selectedChemicalCrop: null,
      modalObject: null,
      isNew: false,
      chemicalDetailOpen: false,
      chemicalCropDialogOpen: false,
      errors: {},
      openModal: '',
      chemicalCropTargetDialogOpen: false,
      selectedChemicalCropTarget: null,

    };
  }

  handleClickShow = () => {
    this.setState({ chemicalDetailOpen: true, isNew: false });
  }

  handleBlur = (event) => {
    // return this.validateField (event.target.id, this.props.requiredFields);
  }

  handleClickAddChemical() {
    this.setState({ chemicalDetailOpen: true, isNew: true, selectedChemical: null, selectedChemicalCrop: null, selectedChemicalCropTarget: null });
  }

handleClickAddNewChemicalCrop = (chemicalSK) => {
  this.setState({
    chemicalCropDialogOpen: true, isNew: true, selectedChemicalSK: chemicalSK, selectedChemicalCrop: null, selectedChemicalCropTarget: null,
  });
}

handleClickAddNewChemicalCropTarget = () => {
  this.setState({
    chemicalCropTargetDialogOpen: true, isNew: true, selectedChemicalCropTarget: {},
  });
}

handleClickShowChemical(event, parent, selectedChemicalID) {
  // const { selectedChemical } = this.state;
  // const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalID);
  this.setState({
    chemicalDetailOpen: 'true', isNew: false,
  // selectedChemical, selectedChemicalSK: selectedChemicalID,
  });
}

handleClickSelectChemical(event, parent, selectedChemicalID) {
  const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalID);
  this.setState({
    selectedChemical, selectedChemicalCrop: null, selectedChemicalCropTarget: null, isNew: false,
  // selectedChemical, selectedChemicalSK: selectedChemicalID,
  });
}

handleClickShowChemicalCrop = (event, parent, selectedChemicalCropId) => {
  // const { selectedChemicalCrop } = this.state;
  // const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalId);
  this.setState({ selectedChemicalCropTarget: null, chemicalCropDialogOpen: true, isNew: false });
}

handleSelectChemicalCrop = (event, parent, selectedChemicalCropId) => {
  const { selectedChemical } = this.state;
  // const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalId);
  const selectedChemicalCrop = selectedChemical.chemcrops.find((cc) => cc.sk === selectedChemicalCropId);
  this.setState({ selectedChemicalCrop });
}

handleClickShowChemicalCropTarget = (event, parent, selectedChemicalCropId) => {
  // const { selectedChemicalCrop } = this.state;
  // const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalId);
  this.setState({ chemicalCropTargetDialogOpen: true, isNew: false });
}

 handleSelectChemicalCropTarget = (event, parent, selectedChemicalCropTargetId) => {
   const { selectedChemicalCrop } = this.state;
   // const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalId);
   const selectedChemicalCropTarget = selectedChemicalCrop.targets.find((cc) => cc.sk === selectedChemicalCropTargetId);
   this.setState({ selectedChemicalCropTarget });
 }

 handleClickEdit() {
   this.setState({ chemicalDetailOpen: true, isNew: true });
 }

 setClose() {
   this.setState({ chemicalDetailOpen: false });
 }

 setCloseVariety() {
   this.setState({ chemicalCropDialogOpen: false });
 }

 setCloseChemicalCropTarget() {
   this.setState({ chemicalCropTargetDialogOpen: false });
 }

 handleUpdate(chemicalIn) {
   // see if we have the block
   // see if we have the block

   const updatedIndex = this.props.getFromGlobalState('chemicals').findIndex((i) => i.sk === chemicalIn.sk);
   if (updatedIndex === -1) {
     const newChemicals = this.props.getFromGlobalState('chemicals');
     newChemicals.push(chemicalIn);
     this.props.setGlobalState({ chemicals: newChemicals });
     // this.state.blocks.push(blockIn);
   } else {
     const newChemicals = this.props.getFromGlobalState('chemicals');
     newChemicals[updatedIndex] = chemicalIn;
     this.props.setGlobalState({ chemicals: newChemicals });
   }
   this.setState({ selectedChemicalCropTarget: null, selectedChemicalCrop: null, selectedChemical: chemicalIn});
 }

 handleUpdateChemicalCropTarget(chemicalCropTargetIn) {
   const { setGlobalState, getFromGlobalState } = this.props;
   const { selectedChemicalCrop, selectedChemical } = this.state;
   const chemicals = getFromGlobalState('chemicals');
   const chemical = chemicals.find((c) => c.sk === selectedChemical.sk);
   const crop = chemical.chemcrops.find((c) => c.sk === selectedChemicalCrop.sk);
   if (!crop.targets) {
     crop.targets = [];
   }
   const index = crop.targets.findIndex((t) => chemicalCropTargetIn.sk === t.sk);
   if (index === -1) {
     crop.targets.push(chemicalCropTargetIn);
   } else {
     crop.targets[index] = { ...chemicalCropTargetIn };
   }
   setGlobalState({ chemicals });
   this.setState({ selectedChemicalCropTarget: chemicalCropTargetIn });
 }

 handleUpdateChemicalCrop(chemicalCropIn, chemicalSK) {
   // see if we have the block

   const updatedChemicalIndex = this.props.getFromGlobalState('chemicals').findIndex((i) => i.sk === chemicalSK);
   if (updatedChemicalIndex === -1) {
     alert("impossible - we don't have this crop!");
     return;
   }

   const chemicals = this.props.getFromGlobalState('chemicals');
   const chemicalCrops = chemicals[updatedChemicalIndex].chemcrops;

   if (!chemicalCrops) {
     chemicals[updatedChemicalIndex].chemcrops = [];
   }

   const updatedChemCropIndex = (chemicals ? chemicals[updatedChemicalIndex].chemcrops.findIndex((i) => i.sk === chemicalCropIn.sk) : -1);
   if (updatedChemCropIndex === -1) {
     chemicals[updatedChemicalIndex].chemcrops.push(chemicalCropIn);
     this.props.setGlobalState({ chemicals, chemicalCropDialogOpen: false });
   } else {
     chemicals[updatedChemicalIndex].chemcrops[updatedChemCropIndex] = { ...chemicalCropIn };
     this.props.setGlobalState({ chemicals, chemicalCropDialogOpen: false });
   }
   this.setState({ selectedChemicalCropTarget: null, selectedChemicalCrop: chemicalCropIn });
 }


 getCropsNotAddedYet(crops, chemical, isNew, selectedChemicalCrop) {

  if (!chemical) { return []; }
   if (!chemical.chemcrops) { 
       chemical.chemcrops = []
    } 
   
   const chemFilter = chemical.chemcrops.map((chem) => chem.crop);

   const filteredCrops = crops.filter((crop) => !chemFilter.includes(crop.sk));
   if (!isNew) {
     const crop = crops.filter((c) => c.sk === selectedChemicalCrop.crop);
     if (crop) {
       filteredCrops.push(crop[0]);
     }
   }

   return filteredCrops;
 }

 render() {
   const { classes, initialState, getFromGlobalState } = this.props;
   const {
     selectedChemical, selectedChemicalCrop, chemicalCropDialogOpen, chemicalDetailOpen, selectedChemicalCropTarget, chemicalCropTargetDialogOpen,
   } = this.state;
   const chemicals = initialState;
   // const parent = selectedChemical;

   const chemicalHeadCells = detailChemicalHeadCells;
   const chimcalRows = generatePPPChemicalRows(chemicals);
   
   const cropRows = selectedChemical ? generateCropRows(selectedChemical, getFromGlobalState) : [];
   const targetRows = selectedChemicalCrop ? generateCropTargetRows(selectedChemicalCrop, getFromGlobalState) : [];
   const userDetail = getFromGlobalState('userDetail');
   return (

     <Paper elevation={0} className={classes.mainSection}>
       <Grid container className={classes.root} spacing={1} alignItems="flex-start">

         {(this.state.chemicalDetailOpen)
          && (
          <ChemicalD
            handleUpdate={this.handleUpdate.bind(this)}
            open={chemicalDetailOpen}
            setClose={this.setClose.bind(this)}
            isNew={this.state.isNew}
            autoSearch
            chemical={this.state.selectedChemical}
            getFromGlobalState={this.props.getFromGlobalState}
            ppps={chemicals}
            requiredFields={[
              { name: 'name', min: 1, max: 40 },
              { name: 'aIngredient', min: 1, max: 1000 },
              { name: 'registrationNumber', min: 1, max: 40 },
              { name: 'registrationHolder', min: 1, max: 300 },
              { name: 'unit', min: 1, max: 40 },
              { name: 'supplier', min: 1, max: 300 },
              /* {name:'minStock',min:1,max:40}, */
            ]}
          />
          )}

         {(this.state.chemicalCropDialogOpen)
          && (
          <ChemCropD
            handleUpdate={this.handleUpdateChemicalCrop.bind(this)}
            open={chemicalCropDialogOpen}
            setClose={this.setCloseVariety.bind(this)}
            isNew={this.state.isNew}
           // metaData= {getFromGlobalState("monitorings")}
            chemCrop={selectedChemicalCrop}
            parent={selectedChemical}
            getFromGlobalState={getFromGlobalState}
         // metaData={this.props.metaData} />}
            metaData={{ filteredCrops: this.getCropsNotAddedYet(this.props.metaData, this.state.selectedChemical, this.state.isNew, this.state.selectedChemicalCrop), monitorings: getFromGlobalState('monitorings') }}
          />
          )}
         {(chemicalCropTargetDialogOpen)
          && (
          <ChemCropTargetD
            handleUpdate={this.handleUpdateChemicalCropTarget.bind(this)}
            open={chemicalCropTargetDialogOpen}
            setClose={this.setCloseChemicalCropTarget.bind(this)}
            isNew={this.state.isNew}
            chemCropTarget={selectedChemicalCropTarget}
            chemical={selectedChemical}
            parent={selectedChemicalCrop}
            getFromGlobalState={getFromGlobalState}
            crop={selectedChemicalCrop ? selectedChemicalCrop.crop : null}
            metaData={{ monitorings: getFromGlobalState('monitorings') }}
            alreadySet={targetRows}
          />
          )}
         <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
           <EnhancedTable
             heading="Product"
             displayToolbar
             id="pppChemTable"
             noPagination 
             rows={chimcalRows}
             headCells={chemicalHeadCells}
             handleSelect={this.handleClickSelectChemical}
             handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handleClickAddChemical : null}
             actionType="actionType"
             rowKeyName="sk"
             disablePadFirstCol
             clean
             dense
             showEditButton
             editDisabled={!selectedChemical}
             handleEditItem={this.handleClickShowChemical}
             maxHeight="350px"
             selectedRow={selectedChemical ? selectedChemical.sk : ''}
           />
         </Grid>
         <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
           <EnhancedTable
             heading="Crop"
             displayToolbar
             id="pppCropTable"
             noPagination 
             rows={cropRows}
             headCells={cropHeadCells}
             handleSelect={this.handleSelectChemicalCrop}
             handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ?this.handleClickAddNewChemicalCrop : null}
             actionType="actionType"
             rowKeyName="sk"
             disablePadFirstCol
             clean
             dense
             showEditButton
             editDisabled={!selectedChemicalCrop}
             disableAdd={!selectedChemical}
             handleEditItem={this.handleClickShowChemicalCrop}
             parent={selectedChemical}
             maxHeight="350px"
             selectedRow={selectedChemicalCrop ? selectedChemicalCrop.sk : ''}
           />
         </Grid>
         <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
           <EnhancedTable
             heading="Target"
             displayToolbar
             id="pppCropTargetTable"
             noPagination 
             rows={targetRows}
             headCells={targetHeadCells}
             handleSelect={this.handleSelectChemicalCropTarget}
             handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handleClickAddNewChemicalCropTarget : null}
             actionType="actionType"
             rowKeyName="sk"
             disablePadFirstCol
             clean
             dense
             showEditButton
             disableAdd={!selectedChemicalCrop}
             editDisabled={!selectedChemicalCropTarget}
             handleEditItem={this.handleClickShowChemicalCropTarget}
             parent={selectedChemicalCrop}
             maxHeight="350px"
             selectedRow={selectedChemicalCropTarget ? selectedChemicalCropTarget.sk : ''}
           />
         </Grid>
       </Grid>
     </Paper>
   );
 }
}
ChemicalDetailForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  initialState: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  sync:PropTypes.func.isRequired,
  activeSite:PropTypes.any

};
export default withStyles(styles)(ChemicalDetailForm);
