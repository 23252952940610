import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

const requiredFields = [
  { name: 'name', min: 1, max: 40 },
  { name: 'capacity', min: 1, max: 40 },
];

class HarvestingEquipmentD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      harvestingEquipment: {},
      errors: { count: 0 },
      changed: false,

    };
  }

  componentDidMount() {
    if (this.props.isNew) {
      this.setState({ harvestingEquipment: {} });
    } else {
      const harvestingEquipment = this.props.selectedSetting;
      this.setState({ harvestingEquipment });
    }
  }

  validateField(field, requiredFields) {
    const error = validate(field, this.state.harvestingEquipment, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const harvestingEquipment = { ...this.state.harvestingEquipment };
    harvestingEquipment[event.target.id] = event.target.value;
    this.setState({ harvestingEquipment, changed: true });
  }

  render() {
    const {
      open, isNew, setClose, handleUpdate, getFromGlobalState,
    } = this.props;
    const { harvestingEquipment, errors } = this.state;
    const formValid = validateForm(harvestingEquipment, requiredFields);

    return (

      <GenDialog
        open={open}
        dialogObject={harvestingEquipment}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary="init_setting_"
        dynamoType="harvestequips"
        valid={formValid}
        changed={this.state.changed}
        newTitle="New Harvesting Equipment"
        title={harvestingEquipment.name}
        getFromGlobalState={getFromGlobalState}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoFocus
          value={harvestingEquipment.name}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.name ? true : undefined}
          helperText={errors.name}
        />
        <TextField
          margin="normal"
            // required
          fullWidth
          id="capacity"
          label="Capacity in Kg"
          name="capacity"
          type="number"
          inputProps={{ min: '0.00001', step: 'any' }}
           // autoFocus
          value={harvestingEquipment.capacity}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.capacity ? true : undefined}
          helperText={errors.capacity}
        />

        {/* }  <FormControl className={classes.formControl}>
          <Typography variant="subtitle1"> Can be used for  *</Typography>

          <SingleListControl

          data= {metaData}
          onBlur={this.handleBlur}
          checkedItems ={chemEquipment.allowedChemicals}
          handleToggle={this.handleToggle}

          />
         <FormHelperText className={classes.helperText}>{errors.allowedChemicals}</FormHelperText>
          {errors.allowedChemicals&& <FormHelperText className={classes.helperText}>{errors.allowedChemicals}</FormHelperText>}
    </FormControl> */}

      </GenDialog>

    );
  }
}
HarvestingEquipmentD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedSetting: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(HarvestingEquipmentD);
