import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import uuid from 'uuid';
import BlockD from '../BlockD';
import Load from '../../components/Load';
import EnhancedTable from '../../components/Etable';
import Map from '../../components/Map';
import {
  getErrorMessage, getCropDetails, createBatchRecords, toStore,
} from '../../libs/storage';
import {
  geoStringToLatLong, parseKML, randomColor2, readFileAsText, parseCSV,
} from '../../libs/genLib';
import FileUploader from '../FileUploader';
import InfoBox from '../../components/InfoBox';
import { numberOfPlants } from '../../libs/analytics';

function parseCustomDate(inputDate) {
  // Regular expressions to match date formats
  const ddmmyyyyRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/; // DD/MM/YYYY
  const mmddyyyyRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/; // MM/DD/YYYY

  // Check if inputDate matches DD/MM/YYYY format
  if (ddmmyyyyRegex.test(inputDate)) {
      const [_, day, month, year] = inputDate.match(ddmmyyyyRegex).map(Number);
      // Create ISO date string assuming DD/MM/YYYY
      const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      const dateObject = new Date(formattedDate);
      if (!isNaN(dateObject.getTime())) {
          return dateObject.toISOString().substr(0, 10);
      }
  }

  // Check if inputDate matches MM/DD/YYYY format
  if (mmddyyyyRegex.test(inputDate)) {
      const [_, month, day, year] = inputDate.match(mmddyyyyRegex).map(Number);
      // Create ISO date string assuming MM/DD/YYYY
      const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      const dateObject = new Date(formattedDate);
      if (!isNaN(dateObject.getTime())) {
          return dateObject.toISOString().substr(0, 10);
      }
  }

  // If the date is not in a recognized format, throw an error
  throw new Error('Invalid Date');
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paperDropperBody: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  heading: {
    marginTop: '4px',
    cursor: 'pointer',
  },
  submit: {
    margin: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(10),
  },
  formControl: {
    width: '100%',
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  mainSection: {
    width: '100%',
    flexWrap: 'wrap',
  },
  parentSection: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },

  },
  childSection: {
    marginLeft: theme.spacing(5),
  },
  childSectionChildren: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class BlockDetailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBlock: null,
      isNew: false,
      blockDialogOpen: false,
      selectedMapBlock: '',
      infoWindowPosition: null,
      mapUploading: false,
      blockColors: { count: 0 },
      error:{},
      userLocation:null

    };
  }


  componentDidMount() {
    // Check if the Geolocation API is available
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // Update the state with user's location
          this.setState({
            userLocation: {
              lat: latitude,
              lng: longitude,
            },
          });
        },
        (error) => {
          console.error('Error fetching user location:', error);
          // Handle errors here (e.g., set a default location or show an error message)
          this.setState({
            userLocation: null,
          });
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      // Handle cases where geolocation is not supported
      this.setState({
        userLocation: null,
      });
    }
  }

  handleClickShow = () => {
    this.setState({ blockDialogOpen: true });
  }

  handleBlur = () => {
    // return this.validateField (event.target.id, this.props.requiredFields);
  }

  handleClickAdd() {
    this.setState({ blockDialogOpen: true, isNew: true, selectedBlock: {} });
  }

  handleClickShowBlock(event, planting, selectedBlockId) {
    const { getFromGlobalState } = this.props;
    const selectedBlock = getFromGlobalState('blocks').find((block) => block.sk === selectedBlockId);
    this.setState({
      selectedBlock, selectedBlockSK: selectedBlockId, blockDialogOpen: 'true', isNew: false,
    });
  }

  handleClickEdit() {
    this.setState({ blockDialogOpen: true, isNew: true });
  }

  setClose() {
    this.setState({ blockDialogOpen: false });
  }

  handleUpdateBlocks(blockIn) {
    const { getFromGlobalState, setGlobalState } = this.props;
    const updatedBlockIndex = getFromGlobalState('blocks').findIndex((i) => i.sk === blockIn.sk);
    if (updatedBlockIndex === -1) {
      const newBlocks = getFromGlobalState('blocks');
      newBlocks.push(blockIn);
      setGlobalState({ blocks: newBlocks });
      // this.state.blocks.push(blockIn);
    } else {
      const newBlocks = getFromGlobalState('blocks');
      newBlocks[updatedBlockIndex] = blockIn;
      setGlobalState({ blocks: newBlocks });
    }
  }

  handleDeleteBlock(blockIn) {
    // see if we have the block
    const { getFromGlobalState, setGlobalState } = this.props;
    const blocks = getFromGlobalState('blocks');
    const updatedBlockIndex = blocks.findIndex((i) => i.sk === blockIn.sk);
    if (updatedBlockIndex === -1) {
      alert("impossible - we don't have this block!");
      return;
    }
    blocks.splice(updatedBlockIndex, 1);
    setGlobalState({ blocks });
  }

  onMapBlockClick(key, event) {
    // let selectedBlock = this.props.getFromGlobalState('blocks').find(block=>block.sk===key);
    this.setState({ selectedMapBlock: key, infoWindowPosition: { lat: event.latLng.lat(), lng: event.latLng.lng() } });
  }

  onCloseInfoWindow() {
    this.setState({ selectedMapBlock: '', infoWindowPosition: null });
  }

  onMapClick() {
    this.setState({ selectedMapBlock: '', infoWindowPosition: null });
  }

  genIndex = (str) => {
    const s = str;
    // s = s.replace(/[^\w\s]/gi, '');
    // s = s.replace(/[' ']/gi, '');
    let h = 0;
    for (let i = 0; i < s.length && i < 3; i += 1) {
      h = 31 * h + s.charCodeAt(i);
    }
    return h;
  }

  getBlockColor = (block, crop, cropDetail) => {
    const { blockColors } = this.state;

    let color = '#FFFFFF';
    if (crop.color) {
      if (blockColors[crop.sk] && blockColors[crop.sk][cropDetail.sk]) {
        color = blockColors[crop.sk] && blockColors[crop.sk][cropDetail.sk];
      } else {
        const idx = blockColors.count + 1;// this.genIndex(cropDetail.cultivar) / 100000;
        // color = convertHex(ColorLuminance(crop.color, -0.2 * idx), 70);
        // color = ColorLuminance(crop.color, -0.3 * idx);
        color = randomColor2();// getShade(crop.color, idx * 10);
        const newColors = { ...blockColors };
        if (!newColors[crop.sk]) {
          newColors[crop.sk] = {};
        }
        if (!newColors[crop.sk][cropDetail.sk]) {
          newColors[crop.sk][cropDetail.sk] = {};
        }
        newColors[crop.sk][cropDetail.sk] = color;
        newColors.count = idx;
        this.setState({ blockColors: newColors });
      }
    }
    return color;
  }

  mapBlockDetailsUploaded = async (file) => {
    const { getFromGlobalState, setGlobalState, setSnack } = this.props;
    try {
      const batchInserts = [];
      const activeTenant = getFromGlobalState('activeSite');
      const blockDetailText = await readFileAsText(file.file);
      const blockDetails = parseCSV(blockDetailText);
      const blockDetailsValid = this.validateBlockDetails(blockDetails);
      let hasError = false;
      let messageText = '';
      if (blockDetailsValid.error) {
        setSnack({ open: true, variant: 'error', messageText: blockDetailsValid.messageText });
        return null;
      }
      // get the blocks - and populate the details.
      const blocks = [...getFromGlobalState('blocks')];
      const myCrops = [...getFromGlobalState('crops')];
      if (!blocks || !blocks.length) {
        setSnack({
          open: true,
          variant: 'error',
          messageText: 'No blocks found. first upload your blocks using a google earth KML file or map the blocks directly.',
        });
        return null;
      }
      setSnack({ open: true, variant: 'info', messageText: `Creating ${blockDetails.length} blocks.` });
      // if (blocks.length !== blockDetails.length) {
      //  setSnack({ open: true, variant: 'warning', messageText:
      // `There are ${blocks.length} polygons on the map and ${blockDetails.length} blocks in the csv file.
      // Confirm you have an entry for each mapped block.` });
      // }
      const cropTypes = getFromGlobalState('cropTypesSetting');
      // const irrigations = getFromGlobalState('irrigations');
      for (const item of blockDetails) {
        if (item.Block === '') {
          continue;
        }
        const block = blocks.find((b) => b.number === item.Block);
        if (!block) {
          setSnack({
            open: true,
            variant: 'error',
            messageText: `Block ${item.Block} not found on the map. Please upload this block.`,
          });
          continue;
        }

        block.soilType = item.Soil_Type;
        block.slope = item.Slope;
        // block.irrigated = item.Irrigation_rate_l_p_h && item.Irrigation_rate_l_p_h > 0 ? 'irrigated' : 'dryland';
        block.irrigated = item.Irrigation !== 'Dry land' ? 'irrigated' : 'dryland';
        block.volumePerHour = item.Irrigation_rate_l_p_h;
        block.irrigationSystem = item.Irrigation;
        block.flowunit = item.Plant_or_ha ? item.Plant_or_ha.toLowerCase() : undefined;

        const retVal = await this.createPlantings(item, block, cropTypes);
        if (retVal.error || !retVal.planting) {
          hasError = true;
          messageText = retVal.messageText;
          break;
        }
        if (!block.plantings) {
          block.plantings = [];
        }
        const pIdx = block.plantings.findIndex((p) => p.name === retVal.planting.name);
        const planting = { ...retVal.planting };
        planting.activeTenant = activeTenant;
        if (pIdx === -1) {
          planting.sk = block.sk.concat('_planting_').concat(uuid.v1());
          block.plantings.push(planting);

          batchInserts.push(planting);
        } else {
          planting.sk = block.plantings[pIdx].sk; // grab the previous sk so we overwrite.
          block.plantings[pIdx] = planting;
          batchInserts.push(planting);
        }

        if (!block.size) {
          block.size = 0;
        }

        block.size = parseFloat(block.sizeMap); // += parseFloat(retVal.planting.size);
        block.activeTenant = activeTenant;
        block.color = this.getBlockColor(block, retVal.crop, retVal.cropDetail);
        block.type = 'blocks';
        const { plantings, ...newBlock } = block;
        const bIdx = batchInserts.findIndex((b) => b.sk === block.sk);
        if (bIdx === -1) {
          batchInserts.push(newBlock);
        } else {
          batchInserts[bIdx] = newBlock;
        }

        // add the irrigation to my irrigation.
        // const irrIdx = irrigations.findIndex

        // add the crops to myCrops

        const idx = myCrops.findIndex((i) => i.sk === retVal.crop.sk);
        if (idx === -1) {
          const genCrop = {};
          myCrops.push(retVal.crop);
          genCrop.genCropSK = retVal.crop.sk;
          genCrop.type = 'gen_crops';
          genCrop.sk = `init_setting_${uuid.v1()}`;
          batchInserts.push(genCrop);
        }
        const newCrop = myCrops.find((i) => i.sk === retVal.crop.sk);
        if (!newCrop.varieties) {
          newCrop.varieties = [];
        }
        const vIdx = newCrop.varieties.findIndex((v) => v.sk === retVal.cropDetail.sk);
        if (vIdx === -1) {
          newCrop.varieties.push(retVal.cropDetail);
          const newCropDetail = {};
          newCropDetail.type = 'gen_varieties';
          newCropDetail.genVarietySK = retVal.cropDetail.sk;
          newCropDetail.sk = `${newCrop.sk}_setchild_${uuid.v1()}`;
          // newCropDetail.activeTenant=activeTenant;
          batchInserts.push(newCropDetail);
        }
        { /* else {
        newCrop.varieties[vIdx] = retVal.cropDetail;
       // batchInserts.push(retVal.cropDetail);
      } */ }
      }
      if (!hasError) {
        const valueArr = batchInserts.map((item) => item.sk);

        const lookup = batchInserts.reduce((a, e) => {
          a[e.sk] = ++a[e.sk] || 0;
          return a;
        }, {});

        console.log(batchInserts.filter((e) => lookup[e.sk]));

        const uniqueValues = new Set(batchInserts.map((v) => v.sk));
        if (uniqueValues.size < batchInserts.length) {
          // const b = 'notunique';
        }

        const isDuplicate = valueArr.some((item, idx) => valueArr.indexOf(item) !== idx);
        console.log(isDuplicate);
        const recordsObject = { items: batchInserts };

        recordsObject.activeTenant = getFromGlobalState('activeSite');
        recordsObject.type = 'batchupload';
        await createBatchRecords(
          getFromGlobalState('currentUser').username,
          'calls', toStore(recordsObject, true),
          this.props.getFromGlobalState('activeSite'),
        );
        setGlobalState({ crops: myCrops });
        setGlobalState({ blocks });
        setSnack({ open: true, variant: 'info', messageText: `${blocks.length} blocks created.` });
      } else {
        const msg = `${messageText}. Please rectify the data. Your data has not been saved`;
        setSnack({ open: true, variant: 'error', messageText: msg });
      }
    } catch (e) {
      console.log(e);
      setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    }
  }

  mapFileUploaded = async (file) => {
      if (!file.serverId) {
        console.log('received undefined key');
        return null;
      }
      const { setSnack } = this.props;
      // let farm = {...this.state.farm}
      // farm.farmKml = file.serverId;
      // farm.farmKmlFileType = file.fileType;
      // const farmMapKml = await this.getLogoUrl (farm.farmKml, farm.farmKmlFileType);
      //  const obURL = URL.createObjectURL(files[0]);
      const kmlText = await readFileAsText(file.file);
      const geometries = await parseKML(kmlText);
      if (geometries.errors && geometries.errors.length) {
        setSnack(
          {
            open: true,
            variant: 'error',
            messageText: 'At least one block without a name found. Please correct and upload again.',
          },
        );
      }
      this.insertBlocks(geometries);
      this.setState({ changed: true });
      // this.setState({farm});
    
  }

  calculateHectarage=(polygonCoords)=> {
    const earthRadius = 6371000; 

    const toRadians = (degree) => degree * Math.PI / 180;

    const area = polygonCoords.reduce((accumulator, currentCoord, index, array) => {
        if (index === array.length - 1) return accumulator; 

        const nextCoord = array[index + 1];

        const lat1 = toRadians(currentCoord.lat);
        const lng1 = toRadians(currentCoord.lng);
        const lat2 = toRadians(nextCoord.lat);
        const lng2 = toRadians(nextCoord.lng);

        return accumulator + (lng2 - lng1) * (2 + Math.sin(lat1) + Math.sin(lat2));
    }, 0);

    const absoluteArea = Math.abs(area * earthRadius * earthRadius / 2);
    const hectarage = (absoluteArea / 10000).toFixed(2);
    return hectarage;
}

  insertBlocks = (geometries) => {
    try {
      const { setGlobalState, getFromGlobalState } = this.props;
      const blocks = [...getFromGlobalState('blocks')];
      if (!geometries || !geometries.polygons) {
        return null;
      }

      for (const item of geometries.polygons) {
        const polygon = new window.google.maps.Polygon({ paths: item.googlePolygonsPaths });
        const size = window.google.maps.geometry.spherical.computeArea(polygon.getPath());
        const block = {};
        block.sizeMap = size / 10000;
        block.sizeMap = block.sizeMap.toFixed(4);
        block.number = item.name;
        block.paths = item.googlePolygonsPaths;
        block.size = this.calculateHectarage(item.googlePolygonsPaths)
        // block.color = randomColor();
        block.color = '#d3d3d3';
        block.strokeColor = '#FFFFFF';
        block.sk = `init_block_${uuid.v1()}`;
        const idx = blocks.findIndex((b) => b.number === item.name);
        if (idx === -1) {
          blocks.push({ ...block });
        } else {
          blocks[idx] = block;
        }
      }
      setGlobalState({ blocks });
      this.setState({ mapUploading: false });
    } catch (e) {
      alert(e);
    }
  }

  onCloseMarker() {
    this.setState({ selectedMarker: '' });
  }

  onFileUploadStart() {
    this.setState({ mapUploading: true });
  }

  getColorIcon(color) {
    if (!color) {
      color = 'grey';
    }
    return (
      <div style={{ height: '15px', width: '15px', backgroundColor: color }} />
    );
  }

  getBlockInfo(block) {
    return (
      <>
        <Typography variant="h6">
          {block.number}
        </Typography>
        <Typography variant="subtitle2">{` Size: ${block.size} ha`}</Typography>
        <Typography variant="subtitle2">{` Soil Type: ${block.soilType}`}</Typography>
        <Typography variant="subtitle2">{` Slope: ${block.slope}`}</Typography>
        <Typography variant="subtitle2">
          {` Irrigation: ${block.irrigated === 'irrigated' ? 'Irrigated' : 'Dry Land'}`}

        </Typography>
        <Link href="#" variant="h6" onClick={this.mapEditBlock.bind(this, block.sk)}>
          Edit
        </Link>
      </>
    );
  }

  validateBlockDetails = (blockDetails) => {
    const retVal = { error: false, messageText: '' };
    if (!blockDetails) {
      retVal.error = true;
      retVal.messageText = 'No block details';
    }
    // ensure we have the correct entries - polygons and csv entries.

    // ensure we have all the elements in the file
    // find unique blocks
    const uniqueBlocks = blockDetails.filter((v, i, a) => a.findIndex((t) => (t.Block === v.Block)) === i);
    if (uniqueBlocks.length === blockDetails.length) {
      return retVal;
    }
    for (const ub of uniqueBlocks) {
      const plantings = blockDetails.filter((b) => b.Block === ub.Block && b.Planting === ub.Planting);

      if (plantings.length !== 1) {
        retVal.error = true;
        retVal.messageText = `Block ${ub.Block} contains duplicate planting names: ${ub.Planting} in column Planting. Please make them distinct if you have more than one planting in this block.`;
        break;
      }
    }
    return retVal;
  }

  createCrop = async (crop, crops) => {
    const idx = crops.findIndex((i) => i.sk === crop.sk);
    if (idx === -1) {
      const newCrop = {};
      newCrop.sk = uuid.v1();
    } else {
      return crops[idx];
    }
  }

  createPlantings = async (planting, block, cropTypes) => {
    if (!block || !planting) {
      return;
    }
    const { getFromGlobalState, setGlobalState, setSnack } = this.props;
    const newPlanting = {};
    const cropType = cropTypes.find((c) => c.name === planting.Crop_type);
    if (!cropType) {
      // setSnack({ open: true, variant: 'error', messageText: `Crop type ${planting.Crop_type}  not found in crop type database. Please confirm crop type name for planting ${planting.Planting}` });
      return {
        error: true,
        messageText: `Crop type ${planting.Crop_type} for block ${block.number} and plating ${planting.name}  not found in crop type database. Please confirm crop type name for planting ${planting.Planting}`
      };
    }
    const cropDetails = await getCropDetails(getFromGlobalState, setGlobalState, setSnack, cropType.sk);
    const crop = cropDetails.crops.find((c) => c.name === planting.Crop_name);
    if (!crop) {
    //  setSnack({ open: true, variant: 'error', messageText: `Crop name ${planting.Crop_name}  not found in crops database. Please confirm crop name for planting ${planting.Planting}` });
      return { error: true, messageText: `Crop name ${planting.Crop_name}  not found in crops database. Please confirm crop for block ${block.number} and planting ${planting.Planting}` };
    }
    const cropDetail = cropDetails.cropsDetail.find((c) => c.cultivarType === planting.Cultivar_type && c.cultivar === planting.Cultivar_name && c.rootStockType === planting.Rootstock_type && c.rootStock === planting.Rootstock_name);
    if (!cropDetail) {
      // setSnack({ open: true, variant: 'error', messageText: `Crop name ${planting.Crop_name}, cultivar ${planting.Cultivar_name}, rootstock type ${planting.Rootstock_type}, rootstock name ${planting.Rootstock_name}  not found in crops database. Please confirm these details for planting ${planting.Planting}. Names are case sensitive.` });
      return { error: true, messageText: `Crop name ${planting.Crop_name}, cultivar type ${planting.Cultivar_type}, cultivar name ${planting.Cultivar_name}, rootstock type ${planting.Rootstock_type}, rootstock name ${planting.Rootstock_name}  not found in crops database. Please confirm these details for block ${block.number} planting ${planting.Planting}. Names are case sensitive.` };
    }
    newPlanting.cropType = cropType.sk;

    newPlanting.crop = crop.sk;
    newPlanting.name = planting.Planting;
    if (planting.Size_hectares && parseFloat(planting.Size_hectares.replace(',', '.')) > 0) {
      newPlanting.size = parseFloat(planting.Size_hectares.replace(',', '.'));
      // newPlanting.size = newPlanting.size.toFixed(4);
    }
    
    newPlanting.date = parseCustomDate(planting.Plant_Date);
    newPlanting.variety = cropDetail.sk;
    // newPlanting.harvestDate = new Date(`${planting.Next_Harvest_Date} Z`).toISOString().substr(0, 10);
    newPlanting.plantSpacing = parseFloat(planting.Spacing_Inter_plant_in_m.replace(',', '.'));
    if (isNaN(newPlanting.plantSpacing)) {
      return { error: true, messageText: `Spacing_Inter_plant_in_m required for block ${block.number} planting ${planting.Planting}. Names are case sensitive.` };
    }
    newPlanting.plantDensity = parseFloat(planting.Spacing_Intra_row_in_m.replace(',', '.'));
    if (isNaN(newPlanting.plantDensity)) {
      return { error: true, messageText: `Spacing_Inter_plant_in_m required for block ${block.number} planting ${planting.Planting}. Names are case sensitive.` };
    }
    newPlanting.totalPlants = parseFloat(planting.Total_Plants.replace(',', '.'));
    if (!isNaN(newPlanting.totalPlants)) {
      newPlanting.totalPlantsOverride = true;
    } else {
      newPlanting.totalPlants = numberOfPlants(newPlanting.plantSpacing, newPlanting.plantDensity, (newPlanting.size ? newPlanting.size : block.sizeMap));
    }
    newPlanting.totalPlantsPerHa = parseFloat(planting.Plants_ha.replace(',', '.'));
    if (isNaN(newPlanting.totalPlantsPerHa)) {
      newPlanting.totalPlantsPerHa = newPlanting.totalPlants / (newPlanting.size ? newPlanting.size : block.sizeMap);
    }

    newPlanting.type = 'plantings';
    // newPlanting.cluster = planting.Cluster ? planting.Cluster : null;

    newPlanting.irrigated = block.irrigated;
    newPlanting.volumePerHour = block.volumePerHour;
    newPlanting.irrigationSystem = block.irrigationSystem;
    newPlanting.flowunit = block.flowunit;

    return { planting: newPlanting, crop, cropDetail };
  }

  mapEditBlock(selectedBlockId) {
    const { getFromGlobalState } = this.props;
    const selectedBlock = getFromGlobalState('blocks').find((block) => block.sk === selectedBlockId);
    this.setState({
      selectedBlock, selectedBlockSK: selectedBlockId, blockDialogOpen: 'true', isNew: false,
    });
  }

  render() {
    const {
      classes, farm, getFromGlobalState,
    } = this.props;
    const { infoWindowPosition, mapUploading } = this.state;
    // const blocks = initialState;
    const userDetail = getFromGlobalState('userDetail');
    const blocks = getFromGlobalState('blocks');
    if (!farm[0]) {
      return null;
    }
    if (!blocks) {
      return null;
    }

    const headCells = [
      {
        id: 'color', numeric: true, label: '', disablePadding: true, maxWidth: '10px',
      },
      {
        id: 'number', combine: [{ id: 'number', opacity: 1 }, { id: 'label', opacity: 0.6 }], numeric: false, disablePadding: false, label: 'Number',
      },
      // { id: 'reason', numeric: false, disablePadding: false, label: 'Reason', maxWidth:'70px'},
      {
        id: 'size', numeric: true, disablePadding: false, label: 'Size (ha)',
      },
    ];

    const rows = blocks.map((block) => {
      const ob = { ...block };
      ob.label = `${ob.slope} slope, ${ob.soilType} soil, ${(block.irrigated ? (block.irrigated === 'irrigated' ? `${block.volumePerHour}l/hour` : 'Dry land') : `${block.volumePerHour}l/hour`)}`;
      ob.color = this.getColorIcon(ob.color);
      return ob;
    });
    const blockPaths = [];
    let infoWindow = null;
    for (const block of blocks) {
      if (block.paths) {
        let strokeColor = block.color;
        let strokeWeight = 1;
        if (block.sk === this.state.selectedMapBlock) {
          infoWindow = { label: block.number, position: infoWindowPosition, content: this.getBlockInfo(block) };
          strokeColor = 'black';
          strokeWeight = 3;
        }
        blockPaths.push({
          label: block.number, sk: block.sk, paths: block.paths, color: block.color, strokeColor, strokeWeight,
        });
      }
    }
    const center = geoStringToLatLong(farm[0].geoLocation) ? geoStringToLatLong(farm[0].geoLocation) : this.state.userLocation;

    return (

      <Paper elevation={0} className={classes.mainSection}>

        {(this.state.blockDialogOpen)
          && (
          <BlockD
            handleUpdateBlocks={this.handleUpdateBlocks.bind(this)}
            handleDeleteBlock={this.handleDeleteBlock.bind(this)}
            allowDelete
            open={this.state.blockDialogOpen}
            setClose={this.setClose.bind(this)}
            isNew={this.state.isNew}
            block={this.state.selectedBlock}
            otherBlockPolygons={blockPaths}
            metaData={this.props.getFromGlobalState('irrigations')}
            farm={this.props.getFromGlobalState('farm')}
            getFromGlobalState={this.props.getFromGlobalState}
            userLocation={this.state.userLocation}
          />
          )}
        <InfoBox message="You can add your blocks by selecting the add block button below. You will be able to
           map your blocks. You can also import a KML file from google earth if you have already mapped them. Please ensure that the block name is used for the polygon name. The block details can be imported using a csv template."
        />
        <Load isloading={mapUploading} style={{ width: '100%', height: '500px' }}>
          <Map
            center={center}
            // zoom={16}
            polygons={blockPaths}
            onPolygonClick={this.onMapBlockClick.bind(this)}
            onCloseInfoWindow={this.onCloseInfoWindow.bind(this)}
            infoWindow={infoWindow}
            onClick={this.onMapClick.bind(this)}
          />
        </Load>

        <InputLabel shrink htmlFor="kmlUploader">Import KML file</InputLabel>

        <FileUploader
          activeTenant={this.props.getFromGlobalState('activeSite')}
          generateThumbnail={false}
          id="kmlUploader2"
          generatePreview={false}
        // maxFileSize={'350KB'}
          onFileUploaded={this.mapFileUploaded.bind(this)}
          folder="maps/"
          getFromGlobalState={this.props.getFromGlobalState}
          allowMultiple={false}
          forceName
         // onFileUploadStart={this.onFileUploadStart.bind(this)}
          fileName="farmKml.kml"
          // allowFileTypeValidation
          error={this.state.error}
          acceptedFileTypes={['application/vnd.google-earth.kml+xml','text/xml','kml']}

        />
        <InputLabel shrink htmlFor="csvUploader">Import Block details using a .csv file</InputLabel>
        <FileUploader
          activeTenant={this.props.getFromGlobalState('activeSite')}
          generateThumbnail={false}
          id="csvUploader"
          generatePreview={false}
              // maxFileSize={'350KB'}
          onFileUploaded={this.mapBlockDetailsUploaded.bind(this)}
          folder="blockdetails/"
          getFromGlobalState={this.props.getFromGlobalState}
          allowMultiple={false}
          forceName
          fileName="blockdetails.csv"
          allowFileTypeValidation
          acceptedFileTypes={['text/csv', 'application/vnd.ms-excel']}
        />

        <EnhancedTable
          displayToolbar
          id="blockTable"
          heading=""
          noPagination
          rows={rows}
          headCells={headCells}
          handleSelect={this.handleClickShowBlock.bind(this)}
          handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handleClickAdd.bind(this) : null}
                         // parent={}
          actionType="actionType"
          rowKeyName="sk"
                          // disablePadFirstCol
          clean
          maxHeight="350px"
                          // dense = {showDetail ? true : false}
          selectedRow={this.state.selectedinventoryItemSK}
        />

      </Paper>
    );
  }
}
BlockDetailForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  initialState: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  farm: PropTypes.array.isRequired,

};
export default withStyles(styles)(BlockDetailForm);
