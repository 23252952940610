import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Typography } from '@material-ui/core';

import LoaderButton from '../../components/LoaderButton';
// import "./ResetPassword.css";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1),
  },
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      email: '',
      password: '',
      codeSent: false,
      confirmed: false,
      confirmPassword: '',
      isConfirming: false,
      isSendingCode: false,
      isNotValid: false,
      error: '',
      showPassword: false,
    };
  }

  validateCodeForm() {
    return this.state.email.length > 0;
  }

  validateResetForm() {
    if (this.state.code.length <= 0) return false;
    let isNotValid = true;
    let error = '';
    if (this.state.password.length < 8 || !this.state.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$/)) {
      error = 'Password guidance. Minimum length 8, must contain uppercase, lowercase and special characters';
      isNotValid = true;
    } else {
      error = '';
      isNotValid = false;
    }

    return (

      {
        isNotValid: !isNotValid && this.state.code.length > 0
      && this.state.password.length > 0
      && this.state.password === this.state.confirmPassword,
        error,
      }
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  showPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  handleSendCodeClick = async (event) => {
    event.preventDefault();

    this.setState({ isSendingCode: true });

    try {
      await Auth.forgotPassword(this.state.email.toLowerCase());
      this.setState({ codeSent: true });
    } catch (e) {
      alert(e.message);
      this.setState({ isSendingCode: false });
    }
  };

  handleConfirmClick = async (event) => {
    event.preventDefault();

    this.setState({ isConfirming: true });

    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.code,
        this.state.password,
      );
      this.setState({ confirmed: true });
    } catch (e) {
      alert(e.message);
      this.setState({ isConfirming: false });
    }
  };

  renderRequestCodeForm(classes) {
    return (
      <form onSubmit={this.handleSendCodeClick}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={this.state.email}
          onChange={this.handleChange}
        />

        <LoaderButton
          type="submit"
            // fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!this.validateCodeForm()}
          isloading={this.state.isSendingCode}
          text="Send Confirmation"
          loadingText="Sending…"
        />

      </form>
    );
  }

  renderConfirmationForm(classes) {
    const validation = this.validateResetForm();
    const { showPassword } = this.state;
    return (
      <form onSubmit={this.handleConfirmClick}>
        <Typography gutterBottom color="secondary">An email containing your confirmation code was sent to the address provided. Please enter that code below.</Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="code"
          label="Confirmation Code"
          name="code"
          autoFocus
          value={this.state.code}
          onChange={this.handleChange}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={this.showPassword}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
            type: showPassword ? 'text' : 'password',
          }}
          id="password"
           // autoComplete="current-password"
          value={this.state.password}
          onChange={this.handleChange}
        />
        <Typography variant="subtitle2">{validation.error}</Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={this.showPassword}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
            type: showPassword ? 'text' : 'password',
          }}
          id="confirmPassword"
          value={this.state.confirmPassword}
          onChange={this.handleChange}
        />

        <LoaderButton
          type="submit"
            // fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!validation.isNotValid}
          isloading={this.state.isConfirming}
          text="Confirm"
          loadingText="Confirm…"
        />
      </form>
    );
  }

  renderSuccessMessage() {
    return (
      <div className="success">
        {/* } <Glyphicon glyph="ok" /> */}
        <p>Your password has been reset.</p>
        <p>
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="ResetPassword">
        {!this.state.codeSent
          ? this.renderRequestCodeForm(classes)
          : !this.state.confirmed
            ? this.renderConfirmationForm(classes)
            : this.renderSuccessMessage()}
      </div>
    );
  }
}
export default withStyles(styles)(ResetPassword);
