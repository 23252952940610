import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Location from '../../components/Location';
import OperatorSelector from '../../components/OperatorSelector';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
});

const getTotal = (harvestEquips, packEquip, units) =>{
  if (!harvestEquips) {return 0;}
  if (!packEquip) {return 0;}
  if (!units) {return 0;}

  let equip = harvestEquips.find(p=>p.sk===packEquip);
  if (!equip) {return undefined;}
  return units*equip.capacity;
};

function HarvestingForm(props) {
  const { classes,
    record,
    errors,
    handleBlur,
    handleChange,
    metaData,
    overrideTotalHarvest,
    handleLocationChange,
    setSnack} = props;
  const harvestEquips = metaData.harvestEquips;
  if (!record.totalHarvestOverride){
    record.totalHarvestOverride = false;
    if (record.harvestEquip &&record.harvestUnits ){
    record.harvestTotal=getTotal(harvestEquips,record.harvestEquip,record.harvestUnits);
    }
  }
  return ( 
    <Grid container className={classes.root} spacing={1} alignItems="flex-start" >
       <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
    margin="normal"
    required
    //fullWidth
    type="date"
    //defaultValue={new Date()}
    id="date"
    label="Harvest Date"
    name="date"
    InputLabelProps={{
      shrink: true,
    }}
    value={record.date}
    onChange={handleChange.bind(this)}
    onBlur={handleBlur}
    error={errors.date ? true : undefined}
    helperText={errors.date}
  />
  </Grid>
     <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
       <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="harvestEquip">Harvest Unit</InputLabel>
          <Select
          native
          fullWidth
          required
          displayEmpty
          value={record.harvestEquip}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur.bind(this)}
          error={errors.harvestEquip? true : undefined}
          inputProps={{
            name: 'harvestEquip',
            id: 'harvestEquip',
          }}
        >
          <option value=""></option>
          {harvestEquips && harvestEquips.map ((heq) => 
          <>
          <option value={heq.sk}>{heq.name}</option>
          </>
     )}
        </Select>
        {errors.harvestEquip&& <FormHelperText className={classes.helperText}>{errors.harvestEquip}</FormHelperText>}
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
        margin="normal"
        required
        //fullWidth
        style={{minWidth:'200px'}}
        type="number"
        inputProps={{ 'min': '0.00001', 'step':'any' }}
        id="harvestUnits"
        label="Total Units"
        name="harvestUnits"
        value={record.harvestUnits}
        onChange={handleChange.bind(this)}
        onBlur={handleBlur.bind(this)}
        error={errors.harvestUnits ? true : undefined}
        helperText={errors.harvestUnits}
      /> 
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="harvestTotal">Total harvest in Kg</InputLabel>
       <TextField
        margin="normal"
        required
        fullWidth
        type="number"
        inputProps={{ 'min': '0.00001', 'step':'any' }}
        id="harvestTotal"
        label=""
        name="harvestTotal"
        value={record.harvestTotal}
        onChange={handleChange.bind(this)}
        disabled={!record.totalHarvestOverride}
        onBlur={handleBlur.bind(this)}
        error={errors.harvestTotal ? true : undefined}
        helperText={errors.harvestTotal}
      />
      </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <FormControl className={classes.formControl}>
          <FormControlLabel value='harvestOverride' 
          control={<Radio color="secondary" name='totalHarvestOverride' 
          checked={record.totalHarvestOverride} 
          id='totalHarvestOverride'
          onClick={overrideTotalHarvest} />} 
          label="Override" />
          </FormControl>
          </Grid>
     
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Location
          onChange={handleLocationChange.bind(this)}
          value={record.geoLocation}
          onBlur={handleBlur.bind(this)}
          id="geoLocation"
          required
          name="geoLocation"
          setSnack={setSnack}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OperatorSelector
            onChange={handleChange.bind(this)}
            operators={metaData.operators}
            title="Operator"
            value={record.operator}
            team={record.team}
            onBlur={handleBlur}
            error={errors.operator}
          />
        </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
        margin="normal"
        fullWidth
        type="text"
        id="deliveryNote"
        label="Delivery Note"
        name="deliveryNote"
        value={record.deliveryNote}
        onChange={handleChange.bind(this)}
        onBlur={handleBlur.bind(this)}
        error={errors.deliveryNote ? true : undefined}
        helperText={errors.deliveryNote}
      /> 
          </Grid> 
 
  <Grid item xs={4} sm={4} md={8} lg={8} xl={8}></Grid>

  </Grid>
  );
}

HarvestingForm.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
  overrideTotalHarvest: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
};

export default withStyles(styles)(HarvestingForm);
