import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';

function hashCode(str) { // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  // eslint-disable-next-line no-bitwise
  const c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();

  return `#${'00000'.substring(0, 6 - c.length)}${c}`; // return '#' + "00000".substring(0, 6 - c.length) + c;
}

function getColor(name, key) {
  const nameSplit = name.split(' ');
  const initials = nameSplit[0].charAt(0).toUpperCase() + nameSplit[1].charAt(0).toUpperCase();
  return { color: intToRGB(hashCode(key)), initials };
}
export default function AutoAvatar(props) {
  const { name, userKey } = props;
  const color = getColor(name, userKey);
  return (

    <Avatar style={{ backgroundColor: color.color }}>
      {' '}
      {color.initials}
      {' '}
    </Avatar>

  );
}
AutoAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  userKey: PropTypes.string.isRequired,

};
