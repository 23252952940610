import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
});

const requiredFields = [
  { name: 'cultivar', min: 1, max: 40 },
];
class VarietyD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      variety: {},
      errors: { count: 0 },
      varieties: [],
      changed: false,
      
    
    };
  }
  
  componentDidMount() {
    if (this.props.isNew){
      this.setState({ variety: {} });
    }
    else {
      this.setState({ variety: this.props.variety });
    }
  }


  validateField (field, requiredFields)
  {
    let error = validate(field, this.state.variety, requiredFields)
    let errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors: errors }); 
  }

  handleBlur = (event) => {
    return this.validateField (event.target.id, requiredFields);
  }


  handleChange = (event) => {
    let variety = { ...this.state.variety };
    variety[event.target.id] = event.target.value; 
    this.setState({ variety: variety,changed: true });
  }
  
  render() {
    const { classes, open, isNew, setClose, handleUpdate, parentSK,parent, getFromGlobalState } = this.props;
    const { variety, errors } = this.state;
    const formValid = validateForm(variety, requiredFields);
    
    return (
 
      <GenDialog
      open={open}
      dialogObject={variety}
      setClose={setClose}
      isNew={isNew}
      handleUpdateDialogObject={handleUpdate.bind(this)}
      skPrimary="_setchild_"
      dynamoType="varieties"
      valid={formValid}
      disableActions={variety.isGeneral}
      changed={this.state.changed}
      newTitle='New Cultivar'
      title={variety.cultivar ? variety.cultivar : ''}
      subTitle={parent ? parent.name : '' }
      parentKey={parentSK}
      getFromGlobalState={getFromGlobalState}
      >
         
          <TextField
            margin="normal"
            required
            fullWidth
            id="cultivar"
            label="Cultivar"
            name="cultivar"
            
            value={variety.cultivar}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.cultivar ? true : undefined}
            helperText={errors.cultivar}
          />
         
         

      </GenDialog>
     
    );
  }
}
VarietyD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  variety: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  parentSK: PropTypes.string.isRequired,
  settings: PropTypes.object,
  parent: PropTypes.object.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(VarietyD);
