import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: { main: '#0e4553' },
    secondary: { main: '#63c7c8' },
  },
  typography: {
    useNextVariants: true,
  },
});
export default theme;
