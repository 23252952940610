import React from 'react';
import PropTypes from 'prop-types';
import EnhancedTable from '../../components/Etable';
import settings from '../../libs/settings';
import {getData} from '../../libs/analytics';

const RECENTS_LEN=settings.constants.RECENTS_LEN;
 
  
 
export default function Load(props) {
  const {recordType,planting,handleSelect,getFromGlobalState,block,clean, displayToolbar,noPagination,hideHeader,handleAddItem,selectedRow,showAll } = props;
  let data =  getData(recordType,planting,block, getFromGlobalState,clean,showAll,RECENTS_LEN,undefined, undefined, undefined, undefined, undefined, undefined,true);

  const userDetail = getFromGlobalState('userDetail');

  return (
    <div>
     {/* {!clean && <ReportHeader block={block} planting={planting} farm={farm} getFromGlobalState={getFromGlobalState}></ReportHeader>}*/}
   
    <EnhancedTable 
              displayToolbar={displayToolbar}
              noPagination={noPagination}
              hideHeader={hideHeader}
              heading={data.title}
              rows={showAll ? data.rows: data.mostRecent} 
              headCells={data.headCells}
              handleSelect={handleSelect}
              handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? handleAddItem : null}
              parent={planting}
              grandParentSK={block.sk} 
              actionType='actionType'
              rowKeyName='sk'
              recordType={recordType}
              clean={clean}
              selectedRow={selectedRow}
              />
     </div>
  );
}
Load.propTypes = {
    isloading: PropTypes.bool.isRequired,
    children: PropTypes.any,
    recordType:PropTypes.string.isRequired,
    planting:PropTypes.array.isRequired,
    block:PropTypes.array.isRequired,
    farm:PropTypes.any,
    handleSelect:PropTypes.func.isRequired,
    clean:PropTypes.bool,
    displayToolbar:PropTypes.bool,
    noPagination:PropTypes.bool,
    hideHeader:PropTypes.bool,
    handleAddItem:PropTypes.func,
    selectedRow:PropTypes.string,
    showAll:PropTypes.bool,
    noPagination:PropTypes.bool,
};
  