import { API, Storage } from 'aws-amplify';
import uuid from 'uuid';
import { Service } from './offlineService';

const TIMEOUT = 30000; // ms
const TIMEOUTSTR = 'timeout of 7000ms exceeded';
const params = {
  timeout: TIMEOUT,
};

export async function getFile(url) {
  const result = await Storage.get(url, { download: true });
  // data.Body is a Blob
  result.Body.text().then((string) => string);
}

export async function fromStore(object) {
  const blocks = object.filter((record) => record.type === 'blocks');
  const plantings = object.filter((block) => block.type === 'plantings');
  const waterings = object.filter((block) => block.type === 'waterings');
  const pestings = object.filter((block) => block.type === 'pestings');
  const fertilisings = object.filter((block) => block.type === 'fertilisings');
  const sprayings = object.filter((block) => block.type === 'sprayings');
  const harvestings = object.filter((block) => block.type === 'harvestings');
  const packings = object.filter((block) => block.type === 'packings');
  const activities = object.filter((block) => block.type === 'activities');
  const allFarms = object.filter((block) => block.type === 'allFarms');

  // set our blocks
  blocks.forEach((block) => {
    block.plantings = plantings.filter((record) => record.sk.startsWith(block.sk));
    if (!block.plantings) {
      block.plantings = [];
    }
    plantings.forEach((planting) => {
      const skString = planting.sk;
      planting.waterings = waterings && waterings.filter((record) => record.sk.startsWith(skString));
      planting.pestings = pestings && pestings.filter((record) => record.sk.startsWith(skString));
      planting.fertilisings = fertilisings && fertilisings.filter((record) => record.sk.startsWith(skString));
      planting.sprayings = sprayings && sprayings.filter((record) => record.sk.startsWith(skString));
      planting.harvestings = harvestings && harvestings.filter((record) => record.sk.startsWith(skString));
      planting.packings = packings && packings.filter((record) => record.sk.startsWith(skString));
      planting.activities = activities && activities.filter((record) => record.sk.startsWith(skString));
    });
  });

  //
  // set our crop settings
  let crops = object.filter((record) => record.type === 'crops');
  const varieties = object.filter((record) => record.type === 'varieties');
  if (!crops) {
    crops = {};
  }

  crops.forEach((crop) => {
    crop.varieties = varieties.filter((record) => record.sk.startsWith(crop.sk));
    if (!crop.varieties) {
      crop.varieties = [];
    }
  });

  let myGenCrops = object.filter((record) => record.type === 'gen_crops');
  let genVarieties = object.filter((record) => record.type === 'gen_varieties');
  if (!myGenCrops) {
    myGenCrops = [];
  }
  if (!genVarieties) {
    genVarieties = [];
  }

  // set our farm settings
  let farm = object.filter((record) => record.type === 'farm');
  if (!farm) {
    farm = [];
    farm.push({});
  }
  // if (farm && farm.length === 0){
  //  farm.push({});
  // }

  // set our chemicals
  // let chemicals = object.filter(record => record.type === 'chemicals');

  // if (!chemicals) {
  //  chemicals=[];
  // }
  // set our chemical settings
  let chemicals = object.filter((record) => record.type === 'chemicals');
  const chemcrops = object.filter((record) => record.type === 'chemcrops');
  const chemcropsTargets = object.filter((record) => record.type === 'chemcropsTargets');
  if (!chemicals) {
    chemicals = {};
  }

  chemicals.forEach((chemical) => {
    chemical.chemcrops = chemcrops.filter((record) => record.sk.startsWith(chemical.sk));
    if (!chemical.chemcrops) {
      chemical.chemcrops = [];
    }
    for (let c = 0; c < chemical.chemcrops.length; c += 1) {
      if (!chemical.chemcrops[c].targets) {
        chemical.chemcrops[c].targets = [];
      }
      chemical.chemcrops[c].targets = chemcropsTargets.filter((record) => record.sk.startsWith(chemical.chemcrops[c].sk));
    }
  });

  // set our chemicalEquipment
  let chemequips = object.filter((record) => record.type === 'chemequips');

  if (!chemequips) {
    chemequips = [];
  }

  // set our harvesting equips
  let harvestequips = object.filter((record) => record.type === 'harvestequips');

  if (!harvestequips) {
    harvestequips = [];
  }

  // set our clusters
  let clusters = object.filter((record) => record.type === 'clusters');

  if (!clusters) {
    clusters = [];
  }

  // set our harvesting equips
  let farms = object.filter((record) => record.type === 'farms');

  if (!farms) {
    farms = [];
  }

  // set our packing equips
  let packequips = object.filter((record) => record.type === 'packequips');

  if (!packequips) {
    packequips = [];
  }
  // set our operators
  let operators = object.filter((record) => record.type === 'operators');

  if (!operators) {
    operators = [];
  }

  // set our agronomistAct
  let agronomistActs = object.filter((record) => record.type === 'agronomistActs');

  if (!agronomistActs) {
    agronomistActs = [];
  }

  // set our fertilisers
  let fertilisers = object.filter((record) => record.type === 'fertilisers');
  const fertcrops = object.filter((record) => record.type === 'fertcrops');
  if (!fertilisers) {
    fertilisers = {};
  }

  fertilisers.forEach((fertiliser) => {
    fertiliser.fertcrops = fertcrops.filter((record) => record.sk.startsWith(fertiliser.sk));
    if (!fertiliser.fertcrops) {
      fertiliser.fertcrops = [];
    }
  });
  // set our pests
  let pests = object.filter((record) => record.type === 'pests');

  if (!pests) {
    pests = [];
  }
  // set our monitorings
  let monitorings = object.filter((record) => record.type === 'monitorings');

  if (!monitorings) {
    monitorings = [];
  }

  let facilities = object.filter((record) => record.type === 'facilities');

  if (!facilities) {
    facilities = [];
  }

  // set our traget types
  let targetsTypesSetting = object.filter((record) => record.type === 'targetsTypesSetting');
  targetsTypesSetting = targetsTypesSetting && targetsTypesSetting.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: false }));

  if (!targetsTypesSetting) {
    targetsTypesSetting = [];
  }

  let cropTypesSetting = object.filter((record) => record.type === 'cropTypesSetting');
  cropTypesSetting = cropTypesSetting && cropTypesSetting.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: false }));

  if (!cropTypesSetting) {
    cropTypesSetting = [];
  }
  let cropsSetting = object.filter((record) => record.type === 'cropsSetting');
  cropsSetting = cropsSetting && cropsSetting.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: false }));

  if (!cropsSetting) {
    cropsSetting = [];
  }

  // set our product processor types types
  let productProcessorTypes = object.filter((record) => record.type === 'productProcessorTypes');
  productProcessorTypes = productProcessorTypes && productProcessorTypes.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: false }));
  if (!productProcessorTypes) {
    productProcessorTypes = [];
  }

  // set our product processor types types
  let productProcessors = object.filter((record) => record.type === 'productProcessors');
  productProcessors = productProcessors && productProcessors.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: false }));
  if (!productProcessors) {
    productProcessors = [];
  }

   // set our combinations
   let combinations = object.filter((record) => record.type === 'combinations');
   combinations = combinations && combinations.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: false }));
   if (!combinations) {
    combinations = [];
   }

  // set our product processor types types
  let filters = object.filter((record) => record.type === 'filters');
  filters = filters && filters.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: false }));
  if (!filters) {
    filters = [];
  }

  // set my product processor types types
  let myProductProcessors = object.filter((record) => record.type === 'myProductProcessors');
  myProductProcessors = myProductProcessors && myProductProcessors.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: false }));
  if (!myProductProcessors) {
    myProductProcessors = [];
  }

  /// //////
  // set our crop settings

  const monitorStations = object.filter((record) => record.type === 'monitorStations');

  monitorings.forEach((monitoring) => {
    monitoring.monitorStations = monitorStations.filter((record) => record.sk.startsWith(monitoring.sk));
    if (!monitoring.monitorStations) {
      monitoring.monitorStations = [];
    }
  });
  /// //////

  let monitoringsOther = object.filter((record) => record.type === 'monitoringsOther');

  if (!monitoringsOther) {
    monitoringsOther = [];
  }

  //
  const monitorOtherStations = object.filter((record) => record.type === 'monitorOtherStations');

  monitoringsOther.forEach((monitoringOther) => {
    monitoringOther.monitorStations = monitorOtherStations.filter((record) => record.sk.startsWith(monitoringOther.sk));
    if (!monitoringOther.monitorStations) {
      monitoringOther.monitorStations = [];
    }
  });
  //

  // set our irrigations
  let irrigations = object.filter((record) => record.type === 'irrigations');

  if (!irrigations) {
    irrigations = [];
  }

  // set our api keys
  let apiKeys = object.filter((record) => record.type === 'apiKeys');

  if (!apiKeys) {
    apiKeys = [];
  }

  // set our chemical inventory

  let inv_chemical = object.filter((record) => record.type === 'inv_chemical');
  if (!inv_chemical) {
    inv_chemical = [];
  }

  // set our fertiliser inventory

  let inv_fertiliser = object.filter((record) => record.type === 'inv_fertiliser');
  if (!inv_fertiliser) {
    inv_fertiliser = [];
  }

  // set our users
  let users = object.filter((record) => record.type === 'users');
  if (!users) {
    users = [];
  }

  // set our folders
  let folders = object.filter((record) => record.type === 'folders');
  if (!folders) {
    folders = [];
  }

  let files = object.filter((record) => record.type === 'files');
  if (!files) {
    files = [];
  }

  let invites = object.filter((record) => record.type === 'invite');
  if (!invites) {
    invites = [];
  }

  let sentInvites = object.filter((record) => record.type === 'SentInvite');
  if (!sentInvites) {
    sentInvites = [];
  }

  let sentSharedInvites = object.filter((record) => record.type === 'SentSharedInvite');
  if (!sentSharedInvites) {
    sentSharedInvites = [];
  }
  
  console.log("this are invites->",invites, sentInvites, sentSharedInvites);

  let siteList = object.filter((record) => record.type === 'siteAccess');
  if (!siteList) {
    siteList = [];
  }

  let activeSite = null;
  if (siteList.length > 0) {
    const activeSiteOb = siteList.find((s) => s.default);
    if (activeSiteOb) {
      activeSite = activeSiteOb.tenantId;
    } else {
      activeSite = siteList[siteList.length - 1].tenantId;
    }
  } else if (object.filter((record) => record.type === 'farm')[0]) {
    activeSite = object.filter((record) => record.type === 'farm')[0].activeTenant;
  }

  // also add the default site.
  // const defaults = {sk: this.state.currentUser.username, activeSite};
  // await Service.putFlat(this.state.currentUser.username, 'defaults', defaults);

  return {
    blocks,
    crops,
    farm,
    chemicals,
    fertilisers,
    pests,
    monitorings,
    monitoringsOther,
    irrigations,
    chemequips,
    harvestequips,
    clusters,
    packequips,
    operators,
    agronomistActs,
    inv_chemical,
    inv_fertiliser,
    users,
    farms,
    invites,
    siteList,
    activeSite,
    sentInvites,
    sentSharedInvites,
    folders,
    files,
    myGenCrops,
    genVarieties,
    targetsTypesSetting,
    cropTypesSetting,
    cropsSetting,
    facilities,
    productProcessorTypes,
    productProcessors,
    myProductProcessors,
    filters,
    apiKeys,
    combinations,
    allFarms
  };
}

export function toStore(object, batch) {
  if (batch) {
    const { ...newObject } = object;
    return newObject;
  }
  switch (object.type) {
    case 'blocks': {
      const { plantings, ...newObject } = object;
      return newObject;
    }
    case 'plantings': {
      const {
        waterings, pestings, fertilisings, sprayings, harvestings, ...newObject
      } = object;
      return newObject;
    }
    /* case 'crops': {
      const newObject = (({ name, sk, type, version }) => ({ name, sk, type, version }))(object);
      //const { crops,sk,type, ...newObject } = object;
      return newObject;

    }
    case 'varieties': {
      const newObject = (({ name, sk, type, version }) => ({ name, sk, type, version }))(object);
      //const { crops,sk,type, ...newObject } = object;
      return newObject;

    } */
    case 'farm': { // todo: filter out what we don't need
      const { ...newObject } = object;
      return newObject;
    }
    case 'chemicals': { // todo: filter out what we don't need
      const { ...newObject } = object;
      return newObject;
    }
    case 'chemequips': { // todo: filter out what we don't need
      const { ...newObject } = object;
      return newObject;
    }
    case 'fertilisers': { // todo: filter out what we don't need
      const { ...newObject } = object;
      return newObject;
    }
    case 'pests': { // todo: filter out what we don't need
      const { ...newObject } = object;
      return newObject;
    }
    case 'monitorings': { // todo: filter out what we don't need
      const { ...newObject } = object;
      return newObject;
    }
    case 'irrigations': { // todo: filter out what we don't need
      const { ...newObject } = object;
      return newObject;
    }
    default: {
      const { ...newObject } = object;
      return newObject;
    }
  }
}

export function updateBatchStateRecord(dialogObject, parentSK, getFromGlobalState, setGlobalState) {
  // see if we have the block

  const blocks = getFromGlobalState('blocks');
  // we only have inserts here.
  for (const item of dialogObject.items) {
    const updatedBlockIndex = blocks.findIndex((i) => i.sk === item.block);
    if (updatedBlockIndex === -1) {
      alert("impossible - we don't have this block!");
      return;
    }

    const { plantings } = blocks[updatedBlockIndex];

    const updatedPlantingIndex = blocks[updatedBlockIndex].plantings.findIndex((i) => i.sk === item.planting);

    if (!blocks[updatedBlockIndex].plantings[updatedPlantingIndex][item.record.type]) {
      blocks[updatedBlockIndex].plantings[updatedPlantingIndex][item.record.type] = [];
    }
    blocks[updatedBlockIndex].plantings[updatedPlantingIndex][item.record.type].push(item.record);
  }
  setGlobalState({ blocks });
}

export function updateStateRecord(recordIn, parentSK, getFromGlobalState, setGlobalState, selectedBlockSK, selectedPlantingSK) {

  const blocks = getFromGlobalState('blocks');
  const updatedBlockIndex = blocks.findIndex((i) => i.sk === selectedBlockSK);
  if (updatedBlockIndex === -1) {
    alert("impossible - we don't have this block!");
    return;
  }
  const { plantings } = blocks[updatedBlockIndex];

  const updatedPlantingIndex = blocks[updatedBlockIndex].plantings.findIndex((i) => i.sk === selectedPlantingSK);

  const recordsIn = plantings[updatedPlantingIndex][recordIn.type];
  const recordsIndex = (recordsIn ? plantings[updatedPlantingIndex][recordIn.type].findIndex((i) => i.sk === recordIn.sk) : -1);

  if (recordsIndex === -1) {
    if (!recordsIn) {
      blocks[updatedBlockIndex].plantings[updatedPlantingIndex][recordIn.type] = [];
    }

    if((recordIn.items||[])?.length >=1){
      recordIn.items.filter((x)=> x.type === recordIn.type).map((x)=>{
        blocks[updatedBlockIndex].plantings[updatedPlantingIndex][recordIn.type].push(x)
      })
      console.log("new records ->",blocks[updatedBlockIndex].plantings[updatedPlantingIndex][recordIn.type])
    }else{
      blocks[updatedBlockIndex].plantings[updatedPlantingIndex][recordIn.type].push(recordIn);
    }
    setGlobalState({ blocks });
  } else {
    blocks[updatedBlockIndex].plantings[updatedPlantingIndex][recordIn.type][recordsIndex] = recordIn;
    setGlobalState({ blocks });
  }
}

async function replayCache(localStorageName, objectStore, tenant) {
  console.log('replaying cache');
  let currentItem = {};
  try {
    const recordsToUpdate = await Service.getAll(localStorageName, objectStore, tenant);

    // eslint-disable-next-line no-restricted-syntax
    if (recordsToUpdate && recordsToUpdate.records) {
      for (const item of recordsToUpdate.records) {
      // eslint-disable-next-line no-await-in-loop
        if (!item.payload.syncError) {
          currentItem = item;
          item.payload.cached = false;
          item.payload.syncError = false;
          item.payload.syncMessage = '';
          const retVal = await API[item.method](item.api, item.route, {
            body: item.payload,
            timeout: item.params.timeout,
          });
          // now delete this item from cache.
          // eslint-disable-next-line no-await-in-loop
          // update local copy - not cached anymore
          await Service.put(localStorageName, 'farms', item.payload, tenant);
          // delete from cache
          await Service.delete(localStorageName, objectStore, item, tenant);
        }
      }
    }
  } catch (e) {
    if (e.message === 'Network Error' || e.message === TIMEOUTSTR) {
      console.log('Synch failed - network');
      return;
    }
    if (e.response && e.response.data && e.response.data.error.substring(0, 13) === 'Update failed') {
      console.log('Synch failed - conflict');
      currentItem.payload.cached = true;
      currentItem.payload.syncError = true;
      currentItem.syncError = true;
      currentItem.syncMessage = e.response.data.error;

      await Service.put(localStorageName, objectStore, currentItem, tenant);
      e.response.data.error = `Syncronisation failure: ${e.response.data.error}`;
      e.message = 'Syncronisation failure';
      const newObj = { ...currentItem.payload };
      newObj.cached = true;
      newObj.syncError = true;
      await Service.put(localStorageName, 'farms', newObj, tenant);
      throw (e);
    } else {
      console.log('replayCache exception caught', e);
      if (e.response && e.response.data.error.message) {
        e.response.data.error.message = `Syncronisation failure: ${e.response.data.error.message}`;
      } else if (e.message) {
        e.message = `Syncronisation failure: ${e.message}`;
      }
      throw (e);
    }
  }
}

export async function createObject(localStorageName, objectStore, dialogObject, tenant) {
  console.log('api post from Storage');
  let retVal = { status: false, online: true };
  try {
    await replayCache(localStorageName, 'calls', tenant);
    if (dialogObject.type === 'apiKeys') {
      await API.post('farms', '/apiKey', {
        body: dialogObject,
        timeout: TIMEOUT,
      });
    } else {
      await API.post('farms', '/farms', {
        body: dialogObject,
        timeout: TIMEOUT,
      });
    }
    await Service.put(localStorageName, 'farms', dialogObject, tenant);
    retVal = { status: true, online: true, cached: false };
  } catch (e) {
    console.log('caught here');
    if (e.message === 'Network Error' || e.message === TIMEOUTSTR) {
      console.log('failed to create object, storing request locally');
      const requestObject = {
        stamp: new Date().valueOf(), sk: uuid.v1(), method: 'post', api: 'farms', route: '/farms', params, syncError: false, syncMessage: '', payload: dialogObject,
      };
      await Service.put(localStorageName, objectStore, requestObject, tenant);
      const newObj = { ...dialogObject };
      newObj.cached = true;
      await Service.put(localStorageName, 'farms', newObj, tenant);
      retVal = { status: true, online: false, cached: true };
    } else {
      throw (e);
    }
  }
  return retVal;
}

export async function createBatchRecords(localStorageName, objectStore, dialogObject, tenant) {
  console.log('api post from Storage - Batch');
  let retVal = { status: false, online: true };
  try {
    await replayCache(localStorageName, 'calls', tenant);
    await API.post('farms', '/batchCreate', {
      body: dialogObject,
      timeout: TIMEOUT,
    });
    for (const item of dialogObject.items) {
      await Service.put(localStorageName, 'farms', item, tenant);
    }

    retVal = { status: true, online: true, cached: false };
  } catch (e) {
    console.log('caught here');
    if (e.message === 'Network Error' || e.message === TIMEOUTSTR) {
      console.log('failed to create object, storing request locally');
      const requestObject = {
        stamp: new Date().valueOf(), method: 'post', api: 'farms', route: '/batchCreate', params, syncError: false, syncMessage: '', payload: dialogObject,
      };
      await Service.put(localStorageName, objectStore, requestObject, tenant);
      const newObj = { ...dialogObject };
      for (const item of newObj.items) {
        item.cached = true;
        await Service.put(localStorageName, 'farms', item, tenant);
      }
      // newObj.cached = true;
      // await Service.put(localStorageName, 'farms', newObj,tenant);
      retVal = { status: true, online: false, cached: true };
    } else {
      throw (e);
    }
  }
  return retVal;
}

export function createObject2(dialogObject) {
  console.log('api post from Storage');
  return API.post('farms', '/farms', {
    body: dialogObject,
  });
}

export function storeFile(dialogObject) {
  console.log('api post from Storage');
  return API.post('farms', '/file', {
    body: dialogObject,
  });
}
export function getSiteList() {
  console.log('api get from Storage');
  return API.get('farms', '/listSites', params);
}

export function apiGetFile(url) {
  console.log('api get from Storage');
  return API.get('farms', url, params);
}

// write a function that will use the fetch command to download a file using a presigned url
export function downloadFile(url) {
  console.log('api get from Storage');
  return fetch(url);
}

async function fetchDataFromS3(url) {
  try {
    // Fetch the data from the S3 URL
    const response = await fetch(url);
    const jsonString = await response.text();

    // Convert the JSON string back to an array
    const dataArray = JSON.parse(jsonString);

    // Integrate the array into an object
    const dataObject = {
      status: true,
      records: dataArray,
      updateStatus: false,
      recordsUpdated: [],
    };

    console.log('Data Object:', dataObject);
    return  dataArray;
  } catch (error) {
    console.error('Error fetching or parsing data:', error);
    throw error;
  }
}


export function downloadFile2(url) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // convert the blob to text
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        const text = reader.result;
        return text;
      });
      reader.readAsText(blob);
    })
    .catch((error) => {
      console.error('Error downloading S3 object:', error);
    });
}

export function getCensusAnon() {
  console.log('api get from Storage');
  return API.get('farms', '/censusAnon', params);
}
export function getAdminSettings(activeTenant, selection, filter = null, filterValue = null) {
  console.log('api get from Storage');
  // adminSettings/{activeTenant}/{selection}/{filter}/{filterValue}/
  // http://example.com/page?parameter=value&also=another
  // return API.get('farms', `/adminSettings/${activeTenant}/${selection}/${filter}/${filterValue}/`, params);
  return API.get('farms', `/adminSettings/${activeTenant}?selection=${selection}&filter=${filter || ''}&filterValue=${filterValue || ''}`, params);
}

export function deleteObject(dialogObject) {
  console.log('api post from Storage');
  return API.del('farms', '/farms', {
    body: dialogObject,
  });
}

export function storeAsset(asset) {
  if (!asset) {
    return;
  }
  const storageObject = { ...asset };
  storageObject.type = 'asset';
  const version = storageObject.version ? storageObject.version : 0;
  storageObject.version = version + 1;
  return API.post('farms', '/farms', {
    body: storageObject,
  });
}
export function archiveObject(dialogObject) {
  console.log('api post from Storage');
  return API.post('farms', '/archive', {
    body: dialogObject,
  });
}
export function createEntity(object) {
  console.log('api post from Storage');
  return API.post('farms', '/tenant', {
    body: object,
  });
}

export function addSite(object) {
  console.log('api post from Storage');
  return API.post('farms', '/addSite', {
    body: object,
  },{
    Headers:{
      'Content-type':'application/json'
    }
  });
}
export function createFolder(dialogObject) {
  console.log('api post from Storage');
  return API.post('farms', '/bucket', {
    body: dialogObject,
  });
}
export function addUser(object) {
  console.log('api post from Storage');
  return API.post('farms', '/user', {
    body: object,
  });
}
export function disableUser(object) {
  console.log('api post from Storage');
  return API.post('farms', '/disableUser', {
    body: object,
  });
}
export function deleteUser(object) {
  console.log('api post from Storage');
  return API.post('farms', '/deleteUser', {
    body: object,
  });
}
export function enableUser(object) {
  console.log('api post from Storage');
  return API.post('farms', '/enableUser', {
    body: object,
  });
}
export function sharedUserInvite(object) {
  console.log('api post from storage');
  return API.post('farms', '/sharedUserInvite', {
    body: object,
  });
}

export async function deleteRejectedInvitation(object) {
  console.log('api post from storage');
  return API.post('farms', '/deleteRejectedInvitation', {
    body: object,
  });
}
export function getSignedUrl(object) {
  console.log('api post from storage');
  API
    .post('farms', '/signedUrl', {
      body: object,
    })
    .then((response) => response);
}

export function sharedUserInviteDelete(object) {
  console.log('api post from storage');
  return API.del('farms', '/sharedUserInviteDelete', {
    body: object,
  });
}

export function userInviteDelete(object) {
  console.log('api post from storage');
  return API.del('farms', '/userInviteDelete', {
    body: object,
  });
}

export function getErrorMessage(e) {
  if (!e) {
    return '';
  }

  if (e.response && e.response.data) {
    if (e.response.data.error) {
      if (e.response.data.error && typeof e.response.data.error.valueOf() === 'string') {
        return e.response.data.error;
      }
      if (e.response.data.error.message) {
        return e.response.data.error.message;
      }
      if (e.response.data.error.message) {
        return e.response.data.error;
      }
    }
    if (e.response.data.message) {
      return e.response.data.message;
    }
  }
  if (e.message) {
    return e.message;
  }
  return 'Unknown error';
}

export function sharedUserAdd(object) {
  console.log('api post from storage');
  return API.post('farms', '/sharedUserAdd', {
    body: object,
  });
}

export function maintain(object) {
  console.log('api post from storage');
  return API.post('farms', '/maintain', {
    body: object,
  });
}

export function sharedUserReject(object) {
  console.log('api post from storage');
  return API.post('farms', '/sharedUserReject', {
    body: object,
  });
}

export function deleteFile(object) {
  console.log('api post from storage');
  return API.post('farms', '/deleteFile', {
    body: object,
  });
}

export function getDownloadUrl(object) {
  console.log('api post from storage');
  return API.post('farms', '/downloadUrl', {
    body: object,
  });
}

export function getFarms(){
  return API.get('farms', '/listFarms');
}

export async function getDownloadUrls(objects) {
  const allPromises = [];
  for (let r = 0; r < objects.lengt; r += 1) {
    const promise = getDownloadUrl(r[objects[r]]);
    allPromises.push(promise);
  }
  await Promise.allSettled(allPromises);
}

export async function records(localStorageName, objectStore, tenant) {
  // return API.get("farms", "/farms/block_");
  console.log('api get from local Storage');
  let recordsLoaded = [];
  try {
    recordsLoaded = await Service.getAll(localStorageName, objectStore, tenant);
    // add unsynced records as well.
    /* let cacheRecords = await Service.getAll(localStorageName, 'calls');
    cacheRecords = cacheRecords ? cacheRecords.map((item) => {
      const ob = { ...item.payload };
      return ob;
    }) : [];
    recordsLoaded = [...recordsLoaded, ...cacheRecords]; */
  } catch (e) {
    // if (e.message === 'Network Error') {
    //  console.log("serving offline");
    //  recordsLoaded = await Service.getAll(localStorageName, objectStore);
    // } else {
    console.log('Exception caught could not retreive recods', e);
    throw (e);
    // }
  }
  return recordsLoaded;
}

export async function synchronise(localStorageName, objectStore, activeTenant) {
  // return API.get("farms", "/farms/block_");
  console.log('synchronise api get from Storage');
  let activeTenantIn = activeTenant;
  let recordsLoaded = {
    status: false, records: [], updateStatus: false, recordsUpdated: [],
  };
  try {
    if (activeTenantIn) {
      await replayCache(localStorageName, 'calls', activeTenantIn);
    }
    if (!activeTenantIn) {
      activeTenantIn = 'default';
    };

    const recordsA = await API.get('farms', `/farmsList/v2/${activeTenantIn}/${1}`, {...params});
    // const recordsB = await API.get('farms', `/farmsList/v2/${activeTenantIn}/${2}`, {...params});
    const records = await fetchDataFromS3(recordsA);

    // console.log("records",farmsAll.filter(farm => farm?.parentFarm === entity?.sk));
    // const alternativeRecords = await API.get('farms', `/farmsList/${activeTenantIn}`, {...params});

    recordsLoaded.records = records;
    // recordsLoaded.records = alternativeRecords;
    //log this result to a file on disk not using service but local file storage
    //console.log('loaded records', JSON.stringify(recordsLoaded));

    if (recordsLoaded.records && recordsLoaded.records.industryView) {
      return recordsLoaded;
    }
    if (activeTenantIn === null && recordsLoaded.records.length > 0) {
      activeTenantIn = recordsLoaded.records[0].farmId;
    }
    const tenantRecords = { records: recordsLoaded.records, sk: activeTenantIn };
    await Service.replaceAll(localStorageName, objectStore, activeTenantIn, tenantRecords);
    ///
    const siteList = tenantRecords.records.filter((record) => record.type === 'siteAccess');

    let activeSite = null;
    if (siteList && siteList.length > 0) {
      const activeSiteOb = siteList.find((s) => s.default);
      if (activeSiteOb) {
        activeSite = activeSiteOb.tenantId;
      } else {
        activeSite = siteList[siteList.length - 1].tenantId;
      }
    }

    // also add the default site.
    const defaults = { sk: localStorageName, activeSite: activeTenantIn };
    await Service.putFlat(localStorageName, 'defaults', defaults);

    ///
    recordsLoaded.status = true;
  } catch (e) {
    if (e.message === 'Network Error' || e.message === TIMEOUTSTR) {
      console.log('failed to synchronise return offline', e);
      // if we don't have a tenant.
      recordsLoaded = await Service.getAll(localStorageName, 'farms', activeTenantIn);
      if (recordsLoaded) {
        recordsLoaded.status = true;
      } else {
        recordsLoaded = { records: [], status: true };
        // recordsLoaded.records=[];
        // recordsLoaded.status.true;
      }

      // recordsLoaded.status = false;
      // recordsLoaded.records = [];
      return recordsLoaded;
    }
    if (e.message === 'Syncronisation failure') {
      recordsLoaded.status = true;
      recordsLoaded = await Service.getAll(localStorageName, 'farms', activeTenantIn);
      recordsLoaded.message = 'Your synchroniation failed. They are marked with an error.';
      return recordsLoaded;
    }
    if (e.message === '') {
      recordsLoaded.status = true;
      recordsLoaded.records = [];
      return recordsLoaded;
    }
    console.log('Exception caught could not synchronise recods', e);
    throw (e);
  }
  return recordsLoaded;
}
export async function recordsbu(localStorageName, objectStore) {
  // return API.get("farms", "/farms/block_");
  console.log('api get from Storage');
  let recordsLoaded = [];
  try {
    recordsLoaded = await API.get('farms', '/farms');
    await Service.replaceAll(localStorageName, objectStore, recordsLoaded);
  } catch (e) {
    if (e.message === 'Network Error') {
      console.log('serving offline');
      recordsLoaded = await Service.getAll(localStorageName, objectStore);
    } else {
      console.log('Exception caught could not retreive recods', e);
      throw (e);
    }
  }
  return recordsLoaded;
}

// https://pqina.nl/filepond/docs/patterns/api/server/
export function processFile(fieldName, file, metadata, activeTenant, path, load, error, progress, abort, isGeneral = null, setId = null) {
  // const request = new XMLHttpRequest();
  const upload_request = new XMLHttpRequest();
  API
    .post('farms', '/signedUrl', {
      body: {
        activeTenant, path, contentType: file.type, type: 'files', isGeneral, setId,
      },
    })
    .then((response) => { // handles the retrival the presigned url for upload (preprocess)
      // console.log(JSON.stringify(response));
      if ((!response.status) || (response.status >= 200 && response.status < 300)) {
        // const preprocess = JSON.parse(response);
        upload_request.open('PUT', response);
        upload_request.upload.onprogress = function (e) { // update progress of upload
          progress(e.lengthComputable, e.loaded, e.total);
        };
        upload_request.onload = function () { // handles the upload
          if (upload_request.status >= 200 && upload_request.status < 300) {
            // NOTE: upload_request.responseText is empty
            load(path); // the id comes from the preprocess response
          } else {
            error('Failed to upload file');
          }
        };
        upload_request.onerror = function (e) {
          error('Failed to upload file');
        };
        upload_request.send(file); // sending the file to the presigned url
      } else {
        error('Could not get the upload URL');
      }
    }).catch((err) => {
      if (err) {
        error(err.response.data.error);
      }
    });

  return {
    abort() {
      upload_request.abort();
      // request.abort();
      abort();
    },
  };
}

export function revert(uniqueFileId, url, load, error) {
  const request = new XMLHttpRequest();
  request.open('DELETE', `${url}/file/${uniqueFileId}`);
  request.onload = function () { // handles the delete for the file
    if (request.status >= 200 && request.status < 300) {
      load();
    } else {
      error('Could not revert the file');
    }
  };
}

export async function getTargets(getFromGlobalState, setGlobalState, setSnack, cropSK) {
  const targets = getFromGlobalState('genTargets');
  if (targets && targets[cropSK]) {
    return targets[cropSK];
  }
  // TODO: write a new server function that can return for this target type the targets
  // and filter with the crop - on server side - data will be big.
}
/* export async function getCropTypes (getFromGlobalState, setGlobalState, setSnack,noFilter) {

    let  cropTypes = getFromGlobalState('cropTypesSetting');
    if (cropTypes && cropTypes.length) {
      return cropTypes;
    }
    let activeSite = getFromGlobalState('activeSite');
    if (activeSite === '' || !activeSite) {
      activeSite = 'default';
    }
    try {
      // this.setState({ isloading: true });
      cropTypes = await getAdminSettings(activeSite, 'group', 'cropTypesSetting');
      if(!noFilter){
        cropTypes = cropTypes.filter((c)=>c.enabled);
      }
      cropTypes = cropTypes && cropTypes.sort((a, b) => ("" + a.name).localeCompare(b.name, undefined, { numeric: false }));
      // this.setState({ isloading: false, cropTypes });
      setGlobalState({cropTypesSetting: cropTypes});
     return cropTypes;
    } catch (e) {
      setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
      // this.setState({ isloading: false });
    }
  }
  */

export async function getTargetUrl(key, fileType, activeTanant) {
  const params = {
    activeTenant: activeTanant,
    type: 'files',
    path: key,
    contentType: fileType,
  };
  const url = await getDownloadUrl(params);
  return url;
}

export async function getTargetDetails(getFromGlobalState, setGlobalState, setSnack, targetType, crop) {
  if (!targetType) {
    return null;
  }
  const targetsDetail = getFromGlobalState('targetDetail');
  if (targetsDetail && targetsDetail[targetType] && targetsDetail[targetType][crop]) {
    return targetsDetail[targetType][crop];
  }
  try {
    const activeSite = getFromGlobalState('activeSite');
    const newTargets = await getAdminSettings(activeSite, targetType.concat('_'), 'observableOn', crop);
    const newTargetsDetail = { ...targetsDetail };
    if (!newTargetsDetail[targetType]) {
      newTargetsDetail[targetType] = {};
    }
    if (!newTargetsDetail[targetType][crop]) {
      newTargetsDetail[targetType][crop] = {};
    }
    newTargetsDetail[targetType][crop] = newTargets;
    setGlobalState({ targetDetail: newTargetsDetail });
    return newTargetsDetail[targetType][crop];
  } catch (e) {
    setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    return false;
  }
}
export async function getNonCropSpecificTargetDetails(getFromGlobalState, setGlobalState, setSnack, targetType) {
  if (!targetType) {
    return null;
  }
  const targetsDetail = getFromGlobalState('targetDetailNonCrop');
  if (targetsDetail && targetsDetail[targetType]) {
    return targetsDetail[targetType];
  }
  try {
    const activeSite = getFromGlobalState('activeSite');
    const newTargets = await getAdminSettings(activeSite, targetType.concat('_'), 'cropSpecific', 'No');
    const newTargetsDetail = { ...targetsDetail };
    if (!newTargetsDetail[targetType]) {
      newTargetsDetail[targetType] = {};
    }
    newTargetsDetail[targetType] = newTargets;
    setGlobalState({ targetDetailNonCrop: newTargetsDetail });
    return newTargetsDetail[targetType];
  } catch (e) {
    setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    return false;
  }
}

export async function getCrops(getFromGlobalState, setGlobalState, setSnack, cropType) {
  try {
    const activeSite = getFromGlobalState('activeSite');
    const crops = getFromGlobalState('genCrops');
    if (crops[cropType]) {
      return crops;
    }
    const cropsNew = await getAdminSettings(activeSite, cropType.concat('_'), 'type', 'cropsSetting');
    const newCrops = { ...crops };
    newCrops[cropType] = cropsNew;
    setGlobalState({ genCrops: newCrops });
    return newCrops;
  } catch (e) {
    setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    // this.setState({ isloadingTable: false });
    return false;
  }
}

export async function getFertiliserDetails(getFromGlobalState, setGlobalState, setSnack) {
  try {
    const activeSite = getFromGlobalState('activeSite');
    const fertilisers = getFromGlobalState('genFertilisers');
    if (fertilisers && fertilisers.length) {
      return fertilisers;
    }
    // getAdminSettings(activeSite, cropType.concat('_'), 'type', 'cropsSetting');
    const fertilisersNew = await getAdminSettings(activeSite, null, 'genFertilisers');
    const newFertilisers = [...fertilisersNew];
    // newCrops[cropType] = cropsNew;
    setGlobalState({ genFertilisers: newFertilisers });
    return newFertilisers;
  } catch (e) {
    setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    // this.setState({ isloadingTable: false });
    return false;
  }
}

export async function getPPPDetails(getFromGlobalState, setGlobalState, setSnack) {
  try {
    const activeSite = getFromGlobalState('activeSite');
    const pPPs = getFromGlobalState('genPPPs');
    if (pPPs && pPPs.length) {
      return pPPs;
    }
    // getAdminSettings(activeSite, cropType.concat('_'), 'type', 'cropsSetting');
    const pPPsNew = await getAdminSettings(activeSite, null, 'genPPPs');
    const newPPPs = [...pPPsNew];
    // newCrops[cropType] = cropsNew;
    setGlobalState({ genPPPS: newPPPs });
    return newPPPs;
  } catch (e) {
    setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    // this.setState({ isloadingTable: false });
    return false;
  }
}

export async function getCropDetails(getFromGlobalState, setGlobalState, setSnack, cropType, noUpdateState) {
  // this.setState({ isloadingTable: true });
  if (!cropType) {
    return null;
  }
  const crops = getFromGlobalState('genCrops');
  const cropsDetail = getFromGlobalState('cropsDetail');

  if (crops && crops[cropType] && crops[cropType].length && cropsDetail && cropsDetail[cropType] && cropsDetail[cropType].length) {
    // this.setState({ isloadingTable: false });
    return { fromCache: true, crops: crops[cropType], cropsDetail: cropsDetail[cropType] };
  }
  let activeSite = getFromGlobalState('activeSite');
  if (activeSite === '' || !activeSite) {
    activeSite = 'default';
  }
  try {
    const newCropsDetail = await getAdminSettings(activeSite, cropType.concat('_'));

    let cropsNew = newCropsDetail.filter((crop) => crop.type === 'cropsSetting');
    cropsNew = cropsNew && cropsNew.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: false }));
    let cropsDetailNew = newCropsDetail.filter((detail) => detail.type === 'cropsDetail');
    cropsDetailNew = cropsDetailNew && cropsDetailNew.sort((a, b) => (`${a.name}`).localeCompare(b.rootStock, undefined, { numeric: false }));
    // const columnsNew = this.getHeader(crops).sort((a, b) => ("" + a.name).localeCompare(b.name, undefined, { numeric: false }));
    const newCrops = { ...crops };
    newCrops[cropType] = cropsNew;
    const newCropsDetails = { ...cropsDetail };
    newCropsDetails[cropType] = cropsDetailNew;
    // if (!noUpdateState) {
    setGlobalState({ genCrops: newCrops });
    setGlobalState({ cropsDetail: newCropsDetails });
    // this.setState({crops: newCrops, cropsDetail: newCropsDetails});
    // }

    return { crops: newCrops[cropType], cropsDetail: newCropsDetails[cropType] };
  } catch (e) {
    setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    // this.setState({ isloadingTable: false });
    return false;
  }
}
