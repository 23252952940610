import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import Checkbox from '@material-ui/core/Checkbox';
import GenDialog from '../../components/GenDialog';
import SingleListControl from '../../components/SingleListControl';
import { validate, validateForm } from '../../libs/validate';
import { getAdminSettings, getErrorMessage } from '../../libs/storage';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

const requiredFields = [
  { name: 'productProcessor', min: 1, max: 200 },
  { name: 'enabled', min: 1, max: 100 },
];

class MyProductProcessor extends Component {
  constructor(props) {
    super(props);
    const { handleUpdate } = this.props;
    this.handleUpdate = handleUpdate.bind(this);

    this.state = {
      selectedProcessorType: '',
      myProductProcessor: {},
      isLoading: false,
      productProcessors: [],
      allProductProcessors: [],
      errors: { count: 0 },
      changed: false,
      countries: [],
      provinces: [],
      selectedCountry: '',
      selectedProvince: '',
      selectedCrop: '',
      selectedProductProcessor: '',
      processorName: '',
      processorTypeName: '',
      isNew: false,

    };
  }

  componentDidMount() {
    const { isNew, selectedSetting, getFromGlobalState } = this.props;
    if (isNew) {
      this.setState({ isNew, myProductProcessor: { enabled: true } });
    } else {
      const myProductProcessor = selectedSetting;
      const allProductProcessors = getFromGlobalState('productProcessors');
      const processor = allProductProcessors.find((p) => p.sk === myProductProcessor.productProcessor);
      const processorType = processor.sk.substring(0, processor.sk.indexOf('_setchild_'));
      const processorTypes = getFromGlobalState('productProcessorTypes');
      const processorTypeName = processorTypes ? processorTypes.find((h) => h.sk === processorType).name : '';
      const { countries, provinces, productProcessors } = this.state;
      const newCountries = [...countries];
      newCountries.push(processor.country);
      const newProvinces = [...provinces];
      newProvinces.push(processor.province);
      const newProductProcessors = [...productProcessors];
      newProductProcessors.push(processor);
      // ob.province = processor.province;
      this.setState({
        isNew,
        myProductProcessor,
        selectedProcessorType: processorType,
        selectedCountry: processor.country,
        selectedProvince: processor.province,
        processorName: processor.name,
        processorTypeName,
        selectedProductProcessor: myProductProcessor.productProcessor,
        countries: newCountries,
        provinces: newProvinces,
        productProcessors: newProductProcessors,
      });
    }
  }

  handleDelete = (value) => {
    const operator = { ...this.state.operator };
    const currentIndex = operator && operator.roles.findIndex((item) => item === value);

    if (currentIndex !== -1) {
      operator.roles.splice(currentIndex, 1);
    }
    this.setState({ operator, changed: true });
  }

  onSelectAll = () => {
    const { operator, roles } = this.state;
    const newOperator = { ...operator };
    if (newOperator.allSelected) {
      newOperator.allSelected = false;
      newOperator.roles = [];
    } else {
      newOperator.allSelected = true;
      newOperator.roles = [];
      newOperator.roles = roles.map((r) => r.sk);
    }
    this.setState({ operator: newOperator, changed: true });
  }

  handleToggle = (sk) => {
    const { operator } = this.state;
    const newOperator = { ...operator };
    if (!newOperator.roles) {
      newOperator.roles = [];
    }
    const currentIndex = operator && operator.roles.findIndex((item) => item === sk);

    if (currentIndex === -1) {
      newOperator.roles.push(sk);
    } else {
      newOperator.roles.splice(currentIndex, 1);
    }
    this.setState({ operator: newOperator, changed: true });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const { operator } = this.state;
    const newOperator = { ...operator };
    newOperator[event.target.id] = event.target.value;
    this.setState({ operator: newOperator, changed: true });
  }

  handleCountryChange = (event) => {
    const { allProductProcessors, selectedCrop, selectedProvince } = this.state;
    const selectedCountry = event.target.value;
    let newProductProcessors = [...allProductProcessors];
    if (selectedCountry) {
      newProductProcessors = newProductProcessors.filter((f) => f.country === selectedCountry);
    }
    if (!selectedCrop) {
      newProductProcessors = newProductProcessors.filter((f) => !f.processingCrops);
    } else {
      newProductProcessors = this.filterCropChange(newProductProcessors, selectedCountry, selectedProvince, { target: { value: selectedCrop } });
    }
    this.setState({
      selectedCountry, productProcessors: newProductProcessors, selectedProvince: '', selectedProductProcessor: '', changed: true,
    });
  }

  handleProvinceChange = (event) => {
    const { allProductProcessors, selectedCountry, selectedCrop } = this.state;
    const selectedProvince = event.target.value;
    let newProductProcessors = [...allProductProcessors];
    if (selectedCountry) {
      newProductProcessors = newProductProcessors.filter((f) => f.country === selectedCountry);
    }
    if (selectedProvince) {
      newProductProcessors = newProductProcessors.filter((f) => f.province === selectedProvince);
    }
    if (!selectedCrop) {
      newProductProcessors = newProductProcessors.filter((f) => !f.processingCrops);
    } else {
      newProductProcessors = this.filterCropChange(newProductProcessors, selectedCountry, selectedProvince, { target: { value: selectedCrop } });
    }
    this.setState({ selectedProvince, productProcessors: newProductProcessors, changed: true });
  }

  filterCropChange = (allProductProcessors, selectedCountry, selectedProvince, event) => {
    const selectedCrop = event.target.value;
    let newProductProcessors = [...allProductProcessors];
    let filteredNewProductProcessors = [];
    if (selectedCountry) {
      newProductProcessors = newProductProcessors.filter((f) => f.country === selectedCountry);
    }
    if (selectedProvince) {
      newProductProcessors = newProductProcessors.filter((f) => f.province === selectedProvince);
    }
    if (selectedCrop) {
      newProductProcessors = newProductProcessors.filter((f) => f.processingCrops);
      for (let i = 0; i < newProductProcessors.length; i += 1) {
        const processingCrops = Object.keys(newProductProcessors[i].processingCrops);
        for (let p = 0; p < processingCrops.length; p += 1) {
          if (newProductProcessors[i].processingCrops[processingCrops[p]] && newProductProcessors[i].processingCrops[processingCrops[p]].indexOf(selectedCrop) !== -1) {
            filteredNewProductProcessors.push(newProductProcessors[i]);
          }
        }
      }
    } else {
      filteredNewProductProcessors = newProductProcessors.filter((f) => !f.processingCrops);
    }
    return filteredNewProductProcessors;
  }

  handleCropChange = (event) => {
    const { allProductProcessors, selectedCountry, selectedProvince } = this.state;
    const filteredNewProductProcessors = this.filterCropChange(allProductProcessors, selectedCountry, selectedProvince, event);
    const selectedCrop = event.target.value;
    this.setState({
      selectedCrop, productProcessors: filteredNewProductProcessors, selectedProductProcessor: '', changed: true,
    });
  }

  handleProcessorChange = (event) => {
    const { myProductProcessor } = this.state;
    const newMyProductProcessor = { ...myProductProcessor };
    const selectedProductProcessor = event.target.value;
    newMyProductProcessor.productProcessor = selectedProductProcessor;
    this.setState({ selectedProductProcessor, myProductProcessor: newMyProductProcessor, changed: true });
  }

  handleProcessorTypeChange = async (event) => {
    const { selectedProcessorType } = this.state;
    const newSelectedProcessor = event.target.value;
    const { getFromGlobalState, setSnack, setGlobalState } = this.props;
    if (selectedProcessorType !== newSelectedProcessor) {
      try {
        this.setState({ isLoading: true });
        const activeSite = getFromGlobalState('activeSite');
        const productProcessors = await getAdminSettings(activeSite, newSelectedProcessor.concat('_'));
        const countries = productProcessors ? [...new Set(productProcessors.map((item) => item.country))] : [];
        const provinces = productProcessors ? [...new Set(productProcessors.map((item) => item.province))] : [];
        const filteredProcessors = productProcessors.filter((f) => !f.processingCrops);
        const gProductProcessors = [...getFromGlobalState('productProcessors')];
        for (let p = 0; p < productProcessors.length; p += 1) {
          const idx = gProductProcessors.findIndex((i) => i.sk === productProcessors[p].sk);
          if (idx === -1) {
            gProductProcessors.push(productProcessors[p]);
          }
        }
        setGlobalState({ productProcessors: gProductProcessors });
        this.setState({
          selectedProcessorType: newSelectedProcessor, allProductProcessors: productProcessors, productProcessors: filteredProcessors, isLoading: false, countries, provinces, selectedCountry: '', selectedProvince: '', changed: true,
        });
      } catch (e) {
        setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
        this.setState({ isLoading: false });
      }
    }
  }

  handleCheckChange = (event) => {
    const { myProductProcessor } = this.state;
    const newMyProductProcessor = { ...myProductProcessor };
    newMyProductProcessor[event.target.id] = event.target.checked;
    this.setState({ myProductProcessor: newMyProductProcessor, changed: true });
  }

  onDisable = () => {
    const { myProductProcessor } = this.state;
    const newMyProductProcessor = { ...myProductProcessor };
    if (!newMyProductProcessor.disabled) {
      newMyProductProcessor.disabled = true;
    } else {
      newMyProductProcessor.disabled = false;
    }
    this.setState({ myProductProcessor: newMyProductProcessor, changed: true });
  }

  validateField(field) {
    const { myProductProcessor, errors } = this.state;
    const error = validate(field, myProductProcessor, requiredFields);
    const newErrors = { ...errors };
    newErrors[field] = error.msg;
    this.setState({ errors: newErrors });
  }

  render() {
    const {
      classes, open, setClose, getFromGlobalState,
    } = this.props;
    const {
      isNew, selectedProcessorType, errors, changed, myProductProcessor, productProcessors, countries, provinces, allProductProcessors,
      selectedCountry, selectedProvince, selectedCrop, selectedProductProcessor, processorName, processorTypeName,
    } = this.state;
    const processorTypes = getFromGlobalState('productProcessorTypes');
    const crops = getFromGlobalState('crops');
    const formValid = validateForm(myProductProcessor, requiredFields);

    return (

      <GenDialog
        open={open}
        dialogObject={myProductProcessor}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={this.handleUpdate}
        skPrimary="init_setting_"
        dynamoType="myProductProcessors"
        valid={formValid}
        changed={changed}
        onDisable={this.onDisable}
        newTitle="New processor"
        title={myProductProcessor.name}
        getFromGlobalState={getFromGlobalState}
        allowDelete
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={myProductProcessor.enabled}
                 // disabled={isInvite}
                  id="enabled"
                  onChange={this.handleCheckChange}
                  value="enabled"
                  color="secondary"
                />
        )}
              label="Enabled"
            />
          </Grid>
          {true && (
          <>
            {' '}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="unitNum">Processor Type</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  disabled={!isNew}
                  displayEmpty
                  value={selectedProcessorType}
                  onChange={this.handleProcessorTypeChange}
                  onBlur={this.handleBlur}
                  inputProps={{
                    name: 'productProcessorType',
                    id: 'productProcessorType',
                  }}
                >
                  <option value="" />
                  {processorTypes && processorTypes.map((pt) => (
                    <>
                      <option value={pt.sk}>{pt.name}</option>
                    </>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="unitNum">Country</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  disabled={allProductProcessors.length === 0}
                  displayEmpty
                  value={selectedCountry}
                  onChange={this.handleCountryChange}
                  onBlur={this.handleBlur}
                  inputProps={{
                    name: 'selectedCountry',
                    id: 'selectedCountry',
                  }}
                >
                  <option value="" />
                  {countries && countries.map((c) => (
                    <>
                      <option value={c}>{c}</option>
                    </>
                  ))}
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="unitNum">Province</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  disabled={allProductProcessors.length === 0}
                  value={selectedProvince}
                  onChange={this.handleProvinceChange}
                  onBlur={this.handleBlur}
                  inputProps={{
                    name: 'selectedProvince',
                    id: 'selectedProvince',
                  }}
                >
                  <option value="" />
                  {provinces && provinces.map((c) => (
                    <>
                      <option value={c}>{c}</option>
                    </>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="unitNum">Crop</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  disabled={allProductProcessors.length === 0}
                  value={myProductProcessor.selectedCrop}
                  onChange={this.handleCropChange}
                  onBlur={this.handleBlur}
                  inputProps={{
                    name: 'selectedCrop',
                    id: 'selectedCrop',
                  }}
                >
                  <option value="">Not crop specific</option>
                  {crops && crops.map((c) => (
                    <>
                      <option value={c.sk}>{c.name}</option>
                    </>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="unitNum">Processors</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  disabled={allProductProcessors.length === 0}
                  value={selectedProductProcessor}
                  onChange={this.handleProcessorChange}
                  onBlur={this.handleBlur}
                  inputProps={{
                    name: 'selectedProductProcessor',
                    id: 'selectedProductProcessor',
                  }}
                >
                  <option value="" />
                  {productProcessors && productProcessors.map((c) => (
                    <>
                      <option value={c.sk}>{c.name}</option>
                    </>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
          )}
          {false && (
          <>
            <Typography variant="button" gutterBottom>{selectedProvince}</Typography>
            <Typography variant="button" gutterBottom>{processorTypeName}</Typography>
            <Typography variant="button" gutterBottom>{processorName}</Typography>
          </>
          )}
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8} />
        </Grid>
      </GenDialog>

    );
  }
}
MyProductProcessor.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedSetting: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(MyProductProcessor);
