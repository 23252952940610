import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import GeoIcon from '@material-ui/icons/GpsFixedOutlined';
import LoaderFab from '../LoaderFab';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
    flexDirection: 'row',
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

});

function isString(value) {
  return typeof value === 'string';
}

class Location extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationLoading: false,
      locationLoaded: false,
      // eslint-disable-next-line react/destructuring-assignment
      location: this.props.value,
      error: null,
    };
  }

  getCurrentLocation = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 100000,
      maximumAge: 0,
    };
    const { onChange } = this.props;
    const { locationLoaded, locationLoading } = this.state;
    this.setState({ locationLoading: true, locationLoaded: false, error: null });
    navigator.geolocation.getCurrentPosition((position) => {
      const location = {
        lat: parseFloat(position.coords.latitude.toFixed(8)),
        lng: parseFloat(position.coords.longitude.toFixed(8)),
        accuracy: position.coords.accuracy,
        altitude: position.coords.altitude,
        altAcc: position.coords.altitudeAccuracy,
      };
      this.setState({
        location, locationLoading: false, locationLoaded: true,
      });
      onChange(location, locationLoading, locationLoaded);
    },
    (error) => {
      //if (error.code === error.PERMISSION_DENIED) {
        this.setState({ error: error.message, locationLoading: false, locationLoaded: false });
        this.props.setSnack({ open: true, variant: 'error', messageText: ` Failed to find location ${error.message}` });
      //}
    },
    options);
  }

  render() {
    const {
      name, required, classes, id,
    } = this.props;
    const {
      location, error, locationLoading,
    } = this.state;

    return (
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel shrink htmlFor="geoLocation">Geolocation</InputLabel>
        <TextField
          margin="normal"
          required={required}
          style={{ minWidth: '260px' }}
          id={id}
          name={name}
          value={location ? `lat:${location.lat} lng:${location.lng}` : ''}
          onBlur={this.handleBlur}
          error={error ? true : undefined}
          helperText={error}
        />
        {error && <FormHelperText className={classes.helperText}>{error}</FormHelperText>}
        <LoaderFab
          onClick={this.getCurrentLocation}
          success={!error}
          icon={<GeoIcon />}
          loading={locationLoading}
        />
      </FormControl>
    );
  }
}
Location.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};
export default withStyles(styles)(Location);
