import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  /* The Modal (background) */
modal: {
  display: 'none', /* Hidden by default */
  position: 'fixed', /* Stay in place */
  zIndex: '10040 !important', /* Sit on top */
  paddingTop: '100px', /* Location of the box */
  left: 0,
  top: 0,
  width: '100%', /* Full width */
  height: '100%', /* Full height */
  overflow: 'auto', /* Enable scroll if needed */
  //backgroundColor: 'rgb(0,0,0)', /* Fallback color */
  backgroundColor: 'rgba(0,0,0,0.4)' /* Black w/ opacity */
},

/* Modal Content */
modalContent: {
  backgroundColor: '#fefefe',
  margin: 'auto',
  padding: '20px',
  border: '1px solid #888',
  width: '80%',
  borderRadius:'8px',
},

/* The Close Button */
close : {
  color: '#aaaaaa',
  float: 'right',
  fontSize: '28px',
  fontWeight: 'bold',
  padding: '3px,'
},
button: {
  margin: theme.spacing(1),
},
buttonParent:{
  display:'flex',
  alignItem:'center',
  justifyContent:'center',
},
labelParent:{
  display:'flex',
  alignItem:'center',
  justifyContent:'center',
}

/*.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}*/
  
    
});
function UpdateModal(props) {
  //const classes = useStyles();
 const {classes } = props;
  
  return (
    
    <div id="updateModal" className={classes.modal}> 
  <div className={classes.modalContent}>
    <div className={classes.labelParent}>
  <p>Land Diary has been updated. Use the new version now?</p>
  </div>
  <div className={classes.buttonParent}>  
  <Button variant="contained" color="primary" id="modalAccept" className={classes.button}>
        Update now
      </Button>
      <Button variant="contained" color="secondary" id="modalDismiss" className={classes.button}>
        No, Later
      </Button>
      </div>
  </div>

</div>
  
  );
}

export default withStyles(styles, { withTheme: true })(UpdateModal);