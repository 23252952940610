import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
});

const getTotal = (harvestEquips, selectedBlocks) =>{
  if (!harvestEquips) {return 0;}

  for (var ob of selectedBlocks){
    if(!ob.record.totalHarvestOverride){
      ob.record.totalHarvestOverride = false;
   
    if (!ob.record.harvestUnits) {continue;}
    let equip = harvestEquips.find(p=>p.sk===ob.record.harvestEquip);
  if (!equip) {return undefined;}
  ob.record.harvestTotal = ob.record.harvestUnits*equip.capacity;
}

  }
  
};

const getTotal2= (harvestEquips, selectedBlocks) =>{
  
  if (!ob.record.totalHarvestOverride){
    ob.record.totalHarvestOverride = false;
  }
  else {
    if (!harvestEquips) {return 0;}

  for (var ob of selectedBlocks){
        if (!ob.record.harvestUnits) {continue;}
        let equip = harvestEquips.find(p=>p.sk===ob.record.harvestEquip);
      if (!equip) {return undefined;}
      ob.record.harvestTotal = ob.record.harvestUnits*equip.capacity;
      }
  }
  
  
  
};

function HarvestingFormBatch(props) {
  const { classes, record, errors, handleBlur, handleChange, metaData,selectedBlocks ,handleBatchChange, overrideHarvestTotal, handleCommonBlur, handleCommonChange  } = props;
  const harvestEquips = metaData;
  record.harvestTotal=getTotal(harvestEquips,selectedBlocks);
  return ( 
    <Grid container className={classes.root} spacing={1} alignItems="flex-start" >
      
  {selectedBlocks && selectedBlocks.map((selectedBlock) => (
      <Card style={{width:'100%', marginBottom:'15px'}}>
      <CardContent>
  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
  <Typography variant="h6" color="inherit" noWrap className={classes.location}>
  {selectedBlock.label}
</Typography></Grid>

<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          margin="normal"
          required
          fullWidth
          type="date"
    //defaultValue={new Date()}
          id="date"
          label="Date"
          name="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={selectedBlock.record.date}
          onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
          onBlur={handleBlur.bind(this,selectedBlock.planting)}
          error={errors[selectedBlock.planting] && errors[selectedBlock.planting].date ? true : undefined}
          helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].date}
        />
      </Grid>

     <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
       <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="harvestEquip">Harvest Unit</InputLabel>
          <Select
          native
          fullWidth
          required
          displayEmpty
          value={selectedBlock.record.harvestEquip}
          onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
          onBlur={handleBlur.bind(this,selectedBlock.planting)}
          error={errors[selectedBlock.planting] && errors[selectedBlock.planting].harvestEquip? true : undefined}
          inputProps={{
            name: 'harvestEquip',
            id: 'harvestEquip',
          }}
        >
          <option value=""></option>
          {harvestEquips && harvestEquips.map ((heq) => 
          <>
          <option value={heq.sk}>{heq.name}</option>
          </>
     )}
        </Select>
        {errors[selectedBlock.planting] && errors[selectedBlock.planting].harvestEquip&& <FormHelperText className={classes.helperText}>{errors[selectedBlock.planting].harvestEquip}</FormHelperText>}
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
        margin="normal"
        required
        fullWidth
        type="number"
        inputProps={{ 'min': '0.00001', 'step':'any' }}
        id="harvestUnits"
        label="Total Units"
        name="harvestUnits"
        value={selectedBlock.record.harvestUnits}
        onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
        onBlur={handleBlur.bind(this,selectedBlock.planting)}
        error={errors[selectedBlock.planting] && errors[selectedBlock.planting].harvestUnits ? true : undefined}
        helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].harvestUnits}
      /> 
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <FormControl fullWidth className={classes.formControl}>
      <InputLabel shrink htmlFor="harvestTotal">Total harvest in Kg</InputLabel>
       <TextField
        margin="normal"
        required
        fullWidth
        type="number"
        inputProps={{ 'min': '0.00001', 'step':'any' }}
        id="harvestTotal"
        name="harvestTotal"
        disabled={!selectedBlock.record.totalHarvestOverride}
        value={selectedBlock.record.harvestTotal}
        onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
        onBlur={handleBlur.bind(this,selectedBlock.planting)}
        error={errors[selectedBlock.planting] && errors[selectedBlock.planting].harvestTotal ? true : undefined}
        helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].harvestTotal}
      />
      </FormControl>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControlLabel value='harvestOverride' 
          control={<Radio color="secondary" name='totalHarvestOverride' 
          checked={selectedBlock.record.totalHarvestOverride} 
          id='totalHarvestOverride'
          onClick={overrideHarvestTotal.bind(this,selectedBlock.planting,selectedBlock.block)} />} 
          label="Override" />
          </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
        margin="normal"
        fullWidth
        type="text"
        id="deliveryNote"
        label="Delivery Note"
        name="deliveryNote"
        value={selectedBlock.record.deliveryNote}
        onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
        onBlur={handleBlur.bind(this,selectedBlock.planting)}
        error={errors[selectedBlock.planting] && errors[selectedBlock.planting].deliveryNote ? true : undefined}
        helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].deliveryNote}
      /> 
      </Grid>
      
 
  <Grid item xs={4} sm={4} md={8} lg={8} xl={8}></Grid>

  </CardContent>
  </Card>
          ))}
 
    </Grid>
  );
}

HarvestingFormBatch.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
  handleBatchChange: PropTypes.func.isRequired,
  handleCommonChange: PropTypes.func.isRequired,
  handleCommonBlur: PropTypes.func.isRequired,
  overrideHarvestTotal: PropTypes.func.isRequired,
};

export default withStyles(styles)(HarvestingFormBatch);
