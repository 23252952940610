import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import GenDialog from '../../components/GenDialog';
import { validate, validateForm } from '../../libs/validate';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
});

const requiredFields = [
  { name: 'descrName', min: 1, max: 200 },
];

class FileD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      file: { descrName: '' },
      errors: { count: 0 },
      changed: false,
      isloading: false,
    
    };
  }

  componentDidMount() {
    const { isNew, file } = this.props;
    if (!isNew) {
      this.setState({ file });
    }
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    let file = { ...this.state.file };
    file[event.target.id] = event.target.value; 
    this.setState({ file, changed: true });
  }

  validateField(field, requiredFields)
  {
    let error = validate(field, this.state.file, requiredFields)
    let errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors: errors }); 
  }

  render() {
    const { classes, open, isNew, setClose, handleUpdate,  metaData, getFromGlobalState , handlePreExecute, handleDeletePreExecute, parent, handleDeleteDialogObject, allowDelete } = this.props;
    const { file, errors } = this.state;
    const formValid = validateForm(file, requiredFields);
    
    return (
 
      <GenDialog
        open={open}
        dialogObject={file}
        setClose={setClose}
        isNew={isNew}
        allowDelete
        handleDeletePreExecute={handleDeletePreExecute}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        handleDeleteDialogObject={handleDeleteDialogObject}
        skPrimary="init_setting_"
        dynamoType="files"
        parentKey={parent ? parent.sk : null}
        valid={formValid}
        changed={this.state.changed}
        newTitle=''
        title={file && file.descrName ? file.descrName: ''}
        getFromGlobalState={getFromGlobalState}
        //handlePreExecute={handlePreExecute}
        >
         <TextField
            margin="normal"
            required
            fullWidth
            id="descrName"
            label="Name"
            name="descrName"
            autoFocus
            value={file.descrName ? file.descrName : file.filenameWithoutExtension}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.descrName ? true : undefined}
            helperText={errors.descrName}
          />
           <TextField
            margin="normal"
           // required
            fullWidth
            id="description"
            label="Description"
            name="description"
            value={file.description}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.description ? true : undefined}
            helperText={errors.description}
          />
           <br/>

      </GenDialog>
     
    );
  }
}
FileD.defaultProps = {
  parent: null,
};
FileD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  file: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,
  parent: PropTypes.object,
  allowDelete: PropTypes.bool.isRequired,
  handleDeletePreExecute: PropTypes.func.isRequired,
  handleDeleteDialogObject: PropTypes.func.isRequired,

};
export default withStyles(styles)(FileD);
