import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import InfoIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100px',
    maxWidth: '100px',
    maxHeight: '80px',
    margin: '8px',
    float: 'left',
   // backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  cardHeader: { textAlign: 'center', padding: '4px' },
  cardContnet: { textAlign: 'center', padding: '4px' },
}));

export default function StatBox(props) {
  const classes = useStyles();
  const {
    stat, header, subHeader, icon,
  } = props;
  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        title={header}
        //avatar={icon}
        subheader={subHeader}
        titleTypographyProps={{ variant: 'subtitle2' }}
        subheaderTypographyProps={{ variant: 'body2' }}
      />
      <CardContent className={classes.cardContnet}>
        <Typography variant="h6">{stat}</Typography>
      </CardContent>

    </Card>

  );
}
StatBox.propTypes = {
  message: PropTypes.string.isRequired,
  stat: PropTypes.number.isRequired,
  header: PropTypes.object.isRequired,
  subHeader: PropTypes.string.isRequired,
};
