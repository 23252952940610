import React from 'react';
import { Switch } from 'react-router-dom';
import AppliedRoute from './components/AppliedRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

import Home from './containers/Home';
import Login from './containers/Login';
import Signup from './containers/Signup';
import ResetPassword from './containers/ResetPassword';
import ChangePassword from './containers/ChangePassword';
import Settings from './containers/Settings';
import Messages from './containers/Messages';
// import Archive from './containers/Archive';
import Documents from './containers/Documents';
import UnderConstruction from './containers/UnderConstruction';
import Dashboard from './containers/Dashboard';
import Inventory from './containers/Inventory';
import About from './containers/About';
import Records from './containers/Records';
import Admin from './containers/Admin';
import CensusAnon from './containers/CensusAnon';
import MyLocation from './containers/MyLocation';

export default ({ childProps }) => (
  <Switch>
    {/* <AppliedRoute path="/" exact component={Home} props={childProps} /> */}
    <AuthenticatedRoute path="/" exact component={Home} props={childProps} />
    {' '}
    {/* this is blocks at the moment if home it should be an appliedroute */}
    <AuthenticatedRoute path="/records" exact component={Records} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <UnauthenticatedRoute
      path="/login/reset"
      exact
      component={ResetPassword}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/login/changePassword"
      exact
      component={ChangePassword}
      props={childProps}
    />
    <AppliedRoute path="/version" exact component={About} props={childProps} />
    <AuthenticatedRoute path="/documents" exact component={Documents} props={childProps} />
    <AuthenticatedRoute path="/dashboard" exact component={Dashboard} props={childProps} />
    <AuthenticatedRoute path="/inventory" exact component={Inventory} props={childProps} />
    <AuthenticatedRoute path="/messages" exact component={Messages} props={childProps} />
    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />
    <AuthenticatedRoute path="/admin" exact component={Admin} props={childProps} />
    <AuthenticatedRoute path="/census" exact component={CensusAnon} props={childProps} />
    <AuthenticatedRoute path="/location" exact component={MyLocation} props={childProps} />
    <AppliedRoute component={UnderConstruction} props={childProps} />
  </Switch>
);
