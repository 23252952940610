import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default ({
  isloading,
  text,
  loadingText,
  className = '',
  disabled = false,
  size=12,

  ...props
}) =>
  <Button
    disabled={disabled || isloading}
    {...props}
  >
    {isloading && <CircularProgress className={className} size={size} />}
    {!isloading ? text : loadingText}
  </Button>;
