import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LoaderButton from '../LoaderButton';

const styles = (theme) => ({

  submit: {
    margin: theme.spacing(1),
  },

});

function DateRangeDialog(props) {
  const {
    open,
    handleConfirm,
    handleCancel,
    title,
    startDate,
    endDate,
    classes,
    loading,
    handleSetStartDate,
    handleSetEndDate,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            type="date"
            id="startDate"
            label="Start Date"
            name="startDate"
            InputLabelProps={{
              shrink: true,
            }}
            value={startDate}
            onChange={handleSetStartDate}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type="date"
            // defaultValue={new Date()}
            id="endDate"
            label="End Date"
            name="endDate"
            InputLabelProps={{
              shrink: true,
            }}
            value={endDate}
            onChange={handleSetEndDate}
          />
        </DialogContent>
        <DialogActions>
          <LoaderButton
            // type="submit"
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
            isloading={loading}
            text="Submit"
            loadingText="Generating."
          />
          <Button
            onClick={handleCancel}
            color="secondary"
            variant="contained"
            className={classes.submit}
            disabled={loading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
DateRangeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  handleSetStartDate: PropTypes.func.isRequired,
  handleSetEndDate: PropTypes.func.isRequired,
};
export default withStyles(styles, { withTheme: true })(DateRangeDialog);
