import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { ColorLuminance, convertHex } from '../../libs/genLib';

const PARENT = '#0e4553';

export default class ChartBar extends PureComponent {
  render() {
    const {
      title, data, keys, dataKey, height, name,
    } = this.props;
    return (
      <Paper style={{ borderRadius: '4px', overflow: 'hidden'}}>
        {title && (
        <>
          <div style={{
            width: '100%', justifyContent: 'center', display: 'inline-flex', paddingTop: '5px',
          }}
          >
            <Typography variant="button" gutterBottom>{title}</Typography>

          </div>
        </>
        )}

        <ResponsiveContainer key={`${name} 3`} width="100%" height={height} style={{ cursor: 'pointer' }}>
          <BarChart
            width="100%"
            height={height}
            data={data}
            name={name}
            key={`${name} 1`}
            margin={{
              top: 20,
              right: 20,
              left: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={dataKey} />
            <YAxis />
            <Tooltip />
            <Legend wrapperStyle={{ fontSize: '12px' }} />
            { keys && keys.length && keys.map((k, i) => <Bar name={k} key={`${name}-bar-${k}`} label dataKey={k} fill={convertHex(ColorLuminance(PARENT, -0.4 * i), 80)} />)}

          </BarChart>
        </ResponsiveContainer>

      </Paper>

    );
  }
}
ChartBar.defaultProps = {
  height: 400,
};
ChartBar.propTypes = {
  data: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  height: PropTypes.string,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
