import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';




const styles = theme => ({
  card: {
   // minWidth: 275,
   height:"100%",
   //borderRadius:"8px",
  },
  cardHeader: {
    paddingBottom: '6px',

  },
  cardContent: {
    paddingTop: '6px',

  },
  cardActions: {
    paddingBottom:'0px',
    paddingTop:'0px',
  },
  cardExpand: {
    padding: '0px',

  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

 function SimpleCard(props) {
  const { classes, children, title, subHeader, elevation, avatar, action, expandedContent,expanded,handleExpandClick, handleAddExpandedContent,disableContentPadding } = props;
  return (
    <Card elevation={elevation} className={classes.card} onClick={(handleExpandClick? handleExpandClick:undefined)}>
      <CardHeader
        className={classes.cardHeader}
        avatar={avatar}
        action={
          action
        }
        title={title}
        subheader={subHeader}
      />
      <CardContent className={classes.cardContent} style={disableContentPadding?{padding:'0px'}:undefined}>
        {children}
      </CardContent>
     {handleExpandClick && <><CardActions disableSpacing className={classes.cardActions}>
       {handleAddExpandedContent && <IconButton color="secondary" aria-label="add" >
          <AddIcon  onClick={handleAddExpandedContent} />
        </IconButton>}
       
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
         // onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
       <Collapse in={expanded} timeout="auto" unmountOnExit>
       <CardContent className={classes.cardExpand} onClick={function(event) {event.stopPropagation()}}>
       {expandedContent}
       </CardContent>
      </Collapse></>
    
    }
    </Card>
  );
}
SimpleCard.propTypes = {
    classes: PropTypes.object.isRequired,
    //onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string.isRequired,
    subHeader: PropTypes.string,
    children: PropTypes.any,
    avatar: PropTypes.node,
    elevation: PropTypes.number,
    expandedContent:PropTypes.any,
    handleExpandClick: PropTypes.func,
    expanded:PropTypes.bool,
    handleAddExpandedContent:PropTypes.func,
    disableContentPadding:PropTypes.bool
  };
  export default withStyles(styles, { withTheme: true })(SimpleCard);