import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Load from '../../components/Load';
import Tabs from '../../components/Tabs';
import InventoryForm from './inventoryForm';

// import Inbox from '../Inbox';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: 'inherit',
    // overflow: 'scroll',
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  section: {
    margin: theme.spacing(2, 4),
  },
});

class Inventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0,
      
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
  
  }
  handleTabChange = (event,newValue) => {
    let currentTab = this.state.selectedTab;
    if (newValue !== currentTab) {
      this.setState({selectedTab: newValue})
    }
  }
  
 

  createData(sk,name, calories, fat, carbs, protein) {
    return {
        sk, name, calories, fat, carbs, protein 
    };
  }
  
  

  render() {
    const {classes,  getFromGlobalState, setGlobalState} = this.props;
    return (
     
      <Load isloading={this.state.isloading}>
        <Paper elevation={0} className={classes.paper}>

          {/*  <Inbox
       heading='Requests'
       getFromGlobalState={getFromGlobalState}
      setGlobalState={setGlobalState}
       >
    </Inbox> */}
       
    <Tabs value={this.state.selectedTab} handleChange={this.handleTabChange}> 
   
      <InventoryForm 
      heading='Plant Protection' 
      inventoryName='inv_chemical'
      getFromGlobalState={getFromGlobalState}
      setGlobalState={setGlobalState}
      initialState={this.props.getFromGlobalState('inv_chemical')}
      metaData={{
        products: this.props.getFromGlobalState('chemicals'),
        blocks: this.props.getFromGlobalState('blocks'),
        crops: this.props.getFromGlobalState('crops')
      }}
      activityName={'sprayings'}
      />
      <InventoryForm 
      heading="Fertiliser" 
      inventoryName='inv_fertiliser'
      getFromGlobalState={getFromGlobalState}
      setGlobalState={setGlobalState}
      initialState={this.props.getFromGlobalState('inv_fertiliser')}
      metaData={{
        products: this.props.getFromGlobalState('fertilisers'),
        blocks: this.props.getFromGlobalState('blocks'),
        crops: this.props.getFromGlobalState('crops')
      }}
      activityName={'fertilisings'}
      />
  </Tabs>
  
    
       </Paper>
       </Load>
    );
  }
}
export default withStyles(styles)(Inventory);
