import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color:'inherit',
  },
  wrapper: {
    position: 'relative',
    color:'inherit',
  },
  fabProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: 12,
    left: 0,
    zIndex: 1,
  },
}));

export default function LoaderFab(props) {
  const classes = useStyles();
  const {loading, success, onClick, disabled,icon } = props;
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>

        <IconButton
          edge="start"
          color="inherit"
       // style={{color:'white'}}
          onClick={onClick}
          className={buttonClassname}
          aria-label="save"
          disabled={disabled}
        >
          {success ? icon : <ErrorIcon />}
        </IconButton>
        {loading && <CircularProgress size={24} className={classes.fabProgress} />}
      </div>
    </div>
  );
}
LoaderFab.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  icon: PropTypes.any,
};
