import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import FormLabel from '@material-ui/core/FormLabel';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import OperatorSelector from '../../components/OperatorSelector';
import Location from '../../components/Location';
import ExpansionPanelDetail from '../../components/ExpansionPanelDetail';
import { Button } from '@material-ui/core';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  group: {
    flexDirection: 'row',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
});

{/*const filterItems = (arr, selectedBlocks) => {
  let pests = {};
  for (var ob of selectedBlocks){
  let plantingVariety = ob.plantingOB.variety;
  let allowedChemicals=[];
  arr.forEach(function (chemical) {
    let idx = chemical.allowedVarieties.findIndex(c=> c === plantingVariety);
    if (idx !== -1){
      allowedChemicals.push(chemical)
    }
    
  });
  pests[ob.planting] = [...allowedChemicals];
}
  return pests;
};
*/}

const filterItems = (arr, slectedBlock, target, fieldOrFixed,crops, pests, targets) => {
  const plantingCrop = slectedBlock.plantingOB.crop;
  let allowedPests=[];
  if (!target){
    return allowedPests;
  }
  const targetTypeSk = targets[targets.map((x)=>x.name).indexOf(target)]?.sk;
  if (target === 'Other'){
    allowedPests =  arr.filter((f)=> f.targetDetail?.nonCropDetail?.monitorType === fieldOrFixed && f.targetType === targetTypeSk);
    return allowedPests;
  }else {


  
  arr.forEach(function (pest) {
    

    let idx = crops.findIndex(c=> c.sk === plantingCrop );

    const crop = crops[crops.map((x)=>x.sk).indexOf(plantingCrop)];
    const cropName = crop.name;
    const possibleObservations = pest.targetDetail?.observableOn.map((x)=>x.name);
    

    // let idx = crops.findIndex(c=> c.sk === plantingCrop && pest.target === target && pest.monitorType === fieldOrFixed);
    // if (idx !== -1 &&  pest.target === target && pest.monitorType === fieldOrFixed){
    //   allowedPests.push(pest)
    // }

    if(possibleObservations.includes(cropName)){
      allowedPests.push(pest)
    }
    
  });
  const pestsAllowed = filterByPestType(allowedPests,target,targets)
 
  return pestsAllowed;
}


};

const filterByPestType = (arr,type,targetTypes)=>{

  const targetTypeSk = targetTypes[targetTypes.map((x)=>x.name).indexOf(type)].sk;

   return arr.filter((x)=> x.targetType === targetTypeSk )

};

const convertObjectToString=(obj)=> {
  let result = '';

  if (obj.hasOwnProperty('lat') && typeof obj.lat === 'number') {
      result += `lat:${obj.lat} `;
  }

  if (obj.hasOwnProperty('lng') && typeof obj.lng === 'number') {
      result += `lng:${obj.lng}`;
  }

  return result.trim();
}

const RenderPestingForm = ({selectedBlock,handleBatchChange, handleBlur, errors, classes, handleBatchCheckChange, metaData, handleLocationBatchChange,expandedSetting,handleSettingExpand,handlePestingsMonitored,onApplyAll,firstFormValid,index,selectedBlocks}) => {

  const selectedPest = metaData.monitorings.find((p=>p.sk === selectedBlock.record.pest));
  if (selectedPest && !selectedBlock.record.numPointsScoutedOverride){
    selectedBlock.record.numPointsScouted = selectedPest.quantityOfUnit;
  };

  const targetSelected = selectedBlock?.record?.target || '';

  return (
    <Card style={{width:'100%', marginBottom:'15px'}} key={index}>
      <CardContent>
  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
  <Typography variant="h6" color="inherit" noWrap className={classes.location}>
  {selectedBlock.label}
</Typography></Grid>
<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          margin="normal"
          required
          fullWidth
          type="date"
    //defaultValue={new Date()}
          id="date"
          label="Date"
          name="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={selectedBlock.record.date}
          onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
          onBlur={handleBlur.bind(this,selectedBlock.planting)}
          error={errors[selectedBlock.planting] && errors[selectedBlock.planting].date ? true : undefined}
          helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].date}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Location
          onChange={handleLocationBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
          value={selectedBlock?.record?.geoLocation}
          id="geoLocation"
          required
          name="geoLocation"
          // setSnack={setSnack}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginBottom:10}}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel required>Target</FormLabel>
              <RadioGroup
                aria-label="target"
                name="target"
                id="target"
                className={classes.group}
                value={targetSelected}
                onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
              >
                <FormControlLabel value="Pest" control={<Radio id='target' />} label="Pest" />
                <FormControlLabel value="Disease" control={<Radio id='target' />} label="Disease" />
                <FormControlLabel value="Beneficial" control={<Radio id='target'/>} label="Beneficial" />
                <FormControlLabel value="Other" control={<Radio id='target' />} label="Other" />
                <FormControlLabel value="Phenology" control={<Radio id='target' />} label="Phenology" />
                {errors[selectedBlock.planting] && errors[selectedBlock.planting].target && <FormHelperText className={classes.helperText}>{errors[selectedBlock.planting].target}</FormHelperText>}
              </RadioGroup>
            </FormControl>
          </Grid>
          {
            filterItems(metaData.monitorings, selectedBlock, selectedBlock.record.target, 'Field', metaData.crops, metaData.pests, metaData.targetTypes).map((monitoring)=>{
              return <ExpansionPanelDetail
                  heading={monitoring.targetDetail.name}
                  key={monitoring.targetDetail.sk}
                  overwriteDisplay
                  expanded={expandedSetting}
                  handleExpand={handleSettingExpand}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {monitoring.cropSpecific === 'Yes' && monitoring.targetDetail.observableOn[0].stages.map((x,index)=>(
                        <>
                        <InputLabel shrink htmlFor="pest" style={{marginBottom:10}}>{x.stageDescription}</InputLabel>
                          {x.unitNum === 'Number' &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="number"
                            inputProps={{ 'min': '0.00001', 'step':'any' }}
                            id="units"
                            label={selectedPest?.target !== 'Other'? `Number ${selectedPest?.targetDetail?.name} observed`: `${selectedPest.selectedPest?.targetDetail?.nonCropDetail?.unit} observed`}
                          // label={`$${selectedPest.unit} observed`}
                            name="units"
                            // value={selectedBlock.record?.stages[index]?.unit}
                            onChange={(event)=>handlePestingsMonitored(selectedBlock.planting,selectedBlock.block,event,x.sk,monitoring.sk,monitoring.targetDetail.sk,monitoring.cropSpecific,monitoring.targetDetail.observableOn[0].sk)}
                            onBlur={handleBlur.bind(this,selectedBlock.planting)}
                            error={errors[selectedBlock.planting] && errors[selectedBlock.planting].units ? true : undefined}
                            helperText={errors.units}
                          />
                          </Grid>
                      } 
                      {x.unitNum === 'Percentage' &&
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="number"
                            inputProps={{ 'min': '0.00001', 'step':'any' }}
                            id="units"
                            label="Percentage"
                            name="units"
                            value={selectedBlock.record.units}
                            onChange={(event)=>handlePestingsMonitored(selectedBlock.planting,selectedBlock.block,event,x.sk,monitoring.sk,monitoring.targetDetail.sk,monitoring.cropSpecific,monitoring.targetDetail.observableOn[0].sk)}
                            onBlur={handleBlur.bind(this,selectedBlock.planting)}
                            error={errors[selectedBlock.planting] && errors[selectedBlock.planting].units ? true : undefined}
                            helperText={errors.units}
                          />
                          </Grid>
                      } 
                      {x.unitNum === 'Present' &&
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <FormControl component="fieldset" className={classes.formControlRadio}>
                            <FormLabel required>Present</FormLabel>
                            <RadioGroup
                              aria-label="units"
                              name="units"
                              id="units"
                              className={classes.group}
                              value={selectedBlock?.record?.stages && selectedBlock?.record?.stages[selectedBlock?.record?.target] && selectedBlock?.record?.stages[selectedBlock?.record?.target][index]}
                              onChange={(event)=>handlePestingsMonitored(selectedBlock.planting,selectedBlock.block,event,x.sk,monitoring.sk,monitoring.targetDetail.sk,monitoring.cropSpecific,monitoring.targetDetail.observableOn[0].sk,index,selectedBlock.record.target)}
                            >
                              <FormControlLabel value='1' control={<Radio id='units' />} label="Yes" />
                              <FormControlLabel value='0' control={<Radio id='units' />} label="No" />
                              {errors[selectedBlock.planting] && errors[selectedBlock.planting].units && <FormHelperText className={classes.helperText}>{errors[selectedBlock.planting].units}</FormHelperText>}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      } 
                </>))}
                {monitoring.cropSpecific === 'No' && monitoring.targetDetail.nonCropDetail.unitNum === 'Number' &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="number"
                            inputProps={{ 'min': '0.00001', 'step':'any' }} 
                            id="units"
                            label={selectedBlock?.record?.target !== 'Other'? `Number ${monitoring?.targetDetail?.name} observed`: `Number observed`}
                          // label={`$${selectedPest.unit} observed`}
                            name="units"
                            value={selectedBlock.record?.stages && selectedBlock.record?.stages[selectedBlock.record.target] && selectedBlock.record?.stages[selectedBlock.record.target][index]}
                            onChange={(event)=>handlePestingsMonitored(selectedBlock.planting,selectedBlock.block,event,undefined,monitoring.sk,monitoring.targetDetail.sk,monitoring.cropSpecific,undefined,index,selectedBlock.record.target)}
                            onBlur={handleBlur.bind(this,selectedBlock.planting)}
                            error={errors[selectedBlock.planting] && errors[selectedBlock.planting].units ? true : undefined}
                            helperText={errors.units}
                          />
                          </Grid>
                  } 
                   {monitoring.cropSpecific === 'No' && monitoring.targetDetail.nonCropDetail.unitNum === 'Percentage' &&
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                          margin="normal"
                          required
                          fullWidth
                          type="number"
                          inputProps={{ 'min': '0.00001', 'step':'any' }}
                          id="units"
                          label="Percentage"
                          name="units"
                          value={selectedBlock.record?.stages && selectedBlock.record?.stages[selectedBlock.record.target] && selectedBlock.record?.stages[selectedBlock.record.target][index]}
                          onChange={(event)=>handlePestingsMonitored(selectedBlock.planting,selectedBlock.block,event,undefined,monitoring.sk,monitoring.targetDetail.sk,monitoring.cropSpecific,undefined,index,selectedBlock.record.target)}
                           onBlur={handleBlur.bind(this,selectedBlock.planting)}
                          error={errors[selectedBlock.planting] && errors[selectedBlock.planting].units ? true : undefined}
                          helperText={errors.units}
                        />
                    </Grid>
                  }  {monitoring.cropSpecific === 'No' && monitoring.targetDetail.nonCropDetail.unitNum === 'Present' &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl component="fieldset" className={classes.formControlRadio}>
                        <FormLabel required>Present</FormLabel>
                        <RadioGroup
                          aria-label="units"
                          name="units"
                          id="units"
                          className={classes.group}
                          value={selectedBlock.record?.stages && selectedBlock.record?.stages[selectedBlock.record.target] && selectedBlock.record?.stages[selectedBlock.record.target][index]}
                          onChange={(event)=>handlePestingsMonitored(selectedBlock.planting,selectedBlock.block,event,undefined,monitoring.sk,monitoring.targetDetail.sk,monitoring.cropSpecific,undefined,index,selectedBlock.record.target)}
                         >
                          <FormControlLabel value='1' control={<Radio id='units' />} label="Yes" />
                          <FormControlLabel value='0' control={<Radio id='units' />} label="No" />
                          {errors[selectedBlock.planting] && errors[selectedBlock.planting].units && <FormHelperText className={classes.helperText}>{errors[selectedBlock.planting].units}</FormHelperText>}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    } 
                </Grid>
              </ExpansionPanelDetail>
            })
          }
           {index === 0  && selectedBlocks.length > 1 &&  (
              <Grid style={{ marginTop: '20px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={onApplyAll.bind(this, selectedBlock.planting, selectedBlock.block, selectedBlock.record)}
                  disabled={firstFormValid}
                >
                  Apply to All
                </Button>
              </Grid>
              )}
          {/* {selectedBlock.record.target && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="pest">Name</InputLabel>
                <Select
                native
                fullWidth 
                required
                displayEmpty
                value={selectedBlock.record.pest}
                onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
                onBlur={handleBlur.bind(this,selectedBlock.planting)}
                error={errors[selectedBlock.planting] && errors[selectedBlock.planting].pest? true : undefined}
                inputProps={{
                  name: 'pest',
                  id: 'pest',
                }}
              >
                <option value=""></option>
                {metaData.monitorings && filterItems(metaData.monitorings, selectedBlock, selectedBlock.record.target, 'Field', metaData.crops, metaData.pests, metaData.targetTypes).map ((pest) => 
                <>
                <option value={pest.sk}>{pest.targetDetail?.name}</option>
                </>
          )}
              </Select>
              {errors[selectedBlock.planting] && errors[selectedBlock.planting].pest && <FormHelperText className={classes.helperText}>{errors[selectedBlock.planting].pest}</FormHelperText>}
              </FormControl>
              </Grid>
          } */}

        


         {/* {selectedBlock.record.pest && selectedPest && <><Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
          margin="normal"
          required
          fullWidth
          // disabled={!selectedBlock.record.numPointsScoutedOverride} 
          type="number"
          inputProps={{ 'min': '0.00001', 'step':'any' }}
          id="numPointsScouted"
          label={selectedPest.target !== 'Other'? selectedPest.cropSpecific === "Yes" ? `${unit} (s) Monitored` : 
            `${unit
            } (s) monitored`: 'Points monitored'}
          name="numPointsScouted"
          value={selectedBlock.record.numPointsScouted}
          onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
          onBlur={handleBlur.bind(this,selectedBlock.planting)}
          error={errors[selectedBlock.planting] && errors[selectedBlock.planting].numPointsScouted ? true : undefined}
          helperText={errors.numPointsScouted}
      />
      </Grid> */}
       {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
       <FormControlLabel
        control={
          <Checkbox
            id='numPointsScoutedOverride'
            checked={selectedBlock.record.numPointsScoutedOverride}
            onChange={handleBatchCheckChange.bind(this,selectedBlock.planting,selectedBlock.block)}
            name='numPointsScoutedOverride'
            //indeterminate
          />
        }
        label="Override"
      />
       </Grid> */}

       {/* {unitNum === 'Number' &&
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
        margin="normal"
        required
        fullWidth
        type="number"
        inputProps={{ 'min': '0.00001', 'step':'any' }}
        id="units"
        label={selectedPest.target !== 'Other'? `Number ${selectedPest?.targetDetail?.name} observed`: `${selectedPest.selectedPest?.targetDetail?.nonCropDetail?.unit} observed`}
       // label={`$${selectedPest.unit} observed`}
        name="units"
        
        value={selectedBlock.record.units}
        onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
        onBlur={handleBlur.bind(this,selectedBlock.planting)}
        error={errors[selectedBlock.planting] && errors[selectedBlock.planting].units ? true : undefined}
        helperText={errors.units}
      />
      </Grid>
  } */}
  {/* {unitNum === 'Percentage' &&
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
        margin="normal"
        required
        fullWidth
        type="number"
        inputProps={{ 'min': '0.00001', 'step':'any' }}
        id="units"
        label="Percentage"
        name="units"
        value={selectedBlock.record.units}
        onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
        onBlur={handleBlur.bind(this,selectedBlock.planting)}
        error={errors[selectedBlock.planting] && errors[selectedBlock.planting].units ? true : undefined}
        helperText={errors.units}
      />
      </Grid>
  } */}
  {/* {unitNum === 'Present' &&
       <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
       <FormControl component="fieldset" className={classes.formControlRadio}>
         <FormLabel required>Present</FormLabel>
         <RadioGroup
           aria-label="units"
           name="units"
           id="units"
           className={classes.group}
           value={selectedBlock.record.units}
           onChange={handleBatchChange.bind(this,selectedBlock.planting,selectedBlock.block)}
         >
           <FormControlLabel value='1' control={<Radio id='units' />} label="Yes" />
           <FormControlLabel value='0' control={<Radio id='units' />} label="No" />
           {errors[selectedBlock.planting] && errors[selectedBlock.planting].units && <FormHelperText className={classes.helperText}>{errors[selectedBlock.planting].units}</FormHelperText>}
         </RadioGroup>
       </FormControl>
     </Grid>
  } */}

  </CardContent>
  </Card>

  )
}
function PestingFormBatch(props) {
  const { firstFormValid, onApplyAll, classes, errors, handleBlur, metaData, selectedBlocks , handleBatchChange, handleBatchCheckChange, handleLocationBatchChange,handlePestingsMonitored } = props;
  //const pestsToScoutFor = filterItems(metaData.monitorings,selectedBlocks);
  const [state,setState] = useState({expandedSetting:''});

  if (!metaData.monitorings){
    return null;
  }


  const handleSettingExpand = (setting) => {
    const { expandedSetting } = state;
    if (expandedSetting !== setting) {
      setState({ expandedSetting: setting });
    } else {
      setState({ expandedSetting: '' });
    }
  }
  
  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start" >
   {selectedBlocks && selectedBlocks.map((selectedBlock, index) => (
     <RenderPestingForm 
     selectedBlock={selectedBlock}
     handleBatchChange={handleBatchChange}
     handleBlur={handleBlur}
     errors={errors}
     classes={classes}
     handleBatchCheckChange={handleBatchCheckChange} 
     metaData={metaData}
     handleLocationBatchChange={handleLocationBatchChange}
     expandedSetting={state.expandedSetting}
     handleSettingExpand={handleSettingExpand}
     handlePestingsMonitored={handlePestingsMonitored}
     onApplyAll={onApplyAll}
     firstFormValid={firstFormValid}
     index={index}
     selectedBlocks={selectedBlocks}
     />
    //  (selectedBlock,handleBatchChange, handleBlur, errors, classes,handleBatchCheckChange, metaData, handleLocationBatchChange,state.expandedSetting,handleSettingExpand,handlePestingsMonitored,onApplyAll,firstFormValid, index,selectedBlocks)
      
  ))}
  </Grid>
  );
}

PestingFormBatch.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBatchCheckChange: PropTypes.func.isRequired,
  handleLocationBatchChange: PropTypes.func.isRequired,
  handleCommonChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleCommonBlur: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
  planting:PropTypes.object.isRequired,
  selectedBlocks: PropTypes.array.isRequired,
  handleBatchChange: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
  handlePestingsMonitored:PropTypes.func.isRequired,
  onApplyAll: PropTypes.func.isRequired,
  firstFormValid:PropTypes.bool.isRequired
};

export default withStyles(styles)(PestingFormBatch);
