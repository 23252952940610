/* eslint-disable no-plusplus */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-arrow-callback */
import { openDB, deleteDB, wrap, unwrap } from 'idb';

// const DATABASE_NAME = 'Farms';
const DATABASE_VERSION = 5;
// const db = idb.default;

export const BUCKETS = ['Bucket01', 'Bucket02'];
export const BUSINESS_UNITS = ['BU1', 'BU2', 'BU3', 'BU4', 'BU5'];
export const STATUS = ['In Progress', 'Completed', 'Pending'];
/**
 * Initialize the IndexedDB.
 * see https://developers.google.com/web/ilt/pwa/lab-indexeddb
 * for information as to why we use switch w/o breaks for migrations.
 * add do the database version and add a switch case each time you need to
 * change migrations
 */


class OfflineService {
  async open(dbName) {
    const db = await openDB(dbName, DATABASE_VERSION, {
      upgrade(dbUp, oldVersion, newVersion) {
        console.log('got db', oldVersion, 'new', newVersion);
        switch (oldVersion) {
          case 0:
          // a placeholder case so that the switch block will
          // execute when the database is first created
          // (oldVersion is 0)
            console.log("executing version 0");
          case 1:
            dbUp.createObjectStore('farms', {
              // The 'id' property of the object will be the key.
              keyPath: 'sk',
            });
            console.log("executing version 1");
          case 2:
            dbUp.createObjectStore('calls', {
              // The 'id' property of the object will be the key.
              keyPath: 'id',
              autoIncrement: true,
            });
            console.log("executing version 2");
          case 3:
            dbUp.deleteObjectStore('calls');
            console.log("executing version 3");
            dbUp.createObjectStore('calls', {
              // The 'id' property of the object will be the key.
              keyPath: 'sk',
            });
          case 4:
            console.log("executing version 4");
            dbUp.createObjectStore('defaults', {
              // The 'id' property of the object will be the key.
              keyPath: 'sk',
            });
        }
      },
    });
    return db;
  }

  async put(dbName, objectStore, object, tenant) {
    try {
      

      let records = await this.getAll(dbName, objectStore,tenant);
      if (records){
        const idx = records.records.findIndex((r) => r.sk === object.sk);
        if (idx !== -1){
          records.records[idx]=object;
        }
        else {
          records.records.push(object);
        }
      }
      else {
        records = {records:[object], sk:tenant };
      }
     // if (key) {
     //   tx.store.put(object, key);
     // } else {
      const db = await this.open(dbName);
      const tx = db.transaction(objectStore, 'readwrite');
       tx.store.put(records);
      //}
      await tx.done;
    } catch (e) {
      console.log ('put error caught', e);
      throw (e);
    }
  }
  async putFlat(dbName, objectStore, object) {
    try {
      const db = await this.open(dbName);
      const tx = db.transaction(objectStore, 'readwrite');
       tx.store.put(object);
      //}
      await tx.done;
    } catch (e) {
      console.log ('put error caught', e);
      throw (e);
    }
  }
  async delete(dbName, objectStore, object, tenant) {
    try {
      

      let records = await this.getAll(dbName, objectStore,tenant);
      if (records){
        const idx = records.records.findIndex((r) => r.sk === object.sk);
        if (idx !== -1){
          records.records.splice(idx,1);
        }

      

     // if (key) {
     //   tx.store.put(object, key);
     // } else {
      const db = await this.open(dbName);
      const tx = db.transaction(objectStore, 'readwrite');
       tx.store.put(records);
      //}
      await tx.done;
    }
    } catch (e) {
      console.log ('put error caught', e);
      throw (e);
    }
  }


  async delete2(dbName, objectStore, objectKey) {
    try {
      const db = await this.open(dbName);
      const tx = db.transaction(objectStore, 'readwrite');
      tx.store.delete(objectKey);
      await tx.done;
    } catch (e) {
      console.log ('delete error caught', e);
      throw (e);
    }
  }

  async replaceAll(dbName, objectStore,tenant, objects) {
    try {
      const db = await this.open(dbName);
      const tx = db.transaction(objectStore, 'readwrite');
      tx.store.delete(tenant);
      //for (let i = 0; i < objects.length; i++) {
        tx.store.put(objects);
      //}
      await tx.done;
    } catch (e) {
      console.log ('replaceAll error caught', e);
      throw (e);
    }
  }

  async getAll(dbName, objectStore,tenantIn) {
    try {
      let tenant = tenantIn;

      if (!tenant){
        const tCall = await this.getAllFlat(dbName,'defaults');
        if (tCall && tCall.length > 0){
          tenant = tCall[0].activeSite;
        }
      }

      const db = await this.open(dbName);
      //const tx = db.transaction(objectStore, 'readwrite');
      //tx.store.get(tenant);
      return await db.get(objectStore, tenant);
    } catch (e) {
      console.log ('getAll error caught', e);
      throw (e);
    }
  }

  async getAllFlat(dbName, objectStore) {
    try {
      const db = await this.open(dbName);
      return await db.getAll(objectStore);
    } catch (e) {
      console.log ('getAll error caught', e);
      throw (e);
    }
  }
}

export const Service = new OfflineService();
