import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import uuid from 'uuid';
import { toStore, createObject, getErrorMessage } from '../../libs/storage';
import Load from '../../components/Load';
import metadata from '../../metadata.json';
// import orchards from './orchards.json';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    // overflow: 'scroll',
  },
  section: {
    margin: theme.spacing(2, 4),
  },
});

class Archive extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  async componentDidMount() {
    const { getFromGlobalState } = this.props;
    const currentUser = getFromGlobalState('currentUser').username;
    this.setState({ currentUser });
  }

   processCSV = (str, delim = ',') => {
     const headers = str.slice(0, str.indexOf('\n')).split(delim);
     const rows = str.slice(str.indexOf('\n') + 1).split('\n');

     const newArray = rows.map((row) => {
       const values = row.split(delim);
       const eachObject = headers.reduce((obj, header, i) => {
         obj[header] = values[i].replace('\r', '');
         return obj;
       }, {});
       return eachObject;
     });

     // setCsvArray(newArray)
     console.log('new arr', newArray);
   }

readFile = async (input) => {
  // return false; ///////////////
  const file = input.target.files[0];

  const reader = new FileReader();

  reader.readAsText(file);

  reader.onload = async function () {
    //  console.log(reader.result);
    const str = reader.result;
    const headers = str.slice(0, str.indexOf('\n')).split(';');
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');

    const newArray = rows.map((row) => {
      const values = row.split(';');
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header.replace('\r', '')] = values[i].replace('\r', '');
        return obj;
      }, {});
      return eachObject;
    });

    // setCsvArray(newArray)
    //  console.log('new arr', JSON.stringify(newArray));
    // this.createCropData(newArray);
    // return newArray;

    const newEntries = [];
    // loop over array and inject the objects looking like

    for (const item of newArray) {
      // macadamias
      /* const parentSK = 'init_56c7764d-177f-4184-8d84-df56b5e26117_setchild_7563ad0a-74c7-4b6e-9521-d4b829de4425_setChild_';
      const ob =  {
        setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
        sk: parentSK.concat(uuid.v1()),
        rootStock: item.rootStock.trim(),
        version: 1,
        cropName: 'init_56c7764d-177f-4184-8d84-df56b5e26117_setchild_7563ad0a-74c7-4b6e-9521-d4b829de4425',
        activeTenant: 'aeba4040-1dc4-11ec-9f88-e121604a510b',
        cultivar: item.cultivarName.trim(),
        createdAt: 1632947910323,
        createdBy: 'us-east-1:4f16bd35-3bd9-483f-b8e0-b43e64bc295e',
        isGeneral: true,
        cropType: 'init_56c7764d-177f-4184-8d84-df56b5e26117',
        rootStockType: item.rootStockType.trim(),
        cultivarType: item.cultivarType.trim(),
        type: 'cropsDetail'
       }; */
      // avos
      /* const parentSK = 'init_5ed65b75-da77-42bd-befc-b147b2ef2c72_setchild_43629a90-caf4-47d6-9aed-37fceafbbfc3_setChild_';
       const ob =  {
        setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
        sk: parentSK.concat(uuid.v1()),
        rootStock: item.rootStock.trim(),
        version: 1,
        cropName: 'init_5ed65b75-da77-42bd-befc-b147b2ef2c72_setchild_43629a90-caf4-47d6-9aed-37fceafbbfc3',
        activeTenant: 'aeba4040-1dc4-11ec-9f88-e121604a510b',
        cultivar: item.cultivarName.trim(),
        createdAt: 1632947910323,
        createdBy: 'us-east-1:4f16bd35-3bd9-483f-b8e0-b43e64bc295e',
        isGeneral: true,
        cropType: 'init_5ed65b75-da77-42bd-befc-b147b2ef2c72',
        rootStockType: item.rootStockType.trim(),
        cultivarType: item.cultivarType.trim(),
        type: 'cropsDetail'
       }; */
      const parentSK = item.parentSK.concat('_setchild_').concat(uuid.v1());
      const ob = {
        setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
        sk: parentSK,
        name: item.cropName,
        activeTenant: 'aeba4040-1dc4-11ec-9f88-e121604a510b',
        isGeneral: true,
        type: 'cropsSetting',
      };
      const newUUID = uuid.v1();
      const ob2 = {
        setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
        sk: parentSK.concat('_setchild_').concat(newUUID),
        // rootStock: item.rootStock.trim(),
        version: 1,
        cropName: parentSK,
        activeTenant: 'aeba4040-1dc4-11ec-9f88-e121604a510b',
        // cultivar: item.cultivarName.trim(),
        createdAt: 1632947910323,
        createdBy: 'us-east-1:4f16bd35-3bd9-483f-b8e0-b43e64bc295e',
        isGeneral: true,
        cropType: item.parentSK,
        // rootStockType: item.rootStockType.trim(),
        // cultivarType: item.cultivarType.trim(),
        type: 'cropsDetail',
      };

      try {
        const creation = await createObject('67d106c9-0f9e-4811-9aaa-6b814ccf7263', 'calls', toStore(ob), ob.activeTenant);

        if (creation.status) {
          ob.uploaded = true;
          const creation2 = await createObject('67d106c9-0f9e-4811-9aaa-6b814ccf7263', 'calls', toStore(ob2), ob2.activeTenant);
          if (creation2.status) {
            ob2.uploaded = true;
          }
        } else {
          ob.uploaded = false;
        }

        console.log(ob.sk, 'uploaded', ob.uploaded);
        console.log(ob2.sk, 'uploaded', ob2.uploaded);
      } catch (e) {
        this.props.setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
        return false;
      }
    }
    console.log('inserts done');
  };

  reader.onerror = function () {
    console.log(reader.error);
  };
}

toCsv(arr) {
  return arr.reduce((csvString, row) => {
    csvString += row.join(',');
    csvString += '\r\n'; // ";";//"\n";
    return encodeURIComponent(csvString);
  }, '');
}

createCropData(arr) {
  // const arr = this.readFile('/Users/ccdupreez/Development/Projects/featherleaf/macs');

}

  handleSubmit = async (event, dialogObject) => {
    try {
      return false;
      /* const items = [];
      const results = orchards.results;
      const blee = 1;
     // let str = '';
      for (var i = 0; i < results.length; i ++){

        const ob = {};
       // const idx = results[i].polygon.indexOf(' ');
       // const firstPoint = results[i].polygon.substring(0,idx);
       //  const str = `${results[i].date},${results[i].hectares},${results[i].id},${results[i].orchard_id},${firstPoint}`;

        ob.date = results[i].date;
        ob.hectares = results[i].hectares;
        ob.id = results[i].id;
        ob.orchard_id = results[i].orchard_id;
        const idx = results[i].polygon.indexOf(',');
        ob.lat = results[i].polygon.substring(0,idx);
        const idx2 = results[i].polygon.indexOf(' ');
        ob.lng = results[i].polygon.substring(idx+1,idx2);
        items.push(ob);
      }

      //const csv = result.map(e => e.join(',')).join('\n');
     //// var bb = new Blob(str);
      //bb.append(str);
    /////  var fileSaver = window.saveAs(bb.getBlob(), "orchards.csv");
      //console.clear();

      let csv ='';

      // Loop the array of objects
      for(let row = 0; row < items.length; row++){
          let keysAmount = Object.keys(items[row]).length
          let keysCounter = 0

          // If this is the first row, generate the headings
          if(row === 0){

             // Loop each property of the object
             for(let key in items[row]){

                                 // This is to not add a comma at the last cell
                                 // The '\r\n' adds a new line
                 csv += key + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
                 keysCounter++
             }
          }else{
             for(let key in items[row]){
                 csv += items[row][key] + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
                 keysCounter++
             }
          }

          keysCounter = 0
      }

      // Once we are done looping, download the .csv by creating a link
      let link = document.createElement('a')
      link.id = 'download-csv'
      link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
      link.setAttribute('download', 'yourfiletextgoeshere.csv');
      document.body.appendChild(link)
      document.querySelector('#download-csv').click()

*/

      /* //////// const gg = [];
      gg.push(result);
      let csvContent = "data:text/csv;charset=utf-8,"
      + gg.map(e => e.join(";")).join("\n");
      var encodedUri = encodeURI(csvContent);
window.open(encodedUri); */
      // console.log(str);

      // await maintain({operation: 'setUserIds'});
      //  this.loadCropData();
    } catch (e) {
      alert(e);
    }
  }

  render() {
    const { classes, reload } = this.props;
    return (
      <Load isloading={this.state.isloading}>
        <Paper elevation={0} className={classes.paper}>
          <Typography component="h1" variant="h5">
          {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`}
          </Typography>
          <IconButton
            aria-label="Edit"
            color="primary"
            className={classes.menuButton}
            onClick={reload}
          >
            <RefreshIcon />
          </IconButton>
          <Grid container className={classes.root} spacing={2} alignItems="flex-start">
            <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper elevation={0} className={classes.section}>
                <Button text="maintain" color="inherit" onClick={this.handleSubmit}> Maintain</Button>
                <TextField
                  margin="normal"
                  required
                  type="file"
           // fullWidth
                  id="number"
           // label="Block Number"
                  name="number"
                  style={{ minWidth: '200px' }}
           // autoFocus

                  onChange={this.readFile.bind(this)}
                />
                <Divider variant="middle" />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Load>

    );
  }
}
export default withStyles(styles)(Archive);
