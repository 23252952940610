import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import GenDialog from '../../components/GenDialog';
import { validate, validateForm } from '../../libs/validate';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

const requiredFields = [
  { name: 'name', min: 1, max: 100 },
];

class FacilityD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      facility: {},
      errors: { count: 0 },
      changed: false,

    };
  }

  componentDidMount() {
    if (this.props.isNew) {
      this.setState({ facility: {} });
    } else {
      const facility = this.props.selectedSetting;
      this.setState({ facility });
    }
  }

  validateField(field, requiredFields) {
    const error = validate(field, this.state.facility, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const facility = { ...this.state.facility };
    facility[event.target.id] = event.target.value;
    this.setState({ facility, changed: true });
  }

  render() {
    const {
      classes, open, isNew, setClose, handleUpdate, metaData, getFromGlobalState,
    } = this.props;
    const { facility, errors } = this.state;
    const formValid = validateForm(facility, requiredFields);

    return (

      <GenDialog
        open={open}
        dialogObject={facility}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary="init_setting_"
        dynamoType="facilities"
        valid={formValid}
        changed={this.state.changed}
        newTitle="New facility"
        title={facility.name}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
           
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoFocus
              value={facility.name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="discription"
              label="Discription"
              name="discription"
              value={facility.description}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.discription ? true : undefined}
              helperText={errors.discription}
            />
          </Grid>
        </Grid>
      </GenDialog>

    );
  }
}
FacilityD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedSetting: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(FacilityD);
