import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import GenDialog from '../../components/GenDialog';
import { getNameFromKey2 } from '../../libs/genLib';
import { validate, validateForm } from '../../libs/validate';


const styles = theme => ({
    paper: {
      marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      display: 'flex',
    flexWrap: 'wrap',
    },
    formControl: {
      minWidth: 120,
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    helperText :{
      color: 'red',
    },
    group: {
      margin: theme.spacing(1, 0),
    },
   formControlRadio: {
    marginTop: theme.spacing(2),
   },
    
  });

  
const requiredFields = [
    {name:'crop', min: 1, max: 100},
  // {name:'valper100l',min:1,max:40},
  //  {name:'application',min:1,max:200},
   // {name:'totalUnit', min: 1, max: 40},
  ];


class FertCropD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      fertCrop: {},
      errors: {count:0},
      varieties:[],
      changed:false,
      
    
    };
  }
  
  componentDidMount() {
    if (this.props.isNew){
      this.setState({fertCrop: {}});
    }
    else {
      this.setState({fertCrop: this.props.fertCrop});
    }
  }


  validateField (field, requiredFields)
  {
      let error = validate(field, this.state.fertCrop, requiredFields)
      let errors = {...this.state.errors};
      errors[field] = error.msg;
      this.setState({errors: errors }); 
  }

  handleBlur = (event) => {
    return this.validateField (event.target.id, requiredFields);
  }
  handleRadioChange = (name,event) => {
    let fertCrop = {...this.state.fertCrop};
    fertCrop[name] = event.target.value; 
    this.setState({ fertCrop:fertCrop,changed:true });
  }

  handleChange = (event) => {
    let fertCrop = {...this.state.fertCrop};
    fertCrop[event.target.id] = event.target.value; 
    this.setState({ fertCrop:fertCrop ,changed:true});
  }

  handleAppMethodChange = (event) => {
    let fertCrop = {...this.state.fertCrop};
    fertCrop.application = {};

    switch (event.target.value) {
      case 'handPlant': {
        fertCrop.application.method = 'handPlant';
        fertCrop.application.methodText = 'Hand';
        fertCrop.application.dosage = 'kg';
        fertCrop.application.unit = 'plant';
        break;
      }
      case 'handHa': {
        fertCrop.application.method = 'handHa';
        fertCrop.application.methodText = 'Hand';
        fertCrop.application.dosage = 'kg';
        fertCrop.application.unit = 'ha';
        break;
      }
      case 'fertilizerSpreader': {
        fertCrop.application.method = 'fertilizerSpreader';
        fertCrop.application.methodText = 'Fertilizer Spreader';
        fertCrop.application.dosage = 'kg';
        fertCrop.application.unit = 'ha';
        break;

      }
      case 'fertigation' : {
        fertCrop.application.method = 'fertigation';
        fertCrop.application.methodText = 'Fertigation';
        fertCrop.application.dosage = 'l';
        fertCrop.application.unit = '100l';
        break;

      } 
      case 'drench' : {
        fertCrop.application.method = 'drench';
        fertCrop.application.methodText = 'Drench';
        fertCrop.application.dosage = 'ml';
        fertCrop.application.unit = 'l';
        break;
      }
      case 'foliarSpray': {
        fertCrop.application.method = 'foliarSpray';
        fertCrop.application.methodText = 'Foliar Spray';
        fertCrop.application.dosage = 'l';
        fertCrop.application.unit = '100l';
        break;
      }
      case 'arealSpray': {
        fertCrop.application.method = 'arealSpray';
        fertCrop.application.methodText = 'Areal Spray';
        fertCrop.application.dosage = 'l';
        fertCrop.application.unit = '100l';
        break;
      }
      default: {
        fertCrop.application = { method: 'none', dosage: 'none', unit: 'none' };
       // return;
      }
    }
    // fertCrop[event.target.id] = event.target.value; 
    this.setState({ fertCrop:fertCrop ,changed:true});
  }
  
  render() {
    const { classes, open, isNew, setClose, handleUpdate, parent, metaData, getFromGlobalState } = this.props;
    const {fertCrop, errors} = this.state;
    const crops = metaData;
    //console.log('crops!!!!',crops);
   const formValid = validateForm(fertCrop, requiredFields);
    
    return (
 
      <GenDialog
      open={open}
      dialogObject={fertCrop}
      setClose={setClose}
      isNew={isNew}
      handleUpdateDialogObject={handleUpdate.bind(this)}
      skPrimary="_setchild_"
      dynamoType="fertcrops"
      valid={formValid}
      changed={this.state.changed}
      newTitle='New Crop'
      subTitle={`${parent.name}`}
      title={!isNew ? getNameFromKey2(fertCrop.crop, crops, 'name'): ''}
      parentKey={parent.sk}
      getFromGlobalState={getFromGlobalState}
      >
         <Grid container className={classes.root} spacing={1} alignItems="flex-start" >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
         <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="crop">Crop</InputLabel>
          <Select
          native
          fullWidth
          required
          displayEmpty
          value={fertCrop.crop}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.crop? true : undefined}
          inputProps={{
            name: 'crop',
            id: 'crop',
          }}
        >
          <option value=""></option>
          {crops && crops.map ((crop) => 
          <>
          <option value={crop.sk}>{crop.name}</option>
          </>
     )}
          
        </Select>
        {errors.crop&& <FormHelperText className={classes.helperText}>{errors.crop}</FormHelperText>}
        </FormControl>
        
        </Grid>
       {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
<FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="application">Application Method</InputLabel>
          <Select
          native
          fullWidth
          required
          displayEmpty
          value={fertCrop.application && fertCrop.application.method ? fertCrop.application.method : ""}
          onChange={this.handleAppMethodChange}
          onBlur={this.handleBlur}
          error={errors.application? true : undefined}
          inputProps={{
            name: 'application',
            id: 'application',
          }}
        >
           <option value=""></option>
          <option value="handPlant">Hand kg/Plant</option>
          <option value="handHa">Hand kg/Ha</option>
          <option value="fertilizerSpreader">Fertilizer Spreader kg/Ha</option>
          <option value="fertigation">Fertigation l/100l</option>
          <option value="drench">Drench ml/l per Tree</option>
          <option value="foliarSpray">Foliar Spray l/100l</option>
          <option value="aerialSpray">Aerial Spray l/100l</option>
         
          
        </Select>
        {errors.application&& <FormHelperText className={classes.helperText}>{errors.application}</FormHelperText>}
        </FormControl>
        </Grid>*/}
     {/*} <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
       {fertCrop.application && fertCrop.application.method &&  <TextField
            margin="normal"
            required
            type="number"
            inputProps={{ 'min': '0.00001', 'step':'any' }}
            fullWidth
            id="valper100l"
            label={`${fertCrop.application.dosage} per ${fertCrop.application.unit}`}
            name="valper100l"
            
            value={fertCrop.valper100l}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.valper100l ? true : undefined}
            helperText={errors.valper100l}
       />}
     </Grid>*/}
     </Grid>
         {/* <TextField
            margin="normal"
            required
            fullWidth
            id="valperUnit"
            label={`Undiluted chemical per plant or hectare in ${parent.unit}`}
            name="valperUnit"
            type="number"
            inputProps={{ 'min': '0.00001', 'step':'any' }}
            
            value={fertCrop.valperUnit}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.valperUnit ? true : undefined}
            helperText={errors.valperUnit}
          />
         */}
          
         {/* <FormControl component="fieldset" className={classes.formControlRadio}>
        <FormLabel required >Plant or hectare</FormLabel>
        <RadioGroup
        
          aria-label="totalUnit"
          name="totalUnit"
          id="totalUnit"
          className={classes.group}
          value={fertCrop.totalUnit}
          onChange={this.handleRadioChange.bind(this,"totalUnit")}
        >
          <FormControlLabel value="Plant" control={<Radio />} label="Plant" />
          <FormControlLabel  value="Hectare" control={<Radio />} label="Hectare" />
          {errors.totalUnit&& <FormHelperText className={classes.helperText}>{errors.totalUnit}</FormHelperText>}
        </RadioGroup>
        </FormControl>*/}
      </GenDialog>
     
    );
  }
}
FertCropD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  fertCrop: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  metaData:PropTypes.array,
  parent:PropTypes.object.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(FertCropD);
