import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const styles = (theme) => ({

  submit: {
    margin: theme.spacing(1),
  },

});

function EditTable(props) {
  const {
    title,
    rows,
    headCells,
    onRowAdd,
    onRowUpdate,
    onRowDelete,
    isLoading,
    actions,
  } = props;

  return (

    <MaterialTable
      title={title}
      icons={tableIcons}
      actions={actions || undefined}
      elevation={0}
      columns={headCells}
      data={rows}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      isLoading={isLoading}
      options={{
        filtering: true,
        sorting: true,
        maxBodyHeight: 400,
        // tableLayout: 'fixed',
        pageSize: 20,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [20, 40, 80, 200],
        addRowPosition: 'first',
        draggable: false,
        rowStyle: {
          fontSize: '0.875rem',
          fontFamily: 'Roboto',
          fontWeight: 400,
        },
      }}
      editable={onRowAdd && onRowUpdate && {
        onRowAdd: async (newData) => {
          await onRowAdd(newData);
        },
        onRowUpdate: async (newData, oldData) => {
          await onRowUpdate(newData, oldData);
        },
        onRowDelete: async (oldData) => {
          await onRowDelete(oldData);
        },
      }}
    />

  );
}
EditTable.propTypes = {
  onRowAdd: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onRowUpdate: PropTypes.func.isRequired,
  onRowDelete: PropTypes.string.isRequired,
  actions: PropTypes.array,
  rows: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
export default withStyles(styles, { withTheme: true })(EditTable);
