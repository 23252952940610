import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import LoadingIndicator from '../LoadingIndicator';

export default function Load(props) {
  const { children, isloading, style } = props;
  return (
    <>
      {isloading ? <LoadingIndicator style={style} /> : <>{children}</>}
    </>
  );
}
Load.defaultProps = {
  style: { height: '100%' },
};
Load.propTypes = {
  isloading: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
