import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),

    },
  },
  table: {
    // width: '50%',
  },
  col1: {
    width: '80px',
  },
}));

export default function MonitoringDetail(props) {
  const classes = useStyles();
  const { data } = props;
  if (!data || !data.payload) {
    return null;
  }
  return (
    <div className={classes.root}>

      <TableContainer component={Paper} style={{marginBottom: '30px'}}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableBody>
            <TableRow key="date">
              <TableCell className={classes.col1} component="th" scope="row">Date</TableCell>
              <TableCell component="th" scope="row">{new Date(data.payload.date).toLocaleDateString()}</TableCell>
              <TableCell className={classes.col1} component="th" scope="row">Block</TableCell>
              <TableCell component="th" scope="row">{data.payload.blockName}</TableCell>
            </TableRow>
            <TableRow key="block">
              <TableCell className={classes.col1} component="th" scope="row">Target</TableCell>
              <TableCell component="th" scope="row">{data.payload.observationData.targetName}</TableCell>
              <TableCell className={classes.col1} component="th" scope="row">Stage</TableCell>
              <TableCell component="th" scope="row">{data.payload.observationData.stageDescription}</TableCell>

            </TableRow>
            <TableRow key="target">
              <TableCell className={classes.col1} component="th" scope="row">Value</TableCell>
              <TableCell component="th" scope="row">{data.value}</TableCell>
              <TableCell className={classes.col1} component="th" scope="row">Threshold Value</TableCell>
              <TableCell component="th" scope="row">{data.payload.observationData.thresholdValue ? data.payload.observationData.thresholdValue : 'NA'}</TableCell>
            </TableRow>
            <TableRow key="stage" />
            <TableCell className={classes.col1} component="th" scope="row">Threshold</TableCell>
            <TableCell component="th" scope="row">{data.payload.observationData.threshold ? `Low: ${data.payload.observationData.threshold.low} Med: ${data.payload.observationData.threshold.med} High: ${data.payload.observationData.threshold.high} ` : 'None'}</TableCell>
            <TableCell className={classes.col1} component="th" scope="row" />
            <TableCell component="th" scope="row" />
            <TableRow key="value" />
          </TableBody>

        </Table>
      </TableContainer>

    </div>
  );
}
MonitoringDetail.propTypes = {
  data: PropTypes.object.isRequired,
};
