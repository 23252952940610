import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Auth } from 'aws-amplify';
import LoaderButton from '../../components/LoaderButton';
import { createEntity } from '../../libs/storage';
import { validate, validateForm } from '../../libs/validate';
import Policy from '../../components/Policy';
import Privacy from '../../components/Policy/privacy';
import Terms from '../../components/Policy/terms';
// todo
// if user refreshes before entering code - can't get back to code.

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1),
  },
});

const requiredFieldsSignUp = [
  { name: 'name', min: 1, max: 40 },
  { name: 'surname', min: 1, max: 40 },
  {
    name: 'email', min: 1, max: 150, type: 'email',
  },
  {
    name: 'password', min: 8, max: 100, type: 'password',
  },
  // { name: 'passwordConfirm', min: 8, max: 100, type: 'password' },
];
const requiredFieldsConfirmation = [

  { name: 'confirmationCode', min: 1, max: 20 },
  // { name: 'passwordConfirm', min: 8, max: 100, type: 'password' },
];

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      user: {
        email: '',
        password: '',
        confirmPassword: '',
        confirmationCode: '',
        name: '',
        surname: '',
        tAnCAccepted: false,
        privacyAccepted: false,
      },

      // email: '',
      // password: '',
      // confirmPassword: '',
      // confirmationCode: '',
      newUser: null,
      errors: { count: 0 },
      displayTanC: false,
      displayPrivacy: false,
      showPassword: false,
    };
  }

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isloading: true });
    const { user } = this.state;
    const { userHasAuthenticated, history, setSnack } = this.props;

    try {
      await Auth.confirmSignUp(user?.email.toLowerCase(), user.confirmationCode);
      const signIn = await Auth.signIn(user?.email, user.password);
      // create our entity.
      await createEntity({
        userName: signIn.username, email: signIn.attributes.email, name: user?.name, surname: user.surname, shortName: user.shortName, entityType:"entity"
      });

      userHasAuthenticated(true);
      history.push('/');
    } catch (e) {
      // alert(e.message);
      setSnack({ open: true, variant: 'error', messageText: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error} ` : e.message) });
      this.setState({ isloading: false });
    }
  }

  showPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  onDisplayPTanC = () => {
    const { displayTanC } = this.state;
    this.setState({ displayTanC: !displayTanC });
  }

  onDisplayDataPrivacy = () => {
    const { displayPrivacy } = this.state;
    this.setState({ displayPrivacy: !displayPrivacy });
  }

  handleToggle = (event) => {
    const user = { ...this.state.user };
    user[event.target.id] = event.target.checked;
    this.setState({ user, changed: true });
  }

  handleChange = (event) => {
    const user = { ...this.state.user };
    user[event.target.id] = event.target.value;
    this.setState({ user, changed: true });
  }

      handleSubmit = async (event) => {
        event.preventDefault();
        const { user } = this.state;
        this.setState({ isloading: true });

        try {
          const newUser = await Auth.signUp({
            username: user?.email.toLowerCase(),
            password: user.password,
          });
          this.setState({
            newUser,
          });
        } catch (e) {
          // alert(e.message);
          this.props.setSnack({ open: true, variant: 'error', messageText: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error} ` : e.message) });
        }

        this.setState({ isloading: false });
      }

      validateField(field, requiredFields) {
        const { user } = this.state;
        const error = validate(field, user, requiredFields);
        const errors = { ...this.state.errors };
        errors[field] = error.msg;
        this.setState({ errors });
      }

      handleBlur = (event) => this.validateField(event.target.id, requiredFieldsSignUp)

      handleBlurConfo = (event) => this.validateField(event.target.id, requiredFieldsConfirmation)

      renderConfirmationForm(classes) {
        const { user, errors, isloading } = this.state;
        const formValid = validateForm(user, requiredFieldsConfirmation);
        return (
          <form onSubmit={this.handleConfirmationSubmit}>
            <Container component="main" maxWidth="xs">
              <Typography gutterBottom color="primary">An email containing your confirmation code was sent to the email address provided. Please enter that code below.</Typography>

              <TextField
                margin="normal"
                required
                fullWidth
                id="confirmationCode"
                label="Confirmation Code"
                name="confirmationCode"
                onBlur={this.handleBlurConfo}
                value={user.confirmationCode}
                onChange={this.handleChange}
                error={errors.confirmationCode ? true : undefined}
                helperText={errors.confirmationCode}
              />
              <br />
              <LoaderButton
                type="submit"
            // fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!formValid}
                isloading={isloading}
                text="Verify"
                loadingText="Verifying…"
              />
            </Container>
          </form>
        );
      }

      renderForm(classes) {
        const {
          user, isloading, errors, displayTanC, displayPrivacy, showPassword,
        } = this.state;
        const isMobile = !window.matchMedia('(min-width: 850px)').matches;
        let formValid = validateForm(user, requiredFieldsSignUp);
        formValid = (user.password === user.confirmPassword) ? formValid : false;
        return (
          <form onSubmit={this.handleSubmit}>
            <Container component="main" maxWidth="xs">
              <Grid container className={classes.root} spacing={1} alignItems="flex-start">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    type="text"
                    label="Name"
                    name="name"
                    autoFocus
                    value={user?.name}
                    onChange={this.handleChange}
                    onBlur={this.handleBlurConfo}
                    error={errors.name ? true : undefined}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="shortName"
                    type="text"
                    label="Entity Name"
                    name="shortName"
                    autoFocus
                    value={user?.shortName}
                    onChange={this.handleChange}
                    onBlur={this.handleBlurConfo}
                    error={errors.shortName ? true : undefined}
                    helperText={errors.shortName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="surname"
                    type="text"
                    label="Surname"
                    name="surname"
                    value={user.surname}
                    onChange={this.handleChange}
                    onBlur={this.handleBlurConfo}
                    error={errors.surname ? true : undefined}
                    helperText={errors.surname}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    type="email"
                    inputProps={{ autocapitalise: 'none' }}
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={user?.email}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    error={errors.email ? true : undefined}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    // type="password"
                    id="password"
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.showPassword}>
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      type: showPassword ? 'text' : 'password',
                    }}
                    value={user.password}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    error={errors.password ? true : undefined}
                    helperText={errors.password}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.showPassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      type: showPassword ? 'text' : 'password',
                    }}
                    value={user.confirmPassword}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    error={errors.confirmPassword ? true : undefined}
                    helperText={errors.confirmPassword}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Policy
                    message={<Terms />}
                    onChecked={this.handleToggle}
                    checked={user.tAnCAccepted}
                    linkCaption="View and accept Terms and Conditions"
                    policyCaption="Terms and Conditions"
                    onDisplayPolicy={this.onDisplayPTanC}
                    displayPolicy={displayTanC}
                    handleCancel={this.onDisplayPTanC}
                    id="tAnCAccepted"
                  />
                </Grid>
                {!isMobile && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography>
                    We are committed to protecting your privacy and the confidentiality of your personal information. Our policy is not just an exercise in complying with the law, but a continuation of our respect for you and your personal information.
                  </Typography>
                </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Policy
                    message={<Privacy />}
                    onChecked={this.handleToggle}
                    checked={user.privacyAccepted}
                    linkCaption="View and accept Privacy Policy"
                    policyCaption="Data Privacy"
                    onDisplayPolicy={this.onDisplayDataPrivacy}
                    displayPolicy={displayPrivacy}
                    handleCancel={this.onDisplayDataPrivacy}
                    id="privacyAccepted"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <LoaderButton
                    type="submit"
                // fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={!formValid || !user.tAnCAccepted || !user.privacyAccepted}
                    isloading={isloading}
                    text="Signup"
                    loadingText="Signing up…"
                  />
                </Grid>
              </Grid>
            </Container>
          </form>
        );
      }

      render() {
        const { classes } = this.props;
        const { newUser } = this.state;
        return (
          <div className="Signup">
            {newUser === null
              ? this.renderForm(classes)
              : this.renderConfirmationForm(classes)}
          </div>
        );
      }
}
Signup.propTypes = {
  userHasAuthenticated: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
};
export default withStyles(styles)(Signup);
