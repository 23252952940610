import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import ExpansionPanelDetail from '../../components/ExpansionPanelDetail';
import GenSetForm from '../Settings/genSetForm';
import FarmsD from '../FarmsD';
import uuid from 'uuid';
import { createObject, getFarms, toStore } from '../../libs/storage';
import { TextField } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '5px',
    backgroundColor: 'inherit',
  },
});

class FarmsAdminForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedSetting:'',
      entities:[]
    };
  }




  sortEntities = (data) => {
    const entities = [];
    const farms = [];
    const nonEntityRelated = {
      sk: "non_entity_related",
      entityType: "entity",
      name: "Z-Non Entity Related",
      shortName: "Z-Non Entity Related",
      farms: []
    };
  
    data.forEach(item => {
      if (item.entityType === 'entity') {
        entities.push(item);
      } else if (item.entityType === 'farm') {
        farms.push(item);
      }
    });
  
    farms.sort((a, b) => {
      const nameA = a.shortName || a.name || ''; // Use shortName if available, else use name
      const nameB = b.shortName || b.name || '';
      return nameA.localeCompare(nameB); // Compare alphabetically
    });
  
    entities.forEach(entity => {
      entity.farms = farms.filter(farm => farm?.parentFarm === entity?.sk); // Add related farms to the entity
    });
  
    entities.sort((a, b) => {
      const nameA = a.shortName || a.name || ''; // Use shortName if available, else use name
      const nameB = b.shortName || b.name || '';
      return nameA.localeCompare(nameB); // Compare alphabetically
    });
  
    farms.forEach(farm => {
      if (!farm?.parentFarm) {
        nonEntityRelated.farms.push(farm); // Push farms without a parent entity
      }
    });
  
    if (nonEntityRelated.farms.length > 0) {
      entities.push(nonEntityRelated);
    }
  
    return entities;
  };
  

  searchForFarmOrEntity(data, searchTerm) {
    const entities = [];
    const farms = [];
    const result = [];
    const nonEntityRelated = {
      sk: "non_entity_related",
      entityType: "entity",
      name: "Non Entity Related",
      shortName: "Non Entity Related",
      farms: []
    };
  
    data.forEach(item => {
      if (item.entityType === 'entity') {
        entities.push(item);
      } else if (item.entityType === 'farm') {
        farms.push(item);
      }
    });
  
    entities.forEach(entity => {
      const entityName = entity.shortName || entity.name || '';
      if (entityName.toLowerCase().includes(searchTerm.toLowerCase())) {
        entity.farms = farms.filter(farm => farm?.parentFarm === entity?.sk);
        result.push(entity);
      }
    });
  
    if (result.length === 0) {
      farms.forEach(farm => {
        const farmName = farm.shortName || farm.name || '';
        if (farmName.toLowerCase().includes(searchTerm.toLowerCase())) {
          const parentEntity = entities.find(entity => entity?.sk === farm?.parentFarm);
          if (parentEntity) {
            parentEntity.farms = [farm]; 
            result.push(parentEntity);
          } else {
            nonEntityRelated.farms.push(farm);
          }
        }
      });
    }
  
    if (nonEntityRelated.farms.length > 0) {
      result.push(nonEntityRelated);
    }
  
    return result;
  }
  

  componentDidMount() {
    const farms = async() =>{
      const allFarms  = await getFarms();
      const sortedEntities =  this.sortEntities(allFarms);
      this.props.setGlobalState({farmList:allFarms.filter((x)=>x.entityType === 'farm')})
      this.setState({entities:sortedEntities, originalEntities:allFarms});
    }
    farms();
  }

  updateGlobalState = (object, type) => {
    const data = { ...this.props.getFromGlobalState('settings') };
    data[type] = object;
    this.props.setGlobalState({ settings: data });
  }

  handleSubmit = async (dialogObject, event) => {
    event.preventDefault();
    console.log("look at these->", dialogObject, event);
    this.setState({ isSubmitting: true });
    this.setState({ messageText: '' });
    console.log("submiting now");
    try {
      if (!dialogObject.sk) {
        dialogObject.sk = `init_set_crops_${uuid.v1()}`;
        dialogObject.type = 'settings';
        dialogObject.version = 0;
      }
      const version = dialogObject.version ? dialogObject.version : 0;
      dialogObject.version = version + 1;
      let creation = {};
      dialogObject.activeTenant = this.props.getFromGlobalState('activeSite');
      // dialogObject.parentSk = sk
      creation = await createObject(this.props.getFromGlobalState('currentUser').username, 'calls', toStore(dialogObject), this.props.getFromGlobalState('activeSite'));
      // await createObject(toStore(dialogObject));
      if (creation.status) {
        dialogObject.cached = creation.cached;
      }

      this.setState({ isSubmitting: false });
      this.setState({ success: true });
      // todo: look at this below, doesn't look right
      this.updateGlobalState(dialogObject, 'set_crops');
      // this.props.handleUpdateDialogObject(dialogObject, this.props.parentKey);
      
      this.props.setClose();
    } catch (e) {
      this.setState(
        {
          success: false,
          isSubmitting: false,
          messageText: (e.response ? `${e.response.data.error.message}, api src: ${e.response.data.src} ` : e.message),
        },
      );
    }
  }


  handleSettingExpand = (setting) => {
    if (this.state.expandedSetting !== setting) {
      this.setState({ expandedSetting: setting });
    } else {
      this.setState({ expandedSetting: '' });
    }
  }

  handleChange =(e)=>{
    const searchString = e.target.value;
    if(searchString){
      const searchEntities = this.searchForFarmOrEntity(this.state.originalEntities,searchString);
      this.setState({entities:searchEntities})
    }else{
      const og = this.sortEntities(this.state.originalEntities);
      this.setState({entities:og})
    }
  }

  render() {
    const {classes } = this.props;
    const {expandedSetting, entities} = this.state
    return (

      <Paper elevation={0} style={{marginTop:20,padding:10}} className={classes.mainSection}>
        <TextField
              margin="normal"
              id="name"
              label="Search...."
              name="name"
              autoFocus
              disabled={entities.length == 0}
              // value={farms.name}
              onChange={this.handleChange}
              // onBlur={this.handleBlur}
              // error={errors.name ? true : undefined}
              // helperText={errors.name}
            />
        {entities.map((x)=>(
              <ExpansionPanelDetail
              heading={x.name || x.shortName}
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                  initialState={x.farms.map((f) => {
                    const ob = { ...f };
                    ob.isDisabled = f.isDisabled ? 'Yes' : 'No';
                    return ob;
                  })}
                  farm={x}
                  handleSubmit={this.handleSubmit}
                  isSubmitting={this.state.isSubmitting}
                  setGlobalState={this.props.setGlobalState}
                  getFromGlobalState={this.props.getFromGlobalState}
                  settingDialog={<FarmsD parentFarm={x.sk} />}
                  showDetails={true}
                  settingName="farmList"
                  settingHeading="Add"
                  displayLabelPrim="name"
                  displayLabelSec="description"
                  headCells={[
                    {
                      id: 'isDisabled', numeric: false, disablePadding: false, label: 'Disabled',
                    },
                    {
                      id: 'name', numeric: false, disablePadding: false, label: 'Name',
                    },
                    {
                      id: 'description', numeric: false, disablePadding: false, label: 'Description',
                    },
                    {
                      id: 'plantings', numeric: false, disablePadding: false, label: 'Plantings',
                    },
                    {
                      id: 'users', numeric: false, disablePadding: false, label: 'Users',
                    },
                  ]}
                />
            </ExpansionPanelDetail>
            ))}
      </Paper>
    );
  }
}
FarmsAdminForm.propTypes = {
  handleSubmit: PropTypes.func,

};
export default withStyles(styles)(FarmsAdminForm);
