import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import {
  LineChart, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush, ComposedChart, 
  Label,
  Bar,
} from 'recharts';
import { ColorLuminance, convertHex, randomColor2 } from '../../libs/genLib';

const PARENT = '#0e4553';

const formatXAxis = (tickItem) => new Date(tickItem).toLocaleDateString();

function selectColor(number) {
  const hue = number * 137.508; // use golden angle approximation
  return `hsl(${hue},50%,75%)`;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const b = 0;
    return (
      <div className="custom-tooltip">
        <p className="label">{`Date: ${new Date(payload[0].payload.date).toLocaleDateString()}`}</p>
        <p className="label">{`${payload[0].payload.blockName}`}</p>
        <p className="label">{`Value: ${payload[0].value}`}</p>
        <p className="desc">Anything you want can be displayed here.</p>
      </div>
    );
  }

  return null;
};

const formatTooltip = (value, name, props) => new Date(value).toLocaleDateString();

export default class ChartLine2 extends PureComponent {
  render() {
    const {
      title, data, keys, dataKey, height, name, handleClick,Ylabel, procedure
    } = this.props;
    const keysFilteredChem = keys.filter((x)=> !x.includes('Block'));
    const keysFilteredMon = keys.filter((x)=> x.includes('Block'));
    let highestChemical = 0;
    keysFilteredMon.map((z)=>{
      data.map((x)=>{
        if(x[z] >= highestChemical ){
          highestChemical =  parseInt(x[z]) + 2 
        }
      })
    });

    // keysFilteredChem.map((z)=>{
    //   newData = data.map((x)=>{
    //     if(x[z]){
    //       x[z] = highestChemical
    //     }
    //     return x
    //   })
    // });

    const newData = data.map((b)=>{
      keysFilteredChem.map((x)=>{
          if(b[x]){
            b[x] = highestChemical
          }
      })
      return b
    })
    
    return (
      <Paper elevation={0} style={{ borderRadius: '4px', overflow: 'hidden',marginTop:20 }}>
        {title && (
        <>
          <div style={{
            width: '100%', justifyContent: 'center', display: 'inline-flex', paddingTop: '5px',
          }}
          >
            <Typography variant="button" gutterBottom>{title}</Typography>

          </div>
        </>
        )}
        {procedure &&  <div style={{
            width: '100%', justifyContent: 'center', display: 'inline-flex', paddingTop: '5px',
          }}
          >
            <Typography style={{textAlign:'center',fontSize:12}} variant="button" gutterBottom>procedure: {procedure}</Typography>

          </div>}
        <ResponsiveContainer key={`${name} 3`} width="100%" height={height} style={{ cursor: 'pointer' }}>
          <ComposedChart
            width="100%"
            height={height}
            data={newData}
            name={name}
            key={`${name} 1`}
            margin={{
              top: 20,
              right: 20,
              left: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={dataKey} type="number" domain={['dataMin', 'dataMax']} tickFormatter={formatXAxis} />
            <YAxis label={{ value: Ylabel, angle: -90, position: 'insideLeft' }}/>
            <Tooltip labelFormatter={formatTooltip} /> 
           {/*} <Tooltip wrapperStyle={{ zIndex: 1000, backgroundColor: 'white' }}  content={<CustomTooltip />} />*/}
            {/*  <Brush dataKey={dataKey} /> */}
            <Legend wrapperStyle={{ fontSize: '12px' }} />
            { keys && keys.length && keys.map((k, i) => (
              !k.includes('Block') && <Bar
              name={k}
              key={`${name}-line-${k}-i`}
              dataKey={k}
              fill={selectColor(i + 1)}
              barSize={5}
            /> 
            ))}
            { keys && keys.length && keys.map((k, i) => (
              k.includes('Block') && <Line
                // onClick={handleClick}
                connectNulls
                type="monotone"
                activeDot={{ onClick: handleClick, r: 8 }}
                name={k}
                dot={{ r: 6 }}
                key={`${name}-line-${k}-i`}
                dataKey={k}
                stroke={selectColor(i + 1)}
                strokeWidth={2}
              /> 
            //   : <Bar
            //   name={k}
            //   key={`${name}-line-${k}-i`}
            //   dataKey={k}
            //   fill={selectColor(i + 1)}
            //   barSize={50}
            // /> 
            ))}
          </ComposedChart>
        </ResponsiveContainer>

      </Paper>

    );
  }
};

ChartLine2.defaultProps = {
  height: 400,
};

ChartLine2.propTypes = {
  data: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  height: PropTypes.string,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  Ylabel:PropTypes.string,
  procedure:PropTypes.string
};
