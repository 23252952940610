// import {
//  Polygon,
// } from '@react-google-maps/api';

import classifyPoint from 'robust-point-in-polygon';

export function convertHex(hex, opacity) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const result = `rgba(${r},${g},${b},${opacity / 100})`;
  return result;
}

export function getPolysBounds(polygons) {
  const bounds = new window.google.maps.LatLngBounds();
  for (let i = 0; i < polygons.length; i++) {
    const ply = new window.google.maps.Polygon({
      paths: polygons[i].paths,
    });
    const paths = ply.getPaths();
    paths.forEach((path) => {
      const ar = path.getArray();
      for (let i = 0, l = ar.length; i < l; i++) {
        bounds.extend(ar[i]);
      }
    });
  }
  return bounds;
}

/* function inside2(point, vs) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

  const x = point[0]; const
    y = point[1];

  let retVal = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0]; const
      yi = vs[i][1];
    const xj = vs[j][0]; const
      yj = vs[j][1];

    const intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) retVal = !retVal;
  }

  return retVal;
} */

function pathsToArray(paths) {

  const pathArray = [];
  for (let p = 0; paths && p < paths.length; p += 1) {
    pathArray.push([paths[p].lat, paths[p].lng]);
  }
  return pathArray;

}

export function isLocationInPoly(paths, lat, lng) {
  if (!lat || !lng || !paths) {
    return false;
  }
  // const ply = new Polygon({
  //   paths,
  // });
  const pathArray = pathsToArray(paths);
  const coord = [lat, lng];
  //return inside2(coord, pathArray);
  const val = classifyPoint(pathArray, coord);
  if (val === -1 || val === 0) {
    return true;
  }
  return false;
}


export function findBlock(blocks, lat, lng) {
  const coord = [lat, lng];
  let foundBlock = null;
  for (let b = 0; b < blocks.length; b += 1) {
    const block = blocks[b];
    const pathArray = pathsToArray(block.paths);
    const val = classifyPoint(pathArray, coord);
    if (val !== 1) {
      foundBlock = block;
      break;
    }
  }
  return foundBlock;
}

export function getPolyBounds(polygon) {
  const ply = new window.google.maps.Polygon({
    paths: polygon.paths,
  });
  const polybounds = new window.google.maps.LatLngBounds();
  const paths = ply.getPaths();
  paths.forEach((path) => {
    const ar = path.getArray();
    for (let i = 0, l = ar.length; i < l; i++) {
      polybounds.extend(ar[i]);
    }
  });
  return polybounds;
}

export function getPolyCenter(polygon) {
  const bounds = getPolyBounds(polygon);
  return bounds ? bounds.getCenter() : null;
}
