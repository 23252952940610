import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '360px',
    // width: '360px',
    backgroundColor: theme.palette.background.paper,
    maxHeight: '300px',
    // height: '150px',
    overflowY: 'auto',
  },
  formControl: {
    minWidth: 120,
    // marginTop: theme.spacing(2),
  },
  rootLoad: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function ListControl(props) {
  const {
    data, handleToggle, checkedItems, pickApplied, handleApplied, handleReasonsDataChange, arrayName, readOnly, planting, loading, hideCheck, onSelectAll, allSelected, handleChange, handleBlur, handleReentryOverride,
  } = props;

  const classes = useStyles();

  return (
    <>
      {loading && (
      <div className={classes.rootLoad}>
        {' '}
        <CircularProgress color="secondary" />
        {' '}
      </div>
      )}
      {onSelectAll && (
      <FormControlLabel
        value="start"
        style={{ float: 'right' }}
        control={(
          <Checkbox
           // edge="start"
            checked={allSelected}
            onChange={onSelectAll}
           // tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': 'SelectAll' }}
          />
)}
        label="Select All"
        labelPlacement="start"
      />
      )}
      <List className={classes.root}>
        {data && data.map((value) => {
          const labelId = `checkbox-list-label-${value.sk}`;

          return (
            <div>
              <ListItem className={classes.nested} disableRipple disabled={value.disabled} key={value.sk} role={undefined} dense button onClick={handleToggle && handleToggle.bind(this, value.sk, arrayName, planting)}>
                {!hideCheck && !value.disabled && (
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checkedItems && checkedItems.findIndex((p) => p.target === value.sk) !== -1}
                    tabIndex={-1}
                    disableRipple
                    disabled={!!value.disabled}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                )}
                <ListItemText id={labelId} primary={`${value.name}`} />
              </ListItem>
              <>
                {checkedItems && checkedItems.findIndex((p) => p.target === value.sk) !== -1 && (
                <div style={{ marginLeft: '30px', marginBottom: '25px' }}>
                  <Grid container className={classes.root} spacing={1} alignItems="flex-start">
                    {pickApplied && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormLabel required>Dosage applied</FormLabel>
                      <FormControlLabel
                        value="applied"
                        labelPlacement="start"
                        control={(
                          <Checkbox
                            color="secondary"
                            name="applied"
                            id={value.sk}
                            key={value.sk}
                            checked={checkedItems.find((p) => p.target === value.sk).applied}
                            onClick={(event) => handleApplied(event, value.sk, arrayName, planting)}
                          />
)}
                      />
                    </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        margin="normal"
                        disabled={readOnly || checkedItems.find((p) => p.target === value.sk).reentryOverride}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">days</InputAdornment>,
                          min: '0.00001',
                          step: 'any',
                        }}
                        id="reentry"
                        label="Re-entry Period"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="reentry"
                        // disabled={!!checkedItems.find((p) => p.sk === value.sk).reentryOverride}
                        value={checkedItems.find((p) => p.target === value.sk).reentry}
                        onChange={(event) => handleChange(event, value.sk)}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControlLabel
                        value="reentryOverride"
                        label="or deposit dry"
                        labelPlacement="end"
                        disabled={readOnly}
                        control={(
                          <Checkbox
                            color="secondary"
                            name="reentryOverride"
                            checked={checkedItems.find((p) => p.target === value.sk).reentryOverride}
                            onClick={(event) => handleReentryOverride(event, value.sk)}
                          />
)}

                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        margin="normal"
                        required
                        disabled={readOnly}
                        type="number"
                       // inputProps={{ min: '0.00001', step: 'any' }}
                        id="phi"
                        label="Pre-harvest interval (PHI)"
                        name="phi"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">days</InputAdornment>,
                          min: '0.00001',
                          step: 'any',
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={checkedItems.find((p) => p.target === value.sk).phi}
                        onChange={(event) => handleChange(event, value.sk)}
                      />

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        margin="normal"
                        required
                        id="dosage"
                        label="Dosage"
                        disabled={readOnly}
                        name="valperUnit"
                        type="number"
                        value={checkedItems.find((p) => p.target === value.sk).dosage}
                        onChange={(event) => handleChange(event, value.sk)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="cdosagerop">Dosage Unit</InputLabel>
                        <Select
                          native
                          disabled={readOnly}
          // fullWidth
                          required
                          displayEmpty
                          value={checkedItems.find((p) => p.target === value.sk).dosageUnit}
                          onChange={(event) => handleChange(event, value.sk)}
                     // onBlur={this.handleBlur}
                    //  error={errors.dosageUnit ? true : undefined}
                          inputProps={{
                            name: 'dosageUnit',
                            id: 'dosageUnit',
                          }}
                        >
                          <option value="" />
                          <option value="ml/ha">ml/ha</option>
                          <option value="L/ha">L/ha</option>
                          <option value="g/ha">g/ha</option>
                          <option value="kg/ha">kg/ha</option>
                          <option value="/ha">/ha</option>
                          <option value="/trap">/trap</option>
                          <option value="g/L">g/L</option>
                          <option value="g/100L">g/100L</option>
                          <option value="ml/100L">ml/100L</option>
                          <option value="L/100L">L/100L</option>
                          <option value="/m2">/m2</option>
                          <option value="ml/plant">ml/plant</option>
                          <option value="g/plant">g/plant</option>

                        </Select>
                        {/*  {errors.crop && <FormHelperText className={classes.helperText}>{errors.crop}</FormHelperText>} */}
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                )}

              </>
            </div>

          );
        })}
      </List>
    </>

  );
}
ListControl.defaultProps = {
  onSelectAll: null,
  allSelected: false,
};
ListControl.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  checkedItems: PropTypes.array.isRequired,
  handleToggle: PropTypes.func.isRequired,
  arrayName: PropTypes.string.isRequired,
  planting: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  hideCheck: PropTypes.bool.isRequired,
  onSelectAll: PropTypes.func,
  allSelected: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleReentryOverride: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  pickApplied: PropTypes.bool.isRequired,
  handleApplied: PropTypes.func.isRequired,
  handleReasonsDataChange: PropTypes.func.isRequired,
};
