import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { validate, validateForm } from '../../libs/validate';
import {
  toStore,
  archiveObject,
  getCropDetails,
  createBatchRecords,
  getErrorMessage,
} from '../../libs/storage';
import GenDialog from '../../components/GenDialog';
import Load from '../../components/Load';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

const requiredFields = [
  { name: 'cultivarType', min: 1, max: 80 },
  { name: 'cultivar', min: 1, max: 80 },
  { name: 'rootStockType', min: 1, max: 80 },
  { name: 'rootStock', min: 1, max: 80 },
];

class SetCropD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isNew: this.props.isNew,
      crop: {},
      variety: {},
      errors: { count: 0 },
      changed: false,
      selectedCropType: '',
      selectedCrop: '',
      crops: [],
      thisCropDetails: [],
      cultivarTypes: [],
      cultivars: [],
      rootStockTypes: [],
      rootStockNames: [],
      updateObj: null,
      cropTypes: [],

    };
  }

  async componentDidMount() {
    const { getFromGlobalState } = this.props;
    const cropTypesG = getFromGlobalState('cropTypesSetting');
    const cropTypes = cropTypesG && cropTypesG.filter((c) => c.enabled === true);
   
    if (this.props.isNew) {
      const crop = {};
      crop.sk = `init_setting_${uuid.v1()}`;
      crop.type = 'gen_crops';
      const variety = {};
      //  variety.sk = `${crop.sk}_setchild_${uuid.v1()}`;
      //  variety.type='gen_varieties';
     
      this.setState({ crop, variety, cropTypes });
    } else {
      this.setState({ isLoading: false });
      const { crop, variety } = this.props;
      const varietyN = { ...variety };
      const cropTypesG = getFromGlobalState('cropTypesSetting');
      const cropTypes = cropTypesG && cropTypesG.filter((c) => c.enabled === true);
      const cropTypeSK = crop.cropType ? crop.cropType : crop.sk.substring(0, crop.sk.indexOf('_setchild_'));
      const cropType = cropTypes.find((c) => c.sk === cropTypeSK);
      await this.onCropTypeChange({ target: { value: cropType.sk } }, false);
      await this.onCropChange({ target: { value: crop.sk } }, false);
      await this.onCultivarTypeChange({ target: { value: varietyN.cultivarType } }, false);
      await this.onCultivarNameChange({ target: { value: varietyN.cultivar } }, false);
      await this.onRootStockTypeChange({ target: { value: varietyN.rootStockType } }, false);
      this.onChange({ target: { value: varietyN.rootStock, id: 'rootStock' } }, false);
      this.setState({ cropTypes, crop, variety: varietyN });
      this.setState({ isLoading: false });
    }
  }

  onCropTypeChange = async (event, changed = true) => {
    let { selectedCropType, crop, variety } = this.state;
    if (selectedCropType === event.target.value) {
      return true;
    }
    const newCrop = { ...crop };

    selectedCropType = event.target.value;
    newCrop.cropType = selectedCropType;
    newCrop.genCropSK = '';
    const newVariety = { ...variety };
    const crops = [];
    const cultivars = [];
    const cultivarTypes = [];
    const rootStockTypes = [];
    const rootStockNames = [];
    newVariety.cropType = '';
    newVariety.cultivarType = '';
    newVariety.rootStockType = '';
    newVariety.rootStock = '';
    newVariety.cultivar = '';
    this.setState({
      selectedCropType, isLoading: true, crops, cultivars, cultivarTypes, rootStockTypes, rootStockNames, variety: newVariety,
    });
    // this.setState({ selectedCropType, isLoading: true });
    const { getFromGlobalState, setGlobalState, setSnack } = this.props;
    const retVal = await getCropDetails(getFromGlobalState, setGlobalState, setSnack, selectedCropType);
    this.setState({
      changed, isLoading: false, crops: retVal.crops, crop: newCrop,
    });
  }

  onCropChange = async (event, changed = true) => {
    let {
      selectedCrop, selectedCropType, crop, variety,
    } = this.state;
    const { getFromGlobalState } = this.props;
    // if ( selectedCrop === event.target.value ){
    //   return true;
    // }
    selectedCrop = event.target.value;
    const newCrop = { ...crop };
    const genCrop = getFromGlobalState('genCrops')[selectedCropType].find((c) => c.sk === selectedCrop);
    const name = genCrop ? genCrop.name : '';
    newCrop.genCropSK = selectedCrop;
    newCrop.name = name;
    const newVariety = { ...variety };
    newVariety.cropName = selectedCrop;
    newVariety.cropType = selectedCropType;
    newVariety.rootStockType = '';
    newVariety.rootStock = '';
    newVariety.cultivar = '';
    newVariety.cultivarType = '';
    // const cropsDetail = getFromGlobalState('cropsDetail')[this.state.selectedCropType].filter(record => record.sk.startsWith(this.state.selectedCropType));
    const thisCrop = getFromGlobalState('cropsDetail')[selectedCropType].filter((r) => r.cropName === selectedCrop);
    const cultivarTypes = thisCrop && [...new Set(thisCrop.map((item) => item.cultivarType))];
    const cultivars = [];// thisCrop && [...new Set(thisCrop.map((item) => item.cultivar))];
    const rootStockTypes = []; // thisCrop && [...new Set(thisCrop.map((item) => item.rootStockType))];
    const rootStockNames = [];// thisCrop && [...new Set(thisCrop.map((item) => item.rootStock))];

    this.setState({
      changed, selectedCrop, cultivarTypes, cultivars, rootStockTypes, rootStockNames, crop: newCrop, variety: newVariety, thisCropDetails: thisCrop,
    });
  }

  onCultivarTypeChange = async (event, changed = true) => {
    const { selectedCrop, selectedCropType, variety } = this.state;
    const { getFromGlobalState } = this.props;
    // if ( selectedCrop === event.target.value ){
    //   return true;
    // }
    const newVariety = { ...variety };
    newVariety.cultivarType = event.target.value;
    newVariety.rootStockType = '';
    newVariety.rootStock = '';
    newVariety.cultivar = '';
    // const cropsDetail = getFromGlobalState('cropsDetail')[this.state.selectedCropType].filter(record => record.sk.startsWith(this.state.selectedCropType));
    const thisCrop = getFromGlobalState('cropsDetail')[selectedCropType].filter((r) => r.cropName === selectedCrop);
    const cultivarFilter = thisCrop.filter((ct) => ct.cultivarType === newVariety.cultivarType);
    // const cultivarTypes = cultivarFilter && [...new Set(cultivarFilter.map((item) => item.cultivarType))];
    const cultivars = cultivarFilter && [...new Set(cultivarFilter.map((item) => item.cultivar))];
    const rootStockTypes = [];
    const rootStockNames = [];

    this.setState({
      changed, selectedCrop, cultivars, rootStockTypes, rootStockNames, variety: newVariety, thisCropDetails: thisCrop,
    });
  }

  onCultivarNameChange = async (event, changed = true) => {
    const {
      selectedCrop, selectedCropType, variety,
    } = this.state;
    const { getFromGlobalState } = this.props;
    // if ( selectedCrop === event.target.value ){
    //   return true;
    // }
    const newVariety = { ...variety };
    newVariety.cultivar = event.target.value;
    newVariety.rootStockType = '';
    newVariety.rootStock = '';

    // const cropsDetail = getFromGlobalState('cropsDetail')[this.state.selectedCropType].filter(record => record.sk.startsWith(this.state.selectedCropType));
    const thisCrop = getFromGlobalState('cropsDetail')[selectedCropType].filter((r) => r.cropName === selectedCrop);
    const cultivarFilter = thisCrop.filter((ct) => ct.cultivarType === newVariety.cultivarType);
    const cultivarNameFilter = cultivarFilter.filter((t) => t.cultivar === newVariety.cultivar);

    // const cultivarTypes = cultivarFilter && [...new Set(cultivarFilter.map((item) => item.cultivarType))];
    //  const cultivarsFilter= cultivars.filter((cv)=>cv.cultivar ===newVariety.cultivar);
    const rootStockTypes = cultivarNameFilter && [...new Set(cultivarNameFilter.map((item) => item.rootStockType))];
    const rootStockNames = [];

    this.setState({
      changed, selectedCrop, rootStockTypes, rootStockNames, variety: newVariety, thisCropDetails: thisCrop,
    });
  }

  onRootStockTypeChange = async (event, changed = true) => {
    const {
      selectedCrop, selectedCropType, crop, variety, cultivars,
    } = this.state;
    const { getFromGlobalState } = this.props;
    // if ( selectedCrop === event.target.value ){
    //   return true;
    // }
    const newVariety = { ...variety };
    // newVariety.cultivar=;
    newVariety.rootStockType = event.target.value;
    newVariety.rootStock = '';

    // const cropsDetail = getFromGlobalState('cropsDetail')[this.state.selectedCropType].filter(record => record.sk.startsWith(this.state.selectedCropType));
    const thisCrop = getFromGlobalState('cropsDetail')[selectedCropType].filter((r) => r.cropName === selectedCrop);
    const cultivarFilter = thisCrop.filter((ct) => ct.cultivarType === newVariety.cultivarType);
    const cultivarNameFilter = cultivarFilter.filter((t) => t.cultivar === newVariety.cultivar);
    const rootStockTypeFilter = cultivarNameFilter.filter((t) => t.rootStockType === newVariety.rootStockType);

    // const cultivarTypes = cultivarFilter && [...new Set(cultivarFilter.map((item) => item.cultivarType))];
    //  const cultivarsFilter= cultivars.filter((cv)=>cv.cultivar ===newVariety.cultivar);
    // const rootStockTypes= cultivarNameFilter && [...new Set(cultivarNameFilter.map((item) => item.rootStockType))];
    const rootStockNames = rootStockTypeFilter && [...new Set(rootStockTypeFilter.map((item) => item.rootStock))];

    this.setState({
      changed, selectedCrop, rootStockNames, variety: newVariety, thisCropDetails: thisCrop,
    });
  }

  findCropDetail = () => {
    const {
      crop, variety, thisCropDetails,
    } = this.state;
    const { getFromGlobalState } = this.props;
    const retVal = {};

   // const thisCrop2 = getFromGlobalState('cropsDetail')[selectedCropType].filter((r) => r.cropName === selectedCrop);
    const thisCrop = thisCropDetails;
    const cultivarFilter = thisCrop.filter((ct) => ct.cultivarType === variety.cultivarType);
    const cultivarNameFilter = cultivarFilter.filter((t) => t.cultivar === variety.cultivar);
    const rootStockTypeFilter = cultivarNameFilter.filter((t) => t.rootStockType === variety.rootStockType);
    const cropDetail = rootStockTypeFilter.find((t) => t.rootStock === variety.rootStock);

   // const filter1 = thisCropDetails.filter((c) => c.cropType === variety.cropType && c.cropName === variety.cropName && c.cultivarType === variety.cultivarType && c.cultivar === variety.cultivar);

   // const cropDetail = thisCropDetails.find((c) =>  c.cropName === variety.cropName && c.cultivarType === variety.cultivarType && c.cultivar === variety.cultivar && c.rootStockType === variety.rootStockType && c.rootStock === variety.rootStock);
    if (cropDetail) {
      const batchInserts = [];
      let localCrop = {};
      const myCrops = getFromGlobalState('crops');
      const idx = (myCrops.findIndex((c) => c.sk === crop.genCropSK));
      if (idx === -1) {
        // no crop
        localCrop = { ...crop };
        batchInserts.push({ ...localCrop });
        localCrop.varieties = [];
        retVal.crop = localCrop;
      } else {
        // have crop
        localCrop = { ...myCrops[idx] };
        if (!localCrop.varieties) {
          localCrop.varieties = [];
        }
        retVal.crop = localCrop;
        // localCrop = {varieties, ...localCrop} = myCrops[idx];
      }

      const vIdx = localCrop.varieties.findIndex((v) => v.sk === cropDetail.sk);
      if (vIdx === -1) {
        const newVarietyRecord = {};
        newVarietyRecord.genVarietySK = cropDetail.sk;
        newVarietyRecord.sk = `${localCrop.sk}_setchild_${uuid.v1()}`;
        newVarietyRecord.type = 'gen_varieties';
        batchInserts.push(newVarietyRecord);
        localCrop.varieties.push(cropDetail);/// ////////
        retVal.variety = { ...newVarietyRecord };
        retVal.crop = localCrop;
      }
      return ({ retVal, batchInserts });
    }

    return {
      retVal: null, batchInserts: [], error: true, errorMsg: `Could not find_${JSON.stringify(variety)}`,
    };
  }

  onChange = (event, changed = true) => {
    const { variety } = this.state;
    const newVariety = { ...variety };
    newVariety[event.target.id] = event.target.value;
    this.setState({ variety: newVariety, changed });
  }

  validateField(field, requiredFields) {
    const error = validate(field, this.state.variety, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const crop = { ...this.state.crop };
    crop[event.target.id] = event.target.value;
    this.setState({ crop, changed: true });
  }

  handleUpdate = async () => {

  }

  handleDeletePreExecute = async (recordIn, parentSk) => {
    const { getFromGlobalState, setGlobalState, setSnack } = this.props;
    try {
      const varietySK = recordIn.variety.sk;
      const cropSK = recordIn.crop.sk;

      const genVarieties = [...getFromGlobalState('genVarieties')];
      const genCrops = [...getFromGlobalState('myGenCrops')];

      const genVarietyIdx = genVarieties.findIndex((g) => g.genVarietySK === varietySK);
      const genCropIdx = genCrops.findIndex((g) => g.genCropSK === cropSK);

      const activeTenant = getFromGlobalState('activeSite');
      await archiveObject({
        sk: genVarieties[genVarietyIdx].sk, archiveName: 'trash', type: 'gen_varieties', activeTenant,
      });
      const crops = getFromGlobalState('crops');
      const cropsN = [...crops];
      const cIdx = cropsN.findIndex((c) => c.sk === cropSK);
      if (cIdx !== -1) {
        // find the variety
        const vIdx = cropsN[cIdx].varieties.findIndex((v) => v.sk === varietySK);
        if (vIdx !== -1) {
          cropsN[cIdx].varieties.splice(vIdx, 1);
          genVarieties.splice(genVarietyIdx, 1);
          if (cropsN[cIdx].varieties.length === 0) {
            await archiveObject({
              sk: genCrops[genCropIdx].sk, archiveName: 'trash', type: 'gen_crops', activeTenant,
            });
            cropsN.splice(cIdx, 1);
            genCrops.splice(genCropIdx, 1);
          }
        }
        setGlobalState({ crops: cropsN, myGenCrops: genCrops, genVarieties });
      }

      return { status: true };
    } catch (e) {
      setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
      return { status: false };
    }
  }

  handleDelete = async () => {

  }

  handlePreExecute = async (dialogObject, deleting) => {
    if (deleting) {
      return { status: true };
    }
    const { getFromGlobalState, setGlobalState } = this.props;
    const updateObj = this.findCropDetail();
    try {
      if (updateObj.error) {
        this.props.setSnack({ open: true, variant: 'error', messageText: updateObj.errorMsg });
        return { status: false };
      }
      if (!updateObj || updateObj.batchInserts.length === 0) {
        return { status: false, errorMSG: 'This crop already exists on your farm' };
      }
      const recordsObject = { items: updateObj.batchInserts };
      recordsObject.activeTenant = this.props.getFromGlobalState('activeSite');
      recordsObject.type = 'batchupload';

      const creation = await createBatchRecords(this.props.getFromGlobalState('currentUser').username, 'calls', toStore(recordsObject, true), this.props.getFromGlobalState('activeSite'));
      if (creation.status) {
        if (updateObj.retVal.crop) {
          const crops = getFromGlobalState('crops');
          const newCrops = [...crops];
          const cIdx = newCrops.findIndex((p) => p.sk === updateObj.retVal.crop.sk);
          if (cIdx === -1) {
            newCrops.push(updateObj.retVal.crop);
          } else {
            newCrops[cIdx] = updateObj.retVal.crop;
          }
          setGlobalState({ crops: newCrops });
        }
      }
      return creation;
    } catch (e) {
      this.props.setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    }
    // this.props.handleUpdate(obj);
  }

  render() {
    const {
      open, isNew, setClose, getFromGlobalState, classes,
    } = this.props;
    const {
      crop,
      errors,
      variety,
      selectedCropType,
      crops,
      cultivarTypes,
      cultivars,
      rootStockTypes,
      rootStockNames,
      isLoading,
      cropTypes,
    } = this.state;
    const formValid = validateForm(variety, requiredFields);

   // const cropTypesG = getFromGlobalState('cropTypesSetting');
  //  const cropTypes = cropTypesG && cropTypesG.filter((c) => c.enabled === true);
    // const crops = getFromGlobalState('genCrops');
    // const cropsDetail = getFromGlobalState('cropsDetail');

    return (

      <GenDialog
        open={open}
        dialogObject={{ variety, crop }}
        setClose={setClose}
        allowDelete={!isLoading}
        isNew={isNew}
        handleDeletePreExecute
        handleDeletePreExecuteOnly
        handleDeletePreExecute={this.handleDeletePreExecute.bind(this)}
        handleDeleteDialogObject={this.handleDelete.bind(this)}
        handleUpdateDialogObject={this.handleUpdate.bind(this)}
        skPrimary="init_setting_"
        dynamoType="crops"
        changed={this.state.changed}
        valid={formValid}
        noServerInteraction
        preExecuteOnly
        handlePreExecute={this.handlePreExecute.bind(this)}
      // disableActions={crop.isGeneral}
        newTitle="New Crop"
        title={crop.name}
        getFromGlobalState={getFromGlobalState}
      >
        <Load isloading={isLoading}>
          <Grid container className={classes.root} spacing={2} alignItems="flex-start">
            <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="cropType">Crop type</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  disabled={cropTypes && cropTypes.length === 0}
                  displayEmpty
                  value={selectedCropType}
                  onChange={this.onCropTypeChange}
                  inputProps={{
                    name: 'cropType',
                    id: 'cropType',
                  }}
                >
                  <option value="" />
                  {cropTypes && cropTypes.map((cropType) => (
                    <>
                      <option value={cropType.sk}>{cropType.name}</option>
                    </>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="cropType">Crops</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  disabled={crops && crops.length === 0}
                  value={crop.genCropSK}
                  onChange={this.onCropChange}
                  inputProps={{
                    name: 'crop',
                    id: 'crop',
                  }}
                >
                  <option value="" />
                  {crops && crops.map((crop) => (
                    <>
                      <option value={crop.sk}>{crop.name}</option>
                    </>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="cropType">Cultivar type</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  disabled={cultivarTypes && cultivarTypes.length === 0}
                  value={variety.cultivarType}
                  onChange={this.onCultivarTypeChange}
                  inputProps={{
                    name: 'cultivarType',
                    id: 'cultivarType',
                  }}
                >
                  <option value="" />
                  {cultivarTypes && cultivarTypes.map((type) => (
                    <>
                      <option value={type}>{type}</option>
                    </>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="cropType">Cultivar name</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  disabled={cultivars && cultivars.length === 0}
                  value={variety.cultivar}
                  onChange={this.onCultivarNameChange}
                  inputProps={{
                    name: 'cultivar',
                    id: 'cultivar',
                  }}
                >
                  <option value="" />
                  {cultivars && cultivars.map((cultivar) => (
                    <>
                      <option value={cultivar}>{cultivar}</option>
                    </>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="cropType">Rootstock type</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  disabled={rootStockTypes && rootStockTypes.length === 0}
                  value={variety.rootStockType}
                  onChange={this.onRootStockTypeChange}
                  inputProps={{
                    name: 'rootStockType',
                    id: 'rootStockType',
                  }}
                >
                  <option value="" />
                  {rootStockTypes && rootStockTypes.map((type) => (
                    <>
                      <option value={type}>{type}</option>
                    </>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="cropType">Rootstock name</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  disabled={rootStockNames && rootStockNames.length === 0}
                  value={variety.rootStock}
                  onChange={this.onChange}
                  inputProps={{
                    name: 'rootStock',
                    id: 'rootStock',
                  }}
                >
                  <option value="" />
                  {rootStockNames && rootStockNames.map((name) => (
                    <>
                      <option value={name}>{name}</option>
                    </>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/*   <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Crop Name"
            name="name"
            autoFocus
            value={crop.name}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.number ? true : undefined}
            helperText={errors.number}
      /> */}
          </Grid>
        </Load>

      </GenDialog>

    );
  }
}
SetCropD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  crop: PropTypes.object,
  variety: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(SetCropD);
