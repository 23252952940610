import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: 'inherit',
  },
});

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function CenteredTabs(props) {
  const classes = useStyles();
  // const [value, setValue] = React.useState(0);
  const {
    children, value, handleChange, selectedTab,
  } = props;

  // function handleChange(event, newValue) {
  //  setValue(newValue);
  // }

  return (
    <Paper className={classes.root} elevation={0}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        // centered
      >
        {children && children.length && children.filter((c) => c).map((child, index) => <Tab label={child.props.heading} {...a11yProps(index)} />)}

      </Tabs>
      {children && children.length && children.map((child, index) => (
        <TabPanel value={value} index={index}>
          {value === index && child}
        </TabPanel>
      ))}
    </Paper>
  );
}
Tabs.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.any.isRequired,
  selectedTab: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

};
