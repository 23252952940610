import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PDFIcon from '@material-ui/icons/PictureAsPdfOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import CSVIcon from '@material-ui/icons/GetAppOutlined';
import Hidden from '@material-ui/core/Hidden';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import uuid from 'uuid';
import ConfirmDialog from '../ConfirmDialog';
import DateRangeDialog from '../DateRangeDialog';

import Snack from '../Snack';
import LoaderFab from '../LoaderFab';
import LoaderButton from '../LoaderButton';
import Can from '../Can';
import {
  toStore, createObject, archiveObject, createBatchRecords, getErrorMessage,
  deleteRejectedInvitation,
  sharedUserInvite,
  sharedUserAdd,
  addUser,
} from '../../libs/storage';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    //  display: 'flex',
    flexWrap: 'wrap',
    // overflowY:'hidden',
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },

    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(8),

    },
    // display: 'flex',
    // flexDirection: 'column',
    // margin: 'auto',
    // width: 'fit-content',
  },
  closeButton: {
    float: 'right',
    marginLeft: 'auto',
    color: theme.palette.grey[500],
  },
  deleteButton: {
    float: 'right',
    marginLeft: 'auto',
    // color: theme.palette.grey[500],
  },
  pdfButton: {
    float: 'right',
    marginLeft: 'auto',
    // color: theme.palette.grey[500],
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

});

const TransitionMobile = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class GenDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      success: true,
      confirmDeleteOpen: false,
      confirmReInvite:false,
      dateRangeDialogOpen: false,
      reportType: '',
      isDeleting: false,
      reportStartDate: '',
      reportEndDate: new Date().toISOString().substr(0, 10),
      // dialogObject: {},
      messageText: '',
    };
  }
  /* componentDidMount() {
    if (this.props.isNew){
      this.setState({dialogObject: {}});
    }
    else {
      this.setState({dialogObject: this.props.dialogObject});
    }
  } */
  /* handleChange = (event) => {
    let dialogObject = {...this.state.dialogObject};
    dialogObject[event.target.id] = event.target.value;
    this.setState({ dialogObject:dialogObject });
  } */

  setSnackClose = () => {
    this.setState({ messageText: '', success: true });
  }

  confirmDelete = () => {
    this.setState({ confirmDeleteOpen: true });
  }

  confirmReinviteopen=()=>{
    this.setState({confirmReInvite:true});
  }

  generatePDF = () => {
    this.setState({ dateRangeDialogOpen: true, reportType: 'pdf' });
  }

  generateCSV = () => {
    this.setState({ dateRangeDialogOpen: true, reportType: 'csv' });
  }

  handleCancelGeneratePDF = () => {
    this.setState({ dateRangeDialogOpen: false, reportType: '' });
  }

  handleCancelGenerateCSV = () => {
    this.setState({ dateRangeDialogOpen: false, reportType: '' });
  }

  handleCancelConfirmDialog = () => {
    this.setState({ confirmDeleteOpen: false });
  }

  cancelReinvite=()=>{
    this.setState({confirmReInvite:false});
  }

  setReportStartDate = (event) => {
    this.setState({ reportStartDate: event.target.value });
  }

  setReportEndDate = (event) => {
    this.setState({ reportEndDate: event.target.value });
  }

  handleGeneratePDF = () => {
    this.props.onGeneratePDF(this.state.reportStartDate, this.state.reportEndDate);
    this.setState({ dateRangeDialogOpen: false, reportType: '' });
  }

  handleGenerateCSV = () => {
    this.props.onGenerateCSV(this.state.reportStartDate, this.state.reportEndDate);
    this.setState({ dateRangeDialogOpen: false, reportType: '' });
  }

  handleReinvite = async ()=>{
    try{
      this.setState({ isDeleting: true });
      this.setState({ messageText: '' });
      const activeTenant = this.props.getFromGlobalState('activeSite')
      const dialogObject = { ...this.props.dialogObject, activeTenant};
      await deleteRejectedInvitation(dialogObject);
      delete dialogObject?.rejected;
      await addUser(dialogObject);
      this.props.handleDeleteDialogObject(dialogObject, this.props.parentKey);
      this.setState({ isDeleting: false });
      this.setState({ success: true });
      this.handleCancelConfirmDialog();
      this.props.setClose();
      this.props.setSnack({
        open: true, variant: 'info', autoHideDuration: 4000, messageText: 'User re-invitation sent. You can view the user post acceptance.',
      });
      return
    }catch(e){
      this.handleCancelConfirmDialog();
      this.props.setSnack({open:true,variant:'error',message:(e.response? `${e.response.data?.error?.message ? e.response.data?.error?.message:e.response?.data?.error}, api src: ${e.response?.data?.src} ` : e.message)});
      this.setState(
        {
          success: false,
          isDeleting: false,
          messageText: (e.response ? `${e.response.data?.error?.message ? e.response.data?.error?.message : e.response?.data?.error}` : e?.message),
        },
      );
    }
  }

  handleDelete = async (rejectedUser) => {
    this.setState({ isDeleting: true });
    this.setState({ messageText: '' });

    try {
      const dialogObject = { ...this.props.dialogObject };
      if (this.props.isNew) {
        return;
      }
      let preSuccess = true;
      if (this.props.handleDeletePreExecute) {
        preSuccess = await this.props.handleDeletePreExecute(dialogObject);
      } else if (!this.props.handleDeletePreExecuteOnly && this.props.handlePreExecute) {
        preSuccess = await this.props.handlePreExecute(dialogObject);
      }
      if (preSuccess && !this.props.handleDeletePreExecuteOnly) {
        // await deleteObject({sk:dialogObject.sk});
        const activeTenant = this.props.getFromGlobalState('activeSite');
        await archiveObject({
          sk: dialogObject.sk, archiveName: 'trash', type: dialogObject.type, activeTenant,
        });
      }
      this.props.handleDeleteDialogObject(dialogObject, this.props.parentKey);

      // if(rejectedUser){
      // const dialogObject = { ...this.props.dialogObject };
      // delete dialogObject?.sk;
      // this.props.handleSubmitAddExistingUser(dialogObject); 
      // }
      this.setState({ isDeleting: false });
      this.setState({ success: true });
      this.handleCancelConfirmDialog();
      this.props.setClose();
 
    } catch (e) {
      this.handleCancelConfirmDialog();
      // this.props.setSnack({open:true,variant:'error',message:(e.response? `${e.response.data.error.message ? e.response.data.error.message:e.response.data.error}, api src: ${e.response.data.src} ` : e.message)})
      this.setState(
        {
          success: false,
          isDeleting: false,
          messageText: (e.response ? `${e.response?.data?.error?.message ? e.response.data?.error?.message : e.response?.data?.error}` : e?.message),
        },
      );
    }
  }

  handleSubmit = async (event, dialogObject) => {

    !this.props.rejectedUser && event.preventDefault();
    const newproducts = [];
   
    if (this.props.preExecuteOnly) {
      this.setState({ isSubmitting: true });
      const retVal = await this.props.handlePreExecute(dialogObject);
      if (retVal.status) {
        this.setState({ isSubmitting: false });
        this.props.setClose();
      } else {
        this.setState(
          {
            success: false,
            isSubmitting: false,
            messageText: retVal.errorMSG,
          },
        );
      }
      return;
    }
    if (this.props.noServerInteraction) {
      this.props.handleUpdateDialogObject(dialogObject, this.props.parentKey);

    } else {
      this.setState({ isSubmitting: true });
      this.setState({ messageText: '' });

      try {
        const user = this.props.getFromGlobalState('userDetail');
        let dialogObject = { ...this.props.dialogObject }
        const recordsObject = {};
        if (this.props.batchInserting) {
          const recordList = [];
          for (const item of dialogObject.items) {
            const record = { ...item.record };
            record.sk = item.planting.concat(this.props.skPrimary.concat(uuid.v1()));
            record.type = this.props.dynamoType;
            record.clientCreateDate = new Date().valueOf();
            record.createdAt = record.clientCreateDate;
            record.clientTime = record.clientCreateDate;
            record.createdByDetail = user ? {
              sk: user.sk, email: user?.email ? user?.email : '', name: user?.name, surname: user.surname,
            } : undefined;
            record.version = 0;
            recordList.push(record);
            item.record = { ...record };
          }
          recordsObject.type = this.props.dynamoType;
          recordsObject.items = recordList;
          recordsObject.activeTenant = this.props.getFromGlobalState('activeSite');
          recordsObject.sk = uuid.v1();
        } else if (this.props.isNew) {
          if (this.props.parentKey) {
            dialogObject.sk = this.props.parentKey.concat(this.props.skPrimary.concat(uuid.v1()));
          } else {
            dialogObject.sk = this.props.skPrimary.concat(uuid.v1());
          }
          dialogObject.type = this.props.dynamoType;
          dialogObject.clientCreateDate = new Date().valueOf();
          dialogObject.createdAt = dialogObject.clientCreateDate;
          dialogObject.clientTime = dialogObject.clientCreateDate;
          dialogObject.createdByDetail = user ? {
            sk: user.sk, email: user?.email ? user?.email : '', name: user?.name, surname: user.surname,
          } : undefined;
          dialogObject.version = 0;
          dialogObject.activeTenant = this.props.getFromGlobalState('activeSite');
          if((dialogObject?.combinations||[]).length >= 1 ){
            dialogObject.combinations.map((x)=>{
              const item = {...x}
              delete item.combinations;
              if (this.props.parentKey) {
                item.sk = this.props.parentKey.concat(this.props.skPrimary.concat(uuid.v1()));
              } else {
                item.sk = this.props.skPrimary.concat(uuid.v1()) || item.sk;
              }
              item.activeTenant = this.props.getFromGlobalState('activeSite');
              item.clientCreateDate = new Date().valueOf();
              item.createdAt = dialogObject.clientCreateDate;
              item.clientTime = dialogObject.clientCreateDate;
              item.createdByDetail = user ? {
                sk: user.sk, email: user?.email ? user?.email : '', name: user?.name, surname: user.surname,
              } : undefined;
              item.version = 0;
              item.activeTenant = this.props.getFromGlobalState('activeSite');
              newproducts.push(item)
            })
          }
          

        }
        let preSuccess = true;
        if (this.props.handlePreExecute) {
          preSuccess = await this.props.handlePreExecute(dialogObject);
        }
        let creation = {};
        if (this.props.batchInserting) {
          creation = await createBatchRecords(this.props.getFromGlobalState('currentUser').username, 'calls', toStore(recordsObject, true), this.props.getFromGlobalState('activeSite'));
          if (creation.status) {
            for (const item of dialogObject.items) {
              item.record.cached = creation.cached;
            }
          }
        } else if (preSuccess) {
          const version = dialogObject.version ? dialogObject.version : 0;
          dialogObject.version = version + 1;
          dialogObject.activeTenant = this.props.getFromGlobalState('activeSite');
          if(dialogObject.combinations && dialogObject?.combinations?.length >= 1  ){
            dialogObject.sk = uuid.v1();
            dialogObject.items = newproducts;
            delete dialogObject.combinations;
            creation = await createBatchRecords(this.props.getFromGlobalState('currentUser').username, 'calls', toStore(dialogObject, true), this.props.getFromGlobalState('activeSite'));
          }else{
            creation = await createObject(this.props.getFromGlobalState('currentUser').username, 'calls', toStore(dialogObject), this.props.getFromGlobalState('activeSite'));
          }
         
          console.log("we are inserted a new product", dialogObject);


          if (creation.status) {
            dialogObject.cached = creation.cached;
          }
        }
        this.setState({ isSubmitting: false });
        this.setState({ success: true });
        this.props.handleUpdateDialogObject(dialogObject, this.props.parentKey);
        this.props.setClose();
      } catch (e) {
      // this.props.setSnack({open:true,variant:'error',message:(e.response? `${e.response.data.error.message ? e.response.data.error.message:e.response.data.error}, api src: ${e.response.data.src} ` : e.message)})
        this.setState(
          {
            success: false,
            isSubmitting: false,
            messageText: getErrorMessage(e),
          },
        );
      }
    }
  }

  render() {
    const {
      deleteLabel,rejectedUser, classes, open, isNew, setClose, children, valid, title, newTitle, dialogObject, disableActions, maxWidth, onGeneratePDF, changed, pdfRequested, reportPDF, csvRequested, reportCSV, onGenerateCSV, allowDelete, subTitle, disableDelete,
    } = this.props;
    const {
      isSubmitting, success, reportType, confirmDeleteOpen,confirmReInvite, isDeleting, dateRangeDialogOpen, reportStartDate, reportEndDate,
    } = this.state;
    // const dialogTitle = (isNew ? title : (dialogObject ? (dialogObject[titleRef] ? dialogObject[titleRef] : 'Edit') : title));
    const dialogTitle = isNew ? newTitle : title;
    const isMobile = !window.matchMedia('(min-width: 850px)').matches;
    const userDetail = this.props.getFromGlobalState('userDetail');

    console.log(dialogObject);

    return (
      <>
        {confirmDeleteOpen
      && (
      <ConfirmDialog
        open={confirmDeleteOpen}
        loading={isDeleting}
        title="Confirm"
        message={deleteLabel ? deleteLabel :"Are you sure you want to delete this record and all the associated records?"}
        handleConfirm={this.handleDelete}
        handleCancel={this.handleCancelConfirmDialog}
      />
      )}

     {confirmReInvite
      && (
      <ConfirmDialog
        actionText="Reinvite"
        open={confirmReInvite}
        loading={isDeleting}
        title="Confirm"
        message={"Are you sure you want to re-invite this user?"}
        handleConfirm={this.handleReinvite}
        handleCancel={this.cancelReinvite}
      />
      )}

        {dateRangeDialogOpen
        && (
        <DateRangeDialog
          open={dateRangeDialogOpen}
          loading={isDeleting}
          title="Report Date Range"
          handleConfirm={reportType === 'pdf' ? this.handleGeneratePDF : this.handleGenerateCSV}
          handleCancel={reportType === 'pdf' ? this.handleCancelGeneratePDF : this.handleCancelGenerateCSV}
          startDate={reportStartDate}
          endDate={reportEndDate}
          handleSetStartDate={this.setReportStartDate}
          handleSetEndDate={this.setReportEndDate}
        />
        )}

        <Dialog
          fullScreen={isMobile}
          maxWidth={maxWidth}
          disableBackdropClick
          open={open} // zIndex: '10040 !important', /* Sit on top */
          // style={{  zIndex: '10040 !important', root: { zIndex: '10040 !important' } }}
          onClose={setClose}
          aria-labelledby="responsive-dialog-title"
      // TransitionComponent={(isMobile? TransitionMobile : Transition)}
          TransitionComponent={(isMobile ? TransitionMobile : undefined)}
        >
          {isMobile && (
          <AppBar className={classes.appBar}>
            <Toolbar>

              <IconButton edge="start" color="inherit" onClick={setClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                <div>
                  {dialogTitle}
                  <div style={{ fontSize: '10px' }}>{subTitle}</div>
                </div>
              </Typography>

              {!isNew && allowDelete && (
              <IconButton disabled={disableDelete} className={classes.deleteButton} edge="start" color="inherit" onClick={this.confirmDelete} aria-label="close">
                <DeleteIcon />
              </IconButton>
              )}
              <Can
                role={userDetail && userDetail.rights && userDetail.rights.viewDashboard ? 'viewDashboard' : 'noAccess'}
                perform="viewReports"
                isAdministrator={userDetail && userDetail.rights && userDetail.rights.administrator}
                yes={() => (
                  <div style={{ display: 'flex', float: 'right' }}>
                    {onGenerateCSV
           && (
             <>
               {!csvRequested
             && (
             <Tooltip title="Generate CSV report">
               <IconButton edge="start" color="primary" onClick={this.generateCSV} aria-label="close">
                 <CSVIcon />
               </IconButton>
             </Tooltip>
             )}
               {csvRequested
               && reportCSV}
             </>
           )}
                    {onGeneratePDF
           && (
             <>
               {!pdfRequested
             && (
             <Tooltip title="Generate PDF report">
               <IconButton edge="start" color="primary" onClick={this.generatePDF} aria-label="close">
                 <PDFIcon />
               </IconButton>

             </Tooltip>
             )}
               {pdfRequested
               && reportPDF}
             </>
           )}

                  </div>
                )}
              />
              {!disableActions && (
              <LoaderFab
                onClick={this.handleSubmit}
                success={success}
                icon={<CheckIcon />}
                loading={isSubmitting}
                disabled={!changed || (isSubmitting || !valid)}
              />
              )}
            </Toolbar>
          </AppBar>
          ) }
          <DialogTitle style={{ minHeight: '20px' }} id="responsive-dialog-title">
            {!isMobile
      && (
        <>
          <div>
            {dialogTitle}

            <IconButton aria-label="close" className={classes.closeButton} onClick={setClose}>
              <CloseIcon />
            </IconButton>
            {!isNew && allowDelete && (
            <IconButton disabled={disableDelete} className={classes.deleteButton} edge="start" color="inherit" onClick={this.confirmDelete} aria-label="close">
              <DeleteIcon />
            </IconButton>

            )}
            <Can
              role={userDetail && userDetail.rights && userDetail.rights.viewDashboard ? 'viewDashboard' : 'noAccess'}
              perform="viewReports"
              isAdministrator={userDetail && userDetail.rights && userDetail.rights.administrator}
              yes={() => (
                <div style={{ display: 'flex', float: 'right' }}>

                  {onGenerateCSV && !isMobile
           && (
             <>
               {!csvRequested
             && (
             <Tooltip title="Generate CSV report">
               <IconButton color="primary" edge="start" className={classes.pdfButton} onClick={this.generateCSV} aria-label="close">
                 <CSVIcon />
               </IconButton>
             </Tooltip>
             )}
               {csvRequested
               && reportCSV}
             </>
           )}
                  {onGeneratePDF && !isMobile
           && (
             <>
               {!pdfRequested
             && (
             <Tooltip title="Generate PDF report">
               <IconButton color="primary" edge="start" className={classes.pdfButton} onClick={this.generatePDF} aria-label="close">
                 <PDFIcon />
               </IconButton>

             </Tooltip>
             )}
               {pdfRequested
               && reportPDF}
             </>
           )}
                </div>
              )}
            />
            <div style={{ fontSize: '12px' }}>
              {subTitle}
            </div>

          </div>
        </>
      )}

          </DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSubmit} className={classes.form}>
              {children}
              <div style={{ fontSize: '10px', marginTop: '30px' }}>
                {dialogObject && dialogObject.createdByDetail ? (dialogObject.createdByDetail.name && dialogObject.createdByDetail.surname) && <div style={{ fontSize: '10px' }}>{`Updated by: ${dialogObject.createdByDetail.name} ${dialogObject.createdByDetail.surname}`}</div> : (dialogObject && dialogObject.createdByEmail) ? `Updated by: ${dialogObject.createdByEmail}` : ''}
                {dialogObject && dialogObject.createdAt && <div style={{ fontSize: '10px' }}>{`Updated on: ${new Date(parseInt(dialogObject.createdAt)).toLocaleString()}`}</div>}
              </div>
            </form>
          </DialogContent>
          <Hidden smDown>
            <DialogActions>
              {!disableActions && (
                <>
                  {rejectedUser && <Button
                    variant="contained"
                    color="tertiary"
                    className={classes.submit}
                    onClick={this.confirmReinviteopen}
                    disabled={this.state.isSubmitting}
                  >
                    {' '}
                     Re-Invite
                    {' '}
                  </Button>}
                  <LoaderButton
            // type="submit"
                    onClick={this.handleSubmit}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={!changed || (isSubmitting || !valid || userDetail.rights.readOnly)}
                    isloading={this.state.isSubmitting}
                    text={isNew ? 'Add' : 'Update'}
                    loadingText={isNew ? 'Adding...' : 'Updating...'}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={setClose}
                    disabled={this.state.isSubmitting}
                  >
                    {' '}
                    Close
                    {' '}

                  </Button>

                </>
              )}

            </DialogActions>
          </Hidden>
          <Snack
            open={!success}
            setClose={this.setSnackClose.bind(this)}
            messageText={this.state.messageText}
            variant="error"
          />
        </Dialog>
      </>
    );
  }
}
GenDialog.defaultProps = {
  subTitle: '',
};
GenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  dialogObject: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  handleUpdateDialogObject: PropTypes.func.isRequired,
  handleDeleteDialogObject: PropTypes.func.isRequired,
  skPrimary: PropTypes.string.isRequired,
  dynamoType: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  newTitle: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  titleRef: PropTypes.string.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  parentKey: PropTypes.string,
  disableActions: PropTypes.bool,
  maxWidth: PropTypes.string,
  onGeneratePDF: PropTypes.func,
  setGeneratingPDF: PropTypes.func,
  handlePreExecute: PropTypes.func,
  changed: PropTypes.bool,
  pdfRequested: PropTypes.bool,
  reportPDF: PropTypes.any,
  csvRequested: PropTypes.bool,
  reportCSV: PropTypes.any,
  allowDelete: PropTypes.bool,
  rejectedUser: PropTypes.bool,
  disableDelete: PropTypes.bool,
  noServerInteraction: PropTypes.bool,
  batchInserting: PropTypes.bool,
  lastUpdatedBy: PropTypes.object.isRequired,
  handleDeletePreExecute: PropTypes.func.isRequired,
  onGenerateCSV: PropTypes.func,
  deleteLabel:PropTypes.string,
  handleSubmitAddExistingUser:PropTypes.func,
  setSnack:PropTypes.func

};
export default withStyles(styles)(GenDialog);
