import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import SetCropD from '../SetCropD';
import VarietyD from '../VarietyD';
import EnhancedTable from '../../components/Etable';

const detailHeadCells = [
  {
    id: 'cropType', numeric: false, disablePadding: true, label: 'Crop type',
  },
  {
    id: 'name', numeric: false, disablePadding: false, label: 'Crop',
  },
  {
    id: 'cultivarType', numeric: false, disablePadding: false, label: 'Cultivar type',
  },
  {
    id: 'variety', numeric: false, disablePadding: false, label: 'Cultivar name',
  },
  {
    id: 'rootStockType', numeric: false, disablePadding: false, label: 'Rootstock type',
  },
  {
    id: 'rootStock', numeric: false, disablePadding: false, label: 'Rootstock',
  },
];

const mobileHeadCells = [
  {
    id: 'cropType', combine: [{ id: 'cropType', opacity: 1 }, { id: 'name', opacity: 0.6 }, { id: 'varietyCombo', opacity: 0.4 }], numeric: false, disablePadding: true, label: 'Crop', maxWidth: '100px'
  },
  {
    id: 'rootStock', numeric: false, disablePadding: false, label: 'Rootstock',
  },
];

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paperDropperBody: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  heading: {
    marginTop: '4px',
    cursor: 'pointer',
  },
  submit: {
    margin: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(10),
  },
  mainSection: {
    width: '100%',
    flexWrap: 'wrap',
  },
  parentSection: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },

  },
  childSection: {
    marginLeft: theme.spacing(5),
  },
  childSectionChildren: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class CropDetailForm extends Component {
  constructor(props) {
    super(props);
    this.setClose = this.setClose.bind(this);
    this.setCloseVariety = this.setCloseVariety.bind(this);
    this.handleClickShowCrop = this.handleClickShowCrop.bind(this);
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleUpdateVariety = this.handleUpdateVariety.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);

    this.state = {
      selectedCrop: null,
      selectedVariety: null,
      selectedCropOb: null,
      isNew: false,
      cropDialogOpen: false,
      varietyDialogOpen: false,
    };
  }

  handleClickShow = () => {
    this.setState({ cropDialogOpen: true });
  }

  handleBlur = () => {
    // return this.validateField (event.target.id, this.props.requiredFields);
  }

  handleClickAdd() {
    this.setState({ cropDialogOpen: true, isNew: true, selectedCrop: {} });
  }

handleClickAddNewVariety = (crop, event) => {
  event.stopPropagation();
  this.setState({
    varietyDialogOpen: true,
    isNew: true,
    selectedCropSK: crop.sk,
    selectedCrop: crop,
    selectedVariety: {},
  });
}

handleClickShowCrop(bla, parent, selectedId) {
  const { getFromGlobalState } = this.props;
  if (!parent) {
    const selectedCrop = getFromGlobalState('crops').find((crop) => crop.sk === selectedId);
    this.setState({
      selectedCrop, selectedCropSK: selectedId, cropDialogOpen: 'true', isNew: false,
    });
  } else if (selectedId) {
    const selectedCrop = getFromGlobalState('crops').find((crop) => crop.sk === parent);
    const selectedVariety = selectedCrop.varieties.find((variety) => variety.sk === selectedId);
    this.setState({
      selectedCropOb: selectedCrop,
      selectedCrop: parent,
      selectedVariety,
      selectedCropSK: selectedCrop.sk,
    });
    this.setState({ cropDialogOpen: true, isNew: false });
  }
}

handleClickShowVariety = (selectedCropId, selectedVarietyId) => {
  const { getFromGlobalState } = this.props;
  const selectedCrop = getFromGlobalState('crops').find((crop) => crop.sk === selectedCropId);
  const selectedVariety = selectedCrop.varieties.find((variety) => variety.sk === selectedVarietyId);
  this.setState({ selectedCrop, selectedVariety, selectedCropSK: selectedCrop.sk });
  this.setState({ varietyDialogOpen: true, isNew: false });
}

handleClickEdit() {
  this.setState({ cropDialogOpen: true, isNew: true });
}

handleUpdate(cropIn) {
  const { getFromGlobalState, setGlobalState } = this.props;
  const updatedIndex = getFromGlobalState('crops').findIndex((i) => i.sk === cropIn.sk);
  if (updatedIndex === -1) {
    const newCrops = getFromGlobalState('crops');
    newCrops.push(cropIn);
    setGlobalState({ crops: newCrops });
    // this.state.blocks.push(blockIn);
  } else {
    const newCrops = getFromGlobalState('crops');
    newCrops[updatedIndex] = cropIn;
    setGlobalState({ crops: newCrops });
  }
}

handleUpdateVariety(varietyIn, cropSK) {
  const { getFromGlobalState, setGlobalState } = this.props;
  const updatedCropIndex = getFromGlobalState('crops').findIndex((i) => i.sk === cropSK);
  if (updatedCropIndex === -1) {
    alert("impossible - we don't have this crop!");
    return;
  }
  // find the planting
  const crops = getFromGlobalState('crops');
  const { varieties } = crops[updatedCropIndex];

  if (!varieties) {
    crops[updatedCropIndex].varieties = [];
  }

  const updatedVarietyIndex = (crops ? crops[updatedCropIndex].varieties.findIndex((i) => i.sk === varietyIn.sk) : -1);
  if (updatedVarietyIndex === -1) {
    crops[updatedCropIndex].varieties.push(varietyIn);
    setGlobalState({ crops });
  } else {
    crops[updatedCropIndex].varieties[updatedVarietyIndex] = varietyIn;
    setGlobalState({ crops });
  }
}

setClose() {
  this.setState({ cropDialogOpen: false });
}

setCloseVariety() {
  this.setState({ varietyDialogOpen: false });
}

/* addButton(crop) {
  return (

    <Tooltip placement="top" title="Add">
      <IconButton
        aria-label="Add"
        color="secondary"
      // className={this.props.classes.menuButton}
        style={{ padding: '0px' }}
        onClick={this.handleClickAddNewVariety.bind(this, crop)}
        // onClick={() => this.handleClickAddNewVariety(this, crop)}
      >
        <AddIcon />
      </IconButton>
    </Tooltip>

  );
} */

generateRows(crops) {
  const rows = [];
  if (!crops) {
    return rows;
  }
  const { getFromGlobalState } = this.props;
  const cropTypes = getFromGlobalState('cropTypesSetting');
  if (!cropTypes && cropTypes.length === 0) {
    return rows;
  }
  crops.forEach((crop) => {
    const cropTypeSK = crop.cropType ? crop.cropType : crop.sk.substring(0, crop.sk.indexOf('_setchild_'));
    const cropType = cropTypes.find((c) => c.sk === cropTypeSK);
    const cropTypeName = cropType ? cropType.name : '';
    crop.varieties && crop.varieties.forEach((variety) => {
      const varietyCombo = `${variety.cultivarType} ${variety.cultivar} ${variety.rootStockType}`
      rows.push({
        parentSK: crop.sk, sk: variety.sk, varietyCombo, cropType: cropTypeName, name: crop.name, cultivarType: variety.cultivarType, rootStockType: variety.rootStockType, rootStock: variety.rootStock, variety: variety.cultivar,
      });
    });
    // rows.push({ sk: `addrow${i}`, name: '', variety: this.addButton(crop) });
  });

  return rows;
}

render() {
  const { classes, getFromGlobalState, setGlobalState, setSnack } = this.props;
  const { selectedCropOb,
    selectedVariety,
    cropDialogOpen,
    isNew,
    varietyDialogOpen,
    selectedCropSK,
    selectedCrop,
   } = this.state;
  // const crops = initialState;
  const crops = getFromGlobalState('crops');
  const isMobile = getFromGlobalState('isMobile');
  const rows = this.generateRows(crops);
  const headCells = isMobile ? mobileHeadCells : detailHeadCells;
  const userDetail = getFromGlobalState('userDetail');

  return (

    <Paper elevation={0} className={classes.mainSection}>

      {(cropDialogOpen)
          && (
          <SetCropD
            handleUpdate={this.handleUpdate}
            open={cropDialogOpen}
            setClose={this.setClose}
            isNew={isNew}
            getFromGlobalState={getFromGlobalState}
            setGlobalState={setGlobalState}
            setSnack={setSnack}
            crop={selectedCropOb}
            variety={selectedVariety}
          />
          )}

      {(varietyDialogOpen)
          && (
          <VarietyD
            handleUpdate={this.handleUpdateVariety}
            open={varietyDialogOpen}
            setClose={this.setCloseVariety}
            isNew={isNew}
            variety={selectedVariety}
            getFromGlobalState={getFromGlobalState}
            parentSK={selectedCropSK}
            parent={selectedCrop}
          />
          )}

      <EnhancedTable
        heading="Crops"
        displayToolbar
        hideHeading
        id="cropTable"
        noPagination //= { rows && rows.length > 5 ? false:true}
        rows={rows}
        headCells={headCells}
        handleSelect={this.handleClickShowCrop}
        handleAddItem={userDetail && userDetail.rights && ! userDetail.rights.readOnly ? this.handleClickAdd : null}
        actionType="actionType"
        rowKeyName="sk"
        disablePadFirstCol
        padFirstCol="5px"
        clean
        dense
        parent
        ancestorsInRow
        parentSK="parentSK"
        maxHeight="350px"
        selectedRow={selectedVariety ? selectedVariety.sk : ''}
      />
    </Paper>
  );
}
}
CropDetailForm.propTypes = {
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,

};
export default withStyles(styles)(CropDetailForm);
