import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

export default function Terms(props) {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography>
            Welcome to Land-Diary (“the App”). By accessing or using Land-Diary, you agree to comply with and be bound by the following Terms and Conditions. Please read them carefully before using the App. If you do not agree to these terms, do not use the App.
            <br></br>
            <br></br>
            1. Acceptance of Terms
            By accessing or using Land-Diary, you confirm that you have read, understood, and agree to these Terms and Conditions, as well as our Privacy Policy. We may update these Terms from time to time, and it is your responsibility to review them periodically.
            <br></br>
            <br></br>
            2. Use of the App
              - Eligibility: You must be at least 18 years old or have the consent of a legal guardian to use Land-Diary.
              <br></br> - Account Registration: You may be required to create an account to access certain features of the App. You agree to provide accurate and up-to-date information and keep your account credentials secure.
              <br></br>- Permitted Use: You agree to use Land-Diary solely for lawful purposes and in accordance with these Terms. You must not use the App to violate any local, national, or international laws.
            <br></br>
            <br></br>
            3. User Content
            <br></br> - Ownership: You retain ownership of any content you upload or submit to Land-Diary, including but not limited to text, images, and other data ("User Content").
            <br></br> - License to Land-Diary: By submitting User Content, you grant Land-Diary a non-exclusive, royalty-free, worldwide license to use, display, and distribute your User Content for the purposes of operating and improving the App.
            <br></br>  - Prohibited Content: You agree not to post any content that is offensive, harmful, defamatory, or violates any laws. Land-Diary reserves the right to remove any User Content that it deems inappropriate.
            <br></br><br></br>
            4. Intellectual Property
            <br></br>  - All content, software, and materials available on Land-Diary, excluding User Content, are the property of Land-Diary or its licensors. This includes, but is not limited to, text, graphics, logos, and software.
            <br></br>  - You may not copy, distribute, modify, or create derivative works of any content from the App without prior written consent from Land-Diary.
            <br></br><br></br>
            5. Limitation of Liability
            <br></br>  - Land-Diary and its affiliates are not liable for any damages, losses, or harm resulting from your use or inability to use the App.
            <br></br>  - The App is provided "as-is" and "as available," and we make no warranties, express or implied, regarding the App’s functionality, accuracy, or reliability.
            <br></br><br></br>
            6. Disclaimer
            <br></br>  - Land-Diary does not guarantee the accuracy, completeness, or timeliness of information available on the App. The information provided is for general informational purposes and should not be relied upon as legal, financial, or professional advice.
            <br></br><br></br>
            7. Termination
            <br></br>  - Land-Diary reserves the right to suspend or terminate your access to the App at any time, with or without notice, for any reason, including violation of these Terms.
            <br></br><br></br>
            8. Privacy
            <br></br>  - Our collection and use of personal information in connection with the App are described in our Privacy Policy. By using the App, you agree to the terms of our Privacy Policy.
            <br></br><br></br>
            9. Governing Law
            <br></br> - These Terms are governed by and construed in accordance with the laws of South Africa. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of South Africa.
            <br></br><br></br>
            10. Contact Us
            <br></br> - For any questions about these Terms and Conditions, please contact us at info@landdiary.co.za.
            <br></br><br></br>
            <strong>Acknowledgment</strong>
            <br></br>
            By using Land-Diary, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
       </Typography>
    </Paper>
  );
}
Terms.propTypes = {
};
