import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileUploader from '../../containers/FileUploader';
import Load from '../Load';

const PestDeseaseList = [
  { value: 'Trap' },
  { value: 'Fruit' },
  { value: 'Flower' },
  { value: 'Leaf' },
  { value: 'New Leaf (Flush)' },
  { value: 'Raceme' },
  { value: 'Branch' },
  { value: 'Tree' },
  { value: 'Planting' },
];

const PestDeseaseList2 = [
  { value: 'Trap' },
  { value: 'Fruit' },
  { value: 'Flower' },
  { value: 'Leaf' },
  { value: 'Branch' },
  { value: 'Tree' },
  { value: '% of Trees' },
  { value: 'Planting' },
  { value: 'Hectares' },
  { value: 'Block' },
];

const OtherList = [
  { value: 'm' },
  { value: 'mm' },
  { value: 'mm/day' },
  { value: 'Degrees Celcius' },
  { value: 'mS/cm' },
  { value: 'uS/cm' },
  { value: 'Liter' },
  { value: 'kg' },
  { value: 'kPa' },
  { value: 'g' },
  { value: '%' },
  { value: 'mg/kg' },
];

const variableThresholds = [
  {
    month: 1, member: 'jan', label: 'Jan', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 2, member: 'feb', label: 'Feb', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 3, member: 'mar', label: 'Mar', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 4, member: 'apr', label: 'Apr', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 5, member: 'may', label: 'May', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 6, member: 'jun', label: 'Jun', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 7, member: 'jul', label: 'Jul', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 8, member: 'aug', label: 'Aug', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 9, member: 'sep', label: 'Sep', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 10, member: 'oct', label: 'Oct', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 11, member: 'nov', label: 'Nov', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },
  {
    month: 12, member: 'dec', label: 'Dec', thresholdLow: null, thesholdMedium: null, thesholdHigh: null,
  },

];

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  helperText: {
    color: 'red',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    //padding: theme.spacing(2),
    alignItems: 'center',
    width: '100%',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },
  group: {
    flexDirection: 'row',
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: 'white',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
});

function TargetStage(props) {
  const {
    classes,
    stage,
    errors,
    getFromGlobalState,
    obsIdx,
    stageIdx,
    targetType,
    handleDeleteStage,
    handleRadioChange,
    handleArrayChange,
    handleBlur,
    handleDeleteImage,
    fileUploaded,
    handleVariableTChange,
    imageUrls,
    isGeneral,
    setId,
    disableUpload,
    disableDelete,
    disableElevation,
    selectionMode,
    cropSpecific,
    isLoading,
    selectedCropType,
  } = props;
  const targetsTypesSetting = getFromGlobalState('targetsTypesSetting');
  const targetTypeObj = targetType ? targetsTypesSetting.find((t) => t.sk === targetType) : null;
  const targetTypeName = targetTypeObj ? targetTypeObj.name : '';
  const unitList = targetTypeName && targetTypeName !== 'Other' ? PestDeseaseList : OtherList;
  const targetDetails = getFromGlobalState('targetDetail');
  const phynologyType = targetsTypesSetting.find((t) => t.name === 'Phenology');
  const phenologyObjs = targetDetails && phynologyType && targetDetails[phynologyType.sk] ? targetDetails[phynologyType.sk] : [];
  const phenologies = phenologyObjs && phenologyObjs[selectedCropType];
 

  const singleStage = !cropSpecific; // targetTypeName && targetTypeName === 'Other';
  // const unitList2 = targetTypeName && targetTypeName !== 'Other' ? PestDeseaseList2 : OtherList;
  const unitList2 = PestDeseaseList2;
  if (!stage) {
    return null;
  }

  return (
    <Load isloading={isLoading}>
      <Paper elevation={disableElevation ? 0 : 1} className={classes.paper}>
        <Grid container className={classes.root2} spacing={1} alignItems="flex-end">
          {!singleStage && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div>
              <Typography>{`Stage ${stageIdx + 1}`}</Typography>
              {!disableDelete && (
              <IconButton
                onClick={(e) => handleDeleteStage(e, obsIdx, stageIdx)}
              >
                <DeleteIcon color="secondary" />
              </IconButton>
              )}

            </div>
          </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="priority">Priority</InputLabel>
              <Select
                native
                style={{ minWidth: '200px' }}
                required
                displayEmpty
                disabled={selectionMode}
                value={stage.priority}
                onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
                onBlur={handleBlur}
                error={errors.priority ? true : undefined}
                inputProps={{
                  name: 'priority',
                  id: 'priority',
                }}
              >
                <option aria-label="empty" value="" />
                <option value="Primary">Primary</option>
                <option value="Secondary">Secondary</option>

              </Select>
              {errors.priority && <FormHelperText className={classes.helperText}>{errors.priority}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="priority">Phenology</InputLabel>
              <Select
                native
                style={{ minWidth: '200px' }}
                required
                displayEmpty
                disabled={selectionMode}
                value={stage.phenology}
                onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
                onBlur={handleBlur}
                error={errors.phenology ? true : undefined}
                inputProps={{
                  name: 'phenology',
                  id: 'phenology',
                }}
              >
                <option aria-label="empty" value="" />
                {phenologies && phenologies.map((phenology) => <option value={phenology.sk}>{phenology.name}</option>)}

              </Select>
              {errors.priority && <FormHelperText className={classes.helperText}>{errors.priority}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl component="fieldset" className={classes.formControlRadio}>
              <FormLabel required>Type</FormLabel>
              <RadioGroup
                aria-label="monitorType"
                name="monitorType"
                id="monitorType"
                className={classes.group}
                disabled={selectionMode}
                value={stage.monitorType}
                onChange={(e) => handleRadioChange(e, obsIdx, stageIdx, 'monitorType')}
              >
                <FormControlLabel value="Fixed" control={<Radio disabled={selectionMode} />} label="Fixed" />
                <FormControlLabel value="Field" control={<Radio disabled={selectionMode} />} label="Field" />
                {errors.monitorType && <FormHelperText className={classes.helperText}>{errors.monitorType}</FormHelperText>}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              disabled={selectionMode}
              id="stageDescription"
              label="Description"
              name="stageDescription"
              // autoFocus
              value={stage.stageDescription}
              onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
              // onChange={this.handleArrayChange}
              onBlur={handleBlur}
              error={errors.stageDescription ? true : undefined}
              helperText={errors.stageDescription}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.root}>
              <ImageList className={classes.imageList} cols={stage && stage.images && stage.images.length > 1 ? 2.5 : 1}>
                {stage && stage.images && stage.images.map((item, imageIdx) => (
                  <ImageListItem key={item.Key} style={{ minWidth: '120px', height: '200px' }}>
                    {imageUrls && imageUrls[item.Key] ? <img key={item.Key} style={{ objectFit: 'cover', height: '100%' }} alt="target" src={imageUrls[item.Key]} /> : (
                      <div style={{
                        width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                      }}
                      >
                        <CircularProgress color="secondary" />
                      </div>
                    )}

                    <ImageListItemBar
                      title={item.name}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                      actionIcon={!disableDelete ? (
                        <IconButton
                          onClick={(e) => handleDeleteImage(e, obsIdx, stageIdx, item.Key)}
                          aria-label={`star ${item.name}`}
                        >
                          <DeleteIcon className={classes.title} />
                        </IconButton>
                      ) : undefined}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
            {!disableUpload && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUploader
                activeTenant={getFromGlobalState('activeSite')}
                generateThumbnail
                generatePreview
              // onFileUploaded={this.fileUploaded}
                onFileUploaded={(f) => fileUploaded(f, obsIdx, stageIdx, stage)}
                folder={`generalSettings/targets/${targetTypeName}/`}
                allowImageResize
                imageResizeTargetWidth={300}
                imageResizeTargetHeight={300}
                allowMultiple
            //  fileName={stage.imageID}
            //  forceName
                isGeneral={isGeneral}
                setId={setId}
                imageTransformVariantsIncludeOriginal={false}
                getFromGlobalState={getFromGlobalState}
              />

            </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="procedure"
              multiline
              disabled={selectionMode}
              rowsMax="4"
              label="Monitoring procedure"
              name="procedure"
              // autoFocus
              value={stage.procedure}
              onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
              // onChange={this.handleArrayChange}
              onBlur={handleBlur}
              error={errors.procedure ? true : undefined}
              helperText={errors.procedure}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="unitNum">Observation Type</InputLabel>
              <Select
                native
                style={{ minWidth: '200px' }}
                required
                displayEmpty
                disabled={selectionMode}
                value={stage.unitNum}
                onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
                onBlur={handleBlur}
                error={errors.unitNum ? true : undefined}
                inputProps={{
                  name: 'unitNum',
                  id: 'unitNum',
                }}
              >
                <option aria-label="empty" value="" />
                <option value="Number">Number</option>
                <option value="Percentage">Percentage</option>
                <option value="Present">Present / Absent</option>
                <option value="Scale">Scale</option>

              </Select>
              {errors.unitNum && <FormHelperText className={classes.helperText}>{errors.unitNum}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>

            <TextField
              margin="normal"
            //  fullWidth
              id="quantityOfUnit"
              label="Observations"
              disabled={selectionMode}
              name="quantityOfUnit"
              value={stage.quantityOfUnit}
              onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
              onBlur={handleBlur}
              error={errors.quantityOfUnit ? true : undefined}
              helperText={errors.quantityOfUnit}
            />

          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="unit">{targetTypeName === 'Other' ? 'Unit' : 'Unit'}</InputLabel>
              <Select
                native
              // style={{ minWidth: '200px' }}
                required
                displayEmpty
                disabled={selectionMode}
                value={stage.unit}
                onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
                onBlur={handleBlur}
                error={errors.unit ? true : undefined}
                inputProps={{
                  name: 'unit',
                  id: 'unit',
                }}
              >
                <option aria-label="empty" value="" />
                {unitList && unitList.map((unit) => <option value={unit.value}>{unit.value}</option>)}

              </Select>
              {errors.unit && <FormHelperText className={classes.helperText}>{errors.unit}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>

            <TextField
              margin="normal"
             // fullWidth
              disabled={selectionMode}
              id="quantityOfUnit2"
              label="Points"
              name="quantityOfUnit2"
              value={stage.quantityOfUnit2}
              onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
              onBlur={handleBlur}
              error={errors.quantityOfUnit2 ? true : undefined}
              helperText={errors.quantityOfUnit2}
            />

          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="unit">Point description</InputLabel>
              <Select
                native
              // style={{ minWidth: '200px' }}
                required
                disabled={selectionMode}
                displayEmpty
                value={stage.unit2}
                onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
                onBlur={handleBlur}
                error={errors.unit ? true : undefined}
                inputProps={{
                  name: 'unit2',
                  id: 'unit2',
                }}
              >
                <option aria-label="empty" value="" />
                {unitList2 && unitList2.map((unit) => <option value={unit.value}>{unit.value}</option>)}

              </Select>
              {errors.unit2 && <FormHelperText className={classes.helperText}>{errors.unit2}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="frequency">Observation frequency</InputLabel>
              <Select
                native
                style={{ minWidth: '200px' }}
                required
                fullWidth
                disabled={selectionMode}
                displayEmpty
                value={stage.frequency}
                onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
                onBlur={handleBlur}
                error={errors.unit ? true : undefined}
                inputProps={{
                  name: 'frequency',
                  id: 'frequency',
                }}
              >
                <option aria-label="empty" value="" />
                <option value="Second">Second</option>
                <option value="Minute">Minute</option>
                <option value="Hourly">Hourly</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Annually">Annually</option>
              </Select>
              {errors.frequency && <FormHelperText className={classes.helperText}>{errors.frequency}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl component="fieldset" className={classes.formControlRadio}>
              <FormLabel required>Threshold</FormLabel>
              <RadioGroup
                aria-label="threshold"
                name="threshold"
                id="threshold"
                disabled={selectionMode}
                className={classes.group}
                value={stage.threshold}
                onChange={(e) => handleRadioChange(e, obsIdx, stageIdx, 'threshold')}
              >
                <FormControlLabel value="Yes" control={<Radio disabled={selectionMode} />} label="Yes" />
                <FormControlLabel value="No" control={<Radio disabled={selectionMode} />} label="No" />
                {errors.threshold && <FormHelperText className={classes.helperText}>{errors.threshold}</FormHelperText>}
              </RadioGroup>
            </FormControl>
          </Grid>
          {stage.threshold && stage.threshold === 'Yes' && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl component="fieldset" className={classes.formControlRadio}>
              <FormLabel required>Constant Threshold</FormLabel>
              <RadioGroup
                aria-label="thresholdConstant"
                name="thresholdConstant"
                id="thresholdConstant"
                className={classes.group}
                disabled={selectionMode}
                value={stage.thresholdConstant}
                onChange={(e) => handleRadioChange(e, obsIdx, stageIdx, 'thresholdConstant')}
              >
                <FormControlLabel value="Yes" control={<Radio disabled={selectionMode} />} label="Yes" />
                <FormControlLabel value="No" control={<Radio disabled={selectionMode} />} label="No" />
                {errors.thresholdConstant && <FormHelperText className={classes.helperText}>{errors.thresholdConstant}</FormHelperText>}
              </RadioGroup>
            </FormControl>
          </Grid>
          )}

          { stage.threshold && stage.threshold === 'Yes' && stage.thresholdConstant && stage.thresholdConstant === 'Yes' && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormLabel required>Thresholds</FormLabel>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                margin="normal"
                fullWidth
                id="thresholdLow"
                label="Low"
                name="thresholdLow"
                value={stage.thresholdLow}
                onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
                onBlur={handleBlur}
                error={errors.thresholdLow ? true : undefined}
                helperText={errors.thresholdLow}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                margin="normal"
                fullWidth
                id="thresholdMedium"
                label="Medium"
                name="thresholdMedium"
                value={stage.thresholdMedium}
                onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
                onBlur={handleBlur}
                error={errors.thresholdMedium ? true : undefined}
                helperText={errors.thresholdMedium}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                margin="normal"
                fullWidth
                id="thresholdHigh"
                label="High"
                name="thresholdHigh"
                value={stage.thresholdHigh}
                onChange={(e) => handleArrayChange(e, obsIdx, stageIdx)}
                onBlur={handleBlur}
                error={errors.thresholdHigh ? true : undefined}
                helperText={errors.thresholdHigh}
              />
            </Grid>
          </>
          )}
          {stage.thresholdConstant && stage.thresholdConstant === 'No' && variableThresholds.map((threshold, i) => (
            <>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <FormLabel required>{threshold.label}</FormLabel>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  margin="dense"
                  fullWidth
                  id={`${stage.member}Low`}
                  label={i === 0 ? 'Low' : undefined}
                  name={`${stage.member}Low`}
                  value={stage.variableThreshold && stage.variableThreshold[threshold.member] && stage.variableThreshold[threshold.member].low ? stage.variableThreshold[threshold.member].low : undefined}
                  onChange={(e) => handleVariableTChange(e, obsIdx, stageIdx, threshold.member, 'low')}
                  onBlur={handleBlur}
                  error={errors.variableThreshold && stage.variableThreshold[threshold.member] && errors.variableThreshold[threshold.member].low ? true : undefined}
                  helperText={errors.variableThreshold ? errors.variableThreshold[threshold.member] : undefined}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  margin="dense"
                  fullWidth
                  id={`${threshold.member}Med`}
                  label={i === 0 ? 'Medium' : undefined}
                  name={`${threshold.member}Med`}
                  value={stage.variableThreshold && stage.variableThreshold[threshold.member] && stage.variableThreshold[threshold.member].med ? stage.variableThreshold[threshold.member].med : undefined}
                  onChange={(e) => handleVariableTChange(e, obsIdx, stageIdx, threshold.member, 'med')}
                  onBlur={handleBlur}
                  error={errors.variableThreshold && stage.variableThreshold[threshold.member] && errors.variableThreshold[threshold.member].med ? true : undefined}
                  helperText={errors.variableThreshold ? errors.variableThreshold[threshold.member] : undefined}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  margin="dense"
                  fullWidth
                  id={`${threshold.member}High`}
                  label={i === 0 ? 'High' : undefined}
                  name={`${threshold.member}High`}
                  value={stage.variableThreshold && stage.variableThreshold[threshold.member] && stage.variableThreshold[threshold.member].high ? stage.variableThreshold[threshold.member].high : undefined}
                  onChange={(e) => handleVariableTChange(e, obsIdx, stageIdx, threshold.member, 'high')}
                  onBlur={handleBlur}
                  error={errors.variableThreshold && stage.variableThreshold[threshold.member] && errors.variableThreshold[threshold.member].high ? true : undefined}
                  helperText={errors.variableThreshold ? errors.variableThreshold[threshold.member] : undefined}
                />
              </Grid>
            </>
          ))}

        </Grid>
      </Paper>
    </Load>
  );
}
TargetStage.defaultProps = {
  imageUrls: [],
  isGeneral: false,
  setId: '',
  disableUpload: false,
  disableDelete: false,
  disableElevation: false,
  selectionMode: false,
  cropSpecific: false,
};
TargetStage.propTypes = {
  classes: PropTypes.object.isRequired,
  stage: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  obsIdx: PropTypes.number.isRequired,
  stageIdx: PropTypes.number.isRequired,
  targetType: PropTypes.string.isRequired,
  handleDeleteStage: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleArrayChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  fileUploaded: PropTypes.func.isRequired,
  handleVariableTChange: PropTypes.func.isRequired,
  imageUrls: PropTypes.array,
  isGeneral: PropTypes.bool,
  setId: PropTypes.string,
  disableUpload: PropTypes.bool,
  disableDelete: PropTypes.bool,
  disableElevation: PropTypes.bool,
  selectionMode: PropTypes.bool,
  cropSpecific: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  selectedCropType: PropTypes.string.isRequired,

};

export default withStyles(styles)(TargetStage);
