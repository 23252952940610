import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import AddBox from '@material-ui/icons/AddBox';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import uuid from 'uuid';
import {
  toStore, createObject, deleteObject, getErrorMessage, getDownloadUrl,
} from '../../libs/storage';
import Load from '../../components/Load';
import EditTable from '../../components/EditTable';
import TargetD from '../TargetD';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '5px',
    backgroundColor: 'inherit',
  },
  paper: {
    marginLeft: '4px',
    marginTop: '10px',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
    marginLeft: '4px',
  },
});
class TargetsAdminForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      selectedTargetsType: '',
      isNew: false,
      targetsDialogOpen: false,
      selectedTarget: null,
      cropsDetail: [],
      selectedCropType: null,
      crops: [],
      columns: [],
      loadingCrops: false,
      targetsDetail: [],
      loadingTargets: false,

    };
  }

  componentDidMount() {
    const {
      isAuthenticated, getFromGlobalState,
    } = this.props;

    if (!isAuthenticated) {
      return;
    }
    let activeSite = getFromGlobalState('activeSite');
    if (activeSite === '' || !activeSite) {
      activeSite = 'default';
    }
    const columns = this.getHeader();
    this.setState({ columns });
  }

onRowDelete = async (event, oldData) => {
  const {
    getFromGlobalState, setSnack, setAdminState, getAdminState,
  } = this.props;
  const targetsDetail = { ...getAdminState('targetsDetail') };
  try {
    const ob = { ...oldData };
    ob.activeTenant = getFromGlobalState('activeSite');
    this.setState({ loadingTargets: true });
    const columns = this.getHeader();
    const deletion = await deleteObject(ob);
    if (!deletion.status) {
      setSnack({ open: true, variant: 'error', messageText: 'failed to delete - please refresh' });
      return false;
    }
    // const targetsDetailnew = [...targetsDetail];
    const idx = targetsDetail[ob.targetType].findIndex((detail) => detail.sk === ob.sk);
    if (idx === -1) {
      setSnack({ open: true, variant: 'error', messageText: 'row not found in local cache - please refresh' });
      return false;
    }
    targetsDetail[ob.targetType].splice(idx, 1);
    setAdminState({ targetsDetail });
    this.setState({ loadingTargets: false, columns });
    return true;
  } catch (e) {
    setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    return false;
  }
}

onRowAdd = () => {
  this.setState({ isNew: true, targetsDialogOpen: true });
}

onHandleUpdate = (dialogObject) => {
  const { getAdminState, setAdminState } = this.props;
  const targetsDetail = { ...getAdminState('targetsDetail') };
  const columns = this.getHeader();
  const idx = targetsDetail[dialogObject.targetType].findIndex((ob) => ob.sk === dialogObject.sk);
  if (idx === -1) {
    targetsDetail[dialogObject.targetType].push(dialogObject);
  } else {
    targetsDetail[dialogObject.targetType][idx] = dialogObject;
  }
  setAdminState({ targetsDetail });
  this.setState({ columns });
}

onRowEdit = (event, rowData) => {
  const { rows } = this.props;
  // const { targetsDetail } = this.state;
  const selectedTarget = rows.find((itme) => itme.sk === rowData.sk);
  this.setState({ isNew: false, targetsDialogOpen: true, selectedTarget });
}

setClose = () => {
  this.setState({ isNew: false, targetsDialogOpen: false });
}

   onRowAdd2 = async (rowData) => {
     const { getFromGlobalState, setSnack } = this.props;
     const { cropsDetail } = this.state;
     try {
       const ob = { ...rowData };
       ob.setId = 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3';
       ob.sk = rowData.cropName.concat('_setChild_').concat(uuid.v1());
       ob.isGeneral = true;
       ob.type = 'cropsDetail';
       ob.version = 1;
       ob.activeTenant = getFromGlobalState('activeSite');

       const creation = await createObject(getFromGlobalState('currentUser').username, 'calls', toStore(ob), ob.activeTenant);
       if (creation.status) {
         ob.cached = creation.cached;
       }
       const cropsDetailNew = [...cropsDetail];
       cropsDetailNew.push(ob);
       this.setState({ cropsDetail: cropsDetailNew });
       return true;
     } catch (e) {
       setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
       return false;
     }
   }

   onTargetTypeChange = async (event) => {
     let { selectedTargetsType, targetsDetail } = this.state;
     const { getTargetDetails } = this.props;
     if (selectedTargetsType === event.target.value) {
       return true;
     }

     selectedTargetsType = event.target.value;
     this.setState({ selectedTargetsType, loadingTargets: true });
     const retVal = await getTargetDetails(selectedTargetsType);

     if (retVal && retVal.targetsDetail && retVal.targetsDetail.length) {
       let newTargets = [...targetsDetail];
       newTargets = retVal.targetsDetail;
       this.setState({ targetsDetail: newTargets, selectedTargetsType });
     }
     this.setState({ loadingTargets: false });
   }

   onCropTypeChange = async (event) => {
     let { selectedCropType, crops } = this.state;
     const { getCropDetails } = this.props;
     if (selectedCropType === event.target.value) {
       return true;
     }
     selectedCropType = event.target.value;
     this.setState({ selectedCropType, crops: [], loadingCrops: true });
     const retVal = await getCropDetails(selectedCropType);

     if (retVal && retVal.crops && retVal.crops.length) {
       let newCrops = [...crops];
       newCrops = retVal.crops;
       this.setState({ crops: newCrops, loadingCrops: false });
     }
     // this.setState({ isloadingTable: false });
   }

getTargetUrl = async (key, fileType) => {
  const params = {
    activeTenant: this.props.getFromGlobalState('activeSite'),
    type: 'files',
    path: key,
    contentType: fileType,
  };
  const dowloadUrl = await getDownloadUrl(params);
  return (<img src={dowloadUrl} style={{ width: 50, borderRadius: '50%' }} />);
}

stringifyTargets = (arr) => {
  let str = '';
  arr.forEach((i, index) => {
    str = str.concat(i.name);
    if (index !== (arr.length - 1)) {
      str = str.concat(', ');
    }
  });
  return str;
}

getHeader = () => {
  const typeHeader = [
    {
      title: 'Target name',
      field: 'name',
      validate: (rowData) => (rowData.name === '' ? 'Crop name cannot be empty' : ''),
    },
    {
      title: 'Observable On',
      field: 'observableOnString',
      render: (rowData) => (rowData && rowData.observableOn && (this.stringifyTargets(rowData.observableOn))),
      // validate: (rowData) => (rowData && rowData.observableOn && rowData.observableOn.length !== 0 ? 'Observable on cannot be empty' : '')
    },
  /*  {
      title: 'Image',
      field: 'imageUrl',
      render: (rowData) => (rowData && rowData.image && (this.getTargetUrl(rowData.image.Key, rowData.image.fileType))),
    }, */
  ];
  return typeHeader;
}

render() {
  const {
    classes, data, getFromGlobalState, onTargetTypeChange, rows, selectedTargetsType, setGlobalState, setSnack,
  } = this.props;
  const targetsTypes = getFromGlobalState('targetsTypesSetting');
  const {
    crops, loadingTargets, loadingCrops, selectedTarget, isloading, columns, targetsDialogOpen, isNew,
  } = this.state;
  const actions = selectedTargetsType ? [{
    icon: () => <AddBox />,
    tooltip: 'Add Row',
    // This makes add button to appear in table toolbar instead for each row
    isFreeAction: true,
    onClick: (event, rowData) => {
      this.onRowAdd(event, rowData);
    },
  },
  {
    icon: () => <Edit />,
    tooltip: 'Edit Target',
    onClick: (event, rowData) => {
      this.onRowEdit(event, rowData);
    },
  },
  {
    icon: () => <Delete />,
    tooltip: 'Delete Target',
    onClick: (event, rowData) => {
      this.onRowDelete(event, rowData);
    },
  },
  ] : undefined;
  return (
    <Load isloading={isloading}>
      <Paper elevation={0} style={{ marginLeft: '4px' }} className={classes.paper}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="cropType">Target Type</InputLabel>
          <Select
            native
            fullWidth
            required
            displayEmpty
            value={selectedTargetsType}
            onChange={onTargetTypeChange}
            inputProps={{
              name: 'cropType',
              id: 'cropType',
            }}
          >
            <option value="" />
            {targetsTypes && targetsTypes.map((element) => (
              <>
                <option value={element.sk}>{element.name}</option>
              </>
            ))}
          </Select>
        </FormControl>
        <EditTable
          headCells={columns}
          title=""
          rows={rows && rows.length ? rows : []}
          // onRowAdd={selectedCropType && this.onRowAdd}
          // onRowUpdate={this.onRowUpdate}
          // onRowDelete={this.onRowDelete}
          isLoading={loadingTargets}
          actions={actions}
        />
        {targetsDialogOpen
        && (
        <TargetD
          isNew={isNew}
          handleUpdate={this.onHandleUpdate}
       // getFromGlobalState={this.props.getFromGlobalState}
          setClose={this.setClose}
          open={targetsDialogOpen}
          target={selectedTarget}
          loading={loadingCrops}
          targetType={selectedTargetsType}
          data={{ crops, cropTypes: data.cropTypes }}
          onCropTypeChange={this.onCropTypeChange}
          getFromGlobalState={getFromGlobalState}
          setGlobalState={setGlobalState}
          setSnack={setSnack}
        />
        )}
      </Paper>
    </Load>
  );
}
}
TargetsAdminForm.propTypes = {
  selected: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  getCropDetails: PropTypes.func.isRequired,
  getTargetDetails: PropTypes.func.isRequired,
  getAdminState: PropTypes.func.isRequired,
  setAdminState: PropTypes.func.isRequired,
  onTargetTypeChange: PropTypes.func.isRequired,
  selectedTargetsType: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
};
export default withStyles(styles)(TargetsAdminForm);
