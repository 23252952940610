import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as PDFIcon } from '../../images/fileIcons/pdf.svg';
import { ReactComponent as JPGIcon } from '../../images/fileIcons/jpg.svg';
import { ReactComponent as DOCIcon } from '../../images/fileIcons/doc.svg';
import { ReactComponent as GIFIcon } from '../../images/fileIcons/gif.svg';
import { ReactComponent as MP3Icon } from '../../images/fileIcons/mp3.svg';
import { ReactComponent as XLSIcon } from '../../images/fileIcons/xls.svg';
import { ReactComponent as PNGIcon } from '../../images/fileIcons/png.svg';
import { ReactComponent as PPTIcon } from '../../images/fileIcons/ppt.svg';
import { ReactComponent as ZIPIcon } from '../../images/fileIcons/zip.svg';
import { ReactComponent as AIIcon } from '../../images/fileIcons/ai.svg';
import { ReactComponent as MP4Icon } from '../../images/fileIcons/mp4.svg';
import { ReactComponent as MOVIcon } from '../../images/fileIcons/mov.svg';
import { ReactComponent as TXTIcon } from '../../images/fileIcons/txt.svg';
import { ReactComponent as BMPIcon } from '../../images/fileIcons/bmp.svg';
import { ReactComponent as MIDIIcon } from '../../images/fileIcons/midi.svg';
import { ReactComponent as PSIcon } from '../../images/fileIcons/ps.svg';
import { ReactComponent as SVGIcon } from '../../images/fileIcons/svg.svg';
import { ReactComponent as TIFIcon } from '../../images/fileIcons/tif.svg';
import { ReactComponent as WMVIcon } from '../../images/fileIcons/wmv.svg';
import { ReactComponent as PSDIcon } from '../../images/fileIcons/psd.svg';

import FolderIcon from '@material-ui/icons/Folder';
import UnknownIcon from '@material-ui/icons/Help';

 function getFolderIcon(contentType, size, classes) {
    switch (contentType.toLowerCase()) {
      case 'pdf': {
       return (<PDFIcon className={classes[size]} />);
      }
      case 'jpg': {
        return (<JPGIcon className={classes[size]} />);
       }
       case 'doc': {
        return (<DOCIcon className={classes[size]} />);
       }
       case 'docx': {
        return (<DOCIcon className={classes[size]} />);
       }
       case 'gif': {
        return (<GIFIcon className={classes[size]} />);
       }
       case 'mp3': {
        return (<MP3Icon className={classes[size]} />);
       }
       case 'xls':
       case 'xlsx': {
        return (<XLSIcon className={classes[size]} />);
       }
       case 'png': {
        return (<PNGIcon className={classes[size]} />);
       }
       case 'ppt':
       case 'pptx': {
        return (<PPTIcon className={classes[size]} />);
       }
       case 'zip': {
        return (<ZIPIcon className={classes[size]} />);
       }
       case 'ai': {
        return (<AIIcon className={classes[size]} />);
       }
       case 'mp4': {
        return (<MP4Icon className={classes[size]} />);
       }
       case 'mov': {
        return (<MOVIcon className={classes[size]} />);
       }
       case 'txt': {
        return (<TXTIcon className={classes[size]} />);
       }
       case 'bmp': {
        return (<BMPIcon className={classes[size]} />);
       }
       case 'midi': {
        return (<MIDIIcon className={classes[size]} />);
       }
       case 'ps': {
        return (<PSIcon className={classes[size]} />);
       }
       case 'svg': {
        return (<SVGIcon className={classes[size]} />);
       }
       case 'tif': {
        return (<TIFIcon className={classes[size]} />);
       }
       case 'wmv': {
        return (<WMVIcon className={classes[size]} />);
       }
       case 'psd': {
        return (<PSDIcon className={classes[size]} />);
       }
       case 'folder': {
        return (<FolderIcon fill='#cec177' color='secondary' className={classes[size]} />);
       }
      default: {
        return (<UnknownIcon fill='#cec177' color='secondary' className={classes[size]} />);
      }
    }
  }

export function getStream(contentType) {
  switch (contentType) {
    case 'pdf': {
     return ('../../images/fileIcons/pdf.svg');
    }
    case 'jpg': {
      return (<JPGIcon />);
     }
     case 'doc': {
      return (<DOCIcon />);
     }
     case 'docx': {
      return (<DOCIcon />);
     }
     case 'gif': {
      return (<GIFIcon />);
     }
     case 'mp3': {
      return (<MP3Icon />);
     }
     case 'xls': {
      return (<XLSIcon />);
     }
     case 'png': {
      return (<PNGIcon />);
     }
     case 'ppt': {
      return (<PPTIcon />);
     }
     case 'zip': {
      return (<ZIPIcon />);
     }
    default: {
      return (<SvgIcon color='secondary' style={{fontSize:"x-large"}}  viewBox="0 0 24 24">
        <path d="M23,4.1V2.3L21.2,2.1C21.1,2.1 20.5,2 19.5,2C15.4,2 12.4,3.2 10.7,5.3C9.4,4.5 7.6,4 5.5,4C4.5,4 3.8,4.1 3.8,4.1L1.9,4.4L2,6.1C2.1,9.1 3.6,14.8 8.8,14.8C8.9,14.8 8.9,14.8 9,14.8V18.2C5.2,18.7 2,20 2,20V22H22V20C22,20 18.8,18.7 15,18.2V15C21.3,14.9 23,7.8 23,4.1M12,18C11.7,18 11.3,18 11,18V12.4C11,12.4 10.8,9 8,9C8,9 9.5,9.8 9.9,12.7C9.5,12.8 9.1,12.8 8.8,12.8C4.2,12.8 4,6.1 4,6.1C4,6.1 4.6,6 5.5,6C7.4,6 10.5,6.4 11.4,9.1C11.9,4.6 17,4 19.5,4C20.4,4 21,4.1 21,4.1C21,4.1 21,13.1 14.7,13.1C14.5,13.1 14.2,13.1 14,13.1C14,11.1 16,8.1 16,8.1C13,9.1 13,13 13,13V18C12.7,18 12.3,18 12,18Z" />
      ></SvgIcon>)
    }
  }

}
const useStyles = makeStyles(theme => ({
  small: {
    width: '32px',
    height: '32px',
  },
  medium: {
    width: '135px',
    height: '180px',
  },
}));

export default function FileIcon(props) {
  const { contentType, size } = props;
  const classes = useStyles();
  return (
   
      getFolderIcon(contentType, size, classes)
  

  );
}

FileIcon.defaultProps = {
  size: 'medium',
};

FileIcon.propTypes = {
  contentType: PropTypes.string.isRequired,
  size: PropTypes.string,
};
