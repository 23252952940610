import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import OperatorSelector from '../../components/OperatorSelector';

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
});

const getTotalVolume = (duration, planting, block) => {
  if (!duration) { return undefined; }
  
  const unit = block.flowunit;
  let totalVolume = 0;
  if (unit === 'hectare') {
    totalVolume = parseFloat(duration) * parseFloat(block.volumePerHour) * parseFloat((planting.size ? planting.size : block.size));
  } else if (unit === 'plant') {
    totalVolume = duration * block.volumePerHour * planting[0].totalPlants;
  }
  return Math.round(totalVolume);
};


function WateringForm(props) {
  const {
    classes, record, errors, handleBlur, handleChange, block, planting, metaData,
  } = props;
  //record.operator = record.operator ? record.operator : '';
  record.actualLiters = getTotalVolume(record.duration,planting, block);
 // if (record.duration) {
 //   record.actualLiters = getTotalVolume(record.duration,planting, block);
 // }
  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start" >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          margin="normal"
          required
         // fullWidth
          type="date"
    //defaultValue={new Date()}
          id="date"
          label="Date"
          name="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={record.date}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur}
          error={errors.date ? true : undefined}
          helperText={errors.date}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
          margin="normal"
          required
          type="number"
          //inputProps={{ min: '0.00001', step: 'any' }}
          fullWidth
          id="duration"
          label="Duration"
          name="duration"
          value={record.duration}
          InputProps={{
            endAdornment: <InputAdornment position="end">hours</InputAdornment>,
            min: '0.00001',
            step: 'any',
          }}
  // value=
    // onChange={handleCalcVolume.bind(this,record,planting, block,updateParent,handleChange)}
          onChange={handleChange.bind(this)}
   // onChange={handleComplexChange.bind(this,'volumePerHour','litersApplied')}
          onBlur={handleBlur.bind(this)}
          error={errors.duration ? true : undefined}
          helperText={errors.duration}
        />
  </Grid>
     
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
    <FormControl fullWidth className={classes.formControl}>
      <InputLabel shrink htmlFor="actualLiters">Applied</InputLabel>
      <TextField
        margin="normal"
        required
        type="number"
       // inputProps={{ min: '0.00001', step:'any' }}
        InputProps={{
          endAdornment: <InputAdornment position="end">liters</InputAdornment>,
          min: '0.00001',
          step: 'any',
        }}
        fullWidth
        id="actualLiters"
// label="Total Applied"
        name="actualLiters"
// shrink
        value={record.actualLiters}
        onChange={handleChange.bind(this)}
        onBlur={handleBlur.bind(this)}
        error={errors.actualLiters ? true : undefined}
        helperText={errors.actualLiters}
      />

    </FormControl>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <OperatorSelector
          onChange={handleChange.bind(this)}
          operators={metaData.operators}
          title="Operator"
          value={record.operator}
          team={record.team}
          onBlur={handleBlur}
          error={errors.operator}
        />
      </Grid>
  </Grid>
 
  );
}

WateringForm.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
 // handleComplexChange: PropTypes.func,
 planting:PropTypes.object.isRequired,
 updateParent:PropTypes.func.isRequired,
 block: PropTypes.object.isRequired,
 metaData: PropTypes.object.isRequired,
 handleSwitchChange:PropTypes.func.isRequired,
};

export default withStyles(styles)(WateringForm);
