import React from 'react';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import CachedIcon from '@material-ui/icons/CloudUploadOutlined';
import SyncErrorIcon from '@material-ui/icons/CloudOffOutlined';
import RecordList from '../RecordList';
import Status from '../Status';
import RoundIcon from '../RoundIcon';
import settings from '../../libs/settings';
import { totalPlanting } from '../../libs/analytics';
import Can from '../Can';

// const { RECENTS_LEN } = settings.constants;

const styles = (theme) => ({
  actionsOld: {
    border: '1px solid rgba(0, 0, 0, .125)',

    paddingRight: '16px',
    margin: '8px',
    alignItems: 'self-end',
    borderRadius: '8px',
    // borderTop-left-radius: 8px;
    //  border-top-right-radius: 8px;

  },
  planting: {
    border: '1px solid rgba(0, 0, 0, .125)',
    // borderRadius:'8px',
    margin: '4px',
    //  marginTop:'8px',
    marginBottom: '8px',

  },

  actions: {
    alignItems: 'self-end',
    paddingRight: '4px',
    paddingLeft: '4px',

  },
  cardExpand: {
    padding: '2px',

  },
  spacer: {
    flex: '1 1 100%',
  },
  actionsH: {
    color: theme.palette.text.secondary,
    display: 'inline-flex',
  },
  title: {
    flex: '0 0 auto',
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    // marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  addPlanting: {
    // margin: theme.spacing(1),
    // float: 'right',
    left: '50%',
    marginLeft: '-24px',
    marginBottom: '10px',

  },
  editButton: {
    float: 'left',
    marginLeft: 'auto',
    // margin: theme.spacing(1),
    // float: 'right',
    // margin: theme.spacing(1),
    // marginLeft:'8px',
    // padding: '0px',
    // paddingTop: '0px',
    // paddingBottom: '0px',

  },
  editPlanting: {
    // margin: theme.spacing(1),
    // marginRight: 'auto',
    paddingLeft: '1px',

  },
  paper: {
    // marginTop: theme.spacing(8),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: '#fafafa',
    // alignItems: 'center',
    /// /////// marginBottom: theme.spacing(2),
    border: 'none',
    paddingLeft: '4px',
    paddingRight: '4px',
    // overflow: 'scroll',
    /* border: '1px solid rgba(0, 0, 0, .125)',
      marginLeft: '8px',
      marginRight: '8px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      marginTop: '-13px',
      borderTop: 0, */
  },
  cached: {
    fontSize: 15,
    color: theme.palette.secondary.main,
  },
  syncError: {
    fontSize: 15,
    color: 'red',
  },
});

const nameLookup = (record, key) => {
  const entity = record.find((e) => e.sk === key);
  let name = '';
  if (entity) {
    name = entity.name;
  }
  return name;
};
const varietyLookup = (record, parentKey, childKey) => {
  const crop = record.find((crop) => crop.sk === parentKey);
  let varietyName = '';
  if (crop) {
    const variety = crop.varieties.find((v) => v.sk === childKey);
    if (variety) {
      varietyName = variety.cultivar;
    }
  }
  return varietyName;
};

class PlantingItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0,

    };
  }

  // todo: this needs work
 getChildStatus = (records, type) => {
   if (!records) {
     return 'none';
   }
   switch (type) {
     case 'sprayings': {
       const today = new Date();
       for (const spraying of records) {
         if (spraying.phiLapse !== undefined) {
           const lapseDate = new Date(spraying.phiLapse.substring(0, 10));// todo: fix this, need time
           if (lapseDate > today) {
             return 'red';
           }
         } else {
           return 'red';
         }
       }
       return 'none';
     }
     default: {
       return 'none';
     }
   }
 }

 PlantingItem(planting, expandedPlanting, handleExpandClick, onClickView, classes, blockSK, onClickViewRecord,
   onClickAddRecord,
   handleExpandRecordClick,
   expandedRecord, settings, getChemAppDisplayHeader, getFromGlobalState, block, onClickViewReport, onClickViewTableItem, onClickViewAll, showAll, setCloseShowAll, farm, userDetail) {
   const expanded = expandedPlanting === planting.sk;

   // todo: build out for others
   const status = this.getChildStatus(planting.sprayings, 'sprayings');
   const totals = expanded && totalPlanting(planting);
   return (
     <Paper elevation={0} key={planting.sk} className={classes.planting}>
       <CardActions style={expanded ? { borderBottom: '1px solid rgba(0, 0, 0, .125)' } : undefined} className={classes.actions} disableSpacing onClick={handleExpandClick.bind(this, planting.sk)}>

         <IconButton
           className={clsx(classes.expand, {
             [classes.expandOpen]: expanded,
           })}
       // onClick={handleExpandClick.bind(this,planting.sk)}
           aria-expanded={expanded}
           aria-label="show more"
         >
           <ExpandMoreIcon />
         </IconButton>
         <div style={{ marginRight: '5px', cursor: 'pointer' }}>
           {' '}
           <>
             {(planting.cached && !planting.syncError) && <CachedIcon className={classes.cached} />}
             {(planting.cached && planting.syncError) && <SyncErrorIcon className={classes.syncError} />}
             <Typography variant="subtitle2">{new Date(planting.date).toLocaleDateString()}</Typography>
             <Typography style={{ opacity: 0.6 }} variant="subtitle2">{`${nameLookup(settings, planting.crop)} - ${varietyLookup(settings, planting.crop, planting.variety)} - ${planting.name ? planting.name : ''}`}</Typography>
           </>
         </div>

         <Status status={planting.status} hideIfGreen size="small" />
         {expanded && (
          <Can 
          role={userDetail && userDetail.rights && userDetail.rights.changeSettings ? 'changeSettings' : 'noAccess'}
          perform="openPlantingPage"
          yes={() => (
         <Tooltip placement="top" title="View Planting">
           <IconButton
             aria-label="Edit"
             color="primary"
              // size="small"
             className={classes.editButton}
             onClick={onClickView.bind(this, blockSK, planting.sk, block)}
           >
             <EditIcon />
           </IconButton>
         </Tooltip>
          )}
          />  
         )}

         {/* } <Button color="primary" className={classes.editPlanting} onClick={onClickView.bind(this,blockSK,planting.sk)}>
          {`${nameLookup(settings,planting.crop)} | ${varietyLookup(settings,planting.crop,planting.variety)} | ${ new Date(planting.date).toLocaleDateString()}`}
      </Button> */}

       </CardActions>
       <Collapse in={expanded} timeout="auto" unmountOnExit>
         <Paper elevation={0} className={classes.paper}>

           {expanded && (
           <RoundIcon
             fertiliser={{ total: totals.fertilisings }}
             water={{ total: totals.waterings, disabled: (block.irrigated === 'dryLand' ? true : undefined) }}
             pest={{ total: totals.pestings }}
             chemical={{ total: totals.sprayings, status }}
             harvest={{ total: totals.harvestings }}
             pack={{ total: totals.packings }}
             userDetail={getFromGlobalState('userDetail')}
             plantingSK={planting.sk}
             blockSK={blockSK}
             block={block}
             handleSelectType={handleExpandRecordClick}
             selectedIcon={expandedRecord.plantingSK === planting.sk ? expandedRecord.recordType : undefined}
           />
           )}
           {(expandedRecord.plantingSK === planting.sk && expandedRecord.recordType === 'fertilisings') && (
           <RecordList

             records={planting.fertilisings}
             displayFields={['date', 'productName']}
             plantingSK={planting.sk}
             blockSK={blockSK}
             title="Fertiliser"
             type="fertilisings"
             onViewRecord={onClickViewRecord}
             onAddRecord={onClickAddRecord}
             onViewReport={onClickViewReport}
             handleExpandClick={handleExpandRecordClick}
             expandedRecord={expandedRecord}
             getFromGlobalState={getFromGlobalState}
             planting={planting}
             block={block}
             onClickViewTableItem={onClickViewTableItem}
             onViewAll={onClickViewAll}
             showAll={showAll}
             setCloseShowAll={setCloseShowAll}
             farm={farm}
           />
           )}
           {(expandedRecord.plantingSK === planting.sk && expandedRecord.recordType === 'waterings') && (
           <RecordList
             records={planting.waterings}
             displayFields={['date', 'duration']}
             plantingSK={planting.sk}
             blockSK={blockSK}
             title="Irrigation"
             type="waterings"
             onViewRecord={onClickViewRecord}
             onAddRecord={onClickAddRecord}
             onViewReport={onClickViewReport}
             handleExpandClick={handleExpandRecordClick}
             expandedRecord={expandedRecord}
             getFromGlobalState={getFromGlobalState}
             planting={planting}
             block={block}
             onClickViewTableItem={onClickViewTableItem}
             onViewAll={onClickViewAll}
             showAll={showAll}
             setCloseShowAll={setCloseShowAll}
             farm={farm}
           />
           )}

           {(expandedRecord.plantingSK === planting.sk && expandedRecord.recordType === 'pestings') && (
           <RecordList
             records={planting.pestings}
             displayFields={['date', 'pest']}
             plantingSK={planting.sk}
             blockSK={blockSK}
             title="Monitoring"
             type="pestings"
             onViewRecord={onClickViewRecord}
             onAddRecord={onClickAddRecord}
             onViewReport={onClickViewReport}
             handleExpandClick={handleExpandRecordClick}
             expandedRecord={expandedRecord}
             getFromGlobalState={getFromGlobalState}
             planting={planting}
             block={block}
             onClickViewTableItem={onClickViewTableItem}
             onViewAll={onClickViewAll}
             showAll={showAll}
             setCloseShowAll={setCloseShowAll}
             farm={farm}
           />
           )}
           {(expandedRecord.plantingSK === planting.sk && expandedRecord.recordType === 'sprayings') && (
           <RecordList
             records={planting.sprayings}
             displayFields={['date', 'product']}
             plantingSK={planting.sk}
             blockSK={blockSK}
             title="Plant Protection"
             type="sprayings"
             onViewRecord={onClickViewRecord}
             onAddRecord={onClickAddRecord}
             onViewReport={onClickViewReport}
             handleExpandClick={handleExpandRecordClick}
             expandedRecord={expandedRecord}
             getDisplayHeader={getChemAppDisplayHeader}
             getFromGlobalState={getFromGlobalState}
             planting={planting}
             block={block}
             onClickViewTableItem={onClickViewTableItem}
             onViewAll={onClickViewAll}
             showAll={showAll}
             setCloseShowAll={setCloseShowAll}
             farm={farm}
           />
           )}

           {(expandedRecord.plantingSK === planting.sk && expandedRecord.recordType === 'harvestings') && (
           <RecordList
             records={planting.harvestings}
             displayFields={['date', 'harvestTotal']}
             plantingSK={planting.sk}
             blockSK={blockSK}
             title="Harvesting"
             type="harvestings"
             onViewRecord={onClickViewRecord}
             onAddRecord={onClickAddRecord}
             onViewReport={onClickViewReport}
             handleExpandClick={handleExpandRecordClick}
             expandedRecord={expandedRecord}
             getFromGlobalState={getFromGlobalState}
             planting={planting}
             block={block}
             onClickViewTableItem={onClickViewTableItem}
             onViewAll={onClickViewAll}
             showAll={showAll}
             setCloseShowAll={setCloseShowAll}
             farm={farm}
           />
           )}

           {(expandedRecord.plantingSK === planting.sk && expandedRecord.recordType === 'packings') && (
           <RecordList
             records={planting.packings}
             displayFields={['date', 'packTotal']}
             plantingSK={planting.sk}
             blockSK={blockSK}
             title="Packing"
             type="packings"
             onViewRecord={onClickViewRecord}
             onAddRecord={onClickAddRecord}
             onViewReport={onClickViewReport}
             handleExpandClick={handleExpandRecordClick}
             expandedRecord={expandedRecord}
             getFromGlobalState={getFromGlobalState}
             planting={planting}
             block={block}
             onClickViewTableItem={onClickViewTableItem}
             onViewAll={onClickViewAll}
             showAll={showAll}
             setCloseShowAll={setCloseShowAll}
             farm={farm}
           />
           )}

           {(expandedRecord.plantingSK === planting.sk && expandedRecord.recordType === 'activities') && (
           <RecordList
             records={planting.pestings}
             displayFields={['date', 'name']}
             plantingSK={planting.sk}
             blockSK={blockSK}
             title="Agronomic"
             type="activities"
             onViewRecord={onClickViewRecord}
             onAddRecord={onClickAddRecord}
             onViewReport={onClickViewReport}
             handleExpandClick={handleExpandRecordClick}
             expandedRecord={expandedRecord}
             getFromGlobalState={getFromGlobalState}
             planting={planting}
             block={block}
             onClickViewTableItem={onClickViewTableItem}
             onViewAll={onClickViewAll}
             showAll={showAll}
             setCloseShowAll={setCloseShowAll}
             farm={farm}
             noPagenation={false}
           />
           )}

         </Paper>
       </Collapse>

     </Paper>
   );
 }

  handleTabChange = (event, newValue) => {
    const currentTab = this.state.selectedTab;
    if (newValue !== currentTab) {
      this.setState({ selectedTab: newValue });
    }
  }

  render() {
    const getChemAppDisplayHeader = (record) => {
      const productKey = record.product;
      const chemicalProducts = this.props.getFromGloblState('chemicals');
      const productName = nameLookup(chemicalProducts, productKey);
      const header = `${record.date} | ${productName}`;
      return header;
    };
    const {
      classes,
      expandedPlanting,
      handleExpandClick,
      plantings,
      onClickAdd,
      blockSK,
      onClickView,
      onClickViewRecord,
      onClickAddRecord,
      handleExpandRecordClick,
      expandedRecord, settings, getFromGlobalState, block, onClickViewReport, onClickViewTableItem, onClickViewAll, showAll, setCloseShowAll,
    } = this.props;

    const farm = this.props.getFromGlobalState('farm');
    const userDetail = this.props.getFromGlobalState('userDetail');
    return (

      <div className={classes.root}>
        {/* <Typography style={{marginLeft:'5px'}} variant="subtitle1" gutterBottom>Plantings</Typography> */}
        <Toolbar style={{ paddingLeft: '8px', paddingRight: '8px' }}>
          <div className={classes.title}>
            <Typography variant="subtitle2" id="tableTitle">
              Plantings
            </Typography>
          </div>
          <div className={classes.spacer} />
          <Can
                role={userDetail && userDetail.rights && userDetail.rights.changeSettings ? 'changeSettings' : 'noAccess'}
                perform="openSettingsPage"
                yes={() => (
          <div className={classes.actionsH}>

            <Tooltip title="Add Planting">
              <IconButton size="medium" color="secondary" aria-label="add" onClick={onClickAdd.bind(this, blockSK, block)}>
                <AddIcon />
              </IconButton>
            </Tooltip>

          </div>
                )}
                />
        </Toolbar>

        {plantings && plantings.map((planting) => this.PlantingItem(planting,
          expandedPlanting,
          handleExpandClick,
          onClickView,
          classes,
          blockSK,
          onClickViewRecord,
          onClickAddRecord,
          handleExpandRecordClick,
          expandedRecord, settings, getChemAppDisplayHeader, getFromGlobalState, block, onClickViewReport, onClickViewTableItem, onClickViewAll, showAll, setCloseShowAll, farm[0], userDetail))}

      </div>

    );
  }
}
PlantingItems.propTypes = {
  onClickAdd: PropTypes.func.isRequired,
  expandedPlanting: PropTypes.string.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
  plantings: PropTypes.array,
  blockSK: PropTypes.string.isRequired,
  onClickView: PropTypes.func.isRequired,
  onClickViewRecord: PropTypes.func.isRequired,
  onClickViewTableItem: PropTypes.func,
  onClickViewReport: PropTypes.func.isRequired,
  onClickAddRecord: PropTypes.func.isRequired,
  onClickViewAll: PropTypes.func,
  handleExpandRecordClick: PropTypes.func.isRequired,
  expandedRecord: PropTypes.object,
  settings: PropTypes.object,
  getFromGlobalState: PropTypes.func.isRequired,
  block: PropTypes.array.isRequired,
  showAll: PropTypes.bool,
  setCloseShowAll: PropTypes.func,

};
export default withStyles(styles)(PlantingItems);
