import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import UploadIcon from '@material-ui/icons/CloudUpload';
import Fab from '@material-ui/core/Fab';
import uuid from 'uuid';
import { parseCSVCommaInValue } from '../../libs/genLib';
import {
  toStore, getErrorMessage, getDownloadUrl, downloadFile, createBatchRecords,
} from '../../libs/storage';
import Load from '../../components/Load';
import ChemicalD from '../ChemicalD';
import EnhancedTable from '../../components/Etable';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '5px',
    backgroundColor: 'inherit',
  },
  paper: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
    marginLeft: '4px',
  },
  mainSection: {
    width: '100%',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    paddingRight: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1),
  },
});

const headCells = [
  {
    id: 'isDisabled', numeric: false, disablePadding: false, label: 'Disabled', maxWidth: '80px',
  },
  {
    id: 'name', numeric: false, disablePadding: false, label: 'Name',
  },
  {
    id: 'registrationNumber', numeric: false, disablePadding: false, label: 'Reg Num',
  },
  {
    id: 'registrationHolder', numeric: false, disablePadding: false, label: 'Reg Holder',
  },
  {
    id: 'aIngredient', numeric: false, disablePadding: false, label: 'Active Ingredient', minWidth: '200px',
  },
];

const requiredCSVColumns = ['name', 'registrationNumber', 'registrationHolder', 'aIngredient'];

const mobileHeadCells = [
  {
    id: 'name',
    combine: [{ id: 'registrationNumber', opacity: 1 }, { id: 'registrationHolder', opacity: 0.6 }],
    numeric: false,
    disablePadding: true,
    label: 'Name',
    maxWidth: '100px',
  },
  {
    id: 'isDisabled', numeric: false, disablePadding: true, label: 'Disabled',
  },
];

class PPPAdminForm extends Component {
  constructor(props) {
    super(props);
    this.setClose = this.setClose.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleClickShowPPP = this.handleClickShowPPP.bind(this);
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleSelectFile = this.handleSelectFile.bind(this);

    this.state = {
      isloading: false,
      selectedFile: null,
      pppDialogOpen: false,
      isNew: false,
      selectedPPP: null,

    };
  }

  async componentDidMount() {
    const {
      isAuthenticated, getPPPDetails,
    } = this.props;
    if (isAuthenticated) {
      this.setState({ isloading: true });
      await getPPPDetails();
      this.setState({ isloading: false });
      // setAdminState({ genFertilisers });
    }
  }

  handleClickShowPPP = (event, parent, sk) => {
    const { ppps } = this.props;
    const ppp = ppps.find((p) => p.sk === sk);
    if (ppp) {
      this.setState({ selectedPPP: ppp, pppDialogOpen: true });
    }
  };

  handleClickAdd() {
    this.setState({
      isNew: true,
      selectedPPP: {
        isGeneral: true,
        setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
        isDisabled: false,
        type: 'genPPPs',
      },
    });
  }

  handleUpdate(PPPIn) {
    const {
      getAdminState, setAdminState,
    } = this.props;
    const genPPPs = getAdminState('genPPPs');
    const updatedIndex = genPPPs.findIndex((i) => i.sk === PPPIn.sk);
    const newPPPs = [...genPPPs];
    if (updatedIndex === -1) {
      newPPPs.push(PPPIn);
    } else {
      newPPPs[updatedIndex] = PPPIn;
    }
    setAdminState({ genPPPs: newPPPs });
  }

  // close the fertiliser dialog
  setClose() {
    this.setState({ pppDialogOpen: false, isNew: false });
  }

  // write a function called validateFile that will take the loaded file and identify the rows where the name is duplicated
  // return the name of the duplicate row
  validateFile = (ppps) => {
    const { setSnack } = this.props;
    // check if each object contains the required columns
    const missingColumns = ppps.filter((ppp) => requiredCSVColumns.some((column) => !ppp[column]));
    if (missingColumns.length) {
      setSnack({
        open: true,
        variant: 'error',
        messageText: 'The PPPs are missing required columns: \'name\', \'registrationNumber\', \'registrationHolder\', \'aIngredient\'',
      });
      return false;
    }
    const names = ppps.map((p) => p.name);
    const duplicates = names.filter((name, index) => names.indexOf(name.trim()) !== index);
    if (duplicates.length) {
      setSnack({
        open: true,
        variant: 'error',
        messageText: `The following PPPs are duplicated: ${duplicates.join(', ')}. Please remove them and upload again.`,
      });
      return false;
    }
    return true;
  }

  // write a new function called loadFertiliser that will take the fertilisers that has been parsed
  // and then load them into the database
  loadPPP = async (ppps) => {
    const { getFromGlobalState, getAdminState } = this.props;
    const { setSnack } = this.props;
    this.setState({ isloading: true });
    const genPPPS = getAdminState('genPPPs');
    const newPPPs = ppps.map((ppp) => {
      const newPPP = ppp;
      const updatedIndex = genPPPS.findIndex((i) => i.name === ppp.name);
      if (updatedIndex === -1) {
        const skPrimary = 'init_';
        newPPP.sk = skPrimary.concat(uuid.v1());
        newPPP.version = 1;
      } else {
        newPPP.version = genPPPS[updatedIndex].version + 1;
        newPPP.sk = genPPPS[updatedIndex].sk;
      }
      newPPP.type = 'genPPPs';
      newPPP.setId = 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3';
      newPPP.isGeneral = true;
      newPPP.isDisabled = false;
      newPPP.name = ppp.name.trim();
      newPPP.registrationNumber = ppp.registrationNumber.trim();
      newPPP.registrationHolder = ppp.registrationHolder.trim();
      newPPP.aIngredient = ppp.aIngredient.trim();

      return newPPP;
    });
    try {
      setSnack({ open: true, variant: 'info', messageText: `Uploading ${newPPPs.length} PPPs.` });
      const recordsObject = { items: newPPPs };
      recordsObject.activeTenant = getFromGlobalState('activeSite');
      recordsObject.type = 'batchupload';
      const creation = await createBatchRecords(
        getFromGlobalState('currentUser').username, 'calls',
        toStore(recordsObject, true),
        getFromGlobalState('activeSite'),
      );
      if (creation.status) {
        for (let f = 0; f < newPPPs.length; f += 1) {
          this.handleUpdate(newPPPs[f], false);
        }
        setSnack({
          open: true, autoHideDuration: 5000, variant: 'info', messageText: `Completed uploading ${newPPPs.length} PPPS.`,
        });
      } else {
        setSnack({ open: true, variant: 'error', messageText: 'Failed to opload PPPs' });
        this.setState({ isloading: false });
      }
      this.setState({ isloading: false });
    } catch (e) {
      setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
      this.setState({ isloading: false });
    }
  }

  handleParseAndLoad = async (file) => {
    const { setSnack, getFromGlobalState } = this.props;
    const params = {
      activeTenant: getFromGlobalState('activeSite'),
      type: 'files',
      path: file.Key,
      contentType: 'application/octet-stream', // selectedFile.fileType,
    };
    try {
      const url = await getDownloadUrl(params);
      const result = await downloadFile(url);
      const blobResult = await result.blob();
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        const ppps = parseCSVCommaInValue(text);
        if (this.validateFile(ppps)) {
          await this.loadPPP(ppps);
        }
        // this.setState({ rows, columns, isloading: false });
      };
      reader.readAsText(blobResult);
    } catch (e) {
      setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    }
  }

  // create a new function called getFerliserRows that will return the following propoerties
  // for each object in the array
  // sk, name,description, iDisabled and description, the description should be a concateanation
  // of all the rest of the object attributes not yet covered.
  // the iDisabled should be a boolean value that is true if the enabled attribute is false and
  // false if the enabled attribute is true.
  // the sk should be the sk attribute of the object
  // the name should be the name attribute of the object
  // the description should be a concateanation of all the rest of the object attributes
  // not yet covered.

  getPPPRows = (pppDetail) => {
    if (!pppDetail || !pppDetail.length) {
      return [];
    }
    const pppRows = pppDetail.map((ppp) => {
      const ob = { ...ppp };
      ob.isDisabled = ob.isDisabled ? 'Yes' : 'No';
      // the description should be a concateanation of all the rest of the object attributes in a fertilier. use the object attribute name as well
      return ob;
    });
    return pppRows;
  }

  // write and on change handler for the select file
  handleSelectFile = async (event) => {
    const { value } = event.target;
    const { getFromGlobalState } = this.props;
    const newSelectedFile = getFromGlobalState('files').find((file) => file.sk === value);
    this.setState({ selectedFile: newSelectedFile });
  };

  handleUploadFile = async () => {
    const { selectedFile } = this.state;
    this.setState({ isloading: true });
    await this.handleParseAndLoad(selectedFile);
    this.setState({ isloading: false });
  }

  render() {
    const {
      classes,
      getFromGlobalState,
      ppps,
      isMobile,
    } = this.props;
    const {
      isloading,
      selectedFile,
      pppDialogOpen,
      selectedPPP,
      isNew,
    } = this.state;
    const files = getFromGlobalState('files');
    const userDetail = getFromGlobalState('userDetail');
    const rows = this.getPPPRows(ppps);
    // filter the files where the Key contains 'Settings/Fertilisers'
    const filteredFiles = files.filter((file) => file.Key.includes('Settings/Plant protection products'));

    return (

      <Paper elevation={0} className={classes.paper}>
        {(pppDialogOpen)
          && (
          <ChemicalD
            handleUpdate={this.handleUpdate}
            open={pppDialogOpen}
            setClose={this.setClose}
            isNew={isNew}
            getFromGlobalState={getFromGlobalState}
            chemical={selectedPPP}
            dynamoType="genPPPs"
            ppps={rows}
          />
          )}

        <FormControl className={classes.formControl}>
          <InputLabel>Select file to upload</InputLabel>
          <Select
            native
          // disableUnderline
          // key="3a"
             // className={classes.select}
            required
              // style={{ paddingLeft: '8px' }}
            value={selectedFile && selectedFile.sk}
            onChange={this.handleSelectFile}
          // MenuProps={{ classes: { paper: classes.menuPaper } }}
            inputProps={{
              name: 'selectedFile',
              id: 'selectedFile',
            }}
          >
            <option key="" value="" />
            {filteredFiles
              && filteredFiles.map(
                (file) => <option key={file.sk} value={file.sk}>{file.filename}</option>,
              )}

          </Select>
        </FormControl>

        <Tooltip title="Upload">
          <Fab
            variant="extended"
            disabled={!selectedFile || isloading}
            size="medium"
            color="primary"
            aria-label="add"
            className={classes.margin}
            onClick={this.handleUploadFile}
          >
            <UploadIcon className={classes.extendedIcon} />
            Upload
          </Fab>

        </Tooltip>

        <Paper elevation={0} className={classes.paper}>
          <Load isloading={isloading}>
            <InputLabel>{`${rows && rows.length} PPPs `}</InputLabel>
            <EnhancedTable
              heading="PPPs"
              displayToolbar
              hideHeading
              id="pppTable"
              noPagination //= { rows && rows.length > 5 ? false:true}
              rows={rows}
              headCells={!isMobile ? headCells : mobileHeadCells}
              handleSelect={this.handleClickShowPPP}
              handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handleClickAdd : null}
              actionType="actionType"
              rowKeyName="sk"
              disablePadFirstCol
             // padFirstCol="5px"
              clean
              dense
        // parent
       // ancestorsInRow
        // parentSK="parentSK"
              maxHeight="350px"
              selectedRow={selectedPPP ? selectedPPP.sk : ''}
            />
          </Load>
        </Paper>
      </Paper>

    );
  }
}
PPPAdminForm.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  getAdminState: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
  ppps: PropTypes.array.isRequired,
  setAdminState: PropTypes.func.isRequired,
  getPPPDetails: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
export default withStyles(styles)(PPPAdminForm);
