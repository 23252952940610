import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import SvgIcon from '@material-ui/core/SvgIcon';
import AreaIcon from '@material-ui/icons/InfoOutlined';
import Load from '../../components/Load';
import Tabs from '../../components/Tabs';
import Map from '../../components/Map';
import StatBox from '../../components/StatBox';
import KPIBox from '../../components/KPIBox';
import FilterBox from '../../components/FilterBox';
import { getCensusAnon, getErrorMessage } from '../../libs/storage';
import countryData from '../../data/countries.json';
import {
  numberOfPlants, flattenItems, groupBySum, groupByMultiple, groupByMultipleSum, arrayToCSV, groupByCount,
} from '../../libs/analytics';
import ChartBar from '../../components/BarChart';
import ChartPie2 from '../../components/Piechart2';
import BubbleChart from '../../components/BubbleChart';
import ChartPieTwoLevel from '../../components/PieChartTwoLevel';
import ChartBarStacked from '../../components/BarChartStacked';

function AgronomistIcon(props) {
  return (
    <SvgIcon color={props.color} style={{ fontSize: 'x-large' }} viewBox="0 0 24 24">
      <path d="M19.5,12c0.93,0,1.78,0.28,2.5,0.76V8c0-1.1-0.9-2-2-2h-6.29l-1.06-1.06l1.41-1.41l-0.71-0.71L9.82,6.35l0.71,0.71 l1.41-1.41L13,6.71V9c0,1.1-0.9,2-2,2h-0.54c0.95,1.06,1.54,2.46,1.54,4c0,0.34-0.04,0.67-0.09,1h3.14 C15.3,13.75,17.19,12,19.5,12z" />
      <path d="M19.5,13c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5S21.43,13,19.5,13z M19.5,18 c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5s1.5,0.67,1.5,1.5S20.33,18,19.5,18z" />
      <path d="M4,9h5c0-1.1-0.9-2-2-2H4C3.45,7,3,7.45,3,8C3,8.55,3.45,9,4,9z" />
      <path d="M9.83,13.82l-0.18-0.47L10.58,13c-0.46-1.06-1.28-1.91-2.31-2.43l-0.4,0.89l-0.46-0.21l0.4-0.9C7.26,10.13,6.64,10,6,10 c-0.53,0-1.04,0.11-1.52,0.26l0.34,0.91l-0.47,0.18L4,10.42c-1.06,0.46-1.91,1.28-2.43,2.31l0.89,0.4l-0.21,0.46l-0.9-0.4 C1.13,13.74,1,14.36,1,15c0,0.53,0.11,1.04,0.26,1.52l0.91-0.34l0.18,0.47L1.42,17c0.46,1.06,1.28,1.91,2.31,2.43l0.4-0.89 l0.46,0.21l-0.4,0.9C4.74,19.87,5.36,20,6,20c0.53,0,1.04-0.11,1.52-0.26l-0.34-0.91l0.47-0.18L8,19.58 c1.06-0.46,1.91-1.28,2.43-2.31l-0.89-0.4l0.21-0.46l0.9,0.4C10.87,16.26,11,15.64,11,15c0-0.53-0.11-1.04-0.26-1.52L9.83,13.82z M7.15,17.77c-1.53,0.63-3.29-0.09-3.92-1.62c-0.63-1.53,0.09-3.29,1.62-3.92c1.53-0.63,3.29,0.09,3.92,1.62 C9.41,15.38,8.68,17.14,7.15,17.77z" />
      >
    </SvgIcon>
  );
}

function EarthIcon(props) {
  return (
    <SvgIcon color={props.color} style={{ fontSize: 'x-large' }} viewBox="0 0 24 24">
      <path d="M17.9,17.39C17.64,16.59 16.89,16 16,16H15V13C15,12.45 14.55,12 14,12H8V10H10C10.55,10 11,9.55 11,9V7H13C14.1,7 15,6.1 15,5V4.59C17.93,5.77 20,8.64 20,12C20,14.08 19.2,15.97 17.9,17.39M11,19.93C7.05,19.44 4,16.08 4,12C4,11.38 4.08,10.78 4.21,10.21L9,15V16C9,17.1 9.9,18 11,18M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2Z" />
      >
    </SvgIcon>
  );
}

function TreesIcon(props) {
  return (
    <SvgIcon color={props.color} style={{ fontSize: 'x-large' }} viewBox="0 0 24 24">
      <path d="M16 12L9 2L2 12H3.86L0 18H7V22H11V18H18L14.14 12H16M20.14 12H22L15 2L12.61 5.41L17.92 13H15.97L19.19 18H24L20.14 12M13 19H17V22H13V19Z" />
      >
    </SvgIcon>
  );
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginBottom: theme.spacing(1),
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // marginBottom: theme.spacing(10),
    backgroundColor: 'inherit',
    // overflow: 'scroll',
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  section: {
    margin: theme.spacing(2, 4),
  },

});

class CensusAnon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedSetting: '',
      // census: [],
      blockPaths: [],
      flatItems: [],
      graphData: {
        province: {},
        country: {},
        cropType: {},
        crop: {},
        cultivarType: {},
        cultivar: {},
        plantingDensity: {},
        plantAge: {},
        irrigationType: {},
        rootStock: {},
        rootStockType: {},
      },
      selectedTab: 0,
      selectedGraphTab: 0,
      filteredStats: {
        farms: 0,
        size: 0,
        plantings: 0,
        trees: 0,
      },
      allStats: {
        farms: 0,
        size: 0,
        plantings: 0,
        trees: 0,
      },
      isLoading: true,
      metaData: {
        countries: [],
        provinces: [],
        regions: [],
        cropTypes: [],
        crops: [],
        varieties: [],
        cultivarTypes: [],
        cultivars: [],
        rootStockTypes: [],
        rootStocks: [],
      },
      filteredMetaData: {
        countries: [],
        provinces: [],
        regions: [],
        cropTypes: [],
        crops: [],
        varieties: [],
        cultivarTypes: [],
        cultivars: [],
        rootStockTypes: [],
        rootStocks: [],
      },
      selectedData: {
        countries: [],
        regions: [],
        provinces: [],
        cropTypes: [],
        crops: [],
        // varieties: [],
        cultivarTypes: [],
        cultivars: [],
        rootStockTypes: [],
        rootStocks: [],
      },
    };
  }

  async componentDidMount() {
    const { setSnack, getFromGlobalState, setGlobalState } = this.props;
    try {
      // this.setState({ isLoading: true });
      const { stats } = this.state;
      const metaData = getFromGlobalState('metaData');
      const newMetaData = { ...metaData };
      const newStats = { ...stats };
      let census = getFromGlobalState('census');
      if (!census) {
        census = await getCensusAnon();
        if (!census.status) {
          throw new Error(census.error);
        }
        census = census.result;
      }

      const filteredItems = this.filterItems(census.Items);

      const allStats = getFromGlobalState('allStats');
      if (!allStats.set) {
        const newAllStats = { ...allStats };
        newAllStats.set = true;
        newAllStats.farms = filteredItems.stats.farms;
        newAllStats.size = filteredItems.stats.size;
        newAllStats.sizeByCrop = filteredItems.stats.sizeByCrop;
        newAllStats.trees = filteredItems.stats.trees;
        newAllStats.plantings = filteredItems.stats.plantings;
        this.props.setGlobalState({ allStats: newAllStats });
      }
      // census.Items = filteredItems.filteredItems;
      const blockPaths = this.getPaths(filteredItems.filteredItems);

      // const flatItems = flattenItems(filteredItems.filteredItems);
      // const graphData = this.generateGraphData(0, flatItems);

      newMetaData.countries = countryData.map((country) => {
        const ob = {};
        ob.name = country.countryName;
        ob.sk = country.countryName;
        return ob;
      });
      // cropTypes
      const cropTypeArr = census.cropTypes ? Object.getOwnPropertyNames(census.cropTypes) : [];
      for (let ct = 0; ct < cropTypeArr.length; ct += 1) {
        const ctT = {};
        ctT.name = census.cropTypes[cropTypeArr[ct]].name;
        ctT.sk = census.cropTypes[cropTypeArr[ct]].id;
        ctT.setId = census.cropTypes[cropTypeArr[ct]].set;
        newMetaData.cropTypes.push(ctT);
      }
      // crops
      const cropsArr = census.crops ? Object.getOwnPropertyNames(census.crops) : [];
      for (let ct = 0; ct < cropsArr.length; ct += 1) {
        const ctT = {};
        ctT.name = census.crops[cropsArr[ct]].name;
        ctT.sk = census.crops[cropsArr[ct]].id;
        ctT.setId = census.crops[cropsArr[ct]].set;
        ctT.cropType = census.crops[cropsArr[ct]].cropType;
        newMetaData.crops.push(ctT);
      }
      // irrigationTypes
      newMetaData.irrigationTypes = census.irrigationTypes ? Object.getOwnPropertyNames(census.irrigationTypes) : [];
      // varieties
      const varietiesArr = census.varieties ? Object.getOwnPropertyNames(census.varieties) : [];
      for (let ct = 0; ct < varietiesArr.length; ct += 1) {
        const ctT = {};
        ctT.cultivar = census.varieties[varietiesArr[ct]].cultivar;
        ctT.sk = census.varieties[varietiesArr[ct]].id;
        ctT.cultivarType = census.varieties[varietiesArr[ct]].cultivarType;
        ctT.rootStock = census.varieties[varietiesArr[ct]].rootStock;
        ctT.rootStockType = census.varieties[varietiesArr[ct]].rootStockType;
        newMetaData.varieties.push(ctT);
        this.distinctPush(newMetaData.cultivars, { name: ctT.cultivar, sk: ctT.cultivar });
        this.distinctPush(newMetaData.cultivarTypes, { name: ctT.cultivarType, sk: ctT.cultivarType });
        this.distinctPush(newMetaData.rootStocks, { name: ctT.rootStock, sk: ctT.rootStock });
        this.distinctPush(newMetaData.rootStockTypes, { name: ctT.rootStockType, sk: ctT.rootStockType });
      }

      const { setGlobalState } = this.props;
      setGlobalState({ census, filteredMetaData: newMetaData, metaData: newMetaData });
      this.setState({
        isLoading: false, blockPaths,
      });
      /* this.setState({
        census: filteredItems.filteredItems, isLoading: false, blockPaths, metaData: newMetaData, filteredMetaData: newMetaData, stats: newStats,
      }); */
      // temp to set filters for nuts
      this.handleFilterToggle('South Africa', 'countries');
      this.handleFilterToggle('init_56c7764d-177f-4184-8d84-df56b5e26117', 'cropTypes');
      this.handleFilterToggle('init_56c7764d-177f-4184-8d84-df56b5e26117_setchild_7563ad0a-74c7-4b6e-9521-d4b829de4425', 'crops');
    } catch (e) {
      // this.setState({ census: [], blockPaths: [], isLoading: false });
      this.props.setGlobalState({ census: null });
      this.setState({ blockPaths: [], isLoading: false });
      setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    }
  }

  distinctPush = (array, member) => {
    const idx = array.findIndex((p) => p.sk === member.sk);
    if (idx === -1) {
      array.push(member);
    }
  }

  handleExpand = (setting) => {
    const { expandedSetting } = this.state;
    if (expandedSetting !== setting) {
      this.setState({ expandedSetting: setting });
    } else {
      this.setState({ expandedSetting: '' });
    }
  }

  getPaths = (items) => {
    if (!items) {
      return null;
    }
    const paths = [];
    const itemLength = items.length;
    for (let i = 0; i < itemLength; i += 1) {
      if (!items[i].blocks || !items[i].farm) {
        continue;
      }
      for (let b = 0; b < items[i].blocks.length; b += 1) {
        if (!items[i].blocks[b].paths) {
          continue;
        }
        paths.push({
          label: '', sk: items[i].blocks[b].id, key: items[i].blocks[b].id, paths: items[i].blocks[b].paths, color: 'white', strokeColor: 'white', strokeWeight: 1,
        });
      }
    }
    return paths;
  }

  injectVarieties = (newMetaData) => {
    for (let v = 0; v < newMetaData.varieties.length; v += 1) {
      this.distinctPush(newMetaData.cultivars, { name: newMetaData.varieties[v].cultivar, sk: newMetaData.varieties[v].cultivar });
      this.distinctPush(newMetaData.cultivarTypes, { name: newMetaData.varieties[v].cultivarType, sk: newMetaData.varieties[v].cultivarType });
      this.distinctPush(newMetaData.rootStocks, { name: newMetaData.varieties[v].rootStock, sk: newMetaData.varieties[v].rootStock });
      this.distinctPush(newMetaData.rootStockTypes, { name: newMetaData.varieties[v].rootStockType, sk: newMetaData.varieties[v].rootStockType });
    }
  }

  handleFilterToggle2 = (key, arrayName) => {
    const { getFromGlobalState, setGlobalState } = this.props;
    const { selectedGraphTab } = this.state;
    const census = getFromGlobalState('census');
    const metaData = getFromGlobalState('metaData');
    const selectedData = getFromGlobalState('selectedData');
    const newSelectedData = { ...selectedData };
    const newMetaData = { ...metaData };

    const currentIndex = newSelectedData[arrayName].indexOf(key);

    if (currentIndex === -1) {
      newSelectedData[arrayName].push(key);
    } else {
      newSelectedData[arrayName].splice(currentIndex, 1);
    }
    switch (arrayName) {
      case 'countries':
      case 'provinces': {
        const selectedCountries = countryData.filter((f) => newSelectedData.countries.indexOf(f.countryName) !== -1);
        let provinces = [];
        for (let c = 0; c < selectedCountries.length; c += 1) {
          const provinceEntry = {};
          provinceEntry.name = selectedCountries[c].countryName;
          provinceEntry.sk = selectedCountries[c].countryShortCode;
          provinceEntry.disabled = true;
          provinces.push(provinceEntry);
          provinces = provinces.concat(selectedCountries[c].regions.map((r) => {
            const ob = {};
            ob.name = r.name;
            ob.sk = r.name;
            return ob;
          }));
        }
        newMetaData.provinces = provinces;
        setGlobalState({ metaData: newMetaData });
        // this.setState({ metaData: newMetaData });
        break;
      }
      case 'irrigationTypes': {
        break;
      }
      case 'cropTypes':
      case 'crops':
      case 'cultivarTypes':
      case 'cultivars':
      case 'rootStockTypes':
      case 'rootStocks': {
        break;
      }
      default: {
        const g = 'w';
      }
    }
    const filteredItems = this.filterItems(census.Items, newSelectedData);
    const blockPaths = this.getPaths(filteredItems.filteredItems);
    const flatItems = flattenItems(filteredItems.filteredItems);
    // find the remaining irrigation types and add it to newMetadata.
    // if (arrayName !== 'irrigationTypes') {
    if (arrayName !== 'cropTypes') {
      newMetaData.cropTypes = [...new Set(flatItems.map((item) => item.cropTypeName))];
      newMetaData.cropTypes = newMetaData.cropTypes.map((it) => {
        const ob = { name: it, sk: it };
        return ob;
      });
    }
    if (arrayName !== 'crops') {
      newMetaData.crops = [...new Set(flatItems.map((item) => item.cropName))];
      newMetaData.crops = newMetaData.crops.map((it) => {
        const ob = { name: it, sk: it };
        return ob;
      });
    }
    if (arrayName !== 'cultivarTypes') {
      newMetaData.cultivarTypes = [...new Set(flatItems.map((item) => item.cultivarType))];
      newMetaData.cultivarTypes = newMetaData.cultivarTypes.map((it) => {
        const ob = { name: it, sk: it };
        return ob;
      });
    }
    if (arrayName !== 'cultivars') {
      newMetaData.cultivars = [...new Set(flatItems.map((item) => item.cultivar))];
      newMetaData.cultivars = newMetaData.cultivars.map((it) => {
        const ob = { name: it, sk: it };
        return ob;
      });
    }
    if (arrayName !== 'rootStockTypes') {
      newMetaData.rootStockTypes = [...new Set(flatItems.map((item) => item.rootStockType))];
      newMetaData.rootStockTypes = newMetaData.rootStockTypes.map((it) => {
        const ob = { name: it, sk: it };
        return ob;
      });
    }
    if (arrayName !== 'rootStocks') {
      newMetaData.rootStocks = [...new Set(flatItems.map((item) => item.rootStock))];
      newMetaData.rootStocks = newMetaData.rootStocks.map((it) => {
        const ob = { name: it, sk: it };
        return ob;
      });
    }
    if (arrayName !== 'irrigationTypes') {
      newMetaData.irrigationTypes = [...new Set(flatItems.map((item) => item.irrigationSystem))];
      newMetaData.irrigationTypes = newMetaData.irrigationTypes.map((it) => {
        const ob = { name: it, sk: it };
        return ob;
      });
    }
    //  }

    const graphData = this.generateGraphData(selectedGraphTab, flatItems);
    // const graphData = this.generateGraphData(flatItems, newSelectedData);
    // const areaByProvince = groupBySum(flatItems, 'province', 'size', 'Province', false);
    // const areaByProvinceM = groupByMultiple(flatItems, ['country', 'province', 'cropName']);
    // const groupByMultipleSumM = groupByMultipleSum(flatItems, ['country', 'province', 'cropName'], 'size', 'Crop', true);
    // const areaByCrop = groupBySum(flatItems, 'cropName', 'size', 'Crop', true);
    this.props.setGlobalState({ filteredMetaData: newMetaData, selectedData: newSelectedData, filteredStats: filteredItems.stats });
    // this.props.setGlobalState({ selectedData: newSelectedData });
    // this.props.setGlobalState({ filteredStats: filteredItems.stats });
    this.setState({
      blockPaths, flatItems, graphData,
    });
  }

  handleFilterToggle = (key, arrayName) => {
    const { getFromGlobalState, setGlobalState } = this.props;
    const { selectedGraphTab } = this.state;
    const census = getFromGlobalState('census');
    const metaData = getFromGlobalState('metaData');
    const selectedData = getFromGlobalState('selectedData');
    const newSelectedData = { ...selectedData };
    const newMetaData = { ...metaData };

    const currentIndex = newSelectedData[arrayName].indexOf(key);

    if (currentIndex === -1) {
      newSelectedData[arrayName].push(key);
    } else {
      newSelectedData[arrayName].splice(currentIndex, 1);
    }
    switch (arrayName) {
      case 'countries':
      case 'provinces': {
        const selectedCountries = countryData.filter((f) => newSelectedData.countries.indexOf(f.countryName) !== -1);
        let provinces = [];
        for (let c = 0; c < selectedCountries.length; c += 1) {
          const provinceEntry = {};
          provinceEntry.name = selectedCountries[c].countryName;
          provinceEntry.sk = selectedCountries[c].countryShortCode;
          provinceEntry.disabled = true;
          provinces.push(provinceEntry);
          provinces = provinces.concat(selectedCountries[c].regions.map((r) => {
            const ob = {};
            ob.name = r.name;
            ob.sk = r.name;
            return ob;
          }));
        }
        newMetaData.provinces = provinces;
        setGlobalState({ metaData: newMetaData });
        // this.setState({ metaData: newMetaData });
        break;
      }
      case 'irrigationTypes': {
        break;
      }
      case 'cropTypes':
      case 'crops':
      case 'cultivarTypes':
      case 'cultivars':
      case 'rootStockTypes':
      case 'rootStocks': {
        if (selectedData.cropTypes.length) {
          newMetaData.crops = newMetaData.crops.filter((f) => selectedData.cropTypes.indexOf(f.cropType) !== -1);
        }
        let filteredCrops = [...newMetaData.crops];
        if (selectedData.crops.length) {
          filteredCrops = newMetaData.crops.filter((f) => selectedData.crops.indexOf(f.sk) !== -1);
        }
        let varieties = [];
        for (let v = 0; v < filteredCrops.length; v += 1) {
          varieties = varieties.concat(newMetaData.varieties.filter((f) => f.sk.startsWith(filteredCrops[v].sk)));
        }
        newMetaData.varieties = [...varieties];
        newMetaData.cultivars = [];
        newMetaData.cultivarTypes = [];
        newMetaData.rootStocks = [];
        newMetaData.rootStockTypes = [];
        this.injectVarieties(newMetaData, metaData);
        if (selectedData.cultivarTypes.length) {
          newMetaData.varieties = newMetaData.varieties.filter((f) => newSelectedData.cultivarTypes.indexOf(f.cultivarType) !== -1);
          newMetaData.cultivars = [];
          newMetaData.rootStocks = [];
          newMetaData.rootStockTypes = [];
          for (let v = 0; v < newMetaData.varieties.length; v += 1) {
            this.distinctPush(newMetaData.cultivars, { name: newMetaData.varieties[v].cultivar, sk: newMetaData.varieties[v].cultivar });
            // this.distinctPush(newMetaData.cultivarTypes, { name: metaData.varieties[v].cultivarType, sk: metaData.varieties[v].cultivarType });
            this.distinctPush(newMetaData.rootStocks, { name: newMetaData.varieties[v].rootStock, sk: newMetaData.varieties[v].rootStock });
            this.distinctPush(newMetaData.rootStockTypes, { name: newMetaData.varieties[v].rootStockType, sk: newMetaData.varieties[v].rootStockType });
          }
        }
        if (selectedData.cultivars.length) {
          this.injectVarieties(newMetaData, metaData);
          newMetaData.varieties = newMetaData.varieties.filter((f) => newSelectedData.cultivars.indexOf(f.cultivar) !== -1);
          newMetaData.rootStocks = [];
          newMetaData.rootStockTypes = [];
          for (let v = 0; v < newMetaData.varieties.length; v += 1) {
            this.distinctPush(newMetaData.rootStocks, { name: newMetaData.varieties[v].rootStock, sk: newMetaData.varieties[v].rootStock });
            this.distinctPush(newMetaData.rootStockTypes, { name: newMetaData.varieties[v].rootStockType, sk: newMetaData.varieties[v].rootStockType });
          }
        }
        if (selectedData.rootStockTypes.length) {
          this.injectVarieties(newMetaData, metaData);
          newMetaData.varieties = newMetaData.varieties.filter((f) => newSelectedData.rootStockTypes.indexOf(f.rootStockType) !== -1);
          newMetaData.rootStocks = [];
          // newMetaData.rootStockTypes = [];
          for (let v = 0; v < newMetaData.varieties.length; v += 1) {
            this.distinctPush(newMetaData.rootStocks, { name: newMetaData.varieties[v].rootStock, sk: newMetaData.varieties[v].rootStock });
            // this.distinctPush(newMetaData.rootStockTypes, { name: metaData.varieties[v].rootStockType, sk: metaData.varieties[v].rootStockType });
          }
        }
        break;
      }
      default: {
        const g = 'w';
      }
    }
    const filteredItems = this.filterItems(census.Items);
    const blockPaths = this.getPaths(filteredItems.filteredItems);
    const flatItems = flattenItems(filteredItems.filteredItems);
    // find the remaining irrigation types and add it to newMetadata.
   /* if (arrayName !== 'irrigationTypes') {
      newMetaData.irrigationTypes = [...new Set(flatItems.map((item) => item.irrigationSystem))];
      newMetaData.irrigationTypes = newMetaData.irrigationTypes.map((it) => {
        const ob = { name: it, sk: it };
        return ob;
      });
    }*/

    const graphData = this.generateGraphData(selectedGraphTab, flatItems);
    // const graphData = this.generateGraphData(flatItems, newSelectedData);
    // const areaByProvince = groupBySum(flatItems, 'province', 'size', 'Province', false);
    // const areaByProvinceM = groupByMultiple(flatItems, ['country', 'province', 'cropName']);
    // const groupByMultipleSumM = groupByMultipleSum(flatItems, ['country', 'province', 'cropName'], 'size', 'Crop', true);
    // const areaByCrop = groupBySum(flatItems, 'cropName', 'size', 'Crop', true);
    this.props.setGlobalState({ filteredMetaData: newMetaData, selectedData: newSelectedData, filteredStats: filteredItems.stats });
    // this.props.setGlobalState({ selectedData: newSelectedData });
    // this.props.setGlobalState({ filteredStats: filteredItems.stats });
    this.setState({
      blockPaths, flatItems, graphData,
    });
  }

  getSelectedGroup = () => {
    const { getFromGlobalState } = this.props;
    const selectedData = getFromGlobalState('selectedData');
    // if (selectedData.rootStocks.length) {
    //    return { groupBy: ['rootStockType', 'rootStock'], title: 'Density Rootstock/Ha' };
    //  }
    if (selectedData.rootStockTypes.length) {
      return { groupBy: ['rootStockType', 'rootStock'], title: 'Tree Density by Rootstock/Ha' };
    }
    if (selectedData.cultivars.length) {
      return { groupBy: ['cultivar', 'rootStockType'], title: 'Tree Density by Rootstock Type/Ha' };
    }
    if (selectedData.cultivarTypes.length) {
      return { groupBy: ['cultivarType', 'cultivar'], title: 'Tree Density by Cultivar/Ha' };
    }
    if (selectedData.crops.length) {
      return { groupBy: ['cropName', 'cultivarType'], title: 'Tree Density by Cultivar Type/Ha' };
    }
    if (selectedData.cropTypes.length) {
      return { groupBy: ['cropTypeName', 'cropName'], title: 'Tree Density/Ha' };
    }
    if (selectedData.provinces.length) {
      return { groupBy: ['province', 'cropTypeName'], title: 'Tree Density by Crop Type/Ha' };
    }
    if (selectedData.countries.length) {
      return { groupBy: ['country', 'province'], title: 'Tree Density/Ha' };
    }
    return { groupBy: ['cropTypeName', 'cropName'], title: 'Tree Density by Crop Type/Ha' };
  }

  generateGraphData = (selectedGraphTab, flatItems) => {
    let graphData = {};
    const { getFromGlobalState } = this.props;
    const allStats = getFromGlobalState('allStats');
    const selectedData = getFromGlobalState('selectedData');

    /// //arrayToCSV(flatItems);
    switch (selectedGraphTab) {
      case 0: {
        // country total size
        graphData.country = groupBySum(flatItems, 'country', 'size', 'Area in Ha', 'Country', false);
        graphData.country.total = graphData.country.data.reduce((accumulator, row) => accumulator + parseFloat(row['Area in Ha']), 0);

        // province data
        graphData.province = groupBySum(flatItems, 'province', 'size', 'Area', 'Province', true);
        // crop Type
        graphData.cropType = groupBySum(flatItems, 'cropTypeName', 'size', 'Area in Ha', 'Crop type', true);
        // crop
        // graphData.crop = groupByMultipleSum(flatItems, ['cropName'], 'size', 'Crop', true);
        graphData.crop = groupBySum(flatItems, 'cropName', 'size', 'Area in Ha', 'Crop', true);

        // cultivartype
        // graphData.cultivarType = groupByMultipleSum(flatItems, ['cropName', 'cultivarType'], 'size', 'Cultivar Type', true);

        // cultivartype version 2
        graphData.cultivarType = groupBySum(flatItems, 'cultivarType', 'size', 'Area in Ha', 'Crop', true);

        graphData.cultivarType2 = groupByMultipleSum(flatItems, ['cultivarType', 'rootStockType'], 'size', 'Cultivar', true);

        // firt find the crultivarType total
        graphData.cultivarType.outerData = [];
        graphData.cultivarType2.data.forEach((val) => {
          Object.keys(val).forEach((key) => {
            if (key !== 'name') {
              graphData.cultivarType.outerData.push({ name: key, value: val[key] });
            }
          });
        });

        { /* } graphData.cultivarType.outerData = graphData.cultivarType2.data.map((k) => {
          const arr = Object.keys(k).map((key) => {
            const ob = {};
            if (key !== 'name') {
              ob.name = key;
              ob.value = k[key];
            }
            return ob;
          });
          return arr;
        }); */ }

        // then find the rootStocks for the cultivarTypes
        // graphData.cultivarType.innerData = graphData.cultivarType;
        // graphData.cultivarType.outerData =
        // cultivar
        // if (selectedData.cultivarTypes.length) {
        // graphData.cultivar = groupByMultipleSum(flatItems, ['cultivarType', 'cultivar'], 'size', 'Cultivar', true);
        //  }
        graphData.cultivar = groupByMultipleSum(flatItems, ['cultivar', 'rootStock'], 'size', 'Cultivar', true);
        // graphData.cultivar = groupByMultipleSum(flatItems, ['cultivarType', 'cultivar'], 'size', 'Cultivar', true);
        /// double pie graphData.cultivar = groupBySum(flatItems, 'cultivar', 'size', 'Area in Ha', 'Cultivar', true);

        // planting density
        const grouping = this.getSelectedGroup();
        graphData.plantingDensity = groupByMultipleSum(flatItems, grouping.groupBy, 'totalPlants', 'Density by Cultivar type', true, 'size', 'divide');
        graphData.plantingDensity.title = grouping.title;

        // plant age
        // graphData.plantAge = groupByCount(flatItems, 'ageBucket', 'ageBucket', 'Age in years', 'Age', true);
        graphData.plantAge = groupBySum(flatItems, 'ageBucket', 'size', 'Area in Ha', 'Age', true);
        graphData.plantAge.title = 'Tree age';
        graphData.plantAge.data = graphData.plantAge.data.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: true }));
        graphData.plantAge.data = graphData.plantAge.data.map((d) => {
          const b = { ...d };
          b.index = 1;
          return b;
        });

        // irrigation
        graphData.irrigationType = groupBySum(flatItems, 'irrigationSystem', 'size', 'Area in Ha', 'Age', true);
        graphData.irrigationType.title = 'Irrigation';

        // rootStockType
        graphData.rootStockType = groupBySum(flatItems, 'rootStockType', 'size', 'Area in Ha', 'Age', true);
        graphData.rootStockType.title = 'Rootstock Type';

        // rootStock
        graphData.rootStock = groupBySum(flatItems, 'rootStock', 'size', 'Area in Ha', 'Age', true);
        graphData.rootStock.title = 'Rootstock';
        break;
      }
      case 1: {
        graphData.province = groupBySum(flatItems, 'province', 'size', 'Area in Ha', 'Province', true);
        break;
      }
      case 2: {
        graphData = groupBySum(flatItems, 'cropTypeName', 'size', 'Area in Ha', 'Crop type', true);
        break;
      }
      case 3: {
        graphData = groupBySum(flatItems, 'cropName', 'size', 'Area in Ha', 'Crop', true);
        break;
      }
      case 4: {
        // graphData = groupBySum(flatItems, 'cultivarType', 'size', 'Area in Ha', 'Cultivar Type', true);
        graphData = groupByMultipleSum(flatItems, ['cropName', 'cultivarType'], 'size', 'Cultivar Type', true);
        break;
      }
      case 5: {
        graphData = groupByMultipleSum(flatItems, ['cultivarType', 'cultivar'], 'size', 'Cultivar', true);
        // graphData = groupBySum(flatItems, 'cultivar', 'size', 'Area in Ha', 'Cultivar', true);
        break;
      }
      case 6: {
        graphData = groupByMultipleSum(flatItems, ['cultivar', 'rootStockType'], 'size', 'Rootstock Type', true);
        // graphData = groupBySum(flatItems, 'rootStockType', 'size', 'Area in Ha', 'Rootstock Type', true);
        break;
      }
      case 7: {
        graphData = groupByMultipleSum(flatItems, ['rootStockType', 'Rootstock'], 'size', 'Rootstock', true);
        // graphData = groupBySum(flatItems, 'rootStock', 'size', 'Area in Ha', 'Rootstock', true);
        break;
      }
      default: {

      }
    }
    return graphData;
  }

  filterItems = (itemsIn, newSelectedData) => {
    // const { stats } = this.state;
    const { getFromGlobalState } = this.props;
    let selectedData = newSelectedData;
    if (!selectedData) {
      selectedData = getFromGlobalState('selectedData');
    }
    if (!itemsIn) {
      return null;
    }
    // debug

    const newStats = {
      farms: 0,
      size: 0,
      sizeByCrop: {},
      plantings: 0,
      trees: 0,
      set: false,
    };
    // const newStats = { ...stats };
    let items = [...itemsIn];

    const filteredItems = [];
    const paths = [];
    // const itemLength = items.length;
    if (selectedData) {
      if (selectedData.countries.length) {
        items = items.filter((f) => selectedData.countries.indexOf(f.farm.address.country) !== -1);
      }
      if (selectedData.regions.length) {
        items = items.selectedData((f) => selectedData.regions.indexOf(f.farm.address.region) !== -1);
      }
      if (selectedData.provinces.length) {
        items = items.filter((f) => selectedData.provinces.indexOf(f.farm.address.province) !== -1);
      }
    }

    for (let i = 0; i < items.length; i += 1) {
      if (!items[i].blocks || !items[i].farm) {
        continue;
      }
      const tempBlocks = [];
      for (let b = 0; b < items[i].blocks.length; b += 1) {
        if (!items[i].blocks[b].paths) {
          continue;
        }
        // get to the planting info
        let plantings = [];
        if (items[i].blocks[b].plantings) {
          plantings = items[i].blocks[b].plantings;
          let plantingSize;
          if (selectedData) {
            if (selectedData.cropTypes.length) {
              // items[i].blocks[b].plantings = items[i].blocks[b].plantings.filter((f) => selectedData.cropType.indexOf(f.crop.cropType) !== -1);
              plantings = plantings.filter((f) => selectedData.cropTypes.indexOf(f.crop.cropType) !== -1);
            }
            if (selectedData.crops.length) {
              plantings = plantings.filter((f) => selectedData.crops.indexOf(f.crop.id) !== -1);
            }
            if (selectedData.cultivarTypes.length) {
              plantings = plantings.filter((f) => selectedData.cultivarTypes.indexOf(f.variety.cultivarType) !== -1);
            }
            if (selectedData.cultivars.length) {
              plantings = plantings.filter((f) => selectedData.cultivars.indexOf(f.variety.cultivar) !== -1);
            }
            if (selectedData.rootStockTypes.length) {
              plantings = plantings.filter((f) => selectedData.rootStockTypes.indexOf(f.variety.rootStockType) !== -1);
            }
            if (selectedData.rootStocks.length) {
              plantings = plantings.filter((f) => selectedData.rootStocks.indexOf(f.variety.rootStock) !== -1);
            }
            if (selectedData.irrigationTypes.length) {
              plantings = plantings.filter((f) => selectedData.irrigationTypes.indexOf(f.irrigationSystem) !== -1);
            }
          }
        }
        if (plantings.length) {
          const tempBlock = { ...items[i].blocks[b] };
          tempBlock.plantings = plantings;
          let size = plantings.reduce((prev, cur) => prev + parseFloat(cur.size), 0);
          if (isNaN(size)) {
            size = parseFloat(items[i].blocks[b].sizeMap);
          }
          newStats.size += size;
          for (let pp = 0; pp < plantings.length; pp += 1) {
            if (!newStats.sizeByCrop[plantings[pp].crop.id]) {
              newStats.sizeByCrop[plantings[pp].crop.id] = 0;
            }
            if (isNaN(plantings[pp].size)) {
              newStats.sizeByCrop[plantings[pp].crop.id] += parseFloat(items[i].blocks[b].sizeMap);
            } else {
              newStats.sizeByCrop[plantings[pp].crop.id] += parseFloat(plantings[pp].size);
            }
          }

          let trees = plantings.reduce((prev, cur) => prev + parseFloat(cur.totalPlants ? cur.totalPlants : numberOfPlants(cur.plantSpacing, cur.plantDensity, cur.size)), 0);
          if (isNaN(trees)) {
            trees = 0;
          }
          newStats.trees += trees;// plantings.reduce((prev, cur) => prev + parseFloat(cur.totalPlants), 0);
          newStats.plantings += plantings.length;
          tempBlocks.push(tempBlock);
        } else {
          console.log('plantings 0');
        }
      }
      if (tempBlocks.length) {
        const newItem = { farm: {}, blocks: [] };
        newItem.farm = { ...items[i].farm };
        newItem.blocks = [...tempBlocks];
        filteredItems.push(newItem);
      } else {
        console.log('no blocks');
      }
      // add the farm here
    }
    newStats.farms = filteredItems.length;
    return { filteredItems, stats: newStats };
  }

  handleTabChange = (event, newValue) => {
    const { selectedTab } = this.state;
    if (newValue !== selectedTab) {
      this.setState({ selectedTab: newValue });
    }
  }

  handleGraphTabChange = (event, newValue) => {
    const { selectedGraphTab, flatItems } = this.state;
    if (newValue !== selectedGraphTab) {
      const graphData = this.generateGraphData(newValue, flatItems);
      this.setState({ selectedGraphTab: newValue, graphData });
    }
  }

  calcTotalSelectedCrop = (selectedData, allStats) => {
    let sizeBySelectedCrops = allStats.size;
    if (selectedData.crops && selectedData.crops.length) {
      sizeBySelectedCrops = 0;
      for (let k = 0; k < selectedData.crops.length; k += 1) {
        if (allStats.sizeByCrop[selectedData.crops[k]]) {
          sizeBySelectedCrops += allStats.sizeByCrop[selectedData.crops[k]];
        }
      }
    }
    return sizeBySelectedCrops;
  }

  render() {
    const {
      isLoading, blockPaths, expandedSetting, graphData, selectedTab, selectedGraphTab,
    } = this.state;
    const { classes, getFromGlobalState } = this.props;
    const filteredMetaData = getFromGlobalState('filteredMetaData');
    const selectedData = getFromGlobalState('selectedData');
    const allStats = getFromGlobalState('allStats');
    const filteredStats = getFromGlobalState('filteredStats');
    const selectedCropTotalSize = this.calcTotalSelectedCrop(selectedData, allStats);

    return (
      <Load isloading={isLoading}>

        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <FilterBox
              metaData={filteredMetaData}
              expandedSetting={expandedSetting}
              handleExpand={this.handleExpand}
              selectedData={selectedData}
              handleToggle={this.handleFilterToggle}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
            <Tabs value={selectedTab} handleChange={this.handleTabChange}>
              <div key="map" heading="Map">
                {selectedTab === 3 && (
                <Paper className={classes.paper} elevation={2}>
                  <Map
                    polygons={blockPaths}
                    style={{ height: '520px', width: '100%' }}
                  />
                </Paper>
                )}
                <div style={{ width: '100%', display: 'inline-block' }}>
                  <StatBox
                    stat={allStats.farms.toLocaleString()}
                    header="All Farms"
                  />
                  <StatBox
                    stat={filteredStats.farms.toLocaleString()}
                    header={(
                      <Badge variant="dot" color="secondary">
                        Farms
                      </Badge>
                )}
                  />
                  <StatBox
                    stat={Math.floor(allStats.size).toLocaleString()}
                    header="All Ha"
                  />
                  <StatBox
                    stat={Math.floor(filteredStats.size).toLocaleString()}
                    header={(
                      <Badge variant="dot" color="secondary">
                        Ha
                      </Badge>
                )}
                  />
                  <StatBox
                    stat={Math.floor(allStats.trees).toLocaleString()}
                    header="All Trees"
                  />
                  <StatBox
                    stat={Math.floor(filteredStats.trees).toLocaleString()}
                    header={(
                      <Badge variant="dot" color="secondary">
                        Trees
                      </Badge>
                )}
                  />
                  <StatBox
                    stat={Math.floor(allStats.plantings).toLocaleString()}
                    header="All Plantings"
                  />
                  <StatBox
                    stat={Math.floor(filteredStats.plantings).toLocaleString()}
                    header={(
                      <Badge variant="dot" color="secondary">
                        Plantings
                      </Badge>
                )}
                  />
                </div>
              </div>
              <div key="area" heading="Insights" name="country">
                {selectedTab === 1 && (
                <Grid container className={classes.root} spacing={1} alignItems="flex-start">
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <KPIBox
                      stat={filteredStats.farms ? filteredStats.farms.toLocaleString() : ''}
                      header="Farms"
                      message={allStats.farms && filteredStats.farms ? `${Math.round((filteredStats.farms / (allStats.farms)) * 100)} % of total` : ''}
                        // icon={<AreaIcon />}
                      icon={<AgronomistIcon edge="start" color="white" style={{ fontSize: 'x-large' }} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <KPIBox
                      stat={graphData.country && graphData.country.total ? Math.round(graphData.country.total).toLocaleString() : ''}
                      header="Hectares"
                      message={graphData.country && graphData.country.total ? `${Math.round((graphData.country.total / selectedCropTotalSize) * 100)} % of total` : ''}
                      // message={graphData.country ? graphData.country.perCentage : {}}
                      icon={<EarthIcon edge="start" color="white" style={{ fontSize: 'x-large' }} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <KPIBox
                      stat={filteredStats.trees ? Math.floor(filteredStats.trees).toLocaleString() : ''}
                      header="Trees"
                      message={filteredStats.trees && allStats.trees ? `${Math.round((filteredStats.trees / allStats.trees) * 100)} % of total` : ''}
                        // message={graphData.country ? graphData.country.perCentage : {}}
                      icon={<TreesIcon edge="start" color="white" style={{ fontSize: 'x-large' }} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <ChartPie2
                  // title="Result"
                      dataKey="Area"
                      key="Area"
                      addMargin
                      name="province"
                        // title="Area by province"
                      showLedgend
                      keys={graphData.province ? graphData.province.keys : []}
                      data={graphData.province ? graphData.province.data : []}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    {/* <ChartBar
                        dataKey="name"
                        key="cropTypes"
                        title="Crop Type"
                        height={250}
                        name="cropTypes"
                        keys={graphData.cropType ? graphData.cropType.keys : []}
                        data={graphData.cropType ? graphData.cropType.data : []}
                      /> */}
                    <ChartPie2
                  // title="Result"
                      dataKey="Area in Ha"
                      key="Area in Ha"
                      name="crops"
                      title="Crops (Ha)"
                      height={250}
                      legendAlign="center"
                      legendLayout="horizontal"
                      legendVerticalAlign="bottom"
                      cx={140}
                      cy={105}
                      innerRadius={60}
                      outerRadius={80}
                      showLabel
                        // title="Area by province"
                      showLedgend
                      keys={graphData.crop ? graphData.crop.keys : []}
                      data={graphData.crop ? graphData.crop.data : []}
                    />
                    {/* } <ChartBar
                      dataKey="name"
                      key="crops"
                      title="Crops (Ha)"
                      height={250}
                      name="crops"
                      keys={graphData.crop ? graphData.crop.keys : []}
                      data={graphData.crop ? graphData.crop.data : []}
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <ChartPieTwoLevel
                      name="cultivar"
                      title="Cultivar Type and RootStock Type"
                      data={graphData.cultivarType ? graphData.cultivarType.data : []}
                      dataKey="Area in Ha"
                      innerRadius={0}
                      height={250}
                      outerRadius={60}
                      innerRadiusOuter={70}
                      outerRadiusOuter={90}
                      key="cultivar"
                      dataOuter={graphData.cultivarType ? graphData.cultivarType.outerData : []}
                      dataKeyOuter="value"
                    />
                    {/*  <ChartBar
                      dataKey="name"
                      key="cultivarType"
                      title="Cultivar Type (Ha)"
                      height={250}
                      name="cultivarType"
                      keys={graphData.cultivarType ? graphData.cultivarType.keys : []}
                      data={graphData.cultivarType ? graphData.cultivarType.data : []}
                  /> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ChartBarStacked
                      dataKey="name"
                      key="cultivar"
                      title="Rootstock by Cultivar (HA)"
                      height={250}
                      name="cultivar"
                      keys={graphData.cultivar ? graphData.cultivar.keys : []}
                      data={graphData.cultivar ? graphData.cultivar.data : []}
                    />
                    {/* } <ChartPieTwoLevel
                      name="cultivar"
                      title="Cultivar and Roostock"
                      data={graphData.cultivar ? graphData.cultivar.data : []}
                      dataKey="Area in Ha"
                      innerRadius={0}
                      height={250}
                      outerRadius={60}
                      innerRadiusOuter={70}
                      outerRadiusOuter={90}
                      key="cultivar"
                      dataOuter={graphData.rootStock ? graphData.rootStock.data : []}
                      dataKeyOuter="Area in Ha"
                      //key="rootStock"
                  /> */}
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <ChartBar
                      dataKey="name"
                      key="cultivar"
                      title="Cultivar (Ha)"
                      height={250}
                      name="cultivar"
                      keys={graphData.cultivar ? graphData.cultivar.keys : []}
                      data={graphData.cultivar ? graphData.cultivar.data : []}
                />
                </Grid> */}
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <ChartBar
                      dataKey="name"
                      key="rootStockType"
                      title={graphData.rootStockType ? graphData.rootStockType.title : ''}
                      height={250}
                      name="rootStockType"
                      keys={graphData.rootStockType ? graphData.rootStockType.keys : []}
                      data={graphData.rootStockType ? graphData.rootStockType.data : []}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <ChartBar
                      dataKey="name"
                      key="rootStock"
                      title={graphData.rootStock ? graphData.rootStock.title : ''}
                      height={250}
                      name="rootStock"
                      keys={graphData.rootStock ? graphData.rootStock.keys : []}
                      data={graphData.rootStock ? graphData.rootStock.data : []}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <ChartBar
                      dataKey="name"
                      key="plantingDensity"
                      title={graphData.plantingDensity ? graphData.plantingDensity.title : ''}
                      height={250}
                      name="plantingDensity2"
                      keys={graphData.plantingDensity ? graphData.plantingDensity.keys : []}
                      data={graphData.plantingDensity ? graphData.plantingDensity.data : []}
                    />

                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    { /*  <ChartBar
                      dataKey="name"
                      key="plantAge"
                      title={graphData.plantAge ? graphData.plantAge.title : ''}
                      height={250}
                      name="plantAge"
                      keys={graphData.plantAge ? graphData.plantAge.keys : []}
                      data={graphData.plantAge ? graphData.plantAge.data : []}
                 /> */}
                    <BubbleChart
                      dataKeyX="name"
                      dataKeyY="index"
                      dataKeyZ="Area in Ha"
                      key="plantAge"
                      title={graphData.plantAge ? graphData.plantAge.title : ''}
                      height={125}
                      yLabel="Ha"
                      name="plantAge"
                      keys={graphData.plantAge ? graphData.plantAge.keys : []}
                      data={graphData.plantAge ? graphData.plantAge.data : []}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ChartBar
                      dataKey="name"
                      key="irrigationType"
                      title={graphData.irrigationType ? graphData.irrigationType.title : ''}
                      height={250}
                      name="irrigationType"
                      keys={graphData.irrigationType ? graphData.irrigationType.keys : []}
                      data={graphData.irrigationType ? graphData.irrigationType.data : []}
                    />

                    {/* <ChartPie2
                  // title="Result"
                      dataKey="name"
                      key="irrigationType"
                      height={250}
                      innerRadius={30}
                      outerRadius={300}
                      cx= {100}
                      cy={100}
                      name="irrigationType"
                      title={graphData.irrigationType ? graphData.irrigationType.title : ''}
                      //showLedgend
                      keys={graphData.irrigationType ? graphData.irrigationType.keys : []}
                      data={graphData.irrigationType ? graphData.irrigationType.data : []}
                   /> */}

                  </Grid>

                </Grid>
                )}
              </div>
            </Tabs>
          </Grid>

        </Grid>

      </Load>

    );
  }
}
CensusAnon.propTypes = {
  setSnack: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
};
export default withStyles(styles)(CensusAnon);
