import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';
import SearchBox from '../../components/SearchBox';
import { getAdminSettings, getErrorMessage } from '../../libs/storage';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },
  group: {
    //  display: 'flex';
    // flex-wrap: 'wrap';
    flexDirection: 'row',
  },

});

class ChemicalD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chemical: null,
      errors: { count: 0 },
      changed: false,
      searchString: '',
      searchedPPPS: [],
      isSearching: false,
      selectedGenPPP: null,

    };
  }

  componentDidMount() {
    if (this.props.isNew) {
      this.setState({ chemical: { chemcrops: [] } });
    } else {
      const { chemical } = this.props;
      if (!chemical.chemcrops) {
        chemical.chemcrops = [];
      }
      this.setState({ chemical });
    }
  }

  validateField(field) {
    const { requiredFields } = this.props;
    const error = validate(field, this.state.chemical, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleNameBlur = (event) => {
    const { chemical, errors } = this.state;
    // if (!isNew) {
    //   this.validateField(event.target.id, requiredFields);
    //  } else {
    const { ppps, requiredFields } = this.props;
    const error = validate(event.target.id, chemical, requiredFields);
    if (error.isValid) {
      if (ppps) {
        const idx = ppps.filter((c) => c.sk !== chemical.sk).findIndex((f) => f.name === event.target.value);
        if (idx !== -1) {
          error.isValid = false;
          error.msg = 'Name already exists';
        } else {
          error.isValid = true;
          error.msg = '';
        }
      }
    }
    const newErrors = { ...errors };
    newErrors[event.target.id] = error.msg;
    this.setState({ errors: newErrors });
  //  }
  }

  handleInputChange = (event) => {
    const { getFromGlobalState, setSnack, ppps } = this.props;
    const activeSite = getFromGlobalState('activeSite');
    if (event.target.value.length > 0) {
      this.setState({ searchString: event.target.value, isSearching: true }, () => {
        getAdminSettings(activeSite, 'startswith', 'genPPPs', encodeURIComponent(this.state.searchString))
          .then((searchedPPPs) => {
            // filter the searchedPPPS to remove disabled ones and ones already in fertilisers using es6 syntax
            const filtered = searchedPPPs.filter((ppp) => !ppp.isDisabled && !ppps.find((f) => f.loadedFromSettingSK && f.loadedFromSettingSK === ppp.sk));
            // const filtered = searchedFertilisers.filter((fert) => !fert.isDisabled);
            this.setState({ searchedPPPS: filtered, isSearching: false });
          })
          .catch((e) => {
            setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
          });
      });
    } else {
      this.setState({ searchedPPPS: [{ sk: '', name: 'Type the first letter to search' }] });
    }
  }

  handleGenPPPChange = (value) => {
    const { searchedPPPS } = this.state;
    // const { skPrimary } = this.props;
    //console.log('event value', value);
    if (value && value.value) {
      const selectedGenPPP = searchedPPPS.find((ppp) => ppp.sk === value.value);
      const ppp = { ...selectedGenPPP };
      delete ppp.sk;
      delete ppp.isGeneral;
      ppp.loadedFromSettingSK = selectedGenPPP.sk;
      // fertiliser.sk = skPrimary.concat(uuid.v1());
      this.setState({ chemical: ppp, selectedGenPPP });
    }
  }

  handleBlur = (event) => {
    const { requiredFields } = this.props;
    this.validateField(event.target.id, requiredFields);
  }

  handleChange = (event) => {
    const chemical = { ...this.state.chemical };
    chemical[event.target.id] = event.target.value;
    this.setState({ chemical, changed: true });
  }

  handleRadioChange = (name, event) => {
    const chemical = { ...this.state.chemical };
    chemical[name] = event.target.value;
    this.setState({ chemical, changed: true });
  }

  handleCheckChange = (event) => {
    const { chemical } = this.state;
    const newChemical = { ...chemical };
    newChemical[event.target.id] = event.target.checked;
    this.setState({ chemical: newChemical, changed: true });
  }

  handleToggle = (value) => {
    const currentIndex = this.state.chemical.allowedVarieties.indexOf(value);
    const newChemical = { ...this.state.chemical };

    if (currentIndex === -1) {
      newChemical.allowedVarieties.push(value);
    } else {
      newChemical.allowedVarieties.splice(currentIndex, 1);
    }

    this.setState({ chemical: newChemical, changed: true });
  }

  getGenPPPsOptions = (searchedPPPs) => {
    const pppsRows = searchedPPPs.map((ppp) => {
      const ob = {};
      ob.value = ppp.sk;
      ob.key = ppp.sk;
      ob.label = ppp.name;
      return ob;
    });
    return pppsRows;
  }

  getOptions = (options) => {
    const { searchString, isSearching } = this.state;
    if (searchString.length === 0) {
      return [{ value: '', label: 'Type to search', key: 'type' }];
    }
    if (isSearching) {
      return [{ value: '', label: 'Searching...', key: 'searching' }];
    }
    if (!isSearching && options.length === 0) {
      return [{ value: '', label: 'No results', key: 'searching' }];
    }
    return options;
  }

  render() {
    const {
      classes, open, isNew, setClose, handleUpdate, getFromGlobalState, autoSearch, dynamoType, skPrimary, requiredFields,
    } = this.props;
    const {
      chemical, errors, searchedPPPS, selectedGenPPP, isSearching,
    } = this.state;
    const genPPPs = searchedPPPS && searchedPPPS.length ? this.getGenPPPsOptions(searchedPPPS) : [];
    const readOnly = selectedGenPPP || (chemical && chemical.loadedFromSettingSK);
    const formValid = validateForm(chemical, requiredFields)  && (errors.name ? errors.name === '' : true);
    if (!chemical) {
      return null;
    }

    return (

      <GenDialog
        open={open}
        dialogObject={chemical}
        setClose={setClose}
        isNew={isNew}
        changed={this.state.changed}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary={skPrimary}
        dynamoType={dynamoType}
        valid={formValid}
        newTitle="New Plant Protection"
        title={chemical && chemical.name ? chemical.name : ''}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          {!isNew && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={chemical.isDisabled}
                 // disabled={isInvite}
                  id="isDisabled"
                  onChange={this.handleCheckChange}
                  value="isDisabled"
                  color="secondary"
                />
        )}
              label="Disabled"
            />
          </Grid>
          )}
          {autoSearch && isNew && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SearchBox
                options={this.getOptions(genPPPs)}
                onInputChange={this.handleInputChange}
                onChange={this.handleGenPPPChange}
                label={isSearching ? 'Searching...' : 'Search for Plant Protection Product'}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Trade Name"
              name="name"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}
              //autoFocus={!chemical.loadedFromSettingSK}
              value={chemical && chemical.name}
              onChange={this.handleChange}
              onBlur={this.handleNameBlur}
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
              fullWidth
              id="registrationNumber"
              label="Registration Number"
              name="registrationNumber"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}
              //autoFocus
              value={chemical && chemical.registrationNumber}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.registrationNumber ? true : undefined}
              helperText={errors.registrationNumber}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              fullWidth
              id="registrationHolder"
              label="Registration Holder"
              name="registrationHolder"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}
              //autoFocus
              value={chemical && chemical.registrationHolder}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.registrationHolder ? true : undefined}
              helperText={errors.registrationHolder}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              multiline
              fullWidth
              id="aIngredient"
              label="Active Ingredient"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}
              name="aIngredient"
              value={chemical.aIngredient}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.aIngredient ? true : undefined}
              helperText={errors.aIngredient}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
              fullWidth
              id="supplier"
              label="Supplier"
              name="supplier"
              value={chemical.supplier}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.supplier ? true : undefined}
              helperText={errors.supplier}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
              fullWidth
              id="advisor"
              label="Advisor Name and Surname"
              name="advisor"

              value={chemical.advisor}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.advisor ? true : undefined}
              helperText={errors.advisor}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

            <FormControl component="fieldset" className={classes.formControlRadio}>
              <FormLabel required>Dilution unit</FormLabel>
              <RadioGroup

                aria-label="unit"
                name="unit"
                id="unit"
                className={classes.group}
                value={chemical.unit}
                onChange={this.handleRadioChange.bind(this, 'unit')}
              >
                <FormControlLabel value="kg" control={<Radio />} label="kg (granular)" />
                <FormControlLabel value="liter" control={<Radio />} label="liter (liquid)" />
                {errors.unit && <FormHelperText className={classes.helperText}>{errors.unit}</FormHelperText>}
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="number"
            inputProps={{ 'min': '0.00001', 'step':'any' }}
            id="minStock"
            label={`Min stock level in ${(chemical.unit && (chemical.unit))}`}
            name="minStock"

            value={chemical.minStock}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.minStock ? true : undefined}
            helperText={errors.minStock}
          />
          </Grid> */}

        </Grid>

      </GenDialog>

    );
  }
}

ChemicalD.defaultProps = {
  dynamoType: 'chemicals',
  skPrimary: 'init_setting_',
  autoSearch: false,
  searchedPPPS: [],
  requiredFields: [
    { name: 'name', min: 1, max: 40 },
    { name: 'aIngredient', min: 1, max: 1000 },
    { name: 'registrationNumber', min: 1, max: 40 },
    { name: 'registrationHolder', min: 1, max: 40 },
    /* {name:'aIngredientConc', min: 1, max: 40}, */
    /* {name:'unitaInConcPer', min: 1, max: 40}, */
    /* { name: 'unit', min: 1, max: 40 }, */
    /* {name:'minStock',min:1,max:40}, */
  ],
};
ChemicalD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  chemical: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  ppps: PropTypes.array.isRequired,
  autoSearch: PropTypes.bool,
  searchedPPPS: PropTypes.array,
  skPrimary: PropTypes.string,
  requiredFields: PropTypes.array,

};
export default withStyles(styles)(ChemicalD);
