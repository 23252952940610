import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CombinationD from '../CombinationD';
import CombinationProductD from '../CombinationProductD';
import ChemCropD from '../ChemCropD';
import ChemCropTargetD from '../ChemCropTargetD';
import EnhancedTable from '../../components/Etable';
import { getNameFromKey2 } from '../../libs/genLib';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  aligner: {
    // display:'inline-flex',
    // marginRight:'auto',
    // marginBottom:'15px',
  },
  paperDropperBody: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  heading: {
    marginTop: '4px',
    cursor: 'pointer',
    // marginTop: '10px',
  },
  submit: {
    margin: theme.spacing(1),
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    // overflow: 'scroll',
  },
  mainSection: {
    width: '100%',
    flexWrap: 'wrap',
  },
  parentSection: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },

  },
  childSection: {
    marginLeft: theme.spacing(5),
  },
  childSectionChildren: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const combinationTypeHeadCells = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
];

const combinationHeadCells = [
  {
    id: 'isDisabledText', numeric: false, disablePadding: true, label: 'Disabled',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'description', numeric: false, disablePadding: true, label: 'Description',
  },
];

const combinationTargetHeadCells = [
  {
    id: 'typeText', numeric: false, disablePadding: true, label: 'Type',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'crop', numeric: false, disablePadding: true, label: 'Crop',
  },
  {
    id: 'target', numeric: false, disablePadding: true, label: 'Target',
  },
];


const detailChemicalHeadCells = [
  {
    id: 'isDisabled', numeric: false, disablePadding: true, label: 'Disabled',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
];

const cropHeadCells = [
  {
    id: 'isDisabled', numeric: false, disablePadding: true, label: 'Disabled',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
];

const targetHeadCells = [
  {
    id: 'isDisabled', numeric: false, disablePadding: true, label: 'Disabled',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
];

{ /* {
    id: 'name', combine: [{ id: 'targetType', opacity: 1 }, { id: 'name', opacity: 0.8 }, { id: 'mobileDescription', opacity: 0.6 }], numeric: false, disablePadding: true, label: 'Target', maxWidth: '100px',
  }, */ }

function generateReasonLis(pests) {
  if (!pests) {
    return [];
  }
  const rows = pests.map((monitoring) => {
    const ob = {};
    ob.sk = monitoring.sk;
    ob.name = monitoring.targetDetail.name;
    ob.targetSK = monitoring.targetDetail.sk;
    return ob;
  });
  return rows;
}

function generatePPPChemicalRows(chemicals) {
  if (!chemicals) {
    return [];
  }
  // const crops = getFromGlobalState('crops');
  // const pests = generateReasonLis(getFromGlobalState('monitorings'));

  const rows = [];
  for (let i = 0; i < chemicals.length; i += 1) {
    rows.push({ name: chemicals[i].name, sk: chemicals[i].sk, isDisabled: chemicals[i].isDisabled ? 'Yes' : 'No' });
  }
  return rows;
}

function generateCropRows(selectedChemical, getFromGlobalState) {
  if (!selectedChemical) {
    return [];
  }
  const rows = [];
  //const pests = generateReasonLis(getFromGlobalState('monitorings'));
  const crops = getFromGlobalState('crops');
  for (let c = 0; selectedChemical && selectedChemical.chemcrops && c < selectedChemical.chemcrops.length; c += 1) {
    rows.push({
      sk: selectedChemical.chemcrops[c].sk,
      name: getNameFromKey2(selectedChemical.chemcrops[c].crop, crops),
      isDisabled: selectedChemical.chemcrops[c].isDisabled ? 'Yes' : 'No',
    });
  }
  return rows;
}

function generateCropTargetRows(selectedChemicalCrop, getFromGlobalState) {
  if (!selectedChemicalCrop) {
    return [];
  }
  const rows = [];
  const pests = generateReasonLis(getFromGlobalState('monitorings'));

  for (let t = 0; selectedChemicalCrop.targets && t < selectedChemicalCrop.targets.length; t += 1) {
    rows.push({
      sk: selectedChemicalCrop.targets[t].sk,
      name: getNameFromKey2(selectedChemicalCrop.targets[t].target, pests),
      targetSK: selectedChemicalCrop.targets[t].target,
      isDisabled: selectedChemicalCrop.targets[t].isDisabled ? 'Yes' : 'No',
    });
  }

  return rows;
}

class CombinationDetailForm extends Component {
  constructor(props) {
    super(props);
    this.handleClickShowChemical = this.handleClickShowChemical.bind(this);
    this.handleClickAddChemical = this.handleClickAddChemical.bind(this);
    this.handleClickSelectChemical = this.handleClickSelectChemical.bind(this);

   // this.handleClickShowCombinationType = this.handleClickShowCombinationType.bind(this);
    this.handleClickAddCombinationType = this.handleClickAddCombinationType.bind(this);
    this.handleClickSelectCombinationType = this.handleClickSelectCombinationType.bind(this);


    this.state = {
      expandedSetting: '',
      // chemicals: this.props.initialState,
      selectedChemical: null,
      selectedChemicalCrop: null,
      modalObject: null,
      isNew: false,
      chemicalDetailOpen: false,
      chemicalCropDialogOpen: false,
      errors: {},
      openModal: '',
      chemicalCropTargetDialogOpen: false,
      selectedChemicalCropTarget: null,
      combinationTypeOpen: false,
      combinationProductDialogOpen: false,
      selectedCombinationType: null,
      selectedCombination: null,
      selectedCombinationTarget: null,
      isNewComboEntry: false,


    };
  }

  handleClickShow = () => {
    this.setState({ chemicalDetailOpen: true, isNew: false });
  }

  handleBlur = (event) => {
    // return this.validateField (event.target.id, this.props.requiredFields);
  }

  handleClickAddChemical() {
    this.setState({ chemicalDetailOpen: true, isNew: true, selectedChemical: null, selectedChemicalCrop: null, selectedChemicalCropTarget: null });
  }


handleClickAddNewChemicalCrop = (chemicalSK) => {
  this.setState({
    chemicalCropDialogOpen: true, isNew: true, selectedChemicalSK: chemicalSK, selectedChemicalCrop: null, selectedChemicalCropTarget: null,
  });
}

handleClickAddNewChemicalCropTarget = () => {
  this.setState({
    chemicalCropTargetDialogOpen: true, isNew: true, selectedChemicalCropTarget: {},
  });
}

handleSelectCombinationType = (event, parent, combinationType) => {
  this.setState({
    selectedCombinationType: combinationType, selectedCombination: null, selectedCombinationTarget: null,
  });
}

handleClickAddCombinationType() {
  this.setState({ combinationTypeOpen: true, isNew: true, selectedCombinationType: null, selectedCombination: null, selectedCombinationTarget: null });
}

handelClickAddNewCombination = () => {
  this.setState({
    combinationDialogOpen: true, isNew: true, selectedCombinationTarget: null,
  });
}

handleClickAddNewCombinationProduct = () => {
  this.setState({
    combinationProductDialogOpen: true, isNew: false,
  });
}

handleClickSelectCombinationType = (combinationSK) => {
  this.setState({
    selectedCombinationType: combinationSK, selectedCombination: null, selectedCombinationTarget: null,
  });
}

handleSelectCombination = (event, parent, combinationSK) => {
  const { getFromGlobalState } = this.props;
  const selectedCombination = getFromGlobalState('combinations').find((c) => c.sk === combinationSK);
  this.setState({
    selectedCombination, selectedCombinationTarget: null,
  });
}

handleSelectCombinationTarget = (event, parent, combinationTargetSK) => {
  const { selectedCombination } = this.state;
  const selectedCombinationTarget = selectedCombination.combinationProducts.find((c) => c.sk === combinationTargetSK);
  this.setState({
    selectedCombinationTarget: selectedCombinationTarget, combinationProductDialogOpen: true, isNewComboEntry: false,
  });
}


handleClickShowChemical(event, parent, selectedChemicalID) {
  // const { selectedChemical } = this.state;
  // const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalID);
  this.setState({
    chemicalDetailOpen: 'true', isNew: false,
  // selectedChemical, selectedChemicalSK: selectedChemicalID,
  });
}

handleClickSelectChemical(event, parent, selectedChemicalID) {
  const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalID);
  this.setState({
    selectedChemical, selectedChemicalCrop: null, selectedChemicalCropTarget: null, isNew: false,
  // selectedChemical, selectedChemicalSK: selectedChemicalID,
  });
}

handleClickShowCombination = (event, parent, selectedCombination) => {
  // const { selectedChemicalCrop } = this.state;
  // const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalId);
  this.setState({ selectedCombinationTarget: null, combinationDialogOpen: true, isNew: false });
}

handleSelectChemicalCrop = (event, parent, selectedChemicalCropId) => {
  const { selectedChemical } = this.state;
  // const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalId);
  const selectedChemicalCrop = selectedChemical.chemcrops.find((cc) => cc.sk === selectedChemicalCropId);
  this.setState({ selectedChemicalCrop });
}

handleClickShowChemicalCropTarget = (event, parent, selectedChemicalCropId) => {
  // const { selectedChemicalCrop } = this.state;
  // const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalId);
  this.setState({ chemicalCropTargetDialogOpen: true, isNew: false });
}

 handleSelectChemicalCropTarget = (event, parent, selectedChemicalCropTargetId) => {
   const { selectedChemicalCrop } = this.state;
   // const selectedChemical = this.props.getFromGlobalState('chemicals').find((c) => c.sk === selectedChemicalId);
   const selectedChemicalCropTarget = selectedChemicalCrop.targets.find((cc) => cc.sk === selectedChemicalCropTargetId);
   this.setState({ selectedChemicalCropTarget });
 }

 handleClickEdit() {
   this.setState({ chemicalDetailOpen: true, isNew: true });
 }

 setClose() {
   this.setState({ combinationDialogOpen: false, combinationProductDialogOpen: false, selectedCombinationTarget: null });
 }

 setCloseVariety() {
   this.setState({ chemicalCropDialogOpen: false });
 }

 setCloseChemicalCropTarget() {
   this.setState({ chemicalCropTargetDialogOpen: false });
 }

 handleUpdate(combinationIn) {
   const { getFromGlobalState, setGlobalState } = this.props;
   const combinations = getFromGlobalState('combinations');
   const updatedIndex = combinations.findIndex((i) => i.sk === combinationIn.sk);
   if (updatedIndex === -1) {
     combinations.push(combinationIn);
   } else {
     combinations[updatedIndex] = combinationIn;
   }
   setGlobalState({ combinations });
   this.setState({ selectedCombinationTarget: null, selectedCombination: combinationIn });
 }

 handleUpdateChemicalCropTarget(chemicalCropTargetIn) {
   const { setGlobalState, getFromGlobalState } = this.props;
   const { selectedChemicalCrop, selectedChemical } = this.state;
   const chemicals = getFromGlobalState('chemicals');
   const chemical = chemicals.find((c) => c.sk === selectedChemical.sk);
   const crop = chemical.chemcrops.find((c) => c.sk === selectedChemicalCrop.sk);
   if (!crop.targets) {
     crop.targets = [];
   }
   const index = crop.targets.findIndex((t) => chemicalCropTargetIn.sk === t.sk);
   if (index === -1) {
     crop.targets.push(chemicalCropTargetIn);
   } else {
     crop.targets[index] = { ...chemicalCropTargetIn };
   }
   setGlobalState({ chemicals });
   this.setState({ selectedChemicalCropTarget: chemicalCropTargetIn });
 }

 handleUpdateChemicalCrop(chemicalCropIn, chemicalSK) {
   // see if we have the block

   const updatedChemicalIndex = this.props.getFromGlobalState('chemicals').findIndex((i) => i.sk === chemicalSK);
   if (updatedChemicalIndex === -1) {
     alert("impossible - we don't have this crop!");
     return;
   }

   const chemicals = this.props.getFromGlobalState('chemicals');
   const chemicalCrops = chemicals[updatedChemicalIndex].chemcrops;

   if (!chemicalCrops) {
     chemicals[updatedChemicalIndex].chemcrops = [];
   }

   const updatedChemCropIndex = (chemicals ? chemicals[updatedChemicalIndex].chemcrops.findIndex((i) => i.sk === chemicalCropIn.sk) : -1);
   if (updatedChemCropIndex === -1) {
     chemicals[updatedChemicalIndex].chemcrops.push(chemicalCropIn);
     this.props.setGlobalState({ chemicals, chemicalCropDialogOpen: false });
   } else {
     chemicals[updatedChemicalIndex].chemcrops[updatedChemCropIndex] = { ...chemicalCropIn };
     this.props.setGlobalState({ chemicals, chemicalCropDialogOpen: false });
   }
   this.setState({ selectedChemicalCropTarget: null, selectedChemicalCrop: chemicalCropIn });
 }

 getListData = (combination) => {
  const { getFromGlobalState } = this.props;
  const fertilisers = getFromGlobalState('fertilisers');
  //const crops = getFromGlobalState('crops');
 
 const data = combination && combination.combinationProducts && combination.combinationProducts.map((product) => {
 if (product.type === 'fertiliser') {
    const ob = {};
    ob.sk = product.sk;
    ob.typeText = 'Fertiliser';
    ob.type = product.type;
    ob.name = getNameFromKey2(product.sk, fertilisers);
    return ob;
  }
  else {
  const targetRows = product.selectedChemicalCrop ? generateCropTargetRows(product.selectedChemicalCrop, getFromGlobalState) : [];
  const cropRows = product.selectedChemical ? generateCropRows(product.selectedChemical, getFromGlobalState) : [];
  const crop = cropRows.find((c) => c.sk === product.cropSK);
  const target = targetRows.find((c) => c.sk === product.targetSK);
    const ob = {};
    ob.sk = product.sk;
    ob.type = product.type;
    ob.typeText = 'PPP';
    //ob.name = `${getNameFromKey2(product.sk, getFromGlobalState('chemicals'))},  ${crop.name},  ${target.name}`;
    ob.name = getNameFromKey2(product.chemicalSK, getFromGlobalState('chemicals'));
    ob.crop = crop.name;
    ob.target = target.name;
    return ob;
  }
  });
  return data;
}


 getCropsNotAddedYet(crops, chemical, isNew, selectedChemicalCrop) {
   // need to make sure we don't allow duplicate crops
   // in the chemicals.chemprops array.
   if (!chemical) { return []; }
   if (!chemical.chemcrops) { return []; }
   const chemFilter = chemical.chemcrops.map((chem) => chem.crop);

   // Use filter and "not" includes to filter the full dataset by the filter dataset's val.
   const filteredCrops = crops.filter((crop) => !chemFilter.includes(crop.sk));
   if (!isNew) {
     // get this one and add it in.
     const crop = crops.filter((c) => c.sk === selectedChemicalCrop.crop);
     if (crop) {
       filteredCrops.push(crop[0]);
     }
   }

   return filteredCrops;
 }

 render() {
   const { classes, initialState, getFromGlobalState } = this.props;
   const {
    selectedCombinationType,
    combinationDialogOpen,
    selectedCombination,
    isNew,
    selectedChemical,
    selectedChemicalCrop,
    chemicalCropDialogOpen,
    chemicalDetailOpen,
    selectedChemicalCropTarget,
    chemicalCropTargetDialogOpen,
    combinationProductDialogOpen,
   } = this.state;

   const userDetail = getFromGlobalState('userDetail');

   let combinations = getFromGlobalState('combinations');
   if (!combinations) {
     combinations = [];
   }

   combinations = selectedCombinationType ? combinations.filter((c) => c.combinationType === selectedCombinationType) : [];
   
   combinations = combinations.map((it) => {
    const ob = { ...it };
    ob.isDisabledText = ob.isDisabled ? 'Yes' : 'No';
    return ob;
  });

 // const combinationProducts = selectedCombination ? selectedCombination.combinationProducts.filter((c) => c.combination === selectedCombination.sk) : [];
   // replace combinations where the isDisabled bool is replaced by "yes" or "no"
   let products = [];
   if (selectedCombination) {
    products = this.getListData(selectedCombination);
   }
   const b = 1;
   const chemicals = getFromGlobalState('chemicals');
   const fertilisers = getFromGlobalState('fertilisers');
  
   return (

     <Paper elevation={0} className={classes.mainSection}>
       <Grid container className={classes.root} spacing={1} alignItems="flex-start">

         {(combinationDialogOpen)
          && (
          <CombinationD
            handleUpdate={this.handleUpdate.bind(this)}
            open={combinationDialogOpen}
            setClose={this.setClose.bind(this)}
            isNew={isNew}
            combinationType={selectedCombinationType}
            combination={selectedCombination}
            getFromGlobalState={getFromGlobalState}
          />
          )}

          { (combinationProductDialogOpen) &&
           <CombinationProductD
           handleUpdate={this.handleUpdate.bind(this)}
           open={combinationProductDialogOpen}
           setClose={this.setClose.bind(this)}
           isNew={isNew}
           combinationType={selectedCombinationType}
           combination={selectedCombination}
           getFromGlobalState={getFromGlobalState}
           chemicals={chemicals}
            fertilisers={fertilisers}
            isNewComboEntry={this.state.isNewComboEntry}
            selectedCombinationTarget={this.state.selectedCombinationTarget}

         />}

         {(this.state.chemicalCropDialogOpen)
          && (
          <ChemCropD
            handleUpdate={this.handleUpdateChemicalCrop.bind(this)}
            open={chemicalCropDialogOpen}
            setClose={this.setCloseVariety.bind(this)}
            isNew={this.state.isNew}
           // metaData= {getFromGlobalState("monitorings")}
            chemCrop={selectedChemicalCrop}
            parent={selectedChemical}
            getFromGlobalState={getFromGlobalState}
         // metaData={this.props.metaData} />}
            metaData={{ filteredCrops: this.getCropsNotAddedYet(this.props.metaData, this.state.selectedChemical, this.state.isNew, this.state.selectedChemicalCrop), monitorings: getFromGlobalState('monitorings') }}
          />
          )}
         {(chemicalCropTargetDialogOpen)
          && (
          <ChemCropTargetD
            handleUpdate={this.handleUpdateChemicalCropTarget.bind(this)}
            open={chemicalCropTargetDialogOpen}
            setClose={this.setCloseChemicalCropTarget.bind(this)}
            isNew={this.state.isNew}
           // metaData= {getFromGlobalState("monitorings")}
            chemCropTarget={selectedChemicalCropTarget}
            chemical={selectedChemical}
            parent={selectedChemicalCrop}
            getFromGlobalState={getFromGlobalState}
            crop={selectedChemicalCrop ? selectedChemicalCrop.crop : null}
         // metaData={this.props.metaData} />}
            metaData={{ monitorings: getFromGlobalState('monitorings') }}
            alreadySet={[]}

          />
          )}
         <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
           <EnhancedTable
             heading="Type"
             displayToolbar
        // hideHeading
             id="combinationTypes"
             noPagination //= { rows && rows.length > 5 ? false:true}
             rows={[{ name: 'Plant Protection' }, { name: 'Fertiliser' }]}
             headCells={combinationTypeHeadCells}
             handleSelect={this.handleSelectCombinationType}
             // handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handleClickAddCombinationType : null}
             actionType="actionType"
             rowKeyName="name"
             disablePadFirstCol
             // padFirstCol="5px"
             clean
             dense
             // showEditButton
             // editDisabled={!selectedChemical}
             handleEditItem={this.handleClickShowChemical}
        // parent={selectedChemical}
       // ancestorsInRow
           // parentSK="parentSK"
             maxHeight="350px"
             selectedRow={selectedCombinationType}
           />
         </Grid>
         <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
           <EnhancedTable
             heading="Combination"
             displayToolbar
        // hideHeading
             id="combinationTable"
             noPagination //= { rows && rows.length > 5 ? false:true}
             rows={combinations}
             headCells={combinationHeadCells}
             handleSelect={this.handleSelectCombination}
             handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handelClickAddNewCombination : null}
             actionType="actionType"
             rowKeyName="sk"
             disablePadFirstCol
             // padFirstCol="5px"
             clean
             dense
             showEditButton
             editDisabled={!selectedCombination}
             disableAdd={!selectedCombinationType}
             handleEditItem={this.handleClickShowCombination}
             parent={selectedChemical}
       // ancestorsInRow
           // parentSK="parentSK"
             maxHeight="350px"
             selectedRow={selectedCombination ? selectedCombination.sk : ''}
           />
         </Grid>
         <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
           <EnhancedTable
             heading="Products"
             displayToolbar
        // hideHeading
             id="combinationTargets"
             noPagination //= { rows && rows.length > 5 ? false:true}
             rows={products}
             headCells={combinationTargetHeadCells}
             handleSelect={this.handleSelectCombinationTarget}
             handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handleClickAddNewCombinationProduct : null}
             actionType="actionType"
             rowKeyName="sk"
             disablePadFirstCol
             // padFirstCol="5px"
             clean
             dense
             showEditButton
             disableAdd={!selectedCombination}
             editDisabled={!selectedChemicalCropTarget}
             handleEditItem={this.handleClickShowChemicalCropTarget}
             parent={selectedChemicalCrop}
       // ancestorsInRow
           // parentSK="parentSK"
             maxHeight="350px"
             selectedRow={selectedChemicalCropTarget ? selectedChemicalCropTarget.sk : ''}
           />
         </Grid>
       </Grid>
     </Paper>
   );
 }
}
CombinationDetailForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  initialState: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  metaData: PropTypes.array,

};
export default withStyles(styles)(CombinationDetailForm);
