import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
});

const nameLookup = (record, key) => {
  const entity = record.find((e) => e.sk === key);
  let name = '';
  if (entity) {
    name = entity.name;
  }
  return name;
};
const varietyLookup = (record, parentKey, childKey) => {
  const crop = record.find((crop) => crop.sk === parentKey);
  let varietyName = '';
  if (crop) {
    const variety = crop.varieties.find((v) => v.sk === childKey);
    if (variety) {
      varietyName = variety.cultivar;
    }
  }
  return varietyName;
};

function renderList(classes, plantings, parentSK, crops, checkedItems, handleToggle, block) {
  return (
    <List className={classes.root}>
      {plantings && plantings.map((planting) => {
        const labelId = `checkbox-list-label-${planting.sk}`;
        const label = `Block ${block.number} ${nameLookup(crops, planting.crop)} - ${varietyLookup(crops, planting.crop, planting.variety)} - ${planting.name ? planting.name : ''}`;

        return (

          <ListItem className={classes.nested} disableRipple key={planting.sk} role={undefined} dense button onClick={handleToggle.bind(this, planting.sk, parentSK, label, block, planting)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checkedItems.findIndex((selectedItem) => (selectedItem.planting === planting.sk)) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={label} />
          </ListItem>

        );
      })}
    </List>
  );
}

function BlockList(props) {
  const {
    classes, errors, blocks, checkedItems, crops, handleToggle,
  } = props;

  return (
  
      <List className={classes.root}>
        {blocks && blocks.map((block) => (
          <Fragment key={block.sk}>
            <Typography key={block.sk} variant="subtitle1">{`Block ${block.number}`}</Typography>
            {block && block.plantings && renderList(classes, block.plantings, block.sk, crops, checkedItems, handleToggle, block)}
          </Fragment>
        ))}
      </List>
  );
}

BlockList.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleToggle: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  // handleComplexChange: PropTypes.func,
  checkedItems: PropTypes.array.isRequired,
  updateParent: PropTypes.func.isRequired,
  blocks: PropTypes.array.isRequired,
  crops: PropTypes.array.isRequired,

};

export default withStyles(styles)(BlockList);
