import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import Grid from '@material-ui/core/Grid';
import { validate, validateForm } from '../../libs/validate';
import GenModal from '../../components/GenModal';
import { getErrorMessage, getDownloadUrl } from '../../libs/storage';
import ListControl from '../../components/ListControl';
import FileUploader from '../FileUploader';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
    //height: '220px',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  /* title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  }, */
  helperText: {
    color: 'red',
  },
  group: {
    //  display: 'flex';
    // flex-wrap: 'wrap';
    flexDirection: 'row',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },

});

class MonitoringPictureD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      targetStage: this.props.targetStage,
      changed: false,
      // imageUrls: {},

    };
  }

  async componentDidMount() {
    const { getFromGlobalState, setGlobalState } = this.props;
    const imageUrls = { ...getFromGlobalState('imageUrls') };
    const { record, monitoring } = this.props;
    const stage = record.observations ? record.observations.find((f) => f.stageSK === monitoring.stageSK) : null;
    const pictArray = stage && stage.pictures ? stage.pictures : null;
    const images = pictArray && pictArray[monitoring.set] && pictArray[monitoring.set][monitoring.subSet] ? pictArray[monitoring.set][monitoring.subSet] : [];
    const newImageUrls = await this.getUrls(images, imageUrls);
    // this.setState({ imageUrls: newImageUrls });
    setGlobalState({ imageUrls: newImageUrls });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !nextProps.uploading ; //!== this.props.uploading;
  }

  getTargetUrl = async (key, fileType) => {
    const params = {
      activeTenant: this.props.getFromGlobalState('activeSite'),
      type: 'files',
      path: key,
      // isGeneral: true,
      // setId: 'a9d3f4b0-696b-4eb9-a9d7-48828697d1a3',
      contentType: fileType,
    };
    const url = await getDownloadUrl(params);
    return url;
  }

  getUrls = async (images, imageUrls) => {
    // const imgObj = {};
    if (!images) {
      return null;
    }
    for (let i = 0; i < images.length; i += 1) {
      const item = images[i];
      if (!imageUrls[item.Key]) {
        const url = await this.getTargetUrl(item.Key, item.fileType);
        imageUrls[item.Key] = url;
      }
    }

    return imageUrls;
  }

  onError = (error) => {
    // alert(error);
  }

  onFileUploadStart = (items) => {
    // alert (items[0].file.name);
  }

  fileUploadedLocal = async (f, monitoring) => {
    const { fileUploaded, getFromGlobalState, setGlobalState } = this.props;
    const imageUrls = getFromGlobalState('imageUrls');
    const newImageUrls = { ...imageUrls };
    if (!newImageUrls[f.serverId]) {
      const url = await this.getTargetUrl(f.serverId, f.fileType);
      newImageUrls[f.serverId] = url;
      setGlobalState({ imageUrls: newImageUrls });
    }

    await fileUploaded(f, monitoring, monitoring.stageSK, monitoring.set, monitoring.subSet);
  }

  render() {
    const {
      classes,
      open,
      isNew,
      setClose,
      handleUpdate,
      monitoring,
      getFromGlobalState,
      onClose,
      fileUploaded,
      record,
      onFileUploadStart,
    } = this.props;
    const imageUrls = getFromGlobalState('imageUrls');
    const isMobile = getFromGlobalState('isMobile');
    const stage = record.observations ? record.observations.find((f) => f.stageSK === monitoring.stageSK) : null;
    const pictArray = stage && stage.pictures ? record.observations.find((f) => f.stageSK === monitoring.stageSK).pictures : [];
    const images = pictArray && pictArray[monitoring.set] && pictArray[monitoring.set][monitoring.subSet] ? pictArray[monitoring.set][monitoring.subSet] : [];

    // const formValid = validateForm(pest, requiredFields);
    if (!monitoring) {
      return null;
    }

    return (

      <GenModal
        open
        setClose={onClose}
        isMobile={isMobile}
        // title={pest.name}
      >
        <div style={{ zIndex: 6000 }}>
          {/*  <Typography variant="subtitle1">{monitoring && monitoring.procedure ? monitoring.procedure : '' }</Typography> */}
          <Grid container className={classes.root} spacing={1} alignItems="flex-start">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className={classes.root}>
                <ImageList className={classes.imageList} cols={record && monitoring.images && monitoring.images.length > 1 ? 2.5 : 1}>
                  {images && images.map((item, imageIdx) => (
                    <ImageListItem key={item.Key} style={{ maxWidth: '220px', height: '220px' }}>
                      {imageUrls && imageUrls[item.Key] ? <img key={item.Key} style={{ objectFit: 'cover', height: '100%' }} alt="target" src={imageUrls[item.Key]} /> : (
                        <div style={{
                          width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                        }}
                        >
                          <CircularProgress color="secondary" />
                        </div>
                      )}
                    </ImageListItem>
                  ))}
                </ImageList>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div style={{ minWidth: '240px' }}>
                <FileUploader
                  activeTenant={this.props.getFromGlobalState('activeSite')}
                  generateThumbnail={false}
                  generatePreview={false}
                 // onFileUploaded={(f) => this.fileUploadedLocal(f, monitoring)}
                  onFileUploaded={(f) => fileUploaded(f, monitoring, monitoring.stageSK, monitoring.set, monitoring.subSet)}
                 // onFileUploaded={this.fileUploadedLocal.bind(this, monitoring)}
                  folder={`images/monitoring/${monitoring.stageSK}/`}
                  isGeneral={false}
                  allowImageResize
                  //captureMethod="camera"
                  imageResizeTargetWidth={300}
                  imageResizeTargetHeight={300}
                  imageTransformVariantsIncludeOriginal={false}
                  getFromGlobalState={this.props.getFromGlobalState}
                  onFileUploadStart={onFileUploadStart}
                 // allowFileTypeValidation
                 // acceptedFileTypes={['image/png', 'image/jpeg', 'image/gif']}
                />
                  {/*   <FileUploader
                  activeTenant={getFromGlobalState('activeSite')}
                  generateThumbnail
                  //imageTransformOutputQuality={80}
                  onError={this.onError}
                  onFileUploadStart={this.onFileUploadStart}
                  //generatePreview
              // onFileUploaded={this.fileUploaded}
                  onFileUploaded={(f) => this.fileUploadedLocal(f, monitoring)}
               // folder={`generalSettings/targets/${targetTypeName}/`}
                  folder={`images/monitoring/${monitoring.stageSK}/`}
                  allowImageResize
                  imageResizeTargetWidth={300}
                  imageResizeTargetHeight={300}
                  allowMultiple
            //  fileName={stage.imageID}
            //  forceName
                  isGeneral={false}
               // setId={setId}
                  imageTransformVariantsIncludeOriginal={false}
                  getFromGlobalState={getFromGlobalState}
                      />*/}
              </div>
            </Grid>
          </Grid>
        </div>
      </GenModal>
    );
  }
}
MonitoringPictureD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  monitoring: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  fileUploaded: PropTypes.func.isRequired,
  onFileUploadStart: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,

};
export default withStyles(styles)(MonitoringPictureD);
