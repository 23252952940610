import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Load from '../Load';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    alignItems: 'center',
  },
});

function Wizard(props) {
  const {
    classes,
    activeStep,
    steps,
    handleNext,
    handleBack,
    handleReset,
    handleFinish,
    stepValid,
    minHeight,
    minWidth,
    isMobile,
    stepsContent,
    isLoadingStep,
    children,
    replaceFinish,
  } = props;

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel style={{ paddingBottom: '0px', paddingTop: '8px' }}>
        {steps.map((step) => (
          <Step disabled={step.disabled} key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Paper elevation={0} className={classes.paper} style={!isMobile ? { minHeight, minWidth } : {}}>
              <Load isloading={isLoadingStep}>
                {children && children.length > activeStep && children[activeStep] }
              </Load>
            </Paper>
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              {activeStep !== steps.length - 1 && replaceFinish && (
              <Button disabled={!stepValid} variant="contained" color="primary" onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
Wizard.defaultProps = {
  activeStep: 0,
  minHeight: '250px',
  minWidth: '300px',
  isMobile: false,
  isLoadingStep: false,
  replaceFinish: false,
};
Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleFinish: PropTypes.func.isRequired,
  stepsContent: PropTypes.array.isRequired,
  stepValid: PropTypes.bool.isRequired,
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  isMobile: PropTypes.bool,
  replaceFinish: PropTypes.bool,
  isLoadingStep: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default withStyles(styles)(Wizard);
