import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {
  ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, ResponsiveContainer,
} from 'recharts';

const PARENT = '#0e4553';

const parseDomain = (data, dataKeyZ) => [
  0,
  data && Math.max(
    Math.max.apply(
      null,
      data.map((entry) => entry[dataKeyZ]),
    ), /* ,
    Math.max.apply(
      null,
      data.map((entry) => entry.value),
    ), */
  ),
];

export default class BubbleChart extends PureComponent {
  renderTooltip = (props) => {
    const {
      active, payload,
    } = props;

    if (active && payload && payload.length) {
      const data = payload[0] && payload[0].payload;

      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 0,
            padding: 10,
          }}
        >
          <p>
            <span>range: </span>
            {data.name}
          </p>
          <p>
            {data['Area in Ha']}
            <span> Ha</span>
          </p>
        </div>
      );
    }

    return null;
  };

  render() {
    const {
      title, data, dataKeyX, dataKeyY, dataKeyZ, height, name, yLabel,
    } = this.props;
    const domain = parseDomain(data, dataKeyZ);
    const range = [domain[0], domain[1]];
    return (
      <Paper style={{ borderRadius: '4px', overflow: 'hidden' }}>
        {title && (
        <>
          <div style={{
            width: '100%', justifyContent: 'center', display: 'inline-flex', paddingTop: '5px',
          }}
          >
            <Typography variant="button" gutterBottom>{title}</Typography>

          </div>
        </>
        )}

        <ResponsiveContainer key={name} width="100%" height={height} style={{ cursor: 'pointer' }}>
          <ScatterChart
            width="100%"
            height={60}
            margin={{
              top: 30,
              right: 20,
              bottom: 10,
              left: 10,
            }}
          >
            <XAxis
              type="category"
              dataKey={dataKeyX}
              interval={0}
              name={dataKeyX}
              // tick={{ fontSize: 0 }}
              tickLine={{ transform: 'translate(0, -6)' }}
              label
            />
            <YAxis
              type="number"
              dataKey={dataKeyY}
              name={dataKeyY}
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: yLabel, position: 'insideRight' }}
            />
            <ZAxis label type="number" dataKey={dataKeyZ} domain={domain} range={range} />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />
            <Scatter data={data} fill={PARENT} />
          </ScatterChart>
        </ResponsiveContainer>

      </Paper>

    );
  }
}
BubbleChart.defaultProps = {
  height: 400,
};
BubbleChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataKeyX: PropTypes.string.isRequired,
  dataKeyY: PropTypes.string.isRequired,
  dataKeyZ: PropTypes.string.isRequired,
  height: PropTypes.string,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  yLabel: PropTypes.string.isRequired,
};
