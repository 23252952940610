import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import GenDialog from '../../components/GenDialog';
import SingleListControl from '../../components/SingleListControl';
import { validate, validateForm } from '../../libs/validate';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

const requiredFields = [
  { name: 'name', min: 1, max: 100 },
  { name: 'surname', min: 1, max: 100 },
  { name: 'roles', min: 1, max: 1000 },
  {
    name: 'team', min: 1, max: 100, condition: { name: 'teamLeader', value: true },
  },
];

class OperatorD extends Component {
  constructor(props) {
    super(props);
    const { handleUpdate } = this.props;
    this.handleUpdate = handleUpdate.bind(this);

    this.state = {
      operator: {},
      errors: { count: 0 },
      changed: false,

    };
  }

  componentDidMount() {
    const { isNew, selectedSetting, roles } = this.props;
    if (isNew) {
      this.setState({ operator: { enabled: true, roles: [], allSelected: false }, roles });
    } else {
      const operator = selectedSetting;
      this.setState({ operator, roles });
    }
  }

  handleDelete = (value) => {
    const operator = { ...this.state.operator };
    const currentIndex = operator && operator.roles.findIndex((item) => item === value);

    if (currentIndex !== -1) {
      operator.roles.splice(currentIndex, 1);
    }
    this.setState({ operator, changed: true });
  }

  onSelectAll = () => {
    const { operator, roles } = this.state;
    const newOperator = { ...operator };
    if (newOperator.allSelected) {
      newOperator.allSelected = false;
      newOperator.roles = [];
    } else {
      newOperator.allSelected = true;
      newOperator.roles = [];
      newOperator.roles = roles.map((r) => r.sk);
    }
    this.setState({ operator: newOperator, changed: true });
  }

  handleToggle = (sk) => {
    const { operator } = this.state;
    const newOperator = { ...operator };
    if (!newOperator.roles) {
      newOperator.roles = [];
    }
    const currentIndex = operator && operator.roles.findIndex((item) => item === sk);

    if (currentIndex === -1) {
      newOperator.roles.push(sk);
    } else {
      newOperator.roles.splice(currentIndex, 1);
    }
    this.setState({ operator: newOperator, changed: true });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const { operator } = this.state;
    const newOperator = { ...operator };
    newOperator[event.target.id] = event.target.value;
    this.setState({ operator: newOperator, changed: true });
  }

  handleCheckChange = (event) => {
    const { operator } = this.state;
    const newOperator = { ...operator };
    newOperator[event.target.id] = event.target.checked;
    this.setState({ operator: newOperator, changed: true });
  }

  onDisable = () => {
    const { operator } = this.state;
    const newOperator = { ...operator };
    if (!newOperator.disabled) {
      newOperator.disabled = true;
    } else {
      newOperator.disabled = false;
    }
    this.setState({ operator: newOperator, changed: true });
  }

  validateField(field) {
    const { operator, errors } = this.state;
    const error = validate(field, operator, requiredFields);
    const newErrors = { ...errors };
    newErrors[field] = error.msg;
    this.setState({ errors: newErrors });
  }

  render() {
    const {
      classes, open, isNew, setClose, getFromGlobalState,
    } = this.props;
    const {
      operator, errors, changed, roles,
    } = this.state;
    const formValid = validateForm(operator, requiredFields);

    return (

      <GenDialog
        open={open}
        dialogObject={operator}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={this.handleUpdate}
        skPrimary="init_setting_"
        dynamoType="operators"
        valid={formValid}
        changed={changed}
        onDisable={this.onDisable}
        newTitle="New member"
        title={operator.name}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={operator.enabled}
                 // disabled={isInvite}
                  id="enabled"
                  onChange={this.handleCheckChange}
                  value="enabled"
                  color="secondary"
                />
        )}
              label="Enabled"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoFocus
              value={operator.name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="surname"
              label="Surname"
              name="surname"
              value={operator.surname}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.surname ? true : undefined}
              helperText={errors.surname}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
              fullWidth
              id="idNo"
              label="ID"
              name="idNo"
              value={operator.idNo}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.idNo ? true : undefined}
              helperText={errors.idNo}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
              fullWidth
              id="clockNo"
              label="Clock number"
              name="clockNo"
              value={operator.clockNo}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.clockNo ? true : undefined}
              helperText={errors.clockNo}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
              fullWidth
              id="team"
              label="Team"
              name="team"
              value={operator.team}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.team ? true : undefined}
              helperText={errors.team}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={operator.teamLeader}
                 // disabled={isInvite}
                  id="teamLeader"
                  onChange={this.handleCheckChange}
                  value="teamLeader"
                  color="secondary"
                />
        )}
              label="Team Leader"
            />
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
            <FormControl className={classes.formControl}>
              <Typography variant="subtitle1">Allowed tasks</Typography>
              <SingleListControl
                data={roles}
                checkedItems={operator.roles}
                handleToggle={this.handleToggle}
                arrayName="blank"
                onSelectAll={this.onSelectAll}
                allSelected={roles && operator.roles && roles.length === operator.roles.length}
              />
            </FormControl>
          </Grid>
        </Grid>
      </GenDialog>

    );
  }
}
OperatorD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedSetting: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(OperatorD);
