import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import uuid from 'uuid';
import ExpansionPanelDetailed from '../../components/ExpansionPanelDetail';
import FarmDetailForm from './farmDetailForm';
import CropDetailForm from './cropDetailForm';
import ChemicalDetailForm from './chemicalDetailForm';
import FertiliserDetailForm from './fertiliserDetailForm';
import MonitoringDetailForm from './monitoringDetailForm';
import CombinationDetailForm from './combinationDetailForm';
import GenSetForm from './genSetForm';
import ChemEquipmentD from '../ChemEquipmentD';
import ApiKeyD from '../ApiKeyD';
import HarvestingEquipmentD from '../HarvestingEquipmentD';
import ClusterD from '../ClusterD';
import OperatorD from '../OperatorD';
import AgronomistActD from '../AgronomistActD';
import UserDetailForm from './userDetailForm';
import BlockDetailForm from './blockDetailForm';
import FarmsD from '../FarmsD';
import FacilityD from '../FacilityD';
import Load from '../../components/Load';
import { toStore, createObject } from '../../libs/storage';
import { arrayToString } from '../../libs/genLib';
import MyProductProcessorD from '../MyProductProcessorD';

const roles = [
  { name: 'Harvest', sk: 'harvestings' },
  { name: 'Irrigate', sk: 'waterings' },
  { name: 'Fertilise', sk: 'fertilisings' },
  { name: 'Monitor', sk: 'pestings' },
  { name: 'Agronomic Activities', sk: 'activities' },
  { name: 'Plant Protection', sk: 'sprayings' }];

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  headingDropper: {
    display: 'inherit',
    marginRight: 'auto',
    marginBottom: '15px',
  },
  paperDropperBody: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  heading: {
    marginTop: '4px',
    cursor: 'pointer',
  },
  header: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'inherit',
    marginBottom: theme.spacing(10),
  },
  section: {
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedSetting: '',
      errors: {},
      clean: false,

    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    const isMobile = !window.matchMedia('(min-width: 850px)').matches;
    const clean = isMobile;
    this.setState({ clean });
  }

updateGlobalState = (object, type) => {
  const data = { ...this.props.getFromGlobalState('settings') };
  data[type] = object;
  this.props.setGlobalState({ settings: data });
}

  handleSubmit = async (dialogObject, event) => {
    event.preventDefault();

    this.setState({ isSubmitting: true });
    this.setState({ messageText: '' });
    try {
      if (!dialogObject.sk) {
        dialogObject.sk = `init_set_crops_${uuid.v1()}`;
        dialogObject.type = 'settings';
        dialogObject.version = 0;
      }  
      const version = dialogObject.version ? dialogObject.version : 0;
      dialogObject.version = version + 1;
      let creation = {};
      dialogObject.activeTenant = this.props.getFromGlobalState('activeSite');
      creation = await createObject(this.props.getFromGlobalState('currentUser').username, 'calls', toStore(dialogObject), this.props.getFromGlobalState('activeSite'));
      
      if (creation.status) {
        dialogObject.cached = creation.cached;
      }

      this.setState({ isSubmitting: false });
      this.setState({ success: true });
      this.updateGlobalState(dialogObject, 'set_crops');      
      this.props.setClose();
    } catch (e) {
      this.setState(
        {
          success: false,
          isSubmitting: false,
          messageText: (e.response ? `${e.response.data.error.message}, api src: ${e.response.data.src} ` : e.message),
        },
      );
    }
  }

  handleChange = (objectName, event) => {
    const data = { ...this.props.getFromGlobalState('settings') };
    data[objectName][event.target.id] = event.target.value;
    this.props.setGlobalState({ settings: data });
  }

  handleAddChange = (object, objectName, event) => {
    const data = { ...this.props.getFromGlobalState('settings') };
    if (!data[objectName]) {
      data[objectName] = [];
    }
    data[objectName].push(object);
    this.props.setGlobalState({ settings: data });
  }

  roleString = (operatorRoles) => {
    if (!operatorRoles) {
      return '';
    }
    let newRoles = roles.filter((role) => operatorRoles.includes(role.sk));
    newRoles = newRoles.map((r) => r.name);
    return arrayToString(newRoles);
  }

  handleArrayChange = (objectName, index, event) => {
    const data = { ...this.props.getFromGlobalState('settings') };
    const idx = data[objectName].findIndex((ob) => ob.sk === index);
    if (idx !== -1) {
      data[objectName][idx][event.target.id] = event.target.value;
      this.props.setGlobalState({ settings: data });
    }
  }

   comparer = (otherArray) => (current) => otherArray.filter((other) => other.tenantId === current.farmId).length === 0

  filterSites = (sites, farms) => {
    console.log("sites", "farms",sites, farms, this.props.getFromGlobalState("allfarms"));
    if (!farms) { return []; }

    const myArrayFiltered = farms.filter((el) => sites.some((s) => s.tenantId === el.newTenant));

    return myArrayFiltered;
  }

  handleBlur = (event) => {
  }

  handleSettingExpand = (setting) => {
    if (this.state.expandedSetting !== setting) {
      this.setState({ expandedSetting: setting });
    } else {
      this.setState({ expandedSetting: '' });
    }
  }

  render() {
    const { classes, getFromGlobalState, sync } = this.props;
    const { expandedSetting, errors, clean } = this.state;
    const farm = getFromGlobalState('farm');
    let siteList = getFromGlobalState('siteList');
    siteList = siteList.sort((a, b) => (`${a.shortName}`).localeCompare(b.shortName, undefined, { numeric: true }));
    let farms = this.props.getFromGlobalState('farms');
    farms = farms.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: true }));
    const confirmed = siteList.length === 1 && siteList[0].isNewAccount
    const isEntity = confirmed ? true : !!(farm && (farm[0] && farm[0].entityType && farm[0].entityType === 'entity'));

    return (
      <Load isloading={this.state.isloading}>
        <Paper elevation={0} className={classes.paper}>

          <ExpansionPanelDetailed
            heading={isEntity ? 'Entity Detail' : 'Farm Detail'}
            expanded={expandedSetting}
            handleExpand={this.handleSettingExpand}
          >
            <FarmDetailForm
              initialState={farm}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              errors={errors}
              setGlobalState={this.props.setGlobalState}
              getFromGlobalState={this.props.getFromGlobalState}
            />
          </ExpansionPanelDetailed>

          {!isEntity && (
          <>
            {' '}

            <ExpansionPanelDetailed
              heading="Blocks"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <BlockDetailForm
                clean={clean}
                initialState={this.props.getFromGlobalState('blocks')}
                farm={this.props.getFromGlobalState('farm')}
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
                errors={errors}
                setSnack={this.props.setSnack}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              heading="Clusters"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('clusters')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<ClusterD />}
                settingName="clusters"
                settingHeading="Add"
                headCells={[
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Group',
                  },
                  { /* {
                    id: 'capacity', numeric: true, disablePadding: false, label: 'Capacity in Kg',
                  }, */ },

                ]}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              heading="Crops"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <CropDetailForm
      // initialState={crops}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                setSnack={this.props.setSnack}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              heading="Monitoring"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <MonitoringDetailForm
                initialState={this.props.getFromGlobalState('monitorings')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                setSnack={this.props.setSnack}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              // disabled
              heading="Plant Protection Products"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <ChemicalDetailForm
                initialState={this.props.getFromGlobalState('chemicals')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                metaData={this.props.getFromGlobalState('crops')}
                sync={this.props.sync}
                activeSite={this.props.activeSite}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
             // disabled
              heading="Fertilisers"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <FertiliserDetailForm
                initialState={this.props.getFromGlobalState('fertilisers')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                metaData={this.props.getFromGlobalState('crops')}
                setSnack={this.props.setSnack}
                isMobile={this.props.isMobile}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
             // disabled
              heading="Combinations"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <CombinationDetailForm
                initialState={this.props.getFromGlobalState('combinations')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                 metaData={{ fertilisers: this.props.getFromGlobalState('fertilisers'),chemicals: this.props.getFromGlobalState('chemicals') }}
                setSnack={this.props.setSnack}
                isMobile={this.props.isMobile}
              />
            </ExpansionPanelDetailed>
            <ExpansionPanelDetailed
              heading="Equipment"
              expanded={expandedSetting}
             // disabled
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('chemequips')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<ChemEquipmentD />}
                settingName="chemequips"
                settingHeading="Add"
                metaDataName="chemicals"
                headCells={[
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                  {
                    id: 'capacity', numeric: true, disablePadding: false, label: 'Capacity in liter',
                  },

                ]}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              heading="Harvesting"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('harvestequips')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<HarvestingEquipmentD />}
                settingName="harvestequips"
                settingHeading="Add"
                headCells={[
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                  {
                    id: 'capacity', numeric: true, disablePadding: false, label: 'Capacity in Kg',
                  },

                ]}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              heading="Agronomic activities"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('agronomistActs')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<AgronomistActD />}
                settingName="agronomistActs"
                settingHeading="Add"
                displayLabelPrim="name"
                displayLabelSec="description"
                headCells={[
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                  {
                    id: 'description', numeric: false, disablePadding: false, label: 'Description',
                  },

                ]}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              heading="Workforce"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('operators').map((operator) => {
                  const ob = { ...operator };
                  ob.name = `${ob.name} ${ob.surname}`;
                  ob.rolesString = this.roleString(ob.roles);
                  ob.enabled = ob.enabled ? 'Yes' : 'No';
                  ob.leader = ob.teamLeader ? 'Yes' : 'No';
                  return ob;
                })}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<OperatorD roles={roles} />}
                settingName="operators"
                settingHeading="Add"
                displayLabelPrim="name"
                userAvatar
                displayLabelSec="surname"
                headCells={!clean ? [

                  {
                    id: 'enabled', numeric: false, disablePadding: false, label: 'Enabled',
                  },
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                  {
                    id: 'leader', numeric: false, disablePadding: false, label: 'Leader',
                  },
                  {
                    id: 'clockNo', numeric: false, disablePadding: false, label: 'Clock No',
                  },
                  {
                    id: 'idNo', numeric: false, disablePadding: false, label: 'ID',
                  },
                  {
                    id: 'team', numeric: false, disablePadding: false, label: 'Team',
                  },
                  {
                    id: 'rolesString', numeric: false, disablePadding: false, label: 'Role',
                  },

                ] : [
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                ]}
              />
            </ExpansionPanelDetailed>
            {/* <ExpansionPanelDetailed
              disabled
              heading="Processors"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('myProductProcessors').length && this.props.getFromGlobalState('myProductProcessors').filter((f) => f.enabled).map((p) => {
                  const ob = { ...p };
                  const productProcessors = this.props.getFromGlobalState('productProcessors');
                  const processor = productProcessors.find((p) => p.sk === ob.productProcessor);
                  const processorType = processor && processor.sk.substring(0, processor.sk.indexOf('_setchild_'));
                  const processorTypes = this.props.getFromGlobalState('productProcessorTypes');
                  ob.processorType = processor && processorTypes ? processorTypes.find((h) => h.sk === processorType).name : '';
                  ob.name = processor && processor.name;
                  ob.province = processor && processor.province;
                  return ob;
                })}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<MyProductProcessorD setSnack={this.props.setSnack} />}
                settingName="myProductProcessors"
                settingHeading="Add"
                displayLabelPrim="name"
                // userAvatar
                // displayLabelSec="surname"
                headCells={[

                  {
                    id: 'province', numeric: false, disablePadding: false, label: 'Province',
                  },
                  {
                    id: 'processorType', numeric: false, disablePadding: false, label: 'Type',
                  },
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                ]}
              />
            </ExpansionPanelDetailed> */}

            {/* <ExpansionPanelDetailed
              disabled
              heading="Facilities"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('facilities')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<FacilityD />}
                settingName="facilities"
                settingHeading="Add"
                displayLabelPrim="name"
                userAvatar
                displayLabelSec="surname"
                headCells={[

                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                  {
                    id: 'description', numeric: false, disablePadding: false, label: 'Clock No',
                  },
                ]}
              />
            </ExpansionPanelDetailed> */}

          </>
          ) }
          {isEntity && <ExpansionPanelDetailed
            heading={isEntity ? 'Farms' : 'Additional Sites'}
            expanded={expandedSetting}
            handleExpand={this.handleSettingExpand}
          >
            <GenSetForm
              initialState={this.filterSites(siteList, farms).map((f) => {
                const ob = { ...f };
                ob.isDisabled = f.isDisabled ? 'Yes' : 'No';
                return ob;
              })}
              handleSubmit={this.handleSubmit}
              isSubmitting={this.state.isSubmitting}
              setGlobalState={this.props.setGlobalState}
              getFromGlobalState={this.props.getFromGlobalState}
              settingDialog={<FarmsD />}
              settingName="farms"
              settingHeading="Add"
              displayLabelPrim="name"
              displayLabelSec="description"
              headCells={[
                {
                  id: 'isDisabled', numeric: false, disablePadding: false, label: 'Disabled',
                },
                {
                  id: 'name', numeric: false, disablePadding: false, label: 'Name',
                },
                {
                  id: 'description', numeric: false, disablePadding: false, label: 'Description',
                },

              ]}
            />
          </ExpansionPanelDetailed>}
          <ExpansionPanelDetailed
            heading="Users"
            expanded={expandedSetting}
            handleExpand={this.handleSettingExpand}
          >
            <UserDetailForm
              initialState={this.props.getFromGlobalState('users')}
              handleSubmit={this.handleSubmit}
              isSubmitting={this.state.isSubmitting}
              setGlobalState={this.props.setGlobalState}
              getFromGlobalState={this.props.getFromGlobalState}
              setSnack={this.props.setSnack}
            />
          </ExpansionPanelDetailed>

          <ExpansionPanelDetailed
            heading="API Keys"
            expanded={expandedSetting}
             // disabled
            handleExpand={this.handleSettingExpand}
          >
            <GenSetForm
              initialState={this.props.getFromGlobalState('apiKeys').map((apiKey) => { const ob = {...apiKey}; ob.enabled = ob.isDisabled? 'Yes' : 'No'; return ob; })}
             // handleSubmit={this.handleSubmit}
              isSubmitting={this.state.isSubmitting}
              setGlobalState={this.props.setGlobalState}
              getFromGlobalState={this.props.getFromGlobalState}
              settingDialog={<ApiKeyD />}
              settingName="apiKeys"
              settingHeading="Add"
              metaDataName="apiKeys"
              headCells={[
                {
                  id: 'enabled', numeric: false, disablePadding: false, label: 'Disabled',
                },
                {
                  id: 'consumer', numeric: false, disablePadding: false, label: 'Consumer',
                },
                {
                  id: 'apiKey', numeric: false, disablePadding: false, label: 'Key',
                },
              ]}
            />
          </ExpansionPanelDetailed>
        </Paper>
      </Load>

    );
  }
}
export default withStyles(styles)(Settings);
